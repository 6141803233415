import React, { useState, useContext } from "react"
import { GRDialog } from "../ui/GRDialog"
import PhotoIcon from "../../assets/svg/PhotoIcon"
import VideoIcon from "../../assets/svg/VideoIcon"
import { RichTextEditor } from "../richTextEditor/RichTextEditor"
import GRButton from "../ui/GRButton"
import { Form, Formik } from "formik"
import { AddPostValidation } from "../../validations/Validations"
import DialogHeader from "./DialogHeader"
import { useConfigAPI } from "../../services/useConfigAPI"
import { usePostAPI } from "../../services/usePostAPI"
import AppContext from "../../context/app-context/AppContext"
import { useUserHook } from "../../hooks/useUserHook"
import { successToast } from "../../utils/ToastUtils"
import { debounce } from "lodash"
import { urlRegex } from "../../utils/Constants"
import GRProgressBar from "../ui/GRProgressBar"
import { imageExtensions } from "../../utils/Constants"

interface IAddPostFormValues {
  postContent: string
  postType: string
}

const AddPostFormInitialValues = {
  postContent: "",
  postType: "",
}

interface IAddPostDialogProps {
  isOpen: boolean
  onClose: () => void
  onFetchPosts: () => void
}

const AddPostDialog = (props: IAddPostDialogProps) => {
  const { isOpen, onClose, onFetchPosts } = props
  const { uploadFiles } = useConfigAPI()
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  const [isImage, setIsImage] = useState<boolean>(false)
  const [key, setKey] = useState<number>(0) // Key to remount the input element
  const [uploadFileId, setUploadFileId] = useState<number | null>(null)
  const [isUplaoding, setIsUploading] = useState<boolean>(false)
  const [url, setUrl] = useState<string>("")
  const [isSupportedImageType, setIsSupportedImageType] = useState(false)

  //API
  const { isLoading, createPost, previewLink } = usePostAPI()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)
  // Hook
  const { isLoginAsClub } = useUserHook()
  const [fileSizeError, setFileSizeError] = useState<string | null>(null)
  const [progress, setProgress] = useState(0)

  const handlePostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0]
        setIsSupportedImageType(false)
        const formData = new FormData()
        formData.append("files", file, file?.name)
        const fileSizeKB = file.size / 1024 // Convert to KB
        if (/image/.test(file.type)) {
          if (file && !imageExtensions?.includes(file?.type)) {
            setIsSupportedImageType(true)
            return
          }
          if (fileSizeKB < 10 || fileSizeKB > 5 * 1024) {
            setIsUploading(false)
            setFileSizeError("Photo size must be between 10 KB and 5 MB")
            return
          }
        } else {
          if (fileSizeKB < 75 || fileSizeKB > 200 * 1024) {
            setIsUploading(false)
            setFileSizeError("Video size must be between 75 KB and 200 MB")
            return
          }
        }
        setIsUploading(true)
        setFileSizeError(null)
        const uploadResponse = await uploadFiles(formData, setProgress)
        setIsUploading(false)
        uploadResponse?.[0]?.mime?.split("/")[0] === "image" ? setIsImage(true) : setIsImage(false)

        if (uploadResponse?.[0]) {
          setUploadFileId(uploadResponse?.[0]?.id)
          setThumbnail(uploadResponse?.[0]?.url)
          event.target.value = ""
        }
      } else {
        setThumbnail(null)
        event.target.value = ""
      }
      setKey((prevKey) => prevKey + 1)
    } catch {
      console.log("Error uploading file")
    }
  }

  const addPost = async (values: IAddPostFormValues, formikBag: any) => {
    try {
      if (/^\s*<p>\s*<\/p>\s*$/.test(values?.postContent)) {
        formikBag.setFieldError("postContent", "Empty content not allowed")
        return
      }
      const postPayload: any = {
        data: {
          description: values?.postContent || "",
          media: uploadFileId ? uploadFileId : null,
          postType: values?.postType,
          club: isLoginAsClub ? loginAsProfileDetails?.club?.id : null,
          preViewUrl: !uploadFileId ? thumbnail : null,
        },
      }
      const addNewPost = await createPost(postPayload)
      if (addNewPost?.data) {
        onClose()
        successToast("Post created successfully")
        onFetchPosts()
        setThumbnail(null)
      }
    } catch (error) {}
  }

  const getText = (html: string) => {
    const el = document.createElement("div")
    el.innerHTML = html
    return el.textContent
  }

  const convertUrlsToLinks = (content: string) => {
    if (urlRegex.test(content)) {
      const url = content.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")
      if (thumbnail) return
      getPreviewUrl(url)
    }
  }

  const getPreviewUrl = async (urlStr: string) => {
    try {
      if (!urlStr) return
      const values = urlStr?.split(" ")
      const value = values?.find(
        (v) => v?.includes("http://") || v?.includes("https://") || v?.includes("www."),
      )
      !url && value && setUrl(value)
      const validUrl =
        value?.includes("https://") || value?.includes("http://") ? value : `https://${value}`
      const preview = await previewLink(validUrl)
      if (preview?.data?.image) {
        setIsImage(true)
        setThumbnail(preview?.data?.image)
      }
    } catch (error) {}
  }

  const debouncePriView = debounce(convertUrlsToLinks, 1000)

  const body = (
    <Formik
      initialValues={AddPostFormInitialValues}
      validationSchema={AddPostValidation}
      onSubmit={addPost}
      enableReinitialize
    >
      {({ values, errors, touched, setFieldValue, setFieldTouched, handleSubmit }) => (
        <Form className="">
          <div className="md:mx-7 mx-4 mb-3">
            <div>
              <div>
                <p className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-2 font-inter">
                  Select Post Type
                </p>
              </div>
              <div className="mb-4">
                <div className="flex gap-[40px]">
                  <label className="">
                    <div
                      className={`mb-2  px-2 py-2 hover:bg-gr-primaryR1 ${
                        values.postType === "Image" ? "bg-gr-primaryR1" : ""
                      }`}
                    >
                      <PhotoIcon />
                    </div>
                    <div
                      className={`${
                        values.postType === "Image"
                          ? "font-inter text-sm text-gr-primaryN2 ml-1 font-extrabold"
                          : "font-inter font-bold text-sm text-gr-primaryN2 ml-1"
                      }`}
                    >
                      <p className="">Photo</p>
                    </div>
                    <input
                      key={key}
                      type="file"
                      id="photo"
                      name="photo"
                      className="hidden"
                      accept="image/*"
                      onChange={(event) => {
                        handlePostChange(event), setFieldValue("postType", "Image")
                      }}
                    />
                  </label>
                  <label>
                    <div
                      className={`mb-2  px-2 py-2 hover:bg-gr-primaryR1 ${
                        values.postType === "Video" ? "bg-gr-primaryR1" : ""
                      }`}
                    >
                      <VideoIcon />
                    </div>
                    <div
                      className={`${
                        values.postType === "Video"
                          ? "font-inter text-sm text-gr-primaryN2 ml-2 mt-1 font-extrabold"
                          : "font-inter mt-1 font-bold text-sm text-gr-primaryN2 ml-2"
                      }`}
                    >
                      Video
                    </div>
                    <input
                      key={key}
                      id="video"
                      name="video"
                      type="file"
                      className="hidden"
                      accept="video/*"
                      onChange={(event) => {
                        handlePostChange(event), setFieldValue("postType", "Video")
                      }}
                    />
                  </label>
                </div>
                <div className="text-xs text-gray-700 font-semibold">
                  Image size must be between 10 KB and 5 MB and video size must be between 75 KB and
                  200 MB
                </div>
                <div className="font-poppins text-errorRed text-xs">
                  {touched?.postType && errors?.postType ? errors?.postType : ""}
                  {fileSizeError ? <span>{fileSizeError}</span> : null}
                </div>
              </div>
            </div>
            {isUplaoding ? (
              // <div className="h-[350px] flex justify-center items-center">Loading...</div>
              <GRProgressBar progress={progress}></GRProgressBar>
            ) : thumbnail ? (
              <div className=" flex justify-center items-center w-full md:h-[390px] sm:h-[300px] h-[150px] my-2 mb-4">
                {isImage ? (
                  <img className="md:h-[390px] sm:h-[300px] h-[150px]" src={thumbnail} />
                ) : (
                  <video
                    controls
                    className="bg-cover md:h-[390px] sm:h-[300px] h-[150px] w-full"
                    src={thumbnail}
                  />
                )}
              </div>
            ) : (
              isSupportedImageType && (
                <div>
                  <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
                    Supported files include: images (jpg, jpeg, gif*, png, svg, webp)
                  </span>
                </div>
              )
            )}

            <div className="mb-6">
              <div>
                <p className="font-inter text-sm text-black leading-6 mb-1">Write Post</p>
              </div>
              <div className="w-[275px] sm:w-full relative">
                <div className="absolute top-[-1.6em] right-3">
                  {getText(values?.postContent)?.length} / 3000 characters
                </div>
                <RichTextEditor
                  value={values?.postContent}
                  onChange={(v) => {
                    setFieldTouched("postContent", true)
                    if (v === "<p><br></p>") {
                      setFieldValue("postContent", "")
                      return
                    }
                    setFieldValue("postContent", v?.trim())
                    getText(v)?.trim()
                    debouncePriView(v)
                  }}
                  placeholder="Add post"
                />
              </div>

              {/* <div className="font-poppins text-errorRed text-xs h-4 mt-12">
                {touched?.postContent && errors?.postContent ? errors?.postContent : ""}
              </div> */}
            </div>
            <div className="flex justify-between items-center mb-8 mt-20">
              <GRButton
                type="button"
                onClick={() => {
                  setThumbnail(null)
                  onClose()
                }}
                label="Cancel"
                className="w-[100px]"
              />
              <GRButton
                isSubmitting={isLoading}
                disabled={!values.postContent.trim()}
                onClick={handleSubmit}
                label="Post"
                className="w-[100px]"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )

  return (
    <div>
      <GRDialog
        open={isOpen}
        onClose={() => {
          onClose()
          setThumbnail(null)
          setUploadFileId(null)
        }}
        dialogtitle={
          <DialogHeader
            onClose={() => {
              onClose()
              setThumbnail(null)
              setUploadFileId(null)
            }}
            title="Add Post"
          />
        }
        dialogbody={body}
        fullWidth
        disablebackdropclick={false}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
      />
    </div>
  )
}

export default AddPostDialog
