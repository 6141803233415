import React, { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { AUTH_MESSAGE, AUTH_MY_PROFILE, AUTH_USER_PROFILE } from "../../routes/RouteConstants"

import { IUser } from "../../modals/User"
import AppContext from "../../context/app-context/AppContext"
import MessageIcon from "../../assets/svg/MessageIcon"
import LeftArrow from "../../assets/svg/LeftArrow"
import { getNameInitials } from "../../utils/CommonUtils"

interface IFollowersProps {
  profileDetails?: IUser
}

const UserFollowers = (props: IFollowersProps) => {
  // Props
  const { profileDetails } = props
  //Hook
  const { userId } = useParams()

  const { loginAsProfileDetails } = useContext(AppContext)
  const Followers = location.pathname === "/Followers"

  return (
    <div className="flex flex-col  bg-white rounded-t-[16px]   px-6  py-4 ">
      <div className="flex justify-between items-center mt-2">
        <div className="flex items-center gap-4 font-inter text-[24px] text-gr-primaryN1  font-semibold ">
          {!Followers ? (
            <Link to={userId ? `${AUTH_USER_PROFILE}/${userId}` : AUTH_MY_PROFILE}>
              <LeftArrow />
            </Link>
          ) : null}
          {`Followers (${profileDetails?.followers?.length})`}
        </div>
      </div>

      <div className="border border-gray-200 mt-4 mb-5"></div>
      {profileDetails?.followers?.map((el, indx) => (
        <div
          key={indx}
          className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5
              ${indx === 0 && "bg-gr-primaryN9"}`}
        >
          <div className="flex  gap-4 items-center">
            <div>
              {loginAsProfileDetails?.id === el?.userId ? (
                el?.profilePicture?.formats?.thumbnail?.url ? (
                  <Link to={`${AUTH_MY_PROFILE}`}>
                    <img
                      src={el?.profilePicture?.formats?.thumbnail?.url}
                      alt="profile-image"
                      className="rounded-full w-[48px] h-[48px]"
                    />
                  </Link>
                ) : (
                  <Link to={`${AUTH_USER_PROFILE}`}>
                    <div className="flex items-center justify-center pl-3 pr-[14.5px] py-2 mb-1.5 w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                      <span className="text-[16px] sm:text-[18px] text-gr-primaryR2 font-semibold">
                        {getNameInitials(el?.firstname, el?.lastname)}
                      </span>
                    </div>
                  </Link>
                )
              ) : el?.profilePicture?.formats?.thumbnail?.url ? (
                <Link to={`${AUTH_USER_PROFILE}/${el?.userId}`}>
                  <img
                    src={el?.profilePicture?.formats?.thumbnail?.url}
                    alt="profile-image"
                    className="rounded-full w-[48px] h-[48px]"
                  />
                </Link>
              ) : (
                <Link to={`${AUTH_USER_PROFILE}/${el?.userId}`}>
                  <div className="flex items-center justify-center pl-3 pr-[14.5px] py-2 mb-1.5 w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                    <span className="text-[16px] sm:text-[18px] text-gr-primaryR2 font-semibold">
                      {getNameInitials(el?.firstname, el?.lastname)}
                    </span>
                  </div>
                </Link>
              )}
            </div>
            <div>
              <p className="font-inter text-[14px] leading-[17px] text-gr-primaryN1 font-semibold mb-[3px] hover:underline">
                {loginAsProfileDetails?.id === el?.userId ? (
                  <Link to={`${AUTH_MY_PROFILE}`}>
                    {el?.firstname} {el?.lastname}
                  </Link>
                ) : (
                  <Link to={`${AUTH_USER_PROFILE}/${el?.userId}`}>
                    {el?.firstname} {el?.lastname}
                  </Link>
                )}
              </p>
              <p className="font-inter text-[12px] leading-[15px] text-gr-neutralT3 font-normal">
                {el?.profileType}
              </p>
            </div>
          </div>
          {loginAsProfileDetails?.id === userId || !userId ? (
            <div>
              <Link to={`${AUTH_MESSAGE}?chatwith=${el?.userId}`}>
                <MessageIcon />
              </Link>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  )
}

export default UserFollowers
