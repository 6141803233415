import React from "react"
import Select, { SelectProps } from "@mui/material/Select"
import { FormControl, FormHelperText, MenuItem } from "@mui/material"
import clsx from "clsx"

export interface IOptions {
  name: string
  value: string
}

interface IGRSelectProps extends SelectProps {
  options?: IOptions[]
  errormsg?: string
}

const GRSelect: React.FC<IGRSelectProps> = ({
  className,
  style,
  placeholder,
  ...props
}: IGRSelectProps) => {
  return (
    <FormControl fullWidth>
      <Select
        className={clsx("!rounded-[10px]", className)}
        style={{
          height: "40px",
          // padding: "10px",
          fontSize: "14px",
          ...style,
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "darkRed",
            marginRight: "10px",
          },
          "& .MuiSelect-select .notranslate::after":
            placeholder || "--Select--"
              ? {
                  content: `"${placeholder || "--Select--"}"`,
                  opacity: 0.75,
                }
              : {},
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "300px",
            },
            sx: {
              "& .MuiMenuItem-root": {
                fontSize: "14px",
              },
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: "#F8F5F5",
                color: "#9F2E00",
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "#F8F5F5",
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: "#F8F5F5",
              },
            },
          },
        }}
        {...props}
      >
        {props?.options &&
          props?.options?.length > 0 &&
          props?.options?.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
      {props?.errormsg && props?.error && (
        <FormHelperText className="!text-[13px] !text-red-500 lowercase !font-inter first-letter:uppercase !mx-0 !mt-0 leading-[20px]">
          {props?.errormsg}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default GRSelect

export const AutocompleteInputCss = {
  "& .MuiInputBase-root": {
    height: "40px",
    borderRadius: "8px",
    padding: "1px 6px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: false ? "#FFCAC7 !important" : "#D4D4D4",
    },
  },
  "& .MuiFormHelperText-root": {
    marginTop: "0px",
    marginLeft: "4px",
  },
}
