import AppContext from "../context/app-context/AppContext"
import { useContext, useEffect } from "react"

const useChatServices = () => {
  const { socket } = useContext(AppContext)

  const connectToChat = (userId: number) => {
    socket?.emit("new-user-add", userId)
  }

  const getConnectedUser = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    socket?.on("get-users", () => {})
  }

  const sendMessage1 = (message: any) => {
    socket?.emit("send-message", message)
  }

  const receiveMessage1 = (handleReceiveData: (data: any) => void) => {
    socket?.on("receive-message", handleReceiveData)
  }

  const unreadCountMessages = (handleReceiveData: (data: any) => void) => {
    socket?.on("unread-message-count", handleReceiveData)
  }

  const offReceiveMessage = () => {
    socket?.off("receive-message")
  }

  useEffect(() => {
    getConnectedUser()
  }, [])

  return {
    socket,
    connectToChat,
    sendMessage1,
    receiveMessage1,
    offReceiveMessage,
    unreadCountMessages,
  }
}

export default useChatServices
