import React from "react"
import Applicants from "./Applicant"
import { IOpportunitySummary } from "../../../modals/Opportunity"

function AllApplicantsList({ opportunity }: { opportunity?: IOpportunitySummary }) {
  return (
    <div className="font-inter flex flex-col p-[30px] ">
      <span className="font-bold text-[22px]">
        {opportunity?.applicants && opportunity?.applicants?.length > 1
          ? "Applicants"
          : "Applicant"}
      </span>
      <span className="text-gray-500 text-sm">
        Total {opportunity?.applicants?.length}{" "}
        {opportunity?.applicants && opportunity?.applicants?.length > 1
          ? "Applicants"
          : "Applicant"}{" "}
        for this job
      </span>
      <div className="flex flex-col mt-4">
        {opportunity?.applicants &&
          opportunity?.applicants?.map((applicant, index) => (
            <Applicants applicant={applicant} key={index} />
          ))}
      </div>
    </div>
  )
}

export default AllApplicantsList
