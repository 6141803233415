import React, { useContext, useEffect, useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import * as yup from "yup"
import GRTextfield from "../ui/GRTextfield"
import { Box, TextField } from "@mui/material"
import GRSelect from "../ui/GRSelect"
import { seasonIWishToPlayOptions } from "../../utils/Constants"
import GRDatePicker from "../ui/GRDatePicker"
import DialogHeader from "../dialogs/DialogHeader"
import AppContext from "../../context/app-context/AppContext"
import dayjs from "dayjs"
import { useConfigAPI } from "../../services/useConfigAPI"
import { IDropDownOptionsResponse } from "../../modals/Config"
import { useUserHook } from "../../hooks/useUserHook"
import { convertIntoDropDownOptions } from "../../utils/CommonUtils"

interface IEditPersonalInfoFormValues {
  aboutUs: string
  playerPosition_1: string
  playerPosition_2: string
  coachPosition: string
  seasonWishToPlay: string
  height: string
  weight: string
  dob: string | null
}

const initialValues = {
  aboutUs: "",
  // position: "",
  seasonWishToPlay: "",
  dob: "",
  playerPosition_1: "",
  playerPosition_2: "",
  coachPosition: "",
}
interface IGREditPersonalInfoPopupProps {
  isShowDialog: boolean
  onClose: () => void
}

const EditPersonalInfoPopup: React.FC<IGREditPersonalInfoPopupProps> = (props) => {
  // props
  const { isShowDialog, onClose } = props

  // States
  const [dropDownOptions, setDropDownOptions] = useState<IDropDownOptionsResponse>()

  // Hook
  const { updateUserProfile, isUserProfileLoading, isCoach, isParent, isAgent, isPlayer } =
    useUserHook()

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // APIs
  const { getDropDownOptions } = useConfigAPI()

  useEffect(() => {
    fetchConfig()
  }, [])

  const fetchConfig = async () => {
    try {
      const cr = await getDropDownOptions()
      setDropDownOptions(cr)
    } catch {}
  }

  const handleSave = async (values: IEditPersonalInfoFormValues) => {
    try {
      const payload = {
        ...values,
        dob: !isAgent && values?.dob ? dayjs(values?.dob)?.format("YYYY-MM-DD") : null,
        playerPosition_1:
          isPlayer || (isParent && values?.playerPosition_1) ? values?.playerPosition_1 : null,
        playerPosition_2:
          (isPlayer && values?.playerPosition_2) || (isParent && values?.playerPosition_2)
            ? values?.playerPosition_2
            : null,
        coachPosition: isCoach && values?.coachPosition ? values?.coachPosition : null,
        seasonWishToPlay: isCoach || isPlayer ? values?.seasonWishToPlay : null,
      }
      await updateUserProfile(payload)
      onClose()
    } catch {}
  }
  const parentValidationSchema = yup.object({
    aboutUs: yup
      .string()
      .max(2000, "Max. 2000 characters are allowed")
      .required("About me is required"),
    dob: yup
      .date()
      .typeError("Invalid Date")
      .required("Date of birth is required")
      .nullable()
      .test("age", "Age must be valid", function (value) {
        if (!value) return false
        const currentDate = new Date()
        const dob = new Date(value)
        const ageInMilliseconds = currentDate.getTime() - dob.getTime()
        const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000)
        if (loginAsProfileDetails?.isChild) {
          return (
            ageInYears <= 16 || this.createError({ message: "Child age must be smaller than 16" })
          )
        } else {
          return ageInYears >= 16 || this.createError({ message: "Age must be greater than 16" })
        }
      }),
    playerPosition_1: yup
      .string()
      .test("conditionalValidation", "Position 1 is required", function (value) {
        const user = loginAsProfileDetails
        if (user?.profileType !== "Parent") {
          if (!value) {
            return false
          }
        }
        return true
      }),
    playerPosition_2: yup
      .string()
      .test("conditionalValidation", "Position 2 is required", function (value) {
        const user = loginAsProfileDetails
        if (user?.profileType !== "Parent") {
          if (!value) {
            return true
          }
        }
        return true
      }),
  })

  const agentValidationSchema = yup.object({
    aboutUs: yup
      .string()
      .max(2000, "Max. 2000 characters are allowed")
      .required("About me is required"),
  })

  const commonValidationSchema = yup.object({
    height: yup
      .number()
      .typeError("Height must be a number")
      .min(1, "Height can't be zero or negative")
      .max(300, "Height can be 300 max."),
    weight: yup
      .number()
      .typeError("Weight must be a number")
      .min(1, "Weight can't be zero or negative")
      .max(300, "Weight can be 300 max."),
  })

  const validationSchema = yup.object({
    aboutUs: yup
      .string()
      .max(2000, "Max. 2000 characters are allowed")
      .required("About me is required"),
    dob: yup
      .date()
      .typeError("Invalid Date")
      .required("Date of birth is required")
      .nullable()
      .test("age", "Age must be valid", function (value) {
        if (!value) return false
        const currentDate = new Date()
        const dob = new Date(value)
        const ageInMilliseconds = currentDate.getTime() - dob.getTime()
        const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000)
        if (loginAsProfileDetails?.isChild) {
          return (
            ageInYears <= 16 || this.createError({ message: "Child age must be smaller than 16" })
          )
        } else {
          return ageInYears >= 16 || this.createError({ message: "Age must be greater than 16" })
        }
      }),
    coachPosition: yup
      .string()
      .test("conditionalValidation", "Position is required", function (value) {
        const user = loginAsProfileDetails
        if (user?.profileType === "Coach") {
          if (!value) {
            return false
          }
        }
        return true
      }),
    playerPosition_1: yup
      .string()
      .test("conditionalValidation", "Position 1 is required", function (value) {
        const user = loginAsProfileDetails
        if (user?.profileType === "Player") {
          if (!value) {
            return false
          }
        }
        return true
      }),
    playerPosition_2: yup
      .string()
      .test("conditionalValidation", "Position 2 is required", function (value) {
        const user = loginAsProfileDetails
        if (user?.profileType === "Player") {
          if (!value) {
            return true
          }
        }
        return true
      }),
    ...commonValidationSchema.fields,

    // Add conditional validation for height and weight
    height:
      isCoach || isParent || isAgent
        ? yup.mixed().notRequired()
        : commonValidationSchema.fields.height,
    weight:
      isCoach || isParent || isAgent
        ? yup.mixed().notRequired()
        : commonValidationSchema.fields.weight,
  })

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      height: String(loginAsProfileDetails?.height || ""),
      weight: String(loginAsProfileDetails?.weight || ""),
      dob: loginAsProfileDetails?.dob || "",
      seasonWishToPlay: loginAsProfileDetails?.seasonWishToPlay || "",
      aboutUs: loginAsProfileDetails?.aboutUs || "",
      playerPosition_1: String(loginAsProfileDetails?.playerPosition_1?.id || ""),
      playerPosition_2: String(loginAsProfileDetails?.playerPosition_2?.id || ""),
      coachPosition: String(loginAsProfileDetails?.coachPosition?.id || ""),
    },
    onSubmit: (values: IEditPersonalInfoFormValues) => handleSave(values),
    enableReinitialize: true,
    validationSchema: isParent
      ? parentValidationSchema
      : isAgent
      ? agentValidationSchema
      : validationSchema,
  })

  const body = (
    <div className="sm:mx-[32px] mx-[13px] mb-8 compeleteProfileForm ">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <div className="flex">
          <span className="text-red-600 text-lg -mt-2">*</span>

          <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
        </div>
        <div>
          <div className="text-sm  flex mb-1 block font-inter">
            About me<span className="text-red-600">*</span>
          </div>
          <div className="relative">
            <div className="absolute text-right text-gray-600 text-sm -top-6 right-0 font-inter">
              {formik.values.aboutUs?.length || 0} / 2000 characters
            </div>
            <TextField
              fullWidth
              multiline={true}
              minRows={3}
              id="aboutUs"
              name="aboutUs"
              placeholder="Enter about me"
              className="pt-5"
              value={formik.values.aboutUs}
              error={formik.touched.aboutUs && Boolean(formik.errors.aboutUs)}
              helperText={formik.touched.aboutUs && formik.errors.aboutUs}
              onChange={formik.handleChange}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "10px",
                },
              }}
            />
          </div>
        </div>
        {!isAgent ? (
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-4">
            <div className="w-full">
              <div className="text-sm  flex mb-1 block font-inter">
                Date of birth<span className="text-red-600">*</span>
              </div>
              <div>
                <GRDatePicker
                  className="w-full"
                  height="40px"
                  error={Boolean(formik.touched.dob) && Boolean(formik.errors.dob)}
                  slotProps={{
                    textField: {
                      error: Boolean(formik.touched.dob) && Boolean(formik.errors.dob),
                      helperText:
                        formik.touched.dob &&
                        (formik.errors?.dob?.substring(0, 20) === "dob must be a `date`" ? (
                          <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                            Invalid Date*
                          </span>
                        ) : (
                          <span className=" text-[13px] text-red-500 first-letter:uppercase font-inter">
                            {formik.errors.dob}
                          </span>
                        )),
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={dayjs(formik.values.dob)}
                  onChange={(value) => {
                    formik.setFieldValue("dob", value, true)
                  }}
                />
              </div>
            </div>
            {!isParent ? (
              <div className="w-full">
                <div className="text-sm  flex mb-1 block font-inter">Opportunities sought</div>
                <div>
                  <Box sx={{ width: "100%" }}>
                    <GRSelect
                      id="seasonWishToPlay"
                      defaultValue=""
                      placeholder="Select season"
                      name="seasonWishToPlay"
                      onChange={formik.handleChange}
                      value={formik.values.seasonWishToPlay}
                      error={
                        formik.touched.seasonWishToPlay && Boolean(formik.errors.seasonWishToPlay)
                      }
                      className="w-full"
                      options={seasonIWishToPlayOptions}
                      errormsg={
                        formik.touched.seasonWishToPlay && Boolean(formik.errors.seasonWishToPlay)
                          ? formik.errors.seasonWishToPlay
                          : ""
                      }
                    />
                  </Box>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {!isParent && !isAgent ? (
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-4">
            <div className="w-full">
              <div className="text-sm  flex mb-1 block font-inter">
                Height (cm){" "}
                {!(isCoach || isParent || isAgent) && <span className="text-red-600">*</span>}
              </div>
              <div>
                <GRTextfield
                  id="height"
                  type="number"
                  className="w-full"
                  placeholder="Enter height"
                  height="48px"
                  name="height"
                  onChange={formik.handleChange}
                  value={formik.values.height}
                  error={formik.touched.height && Boolean(formik.errors.height)}
                  helperText={<span> {formik.touched.height && formik.errors.height}</span>}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="text-sm  flex mb-1 block font-inter">
                Weight (kg){" "}
                {!(isCoach || isParent || isAgent) && <span className="text-red-600">*</span>}
              </div>
              <div>
                <GRTextfield
                  id="weight"
                  type="number"
                  className="w-full"
                  placeholder="Enter weight"
                  height="48px"
                  name="weight"
                  onChange={formik.handleChange}
                  value={formik.values.weight}
                  error={formik.touched.weight && Boolean(formik.errors.weight)}
                  helperText={<span> {formik.touched.weight && formik.errors.weight}</span>}
                />
              </div>
            </div>
          </div>
        ) : null}

        {isCoach && (
          <div className="w-full">
            <div className="text-sm  flex mb-1 block font-inter">
              Position {!isParent && <span className="text-red-600">*</span>}
            </div>
            <div>
              <Box sx={{ width: "100%" }}>
                <GRSelect
                  id="coachPosition"
                  name="coachPosition"
                  placeholder="Select position"
                  onChange={formik.handleChange}
                  value={formik.values.coachPosition}
                  error={formik.touched.coachPosition && Boolean(formik.errors.coachPosition)}
                  className={
                    formik.touched.coachPosition && Boolean(formik.errors.coachPosition)
                      ? "!border border-lightRed "
                      : ""
                  }
                  errormsg={
                    formik.touched.coachPosition && formik.errors.coachPosition
                      ? formik.errors.coachPosition
                      : ""
                  }
                  options={convertIntoDropDownOptions(dropDownOptions?.coachPosition)}
                />
              </Box>
            </div>
          </div>
        )}

        {!isAgent && !isCoach ? (
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-4">
            {/* player position 1 - for all except coach */}
            <div className="w-full">
              <div className="text-sm  flex mb-1 block font-inter">
                Position 1{!isParent && <span className="text-red-600">*</span>}
              </div>
              <div>
                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="playerPosition_1"
                    name="playerPosition_1"
                    placeholder="Select position1"
                    onChange={formik.handleChange}
                    value={formik.values.playerPosition_1}
                    error={
                      formik.touched.playerPosition_1 && Boolean(formik.errors.playerPosition_1)
                    }
                    className={
                      formik.touched.playerPosition_1 && Boolean(formik.errors.playerPosition_1)
                        ? "!border border-lightRed "
                        : ""
                    }
                    errormsg={
                      formik.touched.playerPosition_1 && formik.errors.playerPosition_1
                        ? formik.errors.playerPosition_1
                        : ""
                    }
                    options={
                      isCoach
                        ? convertIntoDropDownOptions(dropDownOptions?.coachPosition)
                        : convertIntoDropDownOptions(dropDownOptions?.playerPosition)
                    }
                  />
                </Box>
              </div>
            </div>
            {/* player position 2 - for all except coach */}
            {!isCoach ? (
              <div className="w-full">
                <div className="text-sm  flex mb-1 block font-inter">Position 2</div>
                <div>
                  <Box sx={{ width: "100%" }}>
                    <GRSelect
                      id="playerPosition_2"
                      defaultValue=""
                      placeholder="Select position2"
                      name="playerPosition_2"
                      onChange={formik.handleChange}
                      value={formik.values.playerPosition_2}
                      error={
                        formik.touched.playerPosition_2 && Boolean(formik.errors.playerPosition_2)
                      }
                      className={
                        formik.touched.playerPosition_2 && Boolean(formik.errors.playerPosition_2)
                          ? "!border border-lightRed"
                          : ""
                      }
                      errormsg={
                        formik.touched.playerPosition_2 && formik.errors.playerPosition_2
                          ? formik.errors.playerPosition_2
                          : ""
                      }
                      options={convertIntoDropDownOptions(dropDownOptions?.playerPosition)}
                    />
                  </Box>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="flex justify-between items-center mt-6">
          <GRButton
            onClick={onClose}
            label="Cancel"
            type="button"
            className="sm:!px-[75px] !px-[49px] w-[115px] font-inter"
          />

          <GRButton
            isSubmitting={isUserProfileLoading}
            label="Save"
            className="sm:!px-[75px] !px-[49px] w-[115px] font-inter"
          />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Personal info" onClose={onClose} />}
      />
    </>
  )
}

export default EditPersonalInfoPopup
