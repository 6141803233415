import React, { useContext } from "react"
import { GRDialog } from "../ui/GRDialog"
import GRTextfield from "../ui/GRTextfield"
import { IconButton, InputAdornment } from "@mui/material"
import FacebookIcon from "../../assets/svg/FacebookIcon"
import InstagramIcon from "../../assets/svg/InstagramIcon.svg"
import LinkedInIcon from "../../assets/svg/LinkedInIcon"
import TwitterIcon from "../../assets/svg/clubprofileicon/Twitter.svg"
import TiktokIcon from "../../assets/svg/Tiktok.svg"
import SnapChatIcon from "../../assets/svg/SnapChat.svg"
import GRButton from "../ui/GRButton"
import DialogHeader from "../dialogs/DialogHeader"
import { useFormik } from "formik"
import AppContext from "../../context/app-context/AppContext"
import { useUserHook } from "../../hooks/useUserHook"
import * as yup from "yup"

interface ISocialMediaPopupProps {
  open: boolean
  onClose: () => void
}

interface ISocialMediaPopupFormValues {
  linkedinLink: string
  facebookLink: string
  instagramLink: string
  twitterLink: string
  tiktokLink: string
  snapchatLink: string
}
const SocialMediaPopup: React.FC<ISocialMediaPopupProps> = ({ open, onClose }) => {
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // Hook
  const { updateUserProfile, isUserProfileLoading } = useUserHook()

  const handleSubmit = async (values: ISocialMediaPopupFormValues) => {
    try {
      await updateUserProfile(values)
      onClose()
    } catch {}
  }
  const validationSchema = yup.object({
    linkedinLink: yup
      .string()
      .notRequired()
      .matches(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        "Invalid LinkedIn URL",
      ),

    facebookLink: yup
      .string()
      .notRequired()
      .matches(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        "Invalid Facebook URL",
      ),
    instagramLink: yup
      .string()
      .notRequired()
      .matches(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        "Invalid Instagram URL",
      ),
    twitterLink: yup
      .string()
      .notRequired()
      .matches(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        "Invalid Twitter URL",
      ),
    snapchatLink: yup
      .string()
      .notRequired()
      .matches(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        "Invalid Snapchat URL",
      ),
    tiktokLink: yup
      .string()
      .notRequired()
      .matches(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        "Invalid TikTok URL",
      ),
  })

  const formik = useFormik({
    initialValues: {
      instagramLink: loginAsProfileDetails?.instagramLink || "",
      facebookLink: loginAsProfileDetails?.facebookLink || "",
      linkedinLink: loginAsProfileDetails?.linkedinLink || "",
      twitterLink: loginAsProfileDetails?.twitterLink || "",
      snapchatLink: loginAsProfileDetails?.snapchatLink || "",
      tiktokLink: loginAsProfileDetails?.tiktokLink || "",
    } as ISocialMediaPopupFormValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const body = (
    <form onSubmit={formik.handleSubmit} className="mx-4 md:mx-[32px] mb-8">
      {/* label and fields */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
        <div className="w-full mb-4">
          <div className="block text-sm leading-6 font-[400] font-inter mb-1">Facebook link</div>
          <div>
            <GRTextfield
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <FacebookIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              id="facebookLink"
              name="facebookLink"
              value={formik?.values?.facebookLink || ""}
              onChange={formik?.handleChange}
              error={formik?.touched?.facebookLink && Boolean(formik.errors.facebookLink)}
              helperText={formik?.touched?.facebookLink && formik?.errors?.facebookLink}
              placeholder="Enter Facebook link"
            />
          </div>
        </div>
        <div className="w-full mb-4">
          <div className="block text-sm leading-6 font-[400] font-inter mb-1">Instagram link</div>
          <div>
            <GRTextfield
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img src={InstagramIcon} alt="icon" />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              id="instagramLink"
              name="instagramLink"
              value={formik.values.instagramLink || ""}
              onChange={formik.handleChange}
              error={formik?.touched?.instagramLink && Boolean(formik.errors.instagramLink)}
              helperText={formik?.touched?.instagramLink && formik?.errors?.instagramLink}
              placeholder="Enter Instagram link"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
        <div className="w-full mb-4">
          <div className="block text-sm leading-6 font-[400] font-inter mb-1">Linkedin link</div>
          <div>
            <GRTextfield
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <LinkedInIcon width={27} height={27} />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              id="linkedinLink"
              name="linkedinLink"
              value={formik.values.linkedinLink || ""}
              onChange={formik.handleChange}
              error={formik?.touched?.linkedinLink && Boolean(formik.errors.linkedinLink)}
              helperText={formik?.touched?.linkedinLink && formik?.errors?.linkedinLink}
              placeholder="Enter Linkedin link"
            />
          </div>
        </div>
        <div className="w-full mb-4">
          <div className="block text-sm leading-6 font-[400] font-inter mb-1">Twitter link</div>
          <div>
            <GRTextfield
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img src={TwitterIcon} width={27} height={27} />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              id="twitterLink"
              name="twitterLink"
              value={formik.values.twitterLink || ""}
              onChange={formik.handleChange}
              error={formik?.touched?.twitterLink && Boolean(formik.errors.twitterLink)}
              helperText={formik?.touched?.twitterLink && formik?.errors?.twitterLink}
              placeholder="Enter twitter link"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
        <div className="w-full mb-4">
          <div className="block text-sm leading-6 font-[400] font-inter mb-1">Snapchat link</div>
          <div>
            <GRTextfield
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img src={SnapChatIcon} alt="icon" />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              id="snapchatLink"
              name="snapchatLink"
              value={formik.values.snapchatLink || ""}
              onChange={formik.handleChange}
              error={formik?.touched?.snapchatLink && Boolean(formik.errors.snapchatLink)}
              helperText={formik?.touched?.snapchatLink && formik?.errors?.snapchatLink}
              placeholder="Enter Snapchat link"
            />
          </div>
        </div>
        <div className="w-full mb-4">
          <div className="block text-sm leading-6 font-[400] font-inter mb-1">TikTok link</div>
          <div>
            <GRTextfield
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img src={TiktokIcon} width={24} height={24} />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              id="tiktokLink"
              name="tiktokLink"
              value={formik.values.tiktokLink || ""}
              onChange={formik.handleChange}
              error={formik?.touched?.tiktokLink && Boolean(formik.errors.tiktokLink)}
              helperText={formik?.touched?.tiktokLink && formik?.errors?.tiktokLink}
              placeholder="Enter tiktok link"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-4">
        <GRButton onClick={onClose} label="Cancel" type="button" className="w-[100px]" />
        <GRButton isSubmitting={isUserProfileLoading} label="Save" className="w-[100px]" />
      </div>
    </form>
  )

  return (
    <>
      <GRDialog
        open={open}
        onClose={onClose}
        classes={{ paper: "!max-w-[817px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Social network" onClose={onClose} />}
      />
    </>
  )
}

export default SocialMediaPopup
