import React, { useState, useEffect } from "react"
import { List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material"
import { useOpportunityAPI } from "../../../services/useOpportunityAPI"
import { useUserHook } from "../../../hooks/useUserHook"
interface IFilterProps {
  allChecked?: boolean
  options: { name: string; value: string; type: string; id: string }[]
  isShowAll: boolean
  setFilterParams?: any
  filterParams?: any
  index?: any
  setIndex?: any
  setOpportunities?: any
  getOpportunity?: any
}

const LocationFilter: React.FC<IFilterProps> = ({
  setOpportunities,
  allChecked,
  options,
  isShowAll,
  filterParams,
  setFilterParams,
  index,
  setIndex,
  getOpportunity,
}) => {
  const [checked, setChecked] = useState<string[]>([])

  const { getfilterOpportunity } = useOpportunityAPI()
  useEffect(() => {
    setChecked(allChecked ? options.map((option) => option.value) : [])
  }, [allChecked, options])

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }
  const { isLoginAsPlayer } = useUserHook()
  const visibleOptions = isShowAll ? options : options.slice(0, 4) // Show all options if showAll is true

  const handleChange =
    (value: string, name: string, type: string, id: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked
      const queryformat =
        type === "country"
          ? `filters[${type}][${index}]=${value}`
          : type === "position"
          ? isLoginAsPlayer
            ? `filters[playerPosition][id][${index}]=${id}`
            : `filters[coachPosition][id][${index}]=${id}`
          : `filters[${type}][id][${index}]=${id}`

      let updatedParams = []
      if (isChecked) {
        updatedParams = [...filterParams]
        updatedParams.push(queryformat)
      } else {
        console.log("else", filterParams.length)
        for (let i = 0; i < filterParams.length; i++) {
          if (filterParams[i].includes(value) || filterParams[i].includes(id)) {
            continue
          } else {
            updatedParams.push(filterParams[i])
          }
        }
      }
      setFilterParams(updatedParams)
      setIndex(updatedParams.length + 1)
      const apiFilterParams = updatedParams.join("&")
      console.log(apiFilterParams)

      // Call the API with apiFilterParams
      if (updatedParams.length === 0) {
        // All checkboxes are unchecked, call getOpportunity here
        getOpportunity()
      } else {
        getfilterOpportunity(apiFilterParams).then((response) => {
          console.log(response)
          setOpportunities(response.opportunity)
        })
      }
    }

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", padding: "0" }}>
      {visibleOptions.map((option) => {
        const { value, name, type, id } = option
        const labelId = `checkbox-list-label-${value}`

        return (
          <ListItem key={value} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={handleToggle(value)}
              dense
              className="!py-0 !px-2"
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  onChange={handleChange(value, name, type, id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  style={{
                    color: checked.indexOf(value) !== -1 ? "#9F0002" : undefined,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${name}`} />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

export default LocationFilter
