import React, { SVGProps } from "react"

const EditBtn = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={61}
      height={27}
      viewBox="0 0 61 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.504 7.838a.583.583 0 01.825 0l2.334 2.333a.583.583 0 010 .825l-7.584 7.583a.583.583 0 01-.412.171h-2.334a.583.583 0 01-.583-.583v-2.334c0-.154.062-.303.17-.412l5.834-5.833 1.75-1.75zm-1.337 2.987l-5.25 5.25v1.508h1.508l5.25-5.25-1.508-1.508zm2.333.684l.925-.926-1.508-1.508-.925.925 1.508 1.508zM30.057 18V9.273h5.267v.937h-4.21v2.95h3.937v.937h-3.937v2.966h4.278V18h-5.335zm9.507.136a2.563 2.563 0 01-1.445-.413c-.417-.278-.744-.67-.98-1.176-.236-.509-.354-1.11-.354-1.803 0-.687.118-1.284.354-1.79.236-.505.564-.896.985-1.171.42-.276.906-.414 1.457-.414.426 0 .763.071 1.01.213.25.14.44.299.57.478.134.176.238.32.312.434h.085V9.273h1.006V18h-.972v-1.006h-.119c-.074.12-.179.27-.315.452-.137.179-.331.34-.584.482-.253.139-.59.208-1.01.208zm.136-.903c.404 0 .745-.105 1.023-.315.278-.213.49-.508.635-.883a3.64 3.64 0 00.217-1.308c0-.488-.07-.916-.213-1.282a1.867 1.867 0 00-.63-.861c-.279-.208-.623-.311-1.032-.311-.426 0-.781.11-1.065.328-.281.216-.493.51-.635.882-.14.37-.209.784-.209 1.244 0 .466.071.89.213 1.27.145.378.358.68.64.904.284.221.636.332 1.056.332zm4.845.767v-6.546h1.006V18h-1.006zm.512-7.636a.715.715 0 01-.507-.2.644.644 0 01-.21-.482c0-.188.07-.348.21-.482a.715.715 0 01.507-.2.7.7 0 01.503.2.638.638 0 01.213.482.638.638 0 01-.213.481.7.7 0 01-.503.2zm5.183 1.09v.853h-3.392v-.853h3.392zm-2.404-1.568h1.006v6.239c0 .284.041.497.124.64a.64.64 0 00.323.28c.134.046.275.069.422.069.111 0 .202-.006.273-.017l.17-.035.205.904a2.046 2.046 0 01-.285.077 2.103 2.103 0 01-.465.042c-.284 0-.563-.06-.835-.183a1.66 1.66 0 01-.674-.558c-.176-.25-.264-.566-.264-.946V9.886z"
        fill="#9F0002"
      />
      <rect x={0.5} y={1} width={60} height={25} rx={12.5} stroke="#9F0002" />
    </svg>
  )
}

export default EditBtn
