/* eslint-disable react/react-in-jsx-scope */
import { Link } from "react-router-dom"
import { IClubDetails } from "../../modals/Club"
import MessageIcon from "../../assets/svg/MessageIcon"
import { AUTH_MESSAGE, AUTH_MY_PROFILE, AUTH_USER_PROFILE } from "../../routes/RouteConstants"
import LeftArrow from "../../assets/svg/LeftArrow"
import { getNameInitials } from "../../utils/CommonUtils"
import { useContext } from "react"
import AppContext from "../../context/app-context/AppContext"

interface IClubFollowersData {
  clubData?: IClubDetails
}
const ClubFollowers = (props: IClubFollowersData) => {
  // Props
  const { clubData } = props

  const memeberFollowing = clubData?.attributes?.members?.map((c) => ({ ...c, type: "Member" }))
  const userFollowing = clubData?.attributes?.followers?.map((u) => ({ ...u, type: "Follower" }))

  const following =
    memeberFollowing && userFollowing ? [...memeberFollowing, ...userFollowing] : undefined

  const { loginAsProfileDetails } = useContext(AppContext)

  return (
    <>
      <div className="flex flex-col  bg-white rounded-t-[16px]  px-6  py-4  ">
        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center  gap-3 font-inter text-[24px] text-gr-primaryN1  font-semibold ">
            <Link to={AUTH_MY_PROFILE}>
              <LeftArrow />
            </Link>
            {`Followers (${following?.length || 0})`}
          </div>
        </div>

        <div className="border border-gray-200 mt-4 mb-5"></div>
        {following?.map((el) => (
          <div
            key={el?.id}
            className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5`}
          >
            <div className="flex  gap-4 items-center">
              {el?.profilePicture?.formats?.thumbnail?.url ? (
                <div>
                  <Link to={`${AUTH_USER_PROFILE}/${el?.id}`}>
                    <img
                      src={el?.profilePicture?.formats?.thumbnail?.url}
                      alt="profile-image"
                      className="rounded-full w-[48px] h-[48px]"
                    />
                  </Link>
                </div>
              ) : (
                <div className="flex items-center justify-center w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                  <span className="text-[16px] sm:text-[18px] text-gr-primaryR2 font-semibold">
                    <Link to={`${AUTH_USER_PROFILE}/${el?.id}`}>
                      {getNameInitials(el?.firstname, el?.lastname)}
                    </Link>
                  </span>
                </div>
              )}
              <div>
                <p className="font-inter text-[14px] leading-[17px] text-gr-primaryN1 font-semibold mb-[3px] hover:underline">
                  <Link to={`${AUTH_USER_PROFILE}/${el?.id}`}>
                    {el?.firstname} {el?.lastname}
                  </Link>
                </p>
                <p className="font-inter text-[12px] leading-[15px] text-gr-neutralT3 font-normal">
                  {el?.profileType}
                </p>
              </div>
            </div>
            {loginAsProfileDetails?.club?.id === clubData?.id && (
              <div className="cursor-pointer">
                <Link to={`${AUTH_MESSAGE}?chatwith=${el?.id}`}>
                  <MessageIcon />
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default ClubFollowers
