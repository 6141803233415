import React, { SVGProps } from "react"

const LocationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.375 8.708a7.125 7.125 0 0114.25 0M2.375 8.708c0 4.83 5.352 8.709 7.125 8.709 1.773 0 7.125-3.88 7.125-8.709"
        stroke="rgba(76, 83, 93, 1)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 11.875a3.167 3.167 0 100-6.333 3.167 3.167 0 000 6.333z"
        stroke="rgba(76, 83, 93, 1)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LocationIcon
