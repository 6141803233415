import { IUserDetailsSearch } from "@App/modals/User"
import React, { useContext, useEffect, useState } from "react"
import { AUTH_USER_PROFILE } from "../../routes/RouteConstants"
import { Link } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { CircularProgress } from "@mui/material"
import { useUserHook } from "../../hooks/useUserHook"
import { getNameInitials } from "../../utils/CommonUtils"
import { useLoginUserAPI } from "../../services/useLoginUserAPI"
import { IUnfollowUserPayload, useUsersAPI } from "../../services/useUsersAPI"
import AppContext from "../../context/app-context/AppContext"

interface IPeopleProps {
  isShowSeeAllLink?: boolean
  handleSeeAll?: () => void
  user?: IUserDetailsSearch[]
  handleMore?: () => void
  hasMore?: boolean
}

const People = (props: IPeopleProps) => {
  const { isShowSeeAllLink, handleSeeAll, user, handleMore, hasMore } = props
  const handleScroll = () => {
    const scrollableDiv = document.getElementById("scrollableDiv")
    if (scrollableDiv) {
      const scrollPosition = scrollableDiv.scrollTop
      const totalHeight = scrollableDiv.scrollHeight
      const clientHeight = scrollableDiv.clientHeight
      if (totalHeight - (scrollPosition + clientHeight) < 100) {
        handleMore?.()
      }
    }
  }

  return (
    <div className="bg-white rounded-2xl">
      <div
        className="w-full pt-[12px] md:px-[24px] px-4 max-h-[600px] h-full overflow-y-auto"
        id="scrollableDiv"
        onScroll={handleScroll}
      >
        <div className="pt-5 text-[20px] font-inter font-bold mb-[19px] ml-[24px] md:ml-[16px] ">
          People
        </div>
        <InfiniteScroll
          dataLength={user?.length ?? 0}
          next={handleMore as any}
          hasMore={hasMore || false}
          loader={
            !isShowSeeAllLink && (
              <div className="flex justify-center items-center h-14">
                <p className="flex justify-center items-center">
                  Loading
                  <span className="ml-2 flex justify-center items-center">
                    <CircularProgress size={15} style={{ color: "#000" }} />
                  </span>
                </p>
              </div>
            )
          }
        >
          <div className="px-4 mb-4">
            {user?.map((el: IUserDetailsSearch, indx: number) => (
              <Card
                user={el}
                border={indx == 3 ? "border-gr-primaryR8" : "border-gr-primaryR8"}
                key={indx}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
      {isShowSeeAllLink && (
        <div>
          <div className="flex justify-center items-center py-[12px] mb-4">
            <button
              onClick={() => handleSeeAll?.()}
              className="font-inter text-gr-primaryR2 text-lg font-normal mb-3 hover:underline"
            >
              See all People
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default People

interface ICard {
  border: string
  user: IUserDetailsSearch
}
const Card: React.FC<ICard> = ({ border, user }) => {
  const [isAlreadyFollowing, setIsAlreadyFollowing] = useState<boolean>(false)
  const { currentLoginProfile } = useContext(AppContext)

  //API
  const { followUser } = useLoginUserAPI()
  const { unfollowUser } = useUsersAPI()

  const { isAlreadyFollowingUser } = useUserHook()

  useEffect(() => {
    const isAlreadyFollowing = isAlreadyFollowingUser(Number(user.id))
    setIsAlreadyFollowing(isAlreadyFollowing)
  }, [])

  const handleFollowUser = async (userId: number) => {
    try {
      const payload = {
        data: { id: userId },
      }
      await followUser(payload)
      setIsAlreadyFollowing(!isAlreadyFollowing)
    } catch {}
  }
  const handleUnFollow = async (userId: number) => {
    if (!userId) return
    try {
      const payload: IUnfollowUserPayload = {
        data: {
          id: userId.toString(),
        },
      }
      await unfollowUser(payload)
      setIsAlreadyFollowing(!isAlreadyFollowing)
    } catch {}
  }
  return (
    <div className="">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[15px] ">
          <Link to={`${AUTH_USER_PROFILE}/${user?.id}`}>
            {user?.profilePicture?.url ? (
              <div>
                <img
                  src={user?.profilePicture?.url}
                  alt="ProfilePic"
                  className="w-[54px] h-[54px] rounded-full"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center w-[54px] h-[54px] bg-gr-primaryR1 rounded-full">
                <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                  {getNameInitials(user?.firstname, user?.lastname)}
                </span>
              </div>
            )}
          </Link>

          <div>
            <Link to={`${AUTH_USER_PROFILE}/${user?.id}`}>
              <div className="text-sm font-inter font-bold mb-1 hover:underline">{user?.name}</div>
            </Link>
            <div className="text-[10px] font-inter text-gr-neutralT3">{user.profileType}</div>
          </div>
        </div>
        {currentLoginProfile?.profileType !== "Club" &&
          currentLoginProfile?.profileId !== user?.id && (
            <div className="z-30 flex justify-end md:justify-center items-center pt-2">
              {isAlreadyFollowing ? (
                <div
                  className="mb-2 z-40 w-[96px] text-[12px] md:w-[100px] cursor-pointer h-[30px] text-red-darkRed bg-red-secondaryRed  rounded-2xl flex items-center justify-center hover:border-gr-primaryR2"
                  onClick={() => handleUnFollow(user?.id)}
                >
                  Unfollow
                </div>
              ) : (
                <div
                  onClick={() => handleFollowUser(user?.id)}
                  className="mb-2 z-40 w-[96px] text-[12px] md:w-[100px] cursor-pointer h-[30px] text-red-darkRed bg-red-secondaryRed  rounded-2xl flex items-center justify-center hover:border hover:border-gr-primaryR2"
                >
                  Follow
                </div>
              )}
            </div>
          )}
      </div>
      <div className={`border-b  mt-[13px] mb-[15px] ml-[33px] ${border}`}></div>
    </div>
  )
}
