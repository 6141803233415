import CrossIcon from "../../assets/svg/CrossIcon"
import React from "react"

const SocialMediaPostShareButtonDialogHeader = ({
  onClose,
  title,
}: {
  onClose: () => void
  title: string
}) => {
  return (
    <div className=" sm:mx-[32px] mx-[13px] flex justify-between items-center border-b border-b-gr-primaryN8 ">
      <p className="font-microsoftTai leading-6 text-black mt-7 pb-2">{title}</p>
      <div
        className="text-2xl leading-10 font-bold font-inter text-gr-primaryR3 cursor-pointer mt-2"
        onClick={() => onClose()}
      >
        <CrossIcon />
      </div>
    </div>
  )
}

export default SocialMediaPostShareButtonDialogHeader
