import React from "react"
import GlobalRugbyLogo from "../../assets/svg/GlobalRugbyLogo"
import GRTextfield from "../ui/GRTextfield"
import GRButton from "../ui/GRButton"
import * as Yup from "yup"
import { useFormik } from "formik"
import { UNAUTH_LOGIN } from "../../routes/RouteConstants"
import { useLocation } from "react-router"
import { IResetPasswordPayload, ISetNewPasswordPaylod, useAuthAPI } from "../../services/useAuthAPI"
import { errorToast } from "../../utils/ToastUtils"
import { Link, useSearchParams } from "react-router-dom"
import { useUserHook } from "../../hooks/useUserHook"

interface IStepNewPasswordFormValues {
  password: string
  confirmPassword: string
}
interface IStepNewPasswordProps {
  onPasswordResetSuccess: () => void
}

const initialValues: IStepNewPasswordFormValues = {
  password: "",
  confirmPassword: "",
}

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must be atleast 8 characters*")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&!*()_+])[A-Za-z\d@#$%^&!*()_+]+$/,
      "Password must include alphabets, numbers and special characters*",
    )
    .required("Enter New password"),
  confirmPassword: Yup.string()
    .required("Enter confirm password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
})

function StepSetNewPassword({ onPasswordResetSuccess }: IStepNewPasswordProps) {
  // Hooks
  const { search } = useLocation()
  const [searchParams] = useSearchParams()
  const referralCode = searchParams.get("refCode")

  // APIs
  const { resetPassword, isLoading, setNewPassword, updateUserStatus, isValidateSamePassword } =
    useAuthAPI()
  // Hook
  const {
    isLoginAsClub,
    isLoginAsAgent,
    isLoginAsChild,
    isLoginAsParent,
    isLoginAsCoach,
    isLoginAsPlayer,
  } = useUserHook()

  // Constants
  const query = new URLSearchParams(search)
  const code = query.get("code")

  const handleResetPassword = async (values: IStepNewPasswordFormValues) => {
    try {
      if (code) {
        const payload: IResetPasswordPayload = {
          password: values?.password,
          passwordConfirmation: values?.confirmPassword,
          code,
        }
        const isSamePassword = await isValidateSamePassword(values?.password, code)
        if (isSamePassword?.data) {
          errorToast("Old password and new password cannot be same")
        } else {
          const rpr = await resetPassword(payload)
          try {
            if (referralCode) {
              await updateUserStatus({ data: { referralCode, referredUser: rpr?.user?.id } })
            }
            if (rpr) onPasswordResetSuccess()
          } catch {}
        }
      } else {
        const payload: ISetNewPasswordPaylod = {
          data: {
            password: values?.password,
            passwordConfirmation: values?.confirmPassword,
          },
        }
        if (
          isLoginAsAgent ||
          isLoginAsParent ||
          isLoginAsChild ||
          isLoginAsClub ||
          isLoginAsCoach ||
          isLoginAsPlayer
        ) {
          const rpr = await setNewPassword(payload)
          if (rpr) onPasswordResetSuccess()
        } else {
          errorToast("Something went wrong")
        }
      }
    } catch {}
  }

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleResetPassword,
  })

  return (
    <>
      <div className=" flex  justify-center gap-[10px]">
        <div className="w-full sm:w-[530px]  mt-[80px] bg-white flex flex-col items-center rounded-lg shadow-lg px-[69px] py-4  ">
          <GlobalRugbyLogo />
          <div className=" w-full flex flex-col text-center">
            <span className="text-xl md:text-[32px] font-microsoftTai mb-3 font-bold mt-2  ">
              Set new password
            </span>
            <span className="mb-4">Must be at least 8 characters</span>
          </div>
          <form className="w-full compeleteProfileForm">
            <div className="h-[92px]">
              <label className="block  text-[14px] mb-1" htmlFor="password">
                Password
              </label>
              <GRTextfield
                name="password"
                type="password"
                width="100%"
                value={values.password}
                onChange={handleChange}
                helperText={touched.password && errors.password}
                error={touched.password && Boolean(errors.password)}
              />
            </div>

            <div className="mb-3 h-[92px]">
              <label className="block  text-[14px] mb-1" htmlFor="password">
                Confirm password
              </label>
              <GRTextfield
                name="confirmPassword"
                type="password"
                width="100%"
                value={values.confirmPassword}
                onChange={handleChange}
                helperText={touched.confirmPassword && errors.confirmPassword}
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              />
            </div>

            <div className=" flex justify-center mt-2">
              <GRButton
                isSubmitting={isLoading}
                onClick={handleSubmit}
                size="md"
                label="Change password"
                width="100%"
                className="!bg-primaryR2"
              />
            </div>
          </form>
          <Link to={UNAUTH_LOGIN} className="mt-4 underline hover:text-gr-primaryR2">
            {isLoginAsAgent ||
            isLoginAsParent ||
            isLoginAsChild ||
            isLoginAsClub ||
            isLoginAsCoach ||
            isLoginAsPlayer
              ? "Back to dashboard"
              : " Back to login"}
          </Link>
        </div>
      </div>
    </>
  )
}

export default StepSetNewPassword
