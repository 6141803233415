import React, { useContext, useEffect, useState } from "react"
import {
  AUTH_MY_PROFILE,
  AUTH_AGENT_PROFILE,
  AUTH_DASHBOARD,
  AUTH_AGENT_DASHBOARD,
  AUTH_FOLLOWERS,
  AUTH_FOLLOWINGS,
} from "../../routes/RouteConstants"
import { Link } from "react-router-dom"
import { useUserHook } from "../../hooks/useUserHook"
import AppContext from "../../context/app-context/AppContext"
import { getNameInitials } from "../../utils/CommonUtils"
import UpdateChildEmail from "../../components/dialogs/UpdateChildEmail"
import dayjs from "dayjs"

function UserProfile() {
  const isPlayerDashboaard = location.pathname === AUTH_DASHBOARD
  const isAgentDashboard = location.pathname === AUTH_AGENT_DASHBOARD
  const showProgress = 100

  //Hook
  const { isPlayer, isCoach, isParent, isLoginAsChild, fetchLoginAsUserProfile } = useUserHook()
  const [isShowUpdateChildEmail, setIsShowUpdateChildEmail] = useState<boolean>(false)
  //API
  const { loginAsProfileDetails } = useContext(AppContext)

  useEffect(() => {
    const age = loginAsProfileDetails?.dob ? dayjs().diff(loginAsProfileDetails?.dob, "years") : 0
    if (loginAsProfileDetails?.isChild && age > 16 && !loginAsProfileDetails?.isUpdateChildEmail) {
      setIsShowUpdateChildEmail(true)
    }
    fetchLoginAsUserProfile()
  }, [])

  return (
    <div className="flex flex-col  h-[280px] bg-white rounded-2xl">
      <div className="bg-gr-userProfile1 h-[105px] relative rounded-[16px_16px_0px_0px]">
        <div className="flex justify-center items-center bg-gr-primaryR1 border-[1px] border-white min-w-[100px] h-[28px] rounded-[30px] mt-[10px] absolute right-[10px]">
          <span
            className={` ${
              showProgress < 100 ? "ml-[10px]" : ""
            } text-[12px] text-gr-primaryR2 font-inter font-semibold `}
          >
            <Link
              to={isAgentDashboard ? AUTH_AGENT_PROFILE : AUTH_MY_PROFILE}
              className="hover:underline"
            >
              {showProgress < 100 ? "Complete Profile" : "View Profile"}
            </Link>
          </span>
        </div>
        {loginAsProfileDetails?.profilePicture?.url ? (
          <div className=" flex justify-center items-center rounded-full w-[89px] h-[89px] mt-[54px] bg-gr-primaryR1 absolute left-1/2 transform -translate-x-1/2 ">
            <Link to={isAgentDashboard ? AUTH_AGENT_PROFILE : AUTH_MY_PROFILE}>
              <img
                src={loginAsProfileDetails?.profilePicture?.url}
                className="w-[89px] h-[89px] rounded-full"
              />
            </Link>
          </div>
        ) : (
          <div className="flex justify-center items-center rounded-full w-[89px] h-[89px] mt-[54px] bg-gr-primaryR1 absolute left-1/2 transform -translate-x-1/2">
            <Link to={isAgentDashboard ? AUTH_AGENT_PROFILE : AUTH_MY_PROFILE}>
              <span className="text-2xl sm:text-4xl text-gr-primaryR2 font-semibold font-inter">
                {getNameInitials(loginAsProfileDetails?.firstname, loginAsProfileDetails?.lastname)}
              </span>
            </Link>
          </div>
        )}
        <div className="  mt-[150px]  flex flex-col items-center justify-center">
          <Link to={isAgentDashboard ? AUTH_AGENT_PROFILE : AUTH_MY_PROFILE}>
            <div className="">
              <span className="text-[18px] font-semibold font-inter hover:underline">
                {` ${loginAsProfileDetails?.firstname} ${loginAsProfileDetails?.lastname} `}
              </span>
            </div>
          </Link>
          <div className="w-[38px] h-[10px] mt-[1px] ">
            <span className="text-sm text-gr-neutralT3 font-inter">
              {isLoginAsChild
                ? "Child"
                : isPlayer
                ? "Player"
                : isCoach
                ? "Coach"
                : isParent
                ? "Parent"
                : "Agent"}
            </span>
          </div>
          {showProgress < 100 ? (
            <>
              <div className="mt-[30px] ml-[35px] text-[10px] text-gr-placeHolder font-microsoftTai">
                Your profile is{" "}
                <span>
                  <b>15%</b>{" "}
                </span>
                complete
              </div>
              <div className="w-[246px] bg-gray-200 rounded-full h-[8px] ml-[32px] mt-[4px]">
                <div
                  className="bg-gr-primaryR2 h-[8px] w-[246px] rounded-[20px] dark:bg-placeHolder"
                  style={{ width: "15%" }}
                ></div>
              </div>
            </>
          ) : (
            <div className="mt-[34px]"></div>
          )}

          <div className="flex  gap-6 items-center justify-center px-10">
            {isPlayerDashboaard ? null : (
              <div className="flex flex-col items-center">
                <p className="text-[12px] text-gr-placeHolder font-microsoftTai">Clubs</p>
                <p className="text-[20px] text-gr-textUserProfile4 font-semibold font-sans">28</p>
              </div>
            )}
            <div className="flex flex-col items-center">
              <p className="text-sm text-gr-placeHolder font-inter hover:underline">
                <Link to={AUTH_FOLLOWERS}>
                  {loginAsProfileDetails?.followers?.length &&
                  loginAsProfileDetails?.followers?.length > 1
                    ? "Followers"
                    : "Follower"}
                </Link>
              </p>
              <p className="text-[20px] text-gr-textUserProfile4 font-semibold font-sans hover:text-gr-primaryR2">
                <Link to={AUTH_FOLLOWERS}>{loginAsProfileDetails?.followers?.length}</Link>
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-sm text-gr-placeHolder font-inter hover:underline">
                <Link to={AUTH_FOLLOWINGS}>Following</Link>
              </p>
              <p className="text-[20px] text-gr-textUserProfile4 font-semibold font-sans hover:text-gr-primaryR2">
                <Link to={AUTH_FOLLOWINGS}>
                  {(loginAsProfileDetails?.clubFollowing?.length || 0) +
                    (loginAsProfileDetails?.following?.length || 0) +
                    (loginAsProfileDetails?.clubMember?.name ? 1 : 0)}{" "}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {isShowUpdateChildEmail && (
        <UpdateChildEmail
          isShowDialog={isShowUpdateChildEmail}
          onClose={() => setIsShowUpdateChildEmail(false)}
        />
      )}
    </div>
  )
}
export default UserProfile
