import React, { useContext, useEffect } from "react"
import { ReferredInfoCard } from "../agentProfile/ConnectPlayerCoach"
import { useLoginUserAPI } from "../../../services/useLoginUserAPI"
import AppContext from "../../../context/app-context/AppContext"

function Members() {
  const { setConnectedUser, connectedUser } = useContext(AppContext)
  //Hook
  const { getUsersByAgent } = useLoginUserAPI()
  const fectchConnectedUsers = async () => {
    try {
      const res = await getUsersByAgent()
      setConnectedUser(res)
    } catch {}
  }

  useEffect(() => {
    fectchConnectedUsers()
  }, [])
  return (
    <div className="bg-white rounded-2xl  min-w-[315px]  pt-5 pb-1">
      <div className="px-4 flex justify-between items-center mb-4">
        <p className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">
          {" "}
          Connected Members
        </p>
      </div>
      <div className="px-4 mb-4">
        <div className="flex flex-col gap-[17px] overflow-y-scroll">
          {connectedUser?.data?.map((user) => {
            return <ReferredInfoCard key={user?.id} userData={user} />
          })}
        </div>
      </div>
    </div>
  )
}
export default Members
