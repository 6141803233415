import React, { useContext, useState } from "react"
import AddBtn from "../../assets/svg/AddBtn"
import ConnectFamilyMemberDialog from "../dialogs/ConnectFamilyMemberDialog"
import ConnectAgentDialog from "../dialogs/ConnectAgentDialog"
import CreateFamilyMemberDialog from "../dialogs/AddFamilyMemberDialog"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { IConnectedAgent, IUser, IUserFamilyMember } from "../../modals/User"
import { Link, useParams } from "react-router-dom"
import { AUTH_USER_PROFILE } from "../../routes/RouteConstants"
import BinIcon from "../../assets/svg/BinIcon.svg"
import DeleteConnectionDialog from "../dialogs/DeleteConnectionDialog"
import { useLoginUserAPI } from "../../services/useLoginUserAPI"
import { getNameInitials } from "../../utils/CommonUtils"
import { useUserHook } from "../../hooks/useUserHook"
import { isEmpty } from "lodash"
import { successToast } from "../../utils/ToastUtils"
import AppContext from "../../context/app-context/AppContext"
import EditPenIcon from "../../assets/svg/EditPenIcon"

interface IOtherDetailsProps {
  canEdit?: boolean
  profileDetails?: IUser
}

const OtherDetail = (props: IOtherDetailsProps) => {
  // Props
  const { canEdit, profileDetails } = props

  // Hook
  const { isParent, fetchLoginAsUserProfile } = useUserHook()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // State
  const [isShowAgentDialog, setIsShowAgentDialog] = useState<boolean>(false)
  const [isShowAddFamilyMemberDialog, setIsShowAddFamilyMemberDialog] = useState<boolean>(false)
  const [isShowConnectFamilyMemberDialog, setIsShowConnectFamilyMemberDialog] =
    useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConnectFamilyMember = async () => {
    await fetchLoginAsUserProfile()
    setIsShowConnectFamilyMemberDialog(false)
  }

  const handleCreateFamilyMember = async () => {
    await fetchLoginAsUserProfile()
    setIsShowAddFamilyMemberDialog(false)
  }
  const handleConnectAgent = async () => {
    await fetchLoginAsUserProfile()
    setIsShowAgentDialog(false)
  }

  return (
    <div className="relative px-4 py-[30px] rounded-[16px] bg-white ">
      {/* heading and edit btn */}
      <div className="flex justify-between items-center">
        <div className="font-inter sm:text-2xl text-xl font-semibold">Connections</div>
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {canEdit ? <AddBtn /> : ""}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {!loginAsProfileDetails?.isChild && (
              <MenuItem onClick={() => setIsShowConnectFamilyMemberDialog(true)}>
                Connect with family member (current GR user)
              </MenuItem>
            )}

            {!loginAsProfileDetails?.isChild && (
              <MenuItem onClick={() => setIsShowAddFamilyMemberDialog(true)}>
                Add family member (new GR user)
              </MenuItem>
            )}
            {!isParent && isEmpty(profileDetails?.agent) ? (
              <MenuItem onClick={() => setIsShowAgentDialog(true)}>Add agent</MenuItem>
            ) : null}
          </Menu>
        </div>
      </div>

      <ConnectFamilyMemberDialog
        isShowDialog={isShowConnectFamilyMemberDialog}
        onSuccess={handleConnectFamilyMember}
        onClose={() => setIsShowConnectFamilyMemberDialog(false)}
      />
      <CreateFamilyMemberDialog
        isOpen={isShowAddFamilyMemberDialog}
        onSuccess={handleCreateFamilyMember}
        onClose={() => {
          setIsShowAddFamilyMemberDialog(false)
        }}
      />
      {isShowAgentDialog && (
        <ConnectAgentDialog
          isShowDialog={isShowAgentDialog}
          onClose={() => {
            setIsShowAgentDialog(false)
          }}
          onSuccess={handleConnectAgent}
        />
      )}

      <div className="border border-gray-200 mt-4 mb-5"></div>

      <div className=" ">
        {profileDetails?.member && profileDetails?.member.length > 0 && (
          <div className="font-inter font-semibold sm:text-xl text-lg mb-4">Family members</div>
        )}
        <div className="px-2 flex flex-col md:flex-row justify-center lg:justify-start gap-4 flex-wrap">
          {profileDetails?.member?.map((el, idx) => (
            <Card
              canEdit={canEdit}
              data={el}
              key={idx}
              isLoginUserChild={profileDetails?.id === loginAsProfileDetails?.id}
            />
          ))}
        </div>
        {!isParent && profileDetails?.agent ? (
          <>
            <div className="font-inter font-semibold text-[20px] mt-4 mb-4">Agent details</div>
            <div className="px-2 flex flex-col md:flex-row justify-center lg:justify-start gap-4 flex-wrap">
              <AgentCard agentDetails={profileDetails?.agent} />
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}
interface IGRCardProps {
  label?: string
  data?: IUserFamilyMember
  canEdit?: boolean
  isLoginUserChild: boolean
}

const Card: React.FC<IGRCardProps> = (props: IGRCardProps) => {
  // Props
  const { data, canEdit, isLoginUserChild } = props

  // State
  const [isShowDeleteConnectionDialog, setIsShowDeleteConnectionDialog] = useState<boolean>(false)

  // APIs
  const { deleteFamilyMembersById } = useLoginUserAPI()

  // Hook
  const { fetchLoginAsUserProfile, handleSwitchAccount } = useUserHook()
  const { primaryUserDetails } = useContext(AppContext)

  //Function
  const handleDelete = async (id: number) => {
    try {
      await deleteFamilyMembersById(id)
    } catch {}
  }

  const handleDeleteSuccess = async () => {
    await fetchLoginAsUserProfile()
    setIsShowDeleteConnectionDialog(true)
  }

  return (
    <div className="min-w-[16rem] h-[85px] flex justify-between items-center border border-gr-primaryR4  rounded-[6px] sm:px-5 px-4">
      <div className="flex w-full gap-2 justify-between items-center">
        <div className="flex w-full gap-2 items-center">
          {data?.childMember?.profilePicture?.formats?.thumbnail?.url && isLoginUserChild ? (
            <button
              type="button"
              onClick={() =>
                data?.childMember?.id &&
                handleSwitchAccount({
                  profileId: data?.childMember?.id,
                  profileType: "Child",
                })
              }
            >
              <img
                src={data?.childMember?.profilePicture?.formats?.thumbnail?.url}
                alt="logo"
                className="rounded-full w-[48px] h-[48px]"
              />
            </button>
          ) : data?.childMember?.profilePicture?.formats?.thumbnail?.url ? (
            <Link to={`${AUTH_USER_PROFILE}/${data?.childMember?.id}`}>
              <img
                src={data?.childMember?.profilePicture?.formats?.thumbnail?.url}
                alt="logo"
                className="rounded-full w-[48px] h-[48px]"
              />
            </Link>
          ) : !data?.isChild && data?.member?.profilePicture?.formats?.thumbnail?.url ? (
            <Link to={`${AUTH_USER_PROFILE}/${data?.member?.id}`}>
              <img
                src={data?.member?.profilePicture?.formats?.thumbnail?.url}
                alt="logo"
                className="rounded-full w-[48px] h-[48px]"
              />
            </Link>
          ) : (
            <div className="flex justify-center items-center w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
              <span className=" text-xl sm:text-xl text-white font-semibold">
                {data?.isChild && isLoginUserChild ? (
                  <button
                    type="button"
                    onClick={() =>
                      data?.childMember?.id &&
                      handleSwitchAccount({
                        profileId: data?.childMember?.id,
                        profileType: "Child",
                      })
                    }
                  >
                    {`${getNameInitials(
                      data?.childMember?.firstname,
                      data?.childMember?.lastname,
                    )}`}
                  </button>
                ) : data?.isChild ? (
                  <Link to={`${AUTH_USER_PROFILE}/${data?.childMember?.id}`}>
                    {`${getNameInitials(
                      data?.childMember?.firstname,
                      data?.childMember?.lastname,
                    )}`}
                  </Link>
                ) : (
                  <Link to={`${AUTH_USER_PROFILE}/${data?.member?.id}`}>
                    {`${getNameInitials(data?.member?.firstname, data?.member?.lastname)}`}
                  </Link>
                )}
              </span>
            </div>
          )}

          <div>
            <div className="text-sm font-inter font-semibold hover:underline">
              {data?.isChild && data?.childMember?.id && isLoginUserChild ? (
                <button
                  className="hover:underline"
                  type="button"
                  onClick={() =>
                    data?.childMember?.id &&
                    handleSwitchAccount({ profileId: data?.childMember?.id, profileType: "Child" })
                  }
                >
                  {data?.childMember?.firstname} {data?.childMember?.lastname} {props.label}
                </button>
              ) : data?.isChild && data?.childMember?.id ? (
                <Link to={`${AUTH_USER_PROFILE}/${data?.childMember?.id}`}>
                  {data?.childMember?.firstname} {data?.childMember?.lastname} {props.label}
                </Link>
              ) : (
                <Link to={`${AUTH_USER_PROFILE}/${data?.member?.id}`}>
                  {data?.member?.firstname} {data?.member?.lastname} {props.label}
                </Link>
              )}
            </div>
            <div className="text-xs text-gr-neutralT3">{data?.relation?.title}</div>
          </div>
        </div>
        {data?.childMember?.id && data?.isChild && canEdit && (
          <button
            type="button"
            onClick={() =>
              data?.childMember?.id &&
              handleSwitchAccount({ profileId: data?.childMember?.id, profileType: "Child" })
            }
            className="mr-3.5"
          >
            <EditPenIcon />
          </button>
        )}
      </div>
      <div className="flex items-center gap-[12px] cursor-pointer ">
        {data?.member?.id !== primaryUserDetails?.id && data?.member && canEdit && (
          <div onClick={handleDeleteSuccess}>
            <img src={BinIcon} alt="delete icon" width={20} />
          </div>
        )}
        <DeleteConnectionDialog
          isOpen={isShowDeleteConnectionDialog}
          onClose={() => {
            setIsShowDeleteConnectionDialog(false)
          }}
          onDelete={() => {
            data?.member?.id && handleDelete(data?.id)
            fetchLoginAsUserProfile()
          }}
          isAgent={false}
        />
      </div>
    </div>
  )
}

interface IGRAgentCardProps {
  agentDetails?: IConnectedAgent
}

const AgentCard: React.FC<IGRAgentCardProps> = (props: IGRAgentCardProps) => {
  const { agentDetails } = props
  //Hook
  const { userId } = useParams()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)
  const [isShowDeleteConnectionagentDialog, setIsShowDeleteConnectionagentDialog] =
    useState<boolean>(false)
  const { deletePlayerCoachConnection } = useLoginUserAPI()
  const { fetchLoginAsUserProfile } = useUserHook()

  const handleDelete = async (id: number) => {
    try {
      if (!agentDetails?.id) return
      await deletePlayerCoachConnection(id)
      successToast("Agent connection deleted successfully")
      fetchLoginAsUserProfile()
    } catch {}
  }
  return (
    <div className="min-w-[16rem] h-[85px] flex justify-between items-center border border-gr-primaryR4  rounded-[6px] sm:px-5 px-4">
      <div className="  flex gap-2 w-full items-center justify-center">
        <div className="flex w-full gap-2 items-center">
          {agentDetails?.profilePicture?.url ? (
            <Link to={`${AUTH_USER_PROFILE}/${agentDetails?.id}`}>
              <div>
                <img
                  src={agentDetails?.profilePicture?.url}
                  alt="logo"
                  className="rounded-full w-[48px] h-[48px]"
                />
              </div>
            </Link>
          ) : (
            <Link to={`${AUTH_USER_PROFILE}/${agentDetails?.id}`}>
              <div className="flex items-center justify-center rounded-full w-[48px] h-[48px] bg-gr-primaryR1">
                <span className="text-xl sm:text-xl text-white font-semibold">
                  {`${getNameInitials(agentDetails?.firstname, agentDetails?.lastname)}`}
                </span>
              </div>
            </Link>
          )}
          <div>
            <Link to={`${AUTH_USER_PROFILE}/${agentDetails?.id}`}>
              <div className="text-sm font-inter font-semibold hover:underline">
                {agentDetails?.firstname} {agentDetails?.lastname}
              </div>
            </Link>
            <div className="text-xs text-gr-neutralT3">{agentDetails?.profileType}</div>
          </div>
        </div>
      </div>
      {loginAsProfileDetails?.id === userId ||
        (!userId && (
          <div
            className="flex items-center gap-[12px] cursor-pointer mr-2.5 sm:mr-3"
            onClick={() => setIsShowDeleteConnectionagentDialog(true)}
          >
            <img src={BinIcon} alt="delete icon" width={19} />
          </div>
        ))}
      <DeleteConnectionDialog
        isOpen={isShowDeleteConnectionagentDialog}
        onClose={() => {
          setIsShowDeleteConnectionagentDialog(false)
        }}
        onDelete={() => {
          agentDetails?.id && handleDelete(agentDetails?.connectionId)
        }}
        isAgent={true}
      />
    </div>
  )
}
export default OtherDetail
