import React, { SVGProps } from "react"

const FilterIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.884 3.76v2.016c0 .733-.459 1.65-.917 2.108l-3.941 3.483c-.55.459-.917 1.375-.917 2.109v3.941c0 .55-.367 1.284-.825 1.559L11 19.8c-1.191.733-2.841-.092-2.841-1.559v-4.858c0-.642-.367-1.467-.734-1.925l-.43-.45a.912.912 0 01-.11-1.117l4.693-7.535a.915.915 0 01.78-.431h4.692c1.009 0 1.834.825 1.834 1.833zM9.488 3.328L6.234 8.544a.918.918 0 01-1.44.147l-.852-.899c-.458-.458-.825-1.283-.825-1.833V3.851c0-1.1.825-1.925 1.834-1.925h3.758a.92.92 0 01.779 1.402z"
        fill="#4C535D"
      />
    </svg>
  )
}

export default FilterIcon
