import {
  FilterableAttributes,
  MeiliSearch,
  SearchableAttributes,
  SortableAttributes,
} from "meilisearch"

const client = new MeiliSearch({
  host: "https://search.globalrugby.com/",
  //host: "http://52.62.165.78/",
  apiKey: "D2F8E596ED6C9D36962CE1B73272B",
})

const updateFilterableAttributes = async (indexName: string, filter: string[]) => {
  try {
    const index = client.index(indexName)
    // const settings = await index.getSettings();
    // const filterableAttributes = settings.filterableAttributes || [];
    // const searchableAttributes = settings.searchableAttributes || [];
    const filterableAttributes: FilterableAttributes = []
    const searchableAttributes: SearchableAttributes = []
    const sortableAttributes: SortableAttributes = []
    // const commonAttributes = ["state", "followers"]
    filter?.forEach((attr) => {
      if (!filterableAttributes.includes(attr)) {
        filterableAttributes.push(attr)
      }
    })
    await index.updateFilterableAttributes(filterableAttributes)
    // searchFilter.forEach((attr) => {
    ;["name"].forEach((attr) => {
      if (!searchableAttributes.includes(attr)) {
        searchableAttributes.push(attr)
      }
    })
    await index.updateSearchableAttributes(searchableAttributes)
    ;["_geo"].forEach((attr) => {
      if (!sortableAttributes.includes(attr)) {
        sortableAttributes.push(attr)
      }
    })
    await index.updateSortableAttributes(sortableAttributes)
  } catch (error) {
    console.error("Failed to update filterable attributes:", error)
  }
}

export const initializeMeilisearchSettings = () => {
  updateFilterableAttributes("user", ["state", "followers", "confirmed", "blocked"])
  updateFilterableAttributes("club", ["state", "followers"])
  updateFilterableAttributes("opportunity", ["club", "followers"])
}
