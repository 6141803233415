import React, { useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import CameraBtn from "../../assets/svg/CamerBtn.svg"
import BinIcon from "../../assets/svg/BinIcon.svg"
import DialogHeader from "../dialogs/DialogHeader"
import { IUploadFilesResponse } from "../../modals/Config"
import { useConfigAPI } from "../../services/useConfigAPI"
import GRButton from "../ui/GRButton"
import AvatarEditor from "react-avatar-editor"
import RotateLeft from "../../assets/svg/clubprofileicon/RotateLeft"
import RotateRight from "../../assets/svg/clubprofileicon/RotateRight"
import ZoomIn from "../../assets/svg/clubprofileicon/ZoomIn"
import ZoomOut from "../../assets/svg/clubprofileicon/ZoomOut"
import { imageExtensions } from "../../utils/Constants"

interface IGRProfileBannerImgPopupProps {
  isShowBannerImgDialog: boolean
  onClose: () => void
  onProfileUploadSuccess?: (uploadResponse: IUploadFilesResponse) => void
  onProfileDeleteSuccess?: () => void
  profilePicture?: IUploadFilesResponse
}

const ProfileBannerImgPopup: React.FC<IGRProfileBannerImgPopupProps> = (props) => {
  const {
    isShowBannerImgDialog,
    onClose,
    onProfileUploadSuccess,
    onProfileDeleteSuccess,
    profilePicture,
  } = props

  // State
  const [uploadedProfile, setUploadedProfile] = useState<File | undefined>(undefined)
  const [isDeleteProfilePicture, setIsDeleteProfilePicture] = useState(false)
  const [isSupportedImageType, setIsSupportedImageType] = useState(false)

  const [isEditing, setIsEditing] = useState(false)

  // Reference to the AvatarEditor component
  const [avatarEditor, setAvatarEditor] = useState<AvatarEditor | null>(null)

  // APIs
  const { uploadFiles, deleteFiles } = useConfigAPI()

  const handleDeleteFile = () => {
    setIsEditing(false)
    if (uploadedProfile) {
      setIsDeleteProfilePicture(false)
      setUploadedProfile(undefined)
    } else setIsDeleteProfilePicture(true)
  }

  const applyChanges = async () => {
    try {
      if (isEditing) {
        if (avatarEditor) {
          const canvas = avatarEditor.getImageScaledToCanvas()
          canvas.toBlob(async (blob) => {
            if (blob) {
              const formData = new FormData()
              formData.append("files", blob, uploadedProfile?.name)
              const uploadResponse = await uploadFiles(formData)
              onProfileUploadSuccess?.(uploadResponse?.[0])
            }
          })
        }
      } else if (uploadedProfile) {
        const formData = new FormData()
        formData.append("files", uploadedProfile, uploadedProfile?.name)
        const uploadResponse = await uploadFiles(formData)
        onProfileUploadSuccess?.(uploadResponse?.[0])
      } else if (profilePicture && isDeleteProfilePicture) {
        await deleteFiles(profilePicture.id)
        onProfileDeleteSuccess?.() // Optional: You can perform any necessary cleanup here
      }
      onClose()
    } catch (error) {
      console.error("Error applying changes:", error)
    }
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)

  const handleZoomIn = () => {
    setZoom(zoom * 1.1) // Increase zoom by 10%
  }

  const handleZoomOut = () => {
    setZoom(zoom * 0.9) // Decrease zoom by 10%
  }

  const handleRotateLeft = () => {
    setRotation(rotation - 90) // Rotate counterclockwise by 90 degrees
  }

  const handleRotateRight = () => {
    setRotation(rotation + 90) // Rotate clockwise by 90 degrees
  }

  const handleDialogClose = () => {
    // Reset state when the dialog is closed
    setUploadedProfile(undefined)
    setIsDeleteProfilePicture(false)

    onClose()
  }

  const onUploadedProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target?.files?.[0]
      if (file && !imageExtensions?.includes(file?.type)) {
        setIsSupportedImageType(true)
        return
      }
      setIsSupportedImageType(false)
      setUploadedProfile(e.target?.files?.[0])
    } catch (error) {}
  }

  const body = (
    <div className="mx-4 sm:mx-7 mb-8">
      {/* bannerImg */}
      <div>
        <div className="bg-white w-full flex justify-center mb-4">
          {isEditing && uploadedProfile ? (
            <AvatarEditor
              className="rounded-lg w-[100%] h-[100px]"
              ref={(editor) => setAvatarEditor(editor)}
              image={URL.createObjectURL(uploadedProfile)}
              width={700}
              borderRadius={0}
              height={250}
              color={[255, 255, 255, 0.6]}
              scale={zoom}
              rotate={rotation}
            />
          ) : (
            <div className="flex justify-center items-center w-[170px] h-[150px] max-[500px]:w-[100%] max-[640px]:w-[300px] sm:w-[100%] sm:h-[200px] md:w-[720px] rounded-[8px] md:h-[275px] bg-gr-primaryR1 mb-4">
              {profilePicture?.url && !isDeleteProfilePicture ? (
                <img
                  className="w-[175px] h-[150px] max-[500px]:w-[100%] rounded-[8px] max-[640px]:w-[300px] sm:w-[350px] sm:h-[200px] md:w-[720px] md:h-[275px] object-cover"
                  src={profilePicture?.url}
                  alt="clubLogo"
                />
              ) : (
                <div className="w-[88px] h-[88px] sm:w-[150px] sm:h-[150px] md:w-[227px] md:h-[227px]"></div>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        {isSupportedImageType && (
          <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
            Supported files include: images (jpg, jpeg, gif*, png, svg, webp)
          </span>
        )}
      </div>
      {isEditing && (
        <div className="flex justify-between mb-2">
          <div className="flex">
            <button
              className="text-red-darkRed text-[14px] font-inter mr-12"
              onClick={handleRotateLeft}
            >
              <RotateLeft />
            </button>
            <button className="text-red-darkRed text-[14px] font-inter" onClick={handleRotateRight}>
              <RotateRight />
            </button>
          </div>
          <div className="flex">
            <button
              className="text-red-darkRed text-[14px] font-inter mr-12"
              onClick={handleZoomIn}
            >
              <ZoomIn />
            </button>
            <button className="text-red-darkRed text-[14px] font-inter" onClick={handleZoomOut}>
              <ZoomOut />
            </button>
          </div>
        </div>
      )}
      {!isEditing && (
        <div className="text-sm text-gray-700 font-semibold">Recommended size - 1440 X 300</div>
      )}
      <div className="border-b border-gr-primaryN8"></div>

      <div className="flex justify-between items-center  mt-6">
        <GRButton onClick={onClose} label="Cancel" type="button" className="w-[6vw]" />
        <label htmlFor="file-upload" className="cursor-pointer">
          <img src={CameraBtn} alt="camera icon" width={54} onClick={handleEditClick} />
        </label>
        <input
          accept="image/*"
          className="hidden"
          name="profileImage"
          id="file-upload"
          type="file"
          onChange={(e) => onUploadedProfile(e)}
        />
        {(uploadedProfile || profilePicture) && (
          <div onClick={handleDeleteFile} className="flex items-center gap-[8px] cursor-pointer ">
            <div>
              <img src={BinIcon} alt="delete icon" className="sm:w-[16px] w-[20px]" />
            </div>
            <div className="mt-[4px] text-red-darkRed text-[13px] font-inter font-medium">
              Delete
            </div>
          </div>
        )}
        <GRButton label="Apply" className="!px-[35px] w-[5vw]" onClick={applyChanges} />
      </div>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowBannerImgDialog}
        onClose={handleDialogClose}
        classes={{ paper: "!max-w-[817px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader onClose={handleDialogClose} title="Add cover photo" />}
      />
    </>
  )
}

export default ProfileBannerImgPopup
