import React, { useState } from "react"
import ProfileImage from "../../../assets/svg/ProfileImage.svg"
import GotoIcon from "../../../assets/svg/GotoIcon"
import RecordHistoryDialog from "../../dialogs/RecordHistoryDialog"

const AgentClubs = () => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [showAll, setShowAll] = useState<boolean>(false)
  return (
    <div className="bg-white rounded-2xl  w-full sm:w-[270px] md:w-[315px] pt-5">
      <div className="px-4 flex justify-between items-center mb-4">
        <p className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">
          Clubs played for
        </p>
      </div>
      <RecordHistoryDialog
        onSuccess={() => setIsShowDialog(false)}
        isShowDialog={isShowDialog}
        onClose={() => setIsShowDialog(false)}
      />
      <div
        className={`px-4 mb-4 h-[261px]   ${showAll ? "overflow-y-scroll" : "overflow-y-hidden"}`}
      >
        {Array(5)
          .fill(1)
          ?.map((el, indx) => (
            <div
              key={indx}
              className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5 hover:bg-bgRed
               `}
            >
              <div className="flex justify-start gap-4 items-center">
                <div>
                  <img
                    src={ProfileImage}
                    alt="profile-image"
                    className="rounded-full max-w-[44px] max-h-[44px]"
                  />
                </div>
                <div>
                  <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px]">
                    Club Name
                  </p>
                  <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                    1,928 Members
                  </p>
                </div>
              </div>
              <div className="cursor-pointer">
                <GotoIcon />
              </div>
            </div>
          ))}
      </div>
      <div className="hidden lg:block border-b border-gr-primaryR4 "> </div>
      <div className="flex justify-center items-center py-[15px] pr-3">
        <button
          onClick={() => {
            setShowAll(true)
          }}
          className="font-inter text-gr-neutralT4 text-xs leading-4 underline"
        >
          Show All
        </button>
      </div>
    </div>
  )
}

export default AgentClubs
