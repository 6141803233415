import React from "react"
import RegisterForm from "../forms/RegisterForm"
import SSOLogin from "../loginRegistration/SSOLogin"

const Register = () => {
  return (
    <div className="flex justify-center py-10">
      <div className="flex flex-col items-center rounded-2xl px-[30px] w-full sm:w-[570px] sm:px-[60px] py-6 bg-white opacity-90">
        {/* heading */}
        <span className="text-xl sm:text-[32px] font-bold font-microsoftTai">
          Setup your Account
        </span>

        {/* form */}
        <RegisterForm />

        {/* continue with other options */}
        <SSOLogin isLogin={true} />
      </div>
    </div>
  )
}

export default Register
