import React, { ReactNode } from "react"
import Button from "@mui/material/Button"

// Define the props interface
interface IGRButtonProps {
  onClick?: () => void
  label?: string
  color?: string
  size?: "sm" | "md" | "lg"
  width?: string | number
  height?: number
  disabled?: boolean
  className?: string
  endIcon?: ReactNode
  fontFamily?: string
  startIcon?: ReactNode
  borderRadius?: string | number
  fontSize?: string
  isSubmitting?: boolean
  border?: string
  type?: "submit" | "button"
}
const GRButton: React.FC<IGRButtonProps> = ({
  onClick,
  label,
  color = "#9F0002",
  size = "14px",
  width,
  height = 45,
  disabled = false,
  className,
  endIcon,
  startIcon,
  borderRadius,
  fontSize,
  isSubmitting,
  border,
  type = "submit",
}) => {
  disabled = isSubmitting || disabled

  const buttonStyle: React.CSSProperties = {
    backgroundColor: disabled ? "#eee" : color,
    fontSize: fontSize ? fontSize : size === "sm" ? "14px" : size === "lg" ? "24px" : "16px",
    width: `${typeof width === "number" ? width + "px" : width} `,
    height: `${height}px`,
    borderRadius: borderRadius ? borderRadius : "8px",
    textTransform: "none",
    fontFamily: "inter",
    border: border ? border : "",
    boxShadow: "none",
  }

  return (
    <Button
      type={type}
      variant="contained"
      onClick={onClick}
      style={buttonStyle}
      disabled={disabled}
      className={className}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      <div>{label}</div>
    </Button>
  )
}

export default GRButton
