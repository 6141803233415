import React, { useContext, useState, useEffect } from "react"
import ThreeDotsHorizontal from "../../assets/svg/ThreeDotsHorizontal"
import LikeIcon from "../../assets/svg/LikeIcon"
import LikeRedIcon from "../../assets/svg/LikeRedIcon"
import ShareIcon from "../../assets/svg/ShareIcon"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { IPostSummary, IUpdatePost } from "../../modals/Post"
import EditPencilIcon from "../../assets/svg/clubprofileicon/EditPencilIcon"
import TrashIcon from "../../assets/svg/clubprofileicon/TrashIcon"
import DeletePost from "../club/clubprofile/postpopups/DeletePost"
import EditPost from "../club/clubprofile/postpopups/EditPost"
import AppContext from "../../context/app-context/AppContext"
import { useUserHook } from "../../hooks/useUserHook"
import { usePostAPI, IUpdatePostByClub, IUpdatePostByUser } from "../../services/usePostAPI"
import dayjs from "dayjs"
import { getNameInitials } from "../../utils/CommonUtils"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import { Link } from "react-router-dom"
import { AUTH_CLUB_PROFILE, AUTH_USER_PROFILE } from "../../routes/RouteConstants"
import PostShare from "../dialogs/PostShare"
import { usePostHook } from "../../hooks/usePostHook"

interface IPostProps {
  postData: IPostSummary | undefined
  onFetchPosts: () => void
  removePost: () => void
}

const Post = (props: IPostProps) => {
  const { onFetchPosts, removePost } = props
  const shareUrl = process.env.REACT_APP_SHARE_URL
  //State
  const [isDeletePostDialog, SetIsDeletePostDialog] = useState<boolean>(false)
  const [isEditPostDialog, SetIsEditPostDialog] = useState<boolean>(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [postData, setPostData] = React.useState<IPostSummary | undefined>(props?.postData)
  const [isShowShareButtonDilog, setisShowShareButtonDilog] = useState(false)
  const [postShareUrl, setPostShareUrl] = useState("")
  const [updatePostContent, setUpdatePostContent] = useState<IUpdatePost>()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)
  // Hook
  const { isLoginAsClub } = useUserHook()
  const { copyToClipBord } = usePostHook()

  //APIs
  const {
    postHideByUser,
    postHideByClub,
    postLikeByUser,
    postLikeByClub,
    postUnlikeByUser,
    postUnlikeByClub,
  } = usePostAPI()

  const clubId = loginAsProfileDetails?.club?.id

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const postContent = postData?.description

  // Function to convert URLs to links
  const convertUrlsToLinks = (content: string) => {
    const urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    return content.replace(
      urlRegex,
      (url) =>
        `<a href="${
          !/^https?:\/\//i.test(url) ? "https://" + url : url
        }" style="color:rgb(159, 0, 2); text-decoration: underline;" target="_blank">${url}</a>`,
    )
  }
  useEffect(() => {
    if (updatePostContent?.id && updatePostContent?.id === postData?.id) {
      setPostData(
        (postData) =>
          postData && {
            ...postData,
            postType: updatePostContent?.postType,
            description: updatePostContent?.description,
            media: updatePostContent?.media,
            preViewUrl: updatePostContent?.preViewUrl,
          },
      )
    }
  }, [updatePostContent])

  const onHide = (id: number | null | undefined) => {
    try {
      if (!id) return
      if (isLoginAsClub && clubId) {
        const postUpdatePayload: IUpdatePostByClub = {
          data: {
            id: id,
            clubId: clubId,
          },
        }
        postHideByClub(postUpdatePayload)
        onFetchPosts()
      } else {
        const postUpdatePayload: IUpdatePostByUser = {
          data: {
            id: id,
          },
        }
        postHideByUser(postUpdatePayload)
        onFetchPosts()
      }
    } catch (error) {}
  }
  const onLike = async (id: number | null | undefined) => {
    try {
      if (!id) return
      if (isLoginAsClub && clubId) {
        const postUpdatePayload: IUpdatePostByClub = {
          data: {
            id: id,
            clubId: clubId,
          },
        }
        const likeData = await postLikeByClub(postUpdatePayload)

        if (likeData?.data)
          setPostData(
            (postData) =>
              postData && { ...postData, isLike: true, likeCount: postData.likeCount + 1 },
          )
      } else {
        const postUpdatePayload: IUpdatePostByUser = {
          data: {
            id: id,
          },
        }
        const likeData = await postLikeByUser(postUpdatePayload)

        if (likeData?.data)
          setPostData(
            (postData) =>
              postData && { ...postData, isLike: true, likeCount: postData.likeCount + 1 },
          )
      }
    } catch (error) {}
  }

  const onUnLike = async (id: number | null | undefined) => {
    try {
      if (!id) return
      if (isLoginAsClub && clubId) {
        const postUpdatePayload: IUpdatePostByClub = {
          data: {
            id: id,
            clubId: clubId,
          },
        }
        const unLikeData = await postUnlikeByClub(postUpdatePayload)

        if (unLikeData?.data)
          setPostData(
            (postData) =>
              postData && { ...postData, isLike: false, likeCount: postData.likeCount - 1 },
          )
      } else {
        const postUpdatePayload: IUpdatePostByUser = {
          data: {
            id: id,
          },
        }
        const unLikeData = await postUnlikeByUser(postUpdatePayload)

        if (unLikeData?.data)
          setPostData(
            (postData) =>
              postData && { ...postData, isLike: false, likeCount: postData.likeCount - 1 },
          )
      }
    } catch (error) {}
  }
  const shareButtonOptions = async (copyMe: string) => {
    try {
      setPostShareUrl(copyMe)
      setisShowShareButtonDilog(true)
    } catch (error) {}
  }

  // scroll down smoothly
  useEffect(() => {
    const hash = window?.location?.hash
    if (hash) {
      const element = document.getElementById(hash)
      if (element) {
        element?.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  const [youtubeVideoId, setYoutubeVideoId] = useState("")

  useEffect(() => {
    if (postContent) {
      const youtubeRegex = /https:\/\/www.youtube.com\/watch\?v=([A-Za-z0-9_-]+)/
      const matches = postContent?.match(youtubeRegex)

      if (matches) {
        const videoId = matches[1]
        setYoutubeVideoId(videoId)
      }
    }
  }, [postContent])

  const days = dayjs().diff(postData?.createdAt, "days")
  const hours = dayjs().diff(postData?.createdAt, "hours")
  const minutes = dayjs().diff(postData?.createdAt, "minutes")
  let time = minutes ? `${minutes}m` : "Just now"
  if (minutes > 1440) {
    time = `${days}d`
  } else if (minutes > 60) {
    time = `${hours}h`
  }

  const getVisibleCharacterCount = (content: string) => {
    const plainTextContent = document.createElement("div")
    plainTextContent.innerHTML = content
    const cleanedContent = plainTextContent.innerText
    return cleanedContent.length
  }

  const isShowShowMoreBtn = getVisibleCharacterCount(postContent || "") > 250
  return (
    <div
      id={`#${postData?.id}`}
      className="px-4 bg-white  
        sm:mx-0 mt-[-1px] mb-4 rounded-2xl"
    >
      <div className="pt-5 sm:pl-7 mb-3 flex justify-between items-center lg:pl-3 lg:pr-2">
        <div className="flex justify-start gap-4 items-center mr-2">
          {!postData?.isPostByClub ? (
            postData?.user?.profilePicture?.formats?.thumbnail?.url ? (
              <Link to={`${AUTH_USER_PROFILE}/${postData?.user?.id}`}>
                <img
                  src={postData?.user?.profilePicture?.formats?.thumbnail?.url}
                  alt="profile-image"
                  className="w-[44px] h-[44px] rounded-full object-cover"
                />
              </Link>
            ) : (
              <Link to={`${AUTH_USER_PROFILE}/${postData?.user?.id}`}>
                <div className="flex justify-center items-center w-[44px] h-[44px] px-4 rounded-full bg-gr-primaryR1">
                  <span className="text-white font-semibold">
                    {getNameInitials(postData?.user?.firstname, postData?.user?.lastname)}
                  </span>
                </div>
              </Link>
            )
          ) : (
            <Link to={`${AUTH_CLUB_PROFILE}/${postData?.club?.id}`}>
              <img
                src={
                  postData?.club?.clubLogo?.formats?.thumbnail?.url
                    ? postData?.club?.clubLogo?.formats?.thumbnail?.url
                    : ClubLogo
                }
                alt="profile-image"
                className="w-[44px] h-[44px] rounded-full"
              />
            </Link>
          )}
          <div>
            <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px]">
              {postData?.isPostByClub ? (
                <Link
                  to={`${AUTH_CLUB_PROFILE}/${postData?.club?.id}`}
                >{`${postData?.club?.name}`}</Link>
              ) : (
                <Link to={`${AUTH_USER_PROFILE}/${postData?.user?.id}`} className="hover:underline">
                  {`${postData?.user?.firstname} ${postData?.user?.lastname}`}
                </Link>
              )}
            </p>
            <p className="flex gap-2 font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
              {postData?.isPostByClub ? "Club" : postData?.user?.profileType}{" "}
              <div className="border-r border-gr-primaryR4"></div>
              <div>{time}</div>
            </p>
          </div>
        </div>
        <div className="">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="!min-w-[32px] !hover:bg-gray-900 !p-2"
          >
            <ThreeDotsHorizontal />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(isLoginAsClub && postData?.club?.id === clubId) ||
            (!isLoginAsClub && loginAsProfileDetails?.id === postData?.user?.id) ? (
              <>
                <MenuItem
                  onClick={() => {
                    handleClose() // Close the menu
                    SetIsEditPostDialog(true) // Open the edit dialog
                  }}
                >
                  <div className="flex items-center gap-[14px]">
                    <div>
                      <EditPencilIcon size={15} />
                    </div>
                    <div>Edit</div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => SetIsDeletePostDialog(true)}>
                  <div className="flex items-center gap-[14px]">
                    <div>
                      <TrashIcon size={15} />
                    </div>
                    <div>Delete</div>
                  </div>
                </MenuItem>
              </>
            ) : (
              <MenuItem onClick={() => onHide && onHide(postData?.id)}>Hide Post</MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <div className="sm:px-4 mb-3 lg:pl-3 lg:pr-0">
        <p className="font-inter text-gr-neutralT2 text-xs leading-6 font-normal md:text-sm">
          {postContent ? (
            isShowMore ? (
              <span dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(postContent) }} />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: convertUrlsToLinks(postContent?.substring(0, 250)),
                }}
              />
            )
          ) : (
            ""
          )}
        </p>

        <div>
          <div style={{ marginBottom: "20px" }}>
            {youtubeVideoId && (
              <iframe
                width="600"
                className="post-image-cls"
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                title={"YouTube Video"}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
        </div>
        {isShowShowMoreBtn && (
          <div className="flex justify-end">
            <button
              onClick={() => setIsShowMore(!isShowMore)}
              className="font-inter text-gr-neutralT3 text-xs leading-6 font-normal text-end lg:text-xs cursor-pointer select-none"
            >
              {isShowMore ? "...see less" : "...see more"}
            </button>
          </div>
        )}
      </div>
      <div className="flex justify-center items-center mb-5 bg-light-red">
        {postData?.postType === "Image" && (postData?.media?.url || postData?.preViewUrl) ? (
          <img
            src={postData?.media?.url ? postData?.media?.url : postData?.preViewUrl}
            alt="post-img"
            className="post-image-cls object-contain"
          />
        ) : postData?.postType === "Video" && postData?.media?.url ? (
          <video
            controls
            muted={true}
            autoPlay={true}
            className="post-image-cls w-[600px] object-contain"
            src={postData?.media?.url}
          />
        ) : null}
      </div>
      <div className="flex justify-between items-center pb-5 px-3">
        <button className="flex justify-center items-center gap-2 cursor-pointer hover:bg-gray-100 p-1 rounded-lg px-2">
          {postData?.isLike ? (
            <span className="flex gap-2 items-end" onClick={() => onUnLike(postData?.id)}>
              <LikeRedIcon />
              <span className="text-gr-primaryN2 text-xs leading-[17px] font-semibold select-none">
                {postData?.likeCount}{" "}
                {postData?.likeCount && postData?.likeCount > 1 ? "Likes" : "Like"}
              </span>
            </span>
          ) : (
            <span className="flex gap-2 items-end" onClick={() => onLike(postData?.id)}>
              <LikeIcon />
              <span className="text-gr-primaryN2 text-xs leading-[17px] font-semibold select-none">
                {postData?.likeCount}{" "}
                {postData?.likeCount && postData?.likeCount > 1 ? "Likes" : "Like"}
              </span>
            </span>
          )}
        </button>
        <button
          className="hidden md:flex justify-center items-center gap-2 cursor-pointer hover:bg-gray-100 p-1 rounded-lg px-2"
          onClick={() => copyToClipBord(`${shareUrl}#${postData?.id}`)}
        >
          <ShareIcon />
          <span className="text-gr-primaryN2 text-xs leading-[17px] font-semibold select-none ">
            Share
          </span>
        </button>
        <button
          className="md:hidden flex /justify-center items-center gap-2 cursor-pointer"
          onClick={() => shareButtonOptions(`${shareUrl}#${postData?.id}`)}
        >
          <ShareIcon />
          <span className="text-gr-primaryN2 text-xs leading-[17px] font-semibold select-none">
            Share
          </span>
        </button>
      </div>
      {/* Add delete Post dialog */}
      <DeletePost
        isOpen={isDeletePostDialog}
        onClose={() => SetIsDeletePostDialog(false)}
        onDeletePost={() => removePost()}
      />
      <EditPost
        onFetchPosts={onFetchPosts}
        postData={postData}
        isOpen={isEditPostDialog}
        onClose={() => SetIsEditPostDialog(false)}
        setUpdatePostContent={setUpdatePostContent}
      />
      {isShowShareButtonDilog && (
        <PostShare
          isShowDialog={isShowShareButtonDilog}
          onClose={() => setisShowShareButtonDilog(false)}
          url={postShareUrl}
        />
      )}
    </div>
  )
}

export default Post
