import AppContextActions from "./AppContextActions"
import { IAppContextState } from "./AppContext"

export default (state: IAppContextState, action: { type: string; payload: any; options?: any }) => {
  switch (action.type) {
    case AppContextActions.SET_LOAD_APP:
      return {
        ...state,
        isAppLoading: action.payload,
      }
    case AppContextActions.SET_USER_DETAILS:
      return {
        ...state,
        primaryUserDetails: action.payload,
      }
    case AppContextActions.SET_DROPDOWN_OPTIONS:
      return {
        ...state,
        dropDownOptions: action.payload,
      }
    case AppContextActions.SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      }
    case AppContextActions.SET_LOGIN_AS_PROFILE_DETAILS:
      return {
        ...state,
        loginAsProfileDetails: action.payload,
      }
    case AppContextActions.SET_CURRENT_LOGIN_PROFILE:
      return {
        ...state,
        currentLoginProfile: action.payload,
      }
    case AppContextActions.SET_SOCKET:
      return {
        ...state,
        socket: action.payload,
      }
    case AppContextActions.SET_UNREAD_COUNT:
      return {
        ...state,
        unreadMessageCount: action.payload,
      }
    case AppContextActions.SET_CONNECTED_USER:
      return {
        ...state,
        connectedUser: action.payload,
      }
    default:
      return state
  }
}
