export enum GenderEnum {
  Male = "Male",
  Female = "Female",
}

export const genderOptions = Object.values(GenderEnum).map((gender) => ({
  name: gender,
  value: gender,
}))

export enum HearAboutUsEnum {
  SocialMedia = "Social Media",
  Referral = "Referral",
  Other = "Other",
}

export const hearAboutUsOptions = Object.values(HearAboutUsEnum).map((aboutUs) => ({
  name: aboutUs,
  value: aboutUs,
}))

export const clubs = [
  {
    id: 1,
    name: "The New Rugby Club",
    position: "Right Wing",
    season: "Professional",
    location: "Location",
  },
  {
    id: 2,
    name: "Club Name",
    position: "Position",
    season: "Season",
    location: "Location",
  },
  {
    id: 3,
    name: "Club Name",
    position: "Position",
    season: "Season",
    location: "Location",
  },
  {
    id: 4,
    name: "Club Name",
    position: "Position",
    season: "Season",
    location: "Location",
  },
]

export enum StatusEnum {
  Approve = "Approve",
  Reject = "Reject",
  Progress = "In Progress",
}

export const ApplicantStatus = Object.values(StatusEnum).map((status) => ({
  name: status,
  value: status,
}))

export enum opportunityStatusEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export const OpportunityStatus = Object.values(opportunityStatusEnum).map((status) => ({
  name: status,
  value: status,
}))

export enum FamilyMemberDialogEnum {
  BasicInfo = "Basic Info",
  RugbyExperience = "Rugby Experience",
}

export enum ProfileTypeEnum {
  Player = "Player",
  Coach = "Coach",
  Parent = "Parent",
  Agent = "Agent",
  Club = "Club",
  Child = "Child",
}

export const CountryArray = ["England", "Scotland", "Wales", "Northern Ireland"]

export const profileTypeOptions = Object.values({
  Player: ProfileTypeEnum?.Player,
  Coach: ProfileTypeEnum?.Coach,
  Parent: ProfileTypeEnum?.Parent,
  Agent: ProfileTypeEnum?.Agent,
}).map((profileType) => ({
  name: profileType,
  value: profileType,
}))

export const profileOptions = Object.values({
  Player: ProfileTypeEnum?.Player,
  Coach: ProfileTypeEnum?.Coach,
}).map((profileType) => ({
  name: profileType,
  value: profileType,
}))

export const connectPlayerCoachOptions = Object.values({
  Player: ProfileTypeEnum?.Player,
  Coach: ProfileTypeEnum?.Coach,
}).map((profileType) => ({
  name: profileType,
  value: profileType,
}))

export enum SeasonIWishToPlayEnum {
  NotCurrentlyLooking = "Not currently looking",
  Current = "Current season",
  Future = "Next season",
}

export const seasonIWishToPlayOptions = Object.values(SeasonIWishToPlayEnum).map((season) => ({
  name: season,
  value: season,
}))
export const urlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

export const imageExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/svg+xml",
  "image/webp",
]
