import React, { useState } from "react"
import GRTextfield from "../ui/GRTextfield"
import GRButton from "../ui/GRButton"
import { useFormik } from "formik"
import { Box, Checkbox, FormControl, FormHelperText, Popover, Typography } from "@mui/material"
import { useNavigate } from "react-router"
import { UNAUTH_TERMSPOLICY, UNAUTH_LOGIN } from "../../routes/RouteConstants"
import { registerFormValidationSchema as validationSchema } from "../../validations/Validations"
import GRSelect from "../ui/GRSelect"
import { profileTypeOptions } from "../../utils/Constants"
import { IRegisterUserPayload, useAuthAPI } from "../../services/useAuthAPI"
import { useLoginUserAPI } from "../../services/useLoginUserAPI"
import { Link, useSearchParams } from "react-router-dom"
import { successToast } from "../../utils/ToastUtils"
import CrossIcon from "../../assets/svg/CrossIcon"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"

interface IRegisterFormValues {
  firstName: string
  lastName: string
  email: string
  profileType: string
  password: string
  policy: boolean
}

// const initialValues = {
//   firstName: "",
//   lastName: "",
//   email: "",
//   password: "",
//   profileType: "",
//   policy: false,
// }

const RegisterForm = () => {
  // Hooks
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const referralCode = searchParams.get("referralCode")
  const email = searchParams.get("email")

  // APIs
  const { register, isLoading } = useAuthAPI()
  const { updateReferral } = useLoginUserAPI()

  const [initialValues] = useState<IRegisterFormValues>({
    firstName: "",
    lastName: "",
    email: email ? email : "",
    password: "",
    profileType: "",
    policy: false,
  })
  const handleSubmit = async (values: IRegisterFormValues) => {
    try {
      const payload: IRegisterUserPayload = {
        email: values.email?.toLowerCase(),
        firstname: values?.firstName,
        lastname: values?.lastName,
        password: values?.password,
        profileType: values.profileType,
        username: values.email?.toLowerCase(),
      }
      const rr = await register(payload)
      successToast("A verification link has been sent to your email account")
      try {
        if (referralCode) {
          await updateReferral({
            data: { referralCode, referredUser: String(rr?.user?.id), email: values.email },
          })
        }
      } catch {
        navigate(UNAUTH_LOGIN)
        return
      }
      navigate(UNAUTH_LOGIN)
    } catch {}
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  })

  const [messagePopoverAnchorEl, setMessagePopoverAnchorEl] = useState<null | HTMLElement>(null)

  // Handler function to toggle the message
  const toggleMessagePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagePopoverAnchorEl(messagePopoverAnchorEl ? null : event.currentTarget)
  }

  return (
    <div className="w-full">
      <form
        onSubmit={formik.handleSubmit}
        className=" w-full mt-6 flex flex-col  font-inter gap-[2px] compeleteProfileForm"
      >
        {/* first name  and last name */}
        <div className=" flex flex-col sm:gap-4 sm:flex-row sm:items-start">
          <div>
            <label
              className="cursor-pointer block text-[15px] leading-6 font-[400]"
              htmlFor="firstName"
            >
              First Name<span className=" text-red-500">*</span>
            </label>
            <GRTextfield
              id="firstName"
              type="text"
              width="100%"
              placeholder="Enter first name"
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </div>
          <div className="mt-3 sm:mt-0">
            <label
              className="cursor-pointer block text-[15px] leading-6 font-[400]"
              htmlFor="lastName"
            >
              Last Name<span className=" text-red-500">*</span>
            </label>

            <GRTextfield
              id="lastName"
              type="text"
              width="100%"
              placeholder="Enter last name"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </div>
        </div>

        {/* email address */}
        <div className="mt-3">
          <label className="cursor-pointer block text-[15px] leading-6 font-[400]" htmlFor="email">
            Email<span className=" text-red-500">*</span>
          </label>
          <GRTextfield
            id="email"
            type="email"
            width="100%"
            name="email"
            placeholder="Enter email id"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={email ? true : false}
          />
        </div>

        {/* profileType */}

        <div className="mt-3 mb-[2px]">
          <label
            className="cursor-pointer block text-[15px] leading-6 font-[400] flex"
            htmlFor="profileType"
          >
            Profile Type<span className=" text-red-500">*</span>
            <div
              className="mt-[5px] ml-[8px] cursor-pointer"
              onClick={toggleMessagePopover}
              onMouseEnter={toggleMessagePopover}
              onMouseLeave={() => setMessagePopoverAnchorEl(null)}
            >
              <ExclamtionIcon />
              <Popover
                open={Boolean(messagePopoverAnchorEl)}
                anchorEl={messagePopoverAnchorEl}
                onClose={() => setMessagePopoverAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  <div className="flex gap-4 rounded-lg">
                    <div className="flex flex-col gap-4">
                      <span>
                        Player: "Connect with clubs and coaches, showcase your<br></br>skills, and
                        explore playing opportunities globally."
                      </span>
                      <span>
                        Coach: "Network with peers, scout emerging talent, and <br></br>discover
                        coaching opportunities."
                      </span>
                      <span>
                        Parent: "Explore opportunities, connect with the community,<br></br> and
                        support your child's rugby journey."
                      </span>
                      <span>Agent: "Find and represent new talent, build connections."</span>
                    </div>
                    <div className="mt-1 cursor-pointer">
                      <CrossIcon onClick={() => setMessagePopoverAnchorEl(null)} />
                    </div>
                  </div>
                </Typography>
              </Popover>
            </div>
          </label>

          <Box sx={{ width: "100%" }}>
            <GRSelect
              id="profileType"
              defaultValue=""
              placeholder="Select profile type"
              error={formik.touched.profileType && Boolean(formik.errors.profileType)}
              name="profileType"
              onChange={formik.handleChange}
              value={formik.values.profileType}
              className={formik.touched.profileType && Boolean(formik.errors.profileType) ? "" : ""}
              options={profileTypeOptions}
              errormsg={
                formik.touched.profileType && formik.errors.profileType
                  ? formik.errors.profileType
                  : ""
              }
            />
          </Box>
        </div>

        {/* password */}

        <div className="mt-3 ">
          <label
            className="cursor-pointer block text-[15px] leading-6 font-[400]"
            htmlFor="password"
          >
            Password<span className=" text-red-500">*</span>
          </label>
          <GRTextfield
            id="password"
            type="password"
            width="100%"
            name="password"
            placeholder="Enter password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>

        {/* terms and condtions */}
        <div className="flex items-start mt-4">
          <FormControl>
            <div className="flex items-center  w-full">
              <Checkbox
                id="policy"
                style={{ marginLeft: "-4px", padding: "1px" }}
                name="policy"
                onChange={formik.handleChange}
                value={formik.values.policy}
              />

              <div className="ml-1 text-[14px]">
                I agree with{""}
                <Link to={UNAUTH_TERMSPOLICY} target="_blank">
                  <span className="cursor-pointer font-semibold underline mx-1 text-blue-600">
                    Terms and Policy
                  </span>
                </Link>
                <span className=" text-red-500">*</span>
              </div>
            </div>
            {formik.touched.policy && Boolean(formik.errors.policy) && (
              <FormHelperText className="!text-errorRed   !ml-0">
                {formik.touched.policy && formik.errors.policy}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        {/* submit btn */}
        <div className=" flex justify-center mt-4">
          <GRButton isSubmitting={isLoading} label="Create Account" width="100%" size="sm" />
        </div>
      </form>
    </div>
  )
}

export default RegisterForm
