import React, { SVGProps } from "react"

const CrossIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.293.293a1 1 0 011.414 0L7 5.586 12.293.293a1 1 0 111.414 1.414L8.414 7l5.293 5.293a1 1 0 01-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 01-1.414-1.414L5.586 7 .293 1.707a1 1 0 010-1.414z"
        fill="#0D0D0D"
      />
    </svg>
  )
}

export default CrossIcon
