import React, { useContext, useState } from "react"
import GRButton from "../../ui/GRButton"
import MessageIcon from "../../../assets/svg/MessageIcon"
import ExclamtionIcon from "../../../assets/svg/ExclamationIcon"
import AddPlayerCoachDialog from "./AddPlayerCoachDialog"
import Bin from "../../../assets/svg/BinIcon.svg"
import {
  IConnectedUserSummary,
  IUserConnectionPayload,
  useLoginUserAPI,
} from "../../../services/useLoginUserAPI"
import { ISearchUserByNameResponse } from "../../../modals/User"
import { debounce } from "lodash"
import { Formik } from "formik"
import { Form, Link } from "react-router-dom"
import { Autocomplete, Box, TextField, CircularProgress } from "@mui/material"
import DeleteConnectionDialog from "../../../components/dialogs/DeleteConnectionDialog"
import { useUserHook } from "../../../hooks/useUserHook"
import { errorToast, successToast } from "../../../utils/ToastUtils"
import { getNameInitials } from "../../../utils/CommonUtils"
import { Typography, Popover } from "@mui/material"
import { AutocompleteInputCss } from "../../../components/ui/GRSelect"
import AppContext from "../../../context/app-context/AppContext"
import { AUTH_USER_PROFILE } from "../../../routes/RouteConstants"
import { ConnectPlayerCoachDialogSchema } from "../../../validations/Validations"

const initialValues = {
  memberEmail: "",
  relation: "",
  member: "",
}
const ConnectPlayerCoach = () => {
  // State
  const [options, setOptions] = useState<ISearchUserByNameResponse>()
  const [open, setOpen] = useState(false)
  const [formikKey, setFormikKey] = useState(0)
  const [isShowAddPostDialog, setIsShowAddPostDialog] = useState<boolean>(false)

  //Hook
  const { isLoading, addUserConnection, searchPlayerOrCoachByName } = useLoginUserAPI()
  const { setConnectedUser, connectedUser } = useContext(AppContext)

  const fetchResults = async (searchStr: string) => {
    try {
      if (searchStr?.length < 3) {
        return
      }
      const r = await searchPlayerOrCoachByName(searchStr)
      setOptions(r)
    } catch {}
  }

  const debounceSearch = debounce(fetchResults, 1000)

  const getEmailValue = (id: string) => {
    const o = options?.data?.find((u) => u?.id === parseInt(id))
    if (!o) return
    return {
      label: `${o?.firstname} ${o.lastname} ${
        o?.clubMember?.name ? `- ${o?.clubMember?.name}` : ""
      }`,
      value: o?.id,
    }
  }

  // const isAgentDashboard = location.pathname === "/agent/dashboard"
  const handleConnect = async (values: typeof initialValues) => {
    try {
      if (!values?.member) return

      const isAlreadyConnected = connectedUser?.data?.find(
        (user) => user?.user?.id === Number(values?.member),
      )

      if (isAlreadyConnected) {
        setFormikKey((prevstate) => prevstate + 1)
        errorToast("Already Connected!")
        return
      }
      const payload: IUserConnectionPayload = {
        data: {
          userId: Number(values?.member),
        },
      }
      const res = await addUserConnection(payload)
      const connectUsers = []
      connectedUser?.data?.map((cu) => {
        connectUsers.push(cu)
      })
      connectUsers.push(res?.data)
      setConnectedUser({ data: [...connectUsers] })
      setFormikKey((prevstate) => prevstate + 1)
      setOptions(undefined)
      successToast("Connection added successfully")
    } catch {}
  }

  // Handler function to toggle the message
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleInviteOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleInviteClose = () => {
    setAnchorEl(null)
  }
  const openPopover = Boolean(anchorEl)
  return (
    <div className={` w-full  ${"h-[170px]"} rounded-2xl px-4 pt-5 bg-white`}>
      <div className="flex items-center justify-between font-semibold font-inter mb-3 text-lg">
        Connect Player/Coach
        <div
          className="cursor-pointer"
          aria-owns={openPopover ? "mouse-over-invite" : undefined}
          aria-haspopup="true"
          onMouseEnter={handleInviteOpen}
          onMouseLeave={handleInviteClose}
        >
          <ExclamtionIcon />
        </div>
      </div>

      <Popover
        id="mouse-over-invite"
        sx={{
          pointerEvents: "none",
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleInviteClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>
          <div className="flex gap-2">Connect with player/coach that you already represent</div>
        </Typography>
      </Popover>

      <div className="">
        <Formik
          key={formikKey}
          initialValues={initialValues}
          validationSchema={ConnectPlayerCoachDialogSchema}
          onSubmit={handleConnect}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <div className="mx-2 sm:mx-0">
                  {/* email address */}

                  <div className="w-full mb-4 ">
                    <Box sx={{ width: "100%" }}>
                      <Autocomplete
                        noOptionsText={
                          <div className="flex flex-col items-center justify-center absolute bg-white left-0 w-full h-[80px] shadow rounded-2xl px-3">
                            <div className="flex flex-col items-center text-[14px]">
                              <p className="text-center mb-2">
                                no suggestions found please enter email manually
                              </p>
                              <a
                                href="#"
                                className="text-gr-primaryR2 underline"
                                onClick={() => {
                                  setIsShowAddPostDialog(true)
                                }}
                              ></a>
                              <span
                                className="text-gr-primaryR2 underline cursor-pointer mb-4"
                                onClick={() => setOpen(true)}
                              >
                                Add your player/coach
                              </span>
                            </div>
                          </div>
                        }
                        autoFocus
                        id="email-search"
                        onChange={(e, v) => {
                          setFieldValue("member", v?.value)
                          setFieldValue("memberEmail", v?.label)
                        }}
                        value={getEmailValue(values?.member)}
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                        getOptionLabel={(option) => option?.label}
                        options={
                          options?.data?.map((o) => ({
                            label: `${o?.firstname} ${o.lastname} ${
                              o?.clubMember?.name ? `- ${o?.clubMember?.name}` : ""
                            }`,
                            value: o?.id,
                          })) || []
                        }
                        loading={isLoading}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Search name"
                            {...params}
                            onChange={(e) => debounceSearch(e?.target?.value)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            sx={AutocompleteInputCss}
                          />
                        )}
                      />
                    </Box>
                  </div>

                  <div className=" flex justify-end">
                    <GRButton
                      fontSize="12px"
                      height={33}
                      type="submit"
                      borderRadius={100}
                      label="Connect"
                      // isSubmitting={isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      <AddPlayerCoachDialog
        isOpen={open}
        onClose={() => {
          setOpen(false)
        }}
      />
      {/* {isAgentDashboard ? null : (
        <>
          <div className="border-b border-gr-primaryR4 my-4"></div>

          <div className="text-sm font-medium font-microsoftTai mb-[17px]">
            Connected player/coach
          </div>

         
          <div className="  flex flex-col gap-[17px] h-[18rem] overflow-y-scroll">
            {connectedUsers?.data?.map((user) => {
              return <ReferredInfoCard key={user?.id} userData={user} />
            })}
          </div>
        </>
      )} */}
      <AddPlayerCoachDialog
        isOpen={isShowAddPostDialog}
        onClose={() => setIsShowAddPostDialog(false)}
      />
    </div>
  )
}

interface IReferredInfoCardProps {
  userData: IConnectedUserSummary
}

export const ReferredInfoCard = (props: IReferredInfoCardProps) => {
  const { userData } = props
  const [isShowDeleteConnectionDialog, setIsShowDeleteConnectionDialog] = useState<boolean>(false)
  const { setConnectedUser, connectedUser } = useContext(AppContext)

  //Hook
  const { deletePlayerCoachConnection } = useLoginUserAPI()
  const { fetchLoginAsUserProfile } = useUserHook()

  const handleDelete = async () => {
    try {
      const res = await deletePlayerCoachConnection(userData?.id)
      fetchLoginAsUserProfile()
      const remaningUserCoonnection = connectedUser?.data.filter((con) => con?.id !== res?.data?.id)
      remaningUserCoonnection && setConnectedUser({ data: remaningUserCoonnection })
      successToast("Connection Deleted Successfully!")
    } catch {}
  }

  const { isAgent } = useUserHook()

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        {/* logo and name */}
        <div className="ml-[12px]">
          <Link to={`${AUTH_USER_PROFILE}/${userData?.user?.id}`}>
            {userData?.user?.profilePicture?.url ? (
              <div>
                <img
                  className="w-[44px] h-[44px] rounded-full"
                  src={userData?.user?.profilePicture?.url}
                  alt="Pic"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center w-[44px] h-[44px] rounded-full bg-gr-primaryR1">
                <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                  {getNameInitials(userData?.user?.firstname, userData?.user?.lastname)}
                </span>
              </div>
            )}
          </Link>
        </div>
        <div className="flex flex-col gap-[3px]">
          <Link to={`${AUTH_USER_PROFILE}/${userData?.user?.id}`}>
            <div className="font-inter text-sm font-semibold hover:underline">{`${userData?.user?.firstname} ${userData?.user?.lastname}`}</div>
          </Link>
          <div className="font-inter text-[12px] text-gr-neutralT3">
            {userData?.user?.profileType}
          </div>
        </div>
      </div>
      <div className="flex">
        {/* message icon */}
        {!isAgent ? (
          <div className="mr-[20px]">
            <MessageIcon width={20} height={20} />
          </div>
        ) : null}
        <div
          className="mr-[20px] cursor-pointer"
          onClick={() => setIsShowDeleteConnectionDialog(true)}
        >
          <img src={Bin} width={20} height={20} />
        </div>
        <DeleteConnectionDialog
          isOpen={isShowDeleteConnectionDialog}
          onClose={() => {
            setIsShowDeleteConnectionDialog(false)
          }}
          onDelete={() => {
            handleDelete()
          }}
          isAgent={false}
        />
      </div>
    </div>
  )
}

export default ConnectPlayerCoach
