import React from "react"
import LocationIcon from "../../../assets/svg/LocationIcon"
import MoreInfoIcon from "../../../assets/svg/MoreInfoIcon"
import { Link } from "react-router-dom"
import { AUTH_CLUB_PROFILE, AUTH_OPPORTUNITY_DETAIL } from "../../../routes/RouteConstants"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import { getDays } from "../../../utils/CommonUtils"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"

interface IOpportunityCardProps {
  opportunity: IOpportunitySummary
}
const ClubOpportunityCardMob = (props: IOpportunityCardProps) => {
  const { opportunity } = props
  return (
    <div className="mt-[21px] mx-4 pb-4 border-b border-gr-primaryN8">
      {/* top */}
      <div className="flex gap-[10px] ">
        {/* left section - logo */}
        <div className="">
          <Link to={`${AUTH_CLUB_PROFILE}/${opportunity?.club?.id}`}>
            <img
              src={opportunity?.club?.clubLogo?.url ?? ClubLogo}
              alt="profile-image"
              className="min-w-[44px] rounded-full w-[44px] h-[45px]"
            />
          </Link>
        </div>
        {/* right section - details */}
        <div className=" text-gr-neutralT2 flex flex-col gap-[4px]">
          {/* heading */}
          <div className="flex items-start justify-between gap-[3.25rem]">
            <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>
              <div className="font-inter font-semibold text-black text-sm mb-1 hover:underline">
                {opportunity?.club?.name}
              </div>
            </Link>
            <div className="pt-1 font-inter font-[500] text-green-green1 text-[12px]">
              {" "}
              {opportunity?.status ? (
                <div className="sm:mt-2 mt-0 text-green-green1 font-inter text-sm">Active</div>
              ) : (
                <div className="sm:mt-2 mt-0 text-red-darkRed font-inter text-sm">Inactive</div>
              )}
            </div>
          </div>
          {/* position */}
          <div className="text-xs">
            <span className="font-bold "> Position - </span>
            {opportunity?.playerPosition?.title
              ? opportunity?.playerPosition?.title
              : opportunity?.coachPosition?.title}
          </div>

          {/* season */}
          <div className="text-xs">
            {" "}
            <span className="font-bold">Season - </span> {opportunity?.season?.title}
          </div>

          {/* level */}
          <div className="text-xs">
            {" "}
            <span className="font-bold">Level - </span>
            {opportunity?.level?.title}
          </div>

          {/* age group */}
          <div className="text-xs">
            <span className="font-bold">Age group - </span>
            {opportunity?.ageGroup?.title}
          </div>

          {/* location */}
          <div className="flex items-center gap-[4px]">
            <div>
              <LocationIcon width={12} height={16} />
            </div>
            <div className="text-xs font-bold">{opportunity?.country}</div>
          </div>
        </div>
      </div>

      {/* bottom */}
      <div className="flex justify-end  items-center pl-[54px] mr-1 mt-2">
        <div className="flex justify-end  items-center">
          <div className="font-[500] text-xs text-gr-neutralT2 mr-[18px]">
            {opportunity?.applicants?.length}{" "}
            {opportunity?.applicants?.length > 1 ? "applicants" : "applicant"}
          </div>
          <div className="font-[500] text-xs text-gr-neutralT2 mr-[18px]">
            {getDays(opportunity?.createdAt)}{" "}
            {getDays(opportunity?.createdAt) > 1 ? "days ago" : "day ago"}
          </div>
          <div className="text-primaryR2 text-[11px] font-medium hover:underline text-gr-primaryR2 mr-[10px]">
            <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>More Info</Link>
          </div>
          <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>
            <MoreInfoIcon />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ClubOpportunityCardMob
