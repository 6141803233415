import React, { useContext, useEffect, useState } from "react"
import GRTextfield from "../ui/GRTextfield"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import RightArrow from "../../assets/svg/RightArrow"
import { otherInfoOnboardingValidationSchema as validationSchema } from "../../validations/Validations"
import AppContext from "../../context/app-context/AppContext"
import LeftArrow from "../../assets/svg/LeftArrowRed"
import useLocationHook from "../../hooks/useLocationHook"
// import { toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import Autocomplete from "@mui/material/Autocomplete"
import { AutocompleteInputCss } from "../ui/GRSelect"
import { TextField } from "@mui/material"
import { CountryArray } from "../../utils/Constants"

export interface IOtherInfoFormValues {
  contact: string
  address: string
  city: string
  state: string
  country: string
  zipCode: string
}

type IOtherInfoProps = {
  onSubmit: (values: IOtherInfoFormValues) => void
  isSubmitting: boolean
  goToPreviousStep: () => void
}

const OtherInfo: React.FC<IOtherInfoProps> = ({ onSubmit, isSubmitting, goToPreviousStep }) => {
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // Hook
  const [isNotState, setIsNotState] = useState<boolean>(false)
  const {
    countryOptions,
    stateOptions,
    cityOptions,
    fetchStateByCountry,
    fetchCityByStateAndCountry,
    fetchLocationData,
  } = useLocationHook()

  // UseEffect
  useEffect(() => {
    if (loginAsProfileDetails) {
      fetchLocationData(loginAsProfileDetails?.country, loginAsProfileDetails?.state)
    }
  }, [loginAsProfileDetails])

  const handleGoBack = () => goToPreviousStep()

  const formik = useFormik({
    initialValues: {
      contact: loginAsProfileDetails?.contactNumber || "",
      address: loginAsProfileDetails?.address || "",
      city: loginAsProfileDetails?.city || "",
      state: loginAsProfileDetails?.state || "",
      country: loginAsProfileDetails?.country || "",
      zipCode: String(loginAsProfileDetails?.postCode || ""),
    },
    onSubmit: (values: IOtherInfoFormValues) => {
      onSubmit(values)
    },
    validationSchema,
    enableReinitialize: true,
  })

  return (
    <div className=" flex flex-col gap-4  border-t border-gr-primaryN8 bg-white  mt-[-20px ]  font-inter">
      {/* heading- Other Info */}
      <div className="flex justify-between items-center font-bold ">
        <div className="text-lg text-gr-primaryN1 mt-2 font-inter">Other info</div>
        <div>
          3<span className="text-gray-500">/3</span>
        </div>
      </div>

      <div className="flex -mt-3">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>

      {/* form */}
      <div className=" ">
        <form onSubmit={formik.handleSubmit} className="flex flex-col compeleteProfileForm">
          <div className="flex flex-col  ">
            <div className="flex justify-center sm:block">
              {/* ContactNumber */}
              <div className="w-full mb-4">
                <label className="block text-sm leading-6 font-[400]" htmlFor="contact">
                  Contact number
                </label>

                <GRTextfield
                  id="contact"
                  placeholder="Enter contact"
                  type="text"
                  name="contact"
                  className="w-full"
                  onChange={formik.handleChange}
                  value={formik.values.contact}
                  error={formik.touched.contact && Boolean(formik.errors.contact)}
                  helperText={formik.touched.contact && formik.errors.contact}
                />
              </div>
            </div>
            {/* ContactNumber and Address */}
            <div className="flex flex-col gap-x-8 items-center sm:flex-row sm:justify-between sm:items-start">
              {/* Address */}
              <div className="w-full mb-4">
                <label className="block text-sm leading-6 font-[400]" htmlFor="address">
                  Address
                </label>
                <GRTextfield
                  id="address"
                  type="text"
                  placeholder="Enter address"
                  className="w-full"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
              </div>
              {/* Post/Zip Code */}
              <div className="w-full mb-4">
                <label className="block text-sm leading-6 font-[400]" htmlFor="zipCode">
                  Postcode<span className="text-red-600">*</span>
                </label>

                <GRTextfield
                  id="zipCode"
                  type="text"
                  placeholder="Enter postcode"
                  className="w-full"
                  name="zipCode"
                  onChange={formik.handleChange}
                  value={formik.values.zipCode || ""}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </div>
            </div>

            <div className="grid  gap-x-5 grid-cols-1 lg:grid-cols-3 lg:gap-6">
              <div className="w-full mb-4">
                <div className="text-sm  flex mb-1 block font-inter">
                  Country
                  <span className="text-red-600">*</span>
                </div>
                <div className="">
                  <Autocomplete
                    id="country"
                    onChange={(_event, newValue) => {
                      formik.handleChange(_event)
                      formik.setFieldValue("country", newValue || "")
                      formik.setFieldValue("state", "")
                      formik.setFieldValue("city", "")
                      if (newValue) {
                        if (CountryArray?.includes(newValue)) {
                          fetchCityByStateAndCountry(newValue, "")
                          setIsNotState(true)
                        } else {
                          fetchStateByCountry(newValue)
                          setIsNotState(false)
                        }
                      }
                    }}
                    renderOption={(formik, option) => (
                      <li {...formik} style={{ fontSize: "14px" }}>
                        {option}
                      </li>
                    )}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.country}
                    options={countryOptions?.map((c) => c?.name) || []}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Country" sx={AutocompleteInputCss} />
                    )}
                  />
                  {formik?.touched?.country && formik?.errors?.country && (
                    <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                      {formik?.errors?.country}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full mb-4">
                <div className="text-sm  flex mb-1 block font-inter">State</div>
                <Autocomplete
                  id="state"
                  onChange={(_event, newValue) => {
                    formik.handleChange(_event)
                    formik.setFieldValue("state", newValue || "")
                    formik.setFieldValue("city", "")
                    if (newValue) {
                      fetchCityByStateAndCountry(formik.values.country, newValue)
                    }
                  }}
                  renderOption={(formik, option) => (
                    <li {...formik} style={{ fontSize: "14px" }}>
                      {option}
                    </li>
                  )}
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.state}
                  readOnly={CountryArray?.includes(formik.values.country) ? true : false}
                  options={stateOptions?.map((s) => s?.name) || []}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="State" sx={AutocompleteInputCss} />
                  )}
                />
                {isNotState && (
                  <div className="text-[13px] text-blue-600 lowercase first-letter:uppercase !font-inter">
                    The country has no state so you can select a city
                  </div>
                )}
              </div>
              <div className="w-full mb-4">
                <div className="text-sm  flex mb-1 block font-inter">City</div>
                <Autocomplete
                  id="city"
                  onChange={(_event, newValue) => {
                    formik.handleChange(_event)
                    formik.setFieldValue("city", newValue || "")
                  }}
                  renderOption={(formik, option) => (
                    <li {...formik} style={{ fontSize: "14px" }}>
                      {option}
                    </li>
                  )}
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.city}
                  options={cityOptions?.map((c: { name: any }) => c?.name) || []}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="City" sx={AutocompleteInputCss} />
                  )}
                />
              </div>
            </div>
          </div>
          <div>
            {/* buttons -  next */}
            <div className="flex gap-4 justify-between mt-5 w-full">
              <div className="flex justify-start mb-2">
                <GRButton
                  label="Previous"
                  startIcon={<LeftArrow />}
                  width="124px"
                  className="!text-gr-primaryR2 !bg-white !px-[10px]"
                  border="1px solid"
                  onClick={handleGoBack}
                />
              </div>
              <div className="flex mb-2">
                <GRButton
                  isSubmitting={isSubmitting}
                  label="Submit"
                  endIcon={<RightArrow />}
                  width="105px"
                  className="!bg-primaryR2"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default OtherInfo
