import React from "react"
import ReactQuill, { ReactQuillProps } from "react-quill"
import "react-quill/dist/quill.snow.css"

interface IRichTextEditorProps {}

export const RichTextEditor = (props: IRichTextEditorProps & ReactQuillProps) => {
  return (
    <ReactQuill
      className="Gr-richTextEditor"
      theme="snow"
      style={{
        height: 150,
      }}
      modules={{
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        ],
      }}
      {...props}
    />
  )
}
