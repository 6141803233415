import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={42}
      height={52}
      viewBox="0 0 144 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 180c-9.941 0-18-8.059-18-18V57.728A18 18 0 015.272 45L45 5.272A18 18 0 0157.728 0H126c9.941 0 18 8.059 18 18v144c0 9.941-8.059 18-18 18H18zm0-18h108V18H72v40.5C72 65.956 65.956 72 58.5 72H18v90zm3.728-108H54V21.728L21.728 54zM90 99a9 9 0 01-9-9 9 9 0 019-9h9a9 9 0 019 9 9 9 0 01-9 9h-9zm-45 36a9 9 0 01-9-9 9 9 0 019-9h54a9 9 0 019 9 9 9 0 01-9 9H45z"
        fill="#09244B"
      />
    </svg>
  )
}

export default SvgComponent
