import React, { useContext } from "react"
import { GRDialog } from "../ui/GRDialog"
import { Box } from "@mui/material"
import { useFormik } from "formik"
import * as yup from "yup"
import GRButton from "../ui/GRButton"
import DialogHeader from "./DialogHeader"
import { IDropDownOptionsResponse } from "../../modals/Config"
import AppContext from "../../context/app-context/AppContext"
import { profileOptions } from "../../utils/Constants"
import { convertIntoDropDownOptions } from "../../utils/CommonUtils"
import { useOpportunityAPI, IOpportunityPayload } from "../../services/useOpportunityAPI"
import { errorToast, successToast } from "../../utils/ToastUtils"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import Autocomplete from "@mui/material/Autocomplete"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import useLocationHook from "../../hooks/useLocationHook"
import { TextField } from "@mui/material"
interface ICreateOpportunityFormValues {
  season: string
  agegroup: string
  position: string
  level: string
  country: string
  othercriteria: string
  description: string
  discipline: string
  profiletype: string
}

const initialValues = {
  season: "",
  agegroup: "",
  position: "",
  level: "",
  country: "",
  othercriteria: "",
  description: "",
  discipline: "",
  profiletype: "",
}
const validationSchema = yup.object({
  season: yup.string().required("Season is required"),
  agegroup: yup.string().required("Age group is required"),
  level: yup.string().required("Level is required"),
  position: yup.string().required("Position is required"),
  country: yup.string().required("Country is required"),
  discipline: yup.string().required("Code is required"),
  profiletype: yup.string().required("Profile type is required"),
  description: yup.string().max(2000, "Max. 2000 characters are allowed"),
})

interface ICreateOpportunityProps {
  dropDownOptions: IDropDownOptionsResponse | undefined
  isShowDialog: boolean
  onClose: () => void
  onGetOpportunity: () => void
}

const CreateOpportunityDialog = (props: ICreateOpportunityProps) => {
  const { isShowDialog, onClose, onGetOpportunity } = props
  // Context
  const { dropDownOptions, loginAsProfileDetails } = useContext(AppContext)
  //API
  const { isLoading: isSubmitting, createOpportunity } = useOpportunityAPI()
  const { countryOptions, fetchStateByCountry } = useLocationHook()

  const saveOpportunity = async (values: ICreateOpportunityFormValues, formikBag: any) => {
    try {
      const opportunityPayload: IOpportunityPayload = {
        data: {
          season: values?.season,
          ageGroup: values?.agegroup,
          playerPosition: values?.profiletype === "Player" ? values?.position : null,
          coachPosition: values?.profiletype === "Coach" ? values?.position : null,
          level: values?.level,
          discipline: values?.discipline,
          profileType: values?.profiletype,
          country: values?.country,
          otherCriteria: values?.othercriteria,
          description: values?.description,
          opportunityThumbnail: null, // I will update this after implement upload file api
          club: loginAsProfileDetails?.club?.id || null, // I will update after fix club url
        },
      }
      const request = await createOpportunity(opportunityPayload)
      if (request?.opportunity?.id) {
        successToast("Your Opportunity created successfully")
        onGetOpportunity()
        formikBag?.resetForm()
        onClose()
      }
    } catch (error) {
      errorToast("Something went wrong!")
      formikBag?.resetForm()
      onClose()
    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit: saveOpportunity,
    validationSchema,
    enableReinitialize: true,
  })

  const body = (
    <div className="mx-[15px] lg:mx-[31px]">
      <div className="flex justify-start items-center gap-[12px]">
        <img
          src={loginAsProfileDetails?.club?.clubLogo?.url ?? ClubLogo}
          className="rounded-full w-[74px] h-[74px]"
          alt="clubLogo"
        />
        <span className="text-[15px] font-medium sm:text-lg">
          {loginAsProfileDetails?.club?.name}
        </span>
      </div>
      <div className="flex mt-3 mb-4">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>
      {/* season */}
      <form onSubmit={formik.handleSubmit} className="compeleteProfileForm">
        <div className="flex flex-col sm:flex-row justify-between">
          <div className="w-full mb-4">
            <label className="block text-sm leading-6  font-inter mb-1 " htmlFor="season">
              Season<span className="text-red-600">*</span>
            </label>
            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="season"
                defaultValue=""
                placeholder="Select season"
                name="season"
                onChange={formik.handleChange}
                value={formik.values.season}
                options={convertIntoDropDownOptions(
                  dropDownOptions?.seasons.filter((seasons) => {
                    return (
                      seasons.status === "Current" ||
                      seasons.status === "Next" ||
                      seasons.status === "Future"
                    )
                  }),
                )}
              />
            </Box>
            {formik.touched.season && formik.errors.season && (
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                {formik.errors.season}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
          {/* Profile type */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="profiletype">
              Profile type<span className="text-red-600">*</span>
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="profiletype"
                defaultValue=""
                placeholder="Select profile type"
                name="profiletype"
                onChange={formik.handleChange}
                value={formik.values.profiletype}
                options={profileOptions}
              />
            </Box>
            {formik.touched.profiletype && formik.errors.profiletype && (
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                {formik.errors.profiletype}
              </div>
            )}
          </div>
          {/* position */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="discipline">
              Code<span className="text-red-600">*</span>
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="discipline"
                placeholder="Select code"
                defaultValue=""
                name="discipline"
                onChange={formik.handleChange}
                value={formik.values.discipline}
                options={convertIntoDropDownOptions(dropDownOptions?.discipline)}
              />
            </Box>
            {formik.touched.discipline && formik.errors.discipline && (
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                {formik.errors.discipline}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
          {/* Age group */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6  font-inter mb-1 " htmlFor="agegroup">
              Age group<span className="text-red-600">*</span>
            </label>
            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="agegroup"
                defaultValue=""
                name="agegroup"
                placeholder="Select age group"
                onChange={formik.handleChange}
                value={formik.values.agegroup}
                options={convertIntoDropDownOptions(dropDownOptions?.ageGroup)}
              />
            </Box>
            {formik.touched.agegroup && formik.errors.agegroup && (
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                {formik.errors.agegroup}
              </div>
            )}
          </div>
          {/* position */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6  font-inter mb-1 " htmlFor="position">
              Position<span className="text-red-600">*</span>
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="position"
                defaultValue=""
                name="position"
                placeholder="Select position"
                onChange={formik.handleChange}
                value={formik.values.position}
                options={
                  formik.values.profiletype === "Player"
                    ? convertIntoDropDownOptions(dropDownOptions?.playerPosition)
                    : convertIntoDropDownOptions(dropDownOptions?.coachPosition)
                }
              />
            </Box>
            {formik.touched.position && formik.errors.position && (
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                {formik.errors.position}
              </div>
            )}
          </div>
        </div>
        <div className="grid gap-x-5 grid-cols-1 lg:grid-cols-2 lg:gap-6">
          {/* level */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="level">
              Level<span className="text-red-600">*</span>
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="level"
                defaultValue=""
                name="level"
                placeholder="Select level"
                onChange={formik.handleChange}
                value={formik.values.level}
                options={convertIntoDropDownOptions(dropDownOptions?.level)}
              />
            </Box>
            {formik.touched.level && formik.errors.level && (
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                {formik.errors.level}
              </div>
            )}
          </div>
          {/* country */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6  font-inter mb-1 " htmlFor="level">
              Country<span className="text-red-600">*</span>
            </label>
            <div className="w-full">
              <Autocomplete
                id="country"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("country", newValue || "")
                  if (newValue) fetchStateByCountry(newValue)
                }}
                renderOption={(formik, option) => (
                  <li {...formik} style={{ fontSize: "14px" }}>
                    {option}
                  </li>
                )}
                onBlur={formik?.handleBlur}
                value={formik?.values?.country}
                options={countryOptions?.map((c) => c?.name) || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Country" sx={AutocompleteInputCss} />
                )}
              />
              {formik?.touched?.country && formik?.errors?.country && (
                <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                  {formik?.errors?.country}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* other criteria */}
        <div className="mb-4">
          <label className="block text-sm leading-6  font-inter mb-1 " htmlFor="">
            Other criteria
          </label>
          <input
            type="text"
            name="othercriteria"
            placeholder="Enter other criteria"
            className="border border-gr-primaryN8 w-full rounded-lg px-[10px] py-2"
            onChange={formik.handleChange}
            value={formik.values.othercriteria}
          />
        </div>
        {/* description */}
        <div>
          <div className="flex justify-between">
            <label className="block text-sm leading-6 font-inter mb-1 " htmlFor="">
              Description <span className="text-gr-neutral05">{`(optional)`}</span>
            </label>
            <div className="text-sm font-normal !mb-0 font-inter text-gr-neutralT4 leading-6">
              {formik.values.description?.length || 0} / 2000 characters
            </div>
          </div>

          <textarea
            className="border border-[rgba(0,0,0,0.23)] p-[10px] w-full h-[88px] placeholder-text-color rounded-lg"
            name="description"
            placeholder="Write your message ..."
            onChange={formik.handleChange}
            value={formik.values.description}
          ></textarea>
        </div>

        <div className="flex justify-between items-center mt-6 mb-8">
          <GRButton onClick={onClose} label="Cancel" type="button" className="w-[100px]" />
          <GRButton
            isSubmitting={isSubmitting}
            onClick={formik.handleSubmit}
            label="Post"
            className="w-[100px]"
          />
        </div>
      </form>
    </div>
  )
  return (
    <GRDialog
      open={isShowDialog}
      onClose={() => onClose()}
      classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
      dialogbody={body}
      dialogtitle={<DialogHeader title="Post a new opportunity" onClose={onClose} />}
    />
  )
}

export default CreateOpportunityDialog
