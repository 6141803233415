import React from "react"
import { Link } from "react-router-dom"
import MoreInfoIcon from "../../../assets/svg/MoreInfoIcon"
import LocationIcon from "../../../assets/svg/LocationIcon"
import { AUTH_OPPORTUNITY_DETAIL } from "../../../routes/RouteConstants"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import { getDays } from "../../../utils/CommonUtils"
import { AUTH_CLUB_PROFILE } from "../../../routes/RouteConstants"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"

interface IOpportunityCardProps {
  opportunity: IOpportunitySummary
}
const ClubOpportunityCard = (props: IOpportunityCardProps) => {
  const { opportunity } = props

  return (
    <div className="">
      <div className="flex justify-between ">
        {/* logo and heading */}
        <div className="flex items-start gap-[10px] sm:gap-4">
          {/* logo */}
          <div className="md:w-auto">
            <Link to={`${AUTH_CLUB_PROFILE}/${opportunity?.club?.id}`}>
              <img
                src={opportunity?.club?.clubLogo?.url ?? ClubLogo}
                alt="logo"
                className="min-w-[60px] w-[60px] h-[60px] rounded-full"
              />
            </Link>
          </div>

          {/* heading */}
          <div className="flex flex-col gap-1">
            <div className="font-inter font-semibold sm:text-lg hover:underline">
              <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>
                {opportunity?.club?.name}
              </Link>
            </div>
            <div className="text-sm hidden sm:block font-inter uppercase">
              Opportunity for{" "}
              {opportunity?.playerPosition?.title
                ? opportunity?.playerPosition?.title
                : opportunity?.coachPosition?.title}
            </div>
          </div>
        </div>
        {/* status */}
        {opportunity?.status ? (
          <div className="text-green-green1 font-inter text-sm font-medium mt-[2px]">Active</div>
        ) : (
          <div className="text-red-darkRed font-inter text-sm font-medium mt-[2px]">Inactive</div>
        )}
      </div>
      {/* opportunity details- season,level,age,location */}
      <div className="sm:flex flex-wrap text-xs sm:pl-[75px] sm:pb-3 md:pt-0 pt-2">
        <div className="md:w-[25%] sm:w-[50%] w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-4 md:gap-7 text-gr-neutralT3 md:mb-0 mb-2">
          <div className="text-xs">
            <span className="font-inter">Season: {opportunity?.season?.title}</span>
          </div>
        </div>

        <div className="md:w-[25%] sm:w-[50%] w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-4 md:gap-7 text-gr-neutralT3 md:mb-0 mb-2">
          <div className="text-xs">
            <span className="font-inter">Level: {opportunity?.level?.title}</span>
          </div>
        </div>

        <div className="md:w-[25%] sm:w-[50%] w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-5 md:gap-8 text-gr-neutralT3">
          <div className="text-xs">
            <span className="">Age group: {opportunity?.ageGroup?.title}</span>
          </div>
        </div>

        <div className="md:w-[25%] sm:w-[50%] w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-4 md:gap-7 text-gr-neutralT3 md:mb-0 mb-2">
          <div className="text-xs">
            <span className="">Code: {opportunity?.discipline?.title}</span>
          </div>
        </div>
        <div className="md:w-[25%] sm:w-[50%] w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-5 md:gap-8 text-gr-neutralT3 mt-3">
          <div className="flex items-center gap-2 text-xs">
            <div>
              <LocationIcon width={16} height={16} />
            </div>
            <div className=""> {opportunity?.country}</div>
          </div>
        </div>
      </div>

      {/* description */}
      {opportunity?.description && (
        <div className="sm:ml-[75px]">
          <div className="text-black font-medium font-inter md:mb-0 text-sm">
            Opportunity description
          </div>
          <div className="text-gr-neutralT3 font-inter text-sm h-max sm:mt-2 overflow-hidden overflow-ellipsis line-clamp-2">
            {opportunity?.description}
          </div>
        </div>
      )}

      {/* moreInfo */}
      <div className="mt-6 text-gr-neutralT2 font-inter flex justify-end items-center">
        <div className="font-[500] text-xs mr-8">
          {opportunity?.applicants?.length || "No"}{" "}
          {opportunity?.applicants?.length > 1 ? "applicants" : "applicant"}{" "}
        </div>
        <div className="font-[500] text-xs mr-8">
          {getDays(opportunity?.createdAt)}{" "}
          {getDays(opportunity?.createdAt) > 1 ? "days ago" : "day ago"}
        </div>
        <div className="text-primaryR2 text-[11px] font-medium hover:underline text-gr-primaryR2">
          <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>More info</Link>
        </div>
        <div className="ml-[10px] w-[12px]">
          <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>
            <MoreInfoIcon />
          </Link>
        </div>
      </div>
      {/* bottomLine */}
      <div className="border-b border-gr-primaryN9 my-6"></div>
    </div>
  )
}

export default ClubOpportunityCard
