import React, { useContext, useState, useEffect } from "react"
import ProfileImage from "../../../assets/svg/ProfileImage.svg"
import ThreeDotsHorizontal from "../../../assets/svg/ThreeDotsHorizontal"
import LikeIcon from "../../../assets/svg/LikeIcon"
import LikeRedIcon from "../../../assets/svg/LikeRedIcon"
import ShareIcon from "../../../assets/svg/ShareIcon"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { IPostSummary, IUpdatePost } from "../../../modals/Post"
import EditPencilIcon from "../../../assets/svg/clubprofileicon/EditPencilIcon"
import TrashIcon from "../../../assets/svg/clubprofileicon/TrashIcon"
import DeletePost from "../../club/clubprofile/postpopups/DeletePost"
import EditPost from "../../club/clubprofile/postpopups/EditPost"
import AppContext from "../../../context/app-context/AppContext"
import { useUserHook } from "../../../hooks/useUserHook"
import { usePostAPI, IUpdatePostByClub, IUpdatePostByUser } from "../../../services/usePostAPI"
import dayjs from "dayjs"
import { AUTH_USER_PROFILE, AUTH_CLUB_PROFILE } from "../../../routes/RouteConstants"
import { Link } from "react-router-dom"

interface IPostProps {
  postData: IPostSummary | undefined
  onFetchPosts: () => void
  removePost: () => void
}

const Post = (props: IPostProps) => {
  const { onFetchPosts, removePost } = props
  //State
  const [isDeletePostDialog, SetIsDeletePostDialog] = useState<boolean>(false)
  const [isEditPostDialog, SetIsEditPostDialog] = useState<boolean>(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [updatePostContent, setUpdatePostContent] = useState<IUpdatePost>()
  const [postData, setPostData] = React.useState<IPostSummary | undefined>(props?.postData)
  const open = Boolean(anchorEl)

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)
  // Hook
  const { isLoginAsClub } = useUserHook()
  //APIs
  const {
    postHideByUser,
    postHideByClub,
    postLikeByUser,
    postLikeByClub,
    postUnlikeByUser,
    postUnlikeByClub,
  } = usePostAPI()

  const clubId = loginAsProfileDetails?.club?.id

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const postContent = postData?.description

  const isShowShowMoreBtn = postContent && postContent?.length > 250

  useEffect(() => {
    if (updatePostContent?.id && updatePostContent?.id === postData?.id) {
      setPostData(
        (postData) =>
          postData && {
            ...postData,
            description: updatePostContent?.description,
            media: updatePostContent?.media,
          },
      )
    }
  }, [updatePostContent])

  const onHide = (id: number | null | undefined) => {
    try {
      if (!id) return
      if (isLoginAsClub && clubId) {
        const postUpdatePayload: IUpdatePostByClub = {
          data: {
            id: id,
            clubId: clubId,
          },
        }
        postHideByClub(postUpdatePayload)
        onFetchPosts()
      } else {
        const postUpdatePayload: IUpdatePostByUser = {
          data: {
            id: id,
          },
        }
        postHideByUser(postUpdatePayload)
        onFetchPosts()
      }
    } catch (error) {}
  }
  const onLike = (id: number | null | undefined) => {
    try {
      if (!id) return
      if (isLoginAsClub && clubId) {
        const postUpdatePayload: IUpdatePostByClub = {
          data: {
            id: id,
            clubId: clubId,
          },
        }
        postLikeByClub(postUpdatePayload)
        onFetchPosts()
      } else {
        const postUpdatePayload: IUpdatePostByUser = {
          data: {
            id: id,
          },
        }
        postLikeByUser(postUpdatePayload)
        onFetchPosts()
      }
    } catch (error) {}
  }

  const onUnLike = (id: number | null | undefined) => {
    try {
      if (!id) return
      if (isLoginAsClub && clubId) {
        const postUpdatePayload: IUpdatePostByClub = {
          data: {
            id: id,
            clubId: clubId,
          },
        }
        postUnlikeByClub(postUpdatePayload)
        onFetchPosts()
      } else {
        const postUpdatePayload: IUpdatePostByUser = {
          data: {
            id: id,
          },
        }
        postUnlikeByUser(postUpdatePayload)
        onFetchPosts()
      }
    } catch (error) {}
  }

  const days = dayjs().diff(postData?.createdAt, "days")
  const hours = dayjs().diff(postData?.createdAt, "hours")
  const minutes = dayjs().diff(postData?.createdAt, "minutes")
  let time = minutes ? `${minutes}m` : "Just now"
  if (minutes > 1440) {
    time = `${days}d`
  } else if (minutes > 60) {
    time = `${hours}h`
  }

  // Function to convert URLs to links
  const convertUrlsToLinks = (content: string) => {
    const urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    return content.replace(
      urlRegex,
      (url) =>
        `<a href="${
          !/^https?:\/\//i.test(url) ? "https://" + url : url
        }" style="color:rgb(159, 0, 2); text-decoration: underline;" target="_blank">${url}</a>`,
    )
  }
  return (
    <div className="px-4 bg-white rounded-2xl sm:mx-0 mt-[-1px] mb-4">
      <div className="pt-5 sm:pl-7 mb-3 flex justify-between items-center lg:pl-3 lg:pr-2">
        <div className="flex justify-start gap-4 items-center">
          <div>
            <Link to={`${AUTH_USER_PROFILE}/${postData?.user?.id}`}>
              <img
                src={ProfileImage}
                alt="profile-image"
                className="rounded-full max-w-[44px] max-h-[44px]"
              />
            </Link>
          </div>

          <div>
            <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px]">
              {postData?.isPostByClub ? (
                <Link
                  to={`${AUTH_CLUB_PROFILE}/${postData?.club?.id}`}
                >{`${postData?.club?.name}`}</Link>
              ) : (
                <Link to={`${AUTH_USER_PROFILE}/${postData?.user?.id}`}>
                  {`${postData?.user?.firstname} ${postData?.user?.lastname}`}
                </Link>
              )}
            </p>
            <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
              {postData?.isPostByClub ? "Club" : postData?.user?.profileType} {time}
            </p>
          </div>
        </div>
        <div className="">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="!min-w-[32px]"
          >
            <ThreeDotsHorizontal />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(isLoginAsClub && postData?.club?.id === clubId) ||
            (!isLoginAsClub && loginAsProfileDetails?.id === postData?.user?.id) ? (
              <>
                <MenuItem onClick={() => SetIsEditPostDialog(true)}>
                  <div className="flex items-center gap-[14px]">
                    <div>
                      <EditPencilIcon size={15} />
                    </div>
                    <div>Edit</div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => SetIsDeletePostDialog(true)}>
                  <div className="flex items-center gap-[14px]">
                    <div>
                      <TrashIcon size={15} />
                    </div>
                    <div>Delete</div>
                  </div>
                </MenuItem>
              </>
            ) : (
              <MenuItem onClick={() => onHide && onHide(postData?.id)}>Hide Post</MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <div className="sm:px-4 mb-3 lg:pl-3 lg:pr-0">
        <p className="font-inter text-gr-neutralT2 text-xs leading-6 font-normal md:text-sm">
          {postContent ? (
            isShowMore ? (
              <span dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(postContent) }} />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: convertUrlsToLinks(postContent?.substring(0, 250)),
                }}
              />
            )
          ) : (
            ""
          )}
        </p>
        {isShowShowMoreBtn && (
          <div className="flex justify-end">
            <button
              onClick={() => setIsShowMore(!isShowMore)}
              className="font-inter text-gr-neutralT3 text-xs leading-6 font-normal text-end lg:text-xs cursor-pointer select-none"
            >
              {isShowMore ? "...see less" : "...see more"}
            </button>
          </div>
        )}
      </div>
      {postData?.postType === "Image" ? (
        <div className="flex justify-center items-center mb-2">
          <img src={postData?.media?.url} alt="post-img" className="w-[600px] object-contain" />
        </div>
      ) : postData?.postType === "Video" ? (
        <div className="flex justify-center items-center">
          <video controls className="bg-cover h-[390px]" src={postData?.media?.url} />
        </div>
      ) : null}
      <div className="mt-3 flex justify-between items-center pb-5 px-3">
        <button className="flex justify-center items-center gap-2 cursor-pointer">
          {postData?.isLike ? (
            <span>
              <LikeRedIcon onClick={() => onUnLike(postData?.id)} />
            </span>
          ) : (
            <span onClick={() => onLike(postData?.id)}>
              <LikeIcon />
            </span>
          )}
          <span className="text-gr-primaryN2 text-xs leading-[17px] font-semibold select-none">
            {postData?.likeCount}{" "}
            {postData?.likeCount && postData?.likeCount > 1 ? "Likes" : "Like"}
          </span>
        </button>
        <button className="flex justify-center items-center gap-2 cursor-pointer">
          <ShareIcon />
          <span className="text-gr-primaryN2 text-xs leading-[17px] font-semibold select-none">
            Share
          </span>
        </button>
      </div>
      {/* Add delete Post dialog */}
      <DeletePost
        isOpen={isDeletePostDialog}
        onClose={() => SetIsDeletePostDialog(false)}
        onDeletePost={() => removePost()}
      />
      <EditPost
        onFetchPosts={onFetchPosts}
        postData={postData}
        isOpen={isEditPostDialog}
        onClose={() => SetIsEditPostDialog(false)}
        setUpdatePostContent={setUpdatePostContent}
      />
    </div>
  )
}

export default Post
