import React, { SVGProps } from "react"

const LeftArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.293 7.293a1 1 0 000 1.414l6.364 6.364a1 1 0 001.414-1.414L2.414 8l5.657-5.657A1 1 0 006.657.93L.293 7.293zM17 7H1v2h16V7z"
        fill="#4B4B4B"
      />
    </svg>
  )
}

export default LeftArrow
