import { ISearchUserByNameResponse, IUser } from "../modals/User"
import { ISearchAgentResponse } from "../modals/Agent"
import useBaseAPI from "./useBaseAPI"

export const useAgentAPI = () => {
  const { GET, POST, isLoading } = useBaseAPI()

  const searchAgentAccount = (searchStr?: string): Promise<ISearchAgentResponse> => {
    return GET(`/get-agent-email/${searchStr}`)
  }

  const connectAgent = (payload: IConnectAgentPayload): Promise<IConnectAgentResponse> => {
    return POST(`/connect-agent`, payload)
  }
  const searchAgentsByName = (searchStr: string): Promise<ISearchUserByNameResponse> => {
    return GET(`/get-agent-by-name/${searchStr}`)
  }

  return { isLoading, searchAgentAccount, connectAgent, searchAgentsByName }
}

interface IConnectAgentPayload {
  data: {
    agentEmail: string
    agentFirstName: string
    agentLastName: string
    agentID: string
  }
}

interface IConnectAgentResponse {
  data: {
    agentEmail: string
    createdAt: string
    publishedAt: string
    status: boolean
    updatedAt: string
    user: IUser
  }
}
