import React, { useContext, useEffect, useState } from "react"
import BackgroundImage from "../../../assets/svg/BackgroundClubProfile.svg"
import FrontArrow from "../../../assets/svg/FrontArrow"
import { Link } from "react-router-dom"
import { AUTH_MY_PROFILE, AUTH_FOLLOWERS } from "../../../routes/RouteConstants"
import AppContext from "../../../context/app-context/AppContext"
import { useClubAPI } from "../../../services/useClubAPI"
import { IClubDetails } from "../../../modals/Club"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"

function Profile() {
  const [clubData, setClubData] = useState<IClubDetails>()
  //hooks

  const showProgress = 100

  //Context
  const { loginAsProfileDetails, primaryUserDetails } = useContext(AppContext)

  //Api
  const { getClubById } = useClubAPI()

  const clubId = loginAsProfileDetails?.club?.id
    ? loginAsProfileDetails?.club?.id
    : primaryUserDetails?.club?.id
  // useEffect
  useEffect(() => {
    if (clubId) fetchClubDetails()
  }, [])

  // Functions
  const fetchClubDetails = async () => {
    try {
      if (!clubId) return
      const r = await getClubById(String(clubId))
      setClubData(r?.data)
    } catch {}
  }

  return (
    <div className="flex flex-col shadow-md rounded-2xl bg-white">
      <img src={BackgroundImage} className="relative" alt="Background" />
      <div className="flex justify-center items-center bg-gr-primaryN9 border-[1px] border-gr-neutral07 min-w-[100px] h-[28px] rounded-[30px] mt-[10px] absolute right-[10px]">
        <span
          className={` ${
            showProgress < 100 ? "ml-[10px]" : ""
          } text-[12px] text-gr-neutral07 font-inter font-semibold `}
        >
          <Link to={AUTH_MY_PROFILE} className="hover:underline">
            {showProgress < 100 ? "Complete Profile" : "View Profile"}
          </Link>
        </span>
        {showProgress < 100 ? <FrontArrow width={20} height={20} className="mt-[2px]" /> : null}
      </div>
      <div className="flex justify-center items-center rounded-full w-[89px] h-[89px] mt-[54px] bg-gr-primaryR1 absolute left-1/2 transform -translate-x-1/2">
        <Link to={AUTH_MY_PROFILE}>
          <img
            src={clubData?.attributes?.clubLogo?.url ?? ClubLogo}
            className="w-[89px] h-[89px] rounded-full"
          />
        </Link>
      </div>
      <div className="mt-[45px] flex flex-col items-center justify-center mx-8 text-center gap-2">
        <Link to={AUTH_MY_PROFILE} className="hover:underline">
          <span className="text-[18px] font-semibold font-inter">
            {loginAsProfileDetails?.club?.name
              ? loginAsProfileDetails?.club?.name
              : primaryUserDetails?.club?.name}
          </span>
        </Link>
        <span className="text-sm text-gr-placeHolder font-inter hover:text-gr-primaryR2">
          <Link to={AUTH_FOLLOWERS}>
            {(clubData?.memberCount || 0) + (clubData?.followerCount || 0)}{" "}
            {(clubData?.memberCount || 0) + (clubData?.followerCount || 0) > 1
              ? "Followers"
              : "Follower"}
          </Link>
        </span>
        {showProgress < 100 ? (
          <>
            <div className="mt-2 self-start text-xs text-gr-placeHolder font-inter">
              Your profile is{" "}
              <span>
                <b>15%</b>{" "}
              </span>
              complete
            </div>
            <div className="bg-gray-200 rounded-full h-[8px] mt-[4px] w-full">
              <div
                className="bg-gradient-to-r from-[#0E2F55] via-[#0E2F55] to-[#004D85] h-[8px] w-full rounded-[20px] dark:bg-placeHolder"
                style={{ width: "25%" }}
              ></div>
            </div>
            <div className="text-[12px] font-inter mt-2 mb-6 text-left">
              Complete your profile to enhance your experience and increase your profile visibility.
            </div>
          </>
        ) : (
          <div className="mt-4"></div>
        )}
      </div>
    </div>
  )
}

export default Profile
