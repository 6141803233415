import React, { createContext, useContext, useEffect, useState } from "react"

// Define the context
interface ScreenWidthContextProps {
  screenWidth: number
}

const ScreenWidthContext = createContext<ScreenWidthContextProps | undefined>(undefined)

// Define a custom hook to use the screen width context
export const useScreenWidth = () => {
  const context = useContext(ScreenWidthContext)
  if (!context) {
    throw new Error("useScreenWidth must be used within a ScreenWidthProvider")
  }
  return context.screenWidth
}

// Define the provider component
interface ScreenWidthProviderProps {
  children: React.ReactNode
}

const ScreenWidthProvider = ({ children }: ScreenWidthProviderProps) => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const contextValue: ScreenWidthContextProps = {
    screenWidth,
  }

  return <ScreenWidthContext.Provider value={contextValue}>{children}</ScreenWidthContext.Provider>
}

export default ScreenWidthProvider
