import React, { useEffect, useState } from "react"
import Header from "../components/landing/Header"
import Login from "../components/landing/Login"
import AboutUs from "../components/landing/AboutUs"
import MemberBenefits from "../components/landing/MemberBenefits"
import Partners from "../components/landing/Partners"
import { ILandingPageData, useLoginUserAPI } from "../services/useLoginUserAPI"
import Register from "../components/landing/Register"
import Banner from "../components/landing/Banner"
import Footer from "../components/club/clubprofile/Footer"

const Landing = () => {
  const { getLandingPageDetails } = useLoginUserAPI()
  //Hook
  const [landingPage, setLandingPage] = useState<ILandingPageData>()
  const [isCreateAccount, setIsCreateAccount] = useState<boolean>(false)

  useEffect(() => {
    getLandingPage()
  }, [])

  // scroll down smoothly
  useEffect(() => {
    const hash = window?.location?.hash
    if (hash) {
      const elements = document.getElementsByClassName(hash)
      const element = elements?.length > 0 ? elements[0] : undefined
      if (element) {
        element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" })
      }
    }
  }, [landingPage])

  const onCreateAccount = () => {
    setIsCreateAccount(!isCreateAccount)
  }

  const getLandingPage = async () => {
    const landingPage = await getLandingPageDetails()
    setLandingPage(landingPage?.data)
  }

  return (
    <div className="mt-2">
      <section className="md:px-16">
        <Header
          topNavBar={landingPage?.topNavBar}
          onCreateAccount={onCreateAccount}
          isCreateAccount={isCreateAccount}
        />
      </section>
      <section
        className="p-4 md:px-16 max-[600px]:h-[850px] h-[750px]"
        style={{
          backgroundImage: `url(${landingPage?.image_1?.url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="md:flex  md:justify-evenly mb-4">
          <div className="w-full md:w-[40%] flex justify-center"></div>
          <div className="w-full md:w-[60%] max-[390px]:-mt-8">
            {isCreateAccount ? <Register /> : <Login />}
          </div>
        </div>
      </section>
      <section
        className="px-4 md:px-16 h-[975px] sm:h-[885px] lg:h-[530px] #about-us"
        id={`${"about-us"}`}
      >
        <div className="mt-12 md:mt-24 ">
          {landingPage?.aboutUs && (
            <div className="w-full">
              <AboutUs
                aboutUsDetails={landingPage?.aboutUs}
                landingPageTwo={landingPage?.image_2?.url}
              />
            </div>
          )}
        </div>
      </section>

      <section
        className="mt-20 px-4 md:px-16 h-[580px] lg:h-[510px] #member-benefits"
        id={`${"member-benefits"}`}
      >
        <div className=" md:mt-32">
          {landingPage?.memberBenefits && (
            <MemberBenefits memberBenefits={landingPage?.memberBenefits} />
          )}
        </div>
      </section>
      <section className="px-4 md:px-8 h-[410px] #partners" id={`${"partners"}`}>
        <div className="mt-20 md:mt-32">
          {landingPage?.partners && <Partners partners={landingPage?.partners} />}
        </div>
      </section>
      <section className=" md:-px-4">
        <div className="mt-20 md:mt-32">
          {landingPage?.banner && <Banner banner={landingPage?.banner} />}
        </div>
      </section>
      <footer className="">
        <Footer />
      </footer>
    </div>
  )
}

export default Landing
