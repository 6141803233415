import cookies from "js-cookie"
import { IRegisterUserResponse, IUserLoginResponse } from "../modals/Auth"
import useBaseAPI from "./useBaseAPI"

export const useAuthAPI = () => {
  const { POST, isLoading, PUT, GET } = useBaseAPI()

  const logout = () => {
    cookies.remove("authToken")
  }

  const register = (payload: IRegisterUserPayload): Promise<IRegisterUserResponse> => {
    return POST("/auth/local/register", payload)
  }

  const login = (payload: ILoginUserPayload): Promise<IUserLoginResponse> => {
    return POST("/auth/local", payload)
  }

  const forgotPassword = (payload: { email: string }): Promise<any> => {
    return POST("/auth/forgot-password", payload)
  }

  const resetPassword = (payload: IResetPasswordPayload): Promise<IUserLoginResponse> => {
    return POST("/auth/reset-password", payload)
  }

  const setNewPassword = (payload: ISetNewPasswordPaylod): Promise<IUserLoginResponse> => {
    return PUT("/set-new-password", payload)
  }

  const updateUserStatus = (payload: IUpdateUserStatusPayload): Promise<any> => {
    return PUT(`/update-status`, payload)
  }

  const isValidateSamePassword = (password: string, code: string): Promise<any> => {
    return GET(`/valid-same-password/${password}/${code}`)
  }

  return {
    isLoading,
    register,
    login,
    logout,
    forgotPassword,
    resetPassword,
    setNewPassword,
    updateUserStatus,
    isValidateSamePassword,
  }
}

export interface IErrorResponse {
  status: number
  name: string
  message: string
  details: unknown
}

export interface IUpdateUserStatusPayload {
  data: {
    referredUser: number
    referralCode: string
  }
}

export interface IRegisterUserPayload {
  email: string
  password: string
  username: string
  firstname: string
  lastname: string
  profileType: string
  dob?: string
  playingLevel?: string
  discipline?: string
  city?: string
  country?: string
  state?: string
  middlename?: string
  gender?: string | null
  intlEligibility?: string
  nationality?: string
  level?: string | null
  speciality?: string
  higestLevelPlayed?: string
  contact?: string
  address?: string
  zipCode?: string
  confirmed?: boolean
}

export interface IRegisterChildPayload {
  email: string
  password: string
  username: string
  firstname: string
  lastname: string
  middlename?: string
  profileType: string
  dob?: string
  gender?: string | null
  internationalEligibilities?: string
  nationality?: string
  level?: string | null
  highestLevelPlayed?: string | null
  strengths?: string
  discipline?: string
  city?: string
  country?: string
  state?: string
  contact?: string
  address?: string
  postCode?: string
  confirmed?: boolean
  isChild: boolean
}
export interface ILoginUserPayload {
  identifier: string
  password: string
}

export interface IResetPasswordPayload {
  code: string
  password: string
  passwordConfirmation: string
}

export interface ISetNewPasswordPaylod {
  data: {
    password: string
    passwordConfirmation: string
  }
}
