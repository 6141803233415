import React from "react"
import UserHeroSection from "../components/playerProfile/UserHeroSection"
import ProfileImage from "../assets/svg/ProfileImage.svg"
import MessageIcon from "../assets/svg/MessageIcon"

const UserAllClubs = () => {
  return (
    <div>
      <div className="flex flex-col items-center  ">
        <div className=" ">
          <UserHeroSection />
        </div>

        <div className={"bg-white rounded-2xl pt-5 w-[360px] sm:w-[95%] md:w-[92%] sm:mt-4"}>
          <div className="px-4 flex justify-between items-center mb-4">
            <p className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">
              My Clubs (26)
            </p>
          </div>
          <div className="px-4 mb-4">
            {Array(10)
              .fill(1)
              ?.map((el, indx) => (
                <div
                  key={indx}
                  className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5 hover:bg-bgRed cursor-pointer
                  ${indx === 0 && "bg-bgRed"}`}
                >
                  <div className="flex justify-start gap-4 items-center">
                    <div>
                      <img
                        src={ProfileImage}
                        alt="profile-image"
                        className="rounded-full max-w-[44px] max-h-[44px]"
                      />
                    </div>
                    <div>
                      <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px]">
                        Club Name
                      </p>
                      <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                        1,928 Members
                      </p>
                      {indx === 0 && (
                        <p className="underline text-[11px] text-red-darkRed font-inter mt-[5px] cursor-pointer">
                          Claim for admin
                        </p>
                      )}
                      {indx !== 0 && (
                        <p
                          className="rounded-full text-[11px] w-[46px]  py-[1px] px-[7px] mt-[3px] cursor-pointer"
                          style={{ backgroundColor: "rgba(0, 132, 21, 0.2)" }}
                        >
                          Admin
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <MessageIcon height={20} width={20} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserAllClubs
