import React, { useState, useEffect, useContext } from "react"
import GRTextfield from "../components/ui/GRTextfield"
import { GRSwitch } from "../components/ui/GRSwitch"
import FilterOpportunityPopup from "../components/dashboard/Opportunities/FilterOpportunityPopup"
import OpportunityCard from "../components/dashboard/Opportunities/OpportunityCard"
import FilterOpportunity from "../components/dashboard/Opportunities/FilterOpportunity"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import { Link } from "react-router-dom"
import { AUTH_DASHBOARD } from "../routes/RouteConstants"
import LeftArrow from "../assets/svg/LeftArrow"
import AppContext from "../context/app-context/AppContext"
import { IOpportunitySummary } from "../modals/Opportunity"
import { useOpportunityAPI } from "../services/useOpportunityAPI"
import { debounce } from "lodash"
import MobileOpportunities from "../components/dashboard/Opportunities/MobileOpportunities"
import { useScreenWidth } from "../context/ScreenWidthContext"

const Opportunity = () => {
  const [isAppliedJobs, setIsAppliedJobs] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[]>()
  const [searchKey, setSearchKey] = useState<string>()

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // APIs
  const { getOpportunityByProfileType, searchOpportunityByKey, getApplyOpportunity } =
    useOpportunityAPI()

  useEffect(() => {
    getOpportunity()
  }, [])

  useEffect(() => {
    getOpportunity()
  }, [searchKey])

  const getOpportunity = async () => {
    try {
      let opportunityList
      if (loginAsProfileDetails?.profileType === "Parent") {
        if (searchKey) {
          opportunityList = await searchOpportunityByKey(searchKey)
        } else {
          opportunityList = await getOpportunityByProfileType(
            `profileType=${loginAsProfileDetails?.profileType}`,
          )
        }

        const filteredOpportunities = opportunityList?.opportunity?.filter(
          (opportunity) =>
            opportunity?.ageGroup?.title === "Youth Mens" ||
            opportunity?.ageGroup?.title === "Youth Womens",
        )

        setOpportunities(filteredOpportunities)
      } else if (loginAsProfileDetails?.isChild === true) {
        if (searchKey) {
          opportunityList = await searchOpportunityByKey(searchKey)
        } else {
          opportunityList = await getOpportunityByProfileType(
            `profileType=${loginAsProfileDetails?.profileType}`,
          )
        }
        const filteredOpportunities = opportunityList?.opportunity?.filter(
          (opportunity) =>
            opportunity?.ageGroup?.title === "Youth Mens" ||
            opportunity?.ageGroup?.title === "Youth Womens",
        )

        setOpportunities(filteredOpportunities)
      } else if (loginAsProfileDetails?.profileType === "Coach") {
        if (searchKey) {
          opportunityList = await searchOpportunityByKey(searchKey)
        } else {
          opportunityList = await getOpportunityByProfileType(
            `profileType=${loginAsProfileDetails?.profileType}`,
          )
        }
        const filteredOpportunities = opportunityList?.opportunity?.filter(
          (opportunity) => opportunity?.profileType === "Coach",
        )

        setOpportunities(filteredOpportunities)
      } else {
        if (searchKey) {
          opportunityList = await searchOpportunityByKey(searchKey)
        } else {
          opportunityList = await getOpportunityByProfileType(
            `profileType=${loginAsProfileDetails?.profileType}`,
          )
        }
        setOpportunities(opportunityList?.opportunity)
      }
    } catch (error) {
      // Handle error
    }
  }

  const applyOpportunity = async () => {
    try {
      const opportunityList = await getApplyOpportunity()
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }

  const fillterApplyOpportunity = async () => {
    try {
      if (isAppliedJobs) {
        getOpportunity()
        setIsAppliedJobs(!isAppliedJobs)
      } else {
        applyOpportunity()
        setIsAppliedJobs(!isAppliedJobs)
      }
    } catch (error) {}
  }

  const debounceSearch = debounce((event) => setSearchKey(event.target.value), 1000)
  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 650 && screenWidth < 1250

  return (
    <div>
      <div className="sm:hidden flex items-center px-4 mb-4 sm:mt-0 mt-4">
        <div className="flex justify-start items-center font-inter">
          <Link to={AUTH_DASHBOARD} className="flex items-center justify-start">
            <LeftArrow className="mr-3 w-3 min-w-3" /> Back
          </Link>
        </div>
      </div>
      <HeaderSearchFeild />

      <div className="">
        <div className="flex justify-end gap-2 items-center sm:pb-[5px] pb-4 sm:ml-[17px] md:ml-[38px] sm:hidden sm:px-0 px-4">
          <div className="text-sm text-gr-neutralT3 w-max font-inter">
            Show only opportunities that you've applied for previously
          </div>
          <div className="sm:ml-[10px] md:ml-[27px]">
            <GRSwitch onChange={() => fillterApplyOpportunity()} value={isAppliedJobs} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center sm:hidden ">
          {!open ? (
            <>
              <div className="w-full px-4">
                <MobileOpportunities opportunities={opportunities} />
              </div>
            </>
          ) : (
            <>
              <div className="absolute z-10 w-screen h-screen bg-black opacity-[0.6]"></div>
              <div className="z-50">
                <FilterOpportunityPopup
                  setOpen={setOpen}
                  setOpportunity={setOpportunities}
                  getOpportunities={getOpportunity}
                />
              </div>
            </>
          )}
        </div>
        <div className="hidden sm:flex px-4 md:px-6 mt-8 justify-between mb-8">
          <div
            className={`w-full h-[742px] ${
              isLargeScreen
                ? "flex-[25%] max-w-[25%]"
                : isMediumScreen
                ? "flex-[35%] max-w-[35%]"
                : "flex-[100%] max-w-[100%]"
            }`}
          >
            <FilterOpportunity
              setOpportunity={setOpportunities}
              getOpportunities={getOpportunity}
            />
          </div>
          <div
            className={`${
              isLargeScreen
                ? "flex-[73%] max-w-[73%]"
                : isMediumScreen
                ? "flex-[62%] max-w-[62%]"
                : "flex-[100%] max-w-[100%]"
            } flex flex-col`}
          >
            {/* topSection - searchBar  and toggleBtn */}
            <div className="hidden sm:flex lg:h-[50px] justify-between mb-4 sm:w-full md:w-full">
              <div className="sm:w-full flex items-center lg:flex-row sm:flex-col">
                <GRTextfield
                  type="searchField"
                  width="100%"
                  height="50px"
                  placeholder="Search opportunity by position, level and age group"
                  onChange={debounceSearch}
                />
                <div className="flex md:w-auto w-full items-center md:pb-[5px] md:ml-20 md:justify-normal justify-between md:mt-0 sm:mt-4 mt-0">
                  <div className={`text-sm text-blue-dark1 `}>
                    Show only opportunities that you've applied for previously
                  </div>
                  <div className="ml-2">
                    <GRSwitch onChange={() => fillterApplyOpportunity()} value={isAppliedJobs} />
                  </div>
                </div>
              </div>
            </div>

            {/* opportunityList */}
            {opportunities?.length == 0 ? (
              <div
                className="bg-white rounded-2xl flex justify-center items-center"
                style={{ height: "calc(100% - 48px)" }}
              >
                <div className="font-inter text-gray-500 text-lg">No opportunities found</div>
              </div>
            ) : (
              <div className=" rounded-top rounded-2xl bg-white pt-5">
                {opportunities &&
                  opportunities?.map((opportunity, index) => (
                    <OpportunityCard opportunity={opportunity} key={index} />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Opportunity
