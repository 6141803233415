import { IUploadFilesResponse } from "../../modals/Config"
import React from "react"
import GRButton from "../ui/GRButton"
import ClubLogo from "../../assets/svg/ClubLogo.svg"

interface ProfileImgProps {
  isShowProfileImg: boolean
  onClose: () => void
  profilePicture?: IUploadFilesResponse
}

const ProfileImg: React.FC<ProfileImgProps> = ({ isShowProfileImg, onClose, profilePicture }) => {
  if (!isShowProfileImg) {
    return null
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 sm:p-8 rounded-lg w-[300px] h-[350px]  sm:w-[500px] sm:h-[550px]">
        <img
          src={profilePicture?.url ?? ClubLogo}
          alt="Profile"
          className="w-full h-auto rounded-[300px]"
        />
        <div className="mt-4 ml-[170px]  sm:ml-[330px]">
          <GRButton label="Close" className="!px-[25px] sm:!px-[35px]" onClick={onClose} />
        </div>
      </div>
    </div>
  )
}

export default ProfileImg
