import React, { useState, useEffect, useContext } from "react"
import ClubCard from "./ClubCard"
import FilterIcon from "../../../assets/svg/FilterIcon"
import FilterOpportunityPopup from "./FilterOpportunityPopup"
import { useOpportunityAPI } from "../../../services/useOpportunityAPI"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import AppContext from "../../../context/app-context/AppContext"
import { useUserHook } from "../../../hooks/useUserHook"

interface IOpportunitiesValue {
  opportunities: IOpportunitySummary[] | undefined
}

const MobileOpportunities = (props: IOpportunitiesValue) => {
  const [open, setOpen] = useState<boolean>(false)
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[]>()
  const [showAllOpportunities, setShowAllOpportunities] = useState<boolean>(false)

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // Hook
  const { isLoginAsClub } = useUserHook()

  // APIs
  const { getOpportunityByProfileType, getOpportunityByClubId } = useOpportunityAPI()

  useEffect(() => {
    setOpportunities(props?.opportunities)
  }, [props?.opportunities])

  const getOpportunity = async () => {
    try {
      const opportunityList =
        isLoginAsClub && loginAsProfileDetails?.club?.id
          ? await getOpportunityByClubId(loginAsProfileDetails?.club?.id)
          : await getOpportunityByProfileType(`profileType=${loginAsProfileDetails?.profileType}`)
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }

  // Toggle the display of all opportunities
  const toggleShowAllOpportunities = () => {
    setShowAllOpportunities((prevShowAll) => !prevShowAll)
  }

  // Display only limited opportunities based on showAllOpportunities state
  const limitedOpportunities = showAllOpportunities ? opportunities : opportunities?.slice(0, 3) // Display the first 3 opportunities

  return (
    <div className="flex flex-col bg-white pb-3 rounded-2xl">
      {open ? (
        <FilterOpportunityPopup
          setOpen={setOpen}
          setOpportunity={setOpportunities}
          getOpportunities={getOpportunity}
        />
      ) : (
        <div>
          <div className="flex items-center justify-between p-4 pb-0">
            <div className="">
              <h2 className="text-xl font-semibold font-inter">Opportunities</h2>
            </div>
            <div className="cursor-pointer">
              <div className="text-blue-400" onClick={() => setOpen(true)}>
                <FilterIcon />
              </div>
            </div>
          </div>
          <div className={`overflow-auto`}>
            {limitedOpportunities && limitedOpportunities.length !== 0 ? (
              limitedOpportunities.map((opportunity, index) => (
                <ClubCard opportunity={opportunity} key={index} />
              ))
            ) : (
              <div className="font-inter text-gray-500 text-md text-center mb-[60%] mt-[60%] md:mt-[25%]">
                No opportunities found!
              </div>
            )}
          </div>
          {opportunities && opportunities.length > 3 && (
            <div className="flex justify-center items-center pr-4">
              <button
                onClick={toggleShowAllOpportunities}
                className={`font-inter text-gr-neutralT4 text-[13px] font-semibold underline pb-3`}
              >
                {showAllOpportunities ? "View less" : "View all"}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MobileOpportunities
