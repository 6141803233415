import React from "react"
import "react-multi-carousel/lib/styles.css"
import Carousel from "react-multi-carousel"
import { VideoType } from "./EditHighlightPopup"
import { IUser } from "../../modals/User"

interface IHighlightCrousalProps {
  handlePlay: (url: string) => void
  setPostContent: (description: string) => void
  profileDetails?: IUser
}

export const HighlightCrousal = (props: IHighlightCrousalProps) => {
  const { handlePlay, profileDetails, setPostContent } = props

  if (!profileDetails || !profileDetails.videoHighlights) {
    return <p>No highlights available.</p>
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }

  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={true}
      infinite={false}
      autoPlay={false}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["mobile"]}
      deviceType={"desktop"}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px sm:mr-0 mr-4 last:mr-0"
      centerMode={true}
    >
      {profileDetails?.videoHighlights.map((vh, idx) => (
        <div
          key={idx}
          onClick={(e) => {
            e.stopPropagation()

            if (vh.type === VideoType.url) {
              handlePlay(`https://www.youtube.com/embed/${vh.videoURL?.split("=")[1]}`)
            } else if (vh?.type === VideoType.embed) {
              const srcMatch = vh?.videoEmbed.match(/src="([^"]+)"/)
              if (srcMatch) handlePlay(srcMatch[1])
            } else {
              handlePlay(vh?.video?.url)
            }
          }}
        >
          {vh.type === "Upload video" ? (
            <div
              className="mr-4 rounded-sm w-48 h-32 relative"
              key={idx}
              onClick={(e) => {
                e.stopPropagation()
                setPostContent(vh?.description)
                if (vh?.type === VideoType.url) {
                  handlePlay(`https://www.youtube.com/embed/${vh?.videoURL?.split("=")[1]}`)
                } else if (vh?.type === VideoType.embed) {
                  //how to show embed video
                  const srcMatch = vh?.videoEmbed?.match(/src="([^"]+)"/)
                  if (srcMatch) handlePlay(srcMatch[1])
                } else {
                  handlePlay(vh?.video?.url)
                }
              }}
            >
              <video
                autoPlay={false}
                className="rounded-sm cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300"
                src={vh?.video?.url}
              />
            </div>
          ) : (
            <div className="sm:w-48 sm:h-32 w-full h-20 relative flex items-center justify-center transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300">
              {vh?.videoURL && vh?.videoURL.includes("https://") && (
                <iframe
                  className="relative sm:w-48 w-full iframecss"
                  src={`https://www.youtube.com/embed/${new URL(vh?.videoURL).searchParams.get(
                    "v",
                  )}`}
                />
              )}
              <div className="w-full h-full absolute top-0 left-0 cursor-pointer z-20"></div>
            </div>
          )}
        </div>
      ))}
    </Carousel>
  )
}
