import React from "react"
import FilterIcon from "../../../assets/svg/FilterIcon"
import { Checkbox } from "@mui/material"

interface IFilterValue {
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
  setIsInactive: React.Dispatch<React.SetStateAction<boolean>>
  isActive: boolean
  isInactive: boolean
}
const ClubFilterCard = (props: IFilterValue) => {
  const { setIsActive, setIsInactive, isActive, isInactive } = props
  return (
    <div className="relative rounded-2xl p-5 bg-white h-full">
      {/* icon and heading */}
      <div className="flex items-center text-lg mb-3">
        <div className="">
          <FilterIcon />
        </div>
        <div className="text-gr-neutralT2 text-base font-inter font-medium ml-2">Filter by :</div>
      </div>

      {/* filterOptions */}
      <div className="font-inter text-sm">
        <div className="flex items-center -ml-[10px]">
          <div>
            <Checkbox
              onChange={(e) => {
                setIsActive(e.target.checked)
                setIsInactive(e.target.checked)
              }}
            />
          </div>
          <div className="font-inter font-sm">All</div>
        </div>
        <div className="flex items-center -ml-[10px]">
          <div>
            <Checkbox
              checked={isActive}
              onChange={(e) => {
                setIsActive(e.target.checked)
              }}
            />
          </div>
          <div className="font-inter font-sm">Active</div>
        </div>
        <div className="flex items-center -ml-[10px]">
          <div>
            <Checkbox
              checked={isInactive}
              onChange={(e) => {
                setIsInactive(e.target.checked)
              }}
            />
          </div>
          <div className="font-inter font-sm">Inactive</div>
        </div>
      </div>
    </div>
  )
}

export default ClubFilterCard
