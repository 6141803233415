import { successToast } from "../utils/ToastUtils"

export const usePostHook = () => {
  const copyToClipBord = async (copyMe: string) => {
    try {
      // Use either the older document.execCommand('copy') method or the newer navigator.clipboard.writeText() method.
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(copyMe)
          .then(() => {
            successToast("Copied!!")
          })
          .catch((error) => {
            console.error("Failed to copy:", error)
          })
      } else {
        const textarea = document.createElement("textarea")
        textarea.value = copyMe
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand("copy")
        document.body.removeChild(textarea)
        successToast("Copied!!")
      }
    } catch (error) {}
  }

  return {
    copyToClipBord,
  }
}
