import React, { useEffect, useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import GRTextfield from "../ui/GRTextfield"
import FacebookIcon from "../../assets/svg/FacebookIcon"
import InstagramIcon from "../../assets/svg/InstagramIcon.svg"
import LinkedInIcon from "../../assets/svg/LinkedInIcon"
import { IconButton, InputAdornment } from "@mui/material"
import DialogHeader from "./DialogHeader"
import { IClubDetails } from "../../modals/Club"
import useClubHook from "../../hooks/useClubHook"
import { errorToast } from "../../utils/ToastUtils"
import { ClubContactDetailsValidations } from "../../validations/Validations"
import TwitterIcon from "../../assets/svg/clubprofileicon/Twitter.svg"
import TiktokIcon from "../../assets/svg/Tiktok.svg"
import SnapChatIcon from "../../assets/svg/SnapChat.svg"
import useLocationHook from "../../hooks/useLocationHook"
import { TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { AutocompleteInputCss } from "../ui/GRSelect"
import { CountryArray } from "../../utils/Constants"

interface IClubContactInfoFormValues {
  email?: string
  mobileNumber?: string
  addressLine_1?: string
  addressLine_2?: string
  city?: string
  country?: string
  state?: string
  zipCode?: string
  facebookLink?: string
  instagramLink?: string
  linkedinLink?: string
  twitterLink?: string
}

const initialValues = {
  email: "",
  mobileNumber: "",
  addressLine_1: "",
  addressLine_2: "",
  city: "",
  country: "",
  state: "",
  zipCode: "",
  facebookLink: "",
  instagramLink: "",
  linkedinLink: "",
  twitterLink: "",
  tiktokLink: "",
  snapchatLink: "",
}

interface IClubContactDetailsDialogProps {
  open: boolean
  onClose: () => void
  clubData?: IClubDetails
  onSuccess: () => void
}

const ClubContactDetailsDialog: React.FC<IClubContactDetailsDialogProps> = (props) => {
  const { open, onClose, clubData, onSuccess } = props

  // Hook
  const [isNotState, setIsNotState] = useState<boolean>(false)
  const { updateClubProfile, isClubProfileLoading } = useClubHook()
  const {
    countryOptions,
    cityOptions,
    stateOptions,
    fetchStateByCountry,
    fetchLocationData,
    fetchCityByStateAndCountry,
  } = useLocationHook()

  useEffect(() => {
    fetchLocationData(clubData?.attributes?.country, clubData?.attributes?.state)
  }, [clubData?.attributes?.country, clubData?.attributes?.state])

  const handleSubmit = async (values: IClubContactInfoFormValues) => {
    try {
      if (!clubData) {
        errorToast("Something went wrong")
        return
      }
      await updateClubProfile(clubData?.id, values)
      onSuccess()
    } catch {}
  }

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      email: clubData?.attributes?.email || "",
      mobileNumber: clubData?.attributes?.mobileNumber || "",
      addressLine_1: clubData?.attributes?.addressLine_1 || "",
      addressLine_2: clubData?.attributes?.addressLine_2 || "",
      city: clubData?.attributes?.city || "",
      state: clubData?.attributes?.state || "",
      country: clubData?.attributes?.country || "",
      zipCode: clubData?.attributes?.zipCode || "",
      facebookLink: clubData?.attributes?.facebookLink || "",
      instagramLink: clubData?.attributes?.instagramLink || "",
      linkedinLink: clubData?.attributes?.linkedinLink || "",
      twitterLink: clubData?.attributes?.twitterLink || "",
      tiktokLink: clubData?.attributes?.tiktokLink || "",
      snapchatLink: clubData?.attributes?.snapchatLink || "",
    },
    onSubmit: handleSubmit,
    validationSchema: ClubContactDetailsValidations,
    enableReinitialize: true,
  })

  const body = (
    <div className="mt-[12px] mb-8 mx-[15px] lg:mx-[31px]">
      <div className="flex mt-3">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-2 flex flex-col sm:flex-row  gap-2 sm:gap-5 sm:h-[84px]">
          <div className="w-full  h-[84px]">
            <div className="font-inter font-medium text-sm text-gr-neutralT3">
              Email address<span className="text-red-600">*</span>
            </div>
            <div>
              <GRTextfield
                width="100%"
                id="email"
                placeholder="Enter email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
          </div>
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-sm text-gr-neutralT3">Contact number</div>
            <div>
              <GRTextfield
                width="100%"
                id="mobileNumber"
                placeholder="Enter mobile number"
                name="mobileNumber"
                onChange={formik.handleChange}
                value={formik.values.mobileNumber}
                error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
              />
            </div>
          </div>
        </div>

        <div className="mt-1 flex flex-col sm:flex-row  gap-2 sm:gap-5 sm:h-[84px]">
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Address line 1</div>
            <div>
              <GRTextfield
                width="100%"
                id="addressLine_1"
                name="addressLine_1"
                placeholder="Enter address line 1"
                onChange={formik.handleChange}
                value={formik.values.addressLine_1}
              />
            </div>
          </div>
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Address line 2</div>
            <div>
              <GRTextfield
                width="100%"
                id="addressLine_2"
                name="addressLine_2"
                placeholder="Enter address line 2"
                onChange={formik.handleChange}
                value={formik.values.addressLine_2}
              />
            </div>
          </div>
        </div>
        <div className="grid  gap-x-5 grid-cols-1 lg:grid-cols-3 lg:gap-6">
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">
              Country
              <span className="text-red-600">*</span>
            </div>
            <div className="mb-3">
              <Autocomplete
                id="country"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("country", newValue || "")
                  formik.setFieldValue("state", "")
                  formik.setFieldValue("city", "")
                  if (newValue) {
                    if (CountryArray?.includes(newValue)) {
                      fetchCityByStateAndCountry(newValue, "")
                      setIsNotState(true)
                    } else {
                      fetchStateByCountry(newValue)
                      setIsNotState(false)
                    }
                  }
                }}
                placeholder="Select country"
                renderOption={(formik, option) => (
                  <li {...formik} style={{ fontSize: "14px" }}>
                    {option}
                  </li>
                )}
                onBlur={formik?.handleBlur}
                value={formik?.values?.country}
                options={countryOptions?.map((c) => c?.name) || []}
                renderInput={(params) => (
                  <TextField
                    error={!!formik.touched.country && Boolean(formik.errors.country)}
                    {...params}
                    placeholder="Country"
                    sx={AutocompleteInputCss}
                  />
                )}
              />
              {formik?.touched?.country && formik?.errors?.country && (
                <div className=" text-[13px] text-red-600">{formik?.errors?.country}</div>
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">State</div>
            <div className="mb-3">
              <Autocomplete
                id="state"
                placeholder="Select state"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("state", newValue || "")
                  formik.setFieldValue("city", "")
                  if (newValue) {
                    fetchCityByStateAndCountry(formik.values.country, newValue)
                  }
                }}
                renderOption={(formik, option) => (
                  <li {...formik} style={{ fontSize: "14px" }}>
                    {option}
                  </li>
                )}
                onBlur={formik?.handleBlur}
                value={formik?.values?.state}
                readOnly={CountryArray?.includes(formik.values.country) ? true : false}
                options={stateOptions?.map((s) => s?.name) || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="State" sx={AutocompleteInputCss} />
                )}
              />
              {isNotState && (
                <div className="text-[13px] text-blue-600 lowercase first-letter:uppercase !font-inter">
                  The country has no state so you can select a city
                </div>
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">City</div>
            <div className="mb-3">
              <Autocomplete
                id="city"
                placeholder="Select city"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("city", newValue || "")
                }}
                renderOption={(formik, option) => (
                  <li {...formik} style={{ fontSize: "14px" }}>
                    {option}
                  </li>
                )}
                onBlur={formik?.handleBlur}
                value={formik?.values?.city}
                options={cityOptions?.map((c: { name: any }) => c?.name) || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="City" sx={AutocompleteInputCss} />
                )}
              />
            </div>
          </div>
        </div>
        <div className="mt-2 flex flex-col sm:flex-row  gap-2 sm:gap-5 sm:h-[84px]">
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">
              Postcode<span className="text-red-600">*</span>
            </div>
            <div>
              <GRTextfield
                width="100%"
                id="zipCode"
                name="zipCode"
                placeholder="Enter postcode"
                onChange={formik.handleChange}
                value={formik.values.zipCode}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              />
            </div>
          </div>
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Facebook Link</div>
            <div>
              <GRTextfield
                id="facebookLink"
                name="facebookLink"
                width="100%"
                placeholder="Enter Facebook link"
                value={formik.values.facebookLink}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <FacebookIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                error={formik?.touched?.facebookLink && Boolean(formik.errors.facebookLink)}
                helperText={formik?.touched?.facebookLink && formik?.errors?.facebookLink}
              />
            </div>
          </div>
        </div>
        <div className="mt-1 flex flex-col sm:flex-row  gap-2 sm:gap-5 sm:h-[84px]">
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Instagram Link</div>
            <div>
              <GRTextfield
                id="instagramLink"
                name="instagramLink"
                width="100%"
                placeholder="Enter Instagram link"
                value={formik.values.instagramLink}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img src={InstagramIcon} alt="icon" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                error={formik?.touched?.instagramLink && Boolean(formik.errors.instagramLink)}
                helperText={formik?.touched?.instagramLink && formik?.errors?.instagramLink}
              />
            </div>
          </div>
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Linkedin Link</div>
            <div>
              <GRTextfield
                id="linkedinLink"
                name="linkedinLink"
                width="100%"
                value={formik.values.linkedinLink}
                placeholder="Enter LinkedIn link"
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <LinkedInIcon width={27} height={27} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                error={formik?.touched?.linkedinLink && Boolean(formik.errors.linkedinLink)}
                helperText={formik?.touched?.linkedinLink && formik?.errors?.linkedinLink}
              />
            </div>
          </div>
        </div>
        <div className="mt-1 flex flex-col sm:flex-row  gap-2 sm:gap-5 sm:h-[84px]">
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Twitter link</div>
            <div>
              <GRTextfield
                id="twitterLink"
                name="twitterLink"
                width="100%"
                placeholder="Enter Twitter link"
                value={formik.values.twitterLink}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img src={TwitterIcon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                error={formik?.touched?.twitterLink && Boolean(formik.errors.twitterLink)}
                helperText={formik?.touched?.twitterLink && formik?.errors?.twitterLink}
              />
            </div>
          </div>
          <div className="w-full   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Snapchat link</div>
            <div>
              <GRTextfield
                id="snapchatLink"
                name="snapchatLink"
                width="100%"
                placeholder="Enter Snapchat link"
                value={formik.values.snapchatLink}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img src={SnapChatIcon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                error={formik?.touched?.snapchatLink && Boolean(formik.errors.snapchatLink)}
                helperText={formik?.touched?.snapchatLink && formik?.errors?.snapchatLink}
              />
            </div>
          </div>
        </div>
        <div className="mt-1 flex flex-col sm:flex-row  gap-2 sm:gap-5 sm:h-[84px]">
          <div className="w-[320px]   h-[84px]">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Tiktok link</div>
            <div>
              <GRTextfield
                id="tiktokLink"
                name="tiktokLink"
                width="100%"
                placeholder="Enter Tiktok link"
                value={formik.values.tiktokLink}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img src={TiktokIcon} width={20} height={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                error={formik?.touched?.tiktokLink && Boolean(formik.errors.tiktokLink)}
                helperText={formik?.touched?.tiktokLink && formik?.errors?.tiktokLink}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-7">
          <GRButton
            onClick={onClose}
            label="Cancel"
            type="button"
            className="sm:!px-[75px] !px-[49px]"
            width={115}
          />
          <GRButton
            isSubmitting={isClubProfileLoading}
            onClick={formik.handleSubmit}
            label="Save"
            className="sm:!px-[75px] !px-[49px]"
            width={115}
          />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <GRDialog
        open={open}
        onClose={onClose}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Contact details" onClose={onClose} />}
      />
    </>
  )
}

export default ClubContactDetailsDialog
