import { AxiosRequestConfig } from "axios"
import {
  ICountriesResponse,
  IDropDownOptionsResponse,
  IStatesResponse,
  IUploadFilesResponse,
} from "../modals/Config"
import useBaseAPI from "./useBaseAPI"

export const useConfigAPI = () => {
  const { GET, POST, DELETE } = useBaseAPI()

  const getDropDownOptions = (config?: AxiosRequestConfig): Promise<IDropDownOptionsResponse> => {
    return GET("/get-config", undefined, config)
  }

  const uploadFiles = (
    payload: any,
    setProgress?: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<IUploadFilesResponse[]> => {
    return POST(
      "/upload",
      payload,
      { headers: { "content-type": "multipart/form-data" } },
      false,
      setProgress,
    )
  }

  const deleteFiles = (id: number): Promise<any> => {
    return DELETE(`/upload/files/${id}`)
  }

  const getCountries = (query?: any): Promise<ICountriesResponse> => {
    return GET("/get-countries", query, undefined, true)
  }

  const getStatesByCountry = (countryCode: string): Promise<IStatesResponse> => {
    return GET(`/get-state-by-country/${countryCode}`, null, undefined, true)
  }

  const getCityByStateAndCountry = (countryCode: string, stateCode: string): Promise<any> => {
    return GET(
      `/get-cities-by-state-and-country/${countryCode}/${stateCode}`,
      null,
      undefined,
      true,
    )
  }

  const getUserCoordinates = (address: string): Promise<any> => {
    const config: AxiosRequestConfig<any> = {
      baseURL: "https://geocode.maps.co/",
    }
    return GET(`/search?q=${address}`, undefined, config)
  }

  const getUserCountryByCoordinates = (lat: number, lon: number): Promise<any> => {
    const config: AxiosRequestConfig<any> = {
      baseURL: "https://geocode.maps.co/",
    }
    return GET(`/reverse?lat=${lat}&lon=${lon}`, undefined, config)
  }

  return {
    getDropDownOptions,
    uploadFiles,
    deleteFiles,
    getCountries,
    getStatesByCountry,
    getCityByStateAndCountry,
    getUserCoordinates,
    getUserCountryByCoordinates,
  }
}
