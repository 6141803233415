import React, { useState, useContext, useEffect } from "react"
import FilterIcon from "../../assets/svg/FilterIcon"
import { useOpportunityAPI } from "../../services/useOpportunityAPI"
import { IOpportunitySummary } from "../../modals/Opportunity"
import AppContext from "../../context/app-context/AppContext"
import useLocationHook from "../../hooks/useLocationHook"
import { IState } from "../../modals/Config"
import SearchScreenFilterOptions from "./SearchScreenFilterOptions"

interface IFilterScreenProps {
  states: IState[]
  checkedStateList: string[]
  setCheckedStateList: React.Dispatch<React.SetStateAction<string[]>>
}

const FilterScreen = (props: IFilterScreenProps) => {
  const { states, checkedStateList, setCheckedStateList } = props
  const [showLocations, setShowLocations] = useState<boolean>(false)
  const handleToggleLocations = () => {
    setShowLocations(!showLocations)
  }
  const [, setOpportunities] = useState<IOpportunitySummary[]>()

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // APIs
  const { getOpportunityByProfileType } = useOpportunityAPI()

  // Hooks
  const { fetchCountries, fetchStateByCountry, stateOptions, countries } = useLocationHook()

  useEffect(() => {
    fetchCountries()
    getOpportunity()
  }, [])

  useEffect(() => {
    if (loginAsProfileDetails?.country && countries?.length) {
      fetchStateByCountry(loginAsProfileDetails?.country)
    }
  }, [loginAsProfileDetails?.country, countries])

  const getOpportunity = async () => {
    try {
      const opportunityList = await getOpportunityByProfileType(
        `profileType=${loginAsProfileDetails?.profileType}`,
      )
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }

  const statesOps = states?.map((item) => ({
    name: item.name,
    value: item.name,
  }))

  return (
    <div className=" h-full p-4 pt-5 bg-white rounded-2xl">
      <div className="flex items-center sm:gap-[10px] text-lg mb-4">
        <div className="">
          <FilterIcon />
        </div>
        <div className="text-gr-neutralT2 text-base font-inter font-medium">All Filters</div>
      </div>

      <div className=" mb-[24px]">
        <div className="mb-3 flex justify-between items-center pb-2 border-b border-gr-primaryN8">
          <div className="text-blue-dark2 font-medium text-base font-inter">Locations</div>
          <div
            className="text-gr-primaryN1 text-[10px] font-inter underline text-xs cursor-pointer hover:text-gr-primaryR2"
            onClick={handleToggleLocations}
          >
            {" "}
            {showLocations ? "View less" : "View all"}
          </div>
        </div>

        <div>
          <SearchScreenFilterOptions
            checkedStateList={checkedStateList}
            setCheckedStateList={setCheckedStateList}
            options={statesOps?.length ? statesOps : stateOptions?.length ? stateOptions : []}
            isShowAll={showLocations}
            allChecked={false}
          />
        </div>
      </div>
    </div>
  )
}

export default FilterScreen
