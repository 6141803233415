import React, { useContext } from "react"
import { useFormik } from "formik"
import GRTextfield from "../ui/GRTextfield"
import GRButton from "../ui/GRButton"
import { Link, useSearchParams } from "react-router-dom"
import { UNAUTH_FORGOTPASSWORD } from "../../routes/RouteConstants"
import { loginFormValidationSchema as validationSchema } from "../../validations/Validations"
import { ILoginUserPayload, useAuthAPI } from "../../services/useAuthAPI"
import { setAuthToken } from "../../utils/AuthUtils"
import AppContext from "../../context/app-context/AppContext"
import { asyncLocalStorage } from "../../utils/CommonUtils"

interface ILoginFormValues {
  email: string
  password: string
}

const LoginForm = () => {
  // Context
  const { loadApp } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const email = searchParams.get("email")

  // APIs
  const { login, isLoading } = useAuthAPI()

  const handleLogin = async (values: ILoginFormValues) => {
    try {
      const payload: ILoginUserPayload = {
        identifier: values?.email,
        password: values?.password,
      }
      const lr = await login(payload)

      if (!lr?.jwt && !lr?.user) return
      setAuthToken(lr?.jwt)
      await asyncLocalStorage.removeItem("currentProfile")
      await loadApp()
    } catch {}
  }

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: { email: email ? email : "", password: "" },
    validationSchema: validationSchema,
    onSubmit: handleLogin,
  })

  return (
    <div className=" w-full flex flex-col justify-center items-center  ">
      <form onSubmit={handleSubmit} className="w-full font-inter compeleteProfileForm">
        <div className="mb-[16px]">
          <label className="cursor-pointer block text-[15px] leading-6 font-[400]" htmlFor="email">
            Email
          </label>
          <GRTextfield
            name="email"
            type="email"
            width="100%"
            placeholder="Enter email id"
            value={values.email}
            onChange={handleChange}
            helperText={touched.email && errors.email}
            error={touched.email && Boolean(errors.email)}
          />
        </div>
        <div className="mb-3 h-[92px]">
          <label
            className="cursor-pointer block text-[15px] leading-6 font-[400]"
            htmlFor="password"
          >
            Password
          </label>
          <GRTextfield
            name="password"
            type="password"
            width="100%"
            placeholder="Enter password"
            value={values.password}
            onChange={handleChange}
            helperText={touched.password && errors.password}
            error={touched.password && Boolean(errors.password)}
          />
        </div>

        <div className="w-full flex justify-end mt-3  text-[13px] ">
          <Link
            to={UNAUTH_FORGOTPASSWORD}
            className="block  text-black-500 underline font-poppins hover:text-gr-primaryR2"
          >
            Forgot password?
          </Link>
        </div>
        <div className=" flex justify-center mt-3">
          <GRButton
            onClick={handleSubmit}
            isSubmitting={isLoading}
            size="md"
            label="Sign in"
            width="100%"
            className="!bg-primaryR2"
          />
        </div>
      </form>
    </div>
  )
}

export default LoginForm
