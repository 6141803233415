import AppContext from "../context/app-context/AppContext"
import { useContext, useState, useEffect } from "react"
import { ICountry, IState } from "../modals/Config"
import { useConfigAPI } from "../services/useConfigAPI"
import { CountryArray } from "../utils/Constants"

const useLocationHook = () => {
  const [countries, setCountries] = useState<ICountry[]>()
  const [states, setStates] = useState<IState[]>()
  const [cities, setCities] = useState<any>()

  const {
    getCountries,
    getStatesByCountry,
    getCityByStateAndCountry,
    getUserCountryByCoordinates,
  } = useConfigAPI()
  const { currentLocation, loginAsProfileDetails } = useContext(AppContext)
  const { geo } = loginAsProfileDetails ?? {}

  useEffect(() => {
    fetchCountries()
  }, [])

  const fetchCountries = async () => {
    try {
      const c = await getCountries()
      setCountries(c?.countries)
      return c?.countries
    } catch {}
  }

  const fetchStateByCountry = async (country: string) => {
    try {
      const _countries = countries?.length ? countries : await fetchCountries()
      const countryCode = _countries?.find((c) => c?.name === country)?.iso2
      if (!countryCode) return
      const s = await getStatesByCountry(countryCode)
      setStates(s?.states)
      return s?.states
    } catch {}
  }

  const fetchCityByStateAndCountry = async (country: string, state: string) => {
    try {
      if (CountryArray?.includes(country)) {
        const countryCode = countries?.find((c) => c?.name === country)

        if (!countryCode?.iso2 || !countryCode?.cIso2) return
        const c = await getCityByStateAndCountry(countryCode?.cIso2, countryCode?.iso2)
        setCities(c?.cities)
        return c?.cities
      } else {
        const _states = states?.length ? states : await fetchStateByCountry(country)

        const countryCode = countries?.find((c) => c?.name === country)?.iso2
        const stateCode = _states?.find((s) => s?.name === state)?.iso2

        if (!countryCode || !stateCode) return

        const c = await getCityByStateAndCountry(countryCode, stateCode)
        setCities(c?.cities)
        return c?.cities
      }
    } catch {}
  }

  const fetchLocationData = async (country?: string | null, state?: string | null) => {
    try {
      const _countries = countries?.length ? countries : await fetchCountries()
      setCountries(_countries)
      const countryCode = _countries?.find((c) => c?.name === country)?.iso2

      if (country && countryCode) {
        const _states = await getStatesByCountry(countryCode)
        setStates(_states?.states)
        const stateCode = _states?.states?.find((s) => s?.name === state)?.iso2

        if (state && stateCode) {
          const _cities = await getCityByStateAndCountry(countryCode, stateCode)
          setCities(_cities?.cities)
        }
      }
    } catch {}
  }

  const getGeoSortString = () => {
    if (currentLocation?.lat && currentLocation?.lng) {
      return [`_geoPoint(${currentLocation?.lat}, ${currentLocation?.lng}):asc`]
    } else if (geo?.lat && geo?.lng) {
      return [`_geoPoint(${geo?.lat}, ${geo?.lng}):asc`]
    } else {
      return undefined
    }
  }

  const getCountryByGeoCoordinates = async () => {
    try {
      if (currentLocation?.lat && currentLocation?.lng) {
        const res = await getUserCountryByCoordinates(currentLocation?.lat, currentLocation?.lng)
        return res?.address.country
      }
    } catch {}
  }

  const countryOptions = countries?.map((ie) => ({
    name: ie?.name,
    value: ie?.name,
  }))

  const stateOptions = states?.map((ie) => ({
    name: ie?.name,
    value: ie?.name,
  }))

  const cityOptions = cities?.map((ie: any) => ({
    name: ie?.name,
    value: ie?.name,
  }))

  return {
    countries,
    cities,
    states,
    countryOptions,
    stateOptions,
    cityOptions,
    fetchCountries,
    fetchStateByCountry,
    fetchCityByStateAndCountry,
    fetchLocationData,
    getGeoSortString,
    getCountryByGeoCoordinates,
  }
}

export default useLocationHook
