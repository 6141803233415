import GRButton from "../../../../components/ui/GRButton"
import { GRDialog } from "../../../../components/ui/GRDialog"
import React from "react"

interface IDeletePostDialogProps {
  isOpen: boolean
  onClose: () => void
  onDeletePost: () => void
}

const DeletePost = (props: IDeletePostDialogProps) => {
  const { isOpen, onClose, onDeletePost } = props
  const body = (
    <div className="flex justify-center border-b border-b-gr-primaryN8 font-inter p-8  ">
      <div className="flex flex-col items-center ">
        <div className="font-semibold sm:text-[25px] text-[17px]">Delete Post?</div>
        <div className="text-[#54595E] mb-10 sm:text-[19px] text-[13px]">
          Are you sure you want to delete this post?
        </div>
        <div className="flex sm:gap-12 gap-6" onClick={() => onClose()}>
          <GRButton
            label="No,cancel"
            size="sm"
            className="!px-[30px] !bg-white !text-gr-primaryR2"
            border="1px solid"
          />
          <GRButton
            onClick={() => onDeletePost()}
            label="Yes,Delete"
            size="sm"
            className=" !px-[30px]  "
          />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      <GRDialog
        open={isOpen}
        onClose={() => onClose()}
        dialogbody={body}
        fullWidth
        classes={{ paper: "!max-w-[580px] !w-full !rounded-[10px]" }}
      />
    </div>
  )
}

export default DeletePost
