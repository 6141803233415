import React from "react"

import ApprovedIcon from "../../assets/svg/ApprovedIcon"
import GRButton from "../ui/GRButton"
import { useNavigate } from "react-router-dom"
import { UNAUTH_LOGIN } from "../../routes/RouteConstants"
import { useAuthAPI } from "../../services/useAuthAPI"

function StepPasswordReset() {
  const navigate = useNavigate()
  // APIs
  const { logout } = useAuthAPI()

  const handleClick = () => {
    logout()
    navigate(UNAUTH_LOGIN)
  }
  return (
    <div className=" flex  justify-center ">
      <div className="w-full sm:w-[530px] mt-[140px] bg-white flex flex-col items-center rounded-lg sm:shadow-md px-[67px] py-4  ">
        <ApprovedIcon />
        <div className=" w-full flex flex-col text-center">
          <span className="text-xl md:text-[32px] font-microsoftTai mb-5 font-bold mt-2 ">
            Password Reset
          </span>
          <span className="text-[14px]">Password set successful, Please continue to login</span>
        </div>
        <form className="w-full compeleteProfileForm">
          <div className=" flex justify-center mt-7 mb-10">
            <GRButton
              onClick={handleClick}
              size="md"
              label="Continue"
              width="100%"
              className="!bg-primaryR2"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default StepPasswordReset
