import AppContext from "../context/app-context/AppContext"
import { useContext } from "react"

interface GeolocationPosition {
  coords: {
    latitude: number
    longitude: number
  }
}

export const CurrentLocationSettings = () => {
  const { setCurrentLocation } = useContext(AppContext)

  const updatePosition = async () => {
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (loc) => resolve(loc),
          (error) => reject(error),
        )
      })
      setCurrentLocation({
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      })
    } catch (error) {
      console.error("Failed to get geolocation:", error)
    }
  }
  return { updatePosition }
}
