import * as React from "react"

function SvgComponent({ size = 15 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M1.875 3.75h11.25M5 3.75V2.5a1.25 1.25 0 011.25-1.25h2.5A1.25 1.25 0 0110 2.5v1.25m1.875 0v8.75a1.25 1.25 0 01-1.25 1.25h-6.25a1.25 1.25 0 01-1.25-1.25V3.75h8.75zM8.75 6.875v3.75M6.25 6.875v3.75"
        stroke="#4C535D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
