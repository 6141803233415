import React from "react"
import trophy from "../../assets/trophy.png"
import { IMemberBenefits } from "../../services/useLoginUserAPI"
import CustomCarousel from "./CustomCarousel"

interface IMemberBenefitsProps {
  memberBenefits: IMemberBenefits | undefined
}

const MemberBenefits: React.FC<IMemberBenefitsProps> = ({ memberBenefits }) => {
  return (
    <div>
      <div className="text-center">
        <h1 className="m-b-h text-4xl font-bold">{memberBenefits?.heading}</h1>
      </div>
      <div className="mt-12">
        {memberBenefits?.members && (
          <CustomCarousel itemShow={3}>
            {memberBenefits &&
              memberBenefits?.members?.map((mb, index) => (
                <div key={index} className="flex">
                  <div
                    className={`mr-2 p-12 rounded-lg ${index % 2 === 1 ? "bg-gr-primaryR2" : ""}`}
                  >
                    <div
                      className={`rounded-md ${
                        index % 2 === 1 ? "bg-brown" : "bg-gr-primaryR2"
                      }  w-fit p-2.5`}
                    >
                      <img width={50} src={mb?.icon?.url ? mb?.icon?.url : trophy} />
                    </div>
                    <div
                      className={`mt-5 ${
                        index % 2 === 1 ? "text-white" : "lpauh"
                      } text-2xl font-semibold`}
                    >
                      <p>{mb?.heading}</p>
                    </div>
                    <div
                      className={`mt-5 ${
                        index % 2 === 1 ? "text-white" : "lpauh"
                      } text-lg font-light`}
                    >
                      <p>{mb?.description}</p>
                    </div>
                  </div>
                </div>
              ))}
          </CustomCarousel>
        )}
      </div>
    </div>
  )
}

export default MemberBenefits
