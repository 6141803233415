// Unauthenticated Route Constants
export const UNAUTH_LOGIN = "/login"
export const UNAUTH_REGISTER = "/register"
export const UNAUTH_FORGOTPASSWORD = "/forgot-password"
export const UNAUTH_RESETPASSWORD = "/reset-password"
export const UNAUTH_EMAIL_CONFIRMATION = "/email-confirmation"
export const UNAUTH_TERMSPOLICY = "/terms-policy"
export const UNAUTH_FAQ = "/frequently-asked-questions"

// Authenticated Route Constants
export const AUTH_DASHBOARD = "/dashboard"
export const AUTH_OPPORTUNITY = "/opportunity"
export const AUTH_OPPORTUNITY_DETAIL = "/opportunity-details"
export const AUTH_MY_PROFILE = "/my-profile"
export const AUTH_USER_PROFILE = "/user-profile"
export const AUTH_CLUB_OPPORTUNITY = "/club/opportunity"
export const AUTH_CONNECT_AGENT = "/connect-agent"
export const AUTH_CLUB_JOB_DETAIL = "/club/opportunity/detail"
export const AUTH_CLUB_DASHBOARD = "/club/dashboard"
export const AUTH_MESSAGE = "/message"
export const AUTH_CHAT = "chat"
export const AUTH_CLUB_CONTACT_DETAILS = "/club/contact-details"
export const AUTH_PLAYER_ALL_CLUBS = "/all-clubs"
export const AUTH_CLUB_PROFILE = "/club-profile"
export const AUTH_NOTIFICATION = "/notification"
export const AUTH_AGENT_PROFILE = "/agent-profile"
export const AUTH_SEARCH = "/search"
export const AUTH_AGENT_DASHBOARD = "/agent/dashboard"
export const NOT_FOUND = "/not-found"
export const AUTH_FOLLOWERS = "/followers"
export const AUTH_FOLLOWINGS = "/followings"
export const AUTH_REFFERALS = "/refferals"
export const AUTH_CHANGE_PASSWORD = "/change-password"
