import * as React from "react"

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_3312_22889)" fill="#4C535D">
        <path d="M7 14a7 7 0 117-7 7.007 7.007 0 01-7 7zM7 1.167A5.833 5.833 0 1012.833 7 5.84 5.84 0 007 1.167z" />
        <path d="M7.583 2.917H6.416V8.75h1.167V2.917zM7.583 9.916H6.416v1.167h1.167V9.916z" />
      </g>
      <defs>
        <clipPath id="clip0_3312_22889">
          <path fill="#fff" d="M0 0H14V14H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
