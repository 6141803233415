import React, { useState, useEffect, useContext } from "react"
import Opportunities from "./Opportunities/Opportunities"
import LocationIcon from "../../assets/svg/LocationIcon"
import { Link } from "react-router-dom"
import { AUTH_DASHBOARD, AUTH_OPPORTUNITY } from "../../routes/RouteConstants"
import LeftArrow from "../../assets/svg/LeftArrow"
import { IOpportunitySummary } from "../../modals/Opportunity"
import { useOpportunityAPI, IApplicantPayload } from "../../services/useOpportunityAPI"
import { errorToast, successToast } from "../../utils/ToastUtils"
import AppContext from "../../context/app-context/AppContext"
import { useParams, useNavigate } from "react-router-dom"
import { useUserHook } from "../../hooks/useUserHook"
import { CircularProgress } from "@mui/material"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import HeaderSearchFeild from "../Header/HeaderSearchFeild"
import { AUTH_CLUB_PROFILE } from "../../routes/RouteConstants"
import { useScreenWidth } from "../../context/ScreenWidthContext"
import { NOT_FOUND } from "../../routes/RouteConstants"

interface IJobsProfileButtonState {
  text: string
  color: string
  textColor: string
  showText: boolean
  isDisabled: boolean
}

function PlayerOpportunityDetail() {
  const [button, setButton] = useState<IJobsProfileButtonState>({
    text: "Apply Now",
    color: "bg-gr-primaryR2",
    textColor: "text-white",
    showText: false,
    isDisabled: false,
  })

  // Hooks
  const { id } = useParams()
  const { text, color, textColor, showText, isDisabled } = button
  const [opportunity, setOpportunity] = useState<IOpportunitySummary | null | undefined>(null)
  const [notApplicable] = useState<string>("You are not applicable to apply on this opportunity")
  const { isParent, isLoginAsParent } = useUserHook()
  const navigate = useNavigate()

  // Context

  const { loginAsProfileDetails } = useContext(AppContext)

  // APIs
  const { getOpportunityById, updateApplicants, isLoading } = useOpportunityAPI()

  useEffect(() => {
    getOpportunity()
  }, [id])

  const setAppliedButtonData = () => {
    setButton({
      text: "Applied",
      color: "bg-gr-secondaryR2",
      textColor: "text-gr-primaryR2",
      showText: false,
      isDisabled: true,
    })
  }
  const setApplyNowButtonData = () => {
    setButton({
      text: "Apply Now",
      color: "bg-gr-primaryR2",
      textColor: "text-white",
      showText: false,
      isDisabled: false,
    })
  }

  const getOpportunity = async () => {
    try {
      if (!id) return
      const opportunityList = await getOpportunityById(id)
      const applicant = opportunityList?.opportunity?.applicants.find(
        (applicant) => applicant?.id === loginAsProfileDetails?.id,
      )
      applicant ? setAppliedButtonData() : setApplyNowButtonData()

      setOpportunity(opportunityList?.opportunity)

      if (!opportunityList?.opportunity) {
        navigate(NOT_FOUND)
        setOpportunity(undefined)
      }
    } catch (error) {}
  }

  const applyForProfile = async (id: number | null | undefined) => {
    try {
      if (id) {
        const applyPayload: IApplicantPayload = {
          data: {
            id: id,
          },
        }
        const apply = await updateApplicants(applyPayload)
        if (apply?.opportunity) {
          setAppliedButtonData()
          successToast("Applied for opportunity successfully")
        }
      } else {
        errorToast("Something went wrong!")
      }
    } catch (error) {}
  }
  function calculateUserAge(birthdate: Date): number {
    const today = new Date()
    const birthDate = new Date(birthdate)

    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }

    return age
  }
  const dob = loginAsProfileDetails?.dob
  const userAge = dob ? calculateUserAge(new Date(dob)) : 0

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250

  if (opportunity === undefined) navigate(NOT_FOUND)

  return !opportunity ? (
    <></>
  ) : (
    <div>
      <div className="px-4 lg:hidden mb-4 lg:mt-0 mt-4">
        <Link to={AUTH_OPPORTUNITY} className="flex justify-start items-center font-inter">
          <LeftArrow className="mr-3 w-3" /> Back
        </Link>
      </div>
      <HeaderSearchFeild />
      <div className="text-[13px] font-inter text-gr-primaryN2 py-4 ml-16 font-medium hidden lg:block ">
        <Link to={AUTH_DASHBOARD}>Home</Link>
        {" > "} <Link to={AUTH_OPPORTUNITY}>Opportunities</Link>
        {" > "}Opportunity details
      </div>
      <div className="flex px-4 md:px-6 mb-8 justify-between">
        <div className={`w-full flex-[25%] max-w-[25%] lg:block hidden`}>
          <div className="">
            <Opportunities />
          </div>
        </div>

        <div
          className={`bg-white rounded-2xl ${
            isLargeScreen ? "flex-[73%] max-w-[73%]" : "flex-[100%] max-w-[100%]"
          }`}
        >
          <div className="flex sm:flex-row flex-col p-[30px] sm:gap-[30px] gap-5">
            <Link to={`${AUTH_CLUB_PROFILE}/${opportunity?.club?.id}`}>
              <img
                src={
                  opportunity?.club?.clubLogo?.formats?.thumbnail?.url
                    ? opportunity?.club?.clubLogo?.formats?.thumbnail?.url
                    : ClubLogo
                }
                className="min-w-[100px] w-[100px] h-[100px] lg:min-w-[180px] lg:w-[180px] rounded-lg lg:h-[180px] self-start "
              />
            </Link>

            <div className="flex flex-col ">
              <div className="flex flex-row justify-between mb-2">
                <span className="font-inter font-semibold uppercase sm:text-xl text-lg text-black hover:underline">
                  <Link to={`${AUTH_CLUB_PROFILE}/${opportunity?.club?.id}`}>
                    {opportunity?.club?.name}
                  </Link>
                </span>
                {/* {showStatus && (
                  <p className="hidden sm:block text-green-green1  shrink-0">Status in progress</p>
                )} */}
              </div>
              {/* {showStatus && (
                <p className="sm:hidden text-green-green1 shrink-0">Status in progress</p>
              )} */}
              <div className="text-gr-neutralT2 font-inter">
                <span className="text-base block mb-2">
                  {" "}
                  {opportunity?.playerPosition?.title
                    ? opportunity?.playerPosition?.title
                    : opportunity?.coachPosition?.title}
                </span>
                <div className="flex flex-row items-center mb-[10px]">
                  <LocationIcon />
                  <span className="text-sm font-semibold ml-1">{opportunity?.country}</span>
                </div>
                {/* desktop view */}
                <div className="block flex-row text-xs">
                  <span className="mb-2 mr-8 inline-block">
                    <span className="font-medium">Season: </span>
                    {opportunity?.season?.title}
                  </span>
                  <span className=" mr-8 mb-2 lg:mb-0 inline-block">
                    <span className="font-medium">Age group: </span>
                    {opportunity?.ageGroup?.title}
                  </span>
                  <span className=" mr-8 mb-2 lg:mb-0 inline-block">
                    <span className="font-medium">Level: </span>
                    {opportunity?.level?.title}
                  </span>
                  <span className=" mr-8 mb-2 lg:mb-0 inline-block">
                    <span className="font-medium">Code: </span>
                    {opportunity?.discipline?.title}
                  </span>
                </div>
                {isLoginAsParent ? (
                  <div>
                    {!(opportunity?.otherCriteria || opportunity?.description) && (
                      <>
                        <button
                          className={`block rounded-xl px-12 py-2 text-[16px] border border-gr-primaryR2 mt-4 ${color} ${textColor}`}
                          disabled={
                            opportunity?.ageGroup?.title === "Youth Mens" ||
                            opportunity?.ageGroup?.title === "Youth Womens" ||
                            (opportunity?.ageGroup?.title !== "Youth Mens" &&
                              opportunity?.ageGroup?.title !== "Youth Womens") ||
                            isDisabled
                          }
                          style={{
                            opacity:
                              opportunity?.ageGroup?.title === "Youth Mens" ||
                              opportunity?.ageGroup?.title === "Youth Womens" ||
                              (opportunity?.ageGroup?.title !== "Youth Mens" &&
                                opportunity?.ageGroup?.title !== "Youth Womens")
                                ? 0.5
                                : 1,
                          }}
                          onClick={() => applyForProfile(opportunity?.id)}
                          title={
                            isParent
                              ? "Switch to your child profile to apply for opportunities on their behalf"
                              : ""
                          }
                        >
                          {!isLoading ? (
                            text
                          ) : (
                            <CircularProgress size={14} style={{ color: "#fff" }} />
                          )}
                        </button>
                        {!opportunity?.status ||
                        loginAsProfileDetails?.profileType !== opportunity?.profileType ? (
                          <div className="hidden lg:block text-gray-500 text-xs mt-5 text-red-primaryRed">
                            <p>{notApplicable} </p>
                          </div>
                        ) : null}
                      </>
                    )}
                    {(opportunity?.ageGroup?.title === "Youth Mens" ||
                      opportunity?.ageGroup?.title === "Youth Womens") &&
                    opportunity?.profileType === "Player" ? (
                      <div className="hidden lg:block text-gray-500 text-xs mt-5">
                        Switch to your child profile to apply for opportunities on their behalf.
                      </div>
                    ) : (
                      <div className="hidden lg:block text-gray-500 text-xs mt-5">
                        You are ineligible to apply for this opportunity.
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {!(opportunity?.otherCriteria || opportunity?.description) && (
                      <>
                        <button
                          className={`block rounded-xl px-8 py-2 text-[16px] border border-gr-primaryR2 mt-2 ${color} ${textColor}`}
                          disabled={
                            !opportunity?.status ||
                            loginAsProfileDetails?.profileType !== opportunity?.profileType ||
                            ((opportunity?.ageGroup?.title === "Youth Mens" ||
                              opportunity?.ageGroup?.title === "Youth Womens") &&
                              userAge > 18) ||
                            (loginAsProfileDetails?.isChild === true &&
                              opportunity?.ageGroup?.title !== "Youth Womens" &&
                              opportunity?.ageGroup?.title !== "Youth Mens") ||
                            isDisabled
                          }
                          onClick={() => applyForProfile(opportunity?.id)}
                          style={{
                            opacity:
                              !opportunity?.status ||
                              loginAsProfileDetails?.profileType !== opportunity?.profileType ||
                              ((opportunity?.ageGroup?.title === "Youth Mens" ||
                                opportunity?.ageGroup?.title === "Youth Womens") &&
                                userAge > 18) ||
                              (loginAsProfileDetails?.isChild === true &&
                                opportunity?.ageGroup?.title !== "Youth Womens" &&
                                opportunity?.ageGroup?.title !== "Youth Mens")
                                ? 0.5
                                : 1,
                          }}
                        >
                          {!isLoading ? (
                            text
                          ) : (
                            <CircularProgress size={14} style={{ color: "#fff" }} />
                          )}
                        </button>
                      </>
                    )}
                    <div>
                      {loginAsProfileDetails?.profileType === opportunity?.profileType ? null : (
                        <div className="block text-gray-500 text-xs mt-5">
                          This opportunity does not match your user type.
                        </div>
                      )}
                      {loginAsProfileDetails?.profileType === "Agent" ? (
                        <div className="block text-gray-500 text-xs mt-5">
                          You are ineligible to apply for this opportunity.
                        </div>
                      ) : null}
                      {loginAsProfileDetails?.isChild === true &&
                      opportunity?.ageGroup?.title !== "Youth Womens" &&
                      opportunity?.ageGroup?.title !== "Youth Mens" &&
                      opportunity?.profileType === "Player" ? (
                        <div className="block text-gray-500 text-xs mt-5">
                          You are ineligible to apply for this opportunity as your age is below 16.
                        </div>
                      ) : null}
                      {(opportunity?.ageGroup?.title === "Youth Mens" ||
                        opportunity?.ageGroup?.title === "Youth Womens") &&
                      userAge > 18 ? (
                        <div className="block text-gray-500 text-xs mt-5">
                          You are ineligible to apply for this opportunity as your age is 18+.
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="block">
                  {opportunity?.description && (
                    <div className="block">
                      <div className="text-gr-primaryN2 font-semibold text-[14px] mt-2 ">
                        Opportunity description:
                      </div>
                      <p className="mt-2 text-sm">{opportunity?.description}</p>
                    </div>
                  )}
                  {opportunity?.otherCriteria && (
                    <div>
                      <div className="text-gr-primaryN2 font-semibold text-[14px] mt-2">
                        Other criteria:
                      </div>
                      <p className="mt-2 text-sm">{opportunity?.otherCriteria}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {(opportunity?.otherCriteria || opportunity?.description) && !showText && (
            <div className="pr-5 lg:pb-0 pb-7">
              {isLoginAsParent ? (
                <div className="ml-[24px] sm:ml-[160px] md:ml-[240px]">
                  <button
                    className={`block rounded-xl px-8 py-2 text-[16px] mb-4 font-inter border border-gr-primaryR2 ${color} ${textColor} ${
                      isParent ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={
                      opportunity?.ageGroup?.title === "Youth Mens" ||
                      opportunity?.ageGroup?.title === "Youth Womens" ||
                      (opportunity?.ageGroup?.title !== "Youth Mens" &&
                        opportunity?.ageGroup?.title !== "Youth Womens") ||
                      isDisabled
                    }
                    style={{
                      opacity:
                        opportunity?.ageGroup?.title === "Youth Mens" ||
                        opportunity?.ageGroup?.title === "Youth Womens" ||
                        (opportunity?.ageGroup?.title !== "Youth Mens" &&
                          opportunity?.ageGroup?.title !== "Youth Womens")
                          ? 0.5
                          : 1,
                    }}
                    onClick={() => applyForProfile(opportunity?.id)}
                    title={
                      isParent
                        ? "Switch to your child profile to apply for opportunities on their behalf"
                        : ""
                    }
                  >
                    {!isLoading ? text : <CircularProgress size={14} style={{ color: "#fff" }} />}
                  </button>
                  {!opportunity?.status ||
                  loginAsProfileDetails?.profileType !== opportunity?.profileType ? (
                    <div className="hidden lg:block text-gray-500 text-xs mt-5 text-red-primaryRed">
                      <p>{notApplicable} </p>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="ml-[24px] sm:ml-[160px] md:ml-[240px]">
                  <button
                    className={`block rounded-xl px-8 py-2 mb-8 text-[16px] font-inter border border-gr-primaryR2 ${color} ${textColor} ${
                      isParent ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={
                      !opportunity?.status ||
                      loginAsProfileDetails?.profileType !== opportunity?.profileType ||
                      ((opportunity?.ageGroup?.title === "Youth Mens" ||
                        opportunity?.ageGroup?.title === "Youth Womens") &&
                        userAge > 18) ||
                      (loginAsProfileDetails?.isChild === true &&
                        opportunity?.ageGroup?.title !== "Youth Womens" &&
                        opportunity?.ageGroup?.title !== "Youth Mens") ||
                      isDisabled
                    }
                    style={{
                      opacity:
                        !opportunity?.status ||
                        loginAsProfileDetails?.profileType !== opportunity?.profileType ||
                        ((opportunity?.ageGroup?.title === "Youth Mens" ||
                          opportunity?.ageGroup?.title === "Youth Womens") &&
                          userAge > 18) ||
                        (loginAsProfileDetails?.isChild === true &&
                          opportunity?.ageGroup?.title !== "Youth Womens" &&
                          opportunity?.ageGroup?.title !== "Youth Mens")
                          ? 0.5
                          : 1,
                    }}
                    onClick={() => applyForProfile(opportunity?.id)}
                    title={
                      isParent
                        ? "Switch to your child profile to apply for opportunities on their behalf"
                        : ""
                    }
                  >
                    {!isLoading ? text : <CircularProgress size={14} style={{ color: "#fff" }} />}
                  </button>
                  {!opportunity?.status ||
                  loginAsProfileDetails?.profileType !== opportunity?.profileType ? (
                    <div className="hidden lg:block text-gray-500 text-xs mt-5 text-red-primaryRed">
                      <p>{notApplicable} </p>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}

          {/* mobile view */}
          {/* <div className="lg:hidden text-gr-neutralT2 font-inter">
            {isLoginAsParent ? (
              <div>
                <button
                  className={`rounded-xl px-12 py-2 text-[16px] border border-gr-primaryR2 ${color} ${textColor}`}
                  disabled={
                    opportunity?.ageGroup?.title === "Youth Mens" ||
                    opportunity?.ageGroup?.title === "Youth Womens" ||
                    (opportunity?.ageGroup?.title !== "Youth Mens" &&
                      opportunity?.ageGroup?.title !== "Youth Womens") ||
                    isDisabled
                  }
                  style={{
                    opacity:
                      opportunity?.ageGroup?.title === "Youth Mens" ||
                      opportunity?.ageGroup?.title === "Youth Womens" ||
                      (opportunity?.ageGroup?.title !== "Youth Mens" &&
                        opportunity?.ageGroup?.title !== "Youth Womens")
                        ? 0.5
                        : 1,
                  }}
                  onClick={() => applyForProfile(opportunity?.id)}
                  title={
                    isParent
                      ? "Switch to your child profile to apply for opportunities on their behalf"
                      : ""
                  }
                >
                  {!isLoading ? text : <CircularProgress size={14} style={{ color: "#fff" }} />}
                </button>
                {opportunity?.ageGroup?.title === "Youth Mens" ||
                opportunity?.ageGroup?.title === "Youth Womens" ? (
                  <div className="text-gray-500 text-xs mt-5">
                    Switch to your child profile to apply for opportunities on their behalf.
                  </div>
                ) : (
                  <div className="text-gray-500 text-xs mt-5">
                    You are ineligible to apply for this opportunity.
                  </div>
                )}
              </div>
            ) : (
              <div>
                <button
                  className={`rounded-xl px-12 py-2 text-[16px] border border-gr-primaryR2 ${color} ${textColor}`}
                  disabled={
                    loginAsProfileDetails?.profileType !== opportunity?.profileType ||
                    ((opportunity?.ageGroup?.title === "Youth Mens" ||
                      opportunity?.ageGroup?.title === "Youth Womens") &&
                      userAge > 18) ||
                    (loginAsProfileDetails?.isChild === true &&
                      opportunity?.ageGroup?.title !== "Youth Womens" &&
                      opportunity?.ageGroup?.title !== "Youth Mens") ||
                    isDisabled
                  }
                  onClick={() => applyForProfile(opportunity?.id)}
                  style={{
                    opacity:
                      loginAsProfileDetails?.profileType !== opportunity?.profileType ||
                      ((opportunity?.ageGroup?.title === "Youth Mens" ||
                        opportunity?.ageGroup?.title === "Youth Womens") &&
                        userAge > 18) ||
                      (loginAsProfileDetails?.isChild === true &&
                        opportunity?.ageGroup?.title !== "Youth Womens" &&
                        opportunity?.ageGroup?.title !== "Youth Mens")
                        ? 0.5
                        : 1,
                  }}
                >
                  {!isLoading ? text : <CircularProgress size={14} style={{ color: "#fff" }} />}
                </button>
                <div>
                  {loginAsProfileDetails?.profileType === opportunity?.profileType ? null : (
                    <div className="text-gray-500 text-xs mt-5">
                      This opportunity does not match your user type.
                    </div>
                  )}
                  {loginAsProfileDetails?.profileType === "Agent" ? (
                    <div className="text-gray-500 text-xs mt-5">
                      You are ineligible to apply for this opportunity.
                    </div>
                  ) : null}
                  {loginAsProfileDetails?.isChild === true &&
                  opportunity?.ageGroup?.title !== "Youth Womens" &&
                  opportunity?.ageGroup?.title !== "Youth Mens" &&
                  opportunity?.profileType === "Player" ? (
                    <div className="text-gray-500 text-xs mt-5">
                      You are ineligible to apply for this opportunity as your age is below 16.
                    </div>
                  ) : null}
                  {(opportunity?.ageGroup?.title === "Youth Mens" ||
                    opportunity?.ageGroup?.title === "Youth Womens") &&
                  userAge > 18 ? (
                    <div className="text-gray-500 text-xs mt-5">
                      You are ineligible to apply for this opportunity as your age is 18+.
                    </div>
                  ) : null}{" "}
                </div>
              </div>
            )}
            <div className="">
              {opportunity?.description && (
                <div>
                  <div className="text-primaryN2 font-bold text-[14px] mt-[20px] ">
                    Opportunity description:
                  </div>
                  <p className="mt-3">{opportunity?.description}</p>
                </div>
              )}
              {opportunity?.otherCriteria && (
                <div>
                  <div className="text-primaryN2 font-bold text-[14px] mt-6">Other criteria:</div>
                  <p className="mt-3">{opportunity?.otherCriteria}</p>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PlayerOpportunityDetail
