import React from "react"
import { GRDialog } from "../ui/GRDialog"
import DialogHeader from "./DialogHeader"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import { IUserExperienceSeason } from "../../modals/User"
import { ExperienceBody, TableHeadComp } from "../playerProfile/UserRugbyExperience"

interface ISeasonDetailsDialogProps {
  isShowSeasonDetailsDialog: boolean
  onClose: () => void
  seasonData: IUserExperienceSeason
}

const SeasonDetailsDialog: React.FC<ISeasonDetailsDialogProps> = (props) => {
  const { isShowSeasonDetailsDialog, onClose, seasonData } = props

  const body = (
    <div className="mx-4 sm:mx-0 px-2 sm:px-[55px] mt-[14px] mb-8  ">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHeadComp context={"SeasonDetails"} />
          <TableBody>
            <ExperienceBody context="SeasonDetails" seasonData={seasonData} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowSeasonDetailsDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[980px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Season info" onClose={onClose} />}
      />
    </>
  )
}

export default SeasonDetailsDialog
