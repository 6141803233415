import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import DropDownIcon from "../../assets/svg/DropDownIcon.svg"
import GlobalRugbyLogo from "../../assets/svg/GlobalRugbyLogo"
import LogoutIcon from "../../assets/svg/LogoutIcon"
import MessageIcon from "../../assets/svg/MessageIcon"
// import NotificationIcon from "../../assets/svg/NotificationIcon"
import { useUserHook } from "../../hooks/useUserHook"
import GlobalRugbyLogoSvg from "../../assets/svg/GlobalRugbyLogo.svg"
import AppContext from "../../context/app-context/AppContext"
import { styled, Tooltip } from "@mui/material"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "../../assets/svg/DownArrow"
import CreateOpportunityDialog from "../../components/dialogs/CreateOpportunityDialog"
import { IOpportunitySummary } from "../../modals/Opportunity"
import { useOpportunityAPI } from "../../services/useOpportunityAPI"
import CrossIcon from "../../assets/svg/CrossIcon"

import {
  AUTH_DASHBOARD,
  AUTH_MESSAGE,
  // AUTH_NOTIFICATION,
  AUTH_OPPORTUNITY,
  UNAUTH_LOGIN,
  AUTH_REFFERALS,
  AUTH_MY_PROFILE,
  AUTH_CHANGE_PASSWORD,
} from "../../routes/RouteConstants"
import { useAuthAPI } from "../../services/useAuthAPI"
import UserAccountSetting from "../UserAccountSetting"
import AgentClubs from "../agent/agentProfile/AgentClubs"
import ConnectPlayerCoach from "../agent/agentProfile/ConnectPlayerCoach"
import Members from "../club/dashboard/Members"
import ClubsRecords from "../clubsRecords/ClubsRecords"
import FamilyMembers from "../familyMembers/FamilyMembers"
import GlobalSearch from "./GlobalSearch"
import { getNameInitials } from "../../utils/CommonUtils"
import InviteAndReferral from "../playerProfile/InviteAndReferral"
import Badge, { BadgeProps } from "@mui/material/Badge"
import Child from "../familyMembers/Child"

const playerNavigation = [
  {
    name: "Home",
    link: AUTH_DASHBOARD,
  },
  {
    name: "Opportunities",
    link: AUTH_OPPORTUNITY,
  },
]

const clubNavigation = [
  {
    name: "Home",
    link: AUTH_DASHBOARD,
  },
  {
    name: "Opportunities",
    link: AUTH_OPPORTUNITY,
  },
]

const agentNavigation = [
  {
    name: "Home",
    link: AUTH_DASHBOARD,
  },
]

interface IMenu {
  name: string
  link: string
}

interface IHeaderProps {
  type?: any
}

const Header: React.FC<IHeaderProps> = () => {
  // State
  const [anchorElUserSetting, setAnchorElUserSetting] = useState<HTMLButtonElement | null>(null)
  const [menu, setMenu] = useState<IMenu[]>()
  const [isShowAddFamilyMemberDialog, setIsShowAddFamilyMemberDialog] = useState<boolean>(false)
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [, setOpportunities] = useState<IOpportunitySummary[]>([])

  // Context
  const { loginAsProfileDetails, unreadMessageCount, loadApp, dropDownOptions } =
    useContext(AppContext)

  // APIs
  const { logout } = useAuthAPI()
  const { getOpportunityByClubId } = useOpportunityAPI()

  // Hooks
  const navigate = useNavigate()
  const { getCurrentLoginUserPorfilePicUrl, isLoginAsClub, isParent, fetchLoginAsUserProfile } =
    useUserHook()

  const handleCreateFamilyMember = async () => {
    await fetchLoginAsUserProfile()
    setIsShowAddFamilyMemberDialog(false)
    loadApp()
  }

  // UseEffect
  useEffect(() => {
    switch (loginAsProfileDetails?.profileType) {
      case "Club":
        setMenu(clubNavigation)
        break
      case "Player":
        setMenu(playerNavigation)
        break
      case "Coach":
        setMenu(playerNavigation)
        break
      case "Agent":
        setMenu(agentNavigation)
        break
      case "Parent":
        setMenu(playerNavigation)
        break
      default:
        setMenu(undefined)
    }
  }, [loginAsProfileDetails?.profileType])

  // Functions
  const handleLogout = () => {
    logout()
    navigate(UNAUTH_LOGIN)
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElUserSetting(event?.currentTarget)
  }

  const handleClose = () => {
    setAnchorElUserSetting(null)
  }

  const profilePicUrl = getCurrentLoginUserPorfilePicUrl()

  const location = useLocation()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, menuName: string) => {
    if (menuName === "Opportunities") {
      if (location.pathname === AUTH_OPPORTUNITY) {
        navigate(0)
      }
    }
  }
  const getOpportunity = async () => {
    try {
      if (!loginAsProfileDetails?.club?.id) return
      const opportunityList = await getOpportunityByClubId(loginAsProfileDetails?.club?.id) // I will use dynamic club id after pass in URL
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }

  return (
    <div>
      <header className="w-full sm:h-[80px] bg-white px-4 md:px-6 font-microsoftTai ">
        <div className="py-1 flex items-center justify-between">
          <div className="flex items-center gap-[16px] md:gap-[50px] justify-start md:justify-center">
            <div className="block md:hidden  cursor-pointer">
              <TemporaryDrawer
                handleLogout={handleLogout}
                setIsShowAddFamilyMemberDialog={setIsShowAddFamilyMemberDialog}
                setIsShowDialog={setIsShowDialog}
              />
            </div>
            <Link to={"/"}>
              <img src={GlobalRugbyLogoSvg} className="w-auto sm:w-[76px] h-auto sm:h-[70px] " />
            </Link>

            <div className="hidden md:flex gap-[50px] text-gr-neutralT3 font-inter ">
              {menu?.map((m) => (
                <div key={m?.name} className="hover:text-gray-400">
                  <Link to={m?.link} onClick={(e) => handleClick(e, m?.name)}>
                    {m?.name}
                  </Link>
                </div>
              ))}
              <div>
                {isParent && (
                  <div
                    onClick={() => setIsShowAddFamilyMemberDialog(true)}
                    className="cursor-pointer justify-center items-center bg-gr-primaryR1 border-[1px] border-white min-w-[130px] h-[28px] rounded-[30px]"
                  >
                    <span
                      className={` ${"ml-[10px]"} text-[12px] text-gr-primaryR2 font-inter font-semibold `}
                    >
                      Create child profile
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Child
            isOpen={isShowAddFamilyMemberDialog}
            onSuccess={handleCreateFamilyMember}
            onClose={() => {
              setIsShowAddFamilyMemberDialog(false)
            }}
          />
          <div>
            <CreateOpportunityDialog
              dropDownOptions={dropDownOptions}
              isShowDialog={isShowDialog}
              onClose={() => setIsShowDialog(false)}
              onGetOpportunity={() => getOpportunity()}
            />
            <nav className="flex items-center justify-between">
              {/* Search Input */}
              <div className="hidden sm:block">
                <GlobalSearch />
              </div>
              <div className="relative flex items-center ml-4">
                <span className="">
                  <Link className="relative" to={AUTH_MESSAGE}>
                    <StyledBadge className="" badgeContent={unreadMessageCount}>
                      <MessageIcon className="hover:fill-gr-userProfile1" />
                    </StyledBadge>
                  </Link>
                </span>
              </div>
              {/* <div>
                <Link to={AUTH_NOTIFICATION}>
                  <NotificationIcon />
                </Link>
              </div> */}
              <div className="lg:hidden ml-4">
                {profilePicUrl ? (
                  <Link to={AUTH_MY_PROFILE}>
                    <img
                      src={profilePicUrl}
                      alt="Profile"
                      className="rounded-full w-9 h-9 mt-2 cursor-pointer"
                    />
                  </Link>
                ) : isLoginAsClub ? (
                  <button type="button" onClick={handleOpen}>
                    <img
                      src={ClubLogo}
                      alt="Profile"
                      className="rounded-full w-9 h-9 mt-2 cursor-pointer"
                    />
                  </button>
                ) : (
                  <div className="flex items-center justify-center bg-gr-primaryR1 rounded-full w-9 h-9 border hover:border-gr-primaryR2">
                    <Link to={AUTH_MY_PROFILE}>
                      <span className="font-inter text-[16px] text-gr-primaryR2 font-medium">
                        {getNameInitials(
                          loginAsProfileDetails?.firstname,
                          loginAsProfileDetails?.lastname,
                        )}
                      </span>
                    </Link>
                  </div>
                )}
              </div>
              <div className="">
                <div className="hidden lg:block ml-4">
                  {profilePicUrl ? (
                    <button type="button" onClick={handleOpen}>
                      <img
                        src={profilePicUrl}
                        alt="Profile"
                        className="rounded-full w-9 h-9 mt-2 cursor-pointer"
                      />
                    </button>
                  ) : isLoginAsClub ? (
                    <button type="button" onClick={handleOpen}>
                      <img
                        src={ClubLogo}
                        alt="Profile"
                        className="rounded-full w-9 h-9 mt-2 cursor-pointer"
                      />
                    </button>
                  ) : (
                    <div className="flex items-center justify-center bg-gr-primaryR1 rounded-full w-9 h-9">
                      <button type="button" onClick={handleOpen}>
                        <span className="font-inter text-[16px] text-gr-primaryR2 font-medium">
                          {getNameInitials(
                            loginAsProfileDetails?.firstname,
                            loginAsProfileDetails?.lastname,
                          )}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
                <UserAccountSetting
                  anchorElUserSetting={anchorElUserSetting}
                  handleLogout={handleLogout}
                  handleClose={handleClose}
                />
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  )
}

type Anchor = "top" | "left" | "bottom" | "right"
interface ITemporaryDrawerProps {
  handleLogout: () => void
  setIsShowAddFamilyMemberDialog: React.Dispatch<React.SetStateAction<boolean>>
  setIsShowDialog: React.Dispatch<React.SetStateAction<boolean>>
}
interface StateType {
  left: boolean
}
const TemporaryDrawer: React.FC<ITemporaryDrawerProps> = ({
  handleLogout,
  setIsShowAddFamilyMemberDialog,
  setIsShowDialog,
}) => {
  // State
  const [state, setState] = useState<StateType>({ left: false })
  const [isExpanded, setExpanded] = useState(false)
  const [isexpandedClub, setexpandedClub] = useState(true)

  // Hooks
  const { isAgent, isCoach, isLoginAsClub, isParent } = useUserHook()
  const { handleSwitchAccount } = useUserHook()
  const navigate = useNavigate()

  // Context
  const { primaryUserDetails } = useContext(AppContext)

  const handleAccordionClick = () => {
    setExpanded(!isExpanded)
    setexpandedClub(!isexpandedClub)
  }

  // Functions
  const handleItemClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }

      setState({ ...state, [anchor]: open })
    }

  const location = useLocation()

  useEffect(() => {
    setState({ left: false })
  }, [location])

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 319 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
    >
      <div className="flex justify-between items-center">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Link to={AUTH_DASHBOARD} className="">
                  <GlobalRugbyLogo width={48} height={50} />
                </Link>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
          </ListItem>
        </List>

        <div className="px-4" onClick={toggleDrawer(anchor, false)}>
          <CrossIcon />
        </div>
      </div>
      <Divider />
      <div
        className={`${
          isLoginAsClub ? "mt-5" : "mt-7"
        } font-inter font-semibold text-lg leading-6 text-gr-primaryN1 px-4`}
      >
        Accounts
      </div>
      <List>
        <ListItemButton sx={{ marginLeft: "16px" }}>
          <ListItemIcon sx={{ marginLeft: "0px", minWidth: "0px" }}>
            {primaryUserDetails?.profilePicture?.url ? (
              <div>
                <img
                  className="w-[30px] h-[30px] rounded-full"
                  src={primaryUserDetails?.profilePicture?.url}
                  alt="Pic"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-gr-primaryR1">
                <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                  {getNameInitials(primaryUserDetails?.firstname, primaryUserDetails?.lastname)}
                </span>
              </div>
            )}
          </ListItemIcon>

          <div className="flex flex-col font-inter ml-2">
            <div
              className=""
              onClick={() => {
                primaryUserDetails
                  ? handleSwitchAccount({
                      profileId: primaryUserDetails?.id,
                      profileType: primaryUserDetails?.profileType,
                    })
                  : undefined
                navigate(AUTH_DASHBOARD)
              }}
            >
              <div className="truncate cursor-pointer font-inter text-[12px]  text-gr-neutralT3 font-medium flex justify-start items-center gap-2">
                <div>
                  {`${primaryUserDetails?.firstname} ${primaryUserDetails?.lastname}` || ""}
                </div>
              </div>
              <p className="text-[12px] text-gr-secondaryGray">Switch to account</p>
            </div>
          </div>
        </ListItemButton>
        {primaryUserDetails?.member?.[0] && (
          <ListItemButton sx={{ marginLeft: "20px", minWidth: "0px" }}>
            <div className="">
              <div className="font-inter text-[13px] text-gr-textUserProfile4 font-semibold mb-1">
                Children
              </div>
              <div>
                {primaryUserDetails?.member
                  .filter((member) => member?.childMember)
                  ?.map((m) => (
                    <div
                      onClick={() => {
                        primaryUserDetails && m?.childMember?.id
                          ? handleSwitchAccount({
                              profileId: m?.childMember?.id,
                              profileType: "Child",
                            })
                          : undefined
                        navigate(AUTH_DASHBOARD)
                      }}
                      key={m?.id}
                      className="truncate cursor-pointer font-inter text-[12px] mb-1 text-gr-neutralT3 font-medium flex justify-start items-center gap-2"
                    >
                      {m?.childMember?.profilePicture?.url ? (
                        <div>
                          <img
                            className="w-[30px] h-[30px] rounded-full"
                            src={m?.childMember?.profilePicture?.url}
                            alt="Pic"
                          />
                        </div>
                      ) : (
                        <div className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-gr-primaryR1">
                          <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                            {getNameInitials(m?.childMember?.firstname, m?.childMember?.lastname)}
                          </span>
                        </div>
                      )}
                      <div>{`${m?.childMember?.firstname} ${m?.childMember?.lastname}`}</div>
                    </div>
                  ))}
              </div>
            </div>
          </ListItemButton>
        )}
        {primaryUserDetails?.club?.name && (
          <div className="ml-[20px] px-4 ">
            <div className="font-inter text-[13px] text-gr-textUserProfile4 font-semibold my-1">
              Club (Admin)
            </div>
            <div
              className="mb-2.5"
              onClick={() => {
                primaryUserDetails
                  ? handleSwitchAccount({
                      profileId: primaryUserDetails?.club?.id,
                      profileType: "Club",
                    })
                  : undefined
                navigate(AUTH_DASHBOARD)
              }}
            >
              <div className="cursor-pointer font-inter text-[12px] mb-1 text-gr-neutralT3 font-medium flex justify-start items-center gap-2">
                <div>
                  <img
                    className="w-[30px] h-[30px] rounded-full"
                    src={primaryUserDetails?.club?.clubLogo?.url ?? ClubLogo}
                    alt="Pic"
                  />
                </div>
                <Tooltip title={primaryUserDetails?.club?.name}>
                  <div>{primaryUserDetails?.club?.name}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </List>
      <Divider />
      {!isLoginAsClub && !isAgent && (
        <>
          <List>
            <ListItemButton>
              <Link
                to={AUTH_OPPORTUNITY}
                className="font-inter text-base font-medium text-gr-primaryGray"
              >
                Opportunities
              </Link>
            </ListItemButton>
          </List>
          {/* <Divider /> */}
        </>
      )}
      <Divider />
      {isParent && (
        <>
          <List>
            <ListItemButton>
              <div
                onClick={() => setIsShowAddFamilyMemberDialog(true)}
                className=" justify-center items-center bg-gr-primaryR1 border-[1px] border-white min-w-[130px] h-[28px] rounded-[30px]"
              >
                <span
                  className={` ${"ml-[10px]"} text-[12px] text-gr-primaryR2 font-inter font-semibold `}
                >
                  Create child profile
                </span>
              </div>
            </ListItemButton>
          </List>
          {/* <Divider /> */}
        </>
      )}
      {isAgent && (
        <>
          <List onClick={handleItemClick}>
            <ListItem disablePadding>{<InviteAndReferral />}</ListItem>
          </List>
          {/* <Divider /> */}
        </>
      )}
      {!isAgent && !isLoginAsClub && (
        <>
          <List>
            <ListItemButton>
              <Link
                to={AUTH_REFFERALS}
                className="font-inter text-base font-medium text-gr-primaryGray"
              >
                Invite and Referrals
              </Link>
            </ListItemButton>
          </List>
          {/* <Divider /> */}
        </>
      )}
      {!isLoginAsClub ? (
        <>
          <Divider />{" "}
          <List onClick={handleItemClick}>
            <ListItem disablePadding>
              {isAgent ? <AgentClubs /> : isCoach ? null : <ClubsRecords key={"manish"} />}
            </ListItem>
          </List>
          <Divider />
        </>
      ) : null}
      {isLoginAsClub ? (
        <>
          <Accordion
            expanded={isexpandedClub}
            onChange={handleAccordionClick}
            onClick={handleItemClick}
            sx={{
              border: "rgba(0, 0, 0, 0.12)",
              boxShadow: "none",
            }}
            className="club-menu-accordian py-2 !m-0"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">
                {" "}
                Opportunities
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col ml-4 gap-4">
                <div onClick={() => setIsShowDialog(true)}>Create new Opportunities</div>
                <div>
                  <Link to={AUTH_OPPORTUNITY}>View All</Link>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      ) : null}
      <Divider />
      <List onClick={handleItemClick} className={isLoginAsClub ? "!p-0" : ""}>
        <ListItem disablePadding>
          {isAgent ? (
            <ConnectPlayerCoach />
          ) : isCoach ? (
            <FamilyMembers /> // Show FamilyMembers component for coaches
          ) : isLoginAsClub ? (
            <Members />
          ) : (
            <FamilyMembers />
          )}
        </ListItem>
      </List>

      <Divider />
      <List>
        <ListItemButton onClick={handleItemClick}>
          <span className="font-inter font-semibold text-base leading-6 text-gr-primaryN1">
            <Link to={AUTH_CHANGE_PASSWORD}>Change Password</Link>
          </span>
        </ListItemButton>
      </List>
      {/* <Divider /> */}
      <List>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon sx={{ marginLeft: "0px", marginRight: "5px", minWidth: "0px" }}>
            <LogoutIcon />
          </ListItemIcon>
          <span className="font-inter font-semibold text-base leading-6 text-gr-primaryN1 ml-3">
            Logout
          </span>
        </ListItemButton>
      </List>
    </Box>
  )

  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} className="!min-w-[20px] !p-0">
            <img src={DropDownIcon} />{" "}
          </Button>
          <Drawer
            sx={{
              display: {
                md: "none",
              },
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Header

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    background: "#F3635B",
    color: "#fff",
  },
}))
