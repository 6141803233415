import React, { useContext } from "react"
import Opportunities from "../dashboard/Opportunities/Opportunities"
import UserFollowings from "./UserFollowings"
import AppContext from "../../context/app-context/AppContext"
import { useScreenWidth } from "../../context/ScreenWidthContext"

const UserFollowing = () => {
  const { loginAsProfileDetails } = useContext(AppContext)

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 769 && screenWidth < 1250

  return (
    <div className="flex">
      {isMediumScreen || isLargeScreen ? (
        <div
          className={`ml-4 sm:mb-[106px] mb-12 sm:mt-4 mt-4  ${
            isLargeScreen
              ? "flex-[24%] max-w-[24%]"
              : isMediumScreen
              ? "flex-[40%] max-w-[40%]"
              : "flex-[100%] max-w-[100%]"
          }`}
        >
          <Opportunities />
        </div>
      ) : null}
      <div
        className={`ml-4 mr-4 sm:mr-0 sm:mb-[106px] mb-12 sm:mt-4 mt-4  ${
          isLargeScreen
            ? "flex-[73%] max-w-[73%]"
            : isMediumScreen
            ? "flex-[55%] max-w-[55%]"
            : "flex-[100%] max-w-[100%]"
        }`}
      >
        <UserFollowings profileDetails={loginAsProfileDetails} />
      </div>
    </div>
  )
}

export default UserFollowing
