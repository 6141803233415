import React from "react"
import StepEmail from "../components/forgotPasswordFlow/StepEmail"

function ForgotPassword() {
  return (
    <div className="">
      <StepEmail />
    </div>
  )
}
export default ForgotPassword
