import useBaseAPI from "./useBaseAPI"

export const useFaqApi = () => {
  const { GET, isLoading } = useBaseAPI()

  const getFaq = (): Promise<IFaqPayload> => {
    return GET("/faq-page?populate=*")
  }
  return { getFaq, isLoading }
}

export default useFaqApi

export interface IFaqPayload {
  data: Data
  meta: Meta
}
interface Meta {}
export interface Data {
  id: number
  attributes: Attributes
}
export interface Attributes {
  heading: string
  createdAt: string
  updatedAt: string
  publishedAt: string
  fAQs: FAQ[]
}
interface FAQ {
  id: number
  question: string
  answer: string
}
