import React from "react"
import MoreInfoIcon from "../../../assets/svg/MoreInfoIcon"
import LocationIcon from "../../../assets/svg/LocationIcon"
import { Link } from "react-router-dom"
import { AUTH_OPPORTUNITY_DETAIL } from "../../../routes/RouteConstants"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"
import { AUTH_CLUB_PROFILE } from "../../../routes/RouteConstants"

const OpportunityCard = ({
  opportunity,
  isSerchOpportunity,
}: {
  opportunity?: IOpportunitySummary
  isSerchOpportunity?: boolean
}) => {
  return (
    <div
      className={`${isSerchOpportunity ? "p-0" : "md:px-10 sm:px-5 sm:pt-5 px-4 pt-4"} font-inter`}
    >
      {/* topSection - logo, heading, moreInfo */}
      <div className=" flex flex-col  sm:flex-row sm:justify-between">
        {/* logo and heading */}
        <div className="flex justify-center items-start gap-[10px] md:gap-4 ">
          {/* logo */}
          <div className="">
            <Link to={`${AUTH_CLUB_PROFILE}/${opportunity?.club?.id}`}>
              <img
                src={opportunity?.club?.clubLogo?.url ?? ClubLogo}
                alt="logo"
                className="min-w-[60px] w-[60px] h-[60px] rounded-full"
              />
            </Link>
          </div>

          {/* heading */}
          <div className="flex flex-col w-full gap-2">
            <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>
              <div className="font-inter font-semibold sm:text-lg hover:underline">
                {opportunity?.club?.name}
              </div>
            </Link>
            <div className="text-sm hidden sm:block font-inter uppercase">
              Opportunity for{" "}
              {opportunity?.playerPosition?.title
                ? opportunity?.playerPosition?.title
                : opportunity?.coachPosition?.title}
            </div>
          </div>
        </div>

        {/* moreInfo */}
        <div className="hidden md:flex justify-end mt-1 gap-[10px]">
          <div className="text-primaryR2 text-[11px] font-medium hover:underline text-gr-primaryR2">
            <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>More info</Link>
          </div>
          <div className="">
            <MoreInfoIcon />
          </div>
        </div>
      </div>

      {/* opportunity details- season,level,age,location */}
      <div className="sm:flex flex-wrap text-xs sm:pl-[70px] md:pl-[75px] sm:pb-3">
        <div className="flex flex-col ">
          <div className=" w-[200px] pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-5 md:gap-8 text-gr-neutralT3 mt-3">
            <div className="flex items-center gap-2 text-xs">
              <div>
                <LocationIcon width={16} height={16} />
              </div>
              <div className=""> {opportunity?.country}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:ml-[200px]">
          <div className="w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-4 md:gap-7 text-gr-neutralT3 md:mt-[10px] mt-2">
            <div className="text-xs">
              <span className="font-inter">Season: {opportunity?.season?.title}</span>
            </div>
          </div>

          <div className="w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-4 md:gap-7 text-gr-neutralT3 md:mt-[10px] mt-2">
            <div className="text-xs">
              <span className="font-inter">Level: {opportunity?.level?.title}</span>
            </div>
          </div>

          <div className="w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-5 md:gap-8 text-gr-neutralT3 mt-[10px] ">
            <div className="text-xs">
              <span className="">Age group: {opportunity?.ageGroup?.title}</span>
            </div>
          </div>

          <div className="w-full pr-4 flex flex-col gap-[10px] sm:flex-row  sm:items-center sm:gap-4 md:gap-7 text-gr-neutralT3 mt-[10px] ">
            <div className="text-xs">
              <span className="">Code: {opportunity?.discipline?.title}</span>
            </div>
          </div>
        </div>
      </div>

      {/* description */}
      {opportunity?.description && (
        <div className="sm:ml-[70px] md:ml-[75px]">
          <div className="text-black font-medium font-inter md:mb-0 text-sm">
            Opportunity description
          </div>
          <div className="text-gr-neutralT3 font-inter text-sm h-max md:mt-2 overflow-hidden overflow-ellipsis line-clamp-2">
            {opportunity?.description}
          </div>
        </div>
      )}

      <div className="flex justify-end  items-center gap-[10px] mt-[10px] md:hidden md:pb-0 pb-5">
        <div className="text-primaryR2 text-[11px] font-medium underline text-gr-primaryR2">
          <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>More info</Link>
        </div>
        <div>
          <MoreInfoIcon />
        </div>
      </div>
      {/* bottomLine */}
      <div className={`border-b ml-[75px] mt-[13px] mb-[15px] border-gr-primaryR8`}></div>
    </div>
  )
}

export default OpportunityCard
