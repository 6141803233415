import React, { useContext } from "react"
import { GRDialog } from "../../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../../ui/GRButton"
import { IClubDetails } from "../../../modals/Club"
import DialogHeader from "../../../components/dialogs/DialogHeader"
import { Autocomplete, TextField } from "@mui/material"
import useClubHook from "../../../hooks/useClubHook"
import { errorToast } from "../../../utils/ToastUtils"
import { Box } from "@mui/material"
import { AutocompleteInputCss } from "../../ui/GRSelect"
import AppContext from "../../../context/app-context/AppContext"

interface IEditPersoanlInfoFormValues {
  description: string
  disciplines: string[]
}
interface IGREditAboutDialogProps {
  isShowDialog: boolean
  onClose: () => void
  clubData?: IClubDetails
  onSucess: () => void
}

const EditAboutDialog: React.FC<IGREditAboutDialogProps> = (props) => {
  // Props
  const { isShowDialog, onClose, clubData, onSucess } = props
  const { dropDownOptions } = useContext(AppContext)
  // Hooks
  const { updateClubProfile, isClubProfileLoading } = useClubHook()
  const handleSubmit = async (values: IEditPersoanlInfoFormValues) => {
    try {
      if (!clubData) {
        errorToast("Something went wrong")
        return
      }
      await updateClubProfile(clubData?.id, values)
      onSucess()
    } catch (error) {
      // Handle error
    }
  }
  // Formik
  const formik = useFormik({
    initialValues: {
      description: clubData?.attributes?.description || "",
      disciplines: clubData?.attributes?.disciplines
        ? clubData.attributes.disciplines.map((discipline) => discipline.id.toString())
        : [],
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (vals) => {
      const errors: any = {}
      if (vals.description?.length > 2000) {
        errors.description = "Max. 2000 characters allowed"
      }
      return errors
    },
  })

  const body = (
    <div className="sm:mx-[32px] mx-[13px] mb-8 compeleteProfileForm">
      <form onSubmit={formik.handleSubmit} className="flex flex-col">
        <div>
          <div className="flex justify-between">
            <div className="block text-sm leading-6 font-inter mb-1">About us</div>
            <div className="block text-sm leading-6 font-inter mb-1">
              {formik.values.description?.length || 0} / 2000 characters
            </div>
          </div>
          <div className="relative">
            <TextField
              fullWidth
              multiline={true}
              minRows={4}
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "10px",
                },
              }}
            />
          </div>
          <div className="w-full my-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="disciplines">
              Code
            </label>

            <Box sx={{ width: "100%" }}>
              <Autocomplete
                id="disciplines"
                multiple
                disableCloseOnSelect
                limitTags={1}
                renderTags={(value) => {
                  const numTags = value.length
                  return (
                    <span className="max-w-[60%] truncate text-sm mr-3">
                      {numTags > 1 && ` ${numTags - 1} more +`}
                      {value
                        .slice(0, 1)
                        .map((option) => option?.label)
                        .join(", ")}
                    </span>
                  )
                }}
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  const selectedOptions = newValue?.map((n: any) => n?.id)
                  formik.setFieldValue("disciplines", selectedOptions || "")
                }}
                renderOption={(props: object, option, { selected }) => (
                  <div {...props} style={{ fontSize: "14px" }}>
                    <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                    {option?.label}
                  </div>
                )}
                isOptionEqualToValue={(options, selected) => options?.id === selected?.id}
                getOptionLabel={(option) => option.label}
                onBlur={formik?.handleBlur}
                value={(() => {
                  const selectedOptions = dropDownOptions?.discipline?.filter((d) =>
                    formik.values.disciplines?.includes(String(d?.id)),
                  )
                  const options =
                    selectedOptions?.map((s) => ({ label: s?.title, id: String(s?.id) })) || []
                  return options
                })()}
                options={
                  dropDownOptions?.discipline?.map((d) => ({
                    label: d?.title,
                    id: String(d?.id),
                  })) || []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select code"
                    sx={AutocompleteInputCss}
                    className="cursor-pointer"
                  />
                )}
              />
            </Box>
          </div>
        </div>

        <div className="flex justify-between items-center mt-6 mb-4">
          <GRButton label="Cancel" type="button" className="w-[115px]" onClick={onClose} />
          <GRButton label="Save" className="w-[115px]" isSubmitting={isClubProfileLoading} />
        </div>
      </form>
    </div>
  )
  return (
    <>
      <GRDialog
        open={isShowDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Club Description" onClose={onClose} />}
      />
    </>
  )
}

export default EditAboutDialog
