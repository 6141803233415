import NotificationSetting from "../components/notification/NotificationSetting"
import React, { useState } from "react"
import ProfileImage from "../assets/svg/ProfileImage.svg"
import Opportunities from "../components/dashboard/Opportunities/Opportunities"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"

const Notification = () => {
  const [viewed] = useState<boolean>(false)
  return (
    <div className="pb-5">
      <HeaderSearchFeild />
      <div className="flex flex-col items-center sm:items-start sm:flex-row sm:justify-center gap-[20px] md:gap-[30px] sm:mt-7">
        <NotificationSetting />

        <div className="flex flex-col gap-4">
          <NotificationCard backgroundColor={true ? "bg-white" : "bg-gr-neutral08"} />
          <NotificationCard backgroundColor={true ? "bg-white" : "bg-gr-neutral08"} />
          <NotificationCard backgroundColor={true ? "bg-white" : "bg-gr-neutral08"} />
          <NotificationCard backgroundColor={true ? "bg-white" : "bg-gr-neutral08"} />
          <NotificationCard backgroundColor={viewed ? "bg-white" : "bg-gr-neutral08"} />
          <NotificationCard backgroundColor={viewed ? "bg-white" : "bg-gr-neutral08"} />
          <NotificationCard backgroundColor={viewed ? "bg-white" : "bg-gr-neutral08"} />
        </div>
        <div className="hidden md:block">
          <Opportunities />
        </div>
      </div>
    </div>
  )
}
interface INotificationCardProps {
  backgroundColor?: string
}
const NotificationCard: React.FC<INotificationCardProps> = ({ backgroundColor }) => {
  return (
    <div
      className={
        "w-[360px] sm:w-[450px] md:w-[682px] flex justify-center items-center font-inter p-4 px-5 rounded-[10px] h-[91px] cursor-pointer " +
        backgroundColor
      }
    >
      <img
        src={ProfileImage}
        alt="profile-image"
        className="rounded-full max-w-[44px] max-h-[44px]"
      />
      <div className="w-[500px] ml-[34px] text-sm line-clamp-2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum vitae dictumst sit vitae,
        mi imperdiet sit.
      </div>
      <div className="text-red-darkRed   ml-[40px] text-xs font-medium mt-10">16m</div>
    </div>
  )
}
export default Notification
