enum AppContextActions {
  SET_USER_DETAILS = "SET_USER_DETAILS",
  SET_DROPDOWN_OPTIONS = "SET_DROPDOWN_OPTIONS",
  SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION",
  SET_LOAD_APP = "SET_LOAD_APP",
  SET_LOGIN_AS_PROFILE_DETAILS = "SET_LOGIN_AS_PROFILE_DETAILS",
  SET_CURRENT_LOGIN_PROFILE = "SET_CURRENT_LOGIN_PROFILE",
  SET_SOCKET = "SET_SOCKET",
  SET_UNREAD_COUNT = "SET_UNREAD_COUNT",
  SET_CONNECTED_USER = "SET_CONNECTED_USER",
}

export default AppContextActions
