import React, { useContext, useState } from "react"
import { IClubDetailsSearch } from "../../modals/Club"
import InfiniteScroll from "react-infinite-scroll-component"
import { CircularProgress } from "@mui/material"
import { AUTH_CLUB_PROFILE } from "../../routes/RouteConstants"
import { Link } from "react-router-dom"
import { useUserHook } from "../../hooks/useUserHook"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import AppContext from "../../context/app-context/AppContext"

interface IClubProps {
  isShowSeeAllLink?: boolean
  handleSeeAll?: () => void
  club: IClubDetailsSearch[]
  handleMore?: () => void
  hasMore?: boolean
}

const Clubs = (props: IClubProps) => {
  // Props
  const { isShowSeeAllLink, handleSeeAll, club, handleMore, hasMore } = props

  const handleScroll = () => {
    const scrollableDiv = document.getElementById("scrollableDiv")
    if (scrollableDiv) {
      const scrollPosition = scrollableDiv.scrollTop
      const totalHeight = scrollableDiv.scrollHeight
      const clientHeight = scrollableDiv.clientHeight
      if (totalHeight - (scrollPosition + clientHeight) < 100) {
        handleMore?.()
      }
    }
  }

  const removeDuplicatesById = (clubs: IClubDetailsSearch[]) => {
    const uniqueIds = new Set<number>()
    return clubs.filter((item) => {
      if (uniqueIds.has(item.id)) {
        return false
      }
      uniqueIds.add(item.id)
      return true
    })
  }
  const uniqueClubs = removeDuplicatesById(club)
  return (
    <div className="bg-white rounded-[16px]">
      <div
        className="pt-[12px] md:px-[24px] px-4 max-h-[600px] h-full overflow-y-auto"
        id="scrollableDiv"
        onScroll={handleScroll}
      >
        <div className="pt-5 text-[20px] font-inter font-bold mb-[19px] ml-[24px] md:ml-[16px] ">
          Clubs
        </div>
        <InfiniteScroll
          dataLength={club?.length ?? 0}
          next={handleMore as any}
          hasMore={hasMore || false}
          loader={
            !isShowSeeAllLink && (
              <div className="flex justify-center items-center h-14">
                <p className="flex justify-center items-center">
                  Loading
                  <span className="ml-2 flex justify-center items-center">
                    <CircularProgress size={15} style={{ color: "#000" }} />
                  </span>
                </p>
              </div>
            )
          }
        >
          <div className="px-4 mb-4">
            {uniqueClubs?.map((el: IClubDetailsSearch, indx: number) => (
              <Card club={el} key={el?.id} idx={indx} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
      {isShowSeeAllLink && (
        <div>
          <div className="flex justify-center items-center py-[12px] mb-4">
            <button
              onClick={() => handleSeeAll?.()}
              className="font-inter text-gr-primaryR2 text-lg font-normal mb-3 hover:underline"
            >
              See all clubs
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Clubs

const Card = ({ club, idx }: { club: any | undefined; idx: number }) => {
  // Hooks
  const { handleFollowClub, isAlreadyFollowingClub, handleUnFollowClub } = useUserHook()
  const [followersCount, setFollowersCount] = useState<number>(club?.followers?.length)
  const { currentLoginProfile } = useContext(AppContext)

  const onClickFollowClub = async (clubId: number | undefined) => {
    try {
      if (!clubId) return
      await handleFollowClub(clubId)
      setFollowersCount(club?.followers?.length + 1)
    } catch {}
  }
  const handleUnfollow = async (clubId: number | undefined) => {
    try {
      if (!clubId) return
      await handleUnFollowClub(clubId)
      setFollowersCount(followersCount - 1)
    } catch {}
  }

  return (
    <div className="">
      <div className="flex flex-col sm:flex-row sm:justify-between md:items-center ">
        <div className=" flex  gap-3 sm:gap-[15px]">
          <div className="flex-shrink-0 w-max">
            <Link to={`${AUTH_CLUB_PROFILE}/${club?.id}`}>
              <img src={club?.clubLogo?.url ?? ClubLogo} alt="logo" className="w-[54px] h-[54px]" />
            </Link>
          </div>
          <div className="">
            <Link to={`${AUTH_CLUB_PROFILE}/${club?.id}`}>
              <div className="text-sm font-inter font-bold mb-1 hover:underline">{club?.name}</div>
            </Link>
            <div className="text-xs font-inter text-gr-neutralT3 mb-1">{club?.country}</div>
            <div className="flex gap-4 text-[12px] font-inter text-gr-neutralT3">
              <div>Members-{club?.members?.length}</div>
              <div>Followers-{followersCount}</div>
            </div>
          </div>
        </div>
        {currentLoginProfile?.profileType !== "Club" && (
          <div className="z-30 flex justify-end md:justify-center items-center pt-2">
            {!isAlreadyFollowingClub(Number(club?.id)) ? (
              <div
                onClick={() => onClickFollowClub(club?.id)}
                className="mb-2 z-40 w-[96px] text-[12px] md:w-[100px] cursor-pointer h-[30px] text-red-darkRed bg-red-secondaryRed  rounded-2xl flex items-center justify-center hover:border hover:border-gr-primaryR2"
              >
                Follow
              </div>
            ) : (
              <div
                className="mb-2 z-40 w-[96px] text-[12px] md:w-[100px] cursor-pointer h-[30px] text-red-darkRed bg-red-secondaryRed  rounded-2xl flex items-center justify-center hover:border hover:border-gr-primaryR2"
                onClick={() => handleUnfollow(club?.id)}
              >
                Unfollow
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className={`border-b ml-[33px] mt-[13px] mb-[15px] ${
          idx == 4 ? "border-none" : "border-gr-primaryR8"
        }`}
      ></div>
    </div>
  )
}
