import React, { useContext, useEffect, useState } from "react"
import AppContext from "../../../context/app-context/AppContext"
import { IClubDetails } from "../../../modals/Club"
import { useClubAPI } from "../../../services/useClubAPI"
import { Link } from "react-router-dom"
import { AUTH_USER_PROFILE } from "../../../routes/RouteConstants"
import { getNameInitials } from "../../../utils/CommonUtils"

function Members() {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clubData, setClubData] = useState<IClubDetails>()

  // const Open = Boolean(anchorEl)

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  //Api
  const { getClubById } = useClubAPI()

  // Context
  const { loginAsProfileDetails, primaryUserDetails } = useContext(AppContext)

  const clubId = loginAsProfileDetails?.club?.id
    ? loginAsProfileDetails?.club?.id
    : primaryUserDetails?.club?.id

  // useEffect
  useEffect(() => {
    if (clubId) fetchClubDetails()
  }, [])

  // Functions
  const fetchClubDetails = async () => {
    try {
      if (!clubId) return
      const r = await getClubById(String(clubId))
      setClubData(r?.data)
    } catch {}
  }

  return (
    <div className="bg-white rounded-2xl  pt-5 pb-2 w-full">
      <div className="px-4 flex justify-between items-center mb-4">
        <p className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">Members</p>
        {/* <div className="flex space-x-4">
          <div>
            <Button
              id="basic-button"
              aria-controls={Open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Open ? "true" : undefined}
              onClick={handleClick}
            >
              <FilterIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>All Season</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>season 2018-19</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>season 2019-20</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>season 2020-21</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>season 2021-22</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>season 2022-23</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>season 2019-20</div>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex items-center gap-[14px]">
                  <div>
                    <Checkbox />
                  </div>
                  <div>season 2019-20</div>
                </div>
              </MenuItem>
            </Menu>
          </div>
          <img src={ThreeDotVertical}></img>
        </div> */}
      </div>
      <div className="px-4 mb-4">
        {clubData?.attributes?.members?.map((el, indx) => (
          <div
            key={indx}
            className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5 hover:bg-bgRed 
              ${indx === 0 && "bg-gr-primaryN9"}`}
          >
            <div className="flex justify-start gap-4 items-center">
              {el?.profilePicture?.formats?.thumbnail?.url ? (
                <div>
                  <img
                    src={el?.profilePicture?.formats?.thumbnail?.url}
                    alt="profile-image"
                    className="rounded-full w-[48px] h-[48px]"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                  <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                    {getNameInitials(el?.firstname, el?.lastname)}
                  </span>
                </div>
              )}

              <div>
                <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px]">
                  <Link
                    to={`${AUTH_USER_PROFILE}/${el?.id}`}
                  >{`${el?.firstname} ${el?.lastname}`}</Link>
                </p>
                <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                  {el?.profileType}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Members
