import React, { useEffect, useState, useContext } from "react"
import GRTextfield from "../ui/GRTextfield"
import GRButton from "../ui/GRButton"
import MessageIcon from "../../assets/svg/MessageIcon"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"
import { useLoginUserAPI } from "../../services/useLoginUserAPI"
import { useFormik } from "formik"
import { inviteByEmailFormValidation } from "../../validations/Validations"
import { successToast } from "../../utils/ToastUtils"
import { IInvitedPeopleResponse, IInvitedPeoples } from "../../modals/User"
import { Link } from "react-router-dom"
import { AUTH_MESSAGE, AUTH_USER_PROFILE, AUTH_REFFERALS } from "../../routes/RouteConstants"
import LeftArrow from "../../assets/svg/LeftArrow"
import { AUTH_DASHBOARD } from "../../routes/RouteConstants"
import { useUserHook } from "../../hooks/useUserHook"
import AppContext from "../../context/app-context/AppContext"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getNameInitials } from "../../utils/CommonUtils"
import { Typography, Popover } from "@mui/material"
import HeaderSearchFeild from "../Header/HeaderSearchFeild"
import { topScroll } from "../../utils/CommonUtils"

const InviteAndReferral = () => {
  // State
  const [invitedPeoples, setInvitedPeoples] = useState<IInvitedPeopleResponse>()
  const [invitedPeopleCount, setInvitedPeopleCount] = useState<number>()

  // APIs
  const { createReferral } = useLoginUserAPI()
  const { getInvitedPeoples } = useLoginUserAPI()

  //hooks
  const { isAgent } = useUserHook()

  useEffect(() => {
    fetchInvitePeoples()
  }, [])

  // Functions

  const fetchInvitePeoples = async () => {
    try {
      const r = await getInvitedPeoples()
      const fInvitePeople = r?.data?.filter((ip) => ip?.referredUser)
      setInvitedPeopleCount(fInvitePeople?.length)
      setInvitedPeoples(r)
    } catch {}
  }
  const { loginAsProfileDetails } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = async (values: any, formikBag: any) => {
    try {
      // Check if the email already exists in invitedPeoples
      const isExistingEmail = invitedPeoples?.data?.some(
        (ip) => ip?.referredUser?.email === values.email,
      )

      // Check if the email matches the current user's email
      const isSelfRefer = loginAsProfileDetails?.email === values.email

      if (isExistingEmail) {
        // Handle the case where the email already exists
        toast.error("This email is already referred.")
        return
      }

      if (isSelfRefer) {
        // Handle the case where the user is referring themselves
        toast.error("You cannot refer yourself.")
        return
      }
      setIsLoading(true)
      await createReferral({ data: { email: values?.email } })
      successToast("Invitation sent successfully")
      setIsLoading(false)
      formikBag?.resetForm()
    } catch (error) {
      // Handle errors if needed
      setIsLoading(false)
      console.error("Error sending invite:", error)
    }
  }

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: { email: "" },
    validationSchema: inviteByEmailFormValidation,
    onSubmit: onSubmit,
  })

  // Handler function to toggle the message
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleInviteOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleInviteClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const currentRoute = window.location.pathname

  // Check if the current route matches AUTH_REFFERLS
  const showLeftArrow = currentRoute === AUTH_REFFERALS

  return (
    <div>
      {showLeftArrow && (
        <div className="bg-white py-6 px-4  shadow-md mb-9 ">
          <Link to={AUTH_DASHBOARD}>
            <div className=" ">
              <LeftArrow />
            </div>
          </Link>
        </div>
      )}
      <HeaderSearchFeild />
      <div className="min-h-[50px]  px-4 pt-5 pb-2 bg-white rounded-2xl">
        <div className="flex items-center justify-between font-semibold font-inter mb-3 text-lg">
          {!isAgent ? "Invite and referrals" : "Invite a new prospect"}
          <div
            className="ml-3 cursor-pointer"
            aria-owns={open ? "mouse-over-invite" : undefined}
            aria-haspopup="true"
            onMouseEnter={handleInviteOpen}
            onMouseLeave={handleInviteClose}
          >
            <ExclamtionIcon />
          </div>
        </div>

        <Popover
          id="mouse-over-invite"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handleInviteClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 2 }}>
            <div className="flex gap-2 text-sm font-inter">Invite teammate/friend</div>
          </Typography>
        </Popover>

        <div className="text-sm font-medium font-inter mb-2">Email</div>

        <form className="" onSubmit={handleSubmit}>
          <div className="mb-[7px]">
            <GRTextfield
              type="text"
              value={values?.email}
              onChange={handleChange}
              name="email"
              error={touched?.email && Boolean(errors?.email)}
              helperText={touched?.email && errors?.email ? errors?.email : ""}
              width="100%"
              placeholder="Enter email"
            />
          </div>
          <div className="flex justify-end mt-3">
            <GRButton
              isSubmitting={isLoading}
              type="submit"
              fontSize="12px"
              height={33}
              borderRadius={100}
              label="Send invite"
              className="w-[100px]"
            />
          </div>
        </form>

        <div className="border-b border-gr-primaryR4 my-4"></div>

        <div className="text-sm font-semibold font-inter mb-3">
          Referred ({invitedPeopleCount || 0})
        </div>

        {/* list */}
        <div className="flex flex-col gap-[17px] mb-4 overflow-y-auto max-h-[150px]">
          {invitedPeoples?.data?.map(
            (ip) => ip?.referredUser && <ReferredInfoCard key={ip?.id} data={ip} />,
          )}
        </div>
      </div>
    </div>
  )
}

interface IReferredInfoCardProps {
  data?: IInvitedPeoples
}

const ReferredInfoCard = (props: IReferredInfoCardProps) => {
  // props
  const { data } = props
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        {/* logo and name */}
        <div className="flex justify-center items-center w-14 h-14 rounded-full bg-gr-primaryR1">
          {data?.referredUser?.profilePicture ? (
            <img
              src={data.referredUser.profilePicture.url}
              className="w-full h-full rounded-full object-cover"
              alt="profileImg"
            />
          ) : (
            <span className="text-white font-semibold">
              {getNameInitials(data?.referredUser?.firstname, data?.referredUser?.lastname)}
            </span>
          )}
        </div>
        <Link to={`${AUTH_USER_PROFILE}/${data?.referredUser?.id}`} onClick={topScroll}>
          <div className="font-inter text-sm font-semibold cursor-pointer hover:underline">
            {data?.referredUser?.firstname} {data?.referredUser?.lastname}
          </div>
        </Link>
      </div>
      {/* message icon */}
      <Link to={`${AUTH_MESSAGE}?chatwith=${data?.referredUser?.id}`}>
        <div className="">
          <MessageIcon width={20} height={20} />
        </div>
      </Link>
    </div>
  )
}

export default InviteAndReferral
