import React, { useState, useEffect, useContext } from "react"
import ClubCard from "./ClubCard"
import { Link } from "react-router-dom"
import FilterIcon from "../../../assets/svg/FilterIcon"
import FilterOpportunityPopup from "./FilterOpportunityPopup"
import { AUTH_OPPORTUNITY } from "../../../routes/RouteConstants"
import { useOpportunityAPI } from "../../../services/useOpportunityAPI"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import AppContext from "../../../context/app-context/AppContext"
import { useUserHook } from "../../../hooks/useUserHook"

const Opportunities = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[]>()

  // Context
  const { loginAsProfileDetails, currentLoginProfile } = useContext(AppContext)

  // Hook
  const { isLoginAsClub } = useUserHook()

  // APIs
  const { getOpportunityByProfileType, getOpportunityByClubId } = useOpportunityAPI()

  useEffect(() => {
    getOpportunity()
  }, [])
  const clubId = loginAsProfileDetails?.club?.id || currentLoginProfile?.profileId
  const getOpportunity = async () => {
    try {
      const opportunityList =
        isLoginAsClub && clubId
          ? await getOpportunityByClubId(clubId)
          : await getOpportunityByProfileType(`profileType=${loginAsProfileDetails?.profileType}`)
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }

  return (
    <div className="flex flex-col bg-white pb-3 rounded-2xl">
      {open ? (
        <FilterOpportunityPopup
          setOpen={setOpen}
          setOpportunity={setOpportunities}
          getOpportunities={getOpportunity}
        />
      ) : (
        <div>
          <div className="flex items-center  ">
            <div className="ml-4 mt-2">
              <h2 className="text-lg font-semibold font-inter">Opportunities </h2>
            </div>
            <div className="mt-4 flex items-center justify-end gap-[10px] mb-[9px] w-[360px]  mr-4 cursor-pointer">
              <div className="text-blue-400 pt-1" onClick={() => setOpen(true)}>
                <FilterIcon />
              </div>
            </div>
          </div>
          {opportunities &&
            opportunities?.map(
              (opportunity, index) =>
                index < 3 && <ClubCard opportunity={opportunity} key={index} />,
            )}
          <div className="flex justify-center items-center">
            <div className="font-inter text-gr-neutralT4 text-xs leading-4 underline hover:text-gr-primaryR2 pb-1">
              <Link to={AUTH_OPPORTUNITY}>View all</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Opportunities
