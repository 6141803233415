import React, { useState, useCallback } from "react"
import { GRDialog } from "../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import GRTextfield from "../ui/GRTextfield"
import DialogHeader from "./DialogHeader"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import {
  CircularProgress,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import { debounce } from "lodash"
import { useAgentAPI } from "../../services/useAgentAPI"
import { connectAgentValidation } from "../../validations/Validations"
import { successToast } from "../../utils/ToastUtils"
import { AutocompleteInputCss } from "../ui/GRSelect"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"
import CrossIcon from "../../assets/svg/CrossIcon"
import { ISearchUserByNameResponse } from "../../modals/User"

interface IConnectAgentFormValues {
  email: string
  firstName: string
  lastName: string
  agentName: string
  agentID: string
}

const initialValues = {
  name: "",
  email: "",
  firstName: "",
  lastName: "",
  agentName: "",
  agentID: "",
  type: "searchAgent",
}

interface IGRConnectAgentDialogProps {
  isShowDialog: boolean
  onClose: () => void
  onSuccess: () => void
}

const ConnectAgentDialog: React.FC<IGRConnectAgentDialogProps> = ({
  isShowDialog,
  onClose,
  onSuccess,
}) => {
  // APIs
  const { isLoading, searchAgentsByName, connectAgent } = useAgentAPI()

  // State
  const [options, setOptions] = useState<ISearchUserByNameResponse>()
  const [selectedType, setSelectedType] = useState<string>("searchAgent")

  const handleSubmit = async (values: IConnectAgentFormValues) => {
    try {
      const payload = {
        agentEmail: values?.email,
        agentFirstName: values?.firstName,
        agentLastName: values?.lastName,
        agentID: values?.agentID,
      }
      const agentConnection = await connectAgent({ data: payload })
      if (agentConnection?.data?.user) {
        successToast("Agent Added")
      } else {
        successToast("New Agent Invited")
      }
      onClose()
      onSuccess()
    } catch {}
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: connectAgentValidation,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
  })

  const getEmailValue = (id: string) => {
    const o = options?.data?.find((u) => u?.id === parseInt(id))
    if (!o) return
    return {
      label: `${o?.firstname} ${o?.lastname} ${
        o?.clubMember?.name ? `- ${o?.clubMember?.name}` : ""
      }`,
      value: o?.id,
    }
  }

  const fetchResults = async (searchStr: string) => {
    try {
      if (!searchStr) return
      const r = await searchAgentsByName(searchStr)
      setOptions(r)
    } catch {}
  }

  const debounceSearch = useCallback(debounce(fetchResults, 1000), [])
  const [messagePopoverAnchorEl, setMessagePopoverAnchorEl] = useState<null | HTMLElement>(null)

  // Handler function to toggle the message
  const toggleMessagePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagePopoverAnchorEl(messagePopoverAnchorEl ? null : event.currentTarget)
  }

  const body = (
    <div className="mx-7 mb-4">
      <form onSubmit={formik.handleSubmit} className="compeleteProfileForm">
        {/* <div className="">
          <div className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-2">Basic info</div>
        </div>
        <div className="flex mb-3">
          <span className="text-red-600 text-lg -mt-2">*</span>
          <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
        </div> */}
        <RadioGroup
          id="type"
          name="type"
          row
          className="sm:gap-3 gap-1"
          value={selectedType} // Use selectedType here
          onChange={(e) => {
            setSelectedType(e.target.value)
            formik.handleChange(e)
          }}
        >
          <FormControlLabel value="searchAgent" control={<Radio />} label="Search agent" />
          <FormControlLabel value="inviteAgent" control={<Radio />} label="Invite new agent" />
        </RadioGroup>
        {selectedType === "searchAgent" ? (
          <>
            {/* {serch agent} */}
            <div className="flex flex-col mb-4">
              <label className="flex" htmlFor="">
                <div className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-2">
                  Search agent
                </div>

                <div
                  className="mt-1.5 ml-[8px] cursor-pointer"
                  onMouseEnter={toggleMessagePopover}
                  onMouseLeave={() => setMessagePopoverAnchorEl(null)}
                >
                  <ExclamtionIcon />
                  <Popover
                    open={Boolean(messagePopoverAnchorEl)}
                    anchorEl={messagePopoverAnchorEl}
                    onClose={() => setMessagePopoverAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      <div className="flex gap-2">
                        <span>Search for your agent within GR.</span>
                        <div className="mt-1 cursor-pointer">
                          <CrossIcon onClick={() => setMessagePopoverAnchorEl(null)} />
                        </div>
                      </div>
                    </Typography>
                  </Popover>
                </div>
              </label>
              <div className="">
                <Autocomplete
                  autoFocus
                  id="email-search"
                  onChange={(e, v) => {
                    formik.setFieldValue("agentID", v?.value)
                    formik.setFieldValue("name", v?.label)
                  }}
                  value={getEmailValue(formik?.values?.agentID)}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  getOptionLabel={(option) => option?.label}
                  options={
                    options?.data?.map((o) => ({
                      label: `${o?.firstname} ${o.lastname} ${
                        o?.clubMember?.name ? `- ${o?.clubMember?.name}` : ""
                      }`,
                      value: o?.id,
                    })) || []
                  }
                  loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => debounceSearch(e?.target?.value)}
                      placeholder="Search name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          </React.Fragment>
                        ),
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "darkRed",
                        },
                        "& fieldset": {
                          borderColor: formik.errors.name ? "#FFCAC7 !important" : "#D4D4D4",
                        },
                        ...AutocompleteInputCss,
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  )}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {/* invite agent */}
            <div>
              <div className="">
                <div className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-2">
                  Invite new agent
                </div>
              </div>
              {/* email address */}
              <div className="flex flex-col mb-4">
                <label className="block text-sm leading-6 font-[400] font-inter mb-1" htmlFor="">
                  Enter email<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <GRTextfield
                    id="email"
                    type="email"
                    width="100%"
                    name="email"
                    placeholder="Enter email id"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
              </div>

              {/* firstName and lastName */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 ">
                {/* first name  */}
                <div className="w-full mb-4">
                  <label className="block text-sm leading-6 font-[400] font-inter mb-1" htmlFor="">
                    Agent first name<span className="text-red-600">*</span>
                  </label>
                  <GRTextfield
                    id="firstName"
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    className="border border-gr-primaryN8 w-[272px] sm:w-full rounded-lg px-4 py-2 "
                  />
                </div>
                {/* Last Name */}
                <div className="w-full mb-4">
                  <label className="block text-sm leading-6 font-[400] font-inter mb-1" htmlFor="">
                    Agent last name<span className="text-red-600">*</span>
                  </label>
                  <GRTextfield
                    id="lastName"
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    className="border border-gr-primaryN8  w-[272px] sm:w-full rounded-lg px-4 py-2 "
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/*  Agent ID*/}
        {/* <div className="sm:mt-2 w-[284px] sm:w-full">
          <label className="cursor-pointer block text-[15px] leading-6 font-[400]" htmlFor="">
            Agent id
          </label>
          <GRTextfield
            id="agentID"
            type="text"
            width="100%"
            name="agentID"
            onChange={formik.handleChange}
            value={formik.values.agentID}
            error={formik.touched.agentID && Boolean(formik.errors.agentID)}
            helperText={formik.touched.agentID && formik.errors.agentID}
            className="border border-gr-primaryN8 w-[284px] sm:w-full h-[48px] rounded-lg px-4 py-2 "
          />
        </div> */}
        <div className="flex justify-between items-center mt-4 mb-6">
          <GRButton onClick={onClose} label="Cancel" type="button" className="w-[100px]" />
          <GRButton
            isSubmitting={formik?.isSubmitting}
            onClick={formik.handleSubmit}
            label="Save"
            className="w-[100px]"
          />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Connect agent" onClose={onClose} />}
      />
    </>
  )
}

export default ConnectAgentDialog
