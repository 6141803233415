import {
  IChatSummaryResponse,
  IConnectedUserForChatResponse,
  IInitiateChatResponse,
  IGetMessagesResponse,
  IMessageSummaryResponse,
  IUnreadMessageCountResponse,
} from "../modals/Chat"
import useBaseAPI from "./useBaseAPI"

export const useChatAPI = () => {
  const { GET, POST, DELETE, isLoading } = useBaseAPI()

  const getUserChat = (userId: any): Promise<IChatSummaryResponse> => {
    return GET(`/get-user-chat/${userId}`, null, undefined, true)
  }

  const initiateChat = (payload: IInitiateChatPayload): Promise<IInitiateChatResponse> => {
    return POST("/create-chat", payload)
  }

  const getConnectedUserForChat = (): Promise<IConnectedUserForChatResponse> => {
    return GET(`/get-connected-user`)
  }

  const deleteChat = (chatId: number): Promise<any> => {
    return DELETE(`/delete-chat/${chatId}`)
  }

  const addMessage = (payload: IAddMessagePayload): Promise<IMessageSummaryResponse> => {
    return POST("/add-message", payload, undefined, true)
  }

  const getMessage = (chatId: any): Promise<IGetMessagesResponse> => {
    return GET(`/get-message-by-chat/${chatId}`, null, undefined, true)
  }

  const getUnreadMessageCount = (): Promise<IUnreadMessageCountResponse> => {
    return GET(`/unread-message-count`, null, undefined, true)
  }

  return {
    isLoading,
    getUserChat,
    getConnectedUserForChat,
    initiateChat,
    deleteChat,
    addMessage,
    getMessage,
    getUnreadMessageCount,
  }
}

export interface IInitiateChatPayload {
  data: {
    receiverId: number
    profileType: string
  }
}

export interface IAddMessagePayload {
  data: {
    text: string
    chatId: number
  }
}
