import React, { useState } from "react"
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import AppLayout from "./components/Layout/AppLayout"
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes"
import {
  AUTH_CLUB_PROFILE,
  AUTH_DASHBOARD,
  AUTH_MESSAGE,
  AUTH_NOTIFICATION,
  AUTH_OPPORTUNITY,
  AUTH_OPPORTUNITY_DETAIL,
  AUTH_PLAYER_ALL_CLUBS,
  AUTH_SEARCH,
  AUTH_FOLLOWERS,
  AUTH_FOLLOWINGS,
  AUTH_MY_PROFILE,
  NOT_FOUND,
  UNAUTH_EMAIL_CONFIRMATION,
  UNAUTH_FORGOTPASSWORD,
  UNAUTH_LOGIN,
  UNAUTH_REGISTER,
  UNAUTH_RESETPASSWORD,
  AUTH_USER_PROFILE,
  AUTH_REFFERALS,
  AUTH_CHANGE_PASSWORD,
  UNAUTH_TERMSPOLICY,
  UNAUTH_FAQ,
} from "./routes/RouteConstants"
import UnAuthenticatedRoutes from "./routes/UnAuthenticatedRoutes"
import AgentProfile from "./screens/AgentProfile"
import ClubProfile from "./screens/ClubProfile"
import PlayerDashboard from "./screens/DashBoard"
import EmailConfirmation from "./screens/EmailConfirmation"
import ForgotPassword from "./screens/ForgotPassword"
import Login from "./screens/Login"
import Message from "./screens/Message"
import Notification from "./screens/Notification"
import Opportunity from "./screens/Opportunity"
import UserAllClubs from "./screens/UserAllClubs"
import UserProfile from "./screens/UserProfile"
import Register from "./screens/Register"
import ResetPassword from "./screens/ResetPassword"
import SearchScreen from "./screens/SearchScreen"
import PlayerOpportunityDetail from "./components/dashboard/JobsProfile"
import { useUserHook } from "./hooks/useUserHook"
import NotFound from "./components/notFound/NotFound"
import ClubDashBoard from "./screens/ClubDashBoard"
import ClubOpportunity from "./screens/ClubOpportunity"
import ClubOpportunityDetail from "./components/club/jobdetail/ClubJobDetail"
import AgentDashboard from "./screens/AgentDashboard"
import InviteAndReferral from "./components/playerProfile/InviteAndReferral"
import StepSetNewPassword from "./components/forgotPasswordFlow/StepSetNewPassword"
import StepPasswordReset from "./components/forgotPasswordFlow/StepPassowordReset"
import TermsPolicy from "./screens/TermsPolicy"
import Landing from "./screens/Landing"
import UserFollowing from "./components/playerProfile/UserFollowing"
import UserFollower from "./components/playerProfile/UserFollower"
import Faq from "./screens/Faq"

const AppRoutes: React.FC = () => {
  const {
    isLoginAsAgent,
    isLoginAsChild,
    isLoginAsClub,
    isLoginAsPlayer,
    isLoginAsCoach,
    isLoginAsParent,
    currentLoginProfileId,
  } = useUserHook()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showSuccessfulReset, setShowSuccessfulReset] = useState<boolean>(false)

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* comman routes */}
        <Route path={UNAUTH_TERMSPOLICY} element={<TermsPolicy />} />
        <Route path={UNAUTH_FAQ} element={<Faq />} />

        {/* Unauthenticated routes */}
        <Route element={<UnAuthenticatedRoutes />}>
          <Route path={"/"} element={<Landing />} />
          <Route path={UNAUTH_LOGIN} element={<Login />} />
          <Route path={UNAUTH_EMAIL_CONFIRMATION} element={<EmailConfirmation />} />
          <Route path={UNAUTH_REGISTER} element={<Register />} />
          <Route path={UNAUTH_FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route path={UNAUTH_RESETPASSWORD} element={<ResetPassword />} />
        </Route>

        {/* Authenticated routes */}
        <Route element={<AuthenticatedRoutes />}>
          <Route element={<AppLayout />}>
            <Route
              path={AUTH_DASHBOARD}
              element={
                isLoginAsPlayer || isLoginAsParent || isLoginAsCoach || isLoginAsChild ? (
                  <PlayerDashboard key={currentLoginProfileId} />
                ) : isLoginAsClub ? (
                  <ClubDashBoard key={currentLoginProfileId} />
                ) : isLoginAsAgent ? (
                  <AgentDashboard key={currentLoginProfileId} />
                ) : (
                  <Navigate to={NOT_FOUND} />
                )
              }
            />
            <Route
              path={AUTH_MY_PROFILE}
              element={
                isLoginAsPlayer || isLoginAsChild || isLoginAsParent || isLoginAsCoach ? (
                  <UserProfile key={currentLoginProfileId} canEdit />
                ) : isLoginAsAgent ? (
                  <AgentProfile key={currentLoginProfileId} canEdit />
                ) : isLoginAsClub ? (
                  <ClubProfile key={currentLoginProfileId} canEdit />
                ) : (
                  <Navigate to={NOT_FOUND} />
                )
              }
            />
            <Route path={`${AUTH_USER_PROFILE}/:userId`} element={<UserProfile />} />
            <Route path={`${AUTH_CLUB_PROFILE}/:clubId`} element={<ClubProfile />} />
            <Route path={AUTH_SEARCH} element={<SearchScreen />} />
            <Route path={AUTH_NOTIFICATION} element={<Notification />} />
            <Route path={AUTH_MESSAGE} element={<Message />} />
            <Route path={AUTH_FOLLOWERS} element={<UserFollower />} />
            <Route path={AUTH_FOLLOWINGS} element={<UserFollowing />} />
            <Route path={AUTH_REFFERALS} element={<InviteAndReferral />} />
            <Route
              path={AUTH_CHANGE_PASSWORD}
              element={
                !showSuccessfulReset ? (
                  <StepSetNewPassword onPasswordResetSuccess={() => setShowSuccessfulReset(true)} />
                ) : (
                  <StepPasswordReset />
                )
              }
            />

            <Route
              path={AUTH_OPPORTUNITY}
              element={
                isLoginAsPlayer ||
                isLoginAsChild ||
                isLoginAsAgent ||
                isLoginAsParent ||
                isLoginAsCoach ? (
                  <Opportunity key={currentLoginProfileId} />
                ) : isLoginAsClub ? (
                  <ClubOpportunity key={currentLoginProfileId} />
                ) : (
                  <Navigate to={NOT_FOUND} />
                )
              }
            />
            <Route
              path={`${AUTH_OPPORTUNITY_DETAIL}/:id`}
              element={
                isLoginAsPlayer || isLoginAsChild || isLoginAsParent || isLoginAsCoach ? (
                  <PlayerOpportunityDetail key={currentLoginProfileId} />
                ) : isLoginAsClub ? (
                  <ClubOpportunityDetail key={currentLoginProfileId} />
                ) : (
                  <Navigate to={NOT_FOUND} />
                )
              }
            />

            <Route
              path={AUTH_PLAYER_ALL_CLUBS}
              element={
                isLoginAsPlayer || isLoginAsChild || isLoginAsParent || isLoginAsCoach ? (
                  <UserAllClubs key={currentLoginProfileId} />
                ) : (
                  <Navigate to={NOT_FOUND} />
                )
              }
            />
          </Route>

          <Route path={"*"} element={<NotFound />} />
        </Route>
      </>,
    ),
  )

  return <RouterProvider router={router} />
}

export default AppRoutes
