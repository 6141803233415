import React, { useContext, useState } from "react"
import GotoIcon from "../../assets/svg/GotoIcon"
import CreateFamilyMemberDialog from "../dialogs/AddFamilyMemberDialog"
import EmptyTileFamilyMembers from "./EmptyTileFamilyMembers"
import AppContext from "../../context/app-context/AppContext"
import { AUTH_USER_PROFILE } from "../../routes/RouteConstants"
import { Link } from "react-router-dom"
import Button from "@mui/material/Button"
import AddBtn from "../../assets/svg/AddBtn"
import { getNameInitials } from "../../utils/CommonUtils"
import { useUserHook } from "../../hooks/useUserHook"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ConnectFamilyMemberDialog from "../dialogs/ConnectFamilyMemberDialog"

const FamilyMembers = () => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const [isShowAddFamilyMemberDialog, setIsShowAddFamilyMemberDialog] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isShowConnectFamilyMemberDialog, setIsShowConnectFamilyMemberDialog] =
    useState<boolean>(false)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Hook
  const { fetchLoginAsUserProfile, handleSwitchAccount } = useUserHook()

  //API
  const { loginAsProfileDetails, primaryUserDetails } = useContext(AppContext)
  const handleMemberSuccess = async () => {
    await fetchLoginAsUserProfile()
    setIsShowAddFamilyMemberDialog(false)
  }

  const handleConnectFamilyMember = async () => {
    await fetchLoginAsUserProfile()
    setIsShowConnectFamilyMemberDialog(false)
  }

  return (
    <>
      {loginAsProfileDetails?.member && loginAsProfileDetails?.member.length > 0 ? (
        <div className="bg-white px-4 pt-5 w-full rounded-2xl">
          <div className="flex justify-between items-center mb-2">
            <p className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">
              Family members
            </p>
            <div>
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="!p-0 !min-w-[58px]"
                >
                  <AddBtn />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem onClick={() => setIsShowConnectFamilyMemberDialog(true)}>
                    Connect with family member (current GR user)
                  </MenuItem>
                  <MenuItem onClick={() => setIsShowAddFamilyMemberDialog(true)}>
                    Add family member (new GR user)
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <div
            className={`mb-3 min-h-[50px] max-h-[300px]  ${
              showAll ? "overflow-y-scroll" : "overflow-y-hidden"
            }`}
          >
            {loginAsProfileDetails?.member?.map((member, indx) => (
              <div
                key={indx}
                className={`flex justify-between items-center px-3 hover:bg-bgRed rounded-[10px] py-2 mb-1.5 mt-2
               `}
              >
                <div className="flex justify-start gap-4 items-center">
                  {member?.childMember?.profilePicture?.formats?.thumbnail?.url ||
                  member?.member?.profilePicture?.formats?.thumbnail?.url ? (
                    <button
                      type="button"
                      onClick={() =>
                        member?.childMember?.id &&
                        handleSwitchAccount({
                          profileId: member?.childMember?.id,
                          profileType: "Child",
                        })
                      }
                    >
                      <img
                        src={
                          member?.childMember?.profilePicture?.formats?.thumbnail?.url ||
                          member?.member?.profilePicture?.formats?.thumbnail?.url
                        }
                        alt="profile-image"
                        className="rounded-full w-[48px] h-[48px] object-cover"
                      />
                    </button>
                  ) : member?.childMember ? (
                    <div className="flex justify-center items-center w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                      <span className=" text-xl sm:text-xl text-white font-semibold">
                        <div>
                          <button
                            type="button"
                            onClick={() =>
                              member?.childMember?.id &&
                              handleSwitchAccount({
                                profileId: member?.childMember?.id,
                                profileType: "Child",
                              })
                            }
                          >
                            {getNameInitials(
                              member?.childMember?.firstname,
                              member?.childMember?.lastname,
                            )}
                          </button>
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                      <span className=" text-xl sm:text-xl text-white font-semibold">
                        <div>
                          <Link to={`${AUTH_USER_PROFILE}/${member?.member?.id}`}>
                            {getNameInitials(member?.member?.firstname, member?.member?.lastname)}
                          </Link>
                        </div>
                      </span>
                    </div>
                  )}
                  <div>
                    <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px]">
                      {member?.childMember ? (
                        <button
                          type="button"
                          onClick={() =>
                            member?.childMember?.id &&
                            handleSwitchAccount({
                              profileId: member?.childMember?.id,
                              profileType: "Child",
                            })
                          }
                        >
                          {`${member?.childMember?.firstname || ""} ${
                            member?.childMember?.lastname || ""
                          }`.trim()}
                        </button>
                      ) : member?.member?.id === primaryUserDetails?.id ? (
                        <button
                          type="button"
                          onClick={() =>
                            member?.member?.id &&
                            handleSwitchAccount({
                              profileId: member?.member?.id,
                              profileType: member?.member?.profileType,
                            })
                          }
                        >
                          {`${member?.member?.firstname || ""} ${
                            member?.member?.lastname || ""
                          }`.trim()}
                        </button>
                      ) : (
                        <Link to={`${AUTH_USER_PROFILE}/${member?.member?.id}`}>
                          {`${member?.member?.firstname || ""} ${
                            member?.member?.lastname || ""
                          }`.trim()}
                        </Link>
                      )}
                    </p>
                    <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                      {member?.relation?.title}
                    </p>
                  </div>
                </div>
                <div className="cursor-pointer">
                  {member?.member?.id === primaryUserDetails?.id ? (
                    <button
                      type="button"
                      onClick={() =>
                        member?.member?.id &&
                        handleSwitchAccount({
                          profileId: member?.member?.id,
                          profileType: member?.member?.profileType,
                        })
                      }
                    >
                      <GotoIcon />
                    </button>
                  ) : member?.member?.id ? (
                    <Link to={`${AUTH_USER_PROFILE}/${member?.member?.id}`}>
                      <GotoIcon />
                    </Link>
                  ) : (
                    <button
                      type="button"
                      onClick={() =>
                        member?.childMember?.id &&
                        handleSwitchAccount({
                          profileId: member?.childMember?.id,
                          profileType: "Child",
                        })
                      }
                    >
                      <GotoIcon />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {loginAsProfileDetails?.member?.length > 4 && (
            <>
              <div className=" hidden lg:block border-t border-solid border-gr-primaryN8"></div>
              <div className="flex justify-center items-center py-[15px]">
                <button
                  onClick={() => {
                    setShowAll(!showAll)
                  }}
                  className="font-inter text-gr-neutralT4 text-xs leading-4 underline hover:text-gr-primaryR2"
                >
                  {showAll ? "Show less" : "Show all"}
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <EmptyTileFamilyMembers title="No family members connected yet" />
      )}
      <CreateFamilyMemberDialog
        isOpen={isShowAddFamilyMemberDialog}
        onClose={() => setIsShowAddFamilyMemberDialog(false)}
        onSuccess={handleMemberSuccess}
      />
      <ConnectFamilyMemberDialog
        isShowDialog={isShowConnectFamilyMemberDialog}
        onSuccess={handleConnectFamilyMember}
        onClose={() => setIsShowConnectFamilyMemberDialog(false)}
      />
    </>
  )
}

export default FamilyMembers
