import React, { useContext, useEffect, useState } from "react"
// import { Tooltip } from "@mui/material"
import { useNavigate } from "react-router-dom"
import AppContext from "../../context/app-context/AppContext"
import useLocationHook from "../../hooks/useLocationHook"
import { useUserHook } from "../../hooks/useUserHook"
import { ICurrentLoginProfile, IUserExperience } from "../../modals/User"
import { AUTH_CLUB_PROFILE } from "../../routes/RouteConstants"
import { useClubAPI } from "../../services/useClubAPI"
import { successToast } from "../../utils/ToastUtils"
import RecordHistoryDialog from "../dialogs/RecordHistoryDialog"
import EmptyTile from "../emptyTile/EmptyTile"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import { Button } from "@mui/material"
import EditPenIcon from "../../assets/svg/EditPenIcon"
import AddBtn from "../../assets/svg/AddBtn"

const ClubsRecords = () => {
  // State
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [isShowAll, setShowAll] = useState<boolean>(false)
  const [experienceToEdit, setExperienceToEdit] = useState<IUserExperience>()

  // Context
  const { setLoginAsProfileDetails, loginAsProfileDetails } = useContext(AppContext)

  // APIs
  const { sendClaimAdminRequest } = useClubAPI()

  // Hooks
  const navigate = useNavigate()
  const { handleSwitchAccount, isAgent, fetchLoginAsUserProfile } = useUserHook()
  const { countries } = useLocationHook()

  const handleSendClaimRequest = async (clubId: number) => {
    try {
      if (!clubId || !loginAsProfileDetails) {
        return
      }
      const updatedLoginAsProfileDetails = { ...loginAsProfileDetails }
      const userExperiences = updatedLoginAsProfileDetails?.userExperiences
      const targetExperienceIndex = userExperiences?.findIndex((ue) => ue?.club?.id === clubId)
      if (targetExperienceIndex !== -1) {
        userExperiences[targetExperienceIndex].club.adminStatus = "Pending"
      }
      setLoginAsProfileDetails(updatedLoginAsProfileDetails)
      await sendClaimAdminRequest({
        data: { club: String(clubId), user: String(loginAsProfileDetails?.id) },
      })

      successToast("Club Admin request sent successfully")
    } catch {}
  }

  const handleExperienceSuccess = async () => {
    await fetchLoginAsUserProfile()
    setIsShowDialog(false)
  }

  const handleRedirect = (clubId: number) => {
    if (loginAsProfileDetails?.club?.id === clubId) {
      if (!loginAsProfileDetails) return
      const switchAccountTo: ICurrentLoginProfile = {
        profileId: loginAsProfileDetails?.club?.id,
        profileType: "Club",
      }
      handleSwitchAccount(switchAccountTo)
    } else {
      navigate(`${AUTH_CLUB_PROFILE}/${clubId}`)
    }
  }
  const makeCurrentClubAtTop = () => {
    const approvedClub = loginAsProfileDetails?.userExperiences?.find(
      (ue) => ue?.club?.adminStatus === "Approved",
    )
    if (approvedClub) {
      const approvedClubIdx = loginAsProfileDetails?.userExperiences?.indexOf(approvedClub)
      if (approvedClubIdx !== -1 && approvedClubIdx) {
        loginAsProfileDetails?.userExperiences?.splice(approvedClubIdx, 1)
        loginAsProfileDetails?.userExperiences?.unshift(approvedClub)
      }
    }
  }
  useEffect(() => {
    makeCurrentClubAtTop()
  }, [])

  return (
    <>
      {loginAsProfileDetails?.userExperiences.length ? (
        <div className="bg-white px-4 pt-5 rounded-2xl">
          <div className="flex justify-between items-center mb-4">
            <p className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">My clubs</p>
            <Button
              onClick={() => {
                setExperienceToEdit(undefined)
                setIsShowDialog(true)
              }}
              className="!p-0 !hover:bg-transparent !min-w-[58px]"
            >
              <AddBtn />
            </Button>
          </div>

          <div
            className={`mb-4 min-h-[50px] max-h-[300px]  ${
              isShowAll ? "overflow-y-auto" : "overflow-y-hidden"
            }`}
          >
            {loginAsProfileDetails?.userExperiences
              ?.sort((a: any, b: any) =>
                b?.club?.adminStatus === "Approved" ||
                b?.club?.adminStatus === "Pending" ||
                b?.club?.adminStatus === "Claim admin"
                  ? 1
                  : -1,
              )
              ?.map((ue, indx) => (
                <div
                  key={indx}
                  className={`w-full flex px-3 rounded-[10px] py-2 mb-1.5 hover:bg-bgRed`}
                >
                  <div className="flex justify-start items-start gap-4 max-w-[94%] pr-3 w-full">
                    <button className="text-start" onClick={() => handleRedirect(ue?.club?.id)}>
                      <img
                        src={ue?.club?.clubLogo?.formats?.thumbnail?.url || ClubLogo}
                        alt="profile-image"
                        className="rounded-full min-w-[48px] w-[48px] h-[48px]"
                      />
                    </button>
                    <div className="">
                      <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px] ">
                        <button className="text-start" onClick={() => handleRedirect(ue?.club?.id)}>
                          {ue?.club?.name} ({countries?.find((c) => c.name === ue?.country)?.iso2})
                        </button>
                      </p>
                      {/* <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                      {ue?.club?.disciplines?.[0]?.title}{" "}
                      <Tooltip
                        title={
                          <React.Fragment>
                            {ue?.club?.disciplines
                              ?.filter((_e, i) => i !== 0)
                              ?.map((d) => d?.title)
                              ?.join(", ")}
                          </React.Fragment>
                        }
                        arrow
                      >
                        <span>
                          {ue?.club?.disciplines?.length > 1
                            ? `+${ue?.club?.disciplines?.length - 1}`
                            : ""}{" "}
                        </span>
                      </Tooltip>
                    </p> */}
                      {/* <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                      {ue?.club?.members?.length} Members
                    </p> */}
                      <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                        {ue?.startDate?.split("-")[0]} |{" "}
                        {ue?.endDate && ue?.endDate !== "Invalid date"
                          ? ue?.endDate?.split("-")[0]
                          : "Present"}
                      </p>
                      {!isAgent ? (
                        <>
                          {ue?.club?.adminStatus === "Approved" ? (
                            <p
                              className="rounded-full text-[11px] w-[46px]  py-[1px] px-[7px] mt-2 cursor-pointer"
                              style={{ backgroundColor: "rgba(0, 132, 21, 0.2)" }}
                            >
                              Admin
                            </p>
                          ) : ue?.club?.adminStatus === "Pending" ? (
                            <p className="rounded-full text-[11px] w-max bg-lightRed  py-[1px] px-[7px] mt-2 cursor-pointer">
                              Pending
                            </p>
                          ) : ue?.club?.adminStatus === "Claim admin" ? (
                            <p
                              onClick={() => handleSendClaimRequest(ue?.club?.id)}
                              className="underline text-[11px] text-red-darkRed font-inter mt-2 cursor-pointer"
                            >
                              Claim for admin
                            </p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex-grow"></div>
                  <div className="cursor-pointer mt-1">
                    <EditPenIcon
                      onClick={() => {
                        setIsShowDialog(true)
                        setExperienceToEdit(ue)
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
          {loginAsProfileDetails?.userExperiences?.length > 4 && (
            <>
              <div className="hidden lg:block border-b border-gr-primaryR4 "> </div>
              <div className="flex justify-center items-center py-[15px] pr-3">
                <button
                  onClick={() => {
                    setShowAll(!isShowAll)
                  }}
                  className="font-inter text-gr-neutralT4 text-xs leading-4 underline hover:text-gr-primaryR2"
                >
                  {isShowAll ? "Show less" : "Show all"}
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <EmptyTile
          title="You don’t have any active club records"
          linkTitle="Add rugby experience"
          onClick={() => {
            setExperienceToEdit(undefined)
            setIsShowDialog(true)
          }}
        />
      )}
      {isShowDialog && (
        <RecordHistoryDialog
          clubDetails={experienceToEdit}
          isShowDialog={isShowDialog}
          onClose={() => setIsShowDialog(false)}
          onSuccess={handleExperienceSuccess}
        />
      )}
    </>
  )
}

export default ClubsRecords
