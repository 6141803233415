import React, { useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import { Button, TextField } from "@mui/material"
import DialogHeader from "../dialogs/DialogHeader"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import GRTextfield from "../ui/GRTextfield"
import VideoIcon from "../../assets/svg/VideoIcon"
import { useConfigAPI } from "../../services/useConfigAPI"
import { IVideoHighlightPayload, useLoginUserAPI } from "../../services/useLoginUserAPI"
import { useUserHook } from "../../hooks/useUserHook"
import { successToast } from "../../utils/ToastUtils"
import { videoHighlightValidation } from "../../validations/Validations"
import { VideoType } from "./EditHighlightPopup"
import HighlightCrousalInPopup from "./HighlightCrousalInPopup"
import AddBtn from "../../assets/svg/CrousalImages/addMore"
import { IUploadFilesResponse } from "../../modals/Config"
import GRProgressBar from "../ui/GRProgressBar"

interface IAddVideoFormValues {
  type: string //"Upload video" | "Video URL" | "Video embed code"
  description: string
  video: string | null
  videoURL: string
  videoEmbed: string
}

const initialValues = {
  description: "",
  type: "Upload video",
  video: "",
  videoURL: "",
  videoEmbed: "",
}

interface IHighlightProps {
  isShowDialog: boolean
  onClose: () => void
}

const AddVideoHighlightPopup: React.FC<IHighlightProps> = (props) => {
  // props
  const { isShowDialog, onClose } = props
  //State
  const { uploadFiles } = useConfigAPI()
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  const [key, setKey] = useState<number>(0) // Key to remount the input element
  const [uploadFileId, setUploadFileId] = useState<number | null>(null)
  const [isUplaoding, setIsUploading] = useState<boolean>(false)
  const [uploadVideo, setUploadVideo] = useState<IUploadFilesResponse>()
  const [uploadVideos, setUploadVideos] = useState<any[]>([])
  const [highlights, setHighlights] = useState<any[]>([])
  const [selectedType, setSelectedType] = useState<string>("Upload video") // Added state for selected type
  const [isDescription, setIsDescription] = useState<boolean>(false)
  const [isYoutubeLink, setIsYoutubeLink] = useState<boolean>(false)
  const [isNotUploadedVideo, setIsNotUploadedVideo] = useState<boolean>(false)
  const [videoUrlErrorMsg, setvideoUrlErrorMsg] = useState<string>("")
  const [progress, setProgress] = useState(0)

  // Hook
  const { createVideoHighlight, isLoading } = useLoginUserAPI()
  const { fetchLoginAsUserProfile } = useUserHook()
  const handlePostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0]
        const formData = new FormData()
        formData.append("files", file, file?.name)
        setIsUploading(true)
        const uploadResponse = await uploadFiles(formData, setProgress)
        setIsUploading(false)
        if (uploadResponse?.[0]) {
          setUploadFileId(uploadResponse?.[0]?.id)
          setThumbnail(uploadResponse?.[0]?.url)
          setUploadVideo(uploadResponse?.[0])
          event.target.value = ""
        }
      } else {
        setThumbnail(null)
        event.target.value = ""
      }
      setKey((prevKey) => prevKey + 1)
    } catch (error) {}
  }
  const handleFormSubmit = async () => {
    try {
      const youtubePattern =
        /(?:https?:\/\/)?(?:www\.)?(youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|watch\?v%3D|v=|embed%2F|e%2F|vi%2F|e%2F|user%2F[^#]#user%2F[^#]#p%2F[^#]*){1}([a-zA-Z0-9_-]+)/
      setIsDescription(false)
      setIsYoutubeLink(false)
      setIsNotUploadedVideo(false)
      if (highlights.length === 0 && formik?.values?.type === VideoType.upload && !uploadFileId) {
        setIsNotUploadedVideo(true)
        return
      } else if (
        highlights.length === 0 &&
        formik?.values?.type === VideoType.url &&
        !formik?.values?.videoURL
      ) {
        setvideoUrlErrorMsg("Please provide video url")
        setIsYoutubeLink(true)
        return
      } else if ((uploadFileId || formik?.values?.videoURL) && !formik?.values?.description) {
        setIsDescription(true)
        return
      } else if (
        formik?.values?.type === VideoType.url &&
        formik?.values?.videoURL &&
        !youtubePattern.test(formik?.values?.videoURL)
      ) {
        setvideoUrlErrorMsg("Please provide valid youtube url.")
        setIsYoutubeLink(true)
        return
      }

      const highlight =
        uploadFileId || formik?.values?.videoURL
          ? {
              description: formik?.values?.description?.replaceAll(/<\/?[^>]+(>|$)/g, ""),
              video:
                uploadFileId && formik?.values?.type === VideoType.upload ? uploadFileId : null,
              type: formik?.values?.type,
              videoURL: formik?.values?.videoURL ? formik?.values?.videoURL : null,
            }
          : null
      const vHReq =
        highlights && highlight
          ? [...highlights, highlight]
          : highlights
          ? [...highlights]
          : [highlight]

      const videoPayload: IVideoHighlightPayload = {
        data: vHReq,
      }
      const addNewVideo = await createVideoHighlight(videoPayload)
      if (addNewVideo?.data) {
        fetchLoginAsUserProfile()
        onClose()
        successToast("Video content uploaded successfully !")
      }
    } catch (error) {}
  }
  const addVideo = async (values: IAddVideoFormValues, formikBag: any) => {
    try {
      setIsDescription(false)
      setIsYoutubeLink(false)
      setIsNotUploadedVideo(false)
      const highlight = {
        description: values?.description?.replaceAll(/<\/?[^>]+(>|$)/g, ""),
        video: uploadFileId && values?.type === VideoType.upload ? uploadFileId : null,
        type: values?.type,
        videoURL: values?.videoURL ? values?.videoURL : null,
      }
      setHighlights([...highlights, highlight])

      const videoHighlightPayload = {
        description: values?.description?.replaceAll(/<\/?[^>]+(>|$)/g, ""),
        videoURL: values?.videoURL ? values?.videoURL : null,
        video: values?.type === VideoType.upload ? uploadVideo : null,
        videoEmbed: null,
        type: values?.type,
      }
      setUploadVideos(
        videoHighlightPayload
          ? [...uploadVideos, videoHighlightPayload]
          : [...videoHighlightPayload],
      )
      setThumbnail(null)
      formikBag?.setFieldValue("description", "")
      formikBag?.setFieldValue("video", "")
      formikBag?.setFieldValue("videoURL", "")
      setUploadFileId(null)
    } catch (error) {
      // Handle error if needed
    }
  }

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    onSubmit: addVideo,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: videoHighlightValidation,
  })

  const body = (
    <div className="sm:mx-[32px] mx-[13px] mb-4 compeleteProfileForm">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        {/* upload type */}
        <div className="font-semibold font-inter text-[18px]">Upload type</div>
        <RadioGroup
          id="type"
          name="type"
          row
          className="sm:gap-3 gap-1"
          value={selectedType} // Use selectedType here
          onChange={(e) => {
            setSelectedType(e.target.value)
            formik.handleChange(e)
          }}
        >
          <FormControlLabel value="Upload video" control={<Radio />} label="Upload video" />
          <FormControlLabel value="Video URL" control={<Radio />} label="Youtube Video URL" />
        </RadioGroup>
        {selectedType === "Upload video" ? (
          <>
            <div title="Upload Video" className="cursor-pointer mb-2">
              <label className="">
                <div className="mb-2 w-max">
                  <VideoIcon className="cursor-pointer" />
                </div>
                <p className="font-microsoftTai text-sm text-gr-primaryN2">Video</p>
                <input
                  key={key}
                  id="video"
                  name="video"
                  type="file"
                  className="hidden"
                  accept="video/*"
                  onChange={async (event) => {
                    await handlePostChange(event)
                    formik.setFieldValue("video", setUploadFileId)
                  }}
                />
              </label>
              <div className="text-xs text-gray-700 font-semibold">
                Video size must be between 75 KB and 200 MB
              </div>
              <div className="font-inter text-errorRed text-xs">
                {(formik?.touched?.video && formik?.errors?.video) || ""}
                {isNotUploadedVideo && !formik?.errors?.video && (
                  <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
                    Please upload a video.
                  </span>
                )}
              </div>
            </div>
            {isUplaoding ? (
              // <div className="h-[350px] flex justify-center items-center">Loading...</div>
              <GRProgressBar progress={progress}></GRProgressBar>
            ) : (
              thumbnail && (
                <div className=" flex justify-center items-center w-full  h-[390px] my-2 mb-4">
                  <video controls className="bg-cover h-[390px]" src={thumbnail} />
                </div>
              )
            )}
          </>
        ) : selectedType === "Video URL" ? (
          <>
            <GRTextfield
              placeholder="Video URL"
              id="videoURL"
              name="videoURL"
              value={formik.values.videoURL}
              onChange={formik.handleChange}
              error={formik.touched.videoURL && Boolean(formik.errors.videoURL)}
              helperText={formik.touched.videoURL && formik.errors.videoURL}
            />
            {isYoutubeLink && (
              <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter -mt-3">
                {videoUrlErrorMsg}
              </span>
            )}
          </>
        ) : (
          <TextField
            fullWidth
            multiline={true}
            minRows={4}
            id="videoEmbed"
            name="videoEmbed"
            value={formik.values.videoEmbed}
            onChange={formik.handleChange}
            error={formik.touched.videoEmbed && Boolean(formik.errors.videoEmbed)}
            helperText={formik.touched.videoEmbed && formik.errors.videoEmbed}
            placeholder="Embed your link"
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "10px",
              },
            }}
          />
        )}
        <div className="min-h-[80px]">
          <TextField
            fullWidth
            multiline={true}
            minRows={2}
            id="description"
            name="description"
            value={formik.values.description}
            error={formik.touched.description && Boolean(formik.errors.description)}
            onChange={formik.handleChange}
            helperText={formik.touched.description && formik.errors.description}
            placeholder="Description"
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "10px",
              },
            }}
          />
          {isDescription && !formik.touched.description && !Boolean(formik.errors.description) && (
            <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
              Description is required
            </span>
          )}
        </div>
        <div className="flex justify-end items-center">
          <div className="flex gap-2 items-center ">
            <div>
              <Button type="submit" id="basic-button">
                {<AddBtn />}
              </Button>
            </div>
          </div>
        </div>
        <HighlightCrousalInPopup>
          {uploadVideos?.map((vh, idx) => (
            <div
              key={idx}
              className="my-1  cursor-pointer py-1 flex  flex-col"
              onClick={() => {
                formik.setFieldValue("description", vh?.description)
                formik.setFieldValue("type", vh?.type)
                formik.setFieldValue("id", vh?.id)
                if (vh?.type === VideoType.url) {
                  formik.setFieldValue("videoURL", vh?.videoURL)
                } else if (vh?.type === VideoType.embed) {
                  formik.setFieldValue("videoEmbed", vh?.videoEmbed)
                } else {
                  formik.setFieldValue("video", vh?.video?.id)
                  setThumbnail(vh?.video?.url)
                }
              }}
            >
              {vh.type === VideoType.upload ? (
                <div className="mr-4 rounded-sm w-48 h-32 relative">
                  <video
                    autoPlay={false}
                    className=" rounded-sm cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300"
                    src={vh?.video?.url}
                  />
                </div>
              ) : (
                <div className=" w-48 h-32 relative  flex items-center justify-center transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300">
                  <iframe
                    className=" relative w-48 iframecss "
                    src={`https://www.youtube.com/embed/${vh?.videoURL?.split("=")[1]}`}
                  ></iframe>
                  <div className="border border-gray-400 w-full h-full absolute top-0 left-0 cursor-pointer z-20"></div>
                </div>
              )}
            </div>
          ))}
        </HighlightCrousalInPopup>
        {/* {newVideoIds.length > 0 && ( */}
        <div title="Upload Video" className="cursor-pointer mb-2">
          {/* <div className="font-inter text-errorRed mt-1 text-xs h-4">
            {(formik?.touched?.video && formik?.errors?.video) || ""}
          </div> */}
        </div>
        {/* )} */}
        <div className="flex justify-between items-center ">
          <GRButton onClick={onClose} label="Cancel" type="button" width={120} />
          <GRButton
            onClick={() => handleFormSubmit()}
            isSubmitting={isLoading}
            type="button"
            label="Submit"
            className="sm:!px-[35px]"
          />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowDialog}
        onClose={() => {
          onClose()
          // setNewVideoIds([])
        }}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Add videos" onClose={onClose} />}
      />
    </>
  )
}

export default AddVideoHighlightPopup
