import { UNAUTH_FAQ, UNAUTH_TERMSPOLICY } from "../../../routes/RouteConstants"
import React from "react"
import { Link } from "react-router-dom"

function Footer() {
  return (
    <>
      <div className="absolute bg-gr-primaryR2 text-gray-300 w-full py-3 md:px-12 px-7 text-[12px] font-inter">
        <div className="md:flex md:flex-row flex-col md:gap-4 gap-3 justify-between items-center">
          <p className="text-center sm:px-0 md:text-left mb-2 md:mb-0">
            Global&nbsp;Rugby&nbsp;©&nbsp;2024
          </p>
          <div className="flex sm:gap-8 gap-3  justify-center md:justify-normal">
            <Link to={UNAUTH_FAQ} target="_blank">
              <span className="hover:underline">FAQ</span>
            </Link>
            <Link to={UNAUTH_TERMSPOLICY} target="_blank">
              <span className="hover:underline">Terms&nbsp;and&nbsp;Policy</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
