import Dialog, { DialogProps } from "@mui/material/Dialog"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import * as React from "react"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface IGRDialogProps {
  dialogtitle?: JSX.Element
  dialogbody?: JSX.Element
  dialogfooter?: JSX.Element
  disablebackdropclick?: boolean
}

export const GRDialog = (props: IGRDialogProps & DialogProps) => {
  const { dialogtitle, dialogbody, dialogfooter, disablebackdropclick, onClose } = props

  const handleClose = (e: any, r: "backdropClick" | "escapeKeyDown") => {
    if (disablebackdropclick && r && r == "backdropClick") return
    onClose && onClose(e, r)
  }

  return (
    <div>
      <Dialog {...props} TransitionComponent={Transition} onClose={handleClose}>
        {dialogtitle && <div>{dialogtitle}</div>}
        {dialogbody && <div>{dialogbody}</div>}
        {dialogfooter && <div>{dialogfooter}</div>}
      </Dialog>
    </div>
  )
}
