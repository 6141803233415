import React, { PropsWithChildren, useState } from "react"
import EditBtn from "../../assets/svg/EditBtn"
import EditPersonalInfoPopup from "./EditPersonalInfoPopup"
import dayjs from "dayjs"
import { useUserHook } from "../../hooks/useUserHook"

import { IUser } from "../../modals/User"

interface IPersonalInfoCardProps {
  canEdit?: boolean
  profileDetails?: IUser
}

const PersonalInfoCard = (props: IPersonalInfoCardProps) => {
  // Props
  const { canEdit, profileDetails } = props

  // State
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)

  // Hook
  const { isPlayer, isParent, isAgent, isCoach } = useUserHook()

  // Check if any of the specified fields are empty
  const isEmpty =
    !profileDetails?.aboutUs &&
    !profileDetails?.coachPosition &&
    !profileDetails?.playerPosition_1 &&
    !profileDetails?.playerPosition_2 &&
    !profileDetails?.seasonWishToPlay &&
    !profileDetails?.height &&
    !profileDetails?.weight &&
    !profileDetails?.dob

  return (
    <>
      {isShowDialog && (
        <EditPersonalInfoPopup isShowDialog={isShowDialog} onClose={() => setIsShowDialog(false)} />
      )}
      <div className="px-4 py-[30px] rounded-[16px] bg-white">
        {/* heading and edit btn */}
        <div className=" flex justify-between items-center">
          <div className="font-inter sm:text-2xl text-xl font-semibold">Personal info</div>
          <div className="cursor-pointer" onClick={() => setIsShowDialog(!isShowDialog)}>
            {canEdit ? <EditBtn /> : ""}
          </div>
        </div>
        <div className="border border-gray-200 mt-4 mb-5"></div>
        <div className=" ">
          <div className="px-2 md:pl-4">
            {isEmpty ? (
              <div className="mx-2">
                <div className="text-gray-500 text-lg text-center">No personal information yet</div>
              </div>
            ) : (
              <>
                {/* about */}
                {profileDetails?.aboutUs ? (
                  <div className="my-4 flex flex-col">
                    <div className="font-inter font-semibold mb-2">About me</div>
                    <div className="font-inter text-gr-neutralT3 ">{profileDetails?.aboutUs}</div>
                  </div>
                ) : null}
                {/* position */}
                {isCoach ? (
                  <>
                    <div className="my-4 sm:flex gap-1">
                      <div className="font-inter font-semibold mb-2">Position - </div>
                      <div className="font-inter text-red-primaryRed">
                        {profileDetails?.coachPosition?.title}
                      </div>
                    </div>
                  </>
                ) : null}
                {!isCoach && !isAgent ? (
                  <>
                    {" "}
                    <div className="my-4 sm:flex gap-2">
                      <div className="font-inter font-semibold mb-2">Position -</div>
                      <div className="font-inter text-red-primaryRed">
                        {(profileDetails?.playerPosition_1?.title || "") +
                          (profileDetails?.playerPosition_2
                            ? ", " + profileDetails?.playerPosition_2?.title
                            : "")}
                      </div>
                    </div>
                  </>
                ) : null}

                {/* season */}
                {!isAgent && !isParent ? (
                  <>
                    <div className="my-4 sm:flex gap-1">
                      {profileDetails?.seasonWishToPlay &&
                      profileDetails?.seasonWishToPlay != "" ? (
                        <>
                          <div className="sm:flex gap-2 font-inter font-semibold mb-2">
                            Season I wish to {isPlayer ? "play" : isParent ? "play" : "coach"} -{" "}
                          </div>
                          <div className="font-inter text-gr-neutralT3">
                            {profileDetails?.seasonWishToPlay}
                          </div>
                        </>
                      ) : (
                        <div className="font-inter font-semibold mb-2">
                          Currently not looking for an opportunity
                        </div>
                      )}
                    </div>
                  </>
                ) : null}

                {/* cards */}
                {!isAgent ? (
                  <>
                    {" "}
                    <div className="sm:px-2 sm:justify-center flex flex-row sm:gap-4 justify-between flex-wrap mt-5">
                      {/* currentClub */}
                      {!isParent
                        ? profileDetails?.height && (
                            <Card label="Height">{profileDetails?.height + "cm"}</Card>
                          )
                        : null}
                      {!isParent
                        ? profileDetails?.weight && (
                            <Card label="Weight">{profileDetails?.weight + "kg"}</Card>
                          )
                        : null}
                      {profileDetails?.isChild && !canEdit ? (
                        <Card label="Age">{"Under 16"}</Card>
                      ) : (
                        <>
                          <Card label="DOB">
                            {profileDetails?.dob
                              ? dayjs(profileDetails?.dob).format("DD/MM/YYYY")?.toString()
                              : ""}
                          </Card>
                          <Card label="Age">
                            {profileDetails?.dob
                              ? dayjs().diff(profileDetails?.dob, "years")?.toString()
                              : ""}
                          </Card>
                        </>
                      )}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

interface IGRCardProps extends PropsWithChildren {
  label?: string
}

const Card: React.FC<IGRCardProps> = (props: IGRCardProps) => {
  return (
    <div className="border border-gr-primaryR4 sm:w-auto w-full lg:min-w-[13rem] sm:min-w-[10rem] max-w-[47%] h-[85px] rounded-[6px] flex flex-col gap-1 justify-center items-center sm:mb-0 mb-5">
      <div className="font-semibold font-inter">{props.label}</div>
      <div className="font-inter text-gr-neutralT3 ">{props.children}</div>
    </div>
  )
}

export default PersonalInfoCard
