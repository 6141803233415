import { useContext } from "react"
import AppContext from "../context/app-context/AppContext"
import {
  IUpdateProfilePayloadValues,
  IUpdateUserDetailsPayload,
  useLoginUserAPI,
} from "../services/useLoginUserAPI"
import { ICurrentLoginProfile } from "../modals/User"
import { isEmpty } from "lodash"
import { IUnfollowClubPayload, useClubAPI } from "../services/useClubAPI"

export const useUserHook = () => {
  // Context
  const {
    loadApp,
    setCurrentLoginProfile,
    currentLoginProfile,
    primaryUserDetails,
    loginAsProfileDetails,
    setLoginAsProfileDetails,
  } = useContext(AppContext)

  // APIs
  const {
    followUser,
    updateUserDetails,
    getCurrentLoginProfileDetails,
    followClub,
    isLoading: isUserProfileLoading,
  } = useLoginUserAPI()
  //API
  const { unfollowClub } = useClubAPI()

  // Constants
  const isPlayer = primaryUserDetails?.profileType === "Player"
  const isCoach = primaryUserDetails?.profileType === "Coach"
  const isAgent = primaryUserDetails?.profileType === "Agent"
  const isParent = primaryUserDetails?.profileType === "Parent"

  const isLoginAsPlayer = currentLoginProfile?.profileType === "Player"
  const isLoginAsCoach = currentLoginProfile?.profileType === "Coach"
  const isLoginAsAgent = currentLoginProfile?.profileType === "Agent"
  const isLoginAsParent = currentLoginProfile?.profileType === "Parent"
  const isLoginAsClub = currentLoginProfile?.profileType === "Club"
  const isLoginAsChild = currentLoginProfile?.profileType === "Child"

  const currentLoginProfileId = currentLoginProfile?.profileId

  const fetchLoginAsUserProfile = async () => {
    try {
      const r = await getCurrentLoginProfileDetails()
      setLoginAsProfileDetails(r?.data?.user)
    } catch {}
  }

  const updateUserProfile = async (values: IUpdateProfilePayloadValues) => {
    try {
      const payload: IUpdateUserDetailsPayload = {
        data: { ...values },
      }

      await updateUserDetails(payload)
      fetchLoginAsUserProfile()
    } catch {}
  }

  const handleSwitchAccount = async (switchAccountTo: ICurrentLoginProfile) => {
    try {
      localStorage.setItem("currentProfile", JSON.stringify(switchAccountTo))
      setCurrentLoginProfile(switchAccountTo)
      await loadApp()
    } catch {}
  }

  const handleFollowUser = async (userId: number) => {
    try {
      const payload = {
        data: { id: userId },
      }
      await followUser(payload)
      await fetchLoginAsUserProfile()
    } catch {}
  }

  const handleFollowClub = async (id: number) => {
    try {
      await followClub({ data: { club: id } })
      await fetchLoginAsUserProfile()
    } catch {}
  }
  const handleUnFollowClub = async (id: number) => {
    try {
      const payload: IUnfollowClubPayload = {
        data: {
          clubId: id.toString(),
        },
      }
      await unfollowClub(payload)
      await fetchLoginAsUserProfile()
    } catch {}
  }

  const isAlreadyFollowingUser = (userId: number) => {
    const found = loginAsProfileDetails?.following?.find((f) => f?.userId === userId)
    return !isEmpty(found) ? true : false
  }

  const isAlreadyFollowingClub = (clubId: number) => {
    const isFollowing = loginAsProfileDetails?.clubFollowing?.find((f) => f?.clubId === clubId)
    const isMember = loginAsProfileDetails?.clubMember?.id === clubId

    return !isEmpty(isFollowing) || isMember ? true : false
  }

  const getCurrentLoginUserPorfilePicUrl = () => {
    if (isLoginAsClub) {
      return loginAsProfileDetails?.club?.clubLogo?.url
    } else if (isLoginAsPlayer || isLoginAsAgent || isLoginAsCoach || isLoginAsParent) {
      return loginAsProfileDetails?.profilePicture?.url
    } else if (isLoginAsChild)
      return loginAsProfileDetails?.member?.find(
        (m) => m?.childMember?.id === currentLoginProfile?.profileId,
      )?.childMember?.profilePicture?.url
  }

  return {
    isLoginAsParent,
    isLoginAsClub,
    isLoginAsChild,
    isLoginAsPlayer,
    isLoginAsCoach,
    isLoginAsAgent,
    isUserProfileLoading,
    isPlayer,
    isAgent,
    isCoach,
    isParent,
    fetchLoginAsUserProfile,
    updateUserProfile,
    handleSwitchAccount,
    handleFollowUser,
    handleFollowClub,
    handleUnFollowClub,
    isAlreadyFollowingUser,
    isAlreadyFollowingClub,
    getCurrentLoginUserPorfilePicUrl,
    currentLoginProfileId,
  }
}
