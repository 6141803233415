import React, { ReactNode, useState } from "react"
import { IconButton, InputAdornment, TextField as MuiTextField } from "@mui/material"
import EyeIcon from "../../assets/svg/EyeIcon"
import ClosedEyeIcon from "../../assets/svg/ClosedEyeIcon"
import SearchIcon from "../../assets/svg/SearchIcon"

interface IGRTextfieldProps {
  label?: string
  name?: string
  type?: string
  placeholder?: string
  height?: string
  width?: string
  value?: string | number
  helperText?: string | boolean | ReactNode
  error?: boolean
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  id?: string
  border?: string
  className?: string
  borderColor?: string
  backgroundColor?: string
  InputProps?: object
  multiline?: boolean
  rows?: number
  padding?: string
  disabled?: boolean
}

const GRTextfield: React.FC<IGRTextfieldProps> = (props: IGRTextfieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  if (props.type === "password") {
    return (
      <MuiTextField
        helperText={
          <div className="text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
            {props.helperText}
          </div>
        }
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        error={props.error}
        value={props.value}
        type={showPassword ? "text" : "password"}
        onChange={props.onChange}
        style={{
          width: props.width,
        }}
        onBlur={props.onBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {!showPassword ? <ClosedEyeIcon className="mr-3" /> : <EyeIcon className="mr-3" />}
              </IconButton>
            </InputAdornment>
          ),
          autoComplete: "off",
        }}
        size="small"
        className={props.className}
        sx={{
          "& .MuiInputBase-root": {
            height: "40px",
            borderRadius: "10px",
            padding: "0px",
            fontSize: "14px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: props.error ? "#FFCAC7 !important" : "#D4D4D4",
            },
          },
          "& .MuiFormHelperText-root": {
            marginTop: "0px",
            marginLeft: "4px",
          },
        }}
      />
    )
  } else if (props.type == "searchField") {
    return (
      <MuiTextField
        helperText={
          <div className="text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
            {props.helperText}
          </div>
        }
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        error={props.error}
        value={props.value}
        type="text"
        onChange={props.onChange}
        style={{
          width: props.width,
        }}
        onBlur={props.onBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                // style={{border:"1px solid black"}}
              >
                <SearchIcon className="w-5 h-5" />
              </IconButton>
            </InputAdornment>
          ),
          autoComplete: "off",
        }}
        size="small"
        className={props.className}
        sx={{
          "& .MuiInputBase-root": {
            height: "40px",
            borderRadius: "10px",
            padding: "0px",
            outlineColor: "none",
            backgroundColor: "#FAFAFA",
            color: "#4C535D",
            borderColor: "green",
            fontSize: "14px",
            border: "1px solid #ccc",
          },
          "& .MuiInputBase-input": {
            // marginLeft: "18px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
            },
          },
        }}
      />
    )
  } else {
    return (
      <MuiTextField
        multiline={props.multiline}
        minRows={props.rows}
        name={props.name}
        helperText={
          <div className="text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
            {props.helperText}
          </div>
        }
        label={props.label}
        placeholder={props.placeholder}
        error={props.error}
        value={props.value}
        type={props.type}
        disabled={props.disabled}
        onChange={props.onChange}
        onBlur={props.onBlur}
        style={{
          width: props.width,
        }}
        size="small"
        className={props.className}
        InputProps={{ ...props.InputProps, autoComplete: "off" }}
        sx={{
          "& .MuiInputBase-root": {
            height: "40px",
            borderRadius: "10px",
            padding: props.padding === undefined ? "0px" : props.padding,
            backgroundColor: "white",
            color: "#4C535D",
            fontSize: "14px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: props.error ? "#FFCAC7 !important" : "#D4D4D4",
            },
          },
          "& .MuiFormHelperText-root": {
            marginTop: "0px",
            marginLeft: "4px",
          },
        }}
      />
    )
  }
}

export default GRTextfield
