import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes) {
  return (
    <svg
      fill="#9f0002"
      width="30px"
      height="30px"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#9f0002"
      transform="scale(-1 1)"
      {...props}
    >
      <path d="M37.328 14.559c1.406 1.054 2.86.633 2.86-1.22v-3.21h.07c5.086 0 8.296 3.516 8.296 8.531 0 3.117-.96 4.29-1.03 5.133-.024.656.21 1.055.75 1.313.726.351 1.57.023 1.968-.657.727-1.242 1.242-3.304 1.242-5.812 0-6.703-4.476-11.227-11.203-11.227h-.094V3.965c0-1.898-1.43-2.32-2.859-1.242L30.93 7.41c-1.078.797-1.078 1.711 0 2.485zM10.539 54.356h23.227c4.007 0 6.023-1.922 6.023-6.024v-23.18c0-4.101-2.016-6.023-6.023-6.023H10.539c-4.008 0-6.023 1.922-6.023 6.023v23.18c0 4.102 2.015 6.024 6.023 6.024zm.07-3.774c-1.593 0-2.32-.656-2.32-2.32v-23.04c0-1.663.727-2.32 2.32-2.32h23.086c1.617 0 2.32.657 2.32 2.32v23.04c0 1.664-.703 2.32-2.32 2.32z" />
    </svg>
  )
}

export default SvgComponent
