import { IUpdateClubDetailsPayloadValues, useClubAPI } from "../services/useClubAPI"

const useClubHook = () => {
  // APIs
  const { updateClub, isLoading: isClubProfileLoading } = useClubAPI()

  const updateClubProfile = async (clubId: number, payload: IUpdateClubDetailsPayloadValues) => {
    try {
      await updateClub(clubId, { data: payload })
    } catch {}
  }

  return { updateClubProfile, isClubProfileLoading }
}

export default useClubHook
