import { IAboutUs } from "../../services/useLoginUserAPI"
import React, { useEffect, useState } from "react"
import ClubLogoTemp1 from "../../assets/landing_1.svg"

interface IAboutUsProps {
  aboutUsDetails: IAboutUs | undefined
  landingPageTwo: any
}

const AboutUs: React.FC<IAboutUsProps> = ({ aboutUsDetails, landingPageTwo }) => {
  const [aboutUs, setAboutUs] = useState<IAboutUs>()
  useEffect(() => {
    setAboutUs(aboutUsDetails)
  }, [aboutUsDetails])

  return (
    <div className="md:flex md:justify-evenly">
      <div className="w-full lg:w-1/2">
        <div>
          <h2 className="text-4xl font-bold text-black">{aboutUs?.heading}</h2>
        </div>
        <div className="mt-8">
          <p className="font-normal text-lg font-inter">{aboutUs?.description}</p>
        </div>
        <div className="block lg:hidden w-full md:flex justify-center">
          <img className="w-auto mx-auto" src={landingPageTwo ? landingPageTwo : ClubLogoTemp1} />
        </div>
        <div className="mt-4 font-inter md:flex lg:block">
          {aboutUs?.aboutUsPoint?.map((point, index) => (
            <div key={index} className="mt-6">
              <div className="flex">
                <div className="w-[16px] h-[16px] bg-gr-primaryR2 mr-4 mt-2 flex-shrink-0"></div>
                <div>
                  <p className="text-[16px] text-base">
                    <span className="text-[16px] text-gr-primaryR2 font-semibold">
                      {point?.title}
                    </span>
                    : {point?.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ml-2 hidden lg:block w-full lg:w-1/2 lg:flex justify-center object-contain">
        <img className="w-auto" src={landingPageTwo ? landingPageTwo : ClubLogoTemp1} />
      </div>
    </div>
  )
}

export default AboutUs
