import React, { useState, useEffect } from "react"
import AddNewBtn from "../../assets/svg/AddNewBtn"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import ExpandMoreIcon from "../../assets/svg/DownArrow"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import MoreInfoIcon from "../../assets/svg/MoreInfoIcon"
import RecordHistoryDialog from "../dialogs/RecordHistoryDialog"
import { IUserExperience, IUserExperienceSeason } from "../../modals/User"
import { useUserHook } from "../../hooks/useUserHook"
import { IUser } from "../../modals/User"
import SeasonDetailsDialog from "../dialogs/SeasonDetailsDialog"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import EditBtn from "../../assets/svg/EditBtn"
import { AUTH_CLUB_PROFILE } from "../../routes/RouteConstants"
import { Link } from "react-router-dom"

interface IUserRugbyExperienceProps {
  canEdit?: boolean
  profileDetails?: IUser
}

const UserRugbyExperience = (props: IUserRugbyExperienceProps) => {
  // Props
  const { canEdit, profileDetails } = props

  // Hooks
  const { fetchLoginAsUserProfile } = useUserHook()

  // State
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const fetchUserExperience = async () => {
    try {
      await fetchLoginAsUserProfile()
    } catch {}
  }

  return (
    <>
      {isShowDialog && (
        <RecordHistoryDialog
          onSuccess={() => fetchUserExperience()}
          isShowDialog={isShowDialog}
          onClose={() => setIsShowDialog(false)}
        />
      )}
      <div className="px-4 py-[30px] rounded-[16px] bg-white ">
        {/* heading and edit btn */}
        <div className="flex justify-between items-center">
          <div className="font-inter sm:text-2xl text-xl font-semibold">Rugby experience</div>
          <div onClick={() => setIsShowDialog(!isShowDialog)} className="cursor-pointer ">
            {canEdit ? <AddNewBtn /> : ""}
          </div>
        </div>

        <div className="border border-gray-200 mt-4 mb-6"></div>

        {/* Accordian */}
        {profileDetails?.userExperiences?.map((experience, idx) => {
          return (
            <AccordionComp
              canEdit={canEdit}
              key={experience.id}
              defaultExpanded={idx === 0}
              experience={experience}
              fetchUserExperience={fetchUserExperience}
            />
          )
        })}
      </div>
    </>
  )
}

interface IGAccordionProps {
  defaultExpanded?: boolean
  experience?: IUserExperience
  canEdit?: boolean
  fetchUserExperience: () => Promise<void>
}

const AccordionComp: React.FC<IGAccordionProps> = (props: IGAccordionProps) => {
  // Props
  const { canEdit, defaultExpanded, experience, fetchUserExperience } = props

  // State
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)

  const handleOnSuccess = async () => {
    await fetchUserExperience()
    setIsShowDialog(false)
  }

  return (
    <Accordion
      className="!font-inter font-semibold text-[20px] mb-4mx-2"
      style={{
        borderRadius: "4px",
        marginTop: "20px",
      }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className=""
      >
        <div className="flex w-full sm:items-center items-start justify-between ">
          <div className="flex sm:items-center items-start gap-2">
            <div className="shrink-0">
              <Link to={`${AUTH_CLUB_PROFILE}/${experience?.club?.id}`}>
                <img
                  src={experience?.club?.clubLogo?.formats?.thumbnail?.url || ClubLogo}
                  alt="logo"
                  className="rounded-full w-[48px] h-[48px]"
                />
              </Link>
            </div>
            <div className="block sm:flex sm:items-center sm:gap-2 ">
              <div className="font-semibold font-inter hover:underline">
                <Link to={`${AUTH_CLUB_PROFILE}/${experience?.club?.id}`}>
                  {experience?.club?.name}
                </Link>
              </div>
              <div className="text-[12px] font-medium font-inter -mt-1 sm:-mt-0 sm:ml-1">
                {experience?.experienceSeasons[0]?.season?.title}
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 mr-4 "
            onClick={(e) => {
              e.stopPropagation()
              setIsShowDialog(true)
            }}
          >
            {canEdit ? (
              <div className="flex items-center gap-1">
                <div className="cursor-pointer">
                  <EditBtn width={"58px"} height={"24px"} />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className=" flex justify-center">
        <DenseTable experience={experience} context={"AccoridanComp"} />
      </AccordionDetails>

      <RecordHistoryDialog
        clubDetails={experience}
        onSuccess={() => handleOnSuccess()}
        isShowDialog={isShowDialog}
        onClose={() => setIsShowDialog(false)}
      />
    </Accordion>
  )
}

const tableHeadCellCss = {
  fontWeight: "600",
  fontSize: "12px",
  borderRight: "1px solid #D4D4D4",
  fontFamily: "inter",
  paddingX: "10px",
  whiteSpace: "nowrap",
}
const tableBodyCellCss = {
  fontFamily: "Inter",
  borderRight: "1px solid #D4D4D4",
  fontSize: "11px",
  color: "#636C79",
  paddingX: "10px",
  paddingY: "16px",
}

export function DenseTable({
  experience,
  context,
}: {
  experience: IUserExperience | undefined
  context: "AccoridanComp" | "SeasonDetails"
}) {
  const [userExperiencesSeasons, setUserExperiencesSeasons] = useState<IUserExperienceSeason[]>([])
  useEffect(() => {
    const sortedArray: IUserExperienceSeason[] | undefined = experience?.experienceSeasons.sort(
      (a, b) => {
        const titleA = a?.season?.title?.toLowerCase()
        const titleB = b?.season?.title?.toLowerCase()
        return titleB.localeCompare(titleA)
      },
    )
    sortedArray && setUserExperiencesSeasons(sortedArray)
  }, [experience])

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHeadComp context={context} />
        <TableBody>
          {userExperiencesSeasons.map((seasonData) => (
            <ExperienceBody context="AccoridanComp" seasonData={seasonData} key={seasonData?.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
interface ITableHeadCompProps {
  context: "AccoridanComp" | "SeasonDetails"
}
export const TableHeadComp = (props: ITableHeadCompProps) => {
  const { context } = props
  const { isPlayer } = useUserHook()
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#EFF1F6" }}>
        <TableCell sx={tableHeadCellCss}>Season</TableCell>
        {context === "SeasonDetails" && <TableCell sx={tableHeadCellCss}>Playing level</TableCell>}
        <TableCell sx={tableHeadCellCss}>Code</TableCell>
        <TableCell sx={tableHeadCellCss}>League / Com.</TableCell>
        <TableCell sx={tableHeadCellCss}>Age group</TableCell>
        {context === "SeasonDetails" && <TableCell sx={tableHeadCellCss}>Position 1</TableCell>}
        {context === "SeasonDetails" && <TableCell sx={tableHeadCellCss}>Position 2</TableCell>}
        {isPlayer && (
          <>
            <TableCell sx={tableHeadCellCss}>Games Played</TableCell>
            <TableCell sx={tableHeadCellCss}>Points Scored</TableCell>
          </>
        )}
        {context === "AccoridanComp" && <TableCell sx={tableHeadCellCss}>More Info</TableCell>}
      </TableRow>
    </TableHead>
  )
}

interface IExperienceBody {
  seasonData: IUserExperienceSeason
  context: "AccoridanComp" | "SeasonDetails"
}
export const ExperienceBody = (props: IExperienceBody) => {
  const { seasonData, context } = props

  const [isShowSeasonDetailsDialog, setIsShowSeasonDetailsDialog] = useState<boolean>(false)

  const { isPlayer } = useUserHook()
  return (
    <>
      <TableRow key={seasonData.id} sx={{ fontSize: "11px", whiteSpace: "nowrap" }}>
        <TableCell sx={tableBodyCellCss} component="th" scope="seasonData">
          {seasonData?.season?.title}
        </TableCell>
        {context === "SeasonDetails" && (
          <TableCell sx={tableBodyCellCss}>{seasonData?.playingLevel?.title}</TableCell>
        )}
        <TableCell sx={tableBodyCellCss}>{seasonData?.discipline?.title}</TableCell>
        <TableCell sx={tableBodyCellCss}>{seasonData?.competition?.title}</TableCell>
        <TableCell sx={tableBodyCellCss}>{seasonData?.ageGroup?.title}</TableCell>
        {context === "SeasonDetails" && (
          <TableCell sx={tableBodyCellCss}>{seasonData?.position_1?.title}</TableCell>
        )}
        {context === "SeasonDetails" && (
          <TableCell sx={tableBodyCellCss}>{seasonData?.position_2?.title}</TableCell>
        )}
        {isPlayer && (
          <>
            <TableCell sx={tableBodyCellCss}>{seasonData?.gamesPlayed}</TableCell>
            <TableCell sx={tableBodyCellCss}>{seasonData?.pointScored}</TableCell>
          </>
        )}
        {context === "AccoridanComp" && (
          <TableCell sx={tableBodyCellCss}>
            <div className="cursor-pointer w-4" onClick={() => setIsShowSeasonDetailsDialog(true)}>
              {<MoreInfoIcon />}
            </div>
          </TableCell>
        )}
      </TableRow>
      <SeasonDetailsDialog
        isShowSeasonDetailsDialog={isShowSeasonDetailsDialog}
        onClose={() => setIsShowSeasonDetailsDialog(false)}
        seasonData={seasonData}
      />
    </>
  )
}
export default UserRugbyExperience
