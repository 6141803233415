import { IOptions } from "../components/ui/GRSelect"
import { IDropDownOptionKeyValue } from "../modals/Config"

export const convertIntoDropDownOptions = (
  options: IDropDownOptionKeyValue[] | undefined,
): IOptions[] => {
  if (!options?.length) return []
  return options?.map?.((o) => ({ name: o?.title, value: String(o?.id) }))
}

export const asyncLocalStorage = {
  setItem(key: string, value: string) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value)
    })
  },
  getItem(key: string) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key)
    })
  },
  removeItem(key: string) {
    return Promise.resolve().then(function () {
      return localStorage.removeItem(key)
    })
  },
}

export const getDays = (date: string | null) => {
  try {
    if (date) {
      const today = new Date()
      const createDay = new Date(date)
      const time_difference = today.getTime() - createDay.getTime()
      const days_difference = Math.round(time_difference / (1000 * 60 * 60 * 24))
      return days_difference
    }
    return 0
  } catch (error) {
    return 0
  }
}

export const getNameInitials = (firstname: string | undefined, lastname?: string | undefined) => {
  const firstInitial = firstname ? firstname.charAt(0).toUpperCase() : ""
  const lastInitial = lastname ? lastname.charAt(0).toUpperCase() : ""
  return `${firstInitial}${lastInitial || ""}`
}

// utils.ts
export const getStringValue = (value: any) => {
  if (typeof value === "object") {
    return JSON.stringify(value)
  }
  return String(value)
}

export const getUniqueOptionsByProperty = (
  items: any[] | undefined,
  propName: string,
  type: string,
  idPropName: string,
) => {
  const props = propName?.split(".")
  const idProps = idPropName?.split(".") // Split the id property path

  const uniqueOptions =
    items
      ?.filter((item: any, index: any, self: any[]) => {
        const propValue = props.reduce(
          (obj: { [x: string]: any }, prop: string | number) => obj?.[prop],
          item,
        )
        const idPropValue = idProps.reduce(
          (obj: { [x: string]: any }, prop: string | number) => obj?.[prop],
          item,
        )

        return (
          propValue &&
          !self.slice(0, index).some((o: any) => {
            const otherPropValue = props.reduce(
              (obj: { [x: string]: any }, prop: string | number) => obj?.[prop],
              o,
            )
            return (
              getStringValue(otherPropValue) === getStringValue(propValue) &&
              getStringValue(idPropValue) === getStringValue(idPropValue)
            )
          })
        )
      })
      .map((item: any) => ({
        name: getStringValue(
          props.reduce((obj: { [x: string]: any }, prop: string | number) => obj?.[prop], item),
        ),
        id: getStringValue(
          // Extract the id property
          idProps.reduce((obj: { [x: string]: any }, prop: string | number) => obj?.[prop], item),
        ),
        value: getStringValue(
          props.reduce((obj: { [x: string]: any }, prop: string | number) => obj?.[prop], item),
        ),
        type: type,
      })) || []

  // Sort the uniqueOptions array alphabetically based on the 'name' property
  uniqueOptions.sort((a, b) => a.name.localeCompare(b.name))
  return uniqueOptions
}

export const generateRandomString = () => {
  const timestamp = new Date().getTime().toString(36)
  const randomString = Math.random().toString(36).substring(2, 8) // Using substring to remove "0." prefix
  return timestamp + randomString
}

export const topScroll = () => {
  window.scrollTo(0, 0)
}
