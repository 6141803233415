import React from "react"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types"
import { DateValidationError, DateView } from "@mui/x-date-pickers"

interface IGRDatePickerProps {
  height?: string
  width?: string
  slotProps?: object
  format?: string
  value?: any
  error?: boolean
  className?: string
  views?: readonly DateView[] | undefined
  onChange?: (
    value: string | null,
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => void
}

const GRDatePicker: React.FC<IGRDatePickerProps> = (props: IGRDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={props?.views}
        slotProps={props.slotProps}
        format={props.format}
        value={props.value}
        onChange={props.onChange}
        className={props.className}
        sx={{
          "& .MuiInputBase-root": {
            height: props.height,
            width: props.width,
            borderRadius: "8px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: props.error ? "#FFCAC7 !important" : "#D4D4D4",
            },
          },
          "& .MuiFormHelperText-root": {
            marginTop: "0px",
            marginLeft: "4px",
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default GRDatePicker
