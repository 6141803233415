import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes) {
  return (
    <svg
      fill="#9f0002"
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#9f0002"
      strokeWidth={0.00024000000000000003}
      {...props}
    >
      <path d="M10.035 18.069a7.981 7.981 0 003.938-1.035l3.332 3.332a2.164 2.164 0 003.061-3.061l-3.332-3.332a8.032 8.032 0 00-12.68-9.619 8.034 8.034 0 005.681 13.715zM5.768 5.768A6.033 6.033 0 114 10.035a5.989 5.989 0 011.768-4.267zm.267 4.267a1 1 0 011-1h2v-2a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2h-2a1 1 0 01-1-1z" />
    </svg>
  )
}

export default SvgComponent
