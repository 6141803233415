import React from "react"
import { GRDialog } from "../ui/GRDialog"
import RecordHistoryForm from "../forms/AddRugbyExperience"
import { IUserExperience } from "../../modals/User"
import DialogHeader from "./DialogHeader"

interface IRecordHistoryProps {
  isShowDialog: boolean
  onClose: () => void
  clubDetails?: IUserExperience
  onSuccess: () => void
}

const RecordHistoryDialog = (props: IRecordHistoryProps) => {
  const { isShowDialog, onClose, clubDetails, onSuccess } = props

  const body = (
    <div className="max-h-[600px] overflow-visible">
      <RecordHistoryForm onSuccess={onSuccess} clubDetails={clubDetails} onClose={onClose} />
    </div>
  )

  return (
    <div>
      <GRDialog
        open={isShowDialog}
        onClose={() => onClose()}
        dialogtitle={
          <DialogHeader
            onClose={onClose}
            title={`${clubDetails?.id ? "Edit" : "Add"} rugby experience`}
          />
        }
        dialogbody={body}
        fullWidth
        classes={{ paper: "!max-w-[880px] !w-full !rounded-[20px] !overflow-hidden !m-5" }}
      />
    </div>
  )
}

export default RecordHistoryDialog
