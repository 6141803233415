import React, { useState, useEffect, useContext } from "react"
import ThreeDotVertical from "../../assets/svg/ThreeDotVertical.svg"
import SendBtn from "../../assets/svg/SendBtn.svg"
import AppContext from "../../context/app-context/AppContext"
import useChatServices from "../../services/useChatServices"
import { ICurrentChat } from "../../screens/Message"
import { IAddMessagePayload, useChatAPI } from "../../services/useChatAPI"
import moment from "moment"
import { Menu, MenuItem } from "@mui/material"
import { getNameInitials } from "../../utils/CommonUtils"
import { IGetMessagesResponse, IMessage } from "../../modals/Chat"
import { AUTH_CLUB_PROFILE, AUTH_USER_PROFILE } from "../../routes/RouteConstants"
import { Link } from "react-router-dom"
import { useLoader } from "../../context/LoaderContext"

interface IConnectionsProps {
  currentChat: ICurrentChat | undefined
  setCurrentChat: React.Dispatch<React.SetStateAction<ICurrentChat | undefined>>
  handleSendMessageSuccess: () => void
  handleDeleteChatSuccess: () => void
}

const Chat: React.FC<IConnectionsProps> = (props: IConnectionsProps) => {
  // Props
  const { currentChat, setCurrentChat, handleSendMessageSuccess, handleDeleteChatSuccess } = props

  // Context
  const { currentLoginProfile } = useContext(AppContext)

  // Hooks
  const { sendMessage1, receiveMessage1, offReceiveMessage } = useChatServices()

  // API
  const { addMessage, getMessage, initiateChat, deleteChat } = useChatAPI()

  // States
  const [messages, setMessages] = useState<IGetMessagesResponse>()
  const [message, setMessage] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isLoading, setIsLoading } = useLoader()

  const open = Boolean(anchorEl)

  useEffect(() => {
    getConversation()
    setMessages(undefined)
    receiveMessage1(handleReceiveMessages)
    return () => {
      offReceiveMessage()
    }
  }, [currentChat?.chatId])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleReceiveMessages = (d: any) => {
    try {
      if (d?.chatId === currentChat?.chatId) {
        getConversation()
      }
    } catch {}
  }

  const handleInitiateChat = async (rId: number, pType: string) => {
    try {
      const r = await initiateChat({ data: { receiverId: rId, profileType: pType } })
      return r
    } catch {}
  }

  const getConversation = async () => {
    try {
      if (!currentChat?.chatId) return
      const conversation = await getMessage(currentChat?.chatId)
      setMessages(conversation)
    } catch {}
  }

  const handleMessageSend = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (isLoading) return
      setIsLoading(true)

      if (!currentChat || !message || !message?.trim()) {
        setMessage("")
        setIsLoading(false)
        return
      }

      let chatId: number | null = currentChat.chatId || null

      if (!chatId) {
        const r = await handleInitiateChat(currentChat.receiverId, currentChat?.profileType)
        chatId = r?.data?.id || null
        setCurrentChat((oldVal) => (oldVal ? { ...oldVal, chatId: chatId } : undefined))
      }

      if (!chatId) {
        setIsLoading(false)
        return
      }

      const msg: IAddMessagePayload = {
        data: {
          text: message,
          chatId: chatId,
        },
      }
      const request = await addMessage(msg)

      if (request?.data) {
        setMessage("")
        getConversation()
        sendMessage1({ ...request?.data, receiverId: currentChat?.receiverId, chatId: chatId })
        handleSendMessageSuccess()
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDeleteChat = async () => {
    try {
      if (!currentChat?.chatId) {
        setCurrentChat(undefined)
        return
      }
      await deleteChat(currentChat?.chatId)
      handleDeleteChatSuccess()
      setCurrentChat(undefined)
    } catch {}
  }

  function getLinkUrl(content: any) {
    if (content.profileType === "Player") {
      return `${AUTH_USER_PROFILE}/${content.receiverId}`
    } else if (content?.profileType === "Club") {
      return `${AUTH_CLUB_PROFILE}/${content.receiverId}`
    } else {
      // Default fallback URL if type is unknown
      return "/"
    }
  }
  return (
    <div className="w-full sm:px-0 px-4">
      <div className="flex justify-center w-full">
        <div className="bg-white rounded-2xl py-[20px] px-2 sm:px-[30px] w-full">
          <div className="flex items-center px-2 sm:px-0 justify-between cursor-pointer border-b pb-4">
            <div className="flex gap-4 items-center">
              {currentChat?.profilePicture?.url ? (
                <div className="relative w-max ">
                  <img
                    className="rounded-full w-[54px] h-[54px]"
                    width={50}
                    src={currentChat?.profilePicture?.url}
                    alt="logo"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center min-w-[44px] w-[44px] h-[44px] sm:min-w-[54px] sm:w-[54px] sm:h-[54px] rounded-full bg-gr-primaryR1">
                  <span className="text-base text-gr-primaryR2 font-semibold">
                    {getNameInitials(currentChat?.name)}
                  </span>
                </div>
              )}

              <div className="">
                <div className="font-inter font-semibold text-[16px] text-[#313131] mb-1 hover:underline">
                  <Link to={getLinkUrl(currentChat)}>{`${currentChat?.name}`}</Link>
                </div>
                <div className="font-inter text-gr-neutralT3 text-xs">
                  {currentChat?.profileType}
                </div>
              </div>
            </div>
            <button onClick={handleClick} className="px-3">
              <div className="font-inter text-gr-neutralT3 text-xs font-semibold">
                <img src={ThreeDotVertical} alt="more info" />
              </div>
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={() => handleDeleteChat()}>Delete Chat</MenuItem>
            </Menu>
          </div>

          <div className=" py-4  h-[75vh] md:h-[520px] flex flex-col justify-end gap-2  ">
            <div className="w-full h-full font-inter flex flex-col-reverse pl-2 !pr-0 overflow-y-scroll ">
              {!!messages?.data?.message?.length &&
                messages?.data?.message?.map((msg) => {
                  return (
                    <div key={msg?.id} className="">
                      {msg.sender?.clubId === currentLoginProfile?.profileId ||
                      msg.sender?.userId === currentLoginProfile?.profileId ? (
                        <MessageCard
                          bgColor="bg-red-darkRed"
                          messageData={msg}
                          textColor="text-white"
                          position="justify-end"
                        />
                      ) : (
                        <MessageCard
                          bgColor="bg-bgRed"
                          messageData={msg}
                          textColor="text-gr-neutralT3"
                          position="justify-start"
                        />
                      )}
                    </div>
                  )
                })}

              <div className="w-full flex justify-center my-3">
                {/* <div className="text-xs font-inter text-gr-neutralT3 flex justify-end">
                  {moment().format("h:mm")} - This currentChat was opened by{" "}
                  {currentChat?.firstname} {currentChat?.lastname}
                </div> */}
              </div>
            </div>
            <form onSubmit={handleMessageSend} className="flex gap-2 items-center w-full mt-6">
              <input
                maxLength={2500}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                className="text-gr-neutralT2 w-full rounded-full focus:outline-none border border-gr-primaryR4 h-[48px] px-6"
                placeholder={`Write a message for ${currentChat?.name}`}
              />
              <button
                type="submit"
                className="bg-red-darkRed w-[48px] min-w-[48px] h-[48px] rounded-full relative cursor-pointer flex justify-center items-center"
              >
                <img className="w-8 h-8 ml-1" src={SendBtn} alt="Send" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

interface IMessageCard {
  bgColor?: string
  position?: string
  messageData?: IMessage
  textColor?: string
}

const MessageCard: React.FC<IMessageCard> = ({
  bgColor = "bgRed",
  position = "end",
  messageData,
  textColor = "text-white",
}) => {
  return (
    <div className={" w-full flex my-3  " + position}>
      <div>
        <div
          className={
            "rounded-3xl text-sm sm:text-base px-5 break-words py-3 max-w-[300px] sm:max-w-[330px] md:max-w-[360px] " +
            bgColor +
            " " +
            textColor
          }
        >
          {messageData?.text}
        </div>
        <div className="text-[10px] mt-[3px] font-inter text-gr-neutralT3 flex justify-end">
          {moment(messageData?.createdAt).format("DD MMM YYYY h:mm a")}
        </div>
      </div>
    </div>
  )
}

export default Chat
