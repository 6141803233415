import React from "react"
import { Link } from "react-router-dom"
import MoreInfoIcon from "../../../assets/svg/MoreInfoIcon"
import LocationIcon from "../../../assets/svg/LocationIcon"
import { AUTH_OPPORTUNITY_DETAIL } from "../../../routes/RouteConstants"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"
import { AUTH_CLUB_PROFILE } from "../../../routes/RouteConstants"
import { IUser } from "../../../modals/User"
interface IClubProps {
  profileDetails?: IUser
  clubCardIdx?: number
  opportunity?: IOpportunitySummary
}

const ClubCard = (props: IClubProps) => {
  const { clubCardIdx, opportunity } = props
  return (
    <div className="mt-[18px] mx-4">
      {/* top */}
      <div className="flex gap-[10px] ">
        {/* left section - logo */}
        <Link to={`${AUTH_CLUB_PROFILE}/${opportunity?.club?.id}`}>
          <div className="w-[44px] ">
            <img
              src={opportunity?.club?.clubLogo?.url ?? ClubLogo}
              alt="profile-image"
              className="min-w-[44px] w-[44px] h-[44px] rounded-full"
            />
          </div>
        </Link>

        {/* right section - details */}
        <div className=" text-gr-neutralT2 flex flex-col w-[200px] gap-[4px]">
          {/* heading */}
          <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>
            <div className="font-inter font-semibold text-black text-sm mb-1 hover:underline">
              {opportunity?.club?.name}
            </div>
          </Link>

          {/* position */}
          <div className="text-xs ">
            <span className="font-bold">Position - </span>
            {opportunity?.playerPosition?.title
              ? opportunity?.playerPosition?.title
              : opportunity?.coachPosition?.title}
          </div>
          <div className="text-xs">
            {" "}
            <span className="font-bold">Season - </span>
            {opportunity?.season?.title}
          </div>
          <div className="text-xs">
            {" "}
            <span className="font-bold">Code - </span>
            {opportunity?.discipline?.title}
          </div>

          {/* level */}
          <div className="text-xs">
            {" "}
            <span className="font-bold">Level - </span> {opportunity?.level?.title}
          </div>

          {/* age group */}
          <div className="text-xs">
            {" "}
            <span className="font-bold">Age group - </span> {opportunity?.ageGroup?.title}
          </div>

          {/* location */}
          <div className="flex items-center gap-[4px]">
            <div>
              <LocationIcon width={12} height={16} />
            </div>
            <div className="text-xs font-bold">{opportunity?.country}</div>
          </div>
        </div>
      </div>

      {/* bottom */}
      <div className="">
        <div className="flex justify-end  items-center gap-[10px] mr-1 mt-1">
          <Link
            to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}
            className="text-primaryR2 text-[11px] font-medium hover:underline text-gr-primaryR2"
          >
            More info
          </Link>
          <Link to={`${AUTH_OPPORTUNITY_DETAIL}/${opportunity?.id}`}>
            <MoreInfoIcon />
          </Link>
        </div>
      </div>
      <div
        className={`border my-4 ${clubCardIdx == 3 ? "border-none" : "border-gr-primaryR8"}`}
      ></div>
    </div>
  )
}

export default ClubCard
