import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { getAuthToken } from "../utils/AuthUtils"
import { UNAUTH_LOGIN } from "./RouteConstants"

const AuthenticatedRoutes = () => {
  return getAuthToken() ? <Outlet /> : <Navigate to={UNAUTH_LOGIN} />
}

export default AuthenticatedRoutes
