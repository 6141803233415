import React, { useContext, useEffect, useState } from "react"
import SocialNetwork from "../components/playerProfile/SocialNetwork"
import UserExperience from "../components/playerProfile/UserRugbyExperience"
import UserHeroSection from "../components/playerProfile/UserHeroSection"
import ConnectPlayerCoach from "../components/agent/agentProfile/ConnectPlayerCoach"

import { useParams, useSearchParams } from "react-router-dom"
import AppContext from "../context/app-context/AppContext"
import { IUploadFilesResponse } from "../modals/Config"
import { useUserHook } from "../hooks/useUserHook"
import { useUsersAPI } from "../services/useUsersAPI"
import { IUser } from "../modals/User"
import { ILoginUser } from "../modals/User"
import UserFollowings from "../components/playerProfile/UserFollowings"
import PersonalInfoCard from "../components/playerProfile/PersonalInfoCard"
import ProfessionalInfoCard from "../components/playerProfile/ProfessionalInfoCard"
import UserFollowers from "../components/playerProfile/UserFollowers"
import UserClubs from "../components/playerProfile/UserClubs"
import InviteAndReferral from "../components/playerProfile/InviteAndReferral"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import { useScreenWidth } from "../context/ScreenWidthContext"

interface IAgentProfileProps {
  canEdit?: boolean
}

const AgentProfile = (props: IAgentProfileProps) => {
  // Props
  const { canEdit } = props

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // State
  const [profileData, setProfileData] = useState<IUser | ILoginUser>()

  // APIs
  const { getUserDetailsById } = useUsersAPI()

  // Hooks
  const { updateUserProfile, fetchLoginAsUserProfile, handleFollowUser } = useUserHook()
  const { userId } = useParams()

  // State
  const [searchParams] = useSearchParams()
  const selectedTab = searchParams.get("tab")

  useEffect(() => {
    if (userId) {
      fetchUserDetailsById()
    } else if (canEdit) {
      setProfileData(loginAsProfileDetails)
    }
  }, [userId, loginAsProfileDetails])

  const fetchUserDetailsById = async () => {
    try {
      if (!userId) return
      const r = await getUserDetailsById(userId)
      setProfileData(r?.data?.user)
    } catch {}
  }

  const handleProfileUploadSuccess = async (r: IUploadFilesResponse) => {
    try {
      await updateUserProfile({ profilePicture: String(r?.id) })
    } catch {}
  }

  const handleProfileDeleteSuccess = async () => {
    try {
      await fetchLoginAsUserProfile()
    } catch {}
  }

  const handleCoverUploadSuccess = async (r: IUploadFilesResponse) => {
    try {
      await updateUserProfile({ coverPhoto: String(r?.id) })
    } catch {}
  }

  const onFollowUser = async () => {
    if (!userId) return

    await handleFollowUser(parseInt(userId))
  }
  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 769 && screenWidth < 1250

  return (
    <div className="">
      <div className="flex flex-col">
        <div className="">
          <HeaderSearchFeild />
        </div>
        <div className=" ">
          <UserHeroSection
            userId={userId}
            onProfileUploadSuccess={handleProfileUploadSuccess}
            onProfileDeleteSuccess={handleProfileDeleteSuccess}
            onCoverUploadSuccess={handleCoverUploadSuccess}
            profileDetails={profileData}
            canEdit={canEdit}
            onClickFollow={onFollowUser}
          />
        </div>

        <div className={`flex px-4 md:px-6 md:mt-8 justify-between`}>
          <nav></nav>{" "}
          <div
            className={`flex flex-col gap-[20px] ${
              isLargeScreen
                ? "flex-[25%] max-w-[25%]"
                : isMediumScreen
                ? "flex-[32%] max-w-[32%]"
                : "hidden"
            }`}
          >
            <UserClubs profileDetails={profileData} canEdit={canEdit} />
            <ConnectPlayerCoach />
            <InviteAndReferral />
          </div>
          {/* left */}
          <div
            className={`flex flex-col gap-[20px] sm:mb-[106px] mb-12 sm:mt-0 mt-4  ${
              isLargeScreen
                ? "flex-[73%] max-w-[73%]"
                : isMediumScreen
                ? "flex-[65%] max-w-[65%]"
                : "flex-[100%] max-w-[100%]"
            }`}
          >
            {selectedTab === "followers" ? (
              <UserFollowers profileDetails={profileData} />
            ) : selectedTab === "following" ? (
              <UserFollowings profileDetails={profileData} />
            ) : (
              <>
                <PersonalInfoCard profileDetails={profileData} canEdit={canEdit} />
                <ProfessionalInfoCard profileDetails={profileData} canEdit={canEdit} />
                <UserExperience profileDetails={profileData} canEdit={canEdit} />
                <SocialNetwork profileDetails={profileData} canEdit={canEdit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentProfile
