import { useUsersAPI } from "../services/useUsersAPI"
import ApprovedIcon from "../assets/svg/ApprovedIcon"
import NotApproved from "../assets/svg/NotApproved"
import { UNAUTH_LOGIN } from "../routes/RouteConstants"
import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

export default function EmailConfirmation() {
  const { updateUserConfirmation } = useUsersAPI()
  const [searchParams] = useSearchParams()
  const confirmationsCode = searchParams.get("confirmation")
  const navigate = useNavigate()
  const [message, setMessage] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [isApproved, setIsApproved] = useState<boolean>(true)

  useEffect(() => {
    emailConfirmed()
  }, [])
  const emailConfirmed = async () => {
    try {
      if (!confirmationsCode) return
      const updatedConfirmationStatus = await updateUserConfirmation({
        data: {
          confirmation: confirmationsCode,
        },
      })
      console.log(updatedConfirmationStatus)
      if (updatedConfirmationStatus?.data) {
        setEmail(updatedConfirmationStatus?.data?.email)
        setMessage("Email Confirmation Successful")
        setIsApproved(true)
        redirectTimer
      } else {
        setMessage(
          "This token is invalid. Please click on the verification link provided in the email sent to your registered email id.",
        )
        setIsApproved(false)
        redirectTimer
      }
    } catch (error) {}
  }
  const redirectTimer = setTimeout(() => {
    email ? navigate(`${UNAUTH_LOGIN}?email=${email}`) : navigate(UNAUTH_LOGIN)
  }, 3000)

  return (
    <div id="email-confirmation-page" className="h-screen flex justify-center items-center">
      {message && (
        <div className=" shadow-lg rounded-lg w-[500px] h-[250px] flex justify-center items-center bg-white text-lg text-gray-600">
          <div className="px-7 flex flex-col items-center gap-3">
            {isApproved ? <ApprovedIcon /> : <NotApproved width={40} />}
            <h1>{message}</h1>
            <div className="text-sm text-gray-600 mt-1">Redirecting to login page in 3sec...</div>
          </div>
        </div>
      )}
    </div>
  )
}
