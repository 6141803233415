import React from "react"
import { Outlet } from "react-router"
import Footer from "../club/clubprofile/Footer"
import Header from "../Header/Header"

const AppLayout = () => {
  return (
    <section className="flex flex-col w-full h-full min-h-screen">
      <header>
        <Header />
      </header>
      <main className="flex-1">
        <Outlet />
      </main>
      <footer className="mt-4">
        <Footer />
      </footer>
    </section>
  )
}

export default AppLayout
