import ClubOpportunityCardMob from "../../../components/dashboard/ClubOpportunities/ClubOpportunityCardMob"
import React, { useContext, useEffect, useState } from "react"
import AppContext from "../../../context/app-context/AppContext"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import { useOpportunityAPI } from "../../../services/useOpportunityAPI"
import { Link } from "react-router-dom"
import { AUTH_OPPORTUNITY } from "../../../routes/RouteConstants"
import GRButton from "../../../components/ui/GRButton"
import CreateOpportunityDialog from "../../../components/dialogs/CreateOpportunityDialog"
import { useConfigAPI } from "../../../services/useConfigAPI"
import { IDropDownOptionsResponse } from "../../../modals/Config"

const ClubOpportunityDashboard = () => {
  //State
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[]>([])
  const [dropDownOptions, setDropDownOptions] = useState<IDropDownOptionsResponse>()
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)

  // APIs
  const { getDropDownOptions } = useConfigAPI()
  const { getOpportunityByClubId } = useOpportunityAPI()

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  useEffect(() => {
    getOpportunity()
    fetchConfig()
  }, [])

  const getOpportunity = async () => {
    try {
      if (!loginAsProfileDetails?.club?.id) return
      const opportunityList = await getOpportunityByClubId(loginAsProfileDetails?.club?.id) // I will use dynamic club id after pass in URL
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }

  const fetchConfig = async () => {
    try {
      const cr = await getDropDownOptions()
      setDropDownOptions(cr)
    } catch {}
  }

  return (
    <div className="flex flex-col  bg-white shadow-lg rounded-2xl  pb-3">
      <div className="pb-4">
        <div className="flex justify-between items-center mx-4 py-4 border-b border-gr-primaryN8 ">
          <div>
            <h2 className="text-lg font-semibold font-inter">Opportunities</h2>
          </div>
          <div className="flex items-center">
            <div>
              <GRButton
                onClick={() => setIsShowDialog(!isShowDialog)}
                label="Add New"
                height={33}
                fontSize="12px"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        {opportunities &&
          opportunities
            ?.slice(0, 3)
            ?.map((opportunity, index) => (
              <ClubOpportunityCardMob opportunity={opportunity} key={index} />
            ))}
        {opportunities.length < 1 && (
          <div className="text-gray-500 text-base text-center mt-5">No opportunities yet</div>
        )}
      </div>
      <div className="font-inter text-gr-neutralT4 text-xs leading-4 underline pb-1 text-center px-4 hover:text-gr-primaryR2">
        <Link to={AUTH_OPPORTUNITY}>View all</Link>
      </div>
      <CreateOpportunityDialog
        dropDownOptions={dropDownOptions}
        isShowDialog={isShowDialog}
        onClose={() => setIsShowDialog(false)}
        onGetOpportunity={() => getOpportunity()}
      />
    </div>
  )
}

export default ClubOpportunityDashboard
