import React, { useEffect, useState } from "react"
import EditBtn from "../../assets/svg/EditBtn"

import { IUser } from "../../modals/User"
import EditHighlightPopup, { VideoType } from "./EditHighlightPopup"
import AddNewBtn from "../../assets/svg/AddNewBtn"
import AddVideoHighlightPopup from "./AddVideoHighlightPopup"
import { HighlightCrousal } from "./HighlightCrousal"

interface IPersonalInfoCardProps {
  canEdit?: boolean
  profileDetails?: IUser
}

const Highlight = (props: IPersonalInfoCardProps) => {
  // Props
  const { canEdit, profileDetails } = props

  // State
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [isShowAddVideoDialog, setIsShowAddVideoDialog] = useState<boolean>(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const [currentUrl, setCurrentUrl] = useState<string>()
  const [postContent, setPostContent] = useState<string>("")

  const isShowShowMoreBtn = postContent && postContent?.length > 250
  useEffect(() => {
    const vh = profileDetails?.videoHighlights[0]
    setPostContent(vh?.description || "")
    if (vh?.type === VideoType?.url) {
      const videoIdMatch = vh?.videoURL?.match(/[?&]v=([^&]+)/)
      const videoId = videoIdMatch ? videoIdMatch[1] : ""
      setCurrentUrl(`https://www.youtube.com/embed/${videoId}`)
    } else if (vh?.type === VideoType.embed) {
      //how to show embed video
      const srcMatch = vh?.videoEmbed?.match(/src="([^"]+)"/)
      if (srcMatch) setCurrentUrl(srcMatch[1])
    } else {
      setCurrentUrl(vh?.video?.url)
    }
  }, [profileDetails])

  const isEmpty = profileDetails?.videoHighlights?.length === 0
  return (
    <>
      {isShowDialog && (
        <EditHighlightPopup isShowDialog={isShowDialog} onClose={() => setIsShowDialog(false)} />
      )}
      {isShowAddVideoDialog && (
        <AddVideoHighlightPopup
          isShowDialog={isShowAddVideoDialog}
          onClose={() => setIsShowAddVideoDialog(false)}
        />
      )}
      <div className="px-4 py-[30px] rounded-[16px] bg-white ">
        {/* heading and edit btn */}
        <div className=" flex justify-between items-center">
          <div className="font-inter sm:text-2xl text-xl font-semibold">Highlights</div>
          <div className="flex gap-2">
            <div
              className="cursor-pointer"
              onClick={() => setIsShowAddVideoDialog(!isShowAddVideoDialog)}
            >
              {canEdit ? <AddNewBtn /> : ""}
            </div>
            {profileDetails?.videoHighlights && profileDetails?.videoHighlights?.length > 0 && (
              <div className="cursor-pointer" onClick={() => setIsShowDialog(!isShowDialog)}>
                {canEdit ? <EditBtn /> : ""}
              </div>
            )}
          </div>
        </div>
        <div className="border border-gray-200 my-4"></div>
        {isEmpty ? (
          <div className="mx-2">
            <div className="text-gray-500 text-lg text-center">No video highlights yet</div>
          </div>
        ) : (
          <>
            {currentUrl && (
              <div className="mx-1">
                {/* video iframe */}
                <div className="w-full flex justify-center">
                  <iframe
                    width="100%"
                    className="sm:h-[210px] md:h-[400px] rounded-[12px]"
                    src={currentUrl}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="px-4 mb-3 pl-0 lg:pl-0 lg:pr-0 mt-6">
                  <p className="font-inter text-gr-neutralT2 text-xs leading-6 font-normal lg:text-xs">
                    {isShowShowMoreBtn
                      ? isShowMore
                        ? postContent
                        : `${postContent?.substring(0, 250)}`
                      : postContent}
                  </p>
                  {isShowShowMoreBtn && (
                    <div className="flex justify-end">
                      <button
                        onClick={() => setIsShowMore(!isShowMore)}
                        className="font-inter text-red-primaryRed text-xs leading-6 font-normal text-end lg:text-xs cursor-pointer select-none"
                      >
                        {isShowMore ? "...see less" : "...see more"}
                      </button>
                    </div>
                  )}
                </div>
                {/* crousal */}
                <HighlightCrousal
                  handlePlay={(url) => setCurrentUrl(url)}
                  setPostContent={(description: string) => setPostContent(description)}
                  profileDetails={profileDetails}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Highlight
