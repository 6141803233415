import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={42}
      height={52}
      viewBox="0 0 180 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M162 0c9.941 0 18 8.059 18 18v126c0 9.941-8.059 18-18 18H18c-9.941 0-18-8.059-18-18V18C0 8.059 8.059 0 18 0h144zm0 18H18v90.905l44.953-44.954c4.394-4.393 11.517-4.393 15.91 0l36.593 36.593 11.137-11.136c4.393-4.394 11.516-4.394 15.909 0L162 108.905V18zm-40.5 18c7.456 0 13.5 6.044 13.5 13.5S128.956 63 121.5 63 108 56.956 108 49.5 114.044 36 121.5 36z"
        fill="#09244B"
      />
    </svg>
  )
}

export default SvgComponent
