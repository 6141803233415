import React, { useState, useEffect } from "react"
import LocationIcon from "../../../assets/svg/LocationIcon"
import MoreInfoIcon from "../../../assets/svg/MoreInfoIcon"
import AllApplicantsList from "./AllAplicantsList"
import Opportunities from "../../dashboard/Opportunities/Opportunities"
import { Link } from "react-router-dom"
import { AUTH_OPPORTUNITY, AUTH_DASHBOARD } from "../../../routes/RouteConstants"
import LeftArrow from "../../../assets/svg/LeftArrow"
import EditOpportunityDialog from "../../../components/dialogs/EditOpportunityDialog"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import { useOpportunityAPI } from "../../../services/useOpportunityAPI"
import { useParams } from "react-router-dom"
import { getDays } from "../../../utils/CommonUtils"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"
import HeaderSearchFeild from "../../../components/Header/HeaderSearchFeild"
import { useScreenWidth } from "../../../context/ScreenWidthContext"

function ClubOpportunityDetail() {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [opportunity, setOpportunity] = useState<IOpportunitySummary>()
  // APIs
  const { getOpportunityById } = useOpportunityAPI()
  // Hooks
  const { id } = useParams()
  useEffect(() => {
    if (id) getOpportunity()
  }, [id])

  const getOpportunity = async () => {
    try {
      if (!id) return
      const opportunityList = await getOpportunityById(id) // I will use dynamic club id after pass in URL
      setOpportunity(opportunityList?.opportunity)
    } catch (error) {}
  }

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250

  return (
    <div>
      <div className="px-4 lg:hidden mb-4 lg:mt-0 mt-4">
        <div className="flex justify-start items-center font-inter">
          <Link to={AUTH_OPPORTUNITY} className="flex items-center justify-start">
            <LeftArrow className="mr-3 w-3 min-w-3" /> Back
          </Link>
        </div>
      </div>
      <HeaderSearchFeild />
      <div className="text-[13px] font-inter text-gr-primaryN2 py-4 px-4 md:px-6 font-medium hidden lg:block ">
        <Link to={AUTH_DASHBOARD} className="hover:text-gr-primaryR2">
          Home
        </Link>
        {" > "}{" "}
        <Link to={AUTH_OPPORTUNITY} className="hover:text-gr-primaryR2">
          Opportunities
        </Link>
        {" > "}
        <span className="text-gr-primaryR2">Opportunity Details</span>
      </div>
      <div className={`flex px-4 md:px-6 mb-8 justify-between`}>
        <div className="w-full flex-[25%] max-w-[25%] lg:block hidden">
          <div className="">
            <Opportunities />
          </div>
        </div>
        <div className={`${isLargeScreen ? "flex-[73%] max-w-[73%]" : "flex-[100%] max-w-[100%]"}`}>
          <div className={`bg-white rounded-2xl`}>
            <div className="flex sm:flex-row flex-col p-[30px] sm:gap-[30px] gap-5">
              <img
                src={opportunity?.club?.clubLogo?.url ?? ClubLogo}
                className="min-w-[100px] w-[100px] h-[100px] lg:min-w-[180px] lg:w-[180px] rounded-lg lg:h-[180px] self-start "
              />
              {/* desktop view */}
              <div className="flex flex-col">
                <div className="flex flex-row justify-between mb-2">
                  <span className="font-inter font-semibold uppercase sm:text-xl text-lg text-black">
                    {opportunity?.playerPosition?.title
                      ? opportunity?.playerPosition?.title
                      : opportunity?.coachPosition?.title}
                  </span>
                  <p className="flex text-[#009733] md:mr-0">
                    {opportunity?.status ? (
                      <div className="text-green-green1 font-inter font-[500]">Active</div>
                    ) : (
                      <div className="text-red-darkRed font-inter font-[500]">Inactive</div>
                    )}
                  </p>
                </div>

                <div className="text-gr-neutralT2 font-inter">
                  <span className="sm:text-base text-sm block mb-2">{opportunity?.club?.name}</span>
                  <div className="flex flex-row items-center mb-2">
                    <LocationIcon fontSize={16} height={16} />
                    <span className="text-sm font-semibold ml-1">{opportunity?.country}</span>
                  </div>

                  <div>
                    <div className="block flex-row sm:mb-2 mb-4 text-[14px] font-medium">
                      <span className="mb-2 sm:mr-8 sm:inline-block block mr-0">
                        <span className="">Season: </span>
                        {opportunity?.season?.title}
                      </span>
                      <span className="sm:mr-8 mb-2 lg:mb-0 sm:inline-block block mr-0">
                        <span className="">Age group: </span>
                        {opportunity?.ageGroup?.title}
                      </span>
                      <span className="sm:mr-8 mb-2 lg:mb-0 sm:inline-block block mr-0">
                        <span className="">Level: </span>
                        {opportunity?.level?.title}
                      </span>
                      <span className="mb-2 lg:mb-0 sm:inline-block block mr-0">
                        <span>Code: </span>
                        {opportunity?.discipline?.title}
                      </span>
                    </div>
                    <div className="flex justify-end items-center gap-[35px] text-xs">
                      <span>
                        {opportunity?.applicants?.length}{" "}
                        {opportunity?.applicants?.length && opportunity?.applicants?.length > 1
                          ? "applicants"
                          : "applicant"}{" "}
                      </span>
                      <span>
                        {opportunity?.createdAt && getDays(opportunity?.createdAt)}{" "}
                        {opportunity?.createdAt && getDays(opportunity?.createdAt) > 1
                          ? "days ago"
                          : "day ago"}
                      </span>
                      <div onClick={() => setIsShowDialog(!isShowDialog)}>
                        <span className="text-primaryR2 text-[11px] font-medium hover:underline text-gr-primaryR2 flex gap-2 items-center cursor-pointer">
                          Edit opportunity
                          <MoreInfoIcon />
                        </span>
                      </div>
                    </div>
                    <div className="block">
                      {opportunity?.description && (
                        <div className="block">
                          <div className="text-gr-primaryN2 font-semibold text-[15px] mt-4">
                            Opportunity description:
                          </div>
                          <p className="mt-2 font-sm">{opportunity?.description}</p>
                        </div>
                      )}

                      {opportunity?.otherCriteria && (
                        <div>
                          <div className="text-gr-primaryN2 font-semibold text-[15px] mt-6">
                            Other criteria:
                          </div>
                          <p className="mt-2 font-sm">{opportunity?.otherCriteria}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-2xl mt-4">
            <AllApplicantsList opportunity={opportunity} />
          </div>
        </div>
      </div>

      <EditOpportunityDialog
        isShowDialog={isShowDialog}
        onClose={() => setIsShowDialog(false)}
        opportunity={opportunity}
        updateOpportunity={() => getOpportunity()}
      />
    </div>
  )
}

export default ClubOpportunityDetail
