import GRButton from "../ui/GRButton"
import { GRDialog } from "../ui/GRDialog"
import React from "react"

interface IDeleteConnectionDialogProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  isAgent: boolean
}

const DeleteConnectionDialog = (props: IDeleteConnectionDialogProps) => {
  const { isOpen, onClose, onDelete, isAgent } = props
  const dialogTitle = isAgent ? "Delete Agent?" : "Delete Connection?"
  const dialogText = isAgent
    ? "Are you sure you want to delete this agent?"
    : "Are you sure you want to delete this connection?"
  const body = (
    <div className="flex justify-center border-b border-b-gr-primaryN8 font-inter p-8  ">
      <div className="flex flex-col items-center ">
        <div className="font-semibold sm:text-[25px] text-[17px]">{dialogTitle}</div>
        <div className="text-[#54595E] mb-10 sm:text-[19px] text-[13px]">{dialogText}</div>
        <div className="flex sm:gap-12 gap-6" onClick={() => onClose()}>
          <GRButton
            label="Cancel"
            type="button"
            size="sm"
            className="!px-[30px] !bg-white !text-gr-primaryR2"
            border="1px solid"
          />
          <GRButton onClick={onDelete} label="Delete" size="sm" className=" !px-[30px]  " />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      <GRDialog
        open={isOpen}
        onClose={() => onClose()}
        dialogbody={body}
        fullWidth
        classes={{ paper: "!max-w-[580px] !w-full !rounded-[10px]" }}
      />
    </div>
  )
}

export default DeleteConnectionDialog
