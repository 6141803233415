import React, { useContext, useEffect, useState } from "react"
import Chat from "../components/message/Chat"
import Connections from "../components/message/Connections"
import AppContext from "../context/app-context/AppContext"
import { useChatAPI } from "../services/useChatAPI"
import useChatServices from "../services/useChatServices"
import LeftArrow from "../assets/svg/LeftArrow"
import { IChatSummary } from "../modals/Chat"
import { IUploadFilesResponse } from "../modals/Config"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useScreenWidth } from "../context/ScreenWidthContext"

export interface ICurrentChat {
  receiverId: number
  name: string
  chatId: number | null
  profileType: string
  profilePicture?: IUploadFilesResponse
}

const Message = () => {
  // Context
  const { currentLoginProfile, setUnreadMessageCount } = useContext(AppContext)

  // Hooks
  const { receiveMessage1, offReceiveMessage } = useChatServices()
  const [searchParams] = useSearchParams()
  const receiverId = searchParams.get("chatwith")
  const navigate = useNavigate()
  // APIs
  const { getUserChat, getUnreadMessageCount } = useChatAPI()

  const [userActiveChats, setUserActiveChats] = useState<IChatSummary[]>([])
  const [currentChat, setCurrentChat] = useState<ICurrentChat>()

  const getAllChatDetails = async () => {
    await getUserActiveChats()
    await receiveMessage1(handleReceiveMessages)
    await fetchUnreadMessageCount()
  }
  useEffect(() => {
    getAllChatDetails()
    return () => {
      offReceiveMessage()
    }
  }, [currentChat?.chatId])

  const fetchUnreadMessageCount = async () => {
    try {
      const unreadCountResponse = await getUnreadMessageCount()
      setUnreadMessageCount(unreadCountResponse?.count || 0)
    } catch {}
  }

  const handleReceiveMessages = () => {
    try {
      getUserActiveChats()
    } catch {}
  }

  const getUserActiveChats = async () => {
    try {
      if (!currentLoginProfile?.profileId) return
      const chats = await getUserChat(currentLoginProfile?.profileId)
      setUserActiveChats(chats?.data)
    } catch (error) {}
  }

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 650 && screenWidth < 1250

  const backConnectionTab = async () => {
    try {
      await setCurrentChat(undefined)
      if (receiverId) navigate(-1)
    } catch (error) {}
  }

  return (
    <div className="pb-5 sm:mt-5 mt-4">
      <div className=" flex justify-center">
        <div className="sm:flex sm:flex-col sm:justify-center gap-5 w-full">
          {/* mobile view  */}
          <div className="sm:hidden">
            {/* <ZeroConnections /> */}
            {currentChat ? (
              <>
                <div className="px-4 lg:hidden mb-4">
                  <div
                    className="flex justify-start items-center font-inter"
                    onClick={() => backConnectionTab()}
                  >
                    <LeftArrow className="mr-3 w-3" /> Back
                  </div>
                </div>
                <Chat
                  key={currentChat?.chatId || currentChat?.receiverId}
                  currentChat={currentChat}
                  setCurrentChat={setCurrentChat}
                  handleSendMessageSuccess={() => getUserActiveChats()}
                  handleDeleteChatSuccess={() => getUserActiveChats()}
                />
              </>
            ) : (
              <Connections
                chats={userActiveChats}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
              />
            )}
          </div>
          {/* web view */}
          <div className="hidden sm:flex px-4 md:px-6 mt-4 justify-between">
            <>
              <Connections
                classes={`flex flex-col gap-[20px] ${
                  isLargeScreen
                    ? "flex-[30%] max-w-[30%]"
                    : isMediumScreen
                    ? "flex-[45%] max-w-[45%]"
                    : "flex-[100%] max-w-[100%]"
                }`}
                chats={userActiveChats}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
              />
              <div
                className={`flex flex-col gap-[20px] ${
                  isLargeScreen
                    ? "flex-[68%] max-w-[68%]"
                    : isMediumScreen
                    ? "flex-[53%] max-w-[53%]"
                    : "flex-[100%] max-w-[100%]"
                }`}
              >
                {currentChat ? (
                  <Chat
                    key={currentChat?.receiverId}
                    currentChat={currentChat}
                    setCurrentChat={setCurrentChat}
                    handleSendMessageSuccess={() => getUserActiveChats()}
                    handleDeleteChatSuccess={() => getUserActiveChats()}
                  />
                ) : (
                  <div className="bg-white flex h-full w-full rounded-2xl">
                    <div className="flex justify-center items-center h-full w-full">
                      <p className="text-gray-500 text-lg items-center">
                        No chat is currently open
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Message
