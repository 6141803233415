import React, { SVGProps } from "react"

function ApprovedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={61}
      viewBox="0 0 60 61"
      fill="none"
      {...props}
    >
      <path
        d="M30.001 60.503C13.493 60.503.02 47.04 0 30.522-.02 13.996 13.515.467 30.035.5c16.528.033 29.97 13.495 29.968 30.018C59.997 47.022 46.51 60.503 30 60.503zM26.7 34.686c-1.665-2.185-3.261-4.266-4.838-6.363-.397-.527-.853-.924-1.506-1.073-1.188-.273-2.808.324-3.438 1.278-.673 1.017-.6 2.472.23 3.556 2.4 3.123 4.81 6.237 7.232 9.343.867 1.112 2.277 1.4 3.533.763a4.328 4.328 0 001.495-1.25c2.574-3.373 5.159-6.735 7.74-10.103 1.867-2.436 3.751-4.863 5.601-7.312 1.326-1.757.96-3.79-.805-4.66-1.33-.656-3.021-.242-3.965.99-2.233 2.926-4.457 5.857-6.681 8.786-1.518 1.99-3.033 3.984-4.598 6.045z"
        fill="#02BB7D"
      />
    </svg>
  )
}

export default ApprovedIcon
