import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import EditBtnBlack from "../../assets/svg/EditBtnBlack"
import ProfileBannerImgPopup from "./ProfileBannerImgPopup"
import BasicInfoPopup from "./BasicInfoPopup"
import ProfileImgPopup from "./ProfileImgPopup"
import GRButton from "../ui/GRButton"
import { useSearchParams } from "react-router-dom"
import { useUserHook } from "../../hooks/useUserHook"
import { IUploadFilesResponse } from "../../modals/Config"
import { IUser } from "../../modals/User"
import { getNameInitials } from "../../utils/CommonUtils"
import { IUnfollowUserPayload, useUsersAPI } from "../../services/useUsersAPI"
import { useLoginUserAPI } from "../../services/useLoginUserAPI"
import AppContext from "../../context/app-context/AppContext"
import ProfileImg from "../../components/playerProfile/ProfileImg"

interface IHeroSectionProps {
  userId?: string
  canEdit?: boolean
  profileDetails?: IUser
  onClickFollow?: () => Promise<void>
  onProfileUploadSuccess?: (uploadResponse: IUploadFilesResponse) => void
  onProfileDeleteSuccess?: () => void
  onCoverUploadSuccess?: (uploadResponse: IUploadFilesResponse) => void
  setUpdateUser?: Dispatch<SetStateAction<any>>
}

const UserHeroSection = (props: IHeroSectionProps) => {
  // Props
  const {
    userId,
    canEdit,
    profileDetails,
    onProfileUploadSuccess,
    onProfileDeleteSuccess,
    onCoverUploadSuccess,
    setUpdateUser,
  } = props

  // State
  const [isShowBannerImgDialog, setIsShowBannerImgDialog] = useState<boolean>(false)
  const [isShowProfileImgDialog, setIsShowProfileImgDialog] = useState<boolean>(false)
  const [isShowBasicInfoDialog, setIsShowBasicInfoDialog] = useState<boolean>(false)
  const [isAlreadyFollowing, setIsAlreadyFollowing] = useState<boolean>(false)
  const [isShowProfileImg, setIsShowProfileImg] = useState<boolean>(false)
  const [followersCount, setFollowersCount] = useState<number>(0)
  const [, setSearchParams] = useSearchParams({ tab: "" })

  // Hook
  const { isPlayer, isCoach, fetchLoginAsUserProfile } = useUserHook()

  //API
  const { unfollowUser } = useUsersAPI()
  // Context
  const { loginAsProfileDetails, currentLoginProfile } = useContext(AppContext)

  const { followUser } = useLoginUserAPI()

  const { isAlreadyFollowingUser } = useUserHook()

  useEffect(() => {
    const isAlreadyFollowing = isAlreadyFollowingUser(Number(userId))
    const isFollowingByLoginUser = profileDetails?.followers?.find(
      (f) => f.userId === loginAsProfileDetails?.id,
    )
    setIsAlreadyFollowing(isAlreadyFollowing || isFollowingByLoginUser ? true : false)
    fetchLoginAsUserProfile()
  }, [])
  useEffect(() => {
    setFollowersCount(profileDetails?.followers?.length ? profileDetails?.followers?.length : 0)
  }, [profileDetails])

  const handleFollowUser = async (userId: number) => {
    try {
      const payload = {
        data: { id: userId },
      }
      await followUser(payload)
      setIsAlreadyFollowing(!isAlreadyFollowing)
      setFollowersCount(followersCount + 1)
      setUpdateUser && setUpdateUser({ id: userId, count: followersCount + 1 })
    } catch {}
  }

  const handleUnfollow = async () => {
    if (!userId) return
    try {
      const payload: IUnfollowUserPayload = {
        data: {
          id: userId,
        },
      }
      await unfollowUser(payload)
      setIsAlreadyFollowing(!isAlreadyFollowing)
      setFollowersCount(followersCount - 1)
      setUpdateUser && setUpdateUser({ id: userId, count: followersCount - 1 })
    } catch {}
  }
  // const screenWidth = window.innerWidth
  // const isMediumScreen = screenWidth >= 800 && screenWidth < 1250

  return (
    <>
      {isShowBasicInfoDialog && (
        <BasicInfoPopup
          isShowBasicInfoDialog={isShowBasicInfoDialog}
          onClose={() => setIsShowBasicInfoDialog(false)}
        />
      )}
      {isShowProfileImgDialog && (
        <ProfileImgPopup
          isShowProfileImgDialog={isShowProfileImgDialog}
          onClose={() => setIsShowProfileImgDialog(false)}
          onProfileUploadSuccess={onProfileUploadSuccess}
          onProfileDeleteSuccess={onProfileDeleteSuccess}
          profilePicture={profileDetails?.profilePicture}
        />
      )}
      <ProfileBannerImgPopup
        isShowBannerImgDialog={isShowBannerImgDialog}
        onClose={() => setIsShowBannerImgDialog(false)}
        onProfileUploadSuccess={onCoverUploadSuccess}
        onProfileDeleteSuccess={onProfileDeleteSuccess}
        profilePicture={profileDetails?.coverPhoto}
      />

      <div className=" sm:mb-4">
        <div className="rounded-2xl pb-3 sm:px-0 px-4">
          <div className="relative sm:bg-transparent bg-white rounded-t-2xl">
            {profileDetails?.coverPhoto?.url ? (
              <img
                className="w-full lg:h-[277px] sm:h-[250px] h-[150px] object-cover rounded-t-2xl sm:rounded-none"
                src={profileDetails?.coverPhoto?.url}
                alt="bannerImg"
              />
            ) : (
              <div className="w-full lg:h-[277px] sm:h-[250px] h-[150px] rounded-t-2xl sm:rounded-none bg-gray-100"></div>
            )}
            <div className="cursor-pointer sm:block absolute top-[20px] sm:right-6 right-4">
              {canEdit && (
                <EditBtnBlack onClick={() => setIsShowBannerImgDialog(!isShowBannerImgDialog)} />
              )}
            </div>

            <div className="ml-[268px] mt-[10px] sm:hidden">
              <div className="sm:block cursor-pointer md:ml-0">
                {canEdit && <EditBtnBlack onClick={() => setIsShowBasicInfoDialog(true)} />}
              </div>
            </div>

            <div className="absolute md:bottom-[-44%] sm:bottom-[-33%] -bottom-3 left-[36%] md:left-6 sm:left-4 mt-10 ">
              <div className="cursor-pointer rounded-full bg-white ">
                <div className="rounded-ful sm:p-1 p-[2px] w-max relative">
                  {profileDetails?.profilePicture ? (
                    <div className="rounded-full bg-white sm:p-1 p-[2px] w-max relative">
                      <img
                        src={profileDetails?.profilePicture?.url}
                        className="w-[88px] h-[88px] sm:w-[150px] sm:h-[150px] md:w-[227px] md:h-[227px] rounded-full "
                        alt="profileImg"
                        onClick={() => setIsShowProfileImg(true)}
                      />
                      {canEdit && (
                        <button
                          onClick={() => (canEdit ? setIsShowProfileImgDialog(true) : undefined)}
                          className="absolute sm:right-[10%] -right-[10px] bottom-[10%] flex justify-center items-center w-[35px] h-[35px] bg-white rounded-full shadow-lg"
                        >
                          <svg
                            enableBackground="new 0 0 32 32"
                            height="20px"
                            id="Layer_1"
                            version="1.1"
                            viewBox="0 0 32 32"
                            width="20px"
                          >
                            <g id="camera">
                              <path
                                clipRule="evenodd"
                                d="M16,10.001c-4.419,0-8,3.581-8,8c0,4.418,3.581,8,8,8   c4.418,0,8-3.582,8-8C24,13.583,20.418,10.001,16,10.001z M20.555,21.906c-2.156,2.516-5.943,2.807-8.459,0.65   c-2.517-2.156-2.807-5.944-0.65-8.459c2.155-2.517,5.943-2.807,8.459-0.65C22.42,15.602,22.711,19.391,20.555,21.906z"
                                fill="#333333"
                                fillRule="evenodd"
                              />
                              <path
                                clipRule="evenodd"
                                d="M16,14.001c-2.209,0-3.999,1.791-4,3.999v0.002   c0,0.275,0.224,0.5,0.5,0.5s0.5-0.225,0.5-0.5V18c0.001-1.656,1.343-2.999,3-2.999c0.276,0,0.5-0.224,0.5-0.5   S16.276,14.001,16,14.001z"
                                fill="#333333"
                                fillRule="evenodd"
                              />
                              <path
                                clipRule="evenodd"
                                d="M29.492,9.042l-4.334-0.723l-1.373-3.434   C23.326,3.74,22.232,3,21,3H11C9.768,3,8.674,3.74,8.214,4.886L6.842,8.319L2.509,9.042C1.055,9.283,0,10.527,0,12v15   c0,1.654,1.346,3,3,3h26c1.654,0,3-1.346,3-3V12C32,10.527,30.945,9.283,29.492,9.042z M30,27c0,0.553-0.447,1-1,1H3   c-0.553,0-1-0.447-1-1V12c0-0.489,0.354-0.906,0.836-0.986l5.444-0.907l1.791-4.478C10.224,5.25,10.591,5,11,5h10   c0.408,0,0.775,0.249,0.928,0.629l1.791,4.478l5.445,0.907C29.646,11.094,30,11.511,30,12V27z"
                                fill="#333333"
                                fillRule="evenodd"
                              />
                            </g>
                          </svg>
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center w-[88px] h-[88px] sm:w-[150px] sm:h-[150px] md:w-[227px] md:h-[227px] rounded-full bg-gr-primaryR1">
                      <span className=" text-4xl sm:text-6xl md:text-8xl text-white font-semibold">
                        {getNameInitials(profileDetails?.firstname, profileDetails?.lastname)}
                      </span>
                      {canEdit && (
                        <button
                          onClick={() => (canEdit ? setIsShowProfileImgDialog(true) : undefined)}
                          className="absolute sm:right-[10%] -right-[10px] bottom-[10%] flex justify-center items-center w-[35px] h-[35px] bg-white rounded-full shadow-lg"
                        >
                          <svg
                            enableBackground="new 0 0 32 32"
                            height="20px"
                            id="Layer_1"
                            version="1.1"
                            viewBox="0 0 32 32"
                            width="20px"
                          >
                            <g id="camera">
                              <path
                                clipRule="evenodd"
                                d="M16,10.001c-4.419,0-8,3.581-8,8c0,4.418,3.581,8,8,8   c4.418,0,8-3.582,8-8C24,13.583,20.418,10.001,16,10.001z M20.555,21.906c-2.156,2.516-5.943,2.807-8.459,0.65   c-2.517-2.156-2.807-5.944-0.65-8.459c2.155-2.517,5.943-2.807,8.459-0.65C22.42,15.602,22.711,19.391,20.555,21.906z"
                                fill="#333333"
                                fillRule="evenodd"
                              />
                              <path
                                clipRule="evenodd"
                                d="M16,14.001c-2.209,0-3.999,1.791-4,3.999v0.002   c0,0.275,0.224,0.5,0.5,0.5s0.5-0.225,0.5-0.5V18c0.001-1.656,1.343-2.999,3-2.999c0.276,0,0.5-0.224,0.5-0.5   S16.276,14.001,16,14.001z"
                                fill="#333333"
                                fillRule="evenodd"
                              />
                              <path
                                clipRule="evenodd"
                                d="M29.492,9.042l-4.334-0.723l-1.373-3.434   C23.326,3.74,22.232,3,21,3H11C9.768,3,8.674,3.74,8.214,4.886L6.842,8.319L2.509,9.042C1.055,9.283,0,10.527,0,12v15   c0,1.654,1.346,3,3,3h26c1.654,0,3-1.346,3-3V12C32,10.527,30.945,9.283,29.492,9.042z M30,27c0,0.553-0.447,1-1,1H3   c-0.553,0-1-0.447-1-1V12c0-0.489,0.354-0.906,0.836-0.986l5.444-0.907l1.791-4.478C10.224,5.25,10.591,5,11,5h10   c0.408,0,0.775,0.249,0.928,0.629l1.791,4.478l5.445,0.907C29.646,11.094,30,11.511,30,12V27z"
                                fill="#333333"
                                fillRule="evenodd"
                              />
                            </g>
                          </svg>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {isShowProfileImg && (
                <ProfileImg
                  isShowProfileImg={isShowProfileImg}
                  onClose={() => setIsShowProfileImg(false)}
                  profilePicture={profileDetails?.profilePicture}
                />
              )}
            </div>
          </div>

          {/* info */}
          <div className="pt-[11px] sm:pt-0 sm:ml-[200px] md:ml-[272px] sm:mt-[22px] md:flex justify-center sm:justify-between sm:bg-transparent bg-white rounded-[0px_0px_16px_16px]">
            <div className={`flex flex-col items-center sm:block sm:justify-normal `}>
              <div className="text-[20px] mb-2 sm:mt-0 mt-2 md:text-[32px] leading-[26px] font-semibold font-inter">
                {` ${profileDetails?.firstname} ${profileDetails?.middlename || ""} ${
                  profileDetails?.lastname
                } `}
              </div>
              <div className="flex gap-2 sm:gap-4">
                <div className="text-[12px] font-inter text-gr-neutralT3">
                  {profileDetails?.profileType}
                  {/* {isPlayer ? "Player" : isCoach ? "Coach" : isParent ? "Parent" : "Agent"} */}
                </div>
                {profileDetails?.playerPosition_1?.title ||
                profileDetails?.playerPosition_2?.title ? (
                  <>
                    {isPlayer ? (
                      <>
                        <div className="border-r border-gr-primaryR4"></div>
                        <div className="text-[12px] font-inter text-gr-neutralT3">
                          {profileDetails?.playerPosition_1?.title}
                          {profileDetails?.playerPosition_2?.title &&
                            `, ${profileDetails?.playerPosition_2?.title}`}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}

                {profileDetails?.coachPosition?.title ? (
                  <>
                    {isCoach ? (
                      <>
                        <div className="border-r border-gr-primaryR4"></div>
                        <div className="text-[12px] font-inter text-gr-neutralT3">
                          {profileDetails?.coachPosition?.title}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                {profileDetails?.country ? (
                  <>
                    <div className="border-r border-gr-primaryR4"></div>
                    <div className="text-[12px] font-inter text-gr-neutralT3">
                      {`${profileDetails?.country ? `${profileDetails?.country}` : ""}`}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="sm:hidden mt-4">
                {canEdit ? (
                  <></>
                ) : isAlreadyFollowing ? (
                  <div className="cursor-text py-1.5">
                    <GRButton
                      onClick={handleUnfollow}
                      className="!font-normal"
                      label="Unfollow"
                      borderRadius={100}
                      width="100px"
                      height={32}
                    />
                  </div>
                ) : (
                  <GRButton
                    onClick={() => handleFollowUser(Number(userId))}
                    className="!font-normal"
                    label="Follow"
                    borderRadius={100}
                    width="100px"
                    height={32}
                  />
                )}
              </div>
              <div className="sm:hidden flex gap-[38px] text-sm sm:h-10 sm:mt-4 sm:mb-0 mt-2 mb-4 text-gr-neutralT3">
                <div
                  onClick={() =>
                    setSearchParams((oldParams) => {
                      oldParams.set("tab", "followers")
                      return oldParams
                    })
                  }
                  className=""
                >
                  {followersCount}
                  {followersCount > 1 ? " Followers" : " Follower"}
                </div>
                <div className="border-r border-gr-primaryR4 "></div>
                <div
                  onClick={() =>
                    setSearchParams((oldParams) => {
                      oldParams.set("tab", "following")
                      return oldParams
                    })
                  }
                  className=""
                >
                  {(profileDetails?.clubFollowing?.length || 0) +
                    (profileDetails?.following?.length || 0) +
                    (profileDetails?.clubMember ? 1 : 0)}{" "}
                  Following
                </div>
              </div>
            </div>
            <div className="hidden sm:flex flex-col justify-start gap-3 items-end sm:mr-[15px] md:mr-[24px]">
              {currentLoginProfile?.profileId !== Number(userId) &&
                currentLoginProfile?.profileType !== "Club" && (
                  <div className={"hidden sm:block cursor-pointer  "}>
                    {canEdit ? (
                      <EditBtnBlack onClick={() => setIsShowBasicInfoDialog(true)} />
                    ) : isAlreadyFollowing ? (
                      <div className="cursor-text py-1.5">
                        <GRButton
                          onClick={handleUnfollow}
                          className="!font-normal"
                          label="Unfollow"
                          borderRadius={100}
                          width="100px"
                          height={32}
                        />
                      </div>
                    ) : (
                      <GRButton
                        onClick={() => handleFollowUser(Number(userId))}
                        className="!font-normal"
                        label="Follow"
                        borderRadius={100}
                        width="100px"
                        height={32}
                      />
                    )}
                  </div>
                )}
              <div className="flex items-center gap-6 text-gr-neutralT3 mt-auto ">
                <div
                  onClick={() =>
                    setSearchParams((oldParams) => {
                      oldParams.set("tab", "followers")
                      return oldParams
                    })
                  }
                >
                  <button className="text-base hover:text-gr-primaryR2">
                    <span className="font-bold">{followersCount}</span>{" "}
                    {followersCount > 1 ? "Followers" : "Follower"}
                  </button>
                </div>
                <div
                  onClick={() =>
                    setSearchParams((oldParams) => {
                      oldParams.set("tab", "following")
                      return oldParams
                    })
                  }
                >
                  <button className="text-base hover:text-gr-primaryR2">
                    <span className="font-bold">
                      {" "}
                      {(profileDetails?.clubFollowing?.length || 0) +
                        (profileDetails?.following?.length || 0) +
                        (profileDetails?.clubMember ? 1 : 0)}{" "}
                    </span>{" "}
                    Following
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserHeroSection
