import React, { useState, useContext, useEffect, useMemo } from "react"
import FilterOptions from "../dashboard/Opportunities/FilterOption"
import { useOpportunityAPI } from "../../services/useOpportunityAPI"
import { IOpportunitySummary } from "../../modals/Opportunity"
import AppContext from "../../context/app-context/AppContext"
import { getUniqueOptionsByProperty } from "../../utils/CommonUtils"
const FilterPopup = () => {
  const [allChecked] = useState<boolean>(true)
  const [showLocations, setShowLocations] = useState<boolean>(false)
  const handleToggleLocations = () => {
    setShowLocations(!showLocations)
  }
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[]>()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)
  // APIs
  const { getOpportunityByProfileType } = useOpportunityAPI()
  useEffect(() => {
    getOpportunity()
  }, [])

  const getOpportunity = async () => {
    try {
      const opportunityList = await getOpportunityByProfileType(
        `profileType=${loginAsProfileDetails?.profileType}`,
      )
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }
  // ...
  const uniqueCountryOptions = useMemo(
    () => getUniqueOptionsByProperty(opportunities, "country", "country", ""),
    [opportunities],
  )
  return (
    <div className="w-[315px] px-8 py-4 bg-white rounded-[16px] border border-[#efefef]">
      <div className=" mb-[24px]">
        <div className="mb-[11px] flex justify-between items-center  pb-[13px] border-b border-gr-primaryN8">
          <div className="text-blue-dark2 ml-[5px] font-bold text-lg font-microsoftTai">
            Locations
          </div>
          <div
            className="text-gr-primaryN1 text-[10px] font-inter underline text-xs"
            onClick={handleToggleLocations}
          >
            {showLocations ? "View less" : "View all"}
          </div>
        </div>
        <FilterOptions
          options={uniqueCountryOptions}
          isShowAll={showLocations}
          allChecked={allChecked}
        />
        <div></div>
      </div>
    </div>
  )
}

export default FilterPopup
