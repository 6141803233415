import { AxiosRequestConfig } from "axios"
import {
  IAddExperienceResponse,
  ICompleteProfileResponse,
  ISearchUserEmailByStringResponse,
  IUpdateFollowClubResponse,
  IUpdateLoginUserDetailsResponse,
  IUserAdminRequestResponse,
  IUserExperienceResponse,
  IGetUserProfileResponse,
  IUserProfileUpdateResponse,
  IActiveFamilyMemberResponse,
  IInvitedPeopleResponse,
  ISearchUserByNameResponse,
} from "../modals/User"
import useBaseAPI from "./useBaseAPI"
import { IUploadFilesResponse } from "../modals/Config"

export const useLoginUserAPI = () => {
  const { GET, POST, PUT, isLoading, DELETE } = useBaseAPI()

  const getUserDetails = (config?: AxiosRequestConfig): Promise<IGetUserProfileResponse> => {
    return GET("/get-all-userdetails", undefined, config)
  }

  const completeUserProfile = (
    payload: ICompleteUserProfilePayload,
  ): Promise<ICompleteProfileResponse> => {
    return POST("/user-profile-details", payload)
  }

  const updateProfile = (
    userId: number,
    payload: IUpdateProfilePayload,
  ): Promise<IUserProfileUpdateResponse> => {
    return PUT(`/users/${userId}`, payload)
  }

  const addUserExperience = (
    payload: IAddUserExperiencePayload,
  ): Promise<IAddExperienceResponse> => {
    return POST(`/create-user-experience`, payload)
  }

  const getUserExperience = (userId: number): Promise<IUserExperienceResponse> => {
    return GET(`/get-user-experience-byuser/${userId}`)
  }

  const getAdminRequestStatus = (clubId: string): Promise<IUserAdminRequestResponse> => {
    return GET(`/get-club-admin-request-byuser/${clubId}`)
  }

  const followClub = (payload: IUpdateFollowClubPayload): Promise<IUpdateFollowClubResponse> => {
    return PUT(`/follow-club/`, payload)
  }

  const updateUserDetails = (
    payload: IUpdateUserDetailsPayload,
  ): Promise<IUpdateLoginUserDetailsResponse> => {
    return PUT(`/userdetails`, payload)
  }

  const updateUserExperience = (
    payload: IAddUserExperiencePayload,
  ): Promise<IAddExperienceResponse> => {
    return PUT(`/user-experience`, payload)
  }

  const addUnder16Member = (payload: IUnder16AddMemberPayload): Promise<any> => {
    return POST(`/add-child-member`, payload)
  }

  const inviteAbove16Member = (payload: IInviteAbove16FormPayload): Promise<any> => {
    return POST(`/request-add-user`, payload)
  }

  const getActiveFamilyMembers = (): Promise<IActiveFamilyMemberResponse> => {
    return GET(`/get-active-member-by-id`)
  }

  const searchUserEmailByString = (
    searchStr: string,
  ): Promise<ISearchUserEmailByStringResponse> => {
    return GET(`/get-user-email/${searchStr}`)
  }

  const connectFamilyMember = (
    payload: IConnectFamilyMemberPayload,
  ): Promise<ISearchUserEmailByStringResponse> => {
    return POST(`/add-user-member/`, payload)
  }

  const followUser = (payload: IFollowUserPayload): Promise<any> => {
    return PUT(`/update-follower`, payload, undefined, true)
  }

  const deleteUserExperienceById = (experienceId: number): Promise<any> => {
    return DELETE(`/delete-user-experience/${experienceId}`)
  }

  const createReferral = (payload: IInvitePeoplePayload): Promise<any> => {
    return POST(`/create-referral`, payload)
  }

  const updateReferral = (payload: IUpdateReferralPayload): Promise<any> => {
    return PUT(`/update-referral`, payload)
  }

  const getInvitedPeoples = (): Promise<IInvitedPeopleResponse> => {
    return GET(`/get-referral-by-user`)
  }

  const deleteFamilyMembersById = (id: number): Promise<any> => {
    return DELETE(`/delete-member/${id}`)
  }

  const getCurrentLoginProfileDetails = (
    config?: AxiosRequestConfig,
  ): Promise<IGetUserProfileResponse> => {
    return GET(`/get-profile`, undefined, config)
  }

  const createVideoHighlight = (payload: IVideoHighlightPayload): Promise<any> => {
    return POST(`/create-video-highlight/`, payload)
  }

  const updateVideoHighlight = (payload: IUpdateVideoHighlightPayload): Promise<any> => {
    return PUT(`/update-video-highlight/`, payload)
  }

  const deleteVideoHighlightById = (id: number): Promise<any> => {
    return DELETE(`/delete-video-highlight/${id}`)
  }

  const addNewUserConnection = (payload: INewUserConnectionPayload): Promise<any> => {
    return POST(`/add-new-user-connection/`, payload)
  }

  const addUserConnection = (payload: IUserConnectionPayload): Promise<any> => {
    return POST(`/create-agent-connection/`, payload)
  }

  const getUsersByAgent = (): Promise<IConnectedUsersResponse> => {
    return GET(`/get-user-by-agent/`)
  }

  const deletePlayerCoachConnection = (id: number): Promise<any> => {
    return DELETE(`/delete-agent-connection/${id}`)
  }
  const getLandingPageDetails = (): Promise<ILandingPage> => {
    return GET(`/get-landing-page`)
  }

  const searchUsersByName = (searchStr: string): Promise<ISearchUserByNameResponse> => {
    return GET(`/get-user-by-name/${searchStr}`)
  }
  const searchPlayerOrCoachByName = (searchStr: string): Promise<ISearchUserByNameResponse> => {
    return GET(`/get-player-or-coach-by-name/${searchStr}`)
  }

  return {
    isLoading,
    completeUserProfile,
    updateProfile,
    getUserDetails,
    addUserExperience,
    getUserExperience,
    getAdminRequestStatus,
    followClub,
    updateUserDetails,
    updateUserExperience,
    addUnder16Member,
    inviteAbove16Member,
    getActiveFamilyMembers,
    searchUserEmailByString,
    followUser,
    connectFamilyMember,
    deleteUserExperienceById,
    createReferral,
    updateReferral,
    getInvitedPeoples,
    deleteFamilyMembersById,
    getCurrentLoginProfileDetails,
    createVideoHighlight,
    updateVideoHighlight,
    deleteVideoHighlightById,
    addNewUserConnection,
    addUserConnection,
    getUsersByAgent,
    deletePlayerCoachConnection,
    getLandingPageDetails,
    searchUsersByName,
    searchPlayerOrCoachByName,
  }
}

export interface IConnectedUsersResponse {
  data: IConnectedUserSummary[]
}
export interface IConnectedUserSummary {
  id: number
  status: boolean
  createdAt: string
  updatedAt: string
  publishedAt: string
  user: {
    id: number
    username: string
    email: string
    provider: string
    password: string
    resetPasswordToken: any
    confirmationToken: string
    confirmed: boolean
    blocked: boolean
    createdAt: string
    updatedAt: string
    firstname: string
    lastname: string
    profileType: string
    middlename: any
    dob: any
    gender: any
    country: any
    contactNumber: any
    address: any
    nationality: any
    strengths: any
    hearingSource: any
    referralUserId: any
    city: any
    postCode: any
    isOnboardingComplete: boolean
    height: any
    weight: any
    aboutUs: any
    internationalEligibilities: any
    instagramLink: any
    facebookLink: any
    twitterLink: any
    linkedinLink: any
    tiktokLink: string
    snapchatLink: string
    state: any
    geo: any
    seasonWishToPlay: any
    profilePicture: IUploadFilesResponse
  }
}

export interface INewUserConnectionPayload {
  data: {
    newUserId: number
  }
}
export interface IUserConnectionPayload {
  data: {
    userId: number
  }
}

export interface IVideoHighlightPayload {
  data: IVideoHighlight[]
}
export interface IVideoHighlight {
  description: string
  video?: number | null
  videoURL?: string
  videoEmbed?: string
  type: string
}

export interface IUpdateVideoHighlightPayload {
  data: {
    id: string
    description: string
    video?: number | null
    videoURL?: string
    videoEmbed?: string
    type: string
  }
}

export interface ICompleteUserProfilePayload {
  data: {
    dob: string
    gender: string
    strength: string
    hearingSource: string
    referralUserId: number
    city: string
    contactnumber: string
    opportunitySeason: number
    address: string
    postcode: string
    nationality: number
    internationalEligibilities: number[]
    level: number
    highestLevelPlayed: number
    userId: number
    country: number
  }
}

export interface IUpdateProfilePayload {
  firstname?: string
  lastname?: string
  middlename?: string
  username?: string
  dob?: string
  gender?: string
  strengths?: string
  hearingSource?: string
  region?: string
  referralUserId?: number
  city?: string
  state?: string
  contactNumber?: string
  seasonWishToPlay?: string | null
  address?: string
  postCode?: string
  nationality?: string
  level?: number
  highestLevelPlayed?: number
  userId?: number
  country?: string
  internationalEligibilities?: string
  isOnboardingComplete?: boolean
  disciplines?: string[]
  highestQualification?: number
  qualification?: number[]
  geo?: {
    lat: number
    lng: number
  }
}

interface IAddUserExperienceData {
  id?: number
  currentClub: boolean
  club: string
  user?: number // Todo: needs to fix
  startDate: string
  endDate?: string | null
  city: string
  state: string
  country: string
  experienceSeason: IUserExperienceSeason[]
}

export interface IUserExperienceSeason {
  id?: number
  competition: string
  gamesPlayed: string
  ageGroup: string
  pointScored: string
  playingLevel: string
  discipline: string
  position_1: string | null
  position_2: string | null
  coachPosition: string | null
  season: string
}

export interface IAddUserExperiencePayload {
  data: IAddUserExperienceData
}

export interface IUpdateFollowClubPayload {
  data: {
    club: number
  }
}

export interface IUpdateProfilePayloadValues {
  firstname?: string
  lastname?: string
  middlename?: string
  profileType?: string
  gender?: string
  internationalEligibilities?: string | string[]
  country?: string
  contactNumber?: string
  address?: string
  nationality?: string
  strengths?: string
  hearingSource?: string
  referralUserId?: number
  city?: string
  postCode?: string
  isOnboardingComplete?: boolean
  level?: number | undefined
  highestLevelPlayed?: number | null
  profilePicture?: string
  coverPhoto?: string
  opportunitySeason?: number
  aboutUs?: string
  height?: string
  weight?: string
  dob?: string | null
  instagramLink?: string | null
  facebookLink?: string | null
  twitterLink?: string | null
  linkedinLink?: string | null
  position?: string
  seasonIWishToPlay?: string
  playerPosition_1?: string | null
  playerPosition_2?: string | null
  coachPosition?: string | null
  qualification?: number[]
  videoHighlights?: number[]
}

export interface IUpdateUserDetailsPayload {
  data: IUpdateProfilePayloadValues
}

export interface IUnder16AddMemberPayload {
  data: {
    relation: string
    childId: number
    userExperience?: IAddUserExperienceData
  }
}

export interface IInviteAbove16FormPayload {
  data: {
    memberEmail: string
    firstName: string
    relation: string
    lastName: string
  }
}

export interface IConnectFamilyMemberPayload {
  data: {
    memberEmail: string
    relation: string
    member: string
  }
}

export interface IFollowUserPayload {
  data: {
    id: number
  }
}

export interface IInvitePeoplePayload {
  data: {
    email: string
  }
}

export interface IUpdateReferralPayload {
  data: {
    referredUser: string
    referralCode: string
    email: string
  }
}

export interface ILandingPage {
  data: ILandingPageData
}
export interface ILandingPageData {
  id: number
  aboutUs: IAboutUs
  image_1: IUploadFilesResponse
  image_2: IUploadFilesResponse
  memberBenefits: IMemberBenefits
  partners: IPartner
  topNavBar: ITopNavBar[]
  banner: IBanner
}

export interface IAboutUs {
  id: number
  description: string
  heading: string
  aboutUsPoint: IAboutUsPoint[]
}
export interface IAboutUsPoint {
  id: number
  title: string
  description: string
}

export interface IMemberBenefits {
  heading: string
  members: IMember[]
}
export interface IMember {
  id: number
  heading: string
  description: string
  icon: IUploadFilesResponse
}
export interface IPartner {
  heading: number
  subHeading: string
  companies: ICompany[]
}
export interface ICompany {
  id: number
  name: string
  logo: IUploadFilesResponse
}
export interface IBanner {
  heading: string
  description: string
  bgImage: IUploadFilesResponse
}
export interface ITopNavBar {
  id: number
  title: string
  url: string
}
