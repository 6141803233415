import React, { useState, useEffect } from "react"
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import ExpandMoreIcon from "../assets/svg/DownArrow"
import useFaqApi, { Attributes } from "../services/useFaqApi"

const FAQ = () => {
  const { getFaq } = useFaqApi()

  const [fAQData, setFAQData] = useState<Attributes>()

  useEffect(() => {
    getfAQ()
  }, [])
  const getfAQ = async () => {
    const faq = await getFaq()
    setFAQData(faq?.data?.attributes)
  }
  return (
    <div>
      <div className="text-center mt-8">
        <b className="font-inter text-4xl">{fAQData?.heading}</b>
      </div>
      <div className="py-5 sm:py-10 px-10 sm:px-24">
        {fAQData?.fAQs?.map((item, index) => (
          <AccordionItem
            key={index}
            index={index}
            question={item?.question}
            answer={item?.answer}
          />
        ))}
      </div>
    </div>
  )
}

interface AccordionItemProps {
  index: number
  question: string
  answer: string
}

const AccordionItem: React.FC<AccordionItemProps> = ({ index, question, answer }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleAccordion = () => {
    setExpanded(!expanded)
  }

  return (
    <div className="mb-4">
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleAccordion}>
          <Typography variant="subtitle1">
            <b>
              {index + 1}. {question}
            </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default FAQ
