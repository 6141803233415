import React, { useEffect, useRef, useState } from "react"
import { GRSwitch } from "../ui/GRSwitch"
import ThreeDotVertical from "../../assets/svg/ThreeDotVertical.svg"

const NotificationSetting = () => {
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])
  return (
    <div className="w-full sm:w-[260px] md:w-[330px] relative">
      {/* tab and web view */}
      <div className="hidden  w-[360px] sm:w-[260px] md:w-[330px] h-[95px] sm:h-[140px] rounded-[10px] sm:rounded-[20px] bg-white sm:flex sm:flex-col sm:items-center">
        <div className="font-inter py-2 sm:pt-4 text-sm">Notification setting</div>
        <div className="border-b border-gr-primaryR4 sm:my-2 w-full"></div>
        <div className="flex justify-between items-center gap-6 md:gap-16">
          <div className="font-medium font-inter my-2 text-sm">Mark all as read </div>
          <div>
            <GRSwitch
              sx={{
                "& .MuiSwitch-thumb": {
                  boxSizing: "border-box",
                  width: 14,
                  height: 14,
                  marginTop: "2px",
                  marginLeft: "2px",
                },
                "& .MuiSwitch-track": {
                  borderRadius: 26 / 2,
                  backgroundColor: "#F8F5F5",
                },
              }}
            />
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className=" sm:hidden font-inter font-semibold bg-white h-[60px] w-full py-4 px-7 flex justify-between items-center">
        <div>Notification settings</div>
        <div ref={ref} onClick={() => setOpen(true)} className=" ">
          <img src={ThreeDotVertical} alt="more info" />
        </div>
      </div>
      {open && (
        <div className="absolute top-[45px] right-[15px] pt-1 z-20 shadow-2xl w-[235px] h-[116px] bg-white rounded-[8px] flex flex-col justify-center px-[12px] ">
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            className="flex items-center md:gap-16 hover:bg-bgRed"
          >
            <div className="font-medium font-inter my-2 text-[10px] ml-[6px]">
              Turn On/Off notifications
            </div>
            <div className="  ml-[27px]">
              <GRSwitch
                sx={{
                  "& .MuiSwitch-thumb": {
                    boxSizing: "border-box",
                    width: 14,
                    height: 14,
                  },
                  "& .MuiSwitch-track": {
                    borderRadius: 26 / 2,
                    backgroundColor: "#F8F5F5",
                    height: "14px",
                    marginTop: "3px",
                    width: "37px",
                  },
                }}
              />
            </div>
          </div>
          <div className="border-b border-gr-primaryR4 mt-2"></div>
          <div className="font-medium font-inter my-2 text-[10px] pl-[6px] hover:bg-bgRed py-2">
            Mark all as read
          </div>
        </div>
      )}
    </div>
  )
}

export default NotificationSetting
