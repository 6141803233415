import React, { useContext, useEffect, useState } from "react"
import { IUser } from "../../modals/User"
import { AUTH_CLUB_PROFILE } from "../../routes/RouteConstants"
import { Link, useParams } from "react-router-dom"
import { useClubAPI } from "../../services/useClubAPI"
import AppContext from "../../context/app-context/AppContext"
import { successToast } from "../../utils/ToastUtils"
import { useUserHook } from "../../hooks/useUserHook"
import ClubLogo from "../../assets/svg/ClubLogo.svg"

interface IMyClubs {
  canEdit?: boolean
  profileDetails?: IUser
}

const UserClubs = (props: IMyClubs) => {
  // Props
  const { canEdit, profileDetails } = props

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  const { userId } = useParams()

  //API
  const { sendClaimAdminRequest } = useClubAPI()

  // Hooks
  const { isAgent } = useUserHook()

  const [showAll, setShowAll] = useState<boolean>(false)
  const [userExperiences, setUserExperiences] = useState(profileDetails?.userExperiences || [])

  const handleSendClaimRequest = async (clubId: number) => {
    try {
      if (!clubId || !loginAsProfileDetails) {
        return
      }
      await sendClaimAdminRequest({
        data: { club: String(clubId), user: String(loginAsProfileDetails?.id) },
      })
      successToast("Request Sent")
    } catch {}
  }
  useEffect(() => {
    setUserExperiences(profileDetails?.userExperiences || [])
  }, [profileDetails])

  useEffect(() => {
    const makeCurrentClubAtTop = () => {
      const approvedClub = profileDetails?.userExperiences?.find(
        (ue) => ue?.club?.adminStatus === "Approved",
      )
      if (approvedClub) {
        const approvedClubIdx = profileDetails?.userExperiences?.indexOf(approvedClub)
        if (approvedClubIdx !== -1 && approvedClubIdx) {
          profileDetails?.userExperiences?.splice(approvedClubIdx, 1)
          profileDetails?.userExperiences?.unshift(approvedClub)
        }
      }
    }
    makeCurrentClubAtTop()
  }, [profileDetails])
  return (
    <>
      {profileDetails?.userExperiences?.length !== 0 ? (
        <div className={"bg-white rounded-2xl pt-5  "}>
          <div className="px-4 flex justify-between items-center mb-4">
            <p className="font-inter font-semibold text-lg leading-6 text-gr-primaryN1">
              {canEdit ? "My" : "Member"} clubs ({profileDetails?.userExperiences?.length || 0})
            </p>
          </div>
          <div
            className={`px-4 mb-4 min-h-[50px] max-h-[300px] ${
              showAll ? "overflow-y-auto " : "overflow-y-hidden"
            }`}
          >
            {userExperiences?.map((ue, indx) => (
              <div
                key={indx}
                className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5 hover:bg-bgRed
                ${indx === 0 && "bg-bgRed"}`}
              >
                <div className="w-full  flex gap-4 justify-start  ">
                  <Link to={`${AUTH_CLUB_PROFILE}/${ue?.club?.id}`}>
                    <img
                      src={ue?.club?.clubLogo?.formats?.thumbnail?.url || ClubLogo}
                      alt="profile-image"
                      className="rounded-full  w-[48px] h-[48px]"
                    />
                  </Link>
                  <div className="w-[65%]">
                    <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px] hover:underline">
                      <Link to={`${AUTH_CLUB_PROFILE}/${ue?.club?.id}`}>{ue?.club?.name}</Link>
                    </p>
                    <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal mt-2">
                      {ue?.club?.members?.length || "No"} Member
                      {(ue?.club?.members?.length || 0) < 2 ? "" : "s"}
                    </p>
                    {!isAgent && (loginAsProfileDetails?.id === userId || !userId) ? (
                      <>
                        {" "}
                        {ue?.club?.adminStatus === "Approved" ? (
                          <p
                            className="rounded-full text-[11px] w-[46px] py-[1px] px-[7px] mt-2 cursor-pointer"
                            style={{ backgroundColor: "rgba(0, 132, 21, 0.2)" }}
                          >
                            Admin
                          </p>
                        ) : ue?.club?.adminStatus === "Pending" ? (
                          <p className="rounded-full text-[11px] w-max bg-lightRed py-[1px] px-[7px] mt-2 cursor-pointer">
                            Pending
                          </p>
                        ) : ue?.club?.adminStatus === "Claim admin" ? (
                          <p
                            onClick={() => handleSendClaimRequest(ue?.club?.id)}
                            className="underline text-[11px] text-red-darkRed font-inter mt-2 cursor-pointer"
                          >
                            Claim for admin
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                {/* <div className="cursor-pointer">
                  <MessageIcon height={20} width={20} />
                </div> */}
              </div>
            ))}
          </div>
          <div className="border-t border-solid border-gr-primaryN8">
            <div className="flex justify-center items-center py-[15px]">
              <button
                onClick={() => {
                  setShowAll(!showAll)
                }}
                className="font-inter text-gr-neutralT4 text-xs leading-4 underline hover:text-gr-primaryR2"
              >
                {!showAll ? "Show all" : "Show less"}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default UserClubs
