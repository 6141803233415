import React, { useContext, useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import GRButton from "../ui/GRButton"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import { Autocomplete, Box, TextField, CircularProgress } from "@mui/material"
import { Form, Formik } from "formik"
import DialogHeader from "./DialogHeader"
import { debounce } from "lodash"
import { IConnectFamilyMemberPayload, useLoginUserAPI } from "../../services/useLoginUserAPI"
import { ISearchUserByNameResponse } from "../../modals/User"
import AppContext from "../../context/app-context/AppContext"
import { toast } from "react-toastify"
import { convertIntoDropDownOptions } from "../../utils/CommonUtils"
import { ConnectFamilyMemberDialogSchema } from "../../validations/Validations"

const initialValues = {
  memberEmail: "",
  relation: "",
  member: "",
}

interface IGRConnectFamilyMemberDialogProps {
  isShowDialog: boolean
  onSuccess: () => void
  onClose: () => void
}
const ConnectFamilyMemberDialog = (props: IGRConnectFamilyMemberDialogProps) => {
  // Props
  const { isShowDialog, onClose, onSuccess } = props

  // Context
  const { dropDownOptions } = useContext(AppContext)

  const { loginAsProfileDetails } = useContext(AppContext)

  // State
  const [options, setOptions] = useState<ISearchUserByNameResponse>()

  // APIs
  const { searchUsersByName, isLoading } = useLoginUserAPI()
  const { connectFamilyMember, isLoading: isSubmitting } = useLoginUserAPI()

  // Function
  const fetchResults = async (searchStr: string) => {
    try {
      if (searchStr?.length < 3) {
        return
      }
      const r = await searchUsersByName(searchStr)
      setOptions(r)
    } catch {}
  }

  const debounceSearch = debounce(fetchResults, 1000)

  const getEmailValue = (id: string) => {
    const o = options?.data?.find((u) => u?.id === parseInt(id))
    if (!o) return
    return {
      label: `${o?.firstname} ${o?.lastname} ${
        o?.clubMember?.name ? `- ${o?.clubMember?.name}` : ""
      }`,
      value: o?.id,
    }
  }

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      if (loginAsProfileDetails?.member?.some((m) => m?.member?.id === parseInt(values?.member))) {
        toast.error("User is already connected", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        })
        return
      }
      const payload: IConnectFamilyMemberPayload = {
        data: {
          ...values,
        },
      }
      await connectFamilyMember(payload)
      onSuccess()
      toast.success("Family member connected", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Auto close the toast after 3000ms (3 seconds)
      })
    } catch {}
  }

  const body = (
    <Formik
      initialValues={initialValues}
      validationSchema={ConnectFamilyMemberDialogSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, setFieldValue }) => (
        <Form className="compeleteProfileForm">
          <div className="mx-2 sm:mx-0 px-4 md:px-[32px] mb-8">
            <div className="flex">
              <span className="text-red-600 text-lg -mt-2">*</span>
              <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
            </div>
            {/* email address */}

            <div className="w-full mb-4 mt-4">
              <label className="block text-sm leading-6 mb-1" htmlFor="email">
                User name<span className="text-red-600">*</span>
              </label>

              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  autoFocus
                  id="email-search"
                  onChange={(e, v) => {
                    setFieldValue("member", v?.value)
                    setFieldValue("memberEmail", v?.label)
                  }}
                  value={getEmailValue(values?.member)}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  getOptionLabel={(option) => option?.label}
                  options={
                    options?.data?.map((o) => ({
                      label: `${o?.firstname} ${o.lastname} ${
                        o?.clubMember?.name ? `- ${o?.clubMember?.name}` : ""
                      }`,
                      value: o?.id,
                    })) || []
                  }
                  loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => debounceSearch(e?.target?.value)}
                      placeholder="Search name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          </React.Fragment>
                        ),
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "darkRed",
                        },
                        ...AutocompleteInputCss,
                      }}
                    />
                  )}
                />
              </Box>
              {touched?.memberEmail && (
                <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                  {errors?.memberEmail}
                </div>
              )}
            </div>
            <div className="w-full mb-[23px]">
              <label className="block text-sm leading-6 mb-1" htmlFor="relation">
                Relation<span className="text-red-600">*</span>
              </label>

              <Box sx={{ width: "100%" }}>
                <GRSelect
                  id="relation"
                  defaultValue=""
                  name="relation"
                  placeholder="Select relation"
                  onChange={handleChange}
                  value={values.relation}
                  error={touched.relation && Boolean(errors.relation)}
                  options={convertIntoDropDownOptions(dropDownOptions?.relation)}
                  errormsg={touched.relation && Boolean(errors.relation) ? errors.relation : ""}
                />
              </Box>
            </div>

            <div className="flex justify-between items-center mt-10">
              <GRButton
                onClick={onClose}
                label="Cancel"
                type="button"
                width={"120px"}
                className="sm:!px-[45px] !px-[29px]"
              />
              <GRButton
                isSubmitting={isSubmitting}
                label="Connect"
                width={"120px"}
                className="sm:!px-[45px] !px-[29px]"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
  return (
    <>
      <GRDialog
        open={isShowDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[600px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={
          <DialogHeader title="Connect with family member (current GR user)" onClose={onClose} />
        }
      />
    </>
  )
}

export default ConnectFamilyMemberDialog
