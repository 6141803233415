import * as React from "react"
import { SVGProps } from "react"

const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.867 17.236a1.672 1.672 0 0 1-1.608-1.228c-.242-.836-.484-1.687-.719-2.51l-.398-1.395c-.213-.746-.435-1.518-.648-2.278-.014-.052-.027-.067-.09-.085-1.744-.496-3.488-.995-5.232-1.494l-.933-.267C.506 7.77.007 7.132 0 6.394a1.665 1.665 0 0 1 1.215-1.64l3.003-.9 2.91-.874c3-.9 5.997-1.799 8.995-2.7a.926.926 0 0 1 .268-.045c.163 0 .306.059.423.174.181.177.23.41.146.69L14.888 8c-.8 2.67-1.602 5.339-2.404 8.009-.223.743-.856 1.224-1.613 1.226h-.004v.001Zm3.244-13.23c-1.76 1.76-3.578 3.578-5.37 5.365-.058.059-.066.087-.042.168.282.974.565 1.965.838 2.924l.43 1.504c.158.554.322 1.127.487 1.689.06.205.221.337.41.337a.447.447 0 0 0 .191-.044c.147-.07.212-.2.265-.377l2.188-7.292 1.01-3.367.466-1.56.093-.312-.966.965Zm-8.684.784c-1.26.378-2.521.756-3.783 1.132-.203.06-.314.146-.37.286a.399.399 0 0 0 .005.33c.06.122.184.214.35.262l1.686.48 4.402 1.261a.22.22 0 0 0 .057.01c.014 0 .034 0 .082-.048 1.78-1.784 3.59-3.595 5.342-5.346l1-1L5.427 4.79Z"
      fill="#000"
    />
  </svg>
)

export default ShareIcon
