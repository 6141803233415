import React, { useState, useEffect, useContext } from "react"
import GRButton from "../components/ui/GRButton"
import ClubOpportunityCard from "../components/dashboard/ClubOpportunities/ClubOpportunityCard"
import EditIcon from "../assets/svg/EditIcon"
import ClubFilterCard from "../components/dashboard/ClubOpportunities/ClubFilterCard"
import ClubOpportunityCardMob from "../components/dashboard/ClubOpportunities/ClubOpportunityCardMob"
import ClubFilterAccordian from "../components/dashboard/ClubOpportunities/ClubFilterAccordian"
import LeftArrow from "../assets/svg/LeftArrow"
import { AUTH_DASHBOARD } from "../routes/RouteConstants"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import { Link } from "react-router-dom"
import CreateOpportunityDialog from "../components/dialogs/CreateOpportunityDialog"
import { IDropDownOptionsResponse } from "../modals/Config"
import { IOpportunitySummary } from "../modals/Opportunity"
import { useConfigAPI } from "../services/useConfigAPI"
import { useOpportunityAPI } from "../services/useOpportunityAPI"
import AppContext from "../context/app-context/AppContext"
import { useScreenWidth } from "../context/ScreenWidthContext"

const ClubOpportunity = () => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [dropDownOptions, setDropDownOptions] = useState<IDropDownOptionsResponse>()
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[]>([])
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isInactive, setIsInactive] = useState<boolean>(false)
  // APIs
  const { getDropDownOptions } = useConfigAPI()
  const { getOpportunityByClubId, getOpportunityByClubIdAndFilter } = useOpportunityAPI()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  useEffect(() => {
    fetchConfig()
    //getOpportunity()
  }, [])

  useEffect(() => {
    if ((isActive && isInactive) || (!isActive && !isInactive)) {
      getOpportunity()
    } else {
      const params = isActive ? "active=true" : "inactive=true"
      getFilterOpportunity(params)
    }
  }, [isActive, isInactive])

  const getFilterOpportunity = async (queryParams: string) => {
    try {
      if (!loginAsProfileDetails?.club?.id) return
      const opportunityList = await getOpportunityByClubIdAndFilter(
        loginAsProfileDetails?.club?.id,
        queryParams,
      )
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }
  const getOpportunity = async () => {
    try {
      if (!loginAsProfileDetails?.club?.id) return
      const opportunityList = await getOpportunityByClubId(loginAsProfileDetails?.club?.id) // I will use dynamic club id after pass in URL
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }

  const fetchConfig = async () => {
    try {
      const cr = await getDropDownOptions()
      setDropDownOptions(cr)
    } catch {}
  }

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 650 && screenWidth < 1250

  return (
    <div>
      <div className="sm:hidden flex items-center px-4 mb-4 sm:mt-0 mt-4">
        <div className="flex justify-start items-center font-inter">
          <Link to={AUTH_DASHBOARD} className="flex items-center justify-start">
            <LeftArrow className="mr-3 w-3 min-w-3" /> Back
          </Link>
        </div>
      </div>
      <HeaderSearchFeild />
      {/* mobile view */}
      <div className="sm:hidden px-4">
        <div className="mb-4">
          <ClubFilterAccordian />
        </div>
        <div className="mb-4 flex justify-end " onClick={() => setIsShowDialog(!isShowDialog)}>
          <GRButton
            width="100%"
            startIcon={<EditIcon />}
            label="Post a new opportunity"
            fontFamily="inter"
            borderRadius={"16px"}
          />
        </div>

        <div className="">
          <div className="flex flex-col bg-white shadow-lg rounded-2xl px-4 pb-3">
            <div>
              <div className="flex mt-4">
                <div className="mb-2">
                  <h2 className="text-[18px] font-semibold">Opportunities</h2>
                </div>
              </div>
              {opportunities &&
                opportunities?.map((opportunity, index) => (
                  <ClubOpportunityCardMob opportunity={opportunity} key={index} />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex px-4 md:px-6 mt-8 justify-between mb-8">
        {/* web view */}
        {/* FilterCard */}
        <div
          className={`w-full min-h-[300px] h-full ${
            isLargeScreen
              ? "flex-[25%] max-w-[25%]"
              : isMediumScreen
              ? "flex-[25%] max-w-[25%]"
              : "flex-[100%] max-w-[100%]"
          }`}
        >
          <ClubFilterCard
            setIsActive={setIsActive}
            isActive={isActive}
            setIsInactive={setIsInactive}
            isInactive={isInactive}
          />
        </div>

        <div
          className={`${
            isLargeScreen
              ? "flex-[73%] max-w-[73%]"
              : isMediumScreen
              ? "flex-[72%] max-w-[72%]"
              : "flex-[100%] max-w-[100%]"
          } flex flex-col rounded-2xl bg-white pt-5`}
        >
          {/* heading and btn */}
          <div className="w-full flex justify-between pt-1 p-6 mb-5 items-center">
            {/* Leftsection */}
            <div className="text-left">
              <div className="font-inter font-semibold md:text-2xl text-xl">
                All opportunities ({opportunities?.length})
              </div>
              <div className="font-inter text-gr-neutralT3">Post a new opportunity today!</div>
            </div>

            {/* RightSection - btn */}
            <div onClick={() => setIsShowDialog(!isShowDialog)} className="text-right">
              <GRButton
                startIcon={<EditIcon />}
                label="Post a new opportunity"
                fontFamily="inter"
                fontSize="12px"
              />
            </div>
          </div>
          <CreateOpportunityDialog
            dropDownOptions={dropDownOptions}
            isShowDialog={isShowDialog}
            onClose={() => setIsShowDialog(false)}
            onGetOpportunity={() => getOpportunity()}
          />
          {/* opportunityCards */}
          <div className="px-6">
            {opportunities &&
              opportunities?.map((opportunity, index) => (
                <ClubOpportunityCard opportunity={opportunity} key={index} />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClubOpportunity
