import dayjs from "dayjs"
import * as yup from "yup"

const getText = (html: string) => {
  const el = document.createElement("div")
  el.innerHTML = html
  return el.textContent
}
export const AddPostValidation = yup.object({
  postContent: yup
    .string()
    ?.trim()
    .required()
    .test(
      "post-content-characters",
      "Post content must be less than 3000 characters",
      function (value) {
        // console.log("value data here >>>> ", value)
        return (getText((value as string) || "")?.length || 0) > 3000 ? false : true
      },
    ),
})

export const AddRugbyExperienceFormValidation = yup.object({
  club: yup.string()?.trim().required("Club is required"),
  currentClub: yup.bool(),
  country: yup.string()?.trim().required("Country is required"),
  startDate: yup
    .string()
    .when("currentClub", {
      is: true,
      then: yup.string().notRequired(),
      otherwise: yup
        .string()
        .test("is-valid-date", "Start date can't be in the future", (value) => {
          const parsedDate = dayjs(value)
          return parsedDate.isValid() && parsedDate.isBefore(dayjs().startOf("day").add(1, "day"))
        })
        .when("endDate", (endDate, schema) => {
          return schema.test({
            test: function (value: string) {
              if (!endDate) return true // If endDate is not provided, don't perform the comparison
              const startDateObj = dayjs(value)
              const endDateObj = dayjs(endDate)
              return (
                startDateObj.isValid() && endDateObj.isValid() && startDateObj.isBefore(endDateObj)
              )
            },
            message: "Start date must be less than the end date",
          })
        }),
    })
    .required("Start date is required"),
  endDate: yup.string().when("currentClub", {
    is: false,
    then: yup
      .string()
      .required("End date is required")
      .test("is-valid-date", "End date can't be in future", (value) => {
        const parsedDate = dayjs(value)
        return parsedDate.isValid() && parsedDate.isBefore(dayjs().startOf("day").add(1, "day"))
      }),

    otherwise: (schema) => schema.optional(),
  }),
  experienceSeason: yup.array().of(
    yup.object().shape({
      userType: yup.string(),
      season: yup.string()?.trim().required("Season is required"),
      playingLevel: yup.string()?.required("Level is required"),
      discipline: yup.string()?.trim().required("Code is required"),
      ageGroup: yup.string()?.trim().required("Age group is required"),
      coachPosition: yup.string().when("userType", {
        is: "Coach",
        then: yup.string().trim().required("Position is required"),
        otherwise: yup.string().trim(),
      }),
      position1: yup.string().when("userType", {
        is: (userType: string) => ["Player", "Parent", "Agent"].includes(userType),
        then: yup.string().trim().required("Position 1 is required"),
        otherwise: yup.string().trim(),
      }),
      position2: yup.string().when(["userType", "position1"], {
        is: (userType: string, position1: string) => userType === "Player" && position1,
        then: yup
          .string()
          .test(
            "not-same-as-position1",
            "Position 2 cannot be the same as Position 1",
            function (value) {
              return value !== this.parent.position1
            },
          ),
        otherwise: yup.string().when("userType", {
          is: "player",
          then: yup.string(),
          otherwise: yup.string(),
        }),
      }),
      leagueCompetition: yup.string()?.trim().required("League/Competition is required"),
      gamePlayed: yup
        .mixed()
        .transform((value, originalValue) => {
          if (typeof originalValue === "string" && originalValue.trim() !== "") {
            return parseInt(originalValue, 10)
          }
          return originalValue
        })
        .test(
          "is-valid-number",
          "Must be a valid number between 1 and 99999",
          (value) =>
            value === undefined || (typeof value === "number" && value >= 1 && value <= 99999),
        ),
      pointScored: yup
        .mixed()
        .transform((value, originalValue) => {
          if (typeof originalValue === "string" && originalValue.trim() !== "") {
            return parseInt(originalValue, 10)
          }
          return originalValue
        })
        .test(
          "is-valid-number",
          "Must be a valid number between 1 and 99999",
          (value) =>
            value === undefined || (typeof value === "number" && value >= 1 && value <= 99999),
        ),
    }),
  ),
})

export const registerFormValidationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
    .min(3, "First name must be at least 3 letters")
    .required("First name is required"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
    .min(3, "Last name must be at least 3 letters")
    .required("Last name is required"),
  email: yup
    .string()
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email format")
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email format")
    .email("Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .min(
      8,
      "Password must be at least 8 characters, and contain letters, numbers and special charactersin",
    )
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(^\S).+$/,
      "Password must contain letters, numbers and special characters",
    )
    .matches(/^\S*$/, "Password must contain letters, numbers and special characters")
    .required("Password is required"),

  policy: yup.boolean().oneOf([true], "You must agree to the privacy policy"),
  profileType: yup
    .string()
    .oneOf(["Player", "Coach", "Parent", "Agent"])
    .required("Profile type is required"),
  dob: yup.string().test("is-past-dob", "DOB must be in the past", function (value) {
    if (!value) {
      return true // If DOB is not provided, validation passes
    }
    const dobDate = new Date(value)
    const currentDate = new Date()

    return dobDate <= currentDate
  }),
  // dob: yup.date().max(new Date(), "DOB must be in the past").required("DOB is required"),
  gender: yup.string().nullable(),
  middleName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*"),
  contact: yup
    .string()
    .matches(/^\d+$/, "Invalid contact number")
    .min(10, "Invalid contact number")
    .max(15, "Invalid contact number"),
  zipCode: yup.string().matches(/^[a-z\d\-_\s]+$/i, "Only alphanumeric are allowed*"),
})

export const loginFormValidationSchema = yup.object({
  email: yup
    .string()
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email")
    //.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address*")
    .email("Invalid email")
    .required("Email is required*"),
  password: yup.string().required("Password is required*"),
})

export const personalInfoOnboardingValidationSchema = (userDetails: any) => {
  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
      .required("First name is required*"),

    middleName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*"),

    lastName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
      .required("Last name is required*"),

    dob: yup
      .date()
      .max(new Date(), "DOB must be in the past*")
      .required("DOB is required*")
      .test("age", "Age must be greater than 16", function (value) {
        if (!value) return false
        const loginAsProfileDetails = userDetails

        const currentDate = new Date()
        const dob = new Date(value)
        const ageInMilliseconds = currentDate.getTime() - dob.getTime()
        const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000)
        if (loginAsProfileDetails?.isChild) {
          return (
            ageInYears <= 16 || this.createError({ message: "Child age must be smaller than 16" })
          )
        } else {
          return ageInYears >= 16 || this.createError({ message: "Age must be greater than 16" })
        }
      }),

    gender: yup.string().required("Gender is required*"),
  })
  return schema
}

export const professionalInfoOnboardingParentValidationSchema = yup.object({
  intlEligibility: yup
    .array()
    .of(yup.string())
    .max(10, "Can select maximum 10 international eligibility"),
  nationality: yup.array().of(yup.string()).max(10, "Can select maximum 10 nationality"),
})

export const professionalInfoOnboardingValidationSchema = yup.object({
  intlEligibility: yup
    .array()
    .of(yup.string())
    .max(10, "Can select maximum 10 international eligibility")
    .test("intlEligibility-required", "International eligibility is required", function (value) {
      if (!value || value.length === 0) {
        return false // Validation failed if the length is 0
      }
      return true // Validation passed if there is at least one item
    }),
  nationality: yup
    .array()
    .of(yup.string())
    .max(10, "Can select maximum 10 nationality")
    .test("nationality-required", "Nationality is required", function (value) {
      if (!value || value.length === 0) {
        return false // Validation failed if the length is 0
      }
      return true // Validation passed if there is at least one item
    }),

  level: yup.string().required("Level is required"),
  // referralUserId: yup.string().when(["hearAboutUs"], {
  //   is: (hearAboutUs: string) => hearAboutUs === "Referral",
  //   then: yup.string().required("Required Field*"),
  //   otherwise: yup.string().notRequired(),
  // }),
  region: yup.array().of(yup.string()).max(4, "Can select maximum 4 region"),
  disciplines: yup.array().notRequired(),
  hearAboutUs: yup.string().required("About us is required"),
})
export const CoachProfessionalInfoOnboardingValidationSchema = yup.object({
  intlEligibility: yup
    .array()
    .of(yup.string())
    .max(10, "Can select maximum 10 international eligibility"),

  nationality: yup
    .array()
    .of(yup.string())
    .max(10, "Can select maximum 10 nationality")
    .test("nationality-required", "Nationality is required", function (value) {
      if (!value || value.length === 0) {
        return false // Validation failed if the length is 0
      }
      return true // Validation passed if there is at least one item
    }),

  level: yup.string().required("Level is required"),
  // referralUserId: yup.string().when(["hearAboutUs"], {
  //   is: (hearAboutUs: string) => hearAboutUs === "Referral",
  //   then: yup.string().required("Required Field*"),
  //   otherwise: yup.string().notRequired(),
  // }),
  region: yup.array().of(yup.string()).max(4, "Can select maximum 4 region"),
  disciplines: yup.array().notRequired(),
  hearAboutUs: yup.string().required("About us is required"),
})

export const AgentProfessionalInfoOnBoardingValidationSchema = yup.object({
  nationality: yup
    .array()
    .of(yup.string().required("Nationality is required"))
    .min(1, "Nationality is required")
    .max(10, "Can select maximum 10 nationality"),
  hearAboutUs: yup.string().required("About us is required"),
  // referralUserId: yup.string().when(["hearAboutUs"], {
  //   is: (hearAboutUs: string) => hearAboutUs === "Referral",
  //   then: yup.string().required("Referral id is required"),
  //   otherwise: yup.string().notRequired(),
  // }),
})

export const otherInfoOnboardingValidationSchema = yup.object({
  contact: yup
    .string()
    .matches(/^\d+$/, "Invalid contact number")
    .min(10, "Invalid contact number")
    .max(15, "Invalid contact number"),
  country: yup.string().required("Country is required").min(1, "Country is required"),
  zipCode: yup
    .string()
    .matches(/^[a-z\d\-_\s]+$/i, "Only alphanumeric are allowed*")
    .required("Postcode is required"),
})

export const IUnder16MemberFormValidations = yup.object({
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
    .required("First Name is required*"),
  middleName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
    .required("Last name is required*"),
  dob: yup
    .date()
    .max(new Date(), "DOB must be in the past*")
    .test("age", "Age must be under 16", (value) => {
      if (!value) return false
      const currentDate = new Date()
      const dob = new Date(value)
      const ageInMilliseconds = currentDate.getTime() - dob.getTime()
      const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000)

      return ageInYears < 16
    })
    .required("DOB is required*"),
  gender: yup.string().required("Gender is required"),
  relation: yup.string().required("Relation is required"),
  // internationalEligibilities: yup.string().required("International eligibility is required"),
  //nationality: yup.string().required("Nationality is required"),
  internationalEligibilities: yup
    .array()
    .min(1, "At least one international eligibility is required")
    .required("International eligibility is required")
    .max(10, "Can select maximum 10 international eligibility"),
  nationality: yup
    .array()
    .min(1, "At least one nationality is required")
    .required("Nationality is required")
    .max(10, "Can select maximum 10 nationality"),
  level: yup.string().required("Level is required"),
  strengths: yup.string().max(50, "Length must be smaller than 50 characters"),
  contactNumber: yup
    .string()
    .matches(/^\d+$/, "Invalid contact number")
    .min(10, "Invalid contact number")
    .max(15, "Invalid contact number"),
  country: yup.string().required("Country is required"),
  postCode: yup
    .string()
    .matches(/^[a-z\d\-_\s]+$/i, "Only alphanumeric are allowed*")
    .required("Postcode is required"),
})

export const IAbove16MemberFormValidation = yup.object({
  relation: yup.string().required("Relation is required"),
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
    .required("First name is required*"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z]+(\s*[A-Za-z]+)*$/, "Only alphabets are allowed*")
    .required("Last name is required*"),
  memberEmail: yup
    .string()
    //.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address*")
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email format")
    .email("Invalid email")
    .required("Email is required*"),
})

export const ConnectFamilyMemberDialogSchema = yup.object({
  memberEmail: yup
    .string()
    //.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address*")
    //.matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email address")
    //.email("Invalid email")
    .required("User name is required"),
  relation: yup.string().required("Relation is required"),
})
export const ConnectPlayerCoachDialogSchema = yup.object({
  memberEmail: yup
    .string()
    //.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address*")
    //.matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email address")
    //.email("Invalid email")
    .required("User name is required*"),
})

export const EditOpportunitySchema = yup.object({
  season: yup.string().required("Season is required"),
  agegroup: yup.string().required("Age group is required"),
  level: yup.string().required("Level is required"),
  position: yup.string().required("Position is required"),
  country: yup.string().required("Country is required"),
})

export const UserBasicInfoValidation = yup.object({
  firstname: yup.string().trim().required("First name is required"),
  lastname: yup.string().trim().required("Last name is required"),
  contactNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Invalid contact number")
    .min(10, "Invalid contact number")
    .max(15, "Invalid contact number"),
  city: yup.string().notRequired(),
  postCode: yup
    .string()
    .required("Postcode is required")
    .matches(/^[a-z\d\-_\s]+$/i, "Only alphanumeric are allowed*"),
  country: yup.string().required("Country is required"),
  state: yup.string().notRequired(),
})

export const ClubContactDetailsValidations = yup.object({
  email: yup
    .string()
    //.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address*")
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email address*")
    .email("Invalid email")
    .required("Email is required"),
  zipCode: yup
    .string()
    .required("Postcode is required")
    .matches(/^[a-z\d\-_\s]+$/i, "Only alphanumeric are allowed*"),
  city: yup.string(),
  state: yup.string(),
  country: yup.string().required("Country is required"),
  mobileNumber: yup
    .string()
    .matches(/^\d+$/, "Only numbers are allowed*")
    .min(10, "Invalid contact number")
    .max(15, "Invalid contact number"),
  linkedinLink: yup
    .string()
    .notRequired()
    .matches(
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
      "Invalid LinkedIn URL",
    ),
  facebookLink: yup
    .string()
    .notRequired()
    .matches(
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
      "Invalid Facebook URL",
    ),
  instagramLink: yup
    .string()
    .notRequired()
    .matches(
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
      "Invalid Instagram URL",
    ),
  twitterLink: yup
    .string()
    .notRequired()
    .matches(
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
      "Invalid Twitter URL",
    ),
  snapchatLink: yup
    .string()
    .notRequired()
    .matches(
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
      "Invalid Snapchat URL",
    ),
  tiktokLink: yup
    .string()
    .notRequired()
    .matches(
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
      "Invalid TikTok URL",
    ),
})

export const inviteByEmailFormValidation = yup.object({
  email: yup
    .string()
    //.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address*")
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email address*")
    .email("Invalid email")
    .required("Email is required*"),
})

export const videoHighlightValidation = yup.object({
  description: yup.string()?.trim().required("Description is required"),
  type: yup.string(),
  video: yup.string().when("type", {
    is: "Upload video",
    then: yup.string().required("Please upload video."),
    otherwise: yup.string().optional(),
  }),
  videoURL: yup.string().when("type", {
    is: "Video URL",
    then: yup
      .string()
      .matches(
        /(?:https?:\/\/)?(?:www\.)?(youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|watch\?v%3D|v=|embed%2F|e%2F|vi%2F|e%2F|user%2F[^#]#user%2F[^#]#p%2F[^#]*){1}([a-zA-Z0-9_-]+)/,
        "Please provide a valid youtube link",
      )
      .required("Please provide video URL"),
    otherwise: yup.string().optional(),
  }),
  videoEmbed: yup.string().when("type", {
    is: "Video embed code",
    then: yup.string().required("Please provide video embed code"),
    otherwise: yup.string().optional(),
  }),
})

// export const connectAgentValidation = yup.object({
//   email: yup
//     .string()
//     .nullable()
//     //.matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address*")
//     .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,3}$/, "Invalid email address*")
//     .email("Invalid email")
//     .required("Email is required*"),
//   firstName: yup.string().required("First name is required"),
//   lastName: yup.string().required("Last name is required"),
// })

export const connectAgentValidation = yup.object().shape({
  name: yup.string().when("type", {
    is: (type: string) => type === "searchAgent",
    then: yup.string().required("Name is required"),
    otherwise: yup.string().nullable(),
  }),
  email: yup.string().when("type", {
    is: (type: string) => type === "inviteAgent",
    then: yup.string().email("Invalid email address").required("Email is required"),
    otherwise: yup.string().nullable(),
  }),
  firstName: yup.string().when("type", {
    is: (type: string) => type === "inviteAgent",
    then: yup.string().required("First name is required"),
    otherwise: yup.string().nullable(),
  }),
  lastName: yup.string().when("type", {
    is: (type: string) => type === "inviteAgent",
    then: yup.string().required("Last name is required"),
    otherwise: yup.string().nullable(),
  }),
})
