import React from "react"
import { GRDialog } from "../ui/GRDialog"
import ShareButtons from "../posts/ShareButtons"
import SocialMediaPostShareButtonDialogHeader from "./SocialMediaPostShareButtonDialogHeader"

interface IRecordHistoryProps {
  isShowDialog: boolean
  onClose: () => void
  url: string
}

const PostShare = (props: IRecordHistoryProps) => {
  const { isShowDialog, onClose, url } = props

  const body = (
    <div className="max-h-[600px] overflow-visible">
      <ShareButtons url={url} title="" />
    </div>
  )

  return (
    <div>
      <GRDialog
        open={isShowDialog}
        onClose={() => onClose()}
        dialogtitle={<SocialMediaPostShareButtonDialogHeader onClose={onClose} title={`${url}`} />}
        dialogbody={body}
        fullWidth
        classes={{ paper: "" }}
      />
    </div>
  )
}

export default PostShare
