import { IGetClubByIdResponse, IGetClubByTextResponse, IGetClubResponse } from "../modals/Club"
import useBaseAPI from "./useBaseAPI"

export const useClubAPI = () => {
  const { GET, POST, PUT, isLoading } = useBaseAPI()

  const getClubs = (query?: object): Promise<IGetClubResponse> => {
    return GET("/clubs", query)
  }

  const getClubById = (id: string): Promise<IGetClubByIdResponse> => {
    return GET(`/get-club/${id}`)
  }

  const getClubByText = (searchStr: string): Promise<IGetClubByTextResponse> => {
    return GET(`/search-club/${searchStr}`)
  }

  const sendClaimAdminRequest = (payload: ISendClaimAdminRequestPayload): Promise<any> => {
    return POST(`/create-club-admin-request`, payload)
  }

  const updateClub = (id: number, payload: IUpdateClubPayload) => {
    return PUT(`/update-club/${id}`, payload)
  }

  const unfollowClub = (payload: IUnfollowClubPayload): Promise<any> => {
    return PUT(`/unfollow-club`, payload)
  }

  return {
    isLoading,
    getClubs,
    getClubById,
    getClubByText,
    sendClaimAdminRequest,
    updateClub,
    unfollowClub,
  }
}

export interface IUnfollowClubPayload {
  data: {
    clubId: string
  }
}

interface ISendClaimAdminRequestPayload {
  data: {
    user: string
    club: string
  }
}

export interface IUpdateClubDetailsPayloadValues {
  name?: string
  disciplines?: string[]
  email?: string
  addressLine_1?: string
  addressLine_2?: string
  city?: string
  state?: string
  zipCode?: string
  country?: string
  phoneNumber?: string
  mobileNumber?: string
  clubLogo?: string
  clubCoverPhoto?: string
  facebookLink?: string
  instagramLink?: string
  twitterLink?: string
  linkedinLink?: string
  members?: string[]
  description?: string
  userExperiences?: string[]
  admins?: string[]
  followers?: string[]
}

interface IUpdateClubPayload {
  data: IUpdateClubDetailsPayloadValues
}
