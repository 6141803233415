import React, { SVGProps } from "react"

const MoreInfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.222 1.333c-.337 0-.61-.298-.61-.666 0-.369.273-.667.61-.667h3.667c.162 0 .317.07.432.195a.699.699 0 01.179.472v4c0 .368-.274.666-.611.666-.338 0-.611-.298-.611-.666v-2.39L4.598 8.47a.576.576 0 01-.863 0 .71.71 0 010-.942l5.679-6.196H7.222zM.5 2.667c0-.737.547-1.334 1.222-1.334h3.056c.337 0 .61.299.61.667 0 .368-.273.667-.61.667H1.722v8h7.334V7.333c0-.368.273-.666.61-.666.338 0 .612.298.612.666v3.334c0 .736-.547 1.333-1.222 1.333H1.722C1.047 12 .5 11.403.5 10.667v-8z"
        fill="#9F0002"
      />
    </svg>
  )
}

export default MoreInfoIcon
