import React from "react"
import LocationIcon from "../../../assets/svg/LocationIcon"
// import GRSelect from "../../ui/GRSelect"
// import { ApplicantStatus } from "../../../utils/Constants"
import { ILoginUser } from "../../../modals/User"
import { getNameInitials } from "../../../utils/CommonUtils"
import { AUTH_USER_PROFILE } from "../../../routes/RouteConstants"
import { Link } from "react-router-dom"

function Applicants({ applicant }: { applicant?: ILoginUser }) {
  return (
    <div className="flex w-full px-4 py-4 hover:bg-gr-primaryN9 transition-colors duration-200 rounded-lg">
      {applicant?.profilePicture?.formats?.thumbnail?.url ? (
        <Link to={`${AUTH_USER_PROFILE}/${applicant?.id}`}>
          <img
            src={applicant?.profilePicture?.formats?.thumbnail?.url}
            className="self-start w-[70px] h-[70px] rounded-full object-cover mr-4"
          />
        </Link>
      ) : (
        <div className="flex justify-center items-center rounded-full w-[70px] h-[70px] bg-gr-primaryR1 mr-4">
          <Link to={`${AUTH_USER_PROFILE}/${applicant?.id}`}>
            <span className="font-inter text-xl sm:text-[28px] text-gr-primaryR2 font-semibold">
              {getNameInitials(applicant?.firstname, applicant?.lastname)}
            </span>
          </Link>
        </div>
      )}
      <div className="flex flex-col text-gr-neutralT2 ">
        <span className="font-semibold text-[18px] text-gr-primaryN1 font-inter mb-1">
          <Link to={`${AUTH_USER_PROFILE}/${applicant?.id}`}>
            {applicant?.firstname} {applicant?.lastname}
          </Link>
        </span>
        <div className="flex flex-row mb-1">
          <LocationIcon />
          <span className="font-medium ml-1 text-[15px]">{applicant?.country || "N/a"}</span>
        </div>
        <span className="text-[12px]">
          {applicant?.playerPosition_1?.title}, {applicant?.playerPosition_2?.title}
        </span>
      </div>
      <div className="flex justify-end items-center space-x-5 sm:mt-[-45px] mt-2">
        {/* <span className="sm:text-[13px] text-[10px]">1Hr</span>
          <span>
            <img src={ApplicantMsgIcon} />
          </span> */}
        {/* <span>
            {" "}
            <GRSelect
              style={{ width: "150px", height: "33px" }}
              options={ApplicantStatus}
              placeholder="In Progress"
            />
          </span> */}
      </div>
    </div>
  )
}

export default Applicants
