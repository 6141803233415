import React, { useState } from "react"
import EditBtn from "../../assets/svg/EditBtn"
import ProfessionalInfoPopup from "./ProfessionalInfoPopup"
import File from "../../../src/assets/svg/File.svg"
import { useUserHook } from "../../hooks/useUserHook"

import { IUser } from "../../modals/User"
import PdfIcon from "../../assets/svg/clubprofileicon/PdfIcon"
import WordIcon from "../../assets/svg/clubprofileicon/WordIcon"
import ImageIcon from "../../assets/svg/clubprofileicon/ImageIcon"
import { IUploadFilesResponse } from "../../modals/Config"
import { Link } from "react-router-dom"

interface IProfessionalInfoCardProps {
  canEdit?: boolean
  profileDetails?: IUser
}
const ProfessionalInfoCard = (props: IProfessionalInfoCardProps) => {
  const getFileIcon = (file: string) => {
    const extension = file?.split(".")?.pop()?.toLowerCase()
    if (extension === "pdf" || extension === "pptx") {
      return <PdfIcon />
    } else if (extension === "doc" || extension === "docx") {
      return <WordIcon />
    } else if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "svg"
    ) {
      return <ImageIcon />
    } else {
      return <img src={File} alt="" />
    }
  }
  // Props
  const { canEdit, profileDetails } = props

  // Hooks
  const { isCoach, isAgent } = useUserHook()

  // State
  const [isShowEditDialog, setIsShowEditDialog] = useState<boolean>(false)
  return (
    <>
      {isShowEditDialog && (
        <ProfessionalInfoPopup open={isShowEditDialog} onClose={() => setIsShowEditDialog(false)} />
      )}
      <div className="px-4 py-[30px] rounded-[16px] bg-white ">
        {/* heading and edit btn */}
        <div className="flex justify-between items-center">
          <div className="font-inter sm:text-2xl text-xl font-semibold">Professional info</div>
          <div className="cursor-pointer">
            {canEdit ? <EditBtn onClick={() => setIsShowEditDialog(true)} /> : ""}
          </div>
        </div>
        <div className="border border-gray-200 mt-4 mb-5"></div>
        {profileDetails?.qualification && profileDetails?.qualification?.length > 0 && (
          <div className="min-h-[110px] bg-bgRed rounded-lg pt-[18px] md:mx-[15px] mx-2">
            <div className="font-inter text-lg font-semibold  pl-[28px]">Qualification</div>
            <div className="flex pb-2 flex-wrap">
              {profileDetails?.qualification &&
                profileDetails?.qualification?.map((q, i) => (
                  <div key={i} className="flex flex-col items-center justify-center my-2 ml-8">
                    <Link to={q?.url} target="_blank">
                      <FilesCard getFileIcon={getFileIcon} file={q} />
                      <div
                        title={q?.name}
                        className="font-inter text-gr-neutralT3 w-28 truncate text-[14px] text-center"
                      >
                        {q?.name}
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        )}
        {/* cards */}
        <div className="flex flex-col sm:flex-row justify-center gap-6 flex-wrap md:px-3 px-2 mt-5">
          {/* currentClub */}
          {profileDetails?.userExperiences?.some((m) => m?.currentClub && m?.club?.name !== null) &&
          !isAgent ? (
            <Card
              label="Current club"
              data={String(
                profileDetails?.userExperiences
                  ?.filter((m) => m?.currentClub && m?.club?.name !== null)
                  .map((m) => m?.club?.name)
                  .join(", ") || "",
              )}
            />
          ) : null}
          {profileDetails?.nationality && (
            <Card label="Nationality" data={String(profileDetails?.nationality || " ")} />
          )}
          {!isAgent ? (
            <>
              {profileDetails?.internationalEligibilities && (
                <Card
                  label="International eligibility"
                  data={profileDetails?.internationalEligibilities}
                />
              )}
              {profileDetails?.level?.title && (
                <Card
                  label={`${isCoach ? "Coaching level" : "Level"}`}
                  data={profileDetails?.level?.title || ""}
                />
              )}
              {profileDetails?.highestLevelPlayed?.title && (
                <Card
                  label={`Highest level ${isCoach ? "coached" : "played"}`}
                  data={String(profileDetails?.highestLevelPlayed?.title || " ")}
                />
              )}
            </>
          ) : null}

          {isCoach && profileDetails?.highestQualification?.title && (
            <Card
              label="Highest coaching qualification"
              data={profileDetails?.highestQualification?.title || ""}
            />
          )}
          {!isAgent && profileDetails?.strengths && (
            <Card label="Specialties / Strength" data={String(profileDetails?.strengths || " ")} />
          )}
          {profileDetails?.disciplines?.length ? (
            <Card
              label="Codes"
              data={profileDetails?.disciplines?.map((d) => d?.title)?.join(", ") || ""}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}
interface IGRCardProps {
  label?: string
  data?: string
}
const Card: React.FC<IGRCardProps> = (props: IGRCardProps) => {
  return (
    <div className="border border-gr-primaryR4 md:max-w-[17.4rem] sm:max-w-[13.3rem] md:py-0 py-4 w-full min-h-[85px] h-auto rounded-[6px] flex flex-col gap-1 justify-center items-center px-4">
      <div className="font-semibold font-inter">{props?.label}</div>
      <div className="font-inter text-gr-neutralT3 text-center">{props?.data}</div>
    </div>
  )
}

interface IFilesCard {
  file: IUploadFilesResponse
  getFileIcon: (file: string) => React.ReactNode
}

export const FilesCard: React.FC<IFilesCard> = ({ file, getFileIcon }) => {
  return (
    <div className="flex justify-center items-center bg-bgRed rounded-[24px] py-[4px]">
      <div className="flex gap-4 items-center">
        <div>{getFileIcon(file?.name)}</div>
      </div>
    </div>
  )
}
export default ProfessionalInfoCard
