import * as React from "react"

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={12} viewBox="0 0 15 12" fill="none">
      <path
        d="M14.625 1.575a.681.681 0 00-.75 0l-3.3 2.25V2.25c0-.825-.675-1.5-1.5-1.5H1.5C.675.75 0 1.425 0 2.25v7.5c0 .825.675 1.5 1.5 1.5H9c.825 0 1.5-.675 1.5-1.5V8.175l3.3 2.25c.15.075.3.15.45.15.15 0 .225 0 .375-.075A.788.788 0 0015 9.825V2.25c0-.3-.15-.525-.375-.675zM9 9.75H1.5v-7.5H9v7.5zm4.5-1.425l-3-2.025v-.675l3-2.025v4.725z"
        fill="#6A984D"
      />
    </svg>
  )
}

export default SvgComponent
