/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react"
import { ICurrentLoginProfile, ILoginUser } from "../../modals/User"
import { IDropDownOptionsResponse } from "../../modals/Config"
import { ILocation } from "../../modals/Auth"
import { Socket } from "socket.io-client"
import { IConnectedUsersResponse } from "../../services/useLoginUserAPI"

export interface IAppContextState {
  isAppLoading: boolean
  primaryUserDetails: ILoginUser | undefined
  dropDownOptions: IDropDownOptionsResponse | undefined
  currentLocation: ILocation | undefined
  loginAsProfileDetails: ILoginUser | undefined
  currentLoginProfile: ICurrentLoginProfile | undefined
  socket: Socket<any> | undefined
  unreadMessageCount: number
  connectedUser: IConnectedUsersResponse | undefined
}

export interface IAppContextType extends IAppContextState {
  setPrimaryUserDetails: (user: ILoginUser | undefined) => void
  setDropdownOptions: (user: IDropDownOptionsResponse | undefined) => void
  setCurrentLocation: (currentLocation: ILocation | undefined) => void
  setLoginAsProfileDetails: (currentProfile: ILoginUser | undefined) => void
  setCurrentLoginProfile: (currentProfile: ICurrentLoginProfile) => void
  loadApp: () => Promise<void>
  setSocket: (socket: Socket<any>) => void
  setUnreadMessageCount: (count: number) => void
  setConnectedUser: (currentConnectedUser: IConnectedUsersResponse | undefined) => void
}

const AppContext = createContext<IAppContextType>({
  isAppLoading: false,
  primaryUserDetails: undefined,
  dropDownOptions: undefined,
  currentLocation: undefined,
  loginAsProfileDetails: undefined,
  currentLoginProfile: undefined,
  socket: undefined,
  unreadMessageCount: 0,
  connectedUser: undefined,
  setPrimaryUserDetails: (_user: ILoginUser | undefined) => undefined,
  setDropdownOptions: (_user: IDropDownOptionsResponse | undefined) => undefined,
  setCurrentLocation: (_currentLocation: ILocation | undefined) => undefined,
  setLoginAsProfileDetails: (_currentProfile: ILoginUser | undefined) => undefined,
  loadApp: async () => undefined,
  setCurrentLoginProfile: (_currentProfile: ICurrentLoginProfile) => undefined,
  setSocket: (_socket) => undefined,
  setUnreadMessageCount: (_count) => undefined,
  setConnectedUser: (_currentConnectedUserIConnectedUsersResponse) => undefined,
})

export default AppContext
