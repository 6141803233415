import React, { useContext, useState } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "../../assets/svg/DownArrow"
import PersonalInfo, { IPersonalInfoFormValues } from "./PersonalInfo"
import OtherInfo, { IOtherInfoFormValues } from "./OtherInfo"
import ProfessionalInfo, { IProfessionalInfoFormValues } from "./ProfessionalInfo"
import { errorToast } from "../../utils/ToastUtils"
import AppContext from "../../context/app-context/AppContext"
import moment from "moment"
import AgentProfessionalInfoOnBoarding, {
  IAgentProfessionalInfoFormValues,
} from "../agent/dashboard/AgentProfessionalInfoOnboarding"
import { useLoginUserAPI, IUpdateProfilePayload } from "../../services/useLoginUserAPI"
import { useUserHook } from "../../hooks/useUserHook"
import { useConfigAPI } from "../../services/useConfigAPI"
import { toast } from "react-toastify"

const Onboarding = () => {
  // Context
  const {
    loginAsProfileDetails,
    setLoginAsProfileDetails,
    setPrimaryUserDetails,
    primaryUserDetails,
  } = useContext(AppContext)

  // State
  const [step, setStep] = useState<number>(1)
  const [expanded, setExpanded] = useState<boolean>(true) // Initialize the Accordion as open
  const [geo, setGeo] = useState<any>(null)

  // APIs
  const { getUserDetails, getCurrentLoginProfileDetails, updateProfile, isLoading } =
    useLoginUserAPI()
  const { getUserCoordinates } = useConfigAPI()
  //hooks
  const { isAgent, isCoach } = useUserHook()

  const onSubmit = async (payload: IUpdateProfilePayload, nextStep: number) => {
    try {
      if (!loginAsProfileDetails) {
        errorToast("Something went wrong")
        return
      }

      const upr = await updateProfile(loginAsProfileDetails?.id, payload)
      if (upr) {
        setStep(nextStep)

        const currentLoginDtl = await getCurrentLoginProfileDetails()
        setLoginAsProfileDetails(currentLoginDtl?.data?.user)

        if (upr?.id === primaryUserDetails?.id) {
          const primaryUserDtl = await getUserDetails()
          setPrimaryUserDetails(primaryUserDtl?.data?.user)
        }
      }
    } catch {}
  }

  const handleStepOneSuccess = async (values: IPersonalInfoFormValues) => {
    try {
      const payload: IUpdateProfilePayload = {
        firstname: values?.firstName,
        lastname: values?.lastName,
        middlename: values?.middleName,
        dob: moment(values?.dob)?.format("YYYY-MM-DD"),
        gender: values?.gender,
      }

      await onSubmit(payload, 2)
    } catch {}
  }

  const handleStepTwoSuccess = async (values: IProfessionalInfoFormValues) => {
    try {
      const payload: IUpdateProfilePayload = {
        internationalEligibilities:
          !isCoach || values?.intlEligibility ? values?.intlEligibility?.join(", ") : "",
        nationality: values?.nationality?.join(", "),
        level: parseInt(values?.level),
        highestLevelPlayed: parseInt(values?.highestLevelPlayed),
        strengths: values?.speciality,
        seasonWishToPlay: values?.season ? values?.season : null,
        hearingSource: values?.hearAboutUs,
        // referralUserId: parseInt(values?.referralUserId),
        highestQualification: parseInt(values?.highestQualification),
        qualification: values?.qualification,
        region: values?.region.toString(),
        disciplines: values?.disciplines,
      }

      await onSubmit(payload, 3)
      window.scrollTo({ top: 0, behavior: "smooth" })
    } catch {}
  }
  const handleStepTwoAgentSuccess = async (values: IAgentProfessionalInfoFormValues) => {
    try {
      const payload: IUpdateProfilePayload = {
        nationality: values?.nationality?.join(", "),
        hearingSource: values?.hearAboutUs,
        disciplines: values?.disciplines,
      }

      await onSubmit(payload, 3)
    } catch {}
  }

  const handleStepThreeSubmit = async (values: IOtherInfoFormValues) => {
    try {
      const location = await getUserCoordinates(`${values.country} ${values.zipCode}`)
      setGeo({
        lat: Number(location?.[0]?.lat),
        lng: Number(location?.[0]?.lon),
      })
    } catch (error) {}
    try {
      const payload: IUpdateProfilePayload = {
        contactNumber: values?.contact,
        address: values?.address,
        city: values?.city,
        state: values?.state,
        country: values?.country,
        postCode: values?.zipCode,
        isOnboardingComplete: true,
        geo,
      }
      await onSubmit(payload, 3)
      toast.success("Onboarding completed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Auto close the toast after 3000ms (3 seconds)
      })
    } catch {}
  }

  const goToPreviousStep = () => {
    setStep((prevStep) => prevStep - 1) // Go back to the previous step
  }

  return (
    <div className=" w-full mb-4">
      <div className="">
        <Accordion
          className="h-full sm:px-4 "
          style={{
            borderRadius: "16px",
          }}
          expanded={expanded} // Use the state variable to control the open/close state
          onChange={(event, isExpanded) => setExpanded(isExpanded)} // Update the state when the Accordion is opened/closed
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className=" "
          >
            <div className="font-inter">
              <div className="text-xl font-bold font-inter">Complete your profile</div>
              <div className="mt-1 text-xs font-[400] text-gr-neutralT3 my-[-5px]">
                Complete your profile in just 3 simple steps!
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className=" mt-[-10px]">
            {step === 1 && (
              <PersonalInfo isSubmitting={isLoading} onSubmit={handleStepOneSuccess} />
            )}
            {step === 2 ? (
              isAgent ? (
                <AgentProfessionalInfoOnBoarding
                  isSubmitting={isLoading}
                  onSubmit={handleStepTwoAgentSuccess}
                  goToPreviousStep={goToPreviousStep}
                />
              ) : (
                <ProfessionalInfo
                  isSubmitting={isLoading}
                  onSubmit={handleStepTwoSuccess}
                  goToPreviousStep={goToPreviousStep}
                />
              )
            ) : null}
            {step === 3 && (
              <OtherInfo
                isSubmitting={isLoading}
                onSubmit={handleStepThreeSubmit}
                goToPreviousStep={goToPreviousStep}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

export default Onboarding
