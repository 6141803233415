import React, { useContext, useEffect, useRef, useState } from "react"
import { CircularProgress, Fade, Popper, Tooltip } from "@mui/material"
import { Link, useLocation, useSearchParams } from "react-router-dom"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import SearchIcon from "../../assets/svg/SearchIcon.svg"
import { useClubAPI } from "../../services/useClubAPI"
import { debounce } from "lodash"
// import { IGetClubResponse } from "../../modals/Club"
import { bindTrigger, bindPopper, usePopupState } from "material-ui-popup-state/hooks"
import ClickAwayListener from "@mui/base/ClickAwayListener"
import {
  AUTH_CLUB_PROFILE,
  AUTH_OPPORTUNITY_DETAIL,
  AUTH_SEARCH,
  AUTH_USER_PROFILE,
} from "../../routes/RouteConstants"
import { useMeilisearchHook } from "../../hooks/useMeilisearchHook"
import AppContext from "../../context/app-context/AppContext"
import { MultiSearchResponse, Hit } from "meilisearch"
import useLocationHook from "../../hooks/useLocationHook"
import { getNameInitials } from "../../utils/CommonUtils"
import { useUserHook } from "../../hooks/useUserHook"

const GlobalSearch = () => {
  // State
  // const [clubs, setClubs] = useState<IGetClubResponse>()
  const [searchParams, setSearchParams] = useSearchParams()
  const filterValue = searchParams.get("filterBy")
  const [searchKey, setSearchKey] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchQueries, setSearchQueries] = useState<any>()
  const { loginAsProfileDetails } = useContext(AppContext)
  const { email: loggedInUserEmail } = loginAsProfileDetails ?? {}
  const location = useLocation()
  const { pathname } = location
  const popupState = usePopupState({ variant: "popper", popupId: "demoPopper" })
  const [searchFrom, setSearchFrom] = useState<"followers" | "all" | "">("")
  const [searchResults, setSearchResults] = useState<Hit<Record<string, any>>[] | []>([])

  // APIs
  const { isLoading } = useClubAPI()

  const { results } = useMeilisearchHook({ queries: searchQueries })

  const getGeoSortString = useLocationHook().getGeoSortString
  //hooks
  const { isAgent } = useUserHook()

  useEffect(() => {
    const searchedResults = [
      ...(searchFrom === "followers" ? searchResults : []),
      ...((results as MultiSearchResponse<Record<string, any>>)?.results?.[0]?.hits || []),
      ...((results as MultiSearchResponse<Record<string, any>>)?.results?.[1]?.hits || []),
      ...((results as MultiSearchResponse<Record<string, any>>)?.results?.[2]?.hits || []),
    ]
    setSearchResults(searchedResults)
    searchFrom === "" ? setSearchFrom("followers") : setSearchFrom("all")
  }, [results])

  useEffect(() => {
    if (searchFrom === "followers" && searchResults?.length < 4) {
      if (searchKey !== "") {
        handleSearch(searchKey as string)
      }
    }
  }, [searchFrom])

  const handleSearch = (searchStr: string) => {
    if (pathname !== AUTH_SEARCH) {
      const query = isAgent
        ? [
            {
              indexUid: "user",
              q: searchStr,
              limit: 4,
              sort: getGeoSortString(),
              filter: [
                searchFrom === "followers"
                  ? `followers.email!='${loggedInUserEmail}'`
                  : `followers.email='${loggedInUserEmail}'`,
                `confirmed=true`,
                `blocked=false`,
              ],
            },
            {
              indexUid: "club",
              q: searchStr,
              limit: 4,
              sort: getGeoSortString(),
              filter: [
                searchFrom === "followers"
                  ? `followers.email!='${loggedInUserEmail}'`
                  : `followers.email='${loggedInUserEmail}'`,
              ],
            },
          ]
        : [
            {
              indexUid: "user",
              q: searchStr,
              limit: 4,
              sort: getGeoSortString(),
              filter: [
                searchFrom === "followers"
                  ? `followers.email!='${loggedInUserEmail}'`
                  : `followers.email='${loggedInUserEmail}'`,
                `confirmed=true`,
                `blocked=false`,
              ],
            },
            {
              indexUid: "club",
              q: searchStr,
              limit: 4,
              sort: getGeoSortString(),
              filter: [
                searchFrom === "followers"
                  ? `followers.email!='${loggedInUserEmail}'`
                  : `followers.email='${loggedInUserEmail}'`,
              ],
            },
            {
              indexUid: "opportunity",
              q: searchStr,
              limit: 4,
              sort: getGeoSortString(),
            },
          ]
      setSearchQueries(query)
    } else {
      if (filterValue) {
        setSearchParams({ search: searchStr, filterBy: filterValue })
      } else {
        setSearchParams({ search: searchStr })
      }
    }
  }

  useEffect(() => {
    if (searchKey !== "") {
      handleSearch(searchKey)
    }
  }, [searchKey])

  const debounceSearch = debounce((event) => {
    setSearchFrom("")
    setSearchKey(event.target.value)
  }, 1000)

  const handleClickAway = () => {
    popupState.close()
    setTimeout(() => {
      if (pathname !== AUTH_SEARCH) {
        setSearchKey("")
        if (inputRef.current) {
          inputRef.current.value = ""
        }
      }
    }, 100)
  }

  function getLinkUrl(content: any) {
    if (content?._meilisearch_id?.includes("opportunity")) {
      return `${AUTH_OPPORTUNITY_DETAIL}/${content?.id}`
    } else if (content.profileType === "Player") {
      return `${AUTH_USER_PROFILE}/${content.id}`
    } else if (content.profileType === "Parent") {
      return `${AUTH_USER_PROFILE}/${content.id}`
    } else if (content.profileType === "Agent") {
      return `${AUTH_USER_PROFILE}/${content.id}`
    } else if (content.profileType === "Coach") {
      return `${AUTH_USER_PROFILE}/${content.id}`
    } else if (content?._meilisearch_id.includes("club")) {
      return `${AUTH_CLUB_PROFILE}/${content.id}`
    } else {
      // Default fallback URL if type is unknown
      return "/"
    }
  }

  return (
    <div>
      <div className="relative  flex justify-center">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="rugbyplaceholder w-full">
            <input
              {...bindTrigger(popupState)}
              type="text"
              ref={inputRef}
              className=" border border-gray-300 font-normal w-full sm:rounded-lg rounded-2xl sm:py-2 py-[10px] px-3 pr-[60px] focus:outline-none focus:ring focus:border-blue-500 hover:border-gray-900"
              placeholder="Search..."
              onChange={(e) => {
                debounceSearch(e)
              }}
            />
            {isLoading ? (
              <div>
                <CircularProgress size={15} className="absolute right-1 top-[30%] w-1 h-1" />
              </div>
            ) : (
              <img
                src={SearchIcon}
                alt="Search"
                className="absolute right-4 top-1/2 transform -translate-y-1/2 w-4 h-4 cursor-pointer"
              />
            )}
          </div>
        </ClickAwayListener>
        {pathname !== AUTH_SEARCH && (
          <Popper {...bindPopper(popupState)} transition placement="bottom-start">
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <div className="bg-white rounded-[20px] max-w-[640px] sm:max-w-[360px] md:max-w-[365px] max-h-[350px] sm:max-h-[517px] overflow-x-auto pt-5 shadow-lg">
                  <div className="px-4 mb-4">
                    {isLoading ? (
                      <p className="text-center">Loading...</p>
                    ) : (
                      <>
                        {!searchResults?.length || !searchKey?.length ? (
                          <p className="text-center">No results</p>
                        ) : null}
                        {searchKey?.length
                          ? searchResults?.map((c: any, indx: number) => (
                              <div
                                key={indx}
                                className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5`}
                              >
                                <Link to={getLinkUrl(c)}>
                                  <div className="flex justify-start gap-4 items-center">
                                    {c?.profilePicture?.url ? (
                                      <div>
                                        <img
                                          src={c?.profilePicture?.url}
                                          alt="profile-image"
                                          className="rounded-full w-[45px] h-[45px]"
                                        />
                                      </div>
                                    ) : c?.firstname && c?.lastname ? (
                                      <div className="  flex justify-center items-center w-[45px] h-[45px] rounded-full bg-gr-primaryR1">
                                        <span className="text-white font-semibold  ">
                                          {getNameInitials(c?.firstname, c?.lastname)}
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        {/* Default image here */}
                                        <img
                                          src={c?.clubLogo?.url ? c?.clubLogo?.url : ClubLogo}
                                          alt="default-profile-image"
                                          className="rounded-full w-[45px] h-[45px]"
                                        />
                                      </div>
                                    )}

                                    <div>
                                      <div className="font-inter w-[220px] text-base text-gr-primaryR6 font-normal search-title">
                                        <Tooltip title={c?.name}>
                                          <div>
                                            {c?.name && (
                                              <div>
                                                {window.innerWidth <= 768 ? (
                                                  <div>
                                                    {c?.name.length > 15
                                                      ? `${c.name.slice(0, 15)}...`
                                                      : c?.name}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {c?.name.length > 35
                                                      ? `${c.name.slice(0, 35)}...`
                                                      : c?.name}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </Tooltip>
                                        {/* c?.name.length > 35
                                              ? `${c.name.slice(0, 35)}...`
                                              : c?.name} */}
                                      </div>
                                      <div className="font-inter max-w-[135] max-h-[19px] text-[14px] text-gr-primaryR7 font-normal ml-1 mt-2">
                                        {c?.profileType && c?.indexUid === "user" ? (
                                          <div className="flex gap-2">
                                            {c?.profileType}
                                            {c?.country && c?.name && (
                                              <div className="border-r border-gr-primaryR4"></div>
                                            )}
                                            {c?.country}
                                          </div>
                                        ) : c?._meilisearch_id.includes("club") ? (
                                          <div className="flex gap-2">
                                            Club
                                            {c?.country && (
                                              <div className="border-r border-gr-primaryR4"></div>
                                            )}
                                            {c?.country && <span>{c?.country}</span>}
                                          </div>
                                        ) : c?._meilisearch_id.includes("opportunity") ? (
                                          <div className="flex gap-2">
                                            Opportunity
                                            {c?.country && (
                                              <div className="border-r border-gr-primaryR4"></div>
                                            )}
                                            {c?.country && <span>{c?.country.slice(0, 9)}</span>}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))
                          : null}
                      </>
                    )}
                  </div>
                  <div className="md:w-[360px] sm:w-[354px] w-[325px] ml-1 border-t border-solid border-gr-primaryR5">
                    {" "}
                  </div>
                  <div className="flex justify-center items-center py-[15px] ">
                    <button className="font-inter text-gr-primaryR2 max-w-[116] max-h-[19px] text-base font-normal mb-1 pb-3">
                      <Link to={searchKey ? `${AUTH_SEARCH}?search=${searchKey}` : AUTH_SEARCH}>
                        See all results
                      </Link>
                    </button>
                  </div>
                </div>
              </Fade>
            )}
          </Popper>
        )}
      </div>
    </div>
  )
}

export default GlobalSearch
