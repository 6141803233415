import { IGetUserDetailsByIdResponse } from "../modals/User"
import useBaseAPI from "./useBaseAPI"

export const useUsersAPI = () => {
  const { GET, isLoading, PUT } = useBaseAPI()

  const getUserDetailsById = (userId: string): Promise<IGetUserDetailsByIdResponse> => {
    return GET(`/get-user-details/${userId}`, null, undefined, true)
  }

  const unfollowUser = (payload: IUnfollowUserPayload): Promise<any> => {
    return PUT(`/unfollow-user`, payload, undefined, true)
  }

  const isValidUserEmail = (email: string): Promise<any> => {
    return GET(`/valid-user/${email}`)
  }

  const updateEmail = (payload: IUpdateEmailPayload): Promise<any> => {
    return PUT(`/update-email`, payload)
  }
  const updateUserConfirmation = (payload: IUpdateEmailConfirmationPayload): Promise<any> => {
    return PUT(`/user-email-confirmation`, payload)
  }
  return {
    isLoading,
    getUserDetailsById,
    unfollowUser,
    isValidUserEmail,
    updateEmail,
    updateUserConfirmation,
  }
}

export interface IUnfollowUserPayload {
  data: {
    id: string
  }
}

export interface IUpdateEmailPayload {
  data: {
    email: string
  }
}

export interface IUpdateEmailConfirmationPayload {
  data: {
    confirmation: string
  }
}
