import React from "react"
import RegisterForm from "../components/forms/RegisterForm"
import GlobalRugbyLogo from "../assets/svg/GlobalRugbyLogo"
import SSOLogin from "../components/loginRegistration/SSOLogin"

const Register = () => {
  return (
    <div className="flex justify-center py-10 bg-bgRed">
      <div className="h-max bg-white flex flex-col items-center rounded-2xl px-[30px] sm:w-[570px]  sm:shadow-light sm:px-[60px] py-4">
        {/* logo */}

        <GlobalRugbyLogo className="w-16 sm:w-[76px]" />

        {/* heading */}
        <span className="text-xl sm:text-[32px] font-bold font-microsoftTai">
          Set Up Your Account
        </span>

        {/* form */}
        <RegisterForm />

        {/* continue with other options */}
        <SSOLogin isLogin={true} />
      </div>
    </div>
  )
}

export default Register
