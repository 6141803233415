import React, { useState, useContext, useEffect } from "react"
import GRTextfield from "../../ui/GRTextfield"
import PhotoIconDashBoard from "../../../assets/svg/PhotoIconDashboard"
import VideoIcon from "../../../assets/svg/VedioIconDashboard"
import { GRSwitch } from "../../ui/GRSwitch"
import AddPost from "../../dialogs/AddPostDialog"
import Post from "../../../components/posts/Post"
import { IPostSummary } from "../../../modals/Post"
import { usePostAPI } from "../../../services/usePostAPI"
import AppContext from "../../../context/app-context/AppContext"
import { useUserHook } from "../../../hooks/useUserHook"
import { getNameInitials } from "../../../utils/CommonUtils"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"
import { Link } from "react-router-dom"
import {
  AUTH_AGENT_DASHBOARD,
  AUTH_AGENT_PROFILE,
  AUTH_MY_PROFILE,
} from "../../../routes/RouteConstants"

function CreatePost() {
  const isAgentDashboard = location.pathname === AUTH_AGENT_DASHBOARD
  // Hook
  const { isLoginAsClub, getCurrentLoginUserPorfilePicUrl } = useUserHook()
  const [isShowAddPostDialog, setIsShowAddPostDialog] = useState<boolean>(false)

  const [isShowMyPost, setIsShowMyPost] = useState<boolean>(isLoginAsClub)
  //State
  const [posts, setPosts] = useState<IPostSummary[]>([])
  const [updatePosts, setUpdatePosts] = useState<IPostSummary[]>([])
  //APIs
  const { getPosts, deletePost } = usePostAPI()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  const fetchPosts = async () => {
    const res = await getPosts()
    await setPosts(res?.data)
    await setUpdatePosts(res?.data)
    if (isShowMyPost) {
      const myFilteredPosts = await filterPost(res?.data)
      await setPosts(myFilteredPosts)
    }
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  const filterPost = (posts: any[]) => {
    return isLoginAsClub
      ? posts.filter((p) => p?.club?.id == loginAsProfileDetails?.club?.id)
      : posts.filter((p) => p?.user?.id == loginAsProfileDetails?.id)
  }
  const myPost = async () => {
    try {
      if (!isShowMyPost) {
        const myFilteredPosts = await filterPost(posts)
        if (myFilteredPosts.length === 0) {
          // Show "No posts available" div when filtered posts are empty
          setIsShowMyPost(false)
          setPosts([])
        } else {
          setPosts(myFilteredPosts)
        }
      } else {
        setPosts(updatePosts)
      }
      setIsShowMyPost(!isShowMyPost)
    } catch (error) {}
  }

  const removePost = async (postId: any | undefined) => {
    try {
      if (!postId) return
      const deletePostResponse = await deletePost(postId)
      if (deletePostResponse?.data) {
        setPosts(posts.filter((p) => p?.id != deletePostResponse?.data?.id))
      }
    } catch (error) {}
  }

  return (
    <div className="w-full">
      <div className="py-3 bg-white mb-2 px-4 rounded-2xl">
        <div className="flex items-center gap-2">
          {getCurrentLoginUserPorfilePicUrl() ? (
            <Link to={isAgentDashboard ? AUTH_AGENT_PROFILE : AUTH_MY_PROFILE}>
              <img
                src={getCurrentLoginUserPorfilePicUrl()}
                alt="profile-image"
                className="w-[44px] h-[44px] min-w-[44px] rounded-full object-cover"
              />
            </Link>
          ) : isLoginAsClub ? (
            <Link to={isAgentDashboard ? AUTH_AGENT_PROFILE : AUTH_MY_PROFILE}>
              <img src={ClubLogo} alt="profile-image" className="w-[44px] h-[44px] rounded-full" />
            </Link>
          ) : (
            <div className="  flex justify-center items-center w-[44px] h-[44px] px-4  rounded-full bg-gr-primaryR1">
              <Link to={isAgentDashboard ? AUTH_AGENT_PROFILE : AUTH_MY_PROFILE}>
                <span className="text-white font-semibold  ">
                  {getNameInitials(
                    loginAsProfileDetails?.firstname,
                    loginAsProfileDetails?.lastname,
                  )}
                </span>
              </Link>
            </div>
          )}

          <div className="w-full ml-3" onClick={() => setIsShowAddPostDialog(true)}>
            <GRTextfield
              placeholder="Start Post"
              className="border border-gr-primaryN8 w-full bg-gr-background08 rounded-lg px-4 py-2"
            />
          </div>
        </div>
        <div className="flex justify-between sm:mt-4 mt-2 px-[75px]  font-inter font-semibold text-sm ">
          <div
            onClick={() => setIsShowAddPostDialog(true)}
            className="flex items-center sm:px-14 rounded-2xl py-1 hover:bg-bgRed "
          >
            <PhotoIconDashBoard />
            <button className="ml-1">Photo</button>
          </div>
          <div
            onClick={() => setIsShowAddPostDialog(true)}
            className="flex items-center sm:px-14 rounded-2xl py-1 hover:bg-bgRed  "
          >
            <VideoIcon />
            <button className="ml-1">Video</button>
          </div>
        </div>
      </div>
      {(posts && posts.length > 0) || (updatePosts && updatePosts.length > 0) ? (
        <div className="flex items-end mt-1 mb-1 pb-2 ml-6 sm:ml-0">
          <div className="font-inter text-[15px] font-semibold text-gr-primaryN1">
            Show only my posts
          </div>
          <div className="ml-[15px]">
            <GRSwitch checked={isShowMyPost} onChange={myPost} value={isShowMyPost} />
          </div>
        </div>
      ) : null}
      <div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            {posts && posts?.length > 0 ? (
              posts?.map((post) => {
                return (
                  <Post
                    onFetchPosts={() => fetchPosts()}
                    key={post?.id}
                    postData={post}
                    removePost={() => removePost(post?.id)}
                  />
                )
              })
            ) : (
              <div
                className="flex flex-col items-center gap-4 font-inter text-gray-500 justify-center  bg-white h-[400px]"
                style={{
                  borderRadius: "8px",
                }}
              >
                <p>No posts yet!</p>
                <p
                  className="underline cursor-pointer hover:text-gr-primaryR2"
                  onClick={() => setIsShowAddPostDialog(true)}
                >
                  Start sharing now!
                </p>
              </div>
            )}
          </div>
        </div>
        {/* Add post dialog */}
        <div>
          <AddPost
            onFetchPosts={() => fetchPosts()}
            isOpen={isShowAddPostDialog}
            onClose={() => setIsShowAddPostDialog(false)}
          />
        </div>
      </div>
    </div>
  )
}
export default CreatePost
