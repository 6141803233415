import React from "react"
import looseheadz from "../../assets/Looseheadz.jpg"
import { IPartner } from "../../services/useLoginUserAPI"
import CustomCarousel from "./CustomCarousel"

interface IPartnerValues {
  partners: IPartner | undefined
}

const Partners: React.FC<IPartnerValues> = ({ partners }) => {
  return (
    <div className="mb-12">
      <div className="m-b-h text-4xl font-bold text-center">{partners?.heading}</div>
      <div className="font-light text-lg text-center">{partners?.subHeading}</div>
      {partners?.companies && (
        <div className="">
          <CustomCarousel itemShow={3}>
            {partners &&
              partners?.companies?.map((partner) => (
                <>
                  <div className="mx-6 p-6 partner">
                    <img
                      className="w-auto mx-auto"
                      src={
                        partner?.logo?.formats?.thumbnail?.url
                          ? partner?.logo?.formats?.thumbnail?.url
                          : looseheadz
                      }
                    />
                  </div>
                </>
              ))}
          </CustomCarousel>
        </div>
      )}
    </div>
  )
}

export default Partners
