import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import Clubs from "../components/search/Clubs"
import People from "../components/search/People"
import FilterScreen from "../components/search/FilterScreen"
import { useSearchParams } from "react-router-dom"
import SearchedOpportunities from "../components/search/SearchedOpportunities"
import FilterIcon from "../assets/svg/FilterIcon"
import FilterPopup from "../components/search/FilterPopup"
import { useMeilisearchHook } from "../hooks/useMeilisearchHook"
import { MultiSearchQuery } from "meilisearch"
import { IUserDetailsSearch } from "../modals/User"
// import { IUserDetailsSearch } from "@App/modals/LoginUser"
import { IClubDetailsSearch } from "../modals/Club"
import AppContext from "../context/app-context/AppContext"
import useLocationHook from "../hooks/useLocationHook"
import { IState } from "../modals/Config"
import { useUserHook } from "../hooks/useUserHook"
import { useScreenWidth } from "../context/ScreenWidthContext"

function SearchScreen() {
  const { loginAsProfileDetails } = useContext(AppContext)
  const { email: loggedInUserEmail } = loginAsProfileDetails ?? {}
  const [searchParams, setSearchParams] = useSearchParams({ filterBy: "" })
  const [searchQuery, setSearchQuery] = useState<MultiSearchQuery[]>()
  const searchValue = searchParams.get("search")
  const filterValue = searchParams.get("filterBy")
  const [searchResults, setSearchResults] = useState<any>()
  const [open, setOpen] = useState<boolean>(false)
  const [states, setStates] = useState<IState[]>([])
  const ref = useRef<HTMLDivElement>(null)

  const [searchStringValue, setSearchStringValue] = useState<string | null>(searchValue)
  const [searchFilterValue, setSearchFilterValue] = useState<string>(filterValue ?? "")
  const [offsSet, setOffSet] = useState<number>(0)
  const [loadMoreAvailable, setLoadMoreAvailable] = useState<boolean>(false)
  const searchLimit = 4
  const [searchFrom, setSearchFrom] = useState<"followers" | "all">("followers")
  const isSearchFromAll = searchFrom === "all"
  const [checkedStateList, setCheckedStateList] = useState<string[]>([])

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  const filterList = useMemo(
    () => ({
      stateFilter: getFilterArray(checkedStateList, "state"),
      clubStateFilter: getFilterArray(checkedStateList, "club.state"),
    }),
    [checkedStateList],
  )
  //hooks
  const { isAgent } = useUserHook()

  const { results } = useMeilisearchHook({ queries: searchQuery })

  const { getGeoSortString, getCountryByGeoCoordinates, fetchStateByCountry } = useLocationHook()

  const fetchCountryByCoordinates = async () => {
    const country = await getCountryByGeoCoordinates()
    const states = await fetchStateByCountry(country)
    if (states) setStates(states)
  }
  useEffect(() => {
    fetchCountryByCoordinates()
  }, [])

  const handleSearch = () => {
    switch (filterValue) {
      case "people":
        setSearchQuery([
          {
            indexUid: "user",
            q: searchStringValue,
            limit: searchLimit,
            offset: offsSet,
            sort: getGeoSortString(),
            filter: [
              ...filterList.stateFilter,
              searchFrom === "followers"
                ? `followers.email='${loggedInUserEmail}'`
                : `followers.email!='${loggedInUserEmail}'`,
              `confirmed=true`,
              `blocked=false`,
            ],
          },
        ])
        break
      case "clubs":
        setSearchQuery([
          {
            indexUid: "club",
            q: searchStringValue,
            limit: searchLimit,
            offset: offsSet,
            sort: getGeoSortString(),
            filter: [
              ...filterList.stateFilter,
              searchFrom === "followers"
                ? `followers.email='${loggedInUserEmail}'`
                : `followers.email!='${loggedInUserEmail}'`,
            ],
          },
        ])
        break
      case "opportunities":
        setSearchQuery([
          {
            indexUid: "opportunity",
            q: searchStringValue,
            limit: searchLimit,
            offset: offsSet,
            sort: getGeoSortString(),
            filter: [...filterList.clubStateFilter],
          },
        ])
        break
      case null:
      case "":
        const query = isAgent
          ? [
              {
                indexUid: "user",
                q: searchStringValue,
                limit: isSearchFromAll ? searchLimit - searchResults?.users?.length : searchLimit,
                sort: getGeoSortString(),
                filter: [
                  ...filterList.stateFilter,
                  isSearchFromAll
                    ? `followers.email!='${loggedInUserEmail}'`
                    : `followers.email='${loggedInUserEmail}'`,
                  `confirmed=true`,
                  `blocked=false`,
                ],
              },
              {
                indexUid: "club",
                q: searchStringValue,
                limit: isSearchFromAll ? searchLimit - searchResults?.clubs?.length : searchLimit,
                sort: getGeoSortString(),
                filter: [
                  ...filterList.stateFilter,
                  isSearchFromAll
                    ? `followers.email!='${loggedInUserEmail}'`
                    : `followers.email='${loggedInUserEmail}'`,
                ],
              },
            ]
          : [
              {
                indexUid: "user",
                q: searchStringValue,
                limit: isSearchFromAll ? searchLimit - searchResults?.users?.length : searchLimit,
                sort: getGeoSortString(),
                filter: [
                  ...filterList.stateFilter,
                  isSearchFromAll
                    ? `followers.email!='${loggedInUserEmail}'`
                    : `followers.email='${loggedInUserEmail}'`,
                  `confirmed=true`,
                  `blocked=false`,
                ],
              },
              {
                indexUid: "club",
                q: searchStringValue,
                limit: isSearchFromAll ? searchLimit - searchResults?.clubs?.length : searchLimit,
                sort: getGeoSortString(),
                filter: [
                  ...filterList.stateFilter,
                  isSearchFromAll
                    ? `followers.email!='${loggedInUserEmail}'`
                    : `followers.email='${loggedInUserEmail}'`,
                ],
              },
              {
                indexUid: "opportunity",
                q: searchStringValue,
                limit: isSearchFromAll ? searchLimit : 0,
                sort: getGeoSortString(),
                filter: [...filterList.clubStateFilter],
              },
            ]
        setSearchQuery(query)
        break

      default:
        break
    }
  }

  useEffect(() => {
    setSearchResults([])
    setOffSet(0)
    setSearchFrom("followers")
    setSearchResults([])
    if (searchValue !== searchStringValue) {
      const searchParam = searchValue ? searchValue : searchStringValue
      searchParam && setSearchParams({ search: searchParam })
      setSearchStringValue(searchParam as string)
    }
    if (filterValue !== searchFilterValue) {
      setSearchFilterValue(filterValue as string)
    }
  }, [searchValue, filterValue, checkedStateList])

  useEffect(() => {
    handleSearch()
  }, [searchStringValue, searchFilterValue, offsSet, searchFrom])

  useEffect(() => {
    const totalHits = results?.results?.[0]?.estimatedTotalHits ?? 0
    switch (filterValue) {
      case "people":
      case "clubs":
        if (offsSet > 0 || (isSearchFromAll && offsSet === 0)) {
          setSearchResults([...searchResults, ...(results?.results?.[0]?.hits as any)])
        } else {
          setSearchResults(results?.results?.[0]?.hits)
        }
        // const totalHits = results?.results?.[0]?.estimatedTotalHits ?? 0
        if (totalHits > searchResults?.length + searchLimit || searchFrom === "followers") {
          if (totalHits <= searchResults?.length + searchLimit && searchFrom !== "all") {
            setSearchFrom("all")
            setOffSet(0)
          }
          setLoadMoreAvailable(true)
        } else {
          setLoadMoreAvailable(false)
        }
        break
      case "opportunities":
        if (offsSet > 0 || (isSearchFromAll && offsSet === 0)) {
          setSearchResults([...searchResults, ...(results?.results?.[0]?.hits as any)])
        } else {
          setSearchResults(results?.results?.[0]?.hits)
        }
        // const totalHits = results?.results?.[0]?.estimatedTotalHits ?? 0
        if (totalHits > searchResults?.length + searchLimit) {
          setLoadMoreAvailable(true)
        } else {
          setLoadMoreAvailable(false)
        }
        break
      case null:
      case "":
        const peopleResults = results?.results?.[0]?.hits
        const clubResults = results?.results?.[1]?.hits
        const opportunityResults = results?.results?.[2]?.hits
        setSearchResults({
          users: [...(searchResults?.users || []), ...(peopleResults || [])],
          clubs: [...(searchResults?.clubs || []), ...(clubResults || [])],
          opportunities: [...(searchResults?.opportunities || []), ...(opportunityResults || [])],
        })
        !isSearchFromAll && setTimeout(() => setSearchFrom("all"), 300)
        break

      default:
        break
    }
  }, [results])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleFetchMoreSearchData = () => {
    setOffSet((prev) => prev + searchLimit)
  }

  const renderView = () => {
    switch (searchParams.get("filterBy")) {
      case "people":
        return (
          <div className="w-full">
            {searchResults?.length > 0 ? (
              <People
                user={searchResults as IUserDetailsSearch[]}
                handleMore={handleFetchMoreSearchData}
                hasMore={loadMoreAvailable}
              />
            ) : (
              <div className="font-inter text-gray-500 text-[20px] sm:text-[24px] text-center mt-[20%] sm:mt-[20%] md:mt-[15%]">
                No match found.
              </div>
            )}
          </div>
        )

      case "clubs":
        return (
          <div className="w-full">
            {searchResults?.length > 0 ? (
              <Clubs
                club={searchResults as IClubDetailsSearch[]}
                handleMore={handleFetchMoreSearchData}
                hasMore={loadMoreAvailable}
              />
            ) : (
              <div className="font-inter text-gray-500 text-[20px] sm:text-[24px] text-center mt-[20%] sm:mt-[20%] md:mt-[15%]">
                No match found.
              </div>
            )}
          </div>
        )

      case "opportunities":
        return (
          <div className="w-full">
            {searchResults?.length > 0 ? (
              <SearchedOpportunities
                opportunity={searchResults}
                handleMore={handleFetchMoreSearchData}
                hasMore={loadMoreAvailable}
              />
            ) : (
              <div className="font-inter text-gray-500 text-[20px] sm:text-[24px] text-center mt-[20%] sm:mt-[20%] md:mt-[15%]">
                No match found.
              </div>
            )}
          </div>
        )

      default:
        return (
          <div>
            {searchResults?.users ? (
              <div className="w-full">
                <People
                  user={searchResults?.users as IUserDetailsSearch[]}
                  isShowSeeAllLink
                  handleSeeAll={() =>
                    setSearchParams((oldParams) => {
                      oldParams.set("filterBy", "people")
                      return oldParams
                    })
                  }
                />
              </div>
            ) : null}
            {searchResults?.clubs ? (
              <div className="w-full">
                <Clubs
                  club={searchResults?.clubs as IClubDetailsSearch[]}
                  isShowSeeAllLink
                  handleSeeAll={() =>
                    setSearchParams((oldParams) => {
                      oldParams.set("filterBy", "clubs")
                      return oldParams
                    })
                  }
                />
              </div>
            ) : null}
            {searchResults?.opportunities && !isAgent ? (
              <div className="w-full">
                <SearchedOpportunities opportunity={searchResults?.opportunities} />
              </div>
            ) : null}
          </div>
        )
    }
  }

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 650 && screenWidth < 1250

  return (
    <div className="">
      <HeaderSearchFeild />

      <div className={`flex px-4 md:px-6 sm:mt-8 mt-4 justify-between mb-8`}>
        <div
          className={`w-full ${
            isLargeScreen
              ? "flex-[25%] max-w-[25%]"
              : isMediumScreen
              ? "flex-[35%] max-w-[35%]"
              : "hidden"
          }`}
        >
          <FilterScreen
            states={states}
            checkedStateList={checkedStateList}
            setCheckedStateList={setCheckedStateList}
          />
        </div>
        <div
          className={`${
            isLargeScreen
              ? "flex-[73%] max-w-[73%]"
              : isMediumScreen
              ? "flex-[62%] max-w-[62%]"
              : "flex-[100%] max-w-[100%]"
          } flex flex-col`}
        >
          {/* headings in web view */}
          <div className="flex gap-[120px] mb-4">
            <div className=" flex items-center gap-5 text-gr-neutralT3 md:text-lg sm:text-base text-xs font-inter ">
              <div className=" text-black">Filter by:</div>
              <div className="flex items-center gap-[36px] font-bold">
                <div
                  className={`cursor-pointer hover:text-red-darkRed ${
                    searchParams.get("filterBy") === "" ? "text-red-darkRed" : "text-gr-neutralT3"
                  }`}
                >
                  <button
                    onClick={() =>
                      setSearchParams((oldParams) => {
                        oldParams.delete("filterBy")
                        return oldParams
                      })
                    }
                  >
                    All
                  </button>
                </div>
                <div
                  className={`cursor-pointer hover:text-red-darkRed ${
                    searchParams.get("filterBy") === "people"
                      ? "text-red-darkRed"
                      : "text-gr-neutralT3"
                  }`}
                >
                  <button
                    onClick={() =>
                      setSearchParams((oldParams) => {
                        oldParams.set("filterBy", "people")
                        return oldParams
                      })
                    }
                  >
                    People
                  </button>
                </div>
                <div
                  className={`cursor-pointer hover:text-red-darkRed ${
                    searchParams.get("filterBy") === "clubs"
                      ? "text-red-darkRed"
                      : "text-gr-neutralT3"
                  }`}
                >
                  <button
                    onClick={() =>
                      setSearchParams((oldParams) => {
                        oldParams.set("filterBy", "clubs")
                        return oldParams
                      })
                    }
                  >
                    Clubs
                  </button>
                </div>
                {!isAgent && (
                  <div
                    className={`cursor-pointer hover:text-red-darkRed ${
                      searchParams.get("filterBy") === "opportunities"
                        ? "text-red-darkRed"
                        : "text-gr-neutralT3"
                    }`}
                  >
                    <button
                      onClick={() =>
                        setSearchParams((oldParams) => {
                          oldParams.set("filterBy", "opportunities")
                          return oldParams
                        })
                      }
                    >
                      {" "}
                      Opportunities
                    </button>
                  </div>
                )}
                {/* <div className="cursor-pointer hover:text-red-darkRed">Posts</div> */}
              </div>
            </div>
          </div>

          {/* filter popup */}
          <div className="sm:hidden flex justify-end mb-4">
            <div ref={ref} onClick={() => setOpen(true)} className=" relative cursor-pointer  ">
              <div className="flex justify-end items-center gap-2 cursor-pointer sm:hidden">
                <div>
                  <FilterIcon />
                </div>
                <div className="font-inter text-[18px]">All Filters</div>
              </div>
              {open && (
                <div className="absolute right-4 top-10 z-[999]">
                  <FilterPopup />
                </div>
              )}
            </div>
          </div>
          {renderView()}
        </div>
      </div>
    </div>
  )
}

export default SearchScreen

const getFilterArray = (states: string[], filterName: string) => {
  return states?.map((state: string) => `${filterName}='${state}'`)
}
