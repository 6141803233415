import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={42}
      height={52}
      viewBox="0 0 144 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86.272 0A18 18 0 0199 5.272L138.728 45A17.998 17.998 0 01144 57.728V162c0 9.941-8.059 18-18 18H18c-9.941 0-18-8.059-18-18V18C0 8.059 8.059 0 18 0h68.272zM72 18H18v144h108V72H85.5c-7.017 0-12.784-5.354-13.438-12.2L72 58.5V18zm18.573 77.84a9 9 0 0117.166 5.316l-.312 1.004-12.657 33.753c-3.072 8.192-14.291 8.549-18.072 1.068l-.468-1.068L72 124.632l-4.23 11.281c-3.072 8.192-14.291 8.549-18.072 1.068l-.468-1.068-12.657-33.753a9 9 0 0116.429-7.281l.425.961 5.073 13.528 4.23-11.28c3.072-8.194 14.291-8.55 18.072-1.07l.468 1.07 4.23 11.28 5.073-13.528zM90 21.728V54h32.272L90 21.728z"
        fill="#09244B"
      />
    </svg>
  )
}

export default SvgComponent
