import { useSearchParams } from "react-router-dom"
import { useUserHook } from "../../../hooks/useUserHook"
import React, { useEffect } from "react"
import { IOpportunitySummary } from "../../../modals/Opportunity"

function ClubDetails({
  selectedSection,
  handleSectionClick,
  opportunitiesNo,
  memberNo,
}: {
  selectedSection: string | null
  handleSectionClick: (section: string) => void
  opportunitiesNo: IOpportunitySummary[] | undefined
  memberNo: number | undefined
}) {
  const { isAgent } = useUserHook()
  const [, setSearchParams] = useSearchParams({ tab: "" })
  const selectedSections = ["opportunities", "members", "about"]

  useEffect(() => {
    selectedSections.forEach((section, index) => {
      setTimeout(() => {
        handleSectionClick(section)
      }, index * 50)
    })
  }, [])

  return (
    <div className="flex flex-col gap-4 bg-white p-4 font-inter rounded-2xl md:mb-0 sm:mb-4">
      <p className="text-lg font-semibold font-inter">Club details</p>
      <div className="overflow-y-auto w-full ">
        <div className="flex md:flex-col md:justify-normal justify-between w-full md:gap-1 gap-8 text-[16px] font-medium md:pb-0 pb-3">
          <div
            className={`rounded py-3 px-3 h-12 hover:bg-gray-100 cursor-pointer  ${
              selectedSection === "about" ? "bg-[#E8C1C113]" : ""
            }`}
            onClick={() => {
              handleSectionClick("about")
              setSearchParams((oldParams) => {
                oldParams.delete("tab")
                return oldParams
              })
            }}
          >
            About
          </div>
          <div
            className={`rounded py-3 px-3 h-12 hover:bg-gray-100 cursor-pointer ${
              selectedSection === "posts" ? "bg-gray-100" : ""
            }`}
            onClick={() => {
              handleSectionClick("posts")
              setSearchParams((oldParams) => {
                oldParams.delete("tab")
                return oldParams
              })
            }}
          >
            Posts
          </div>
          {!isAgent && (
            <>
              <div
                className={`rounded py-3 px-3 h-12 hover:bg-gray-100 cursor-pointer ${
                  selectedSection === "opportunities" ? "bg-gray-100" : ""
                }`}
                onClick={() => {
                  handleSectionClick("opportunities")
                  setSearchParams((oldParams) => {
                    oldParams.delete("tab")
                    return oldParams
                  })
                }}
              >
                Opportunities{opportunitiesNo?.length ? `(${opportunitiesNo.length})` : ""}
              </div>

              <div
                className={`rounded py-3 px-3 h-12 hover:bg-gray-100 cursor-pointer ${
                  selectedSection === "members" ? "bg-gray-100" : ""
                }`}
                onClick={() => {
                  handleSectionClick("members")
                  setSearchParams((oldParams) => {
                    oldParams.delete("tab")
                    return oldParams
                  })
                }}
              >
                Members{memberNo ? `(${memberNo})` : ""}
              </div>
            </>
          )}

          <div
            className={`rounded py-3 px-3 hover:bg-gray-100 cursor-pointer ${
              selectedSection === "otherDetails" ? "bg-gray-100" : ""
            }`}
            onClick={() => {
              handleSectionClick("otherDetails")
              setSearchParams((oldParams) => {
                oldParams.delete("tab")
                return oldParams
              })
            }}
          >
            Contact&nbsp;details
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClubDetails
