import React, { useContext, useEffect, useState } from "react"
import { GRDialog } from "../../ui/GRDialog"
import GRButton from "../../ui/GRButton"
import { Form, Formik } from "formik"
import GRTextfield from "../../ui/GRTextfield"
import { Autocomplete, Box, Popover, TextField, Typography } from "@mui/material"
import GRSelect, { AutocompleteInputCss } from "../../ui/GRSelect"
import { genderOptions, CountryArray } from "../../../utils/Constants"
import GRDatePicker from "../../ui/GRDatePicker"
import DialogHeader from "../../../components/dialogs/DialogHeader"
import { convertIntoDropDownOptions } from "../../../utils/CommonUtils"
import AppContext from "../../../context/app-context/AppContext"
import { connectPlayerCoachOptions } from "../../../utils/Constants"
import useLocationHook from "../../../hooks/useLocationHook"
import { IRegisterUserPayload, useAuthAPI } from "../../../services/useAuthAPI"
import { registerFormValidationSchema as validationSchema } from "../../../validations/Validations"
import { INewUserConnectionPayload, useLoginUserAPI } from "../../../services/useLoginUserAPI"
import { successToast } from "../../../utils/ToastUtils"
import dayjs from "dayjs"
import Nationality from "../../../utils/Nationality.json"
import { useLoader } from "../../../context/LoaderContext"
import ExclamtionIcon from "../../../assets/svg/ExclamationIcon"
import CrossIcon from "../../../assets/svg/CrossIcon"

interface IAddPlayerCoachFormValues {
  password: string
  dob: string
  playingLevel: string | null
  discipline: string
  city: string
  country: string
  state: string
  email: string
  profileType: string
  firstName: string
  middleName: string
  lastName: string
  gender: string | null
  intlEligibility: string
  nationality: string
  level: string
  speciality: string
  higestLevelPlayed: string
  contact: string
  address: string
  zipCode: string
}
const AddPlayerCoachDialogInitialValues = {
  policy: true,
  password: `Random@${new Date()?.getTime()}`,
  dob: "",
  playingLevel: null,
  discipline: "",
  city: "",
  country: "",
  state: "",
  email: "",
  profileType: "",
  firstName: "",
  middleName: "",
  lastName: "",
  gender: null,
  intlEligibility: "",
  nationality: "",
  level: "",
  speciality: "",
  higestLevelPlayed: "",
  contact: "",
  address: "",
  zipCode: "",
}

interface IAddPlayerCoachDialog {
  isOpen: boolean
  onClose: () => void
}

const AddPlayerCoachDialog = (props: IAddPlayerCoachDialog) => {
  const { isOpen, onClose } = props

  const { isLoading, setIsLoading } = useLoader()

  // Context
  const { dropDownOptions, loginAsProfileDetails } = useContext(AppContext)

  //Hook
  const {
    countryOptions,
    stateOptions,
    cityOptions,
    fetchStateByCountry,
    fetchCityByStateAndCountry,
    fetchLocationData,
  } = useLocationHook()
  const { addNewUserConnection } = useLoginUserAPI()

  //API
  const { register } = useAuthAPI()

  // UseEffect
  useEffect(() => {
    if (loginAsProfileDetails) {
      fetchLocationData(loginAsProfileDetails?.country, loginAsProfileDetails?.state)
    }
  }, [loginAsProfileDetails])

  const handleSubmit = async (values: IAddPlayerCoachFormValues) => {
    try {
      const payload: IRegisterUserPayload = {
        password: values?.password,
        confirmed: true,
        username: values?.email,
        firstname: values?.firstName, // becz of this lower case in firstname not able to do ...values
        lastname: values?.lastName,
        dob: values?.dob ? dayjs(values?.dob)?.format("YYYY-MM-DD") : undefined,
        discipline: values?.discipline,
        city: values?.city,
        country: values?.country,
        state: values?.state,
        email: values?.email,
        profileType: values?.profileType,
        middlename: values?.middleName,
        gender: values?.gender,
        intlEligibility: values?.intlEligibility,
        nationality: values?.nationality.toString(),
        level: values?.playingLevel,
        speciality: values?.speciality,
        higestLevelPlayed: values?.higestLevelPlayed,
        contact: values?.contact,
        address: values?.address,
        zipCode: values?.zipCode,
      }

      setIsLoading(true)
      const registerResponse = await register(payload)
      if (registerResponse?.user?.id) {
        const payload: INewUserConnectionPayload = {
          data: {
            newUserId: registerResponse?.user?.id,
          },
        }
        setIsLoading(true)
        await addNewUserConnection(payload)
        successToast("Mail sent to new connection!!")
        setIsLoading(false)
        onClose()
      }
    } catch (err: any) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  const [messagePopoverAnchorEl, setMessagePopoverAnchorEl] = useState<null | HTMLElement>(null)
  const [isNotState, setIsNotState] = useState<boolean>(false)
  // Handler function to toggle the message
  const toggleMessagePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagePopoverAnchorEl(messagePopoverAnchorEl ? null : event.currentTarget)
  }

  const body = (
    <>
      <Formik
        initialValues={AddPlayerCoachDialogInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, handleChange, handleBlur }) => {
          return (
            <Form className="font-inter">
              <div className="sm:mx-[32px] mx-[13px] mb-8 compeleteProfileForm">
                {/* personal info */}
                <p className="text-[18px] font-semibold mb-4">Personal info</p>
                <div className="flex -mt-2 mb-2">
                  <span className="text-red-600 text-lg -mt-2">*</span>
                  <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5  ">
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="profileType">
                      Profile type<span className="text-red-600">*</span>
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="profileType"
                        defaultValue=""
                        name="profileType"
                        placeholder="Select profile type"
                        onChange={handleChange}
                        value={values.profileType}
                        error={touched.profileType && Boolean(errors.profileType)}
                        options={connectPlayerCoachOptions}
                        errormsg={
                          touched.profileType && Boolean(errors.profileType)
                            ? errors.profileType
                            : ""
                        }
                      />
                    </Box>
                  </div>
                  <div className="w-full mb-4 ">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="firstName">
                      First name<span className="text-red-600">*</span>
                    </label>

                    <GRTextfield
                      id="firstName"
                      type="text"
                      placeholder="Enter first name"
                      height="48px"
                      width="100%"
                      name="firstName"
                      onChange={handleChange}
                      value={values?.firstName}
                      error={touched?.firstName && Boolean(errors?.firstName)}
                      helperText={<span> {touched?.firstName && errors?.firstName}</span>}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="middleName">
                      Middle name
                    </label>

                    <GRTextfield
                      id="middleName"
                      type="text"
                      placeholder="Enter middle name"
                      width="100%"
                      height="48px"
                      name="middleName"
                      onChange={handleChange}
                      value={values.middleName}
                      error={touched.middleName && Boolean(errors.middleName)}
                      helperText={<span> {touched.middleName && errors.middleName}</span>}
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="lastName">
                      Last name<span className="text-red-600">*</span>
                    </label>

                    <GRTextfield
                      id="lastName"
                      type="text"
                      width="100%"
                      placeholder="Enter last name"
                      height="48px"
                      name="lastName"
                      onChange={handleChange}
                      value={values.lastName}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={<span> {touched.lastName && errors.lastName}</span>}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="firstName">
                      Email<span className="text-red-600">*</span>
                    </label>

                    <GRTextfield
                      id="email"
                      type="email"
                      width="100%"
                      placeholder="Enter email"
                      height="48px"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      error={touched.email && Boolean(errors.email)}
                      helperText={<span> {touched.email && errors.email}</span>}
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter">
                      Date of birth<span className="text-red-600">*</span>
                    </label>

                    <GRDatePicker
                      className="w-full"
                      height="40px"
                      error={Boolean(touched.dob) && Boolean(errors.dob)}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.dob) && Boolean(errors.dob),
                          helperText:
                            touched.dob &&
                            (errors?.dob?.substring(0, 20) === "Start Date must be a `date`" ? (
                              <span className="text-[13px] text-red-500 font-inter">
                                Invalid Date*
                              </span>
                            ) : (
                              <span className="text-[13px] text-red-500 font-inter ml-3">
                                {errors.dob}
                              </span>
                            )),
                        },
                      }}
                      format="DD/MM/YYYY"
                      value={dayjs(values.dob)}
                      onChange={(value) => setFieldValue("dob", value, true)}
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="gender">
                      Gender
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="gender"
                        defaultValue=""
                        placeholder="Select gender"
                        name="gender"
                        onChange={handleChange}
                        value={values.gender}
                        error={touched.gender && Boolean(errors.gender)}
                        options={genderOptions}
                        errormsg={touched.gender && Boolean(errors.gender) ? errors.gender : ""}
                      />
                    </Box>
                  </div>
                </div>
                <p className="text-[18px] font-semibold mb-4 mt-2">Professional info</p>
                {/* International Eligibility and Nationality */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  {/* International Eligibility */}

                  <div className="w-full mb-4">
                    <label
                      className="text-sm mb-1 flex justify-between font-inter"
                      htmlFor="intlEligibility"
                    >
                      International Eligibility
                      <div className="mt-1 cursor-pointer" onClick={toggleMessagePopover}>
                        <ExclamtionIcon />
                      </div>
                      <Popover
                        open={Boolean(messagePopoverAnchorEl)}
                        anchorEl={messagePopoverAnchorEl}
                        onClose={() => setMessagePopoverAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Typography sx={{ p: 2 }}>
                          <div className="flex gap-2">
                            <span>
                              <a
                                className="font-bold text-red-600"
                                href="https://www.world.rugby/organisation/governance/regulations/reg-8"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                Link to{" "}
                              </a>
                              World Rugby International Eligibility Rules.
                            </span>
                            <div className="mt-1 cursor-pointer">
                              <CrossIcon onClick={() => setMessagePopoverAnchorEl(null)} />
                            </div>
                          </div>
                        </Typography>
                      </Popover>
                    </label>

                    <div className="mb-3">
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        renderTags={(value) => {
                          const numTags = value.length
                          return (
                            <span className="max-w-[60%] truncate text-sm ">
                              {numTags > 1 && ` ${numTags - 1} more +`}
                              {value
                                .slice(0, 1)
                                .map((option) => option)
                                .join(", ")}
                            </span>
                          )
                        }}
                        id="intlEligibility"
                        onChange={(_event, newValue) => {
                          handleChange(_event)
                          setFieldValue("intlEligibility", newValue || "")
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                            {option}
                          </li>
                        )}
                        onBlur={handleBlur}
                        value={
                          Array.isArray(values.intlEligibility)
                            ? values.intlEligibility
                            : [values.intlEligibility]
                        }
                        options={countryOptions?.map((c) => c?.name) || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="International eligibilities"
                            sx={AutocompleteInputCss}
                          />
                        )}
                      />
                      {touched?.intlEligibility && errors?.intlEligibility && (
                        <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                          {errors?.intlEligibility}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*  Nationality*/}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="nationality">
                      Nationality
                    </label>

                    <div className="mb-3">
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        renderTags={(value) => {
                          const numTags = value.length
                          return (
                            <span className="max-w-[60%] truncate text-sm">
                              {numTags > 1 && ` ${numTags - 1} more +`}
                              {value
                                .slice(0, 1)
                                .map((option) => option)
                                .join(", ")}
                            </span>
                          )
                        }}
                        id="nationality"
                        onChange={(_event, newValue) => {
                          handleChange(_event)
                          setFieldValue("nationality", newValue || "")
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                            {option}
                          </li>
                        )}
                        onBlur={handleBlur}
                        value={
                          Array.isArray(values.nationality)
                            ? values.nationality
                            : [values.nationality]
                        }
                        options={
                          Nationality?.sort((a, b) =>
                            a.nationality > b.nationality ? 1 : -1,
                          )?.map((c) => c?.nationality) || []
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Nationality"
                            sx={AutocompleteInputCss}
                          />
                        )}
                      />
                      {touched?.nationality && errors?.nationality && (
                        <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                          {errors?.nationality}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  {/* Level */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="level">
                      Level
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="playingLevel"
                        defaultValue=""
                        name="playingLevel"
                        placeholder="Select level"
                        onChange={handleChange}
                        value={values.playingLevel}
                        error={touched.playingLevel && Boolean(errors.playingLevel)}
                        className={
                          touched.playingLevel && Boolean(errors.playingLevel)
                            ? "!border border-lightRed "
                            : ""
                        }
                        options={convertIntoDropDownOptions(dropDownOptions?.level)}
                        errormsg={
                          touched.playingLevel && errors.playingLevel ? errors.playingLevel : ""
                        }
                      />
                    </Box>
                  </div>

                  {/* Higest Level Played */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="higestLevelPlayed">
                      Highest Level Played
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="higestLevelPlayed"
                        defaultValue=""
                        name="higestLevelPlayed"
                        placeholder="Highest level played"
                        onChange={handleChange}
                        value={values.higestLevelPlayed}
                        options={convertIntoDropDownOptions(dropDownOptions?.level)}
                      />
                    </Box>
                  </div>
                </div>
                {/* Specialties/Strengths and  Select the season for when you want the opportunities.  */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  {/* Specialties/Strengths */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="speciality">
                      Specialities/Strengths
                    </label>
                    <GRTextfield
                      id="speciality"
                      type="text"
                      placeholder="Enter Specialities/Strengths"
                      height="48px"
                      width="100%"
                      name="speciality"
                      onChange={handleChange}
                      value={values.speciality}
                    />
                  </div>

                  <div className="sm:w-[350px] w-full mb-4">
                    <label
                      className="cursor-pointer block  text-[14px]  leading-6 font-[400]"
                      htmlFor="discipline"
                    >
                      Code
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="discipline"
                        defaultValue=""
                        name="discipline"
                        placeholder="Select code"
                        onChange={handleChange}
                        value={values.discipline}
                        options={convertIntoDropDownOptions(dropDownOptions?.discipline)}
                        className="sm:w-[340px]"
                      />
                    </Box>
                  </div>
                </div>
                {/* Other info */}
                <p className="text-[18px] font-semibold mb-4 mt-2">Other info</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  {/* ContactNumber */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="contact">
                      Contact number
                    </label>

                    <GRTextfield
                      id="contact"
                      type="text"
                      placeholder="Enter contact number"
                      height="48px"
                      name="contact"
                      width="100%"
                      onChange={handleChange}
                      value={values.contact}
                      error={touched.contact && Boolean(errors.contact)}
                      helperText={touched.contact && errors.contact}
                    />
                  </div>
                  {/* Email Address */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="address">
                      Address
                    </label>
                    <GRTextfield
                      id="address"
                      type="address"
                      placeholder="Enter address"
                      name="address"
                      height="48px"
                      width="100%"
                      onChange={handleChange}
                      value={values.address}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5">
                  {/* Country */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="country">
                      Country
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <Autocomplete
                        id="country"
                        onChange={(_event, newValue) => {
                          handleChange(_event)
                          setFieldValue("country", newValue || "")
                          setFieldValue("state", "")
                          setFieldValue("city", "")
                          if (newValue) {
                            if (CountryArray?.includes(newValue)) {
                              fetchCityByStateAndCountry(newValue, "")
                              setIsNotState(true)
                            } else {
                              fetchStateByCountry(newValue)
                              setIsNotState(false)
                            }
                          }
                        }}
                        renderOption={(params, option) => <li {...params}>{option}</li>}
                        onBlur={handleBlur}
                        value={values?.country}
                        options={countryOptions?.map((c) => c?.name) || []}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Country" sx={AutocompleteInputCss} />
                        )}
                      />
                    </Box>
                  </div>
                  {/* State */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="state">
                      State
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <Autocomplete
                        id="state"
                        onChange={(_event, newValue) => {
                          handleChange(_event)
                          setFieldValue("state", newValue || "")
                          setFieldValue("city", "")
                          if (newValue) {
                            fetchCityByStateAndCountry(values.country, newValue)
                          }
                        }}
                        renderOption={(params, option) => <li {...params}>{option}</li>}
                        onBlur={handleBlur}
                        value={values?.state}
                        readOnly={CountryArray?.includes(values.country) ? true : false}
                        options={stateOptions?.map((s) => s?.name) || []}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="State" sx={AutocompleteInputCss} />
                        )}
                      />
                      {isNotState && (
                        <div className="text-[13px] text-blue-600 lowercase first-letter:uppercase !font-inter">
                          The country has no state so you can select a city
                        </div>
                      )}
                    </Box>
                  </div>
                  {/* City */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="city">
                      City
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <Autocomplete
                        id="city"
                        onChange={(_event, newValue) => {
                          handleChange(_event)
                          setFieldValue("city", newValue || "")
                        }}
                        renderOption={(params, option) => <li {...params}>{option}</li>}
                        onBlur={handleBlur}
                        value={values?.city}
                        options={cityOptions?.map((c: { name: any }) => c?.name) || []}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="City" sx={AutocompleteInputCss} />
                        )}
                      />
                    </Box>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  {/* Post/Zip Code */}
                  <div className="w-full mb-4">
                    <label className="text-sm  mb-1 block font-inter" htmlFor="zipCode">
                      Post/Zip code
                    </label>

                    <GRTextfield
                      id="zipCode"
                      type="text"
                      width="100%"
                      placeholder="Enter Postcode"
                      height="48px"
                      name="zipCode"
                      onChange={handleChange}
                      value={values.zipCode}
                      error={touched.zipCode && Boolean(errors.zipCode)}
                      helperText={touched.zipCode && errors.zipCode}
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <GRButton label="Cancel" className="w-[100px]" onClick={onClose} />
                  <GRButton label="Save" className="w-[100px]" isSubmitting={isLoading} />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )

  return (
    <div>
      <GRDialog
        open={isOpen}
        onClose={() => onClose()}
        dialogtitle={<DialogHeader onClose={onClose} title="Add your Player / Coach" />}
        dialogbody={body}
        fullWidth
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
      />
    </div>
  )
}

export default AddPlayerCoachDialog
