import { IPostSummaryResponse, IGetPostsResponse } from "../modals/Post"
import useBaseAPI from "./useBaseAPI"

export const usePostAPI = () => {
  const { POST, GET, PUT, DELETE, isLoading } = useBaseAPI()
  const createPost = (payload: IPostPayload): Promise<IPostSummaryResponse> => {
    return POST(`/create-post`, payload)
  }
  const updatePost = (payload: IUpdatePostPayload): Promise<IPostSummaryResponse> => {
    return PUT(`/update-post`, payload)
  }
  const getPostByClubId = (clubId: number): Promise<IGetPostsResponse> => {
    return GET(`/get-post-by-club/${clubId}`)
  }
  const getPostByUser = (): Promise<IPostSummaryResponse> => {
    return GET("/get-post-by-user")
  }
  const deletePost = (id: number): Promise<IPostSummaryResponse> => {
    return DELETE(`/delete-post/${id}`)
  }
  const postHideByUser = (payload: IUpdatePostByUser): Promise<IPostSummaryResponse> => {
    return PUT(`/hide-post-by-user`, payload)
  }
  const postHideByClub = (payload: IUpdatePostByClub): Promise<IPostSummaryResponse> => {
    return PUT(`/hide-post-by-club`, payload)
  }
  const postLikeByUser = (payload: IUpdatePostByUser): Promise<IPostSummaryResponse> => {
    return PUT(`/like-post-by-user`, payload, undefined, true)
  }
  const postLikeByClub = (payload: IUpdatePostByClub): Promise<IPostSummaryResponse> => {
    return PUT(`/like-post-by-club`, payload, undefined, true)
  }
  const postUnlikeByUser = (payload: IUpdatePostByUser): Promise<IPostSummaryResponse> => {
    return PUT(`/unlike-post-by-user`, payload, undefined, true)
  }
  const postUnlikeByClub = (payload: IUpdatePostByClub): Promise<IPostSummaryResponse> => {
    return PUT(`/unlike-post-by-club`, payload, undefined, true)
  }
  const getPosts = (): Promise<IGetPostsResponse> => {
    return GET(`/get-all-post`)
  }
  const previewLink = (url: string): Promise<IPreViewLink> => {
    return GET(`/preview-link?url=${url}`)
  }

  return {
    isLoading,
    createPost,
    updatePost,
    getPostByClubId,
    getPostByUser,
    deletePost,
    getPosts,
    postHideByClub,
    postHideByUser,
    postLikeByUser,
    postLikeByClub,
    postUnlikeByUser,
    postUnlikeByClub,
    previewLink,
  }
}

interface IPostRequestPayload {
  description: string
  media: number
  club: number | null
  preViewUrl: string | null
}
interface IUpdatePostRequestPayload {
  id: number
  description: string
  media: number
  club: number | null
}
interface IUpdatePostRequestByClub {
  id: number
  clubId: number
}

interface IUpdatePostRequestByUser {
  id: number
}

export interface IUpdatePostByClub {
  data: IUpdatePostRequestByClub
}

export interface IUpdatePostByUser {
  data: IUpdatePostRequestByUser
}
export interface IUpdatePostPayload {
  data: IUpdatePostRequestPayload
}
export interface IPostPayload {
  data: IPostRequestPayload
}
export interface IPreViewLink {
  data: IPreView
}
export interface IPreView {
  url: string
  title: string
  favicon: string
  description: string
  image: string
  author: string
}
