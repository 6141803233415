import React, { useState } from "react"
import EditBtn from "../../../assets/svg/EditBtn"
import EditAboutDialog from "./EditAboutDialog"
import { IClubDetails } from "@App/modals/Club"

interface IAboutProps {
  clubData?: IClubDetails
  isAdmin: boolean | undefined
  onSuccess: () => void
}

function About(props: IAboutProps) {
  // Props
  const { clubData, isAdmin, onSuccess } = props

  // State
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)

  return (
    <div className="  md:h-auto  px-4 py-[30px] rounded-2xl bg-white min-h-[332px]">
      <div className="flex justify-between items-center">
        <div className="font-inter sm:text-2xl text-xl font-semibold">Club description</div>
        <EditAboutDialog
          clubData={clubData}
          isShowDialog={isShowDialog}
          onClose={() => setIsShowDialog(false)}
          onSucess={() => {
            setIsShowDialog(false)
            onSuccess()
          }}
        />

        {isAdmin && (
          <div className="cursor-pointer" onClick={() => setIsShowDialog(true)}>
            <EditBtn />
          </div>
        )}
      </div>
      <div className="border border-gray-200 mt-4 mb-5"></div>
      <div className="flex flex-col px-2 md:pl-4">
        <div className="font-inter font-semibold mb-2">About</div>
        <div className="font-inter text-gr-neutralT3 mb-2">
          {clubData?.attributes?.description || <p className="">No content yet</p>}
        </div>
        <div className="font-inter font-semibold mb-2">Code</div>
      </div>
      <div className="flex flex-row justify-between flex-wrap md:px-4 px-2">
        {clubData?.attributes?.disciplines?.map((d, indx) => (
          <Card key={indx} data={d.title} />
        ))}
      </div>
    </div>
  )
}
interface IGRCardProps {
  data?: string[] | string
}
const Card: React.FC<IGRCardProps> = (props: IGRCardProps) => {
  return (
    <div className="border border-gr-primaryR4 md:w-[23%] sm:w-[32%] w-[48%] h-[65px] rounded-[10px] flex flex-col gap-1 justify-center items-center mb-3">
      <div className="font-inter text-[16px] text-gr-neutralT3">{props.data}</div>
    </div>
  )
}

export default About
