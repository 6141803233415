import React, { useContext } from "react"
import StickyBox from "react-sticky-box"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import Onboarding from "../components/onboardingFlow/Onboarding"
import UserProfile from "../components/dashboard/UserProfile"
import ClubsRecords from "../components/clubsRecords/ClubsRecords"
import FamilyMembers from "../components/familyMembers/FamilyMembers"
import AppContext from "../context/app-context/AppContext"
import CreatePost from "../components/club/dashboard/CreatePost"
import InviteAndReferral from "../components/playerProfile/InviteAndReferral"
import Opportunities from "../components/dashboard/Opportunities/Opportunities"
import { useScreenWidth } from "../context/ScreenWidthContext"

function PlayerDashboard() {
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 650 && screenWidth < 1250
  //  const isSmallScreen = screenWidth >=320 && screenWidth < 651

  return (
    <div className={`w-full`}>
      <div className="w-full bg-bgred">
        <HeaderSearchFeild />
      </div>
      <div className="w-full bg-bgred">
        <div>
          <div
            className={`flex flex-col md:flex sm:flex-row sm:justify-between md:mt-8 sm:mt-[30px] px-4 md:px-6 mb-6`}
          >
            {/* left part (25%) */}
            {(isLargeScreen || isMediumScreen) && (
              <StickyBox
                offsetTop={20}
                offsetBottom={20}
                style={{
                  flex: isMediumScreen ? "35%" : "25%",
                  maxWidth: isMediumScreen ? "35%" : "25%",
                  height: "100%",
                }}
              >
                <div className={`lg:flex lg:flex-col lg:gap-[20px]`}>
                  <div>
                    <UserProfile />
                    <div className="mt-4 mb-4">
                      <ClubsRecords />
                    </div>
                    <FamilyMembers />
                  </div>
                </div>
              </StickyBox>
            )}

            {/* center part (45%) */}
            <div
              className="flex flex-col items-center"
              style={{
                flex: isLargeScreen ? "47%" : isMediumScreen ? "62%" : "100%",
                maxWidth: isLargeScreen ? "47%" : isMediumScreen ? "62%" : "100%",
              }}
            >
              {loginAsProfileDetails && !loginAsProfileDetails?.isOnboardingComplete && (
                <Onboarding />
              )}
              <CreatePost />
            </div>

            {/* right part (25%) - Conditional rendering based on screen width */}
            {isLargeScreen && (
              <StickyBox
                offsetTop={20}
                offsetBottom={200}
                style={{
                  flex: isMediumScreen ? "33%" : "25%",
                  maxWidth: isMediumScreen ? "33%" : "25%",
                  height: "100%",
                }}
              >
                <div className="hidden lg:flex lg:flex-col lg:gap-[20px]">
                  <div>
                    <Opportunities />
                    <div className="mt-4">
                      <InviteAndReferral />
                    </div>
                  </div>
                </div>
              </StickyBox>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayerDashboard
