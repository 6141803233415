import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "../../../assets/svg/DownArrow"
import FilterIcon from "../../../assets/svg/FilterIcon"
import { Checkbox } from "@mui/material"

const ClubFilterAccordian = () => {
  return (
    <div>
      <Accordion style={{ borderRadius: "16px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={"span"}>
            <div className="flex items-center justify-end gap-[10px]">
              <div className="text-blue-400">
                <FilterIcon />
              </div>
              <div className="font-inter text-gr-neutralT2  ">All Filter</div>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"}>
            <div className="font-inter text-sm pl-2">
              <div className="flex items-center gap-[14px]">
                <div>
                  <Checkbox />
                </div>
                <div>All</div>
              </div>
              <div className="flex items-center gap-[14px]">
                <div>
                  <Checkbox />
                </div>
                <div>Active</div>
              </div>
              <div className="flex items-center gap-[14px]">
                <div>
                  <Checkbox />
                </div>
                <div>Inactive</div>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ClubFilterAccordian
