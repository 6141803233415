import React from "react"
import { List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material"

interface IFilterProps {
  allChecked?: boolean
  options: { name: string; value: string }[]
  isShowAll: boolean
  checkedStateList: string[]
  setCheckedStateList: React.Dispatch<React.SetStateAction<string[]>>
}

const SearchScreenFilterOptions: React.FC<IFilterProps> = (props) => {
  const { options, isShowAll, checkedStateList, setCheckedStateList } = props

  const handleToggle = (value: string) => () => {
    const currentIndex = checkedStateList.indexOf(value)
    const newChecked = [...checkedStateList]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedStateList(newChecked)
  }

  const visibleOptions = isShowAll ? options : options.slice(0, 5) // Show all options if showAll is true

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {visibleOptions.map((option) => {
        const { value, name } = option
        const labelId = `checkbox-list-label-${value}`

        return (
          <ListItem key={value} disablePadding className="filterScroller ">
            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checkedStateList.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  style={{
                    color: checkedStateList.indexOf(value) !== -1 ? "#9F0002" : undefined,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={` ${name}`} />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

export default SearchScreenFilterOptions
