import React, { useContext } from "react"
import GRTextfield from "../ui/GRTextfield"
import { useFormik } from "formik"
import { Box } from "@mui/material"
import GRSelect from "../ui/GRSelect"
import GRButton from "../ui/GRButton"
import RightArrow from "../../assets/svg/RightArrow"
import GRDatePicker from "../ui/GRDatePicker"
import { genderOptions } from "../../utils/Constants"
import { personalInfoOnboardingValidationSchema } from "../../validations/Validations"
import AppContext from "../../context/app-context/AppContext"
import dayjs from "dayjs"

export interface IPersonalInfoFormValues {
  firstName: string
  middleName: string
  lastName: string
  dob: string
  gender: string
  email: string
  readOnly?: boolean
}

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  gender: "",
  email: "",
}

type IPersonalInfoProps = {
  onSubmit: (values: IPersonalInfoFormValues) => void
  isSubmitting: boolean
}

const PersonalInfo: React.FC<IPersonalInfoProps> = ({ onSubmit, isSubmitting }) => {
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      firstName: loginAsProfileDetails?.firstname || "",
      lastName: loginAsProfileDetails?.lastname || "",
      middleName: loginAsProfileDetails?.middlename || "",
      dob: loginAsProfileDetails?.dob || "",
      gender: loginAsProfileDetails?.gender || "",
      email: loginAsProfileDetails?.isChild
        ? `${loginAsProfileDetails?.firstname}@gr.com`
        : loginAsProfileDetails?.email || "",
    },
    onSubmit: (values) => onSubmit(values),
    validationSchema: personalInfoOnboardingValidationSchema(loginAsProfileDetails),
    enableReinitialize: true,
  })

  return (
    <div className="flex flex-col gap-4 border-t border-gr-primaryN8  bg-white  mt-[-20px ]  font-inter">
      {/* heading- Personal Info */}
      <div className="flex justify-between items-center font-bold">
        <div className="text-lg  text-gr-primaryN1 mt-2 font-inter">Personal info</div>
        <div>
          1<span className="text-gray-500">/3</span>
        </div>
      </div>
      <div className="flex -mt-3">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>
      {/* form */}
      <div className=" ">
        <form onSubmit={formik.handleSubmit} className="flex flex-col compeleteProfileForm">
          <div className="flex flex-col">
            {/* firstName and middleName */}
            <div className="flex flex-col gap-x-8 items-center sm:flex-row sm:items-start sm:justify-between">
              {/* FirstName */}
              <div className="w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="firstName"
                >
                  First name<span className="text-red-600">*</span>
                </label>

                <GRTextfield
                  id="firstName"
                  placeholder="Enter first name"
                  type="text"
                  height="40px"
                  width="100%"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={<span> {formik.touched.firstName && formik.errors.firstName}</span>}
                />
              </div>
              {/* MiddleName */}
              <div className="w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="middleName"
                >
                  Middle name
                </label>
                <GRTextfield
                  id="middleName"
                  type="text"
                  placeholder="Enter middle name"
                  height="40px"
                  width="100%"
                  name="middleName"
                  onChange={formik.handleChange}
                  value={formik.values.middleName}
                  error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                  helperText={formik.touched.middleName && formik.errors.middleName}
                />
              </div>
            </div>
            {/* LastName  and  email*/}
            <div className="flex flex-col gap-x-8 items-center  sm:flex-row sm:items-start sm:justify-between ">
              <div className="w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="lastName"
                >
                  Last name<span className="text-red-600">*</span>
                </label>
                <GRTextfield
                  id="lastName"
                  type="text"
                  placeholder="Enter last name"
                  height="40px"
                  width="100%"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
              <div className="w-full mb-4 ">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="email"
                >
                  Email<span className="text-red-600">*</span>
                </label>
                <GRTextfield
                  id="email"
                  type="text"
                  placeholder="Enter email"
                  height="40px"
                  width="100%"
                  name="email"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
            </div>

            <div className="flex flex-col gap-x-8 items-center sm:flex-row sm:items-start sm:justify-between ">
              <div className="w-full mb-4 ">
                <label className="cursor-pointer block text-[15px] leading-6 font-[400]">
                  Date of birth<span className="text-red-600">*</span>
                </label>

                <GRDatePicker
                  height="40px"
                  className="w-full"
                  error={Boolean(formik.touched.dob) && Boolean(formik.errors.dob)}
                  slotProps={{
                    textField: {
                      error: Boolean(formik.touched.dob) && Boolean(formik.errors.dob),
                      helperText:
                        formik.touched.dob &&
                        (formik.errors?.dob?.substring(0, 20) === "dob must be a `date`" ? (
                          <span className="text-[13px] text-red-500 font-inter">Invalid Date*</span>
                        ) : (
                          <span className="text-[13px] text-red-500 font-inter">
                            {formik.errors.dob}
                          </span>
                        )),
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={dayjs(formik.values.dob)}
                  onChange={(value) => {
                    formik.setFieldValue("dob", value, true)
                  }}
                />
              </div>
              {/* Gender */}
              <div className="w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="gender"
                >
                  Gender<span className="text-red-600">*</span>
                </label>

                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="gender"
                    defaultValue=""
                    name="gender"
                    placeholder="Select gender"
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                    options={genderOptions}
                    errormsg={
                      formik.touched.gender && Boolean(formik.errors.gender)
                        ? formik.errors.gender
                        : ""
                    }
                  />
                </Box>
              </div>
            </div>
          </div>
          <div>
            {/* buttons -  next */}
            <div className="flex gap-4 justify-end mt-5">
              <div className="flex justify-center mb-2">
                <GRButton
                  isSubmitting={isSubmitting}
                  label="Next"
                  endIcon={<RightArrow />}
                  width="110px"
                  size="sm"
                  className="!bg-primaryR2"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PersonalInfo
