// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useContext, useState } from "react"
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios"
import qs from "query-string"
import cookies from "js-cookie"
import { errorToast } from "../utils/ToastUtils"
import AppContext from "../context/app-context/AppContext"
import { useLoader } from "../context/LoaderContext"

const useBaseAPI = (config?: CreateAxiosDefaults<any> | undefined) => {
  const { isLoading, setIsLoading } = useLoader()
  const { currentLoginProfile } = useContext(AppContext)

  const client: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL || "http://54.252.205.217:1337/api/",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    responseType: "json",
    timeout: 30000,
    ...config,
  })

  const request = function (config: AxiosRequestConfig, isLoaderNotShow?: boolean) {
    const setAuthorizationHeader = async (request: InternalAxiosRequestConfig) => {
      setIsLoading(isLoaderNotShow ? false : true)
      const accessToken = cookies.get("authToken")

      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
        if (
          currentLoginProfile &&
          (!request.headers["x-user-id"] || !request.headers["x-profile-type"])
        ) {
          request.headers["x-user-id"] = currentLoginProfile?.profileId
          request.headers["x-profile-type"] = currentLoginProfile?.profileType
        }
      } else {
        delete request.headers.Authorization
      }
      return request
    }

    const onSuccess = (response: AxiosResponse<any>): Promise<any> => {
      setIsLoading(false)

      if (response && response.status !== 200) {
        const errorMsg = response?.statusText
        return Promise.reject(errorMsg)
      } else if (response && response.status === 200 && !response.data) {
        return Promise.resolve()
      }
      return Promise.resolve(response.data)
    }

    const onError = (e: AxiosError) => {
      setIsLoading(false)

      const { message, error } = e?.response?.data as unknown as any
      const defaultErrorMessage = "Something went wrong!"

      if (e && e.response) {
        switch (e.response.status) {
          case 400: // Bad Request.
            if (error?.message === "Email or Username are already taken")
              error["message"] =
                "Oops! This email is already registered. Please use a different email."
            if (error?.message === "Your account email is not confirmed")
              error["message"] =
                "Please confirm your newly registered account via the confirmation link in the email you've received from us."
            if (error?.message === "Invalid identifier or password")
              error["message"] = "Invalid credentials"

            if (error?.message === "Incorrect code provided")
              error["message"] = "This link is already used. Kindly, regenerate the link again."

            errorToast(message || error?.message || defaultErrorMessage)
            return Promise.reject(e)
          case 500: // Internal Server Error
            errorToast(message || error?.message || defaultErrorMessage)
            return Promise.reject(e)
          default:
            if (
              e?.response &&
              !e.response?.config?.baseURL?.includes("https://geocode.maps.co") &&
              e.response.status !== 429
            )
              errorToast(message || error?.message || "Something went wrong")
            return Promise.reject(e)
        }
      } else {
        console.error("Error Message:", e)
      }

      return Promise.reject(e)
    }

    client.interceptors.request.use(setAuthorizationHeader, (error: any) => Promise.reject(error))

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.code === "ERR_NETWORK" &&
          !error?.config?.baseURL.includes("https://geocode.maps.co") &&
          error?.response?.request?.status !== 429
        )
          errorToast("No internet connection")
        return Promise.reject(error)
      },
    )

    return client(config).then(onSuccess).catch(onError)
  }

  const GET = (
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    isLoaderNotShow?: boolean,
  ): Promise<any> => {
    const req: AxiosRequestConfig = {
      url: data ? `${url}?${qs.stringify(data)}` : `${url}`,
      method: "GET",
      ...config,
    }
    return request(req, isLoaderNotShow)
  }

  const POST = (
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    isLoaderNotShow?: boolean,
    setProgress?: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<any> => {
    const req: AxiosRequestConfig = {
      url,
      method: "POST",
      data,
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log("percent >>> ", percentCompleted) // after tsting I will remove this
        setProgress && setProgress(percentCompleted)
      },
      ...config,
    }

    return request(req, isLoaderNotShow)
  }

  const PUT = (url: string, data?: any, config?: AxiosRequestConfig, isLoaderNotShow?: boolean) => {
    const req: AxiosRequestConfig = {
      url,
      method: "PUT",
      data,
      ...config,
    }

    return request(req, isLoaderNotShow)
  }

  const PATCH = (
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    isLoaderNotShow?: boolean,
  ) => {
    const req: AxiosRequestConfig = {
      url,
      method: "PATCH",
      data,
      ...config,
    }

    return request(req, isLoaderNotShow)
  }

  const DELETE = (url: string, data?: any, config?: AxiosRequestConfig) => {
    const req: AxiosRequestConfig = {
      url,
      method: "DELETE",
      data,
      ...config,
    }
    return request(req)
  }

  return { GET, POST, PUT, PATCH, DELETE, isLoading }
}

export default useBaseAPI
