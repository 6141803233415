import React, { ReactNode } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
interface IHighlightCrousalProps {
  children: ReactNode
}
const HighlightCrousalInPopup = (props: IHighlightCrousalProps) => {
  const { children } = props

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }

  return (
    <>
      <Carousel
        className="max-h-[52px] sm:max-h-[54px] md:max-h-[126px] "
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
        deviceType={"desktop"}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        // centerMode={true}
      >
        {children}
      </Carousel>
    </>
  )
}

export default HighlightCrousalInPopup
