import React, { useState } from "react"
import StepSetNewPassword from "../components/forgotPasswordFlow/StepSetNewPassword"
import StepPasswordReset from "../components/forgotPasswordFlow/StepPassowordReset"

function ResetPassword() {
  const [showSuccessfulReset, setShowSuccessfulReset] = useState<boolean>(false)

  return (
    <div>
      {!showSuccessfulReset ? (
        <StepSetNewPassword onPasswordResetSuccess={() => setShowSuccessfulReset(true)} />
      ) : (
        <StepPasswordReset />
      )}
    </div>
  )
}
export default ResetPassword
