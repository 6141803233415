import React from "react"

interface IEmptyTileProps {
  title: string
  linkTitle: string | React.ReactNode
  onClick: () => void
}

const EmptyTile = (props: IEmptyTileProps) => {
  const { onClick, linkTitle, title } = props

  return (
    <div className="bg-white min-h-[219px] flex justify-center items-center w-full rounded-2xl ">
      <div className="flex flex-col justify-center items-center">
        <p className="font-inter font-semibold text-sm leading-[18px] text-black mb-1 text-center">
          {title}
        </p>
        <button
          className="font-inter text-sm font-normal leading-[17px] text-gr-placeHolder underline hover:text-gr-primaryR2"
          onClick={(event) => {
            event.stopPropagation()
            onClick()
          }}
        >
          {linkTitle}
        </button>
      </div>
    </div>
  )
}

export default EmptyTile
