import React from "react"
import Profile from "../components/club/dashboard/Profile"
import Members from "../components/club/dashboard/Members"
import CreatePost from "../components/club/dashboard/CreatePost"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import ClubOpportunityDashboard from "../components/club/dashboard/ClubOpportunityDashboard"
import StickyBox from "react-sticky-box"
import { useScreenWidth } from "../context/ScreenWidthContext"

function ClubDashBoard() {
  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 650 && screenWidth < 1250

  return (
    <div className={`w-full`}>
      <div className="w-full bg-bgred">
        <HeaderSearchFeild />
      </div>
      <div className="w-full bg-bgred">
        <div>
          <div
            className={`flex flex-col md:flex sm:flex-row sm:justify-between md:mt-8 sm:mt-[30px] px-4 md:px-6 mb-6`}
          >
            {/* left part (25%) */}
            {(isLargeScreen || isMediumScreen) && (
              <StickyBox
                offsetTop={20}
                offsetBottom={20}
                style={{
                  flex: isMediumScreen ? "35%" : "25%",
                  maxWidth: isMediumScreen ? "35%" : "25%",
                  height: "100%",
                }}
              >
                <div className={`  lg:flex lg:flex-col lg:gap-[20px]`}>
                  <div>
                    <Profile />
                    <div className="mt-4 mb-4">
                      <Members />
                    </div>
                  </div>
                </div>
              </StickyBox>
            )}

            {/* center part (45%) */}
            <div
              className="flex flex-col items-center"
              style={{
                flex: isLargeScreen ? "47%" : isMediumScreen ? "62%" : "100%",
                maxWidth: isLargeScreen ? "47%" : isMediumScreen ? "62%" : "100%",
              }}
            >
              <CreatePost />
            </div>

            {/* right part (25%) - Conditional rendering based on screen width */}
            {isLargeScreen && (
              <StickyBox
                offsetTop={20}
                offsetBottom={200}
                style={{
                  flex: isMediumScreen ? "33%" : "25%",
                  maxWidth: isMediumScreen ? "33%" : "25%",
                  height: "100%",
                }}
              >
                <div className="hidden lg:flex lg:flex-col lg:gap-[20px]">
                  <div>
                    <ClubOpportunityDashboard />
                  </div>
                </div>
              </StickyBox>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ClubDashBoard
