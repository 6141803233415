import React from "react"
import GlobalSearch from "./GlobalSearch"
function HeaderSearchFeild() {
  return (
    <div className=" sm:hidden px-[16px] z-50 my-4 w-full">
      <GlobalSearch />
    </div>
  )
}

export default HeaderSearchFeild
