import React, { useContext, useEffect, useState } from "react"
import { TextField } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import { Field, FieldArray, Form, Formik } from "formik"
import { cloneDeep, debounce } from "lodash"
import { IGetClubByTextResponse } from "../../modals/Club"
import ExpandMoreIcon from "../../assets/svg/DownArrow"
import { useClubAPI } from "../../services/useClubAPI"
import { AddRugbyExperienceFormValidation } from "../../validations/Validations"
import GRButton from "../ui/GRButton"
import GRDatePicker from "../ui/GRDatePicker"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import GRTextfield from "../ui/GRTextfield"
import { convertIntoDropDownOptions } from "../../utils/CommonUtils"
import { IAddUserExperiencePayload, useLoginUserAPI } from "../../services/useLoginUserAPI"
import AppContext from "../../context/app-context/AppContext"
import { errorToast, successToast } from "../../utils/ToastUtils"
import moment from "moment"
import { IUserExperience } from "../../modals/User"
import dayjs from "dayjs"
import { useUserHook } from "../../hooks/useUserHook"
import BinIcon from "../../assets/svg/BinIcon"
import DeleteExperienceDialog from "../dialogs/DeleteExperienceDialog"
import useLocationHook from "../../hooks/useLocationHook"
import { CountryArray } from "../../utils/Constants"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"
import { Popover, Typography } from "@mui/material"
import CrossIcon from "../../assets/svg/CrossIcon"

interface IRecordHistoryFormProps {
  onClose: () => void
  onSuccess: () => void
  clubDetails?: IUserExperience
}

export interface IAddRugbyExperienceFormValues {
  userType?: string
  id?: number
  club: string
  currentClub: boolean
  startDate: string
  endDate: string
  city: string
  country: string
  state: string
  experienceSeason: {
    userType?: string
    id?: number
    season: string
    playingLevel: string
    discipline: string
    leagueCompetition: string
    ageGroup: string
    position1: string
    position2: string
    coachPosition: string
    gamePlayed: string
    pointScored: string
  }[]
}

interface ILeagueCreatableOption {
  inputValue?: string
  name: string
  value: string
  country: string
}

function RecordHistoryForm(props: IRecordHistoryFormProps) {
  // Hook
  const {
    isLoginAsPlayer,
    isLoginAsCoach,
    isLoginAsAgent,
    isLoginAsChild,
    isLoginAsParent,
    isParent,
    isAgent,
  } = useUserHook()

  const filter = createFilterOptions<ILeagueCreatableOption>()

  //Form initial Values
  const RecordHistoryFormInitialValues: IAddRugbyExperienceFormValues = {
    club: "",
    currentClub: false,
    startDate: "",
    endDate: "",
    city: "",
    state: "",
    country: "",
    experienceSeason: [
      {
        season: "",
        playingLevel: "",
        discipline: "",
        leagueCompetition: "",
        ageGroup: "",
        position1: "",
        position2: "",
        coachPosition: "",
        gamePlayed: "",
        pointScored: "",
      },
    ],
  }
  // Props
  const { onSuccess, clubDetails, onClose } = props

  // States
  const [options, setOptions] = React.useState<IGetClubByTextResponse>()
  const [formValues, setFormValues] = useState<IAddRugbyExperienceFormValues>(
    RecordHistoryFormInitialValues,
  )
  const [isShowDeleteExperienceDialog, setIsShowDeleteExperienceDialog] = useState<boolean>(false)

  const [isSelectFutureData, setIsSelectFutureData] = useState<boolean>(false)
  const [isEndDateLessStartDate, setIsEndDateLessStartDate] = useState<boolean>(false)
  const [isNotState, setIsNotState] = useState<boolean>(false)

  // Context
  const { loginAsProfileDetails, dropDownOptions, currentLoginProfile } = useContext(AppContext)

  // APIs
  const { getClubByText, isLoading } = useClubAPI()
  const {
    addUserExperience,
    isLoading: isSubmitting,
    updateUserExperience,
    deleteUserExperienceById,
  } = useLoginUserAPI()

  const {
    countryOptions,
    stateOptions,
    cityOptions,
    fetchStateByCountry,
    fetchCityByStateAndCountry,
    fetchLocationData,
  } = useLocationHook()

  // UseEffect

  useEffect(() => {
    setFormData()
  }, [clubDetails, currentLoginProfile, loginAsProfileDetails])

  const setFormData = () => {
    if (clubDetails) {
      fetchLocationData(clubDetails?.country, clubDetails?.state)

      const formVal: IAddRugbyExperienceFormValues = {
        id: clubDetails?.id,
        club: String(clubDetails?.club?.id),
        endDate: clubDetails?.endDate,
        startDate: clubDetails?.startDate,
        currentClub: clubDetails?.currentClub,
        city: clubDetails?.city,
        state: clubDetails?.state,
        country: clubDetails?.country,
        userType: currentLoginProfile?.profileType,
        experienceSeason: clubDetails?.experienceSeasons?.map((e) => ({
          id: e?.id,
          ageGroup: String(e?.ageGroup?.id),
          discipline: String(e?.discipline?.id),
          gamePlayed: e?.gamesPlayed !== 0 ? String(e?.gamesPlayed) : "",
          leagueCompetition: String(e?.competition?.id),
          playingLevel: String(e?.playingLevel?.id),
          pointScored: e?.pointScored !== 0 ? String(e?.pointScored) : "",
          position1: String(e?.position_1?.id),
          position2: e?.position_2?.id ? String(e?.position_2?.id) : "",
          coachPosition: String(e?.coachPosition?.id),
          season: String(e?.season?.id),
          userType: currentLoginProfile?.profileType,
        })),
      }
      setFormValues(formVal)
    } else {
      const formVal = cloneDeep(RecordHistoryFormInitialValues)
      formVal.userType = currentLoginProfile?.profileType
      formVal.experienceSeason[0].userType = currentLoginProfile?.profileType

      setFormValues(formVal)
    }
  }

  const fetchResults = async (searchStr: string) => {
    try {
      if (searchStr?.length < 3) {
        return
      }
      const r = await getClubByText(searchStr)
      setOptions(r)
    } catch {}
  }

  const debounceSearch = debounce(fetchResults, 1000)

  const handleSubmit = async (values: IAddRugbyExperienceFormValues) => {
    try {
      if (!loginAsProfileDetails) {
        errorToast("Something went wrong!")
        return
      }
      const payload: IAddUserExperiencePayload = {
        data: {
          id: values?.id,
          club: values?.club,
          currentClub: values?.currentClub,
          user: loginAsProfileDetails?.id,
          startDate: moment(values?.startDate)?.format("YYYY-MM"),
          endDate:
            !values?.currentClub && values?.endDate
              ? moment(values?.endDate)?.format("YYYY-MM")
              : "",
          city: values?.city,
          state: values?.state,
          country: values?.country,
          experienceSeason: values?.experienceSeason?.map((e) => ({
            id: e?.id,
            ageGroup: e?.ageGroup,
            competition: e?.leagueCompetition,
            discipline: e?.discipline,
            gamesPlayed: e?.gamePlayed,
            playingLevel: e?.playingLevel,
            pointScored: e?.pointScored,
            position_1: !isLoginAsCoach && e?.position1 ? e?.position1 : null,
            position_2: !isLoginAsCoach && e?.position2 ? e?.position2 : null,
            coachPosition: isLoginAsCoach && e?.coachPosition ? e?.coachPosition : null,
            season: e?.season,
            userType: undefined,
          })),
        },
      }
      if (clubDetails?.id) {
        const ur = await updateUserExperience(payload)
        if (ur) {
          successToast("Your experience updated successfully")
          onSuccess()
        }
        return
      }
      const r = await addUserExperience(payload)
      if (r) {
        successToast("Your experience added successfully")
        onSuccess()
      }
      onClose()
    } catch {}
  }

  const getClubValue = (clubId: string) => {
    const o = options?.data?.find((d) => d?.id === parseInt(clubId))
    if (!o) return
    return { label: o?.name, value: o?.id }
  }

  const handleDelete = async (experienceId: number) => {
    try {
      const ur = await deleteUserExperienceById(experienceId)
      if (ur) {
        successToast("Your experience deleted successfully")
        onSuccess()
      }
      return
    } catch {}
  }

  const currentClubId = loginAsProfileDetails?.userExperiences?.find((e) => e?.currentClub)?.id
  const isAlreadyMarkCurrentClub =
    currentClubId === clubDetails?.id ? false : Boolean(currentClubId)

  const getLeagueValue = (value: string): ILeagueCreatableOption | undefined => {
    const option = dropDownOptions?.league?.find((l) => String(l?.id) === value)
    if (option) {
      return {
        name: option?.title,
        value: String(option?.id),
        country: option.country,
      }
    }
  }

  const getLeagueDropdownOptions = (): ILeagueCreatableOption[] => {
    const options =
      dropDownOptions?.league?.map((leagueOption: any) => ({
        name: leagueOption?.title,
        value: String(leagueOption?.id),
        country: leagueOption?.country,
      })) || []

    if (options?.length) return options
    return []
  }

  const [messagePopoverAnchorEl, setMessagePopoverAnchorEl] = useState<null | HTMLElement>(null)
  // Handler function to toggle the message
  const toggleMessagePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagePopoverAnchorEl(messagePopoverAnchorEl ? null : event.currentTarget)
  }

  return (
    <>
      <Formik
        initialValues={formValues}
        validationSchema={AddRugbyExperienceFormValidation}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {(formikProps) => (
          <Form className="font-inter compeleteProfileForm">
            <div className="" style={{ overflowY: "auto", maxHeight: "500px" }}>
              <div className="mx-[15px] lg:mx-[31px] mb-4">
                <div className="">
                  <p className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-2">
                    Basic info
                  </p>
                </div>
                <div className="flex mb-3">
                  <span className="text-red-600 text-lg -mt-2">*</span>
                  <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
                </div>
                <div className="w-full">
                  <div className="mb-4">
                    <div className="flex justify-between items-center">
                      <label
                        className="block text-sm leading-6 font-medium font-inter mb-1 flex"
                        htmlFor="club"
                      >
                        Club
                        <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                          *
                        </span>
                        <div
                          className="mt-1.5 ml-[8px] cursor-pointer"
                          onMouseEnter={toggleMessagePopover}
                          onMouseLeave={() => setMessagePopoverAnchorEl(null)}
                        >
                          <ExclamtionIcon />
                          <Popover
                            open={Boolean(messagePopoverAnchorEl)}
                            anchorEl={messagePopoverAnchorEl}
                            onClose={() => setMessagePopoverAnchorEl(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              <div className="flex gap-2">
                                <span>
                                  If you don't see your club, please send us an email to{" "}
                                  <a
                                    href="mailto:info@globalrugby.com"
                                    target="_self"
                                    className="text-gr-primaryR2 hover:underline"
                                  >
                                    info@globalrugby.com
                                  </a>{" "}
                                  and we'll get it added.
                                  {/* <a
                          className="font-bold text-red-600"
                          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@globalrugby.com" 
                          target="_blank" rel="noreferrer">
                          {" "}
                          info@globalrugby.com{" "}
                        </a> */}
                                </span>
                                <div className="mb-1 cursor-pointer">
                                  <CrossIcon onClick={() => setMessagePopoverAnchorEl(null)} />
                                </div>
                              </div>
                            </Typography>
                          </Popover>
                        </div>
                      </label>
                      {!isParent && !isAgent ? (
                        <div className="flex justify-between items-center gap-2 mb-1">
                          <input
                            disabled={
                              isAlreadyMarkCurrentClub ||
                              isLoginAsParent ||
                              isLoginAsChild ||
                              isLoginAsAgent
                            }
                            type="checkbox"
                            id="currentClub"
                            name="currentClub"
                            checked={formikProps?.values?.currentClub}
                            onChange={(e) =>
                              formikProps?.setFieldValue("currentClub", e?.target?.checked)
                            }
                            className=" accent-gr-primaryR2 !border-gr-primaryR2 !border-solid !text-xs"
                          />
                          <label
                            htmlFor="currentClub"
                            className="text-sm font-normal !mb-0 font-inter text-gr-neutralT4 cursor-pointer"
                          >
                            Mark as current club
                          </label>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <Autocomplete
                        autoFocus
                        id="club-search"
                        defaultValue={
                          clubDetails
                            ? { label: clubDetails?.club?.name, value: clubDetails?.club?.id }
                            : undefined
                        }
                        onChange={(e, v) => {
                          formikProps?.handleChange(e)
                          formikProps?.setFieldValue("club", v?.value)
                        }}
                        onBlur={formikProps?.handleBlur}
                        value={getClubValue(formikProps?.values?.club)}
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                        getOptionLabel={(option) => option?.label}
                        options={
                          options?.data?.map((o) => ({
                            label: o?.name + ", " + o?.country,
                            value: o?.id,
                          })) || []
                        }
                        loading={isLoading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => debounceSearch(e?.target?.value)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            placeholder="Search for club"
                            sx={AutocompleteInputCss}
                          />
                        )}
                      />
                      {formikProps?.touched?.club && formikProps?.errors?.club && (
                        <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                          {formikProps?.errors?.club}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="grid gap-x-5 grid-cols-1 lg:grid-cols-3 lg:gap-6">
                      <div className="w-full">
                        <div className="font-inter text-gr-primaryN1 text-sm mb-1">
                          Country
                          <span className="text-red-600">*</span>
                        </div>
                        <div className="mb-4">
                          <Autocomplete
                            id="country"
                            onChange={(_event, newValue) => {
                              formikProps.handleChange(_event)
                              formikProps.setFieldValue("country", newValue || "")
                              formikProps.setFieldValue("state", "")
                              formikProps.setFieldValue("city", "")
                              if (newValue) {
                                if (CountryArray?.includes(newValue)) {
                                  fetchCityByStateAndCountry(newValue, "")
                                  setIsNotState(true)
                                } else {
                                  fetchStateByCountry(newValue)
                                  setIsNotState(false)
                                }
                              }
                            }}
                            renderOption={(formikProps, option) => (
                              <li {...formikProps} style={{ fontSize: "14px" }}>
                                {option}
                              </li>
                            )}
                            onBlur={formikProps?.handleBlur}
                            value={formikProps?.values?.country}
                            options={countryOptions?.map((c) => c?.name) || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Country"
                                sx={AutocompleteInputCss}
                              />
                            )}
                          />
                          {formikProps?.touched?.country && formikProps?.errors?.country && (
                            <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                              {formikProps?.errors?.country}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="font-inter text-gr-primaryN1 text-sm mb-1">State</div>
                        <div className="mb-4">
                          <Autocomplete
                            id="state"
                            onChange={(_event, newValue) => {
                              formikProps.handleChange(_event)
                              formikProps.setFieldValue("state", newValue || "")
                              formikProps.setFieldValue("city", "")
                              if (newValue) {
                                fetchCityByStateAndCountry(formikProps.values.country, newValue)
                              }
                            }}
                            renderOption={(formikProps, option) => (
                              <li {...formikProps} style={{ fontSize: "14px" }}>
                                {option}
                              </li>
                            )}
                            onBlur={formikProps?.handleBlur}
                            value={formikProps?.values?.state}
                            readOnly={
                              CountryArray?.includes(formikProps.values.country) ? true : false
                            }
                            options={stateOptions?.map((s) => s?.name) || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="State"
                                sx={AutocompleteInputCss}
                              />
                            )}
                          />
                          {isNotState && (
                            <div className="text-[13px] text-blue-600 lowercase first-letter:uppercase !font-inter">
                              The country has no state so you can select a city
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="font-inter text-gr-primaryN1 text-sm mb-1">City</div>
                        <div className="mb-4">
                          <Autocomplete
                            id="city"
                            onChange={(_event, newValue) => {
                              formikProps.handleChange(_event)
                              formikProps.setFieldValue("city", newValue || "")
                            }}
                            renderOption={(formikProps, option) => (
                              <li {...formikProps} style={{ fontSize: "14px" }}>
                                {option}
                              </li>
                            )}
                            onBlur={formikProps?.handleBlur}
                            value={formikProps?.values?.city}
                            options={cityOptions?.map((c: { name: any }) => c?.name) || []}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="City" sx={AutocompleteInputCss} />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                    <div className="w-full mb-4">
                      <label className="block font-inter text-gr-primaryN1 text-sm mb-1">
                        Start date
                        <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                          *
                        </span>{" "}
                        (when you first joined the club)
                      </label>

                      <GRDatePicker
                        className="w-full mb-4"
                        height="40px"
                        views={["month", "year"]}
                        error={
                          Boolean(formikProps?.touched.startDate) &&
                          Boolean(formikProps?.errors.startDate)
                        }
                        slotProps={{
                          textField: {
                            error:
                              Boolean(formikProps?.touched.startDate) &&
                              Boolean(formikProps?.errors.startDate),
                            helperText: isSelectFutureData ? (
                              <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
                                Start date can't be in the future
                              </span>
                            ) : (
                              formikProps?.touched.startDate &&
                              (formikProps?.errors?.startDate?.substring(0, 20) ===
                              "Start Date must be a `date`" ? (
                                <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
                                  Invalid Date*
                                </span>
                              ) : (
                                <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
                                  {formikProps?.errors.startDate}
                                </span>
                              ))
                            ),
                          },
                        }}
                        format="MM/YYYY"
                        value={dayjs(formikProps?.values?.startDate)}
                        onChange={(value) => {
                          const parsedDate = dayjs(value)
                          const lastDate = new Date(formikProps?.values?.endDate)
                          const date1 = value ? new Date(value) : new Date()
                          if (formikProps?.values?.endDate && moment(date1).isAfter(lastDate)) {
                            setIsEndDateLessStartDate(true)
                          } else {
                            setIsEndDateLessStartDate(false)
                          }
                          if (
                            parsedDate.isValid() &&
                            parsedDate.isBefore(dayjs().startOf("day").add(1, "day"))
                          ) {
                            formikProps?.setFieldValue("startDate", value, true)
                            setIsSelectFutureData(false)
                          } else {
                            setIsSelectFutureData(true)
                          }
                        }}
                      />
                    </div>

                    {!formikProps?.values?.currentClub && (
                      <div className="w-full mb-4">
                        <label className="block font-inter text-gr-primaryN1 text-sm mb-1">
                          End date
                          <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter ml-1 mt-1">
                            *
                          </span>
                        </label>

                        <GRDatePicker
                          className="w-full mb-4"
                          height="40px"
                          views={["month", "year"]}
                          error={
                            Boolean(formikProps?.touched.endDate) &&
                            Boolean(formikProps?.errors.endDate)
                          }
                          slotProps={{
                            textField: {
                              error:
                                Boolean(formikProps?.touched.endDate) &&
                                Boolean(formikProps?.errors.endDate),
                              helperText: isEndDateLessStartDate ? (
                                <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                  Start date must be less than the end date
                                </span>
                              ) : (
                                formikProps?.touched.endDate &&
                                (formikProps?.errors?.endDate?.substring(0, 20) ===
                                "Start Date must be a `date`" ? (
                                  <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                    Invalid Date*
                                  </span>
                                ) : (
                                  <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter ml-1 mt-1">
                                    {formikProps?.errors.endDate}
                                  </span>
                                ))
                              ),
                            },
                          }}
                          format="MM/YYYY"
                          value={dayjs(formikProps?.values?.endDate)}
                          onChange={(value) => {
                            const parsedDate = dayjs(value)
                            const date1 = new Date(formikProps?.values?.startDate)
                            const lastDate = value ? new Date(value) : new Date()
                            if (
                              parsedDate.isValid() &&
                              (!formikProps?.values?.startDate || moment(lastDate).isAfter(date1))
                            ) {
                              formikProps?.setFieldValue("endDate", value, true)
                              setIsEndDateLessStartDate(false)
                            } else {
                              setIsEndDateLessStartDate(true)
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="">
                  <FieldArray
                    key={`${countryOptions?.[0]?.value}${stateOptions?.[0]?.value}${cityOptions?.[0]?.value}`}
                    name="experienceSeason"
                    render={(arrayHelpers) => (
                      <div className="relative">
                        <div className="flex flex-col gap-1">
                          {/* Season info */}
                          {formikProps?.values?.experienceSeason?.map((es, index) => (
                            <div key={index}>
                              <div className="mb-[10px] mx-[5px] sm:mx-0 mt-3">
                                <Accordion
                                  className="h-full sm:w-full px-5 !shadow-none drop-shadow-[0_18px_10px_rgba(0,0,0,0.15)]"
                                  defaultExpanded={true}
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="!px-0"
                                  >
                                    <div className="font-inter flex justify-between w-full items-center">
                                      <div className="md:text-[20px] text-lg font-semibold font-inter">
                                        Season{" "}
                                        {
                                          dropDownOptions?.seasons?.find(
                                            (s) => s?.id === parseInt(es?.season),
                                          )?.title
                                        }
                                      </div>
                                      {formikProps?.values?.experienceSeason?.length > 1 && (
                                        <div className="pr-5">
                                          <BinIcon
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              arrayHelpers?.remove(index)
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails className="!border-t !border-t-gr-primaryN8 !pt-5 !px-0">
                                    <div className="compeleteProfileForm">
                                      <div>
                                        <Field name={`experienceSeason[${index}].season`}>
                                          {/* season */}
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label
                                                className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                htmlFor="season"
                                              >
                                                Season
                                                <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                                  *
                                                </span>
                                              </label>
                                              <div>
                                                <GRSelect
                                                  key={"season"}
                                                  id="season"
                                                  placeholder="Select season"
                                                  style={{ height: "40px" }}
                                                  options={convertIntoDropDownOptions(
                                                    dropDownOptions?.seasons,
                                                  )}
                                                  error={meta.touched && meta.error}
                                                  errormsg={meta.touched && meta.error}
                                                  {...field}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                        <div>
                                          {/* playinglevel */}
                                          <Field name={`experienceSeason[${index}].playingLevel`}>
                                            {({ field, meta }: any) => (
                                              <div className="w-full mb-4">
                                                <div className="flex justify-between items-center">
                                                  <label
                                                    className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                    htmlFor="playingLevel"
                                                  >
                                                    {isLoginAsCoach
                                                      ? "Coaching Level"
                                                      : "Playing Level"}
                                                    <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                                      *
                                                    </span>
                                                  </label>
                                                </div>
                                                <div>
                                                  <GRSelect
                                                    id="playingLevel"
                                                    placeholder="Select playing level"
                                                    style={{ height: "40px" }}
                                                    options={convertIntoDropDownOptions(
                                                      dropDownOptions?.level,
                                                    )}
                                                    error={meta.touched && meta.error}
                                                    errormsg={
                                                      meta.touched &&
                                                      `${
                                                        isLoginAsPlayer ? "Playing" : "Coaching"
                                                      } ${meta.error}`
                                                    }
                                                    {...field}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </Field>
                                        </div>
                                        <div>
                                          {/* discipline */}
                                          <Field name={`experienceSeason[${index}].discipline`}>
                                            {({ field, meta }: any) => (
                                              <div className="w-full mb-4">
                                                <div className="flex justify-between items-center">
                                                  <label
                                                    className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                    htmlFor="discipline"
                                                  >
                                                    Code
                                                    <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                                      *
                                                    </span>
                                                  </label>
                                                </div>
                                                <div>
                                                  <GRSelect
                                                    id="discipline"
                                                    placeholder="Select code"
                                                    style={{ height: "40px" }}
                                                    options={convertIntoDropDownOptions(
                                                      dropDownOptions?.discipline,
                                                    )}
                                                    error={meta.touched && meta.error}
                                                    errormsg={meta.touched && meta.error}
                                                    {...field}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </Field>
                                        </div>
                                      </div>

                                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                        <div>
                                          {/* league */}
                                          <Field
                                            name={`experienceSeason[${index}].leagueCompetition`}
                                          >
                                            {({ field, meta }: any) => (
                                              <div className="w-full mb-4">
                                                <label
                                                  className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                  htmlFor="leagueCompetition"
                                                >
                                                  League/Competition
                                                  <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                                    *
                                                  </span>
                                                </label>
                                                <div className="mb-4">
                                                  <Autocomplete
                                                    id="league-search"
                                                    onChange={(_event, newValue, reason) => {
                                                      if (reason === "clear") {
                                                        formikProps?.setFieldValue(field?.name, "")
                                                        return
                                                      }
                                                      if (typeof newValue === "string") {
                                                        formikProps?.setFieldValue(
                                                          field?.name,
                                                          newValue,
                                                        )
                                                      } else if (newValue && newValue.inputValue) {
                                                        formikProps?.setFieldValue(
                                                          field?.name,
                                                          newValue.inputValue,
                                                        )
                                                      } else {
                                                        formikProps?.setFieldValue(
                                                          field?.name,
                                                          newValue?.value,
                                                        )
                                                      }
                                                    }}
                                                    filterOptions={(options, params) => {
                                                      const filtered = filter(options, params)

                                                      const { inputValue } = params
                                                      const isExisting = options.some(
                                                        (option) => inputValue === option.name,
                                                      )
                                                      if (inputValue !== "" && !isExisting) {
                                                        filtered.push({
                                                          inputValue,
                                                          name: `Add "${inputValue}"`,
                                                          value: inputValue,
                                                          country: "",
                                                        })
                                                      }

                                                      return filtered
                                                    }}
                                                    getOptionLabel={(option) => {
                                                      if (typeof option === "string") {
                                                        return option
                                                      }

                                                      if (option.inputValue) {
                                                        return option.inputValue
                                                      }

                                                      return `${option.country}-${option.name}`
                                                    }}
                                                    renderOption={(formikProps, option) => (
                                                      <li {...formikProps}>
                                                        {option.country}-{option.name}
                                                      </li>
                                                    )}
                                                    onBlur={formikProps?.handleBlur}
                                                    value={getLeagueValue(field?.value)}
                                                    options={getLeagueDropdownOptions()}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        placeholder="Search or add new"
                                                        sx={AutocompleteInputCss}
                                                      />
                                                    )}
                                                  />
                                                  {meta?.touched && meta?.error && (
                                                    <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                                      {meta?.error}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </Field>
                                        </div>
                                        <div>
                                          <Field name={`experienceSeason[${index}].ageGroup`}>
                                            {({ field, meta }: any) => (
                                              <div className="w-full mb-4">
                                                <label
                                                  className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                  htmlFor="ageGroup"
                                                >
                                                  Age group
                                                  <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                                    *
                                                  </span>
                                                </label>
                                                <div>
                                                  <GRSelect
                                                    id="ageGroup"
                                                    placeholder="Select age group"
                                                    style={{ height: "40px" }}
                                                    options={convertIntoDropDownOptions(
                                                      dropDownOptions?.ageGroup,
                                                    )}
                                                    error={meta.touched && meta.error}
                                                    errormsg={meta.touched && meta.error}
                                                    {...field}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </Field>
                                        </div>
                                      </div>

                                      <div
                                        className={`grid grid-cols-1  gap-x-5  ${
                                          isLoginAsPlayer ? "lg:grid-cols-2" : "lg:grid-cols-1"
                                        }  `}
                                      >
                                        {/* position1  for rest except coach*/}
                                        {!isLoginAsCoach && (
                                          <div>
                                            <Field name={`experienceSeason[${index}].position1`}>
                                              {({ field, meta }: any) => (
                                                <div className="w-full mb-4">
                                                  <label
                                                    className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                    htmlFor="position1"
                                                  >
                                                    Position 1
                                                    <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                                      *
                                                    </span>
                                                  </label>
                                                  <GRSelect
                                                    id="position1"
                                                    placeholder="Select position 1"
                                                    style={{ height: "40px" }}
                                                    options={convertIntoDropDownOptions(
                                                      dropDownOptions?.playerPosition,
                                                    )}
                                                    error={meta.touched && meta.error}
                                                    errormsg={meta.touched && meta.error}
                                                    {...field}
                                                  />
                                                </div>
                                              )}
                                            </Field>
                                          </div>
                                        )}
                                        {/* position2  for rest except coach*/}
                                        {!isLoginAsCoach && (
                                          <div>
                                            <Field name={`experienceSeason[${index}].position2`}>
                                              {({ field, meta }: any) => (
                                                <div className="w-full mb-4">
                                                  <label
                                                    className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                    htmlFor="position2"
                                                  >
                                                    Position 2
                                                  </label>
                                                  <GRSelect
                                                    id="position2"
                                                    placeholder="Select position 2"
                                                    style={{ height: "40px" }}
                                                    options={convertIntoDropDownOptions(
                                                      dropDownOptions?.playerPosition,
                                                    )}
                                                    error={meta.touched && meta.error}
                                                    errormsg={meta.touched && meta.error}
                                                    {...field}
                                                  />
                                                </div>
                                              )}
                                            </Field>
                                          </div>
                                        )}
                                        {/* cochPosition for coach*/}
                                        {isLoginAsCoach && (
                                          <div className="w-full">
                                            <Field
                                              name={`experienceSeason[${index}].coachPosition`}
                                            >
                                              {({ field, meta }: any) => (
                                                <div className="w-full mb-4">
                                                  <label
                                                    className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                    htmlFor="coachPosition"
                                                  >
                                                    Position
                                                    <span className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                                                      *
                                                    </span>
                                                  </label>
                                                  <GRSelect
                                                    id="coachPosition"
                                                    placeholder="Select position"
                                                    style={{ height: "40px" }}
                                                    options={convertIntoDropDownOptions(
                                                      dropDownOptions?.coachPosition,
                                                    )}
                                                    error={meta.touched && meta.error}
                                                    errormsg={meta.touched && meta.error}
                                                    {...field}
                                                  />
                                                </div>
                                              )}
                                            </Field>
                                          </div>
                                        )}
                                      </div>

                                      {/* Game Played */}
                                      {!isLoginAsCoach && (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                          <div>
                                            <Field name={`experienceSeason[${index}].gamePlayed`}>
                                              {({ field, meta }: any) => (
                                                <div className="w-full mb-4">
                                                  <label
                                                    className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                    htmlFor="gamePlayed"
                                                  >
                                                    Game Played
                                                  </label>
                                                  <GRTextfield
                                                    id="gamePlayed"
                                                    type="number"
                                                    placeholder="Games played"
                                                    width="100%"
                                                    height="40px"
                                                    error={meta.touched && meta.error}
                                                    helperText={meta.touched && meta.error}
                                                    {...field}
                                                  />
                                                </div>
                                              )}
                                            </Field>
                                          </div>
                                          <div>
                                            <Field name={`experienceSeason[${index}].pointScored`}>
                                              {({ field, meta }: any) => (
                                                <div className="w-full mb-4">
                                                  <label
                                                    className="block font-inter text-gr-primaryN1 text-sm mb-1"
                                                    htmlFor="pointScored"
                                                  >
                                                    Points Scored
                                                  </label>
                                                  <GRTextfield
                                                    id="pointScored"
                                                    type="number"
                                                    placeholder="Points scored"
                                                    width="100%"
                                                    height="40px"
                                                    error={meta.touched && meta.error}
                                                    helperText={meta.touched && meta.error}
                                                    {...field}
                                                  />
                                                </div>
                                              )}
                                            </Field>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* add another season */}
                        <div className="flex justify-between relative mt-5 sm:mt-0 sm:absolute sm:bottom-[-55px]">
                          <button
                            onClick={() => {
                              arrayHelpers.push({
                                ...cloneDeep(
                                  formikProps?.values?.experienceSeason[
                                    formikProps?.values?.experienceSeason?.length - 1
                                  ],
                                ),
                                id: undefined,
                              })
                            }}
                            type="button"
                            className="text-red-darkRed font-inter text-base hover:underline"
                          >
                            +Add Another Season
                          </button>
                        </div>
                      </div>
                    )}
                  />
                  <div className="flex sm:justify-end items-center my-5">
                    <div className="flex gap-2 items-center justify-between sm:w-auto w-full">
                      <div>
                        <GRButton
                          onClick={() =>
                            clubDetails?.id ? setIsShowDeleteExperienceDialog(true) : onClose()
                          }
                          label={clubDetails?.id ? "Delete" : "Cancel"}
                          size="sm"
                          className="sm:!px-[30px] !bg-white !text-sm !text-red-primaryRed lowercase first-letter:uppercase !font-inter ml-1 mt-1 w-[140px] !border !border-red-primaryRed !shadow-none"
                          border="1px solid"
                          type="button"
                          width={"100px"}
                        />
                        <DeleteExperienceDialog
                          isOpen={isShowDeleteExperienceDialog}
                          onClose={() => setIsShowDeleteExperienceDialog(false)}
                          onDelete={() => {
                            clubDetails?.id && handleDelete(clubDetails?.id)
                          }}
                        />
                      </div>
                      <div>
                        <GRButton
                          isSubmitting={isSubmitting}
                          label={clubDetails?.id ? "Update" : "Submit"}
                          className="sm:!px-[30px] w-[100px]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default RecordHistoryForm
