import React, { SVGProps } from "react"

const EditIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.577 3.244a.833.833 0 011.179 0l2.5 2.5a.833.833 0 010 1.179l-7.5 7.5a.833.833 0 01-.59.244h-2.5a.833.833 0 01-.833-.834v-2.5c0-.22.088-.433.244-.589l7.5-7.5zM7.5 11.678V13h1.321l6.667-6.667-1.321-1.321L7.5 11.679zm-5-5.345c0-.92.746-1.666 1.667-1.666h4.166a.833.833 0 110 1.666H4.167v10h10v-4.166a.833.833 0 111.666 0v4.166c0 .92-.746 1.667-1.666 1.667h-10c-.92 0-1.667-.746-1.667-1.667v-10z"
        fill="#fff"
      />
    </svg>
  )
}

export default EditIcon
