import React, { useContext, useState } from "react"
import SearchIcon from "../../assets/svg/SearchIcon.svg"
import ZeroConnections from "./ZeroConnections"
import { useEffect, useRef } from "react"
import MessageIcon from "../../assets/svg/MessageIcon"
import { IChatSummary, IConnectedUserForChatResponse } from "../../modals/Chat"
import { useChatAPI } from "../../services/useChatAPI"
import { ICurrentChat } from "../../screens/Message"
import AppContext from "../../context/app-context/AppContext"
import moment from "moment"
import { getNameInitials } from "../../utils/CommonUtils"
import { ProfileTypeEnum } from "../../utils/Constants"
import { Link, useSearchParams } from "react-router-dom"
import { errorToast } from "../../utils/ToastUtils"
import { AUTH_MESSAGE } from "../../routes/RouteConstants"
import { useScreenWidth } from "../../context/ScreenWidthContext"

interface IConnectionsProps {
  chats: IChatSummary[]
  setCurrentChat: React.Dispatch<React.SetStateAction<ICurrentChat | undefined>>
  currentChat: ICurrentChat | undefined
  classes?: any
}

const Connections: React.FC<IConnectionsProps> = (props) => {
  // Props
  const { chats, setCurrentChat, currentChat, classes } = props

  // Hooks
  const [searchParams] = useSearchParams()
  const receiverId = searchParams.get("chatwith")

  // APIs
  const { getConnectedUserForChat } = useChatAPI()

  // States
  const [connectedUsers, setConnectedUsers] = useState<IConnectedUserForChatResponse>()
  const [searchString, setSearchString] = useState("")

  const connections = [{}]

  const inputRef = useRef<HTMLInputElement>(null)
  const [showSuggestions, setShowSuggestions] = useState(false)
  // Context
  const { currentLoginProfile } = useContext(AppContext)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener("click", handleOutsideClick)

    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    fetchConnectedUsersForChat()
  }, [])

  useEffect(() => {
    if (!receiverId || !connectedUsers?.user?.length) return

    const chatObj = connectedUsers?.user?.find((u) => u?.receiverId === Number(receiverId))

    if (!chatObj) {
      errorToast("Something went wrong")
      return
    }

    setCurrentChat({
      chatId: chatObj?.chatId || null,
      name: chatObj?.name,
      profileType: chatObj?.profileType,
      receiverId: chatObj?.receiverId,
      profilePicture: chatObj?.profilePicture,
    })
  }, [receiverId, connectedUsers])

  const fetchConnectedUsersForChat = async () => {
    try {
      const r = await getConnectedUserForChat()
      setConnectedUsers(r)
    } catch {}
  }

  const handleInputFocus = () => {
    setShowSuggestions(true)
  }

  const userContacts = connectedUsers
    ? connectedUsers?.user?.filter((f) =>
        searchString
          ? f?.firstname?.toLocaleLowerCase()?.includes(searchString?.toLocaleLowerCase()) ||
            f?.lastname?.toLocaleLowerCase()?.includes(searchString?.toLocaleLowerCase()) ||
            f?.clubName?.toLocaleLowerCase()?.includes(searchString?.toLocaleLowerCase()) ||
            `${f?.firstname} ${f?.lastname}`
              ?.toLocaleLowerCase()
              ?.includes(searchString?.toLocaleLowerCase())
          : true,
      )
    : []

  return (
    <div className={`w-full ${classes ? classes : ""}`}>
      <div className=" flex flex-col items-center">
        <div className="flex flex-col items-center gap-5 w-full mx-2 sm:mx-0 sm:px-0 px-4">
          <div className="relative w-full sm:mt-0 ">
            <input
              type="text"
              className="py-[10px] pl-3 pr-12 text-gr-neutralT2 focus:outline-none  rounded-2xl w-full border border-gr-primaryR4 "
              placeholder="Search..."
              onChange={(e) => setSearchString(e?.target?.value)}
              onFocus={handleInputFocus}
              ref={inputRef}
            />
            <div className="">
              <img
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 cursor-pointer"
                src={SearchIcon}
                alt="SearchIcon"
              />
            </div>
            {showSuggestions && userContacts.length > 0 && (
              <div
                key={searchString}
                className="flex z-50 flex-col items-center justify-center absolute bg-white w-full shadow"
              >
                <div className="flex max-h-[50vh] h-full  px-3 overflow-auto  flex-col items-start justify-between text-[14px] mb-5 gap-3 w-full mt-4">
                  {/* <p className="text-center">{}</p> */}
                  {userContacts &&
                    userContacts
                      ?.filter((us) => us?.userId !== currentLoginProfile?.profileId)
                      ?.map((u) => (
                        <Link
                          className="flex cursor-pointer items-center justify-between w-full mt-1"
                          key={u?.userId || u?.clubId}
                          to={`${AUTH_MESSAGE}?chatwith=${u?.receiverId}`}
                        >
                          <div className="flex items-center gap-4">
                            {u?.profilePicture?.url ? (
                              <div className="">
                                <img
                                  className="rounded-full w-[54px] h-[54px]"
                                  src={u?.profilePicture?.url}
                                  alt="logo"
                                />
                              </div>
                            ) : (
                              <div className="flex items-center justify-center w-[54px] h-[54px] rounded-full bg-gr-primaryR1">
                                <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                                  {u?.profileType === ProfileTypeEnum?.Club
                                    ? getNameInitials(u?.clubName)
                                    : getNameInitials(u?.firstname, u?.lastname)}
                                </span>
                              </div>
                            )}

                            <div className="">
                              <div className="font-inter text-sm font-semibold">
                                {u?.profileType === ProfileTypeEnum?.Club
                                  ? u?.clubName
                                  : `${u?.firstname} ${u?.lastname}`}
                              </div>
                              <div className="text-xs text-gray-500">{u?.profileType}</div>
                            </div>
                          </div>
                          <div className="z">
                            <MessageIcon width={20} height={20} />
                          </div>
                        </Link>
                      ))}
                </div>
              </div>
            )}
          </div>

          <div className="w-full rounded-2xl p-2 bg-white h-full">
            {connections.length !== 0 ? (
              <div>
                <div className="text-xl font-inter font-semibold border-b pl-2 py-2">
                  Connections
                </div>
                <div className="h-[72vh]  md:h-[517px] overflow-y-scroll">
                  {chats &&
                    chats?.map((chat: IChatSummary, index: any) => (
                      <div
                        key={index}
                        onClick={() =>
                          setCurrentChat({
                            chatId: chat?.chatId || null,
                            profilePicture: chat?.profilePicture,
                            profileType: chat?.profileType,
                            name:
                              chat.profileType === ProfileTypeEnum?.Club
                                ? chat.clubName || ""
                                : `${chat.firstname || ""} ${chat.middlename || ""} ${
                                    chat.lastname || ""
                                  }`,
                            receiverId:
                              chat.profileType === ProfileTypeEnum?.Club
                                ? chat?.clubReceiverId
                                : chat?.receiverId,
                          })
                        }
                        className="chat-row"
                      >
                        <ConnectionCard
                          selected={currentChat?.receiverId === chat?.chatId}
                          chat={chat}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <ZeroConnections />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
interface IConnectionCard {
  selected?: boolean
  chat: IChatSummary
}
const ConnectionCard: React.FC<IConnectionCard> = ({ selected, chat }) => {
  // Context
  const { currentLoginProfile } = useContext(AppContext)

  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 650 && screenWidth < 1250

  return (
    <div className={selected ? "bg-bgRed" : "bg-white"}>
      <div className="relative flex items-center pl-2 pr-0 sm:px-2 cursor-pointer hover:bg-bgRed p-4 chatCol">
        <div className="flex items-center w-full">
          {chat?.profilePicture?.formats?.thumbnail?.url ? (
            <div className="relative w-max mr-3">
              <img
                className="rounded-full min-w-[44px] w-[44px] h-[44px] sm:min-w-[54px] sm:w-[54px] sm:h-[54px] "
                width={50}
                src={chat?.profilePicture?.formats?.thumbnail?.url}
                alt="logo"
              />
              {/* <div className="bg-cyan-400 w-3 h-3 rounded-full border-[2px] border-white absolute bottom-0 right-0"></div> */}
            </div>
          ) : (
            <div className="flex items-center justify-center min-w-[44px] w-[44px] h-[44px] sm:min-w-[54px] sm:w-[54px] sm:h-[54px] rounded-full bg-gr-primaryR1 mr-3">
              <span className="text-base text-gr-primaryR2 font-semibold">
                {chat?.profileType === ProfileTypeEnum?.Club
                  ? getNameInitials(chat?.clubName)
                  : getNameInitials(chat?.firstname, chat?.lastname)}
              </span>
            </div>
          )}

          <div
            className="flex flex-col sm:pl-0 pl-4"
            style={{
              minWidth: isLargeScreen
                ? "calc(100% - 66px)"
                : isMediumScreen
                ? "calc(100% - 72px)"
                : "calc(100% - 44px)",
            }}
          >
            <div className="flex justify-between w-full">
              <div style={{ width: "calc(100% - 50px" }}>
                <div className="flex justify-start items-center mb-1">
                  <div className="font-inter font-semibold text-base text-[#313131] mr-2">
                    {chat?.profileType === ProfileTypeEnum?.Club
                      ? chat?.clubName
                      : `${chat?.firstname} ${chat?.lastname}`}
                  </div>
                  {!!chat?.unreadCount && (
                    <div className="w-[18px] h-[18px] rounded-full bg-red-400 flex justify-center items-center text-white font-inter text-[10px]">
                      {chat?.unreadCount}
                    </div>
                  )}
                </div>
                <div className="font-inter text-gr-neutralT3 text-xs truncate">
                  {chat?.sendBy === currentLoginProfile?.profileId
                    ? `You: ${chat?.lastMessage}`
                    : chat?.lastMessage}
                </div>
              </div>

              <div className="font-inter text-gr-neutralT3 text-[11px] font-semibold mt-1 -mr-1">
                {chat?.lastMessageTime && moment(chat?.lastMessageTime).isSame(Date.now(), "day")
                  ? moment(chat?.lastMessageTime).format("h:mm A")
                  : chat?.lastMessageTime &&
                    !moment(chat?.lastMessageTime).isSame(Date.now(), "day")
                  ? moment(chat?.lastMessageTime).format("DD/MM/YYYY")
                  : ""}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="absolute   top-[42px] right-[55px]   flex justify-center items-center text-red-400 font-inter font-semibold ">
          @
        </div> */}
      </div>
    </div>
  )
}

export default Connections
