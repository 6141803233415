import React, { useContext, useEffect, useState } from "react"
import ProfessionalInfoCard from "../components/playerProfile/ProfessionalInfoCard"
import PersonalInfoCard from "../components/playerProfile/PersonalInfoCard"
import InviteAndReferral from "../components/playerProfile/InviteAndReferral"
import OtherDetail from "../components/playerProfile/OtherDetail"
import SocialNetwork from "../components/playerProfile/SocialNetwork"
import UserClubs from "../components/playerProfile/UserClubs"
import UserHeroSection from "../components/playerProfile/UserHeroSection"
import Opportunities from "../components/dashboard/Opportunities/Opportunities"
import UserFollowers from "../components/playerProfile/UserFollowers"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import AppContext from "../context/app-context/AppContext"
import { IUploadFilesResponse } from "../modals/Config"
import { useUserHook } from "../hooks/useUserHook"
import { useUsersAPI } from "../services/useUsersAPI"
import UserExperience from "../components/playerProfile/UserRugbyExperience"
import { IUser } from "../modals/User"
import UserFollowings from "../components/playerProfile/UserFollowings"
import Highlight from "../components/playerProfile/Highlight"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import { topScroll } from "../utils/CommonUtils"
import { NOT_FOUND } from "../routes/RouteConstants"
import { useScreenWidth } from "../context/ScreenWidthContext"

interface IPlayerProfileProps {
  canEdit?: boolean
}

const UserProfile = (props: IPlayerProfileProps) => {
  // Props
  const { canEdit } = props

  // Context
  const { loginAsProfileDetails, primaryUserDetails } = useContext(AppContext)

  // State
  const [profileData, setProfileData] = useState<IUser | undefined | null>(null)
  const [updateUser, setUpdateUser] = useState<any>(null)

  // APIs
  const { getUserDetailsById } = useUsersAPI()
  const navigate = useNavigate()
  // Hooks
  const { updateUserProfile, fetchLoginAsUserProfile, handleFollowUser } = useUserHook()
  const { userId } = useParams()

  // State
  const [searchParams] = useSearchParams()
  const selectedTab = searchParams.get("tab")

  useEffect(() => {
    if (userId) {
      fetchUserDetailsById()
    } else if (canEdit) {
      setProfileData(loginAsProfileDetails)
    }
    if (userId !== undefined) {
      topScroll()
    }
  }, [userId, loginAsProfileDetails, updateUser])

  const fetchUserDetailsById = async () => {
    try {
      if (!userId) return
      const r = await getUserDetailsById(userId)
      if (r?.data?.user) {
        setProfileData(r?.data?.user)
      } else {
        setProfileData(undefined)
        navigate(`${NOT_FOUND}`)
      }
    } catch {}
  }

  const handleProfileUploadSuccess = async (r: IUploadFilesResponse) => {
    try {
      await updateUserProfile({ profilePicture: String(r?.id) })
    } catch {}
  }
  const handleCoverUploadSuccess = async (r: IUploadFilesResponse) => {
    try {
      await updateUserProfile({ coverPhoto: String(r?.id) })
    } catch {}
  }

  const handleProfileDeleteSuccess = async () => {
    try {
      await fetchLoginAsUserProfile()
    } catch {}
  }

  const onFollowUser = async () => {
    if (!userId) return

    await handleFollowUser(parseInt(userId))
  }
  const screenWidth = useScreenWidth()
  if (profileData === undefined) navigate(NOT_FOUND)
  if (!profileData) return <></>

  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 769 && screenWidth < 1250
  return (
    <div className="">
      <div className="flex flex-col">
        <div className="">
          <HeaderSearchFeild />
        </div>
        <div className=" ">
          <UserHeroSection
            userId={userId}
            onProfileUploadSuccess={handleProfileUploadSuccess}
            onProfileDeleteSuccess={handleProfileDeleteSuccess}
            onCoverUploadSuccess={handleCoverUploadSuccess}
            profileDetails={profileData}
            canEdit={canEdit}
            onClickFollow={onFollowUser}
            setUpdateUser={setUpdateUser}
          />
        </div>

        <div className={`flex px-4 md:px-6 md:mt-8 justify-between`}>
          <div
            className={`flex flex-col gap-[20px] ${
              isLargeScreen
                ? "flex-[25%] max-w-[25%]"
                : isMediumScreen
                ? "flex-[32%] max-w-[32%]"
                : "hidden"
            }`}
          >
            <UserClubs profileDetails={profileData} canEdit={canEdit} />
            <Opportunities />
            {profileData?.id === primaryUserDetails?.id && <InviteAndReferral />}
          </div>
          {/* left */}
          <div
            className={`flex flex-col gap-[20px] sm:mb-[106px] mb-12 sm:mt-0 mt-4  ${
              isLargeScreen
                ? "flex-[73%] max-w-[73%]"
                : isMediumScreen
                ? "flex-[65%] max-w-[65%]"
                : "flex-[100%] max-w-[100%]"
            }`}
          >
            {selectedTab === "followers" ? (
              <UserFollowers profileDetails={profileData} />
            ) : selectedTab === "following" ? (
              <UserFollowings profileDetails={profileData} />
            ) : (
              <>
                <Highlight profileDetails={profileData} canEdit={canEdit} />
                <PersonalInfoCard profileDetails={profileData} canEdit={canEdit} />
                <ProfessionalInfoCard profileDetails={profileData} canEdit={canEdit} />
                <UserExperience profileDetails={profileData} canEdit={canEdit} />
                <OtherDetail profileDetails={profileData} canEdit={canEdit} />
                <SocialNetwork profileDetails={profileData} canEdit={canEdit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
