import React, { SVGProps } from "react"
const NotApproved = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 122.88"
      {...props}
    >
      <path
        d="M6 6a20.53 20.53 0 0129 0l26.5 26.49L87.93 6a20.54 20.54 0 0129 0 20.53 20.53 0 010 29L90.41 61.44l26.49 26.49a20.54 20.54 0 010 29 20.54 20.54 0 01-29 0L61.44 90.41 35 116.9a20.54 20.54 0 01-29 0 20.54 20.54 0 010-29l26.47-26.46L6 34.94A20.53 20.53 0 016 6z"
        fill="#ff4141"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default NotApproved
