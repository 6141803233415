import React, { useContext, useState } from "react"
import AvatarEditor from "react-avatar-editor"
import { GRDialog } from "../ui/GRDialog"
import CameraBtn from "../../assets/svg/CamerBtn.svg"
import BinIcon from "../../assets/svg/BinIcon.svg"
import DialogHeader from "../dialogs/DialogHeader"
import { useConfigAPI } from "../../services/useConfigAPI"
import { IUploadFilesResponse } from "../../modals/Config"
import { getNameInitials } from "../../utils/CommonUtils"
import AppContext from "../../context/app-context/AppContext"
import { useUserHook } from "../../hooks/useUserHook"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import GRButton from "../ui/GRButton"
import RotateLeft from "../../assets/svg/clubprofileicon/RotateLeft"
import RotateRight from "../../assets/svg/clubprofileicon/RotateRight"
import ZoomIn from "../../assets/svg/clubprofileicon/ZoomIn"
import ZoomOut from "../../assets/svg/clubprofileicon/ZoomOut"
import { imageExtensions } from "../../utils/Constants"

interface IProfileImgPopupProps {
  isShowProfileImgDialog: boolean
  onClose: () => void
  onProfileUploadSuccess?: (uploadResponse: IUploadFilesResponse) => void
  onProfileDeleteSuccess?: () => void
  profilePicture?: IUploadFilesResponse
}

const ProfileImgPopup: React.FC<IProfileImgPopupProps> = (props: IProfileImgPopupProps) => {
  // Props
  const {
    isShowProfileImgDialog,
    onClose,
    onProfileUploadSuccess,
    onProfileDeleteSuccess,
    profilePicture,
  } = props

  // State
  const [uploadedProfile, setUploadedProfile] = useState<File | undefined>(undefined)
  const [isDeleteProfilePicture, setIsDeleteProfilePicture] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isSupportedImageType, setIsSupportedImageType] = useState(false)

  // Reference to the AvatarEditor component
  const [avatarEditor, setAvatarEditor] = useState<AvatarEditor | null>(null)

  // APIs
  const { uploadFiles, deleteFiles } = useConfigAPI()

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // Hook
  const { isLoginAsClub } = useUserHook()

  const handleDeleteFile = () => {
    if (uploadedProfile) {
      setIsDeleteProfilePicture(false)
      setUploadedProfile(undefined)
    } else {
      setIsDeleteProfilePicture(true)
    }
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const applyChanges = async () => {
    try {
      if (isEditing) {
        if (avatarEditor) {
          const canvas = avatarEditor.getImageScaledToCanvas()
          canvas.toBlob(async (blob) => {
            if (blob) {
              const formData = new FormData()
              formData.append("files", blob, uploadedProfile?.name)
              const uploadResponse = await uploadFiles(formData)
              onProfileUploadSuccess?.(uploadResponse?.[0])
            }
          })
        }
      } else if (uploadedProfile) {
        const formData = new FormData()
        formData.append("files", uploadedProfile, uploadedProfile?.name)
        const uploadResponse = await uploadFiles(formData)
        onProfileUploadSuccess?.(uploadResponse?.[0])
      } else if (profilePicture && isDeleteProfilePicture) {
        await deleteFiles(profilePicture.id)
        onProfileDeleteSuccess?.()
      }
      onClose()
    } catch (error) {
      console.error("Error applying changes:", error)
    }
  }

  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)

  const handleZoomIn = () => {
    setZoom(zoom * 1.1) // Increase zoom by 10%
  }

  const handleZoomOut = () => {
    setZoom(zoom * 0.9) // Decrease zoom by 10%
  }

  const handleRotateLeft = () => {
    setRotation(rotation - 90) // Rotate counterclockwise by 90 degrees
  }

  const handleRotateRight = () => {
    setRotation(rotation + 90) // Rotate clockwise by 90 degrees
  }

  const onUploadedProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target?.files?.[0]
      if (file && !imageExtensions?.includes(file?.type)) {
        setIsSupportedImageType(true)
        return
      }
      setIsSupportedImageType(false)
      setUploadedProfile(e.target?.files?.[0])
    } catch (error) {}
  }

  const body = (
    <div className="mx-4 sm:mx-0 px-2 sm:px-[55px] mt-[44px] mb-8">
      <div className="bg-white w-full flex justify-center mb-2 -mt-14">
        {isEditing && uploadedProfile ? (
          <AvatarEditor
            className="rounded-lg w-[88px] h-[88px]"
            ref={(editor) => setAvatarEditor(editor)}
            image={URL.createObjectURL(uploadedProfile)}
            width={450}
            borderRadius={225}
            height={450}
            color={[255, 255, 255, 0.6]}
            scale={zoom}
            rotate={rotation}
          />
        ) : isLoginAsClub ? (
          <img
            className="w-[88px] h-[88px] sm:w-[150px] sm:h-[150px] md:w-[227px] md:h-[227px] rounded-full "
            src={!isDeleteProfilePicture && profilePicture?.url ? profilePicture?.url : ClubLogo}
            alt="clubLogo"
          />
        ) : (
          <div className="flex justify-center items-center w-[88px] h-[88px] sm:w-[150px] sm:h-[150px] md:w-[227px] md:h-[227px] rounded-full bg-gr-primaryR1">
            {profilePicture?.url && !isDeleteProfilePicture ? (
              <img
                className="w-[88px] h-[88px] sm:w-[150px] sm:h-[150px] md:w-[227px] md:h-[227px] rounded-full "
                src={profilePicture?.url}
                alt="clubLogo"
              />
            ) : (
              <span className="md:text-8xl sm:text-6xl text-3xl text-white font-semibold">
                {isLoginAsClub
                  ? getNameInitials(loginAsProfileDetails?.club?.name)
                  : getNameInitials(
                      loginAsProfileDetails?.firstname,
                      loginAsProfileDetails?.lastname,
                    )}
              </span>
            )}
          </div>
        )}
      </div>
      <div>
        {isSupportedImageType && (
          <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
            Supported files include: images (jpg, jpeg, gif*, png, svg, webp)
          </span>
        )}
      </div>
      {isEditing && (
        <div className="flex justify-between mb-2 -mt-2">
          <div className="flex">
            <button
              className="text-red-darkRed text-[14px] font-inter mr-12"
              onClick={handleRotateLeft}
            >
              <RotateLeft />
            </button>
            <button className="text-red-darkRed text-[14px] font-inter" onClick={handleRotateRight}>
              <RotateRight />
            </button>
          </div>
          <div className="flex">
            <button
              className="text-red-darkRed text-[14px] font-inter mr-12"
              onClick={handleZoomIn}
            >
              <ZoomIn />
            </button>
            <button className="text-red-darkRed text-[14px] font-inter" onClick={handleZoomOut}>
              <ZoomOut />
            </button>
          </div>
        </div>
      )}

      <div className="border-b border-gr-primaryN8"></div>
      <div className="flex justify-between items-center mt-2">
        <GRButton onClick={onClose} label="Cancel" type="button" className=" w-[6vw]" />
        <label htmlFor="file-upload" className="cursor-pointer">
          <img src={CameraBtn} alt="camera icon" onClick={handleEditClick} />
        </label>
        <input
          accept="image/*"
          className="hidden"
          name="profileImage"
          id="file-upload"
          type="file"
          onChange={(e) => onUploadedProfile(e)}
        />
        {(uploadedProfile || profilePicture) && (
          <div
            onClick={handleDeleteFile}
            className="flex items-center gap-[12px] cursor-pointer inline"
          >
            <img src={BinIcon} alt="delete icon" />
            <div className="mt-[4px] text-red-darkRed text-[12px] font-inter ">Delete</div>
          </div>
        )}
        <GRButton label="Apply" className="!px-[35px] w-[5vw]" onClick={applyChanges} />
        {/* </div> */}
      </div>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowProfileImgDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[617px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Profile Image" onClose={onClose} />}
      />
    </>
  )
}

export default ProfileImgPopup
