import React, { ReactNode, useState } from "react"
import EditBtn from "../../../assets/svg/EditBtn"
import GlobalRugbyLogo from "../../../assets/svg/GlobalRugbyLogo"
import MailIcon from "../../../assets/svg/MailIcon.svg"
import Phoneicon from "../../../assets/svg/PhoneIcon.svg"
import Facebook from "../../../assets/svg/clubprofileicon/Facebook.svg"
import Twitter from "../../../assets/svg/clubprofileicon/Twitter.svg"
import Insta from "../../../assets/svg/clubprofileicon/Insta.svg"
import Linkedin from "../../../assets/svg/clubprofileicon/LinkedIn.svg"
import TiktokIcon from "../../../assets/svg/Tiktok.svg"
import SnapChatIcon from "../../../assets/svg/SnapChat.svg"
import ClubContactDetailsDialog from "../../../components/dialogs/ClubContactDetailsDialog"
import { IClubDetails } from "../../../modals/Club"

interface IMembers {
  clubData?: IClubDetails
  isAdmin: boolean | undefined
  onSuccess: () => void
}

function OtherDetails(props: IMembers) {
  // Props
  const { clubData, isAdmin, onSuccess } = props

  // State
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className="rounded-2xl min-h-[332px] px-6 py-4 bg-white">
      <div className="flex justify-between items-center mt-2">
        <div className="font-inter text-2xl text-gr-primaryN1 font-semibold">Contact details</div>
        <ClubContactDetailsDialog
          open={open}
          onClose={() => setOpen(false)}
          clubData={clubData}
          onSuccess={() => {
            setOpen(false)
            onSuccess()
          }}
        />

        {isAdmin && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setOpen(true)
            }}
          >
            <EditBtn />
          </div>
        )}
      </div>
      <div className="border border-gray-200 mt-4 mb-5"></div>
      <div className="flex md:flex-row flex-col-reverse justify-between md:px-4">
        <div className="flex flex-col gap-4 md:my-0 my-4">
          <GlobalRugbyLogo width={45} height={47} />
          {clubData?.attributes?.email && (
            <div className="pl-4 flex gap-3 text-[14px] justify-start items-center">
              <img src={MailIcon} className="w-[17px] h-[17px]" />
              <p className="text-gr-neutralT2 font-inter">{clubData?.attributes?.email}</p>
            </div>
          )}
          {clubData?.attributes?.mobileNumber && (
            <div className="pl-4 flex gap-3 text-[14px] justify-start items-center">
              <img src={Phoneicon} className="w-[17px] h-[17px]" />
              <p className="text-gr-neutralT2 font-inter">{clubData?.attributes?.mobileNumber}</p>
            </div>
          )}
          <div className="pl-[46px] flex gap-4 justify-start items-center">
            {clubData?.attributes?.facebookLink && (
              <SocialMediaCard
                icon={<img src={Facebook} alt="icon" />}
                link={
                  clubData?.attributes?.facebookLink?.includes("https://") ||
                  clubData?.attributes?.facebookLink?.includes("http://")
                    ? clubData?.attributes?.facebookLink
                    : `https://${clubData?.attributes?.facebookLink}`
                }
              />
            )}
            {clubData?.attributes?.linkedinLink && (
              <SocialMediaCard
                icon={<img src={Linkedin} alt="icon" />}
                link={
                  clubData?.attributes?.linkedinLink?.includes("https://") ||
                  clubData?.attributes?.linkedinLink?.includes("http://")
                    ? clubData?.attributes?.linkedinLink
                    : `https://${clubData?.attributes?.linkedinLink}`
                }
              />
            )}
            {clubData?.attributes?.twitterLink && (
              <SocialMediaCard
                icon={<img src={Twitter} alt="icon" />}
                link={
                  clubData?.attributes?.twitterLink?.includes("https://") ||
                  clubData?.attributes?.twitterLink?.includes("http://")
                    ? clubData?.attributes?.twitterLink
                    : `https://${clubData?.attributes?.twitterLink}`
                }
              />
            )}
            {clubData?.attributes?.instagramLink && (
              <SocialMediaCard
                icon={<img src={Insta} alt="icon" />}
                link={
                  clubData?.attributes?.instagramLink?.includes("https://") ||
                  clubData?.attributes?.instagramLink?.includes("http://")
                    ? clubData?.attributes?.instagramLink
                    : `https://${clubData?.attributes?.instagramLink}`
                }
              />
            )}
            {clubData?.attributes?.snapchatLink && (
              <SocialMediaCard
                icon={<img src={SnapChatIcon} alt="icon" />}
                link={
                  clubData?.attributes?.snapchatLink?.includes("https://") ||
                  clubData?.attributes?.snapchatLink?.includes("http://")
                    ? clubData?.attributes?.snapchatLink
                    : `https://${clubData?.attributes?.snapchatLink}`
                }
              />
            )}
            {clubData?.attributes?.tiktokLink && (
              <SocialMediaCard
                icon={<img src={TiktokIcon} alt="icon" width={18} height={18} />}
                link={
                  clubData?.attributes?.tiktokLink?.includes("https://") ||
                  clubData?.attributes?.tiktokLink?.includes("http://")
                    ? clubData?.attributes?.tiktokLink
                    : `https://${clubData?.attributes?.tiktokLink}`
                }
              />
            )}
          </div>
        </div>
        {/* <div>
          <img src={Map}></img>
        </div> */}
      </div>
    </div>
  )
}
interface IGRSocialMediaCardProps {
  icon?: ReactNode
  link?: any
}
const SocialMediaCard: React.FC<IGRSocialMediaCardProps> = (props: IGRSocialMediaCardProps) => {
  return (
    <div className="flex justify-center items-center border rounded w-9 h-9 hover:bg-bgRed hover:shadow-md">
      <a href={props.link} target="_blank" rel="noopener noreferrer" className="w-5">
        {props.icon}
      </a>
    </div>
  )
}
export default OtherDetails
