import React, { useState } from "react"
import { Under16Form } from "../dialogs/AddFamilyMemberDialog"
import { FamilyMemberDialogEnum } from "../../utils/Constants"
import { GRDialog } from "../ui/GRDialog"

import CrossIcon from "../../assets/svg/CrossIcon"

interface ICreateFamilyMemberDialog {
  isOpen: boolean
  onSuccess: () => void
  onClose: () => void
}

const Child = (props: ICreateFamilyMemberDialog) => {
  // Props
  const { isOpen, onSuccess, onClose } = props
  const [activeTab] = useState<FamilyMemberDialogEnum>(FamilyMemberDialogEnum.BasicInfo)

  const dialogHeader = (
    <div className="">
      <div className="lg:mx-[32px] mx-[15px] flex justify-between items-center border-b border-b-gr-primaryN8 mb-5">
        <p className="font-inter sm:text-2xl text-xl font-semibold leading-6 text-black my-5">
          Add family member <br className="sm:hidden" /> (new GR user)
        </p>
        <div
          className="text-2xl leading-10 font-bold font-inter text-gr-primaryR3 cursor-pointer"
          onClick={() => onClose()}
        >
          <CrossIcon />
        </div>
      </div>
      <>
        <div className="mx-[15px] lg:mx-[31px]">
          <p className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-2 font-inter">
            Basic info
          </p>
        </div>
        <div className="flex mx-[15px] md:mx-[31px] mb-4">
          <span className="text-red-600 text-lg -mt-2">*</span>
          <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
        </div>
      </>
    </div>
  )

  const body = (
    <>
      <div className="lg:px-[31px] px-[15px] mb-4"></div>
      <Under16Form onSuccess={onSuccess} onClose={onClose} activeTab={activeTab} />
    </>
  )

  return (
    <div>
      <GRDialog
        open={isOpen}
        onClose={() => onClose()}
        dialogtitle={dialogHeader}
        dialogbody={body}
        fullWidth
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
      />
    </div>
  )
}

export default Child
