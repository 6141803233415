import React, { useState } from "react"
import CreateFamilyMemberDialog from "../dialogs/AddFamilyMemberDialog"
import ConnectFamilyMemberDialog from "../dialogs/ConnectFamilyMemberDialog"

interface IEmptyTileProps {
  title: string
}

const EmptyTileFamilyMembers = (props: IEmptyTileProps) => {
  const { title } = props
  const [isShowAddFamilyMemberDialog, setIsShowAddFamilyMemberDialog] = useState<boolean>(false)
  const [isShowConnectFamilyMemberDialog, setIsShowConnectFamilyMemberDialog] =
    useState<boolean>(false)
  return (
    <div className="bg-white min-h-[219px] flex justify-center items-center w-full rounded-2xl">
      <div className="flex flex-col justify-center items-center">
        <p className="font-inter font-semibold text-sm leading-[18px] text-black mb-1">{title}</p>
        <button
          className="font-inter text-sm font-normal leading-[17px] text-gr-placeHolder underline hover:text-gr-primaryR2"
          onClick={(event) => {
            event.stopPropagation()
            setIsShowAddFamilyMemberDialog(true)
          }}
        >
          Add new family members (new GR user)
        </button>
        <button
          className="font-inter text-sm font-normal leading-[17px] mt-2 text-gr-placeHolder underline hover:text-gr-primaryR2"
          onClick={(event) => {
            event.stopPropagation()
            setIsShowConnectFamilyMemberDialog(true)
          }}
        >
          Connect with family member (current GR user)
        </button>
      </div>
      <CreateFamilyMemberDialog
        isOpen={isShowAddFamilyMemberDialog}
        onClose={() => setIsShowAddFamilyMemberDialog(false)}
        onSuccess={() => {
          setIsShowAddFamilyMemberDialog(false)
        }}
      />
      <ConnectFamilyMemberDialog
        isShowDialog={isShowConnectFamilyMemberDialog}
        onClose={() => setIsShowConnectFamilyMemberDialog(false)}
        onSuccess={() => {
          setIsShowConnectFamilyMemberDialog(false)
        }}
      />
    </div>
  )
}

export default EmptyTileFamilyMembers
