import React, { SVGProps } from "react"

const EditPenIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.004 1.837a.583.583 0 01.825 0l2.333 2.334a.583.583 0 010 .825L5.58 12.579a.583.583 0 01-.412.171H2.833a.583.583 0 01-.583-.583V9.833c0-.154.061-.303.17-.412l5.834-5.833 1.75-1.75zM8.667 4.825l-5.25 5.25v1.508h1.508l5.25-5.25-1.508-1.508zM11 5.508l.925-.925-1.508-1.508L9.492 4 11 5.508z"
        fill="#9F0002"
      />
    </svg>
  )
}

export default EditPenIcon
