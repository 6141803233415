import React, { useContext, useEffect, useState } from "react"
import Opportunities from "../dashboard/Opportunities/Opportunities"
import UserFollowers from "./UserFollowers"
import AppContext from "../../context/app-context/AppContext"
import { useUserHook } from "../../hooks/useUserHook"
import ClubFollowers from "../club/ClubFollowers"
import { IClubDetails } from "../../modals/Club"
import { useClubAPI } from "../../services/useClubAPI"
import { useScreenWidth } from "../../context/ScreenWidthContext"

const UserFollower = () => {
  const { loginAsProfileDetails, currentLoginProfile } = useContext(AppContext)
  const { isLoginAsClub } = useUserHook()
  const [clubData, setClubData] = useState<IClubDetails>()
  const { getClubById } = useClubAPI()

  const clubId = loginAsProfileDetails?.club?.id || currentLoginProfile?.profileId
  // useEffect
  useEffect(() => {
    if (clubId) fetchClubDetails()
  }, [])

  // Functions
  const fetchClubDetails = async () => {
    try {
      if (!clubId) return
      const r = await getClubById(String(clubId))
      setClubData(r?.data)
    } catch {}
  }
  const screenWidth = useScreenWidth()
  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 769 && screenWidth < 1250

  return (
    <div className="flex">
      {isMediumScreen || isLargeScreen ? (
        <div
          className={`ml-4 sm:mb-[106px] mb-12 sm:mt-4 mt-4  ${
            isLargeScreen
              ? "flex-[24%] max-w-[24%]"
              : isMediumScreen
              ? "flex-[40%] max-w-[40%]"
              : "flex-[100%] max-w-[100%]"
          }`}
        >
          <Opportunities />
        </div>
      ) : null}
      <div
        className={`ml-4 mr-4 sm:mr-0 sm:mb-[106px] mb-12 sm:mt-4 mt-4  ${
          isLargeScreen
            ? "flex-[73%] max-w-[73%]"
            : isMediumScreen
            ? "flex-[65%] max-w-[65%]"
            : "flex-[100%] max-w-[100%]"
        }`}
      >
        {!isLoginAsClub ? (
          <UserFollowers profileDetails={loginAsProfileDetails} />
        ) : (
          <ClubFollowers clubData={clubData} />
        )}
      </div>
    </div>
  )
}

export default UserFollower
