import React, { useContext } from "react"
import { GRDialog } from "../ui/GRDialog"
import { Box } from "@mui/material"
import { useFormik } from "formik"
import ClubImg from "../../assets/svg/ClubImg"
import GRButton from "../ui/GRButton"
import DialogHeader from "./DialogHeader"
import { EditOpportunitySchema } from "../../validations/Validations"
import AppContext from "../../context/app-context/AppContext"
import { convertIntoDropDownOptions } from "../../utils/CommonUtils"
import { profileOptions, OpportunityStatus } from "../../utils/Constants"
import { IOpportunitySummary } from "../../modals/Opportunity"
import { useOpportunityAPI, IUpdateOpportunityPayload } from "../../services/useOpportunityAPI"
import { errorToast, successToast } from "../../utils/ToastUtils"
import Autocomplete from "@mui/material/Autocomplete"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import useLocationHook from "../../hooks/useLocationHook"
import { TextField } from "@mui/material"

interface IEditOpportunityFormValues {
  season?: string
  agegroup?: string
  position?: string
  level?: string
  country?: string
  othercriteria?: string
  description?: string
  discipline?: string
  profiletype?: string
  status?: string
}

const initialValues = {
  season: "",
  agegroup: "",
  position: "",
  level: "",
  country: "",
  othercriteria: "",
  description: "",
  discipline: "",
  profiletype: "",
  status: "",
}

interface IEditOpportunityProps {
  isShowDialog: boolean
  onClose: () => void
  opportunity?: IOpportunitySummary
  updateOpportunity: () => void
}

const EditOpportunityDialog = (props: IEditOpportunityProps) => {
  const { isShowDialog, onClose } = props
  //context
  const { dropDownOptions, loginAsProfileDetails } = useContext(AppContext)
  //API
  const { isLoading: isSubmitting, updateOpportunity } = useOpportunityAPI()
  const { countryOptions, fetchStateByCountry } = useLocationHook()

  const EditOpportunity = async (values: IEditOpportunityFormValues) => {
    try {
      if (!props?.opportunity?.id) return
      const opportunityPayload: IUpdateOpportunityPayload = {
        data: {
          id: props?.opportunity?.id || null,
          season: values?.season || "",
          ageGroup: values?.agegroup || "",
          playerPosition: values?.profiletype === "Player" ? values?.position || null : null,
          coachPosition: values?.profiletype === "Coach" ? values?.position || null : null,
          level: values?.level || "",
          discipline: values?.discipline || "",
          profileType: values?.profiletype || "",
          country: values?.country || "",
          otherCriteria: values?.othercriteria || "",
          description: values?.description || "",
          opportunityThumbnail: props?.opportunity?.opportunityThumbnail?.id, // I will update this after implement upload file api
          club: loginAsProfileDetails?.club?.id || null, // I will update after fix club url
          status: values?.status === "Active" ? true : false,
        },
      }
      const request = await updateOpportunity(opportunityPayload)
      if (request?.opportunity?.id) {
        successToast("Opportunity details updated successfully")
        props?.updateOpportunity()
        onClose()
      }
    } catch (error) {
      errorToast("Something went wrong!")
      onClose()
    }
  }

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      season: props?.opportunity?.season?.id ? String(props?.opportunity?.season?.id) : "",
      agegroup: props?.opportunity?.ageGroup?.id ? String(props?.opportunity?.ageGroup?.id) : "",
      position:
        props?.opportunity?.profileType === "Player"
          ? props?.opportunity?.playerPosition?.id
            ? String(props?.opportunity?.playerPosition?.id)
            : ""
          : props?.opportunity?.coachPosition?.id
          ? String(props?.opportunity?.coachPosition?.id)
          : "",
      level: props?.opportunity?.level?.id ? String(props?.opportunity?.level?.id) : "",
      country: props?.opportunity?.country ? props?.opportunity?.country : "",
      othercriteria: props?.opportunity?.otherCriteria ? props?.opportunity?.otherCriteria : "",
      description: props?.opportunity?.description ? props?.opportunity?.description : "",
      discipline: props?.opportunity?.discipline?.id
        ? String(props?.opportunity?.discipline?.id)
        : "",
      profiletype: props?.opportunity?.profileType ? props?.opportunity?.profileType : "",
      status: props?.opportunity?.status ? "Active" : "Inactive",
    },
    onSubmit: EditOpportunity,
    validationSchema: EditOpportunitySchema,
    enableReinitialize: true,
  })
  // const [file, setFile] = useState<File | null>(null)
  // const [files, setFiles] = useState<string[]>([])
  // const handleFileUplaod = (e: ChangeEvent<HTMLInputElement>) => {
  //   setFile(e.target.files?.[0] || null)
  // }
  // useEffect(() => {
  //   if (file) {
  //     setFiles([...files, file.name])
  //   }
  // }, [file])
  // const handleDelete = (name: string) => {
  //   const newFiles = files.filter((f) => f !== name)
  //   setFiles(newFiles)
  // }
  const body = (
    <div className="mx-[15px] lg:mx-[31px]">
      <div className="flex justify-start items-center gap-[12px]">
        {loginAsProfileDetails?.club?.clubLogo?.formats?.thumbnail?.url ? (
          <img
            src={loginAsProfileDetails?.club?.clubLogo?.formats?.thumbnail?.url}
            alt="clublogo"
            className="rounded-full w-[54px] h-[54px]"
          />
        ) : (
          <ClubImg className="rounded-full w-[54px] h-[54px]" />
        )}
        <span className="text-[15px] font-medium sm:text-lg">
          {loginAsProfileDetails?.club?.name}
        </span>
      </div>
      <div className="flex mt-3 mb-4">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>
      {/* season */}
      <form onSubmit={formik.handleSubmit} className="compeleteProfileForm">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="season">
              Season<span className="text-red-600">*</span>
            </label>
            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="season"
                defaultValue=""
                name="season"
                onChange={formik.handleChange}
                value={formik.values.season}
                options={convertIntoDropDownOptions(
                  dropDownOptions?.seasons.filter((seasons) => {
                    return (
                      seasons.status === "Current" ||
                      seasons.status === "Next" ||
                      seasons.status === "Future"
                    )
                  }),
                )}
                errormsg={formik.touched.season && formik.errors.season ? formik.errors.season : ""}
              />
            </Box>
          </div>
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="status">
              Status
            </label>
            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="status"
                defaultValue=""
                name="status"
                onChange={formik.handleChange}
                value={formik.values.status}
                options={OpportunityStatus}
                errormsg={formik.touched.status && formik.errors.status ? formik.errors.status : ""}
              />
            </Box>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
          {/* Profile type */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="profiletype">
              Profile type
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="profiletype"
                defaultValue=""
                name="profiletype"
                onChange={formik.handleChange}
                value={formik.values.profiletype}
                options={profileOptions}
                errormsg={
                  formik.touched.profiletype && formik.errors.profiletype
                    ? formik.errors.profiletype
                    : ""
                }
              />
            </Box>
          </div>
          {/* position */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="discipline">
              Code
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="discipline"
                defaultValue=""
                name="discipline"
                onChange={formik.handleChange}
                value={formik.values.discipline}
                options={convertIntoDropDownOptions(dropDownOptions?.discipline)}
                errormsg={
                  formik.touched.discipline && formik.errors.discipline
                    ? formik.errors.discipline
                    : ""
                }
              />
            </Box>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 ">
          {/* Age group */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="agegroup">
              Age Group <span className="text-red-600">*</span>
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="agegroup"
                defaultValue=""
                name="agegroup"
                onChange={formik.handleChange}
                value={formik.values.agegroup}
                options={convertIntoDropDownOptions(dropDownOptions?.ageGroup)}
                errormsg={
                  formik.touched.agegroup && formik.errors.agegroup ? formik.errors.agegroup : ""
                }
              />
            </Box>
          </div>
          {/* position */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="position">
              Position<span className="text-red-600">*</span>
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="position"
                defaultValue=""
                name="position"
                onChange={formik.handleChange}
                value={formik.values.position}
                options={
                  formik.values.profiletype === "Player"
                    ? convertIntoDropDownOptions(dropDownOptions?.playerPosition)
                    : convertIntoDropDownOptions(dropDownOptions?.coachPosition)
                }
                errormsg={
                  formik.touched.position && formik.errors.position ? formik.errors.position : ""
                }
              />
            </Box>
          </div>
        </div>
        <div className="grid  gap-x-5 grid-cols-1 lg:grid-cols-2 lg:gap-6">
          {/* level */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="level">
              Level<span className="text-red-600">*</span>
            </label>

            <Box sx={{ width: "100%" }}>
              <GRSelect
                id="level"
                defaultValue=""
                name="level"
                onChange={formik.handleChange}
                value={formik.values.level}
                options={convertIntoDropDownOptions(dropDownOptions?.level)}
              />
            </Box>
            {formik.touched.level && formik.errors.level && (
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase !font-inter">
                {formik.errors.season}
              </div>
            )}
          </div>
          {/* country */}
          <div className="w-full mb-4">
            <label className="block text-sm leading-6 font-inter mb-1" htmlFor="level">
              Country<span className="text-red-600">*</span>
            </label>
            <div className="w-full">
              <Autocomplete
                id="country"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("country", newValue || "")
                  if (newValue) fetchStateByCountry(newValue)
                }}
                renderOption={(formik, option) => <li {...formik}>{option}</li>}
                onBlur={formik?.handleBlur}
                value={formik?.values?.country}
                options={countryOptions?.map((c) => c?.name) || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Country" sx={AutocompleteInputCss} />
                )}
              />
              {formik?.touched?.country && formik?.errors?.country && (
                <div className=" text-[13px] text-red-500">{formik?.errors?.country}</div>
              )}
            </div>
          </div>
        </div>
        {/* other criteria */}
        <div className="mb-4">
          <label className="block text-sm leading-6 font-inter mb-1" htmlFor="">
            Other Criteria
          </label>
          <input
            type="text"
            placeholder="Enter other criteria"
            className="border border-gr-primaryN8 w-full rounded-lg px-[10px] py-2"
            name="othercriteria"
            onChange={formik.handleChange}
            value={formik.values.othercriteria}
          />
        </div>
        {/* description */}
        <div>
          <label className="block text-sm leading-6 font-inter mb-1" htmlFor="">
            Description <span className="text-gr-neutral05">{`(optional)`}</span>
          </label>

          <textarea
            className="border border-[rgba(0,0,0,0.23)] p-[10px] w-full h-[88px] rounded-lg"
            placeholder="Write your message ..."
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
          ></textarea>
        </div>
        <div className="flex justify-between items-center mt-6 mb-8">
          <GRButton type="button" onClick={onClose} label="Cancel" className="w-[100px]" />
          <GRButton
            onClick={formik.handleSubmit}
            isSubmitting={isSubmitting}
            label="Update"
            className="w-[100px]"
          />
        </div>
      </form>
    </div>
  )
  return (
    <GRDialog
      open={isShowDialog}
      onClose={() => onClose()}
      classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
      dialogbody={body}
      dialogtitle={<DialogHeader title="Edit Opportunity" onClose={onClose} />}
    />
  )
}

// interface IFilesCard {
//   name: string
//   handleDelete: (name: string) => void
// }
// const FilesCard: React.FC<IFilesCard> = ({ name, handleDelete }) => {
//   return (
//     <div className="flex justify-between items-center bg-bgRed rounded-[24px] px-[30px] py-[12px]">
//       <div className="flex gap-4 items-center">
//         <div>
//           <img src={File} alt="fileIcon" className="w-[20px] h-[20px]" />
//         </div>
//         <div>{name}</div>
//       </div>
//       <div className="cursor-pointer" onClick={() => handleDelete(name)}>
//         <img src={Bin} alt="deleteIcon" />
//       </div>
//     </div>
//   )
// }

export default EditOpportunityDialog
