import * as React from "react"
import { SVGProps } from "react"

const VideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={31} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M42.9 2.436a1.988 1.988 0 0 0-2.2 0l-9.68 6.643v-4.65C31.02 1.993 29.04 0 26.62 0H4.4C1.98 0 0 1.993 0 4.429V26.57C0 29.007 1.98 31 4.4 31h22c2.42 0 4.4-1.993 4.4-4.429v-4.65l9.68 6.643c.44.222.88.443 1.32.443.44 0 .66 0 1.1-.221.66-.443 1.1-1.107 1.1-1.993V4.429c0-.886-.44-1.55-1.1-1.993ZM26.4 26.57h-22V4.43h22V26.57Zm13.2-4.207-8.8-5.978v-1.993l8.8-5.979v13.95Z"
      fill="#6A984D"
    />
  </svg>
)

export default VideoIcon
