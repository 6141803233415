import React from "react"
import LoginForm from "../forms/LoginForm"
import SSOLogin from "../loginRegistration/SSOLogin"

function Login() {
  return (
    <div className="flex justify-center items-center py-10 align-v-center">
      <div className="flex flex-col items-center rounded-2xl w-full sm:w-[570px] px-[30px] sm:px-[60px] py-4 bg-white opacity-90">
        <div className="text-xl md:text-[32px] font-microsoftTai mb-5 font-bold mt-2">Login</div>
        <LoginForm />
        <SSOLogin isLogin={false} />
      </div>
    </div>
  )
}

export default Login
