import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import React, { useEffect, useState } from "react"
import { useLocation, Link } from "react-router-dom"
import DropDownIcon from "../../assets/svg/DropDownIcon.svg"
import GlobalRugbyLogo from "../../assets/svg/GlobalRugbyLogo"
import GlobalRugbyLogoSvg from "../../assets/svg/GlobalRugbyLogo.svg"
import { AUTH_DASHBOARD } from "../../routes/RouteConstants"
import GRButton from "../ui/GRButton"
import { ITopNavBar } from "../../services/useLoginUserAPI"

interface IHeaderProps {
  type?: any
  topNavBar: ITopNavBar[] | undefined
  onCreateAccount: () => void
  isCreateAccount: boolean
}

const Header: React.FC<IHeaderProps> = (props) => {
  const { topNavBar, onCreateAccount, isCreateAccount } = props
  //Hook
  const [menu, setMenu] = useState<ITopNavBar[]>()
  // UseEffect
  useEffect(() => {
    topNavBar && setMenu(topNavBar)
  }, [topNavBar])

  return (
    <div>
      <header className="w-full sm:h-[80px] md:px-6 font-microsoftTai ">
        <div className="py-1 flex items-center justify-between">
          <div className="flex items-center sm:gap-[16px] md:gap-[50px] justify-start md:justify-center">
            <div className="block md:hidden  cursor-pointer">
              <TemporaryDrawer
                topNavBar={topNavBar}
                onCreateAccount={onCreateAccount}
                isCreateAccount={isCreateAccount}
              />
            </div>
            <img src={GlobalRugbyLogoSvg} className="w-[76px] h-[70px]" />
          </div>

          <div>
            <nav className="flex items-center justify-between space-x-3 md:space-x-8">
              <div className="hidden md:flex items-center gap-[30px]">
                {menu?.map((m) => (
                  <div
                    key={m?.title}
                    className="hover:text-gray-400 text-base font-normal font-inter"
                  >
                    <a href={`#${m?.url}`}>{m?.title}</a>
                  </div>
                ))}
                <div className=" flex justify-end">
                  <GRButton
                    type="button"
                    onClick={onCreateAccount}
                    fontSize="12px"
                    height={33}
                    borderRadius={100}
                    label={`${isCreateAccount ? "Login" : "Create account"}`}
                    className="w-[150px]"
                  />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  )
}

type Anchor = "top" | "left" | "bottom" | "right"
interface ITemporaryDrawerProps {
  topNavBar: ITopNavBar[] | undefined
  onCreateAccount: () => void
  isCreateAccount: boolean
}
interface StateType {
  left: boolean
}
const TemporaryDrawer: React.FC<ITemporaryDrawerProps> = ({
  topNavBar,
  onCreateAccount,
  isCreateAccount,
}) => {
  // State
  const [state, setState] = useState<StateType>({ left: false })
  const [menu, setMenu] = useState<ITopNavBar[]>()

  // APIs

  // Context

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }

      setState({ ...state, [anchor]: open })
    }

  const location = useLocation()

  useEffect(() => {
    setState({ left: false })
  }, [location])
  useEffect(() => {
    setMenu(topNavBar)
  }, [topNavBar])

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 319 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to={AUTH_DASHBOARD} className="px-7">
                <GlobalRugbyLogo width={48} height={50} />
              </Link>
            </ListItemIcon>
            <ListItemText />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      {menu?.map((m) => (
        <div key={m?.title} className="hover:text-gray-400 text-base font-normal font-inter">
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <a className="w-full" href={`#${m?.url}`}>
                  {m?.title}
                </a>
                <ListItemText />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </div>
      ))}
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <GRButton
                type="button"
                onClick={onCreateAccount}
                fontSize="12px"
                height={33}
                borderRadius={100}
                label={`${isCreateAccount ? "Login" : "Create account"}`}
                className="w-[150px]"
              />
            </ListItemIcon>
            <ListItemText />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <img src={DropDownIcon} />{" "}
          </Button>
          <Drawer
            sx={{
              display: {
                md: "none",
              },
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Header
