import React, { useContext, useEffect, useState } from "react"
import ClubDetails from "../components/club/clubprofile/Clubdetails"
import HeroSection from "../components/club/clubprofile/HeroSection"
import About from "../components/club/clubprofile/About"
import Members from "../components/club/clubprofile/Members"
import OtherDetails from "../components/club/clubprofile/ContactDetails"
import Opportunities from "../components/club/clubprofile/Opportunities"
import AllPosts from "../components/club/clubprofile/AllPosts"
import { useClubAPI } from "../services/useClubAPI"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { IClubDetails } from "../modals/Club"
import AppContext from "../context/app-context/AppContext"
import { successToast } from "../utils/ToastUtils"
import { useLoginUserAPI } from "../services/useLoginUserAPI"
import { IUploadFilesResponse } from "../modals/Config"
import useClubHook from "../hooks/useClubHook"
import ClubFollowers from "../components/club/ClubFollowers"
import HeaderSearchFeild from "../components/Header/HeaderSearchFeild"
import { NOT_FOUND } from "../routes/RouteConstants"
import { useScreenWidth } from "../context/ScreenWidthContext"
import { IOpportunitySummary } from "@App/modals/Opportunity"

interface IClubProfileProps {
  canEdit?: boolean
}

function ClubProfile(props: IClubProfileProps) {
  // Props
  const { canEdit } = props
  // State
  const [selectedSection, setSelectedSection] = useState<string | null>("about")
  const [clubData, setClubData] = useState<IClubDetails | undefined | null>(null)
  const [adminStatus, setAdminStatus] = useState<boolean>()
  const [opportunitiesNo, setOpportunitiesNo] = useState<IOpportunitySummary[]>()
  const [memberNo, setmemberNo] = useState<IClubDetails>()

  // Context
  const { loginAsProfileDetails, currentLoginProfile } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const selectedTab = searchParams.get("tab")

  // APIs
  const { getClubById, sendClaimAdminRequest } = useClubAPI()
  const { getAdminRequestStatus } = useLoginUserAPI()

  // Hooks
  let { clubId } = useParams()
  const { updateClubProfile } = useClubHook()

  // Constants
  clubId = canEdit
    ? loginAsProfileDetails?.club?.id?.toString() || currentLoginProfile?.profileId?.toString()
    : clubId

  // useEffect
  useEffect(() => {
    if (clubId) fetchClubDetails()
    if (loginAsProfileDetails && clubId) fetchAdminRequestStatus(loginAsProfileDetails?.id, clubId)
  }, [loginAsProfileDetails, clubId])

  const navigate = useNavigate()

  // Functions
  const fetchClubDetails = async () => {
    try {
      if (!clubId) return
      const r = await getClubById(clubId)
      if (r?.data && r?.data?.attributes?.name !== "error") {
        setClubData(r?.data)
      } else {
        setClubData(undefined)
        navigate(`${NOT_FOUND}`)
      }
    } catch {
      setClubData(undefined)
      navigate(`${NOT_FOUND}`)
    }
  }

  const handleSuccess = () => {
    fetchClubDetails()
  }

  const fetchAdminRequestStatus = async (userId: number, cId: string) => {
    try {
      const r = await getAdminRequestStatus(cId)
      setAdminStatus(r?.data?.approve)
    } catch {}
  }

  const handleSectionClick = (section: string) => {
    setSelectedSection(section)
  }

  const handleSendClaimRequest = async () => {
    try {
      if (!clubId || !loginAsProfileDetails) {
        return
      }
      await sendClaimAdminRequest({
        data: { club: clubId, user: String(loginAsProfileDetails?.id) },
      })
      successToast("Request Sent")
    } catch {}
  }
  const screenWidth = useScreenWidth()
  if (clubData === undefined) navigate(NOT_FOUND)

  if (!clubData) return <></>

  const renderView = () => {
    switch (selectedSection) {
      case "about":
        return <About isAdmin={canEdit} clubData={clubData} onSuccess={handleSuccess} />
      case "posts":
        return <AllPosts isAdmin={canEdit} />
      case "opportunities":
        return <Opportunities isAdmin={canEdit} setOpportunitiesNo={setOpportunitiesNo} />
      case "members":
        return <Members isAdmin={canEdit} clubData={clubData} setmemberNo={setmemberNo} />
      case "otherDetails":
        return <OtherDetails isAdmin={canEdit} clubData={clubData} onSuccess={handleSuccess} />

      default:
        return <About isAdmin={canEdit} clubData={clubData} onSuccess={handleSuccess} />
    }
  }

  const handleProfileUploadSuccess = async (r: IUploadFilesResponse) => {
    try {
      await updateClubProfile(Number(clubId), { clubLogo: String(r?.id) })
      fetchClubDetails()
    } catch {}
  }
  const handleBannerUploadSuccess = async (r: IUploadFilesResponse) => {
    try {
      await updateClubProfile(Number(clubId), { clubCoverPhoto: String(r?.id) })
      fetchClubDetails()
    } catch {}
  }

  const handleProfileDeleteSuccess = async () => {
    try {
      await fetchClubDetails()
    } catch {}
  }

  const isLargeScreen = screenWidth >= 1250
  const isMediumScreen = screenWidth >= 769 && screenWidth < 1250

  return (
    <>
      <div className="mb-12">
        <div className="flex flex-col items-center">
          <div className="w-full">
            <HeaderSearchFeild />
          </div>
          <div className="w-full">
            <HeroSection
              clubId={clubId || ""}
              isAdmin={canEdit}
              adminStatus={adminStatus}
              handleSendClaimRequest={handleSendClaimRequest}
              clubData={clubData}
              onProfileDeleteSuccess={handleProfileDeleteSuccess}
              onProfileUploadSuccess={handleProfileUploadSuccess}
              onBannerUploadSuccess={handleBannerUploadSuccess}
            />
          </div>

          <div className="flex w-full">
            <div
              className={`flex px-4 md:px-6 md:mt-8 justify-between w-full md:flex-row flex-col`}
            >
              <div
                className={`flex flex-col gap-[20px] w-full ${
                  isLargeScreen
                    ? "flex-[25%] max-w-[25%]"
                    : isMediumScreen
                    ? "flex-[32%] max-w-[32%]"
                    : "flex-[100%] max-w-[100%]"
                }`}
              >
                <ClubDetails
                  selectedSection={selectedSection}
                  handleSectionClick={handleSectionClick}
                  opportunitiesNo={opportunitiesNo}
                  memberNo={memberNo?.attributes?.members?.length}
                />
              </div>
              <div
                className={`flex flex-col gap-[20px] sm:mt-0 mt-4 w-full ${
                  isLargeScreen
                    ? "flex-[73%] max-w-[73%]"
                    : isMediumScreen
                    ? "flex-[65%] max-w-[65%]"
                    : "flex-[100%] max-w-[100%]"
                }`}
              >
                {selectedTab ? <ClubFollowers clubData={clubData} /> : <>{renderView()}</>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClubProfile
