import * as React from "react"

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14" fill="none">
      <path d="M9.792 5.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" fill="#4390E3" />
      <path
        d="M.417 1.75a1.5 1.5 0 011.5-1.5h10.5a1.5 1.5 0 011.5 1.5v10.5a1.5 1.5 0 01-1.5 1.5h-10.5a1.5 1.5 0 01-1.5-1.5V1.75zm12 0h-10.5v5.94l2.531-2.026a.75.75 0 01.937 0l3.226 2.581L9.636 7.22a.75.75 0 011.061 0l1.72 1.72V1.75zm-10.5 10.5h10.5v-1.19l-2.25-2.25-.97.97a.75.75 0 01-.999.056L4.917 7.21l-3 2.4v2.64z"
        fill="#4390E3"
      />
    </svg>
  )
}

export default SvgComponent
