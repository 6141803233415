import React, { SVGProps } from "react"

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={26}
      height={27}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.712 13.914c0-7.26-5.724-13.148-12.782-13.148C5.87.766.147 6.653.147 13.914c0 6.563 4.674 12.001 10.785 12.987v-9.186H7.687v-3.802h3.245v-2.895c0-3.294 1.91-5.115 4.829-5.115 1.397 0 2.86.257 2.86.257v3.236H17.01c-1.588 0-2.083 1.013-2.083 2.053v2.465h3.545l-.567 3.802h-2.978v9.186c6.112-.987 10.786-6.427 10.786-12.988z"
        fill="#1877F2"
      />
    </svg>
  )
}

export default FacebookIcon
