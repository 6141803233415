import { useState, useEffect } from "react"
import { MeiliSearch, MultiSearchQuery, MultiSearchResponse } from "meilisearch"

const client = new MeiliSearch({
  host: "https://search.globalrugby.com/",
  //host: "http://52.62.165.78/",
  apiKey: "D2F8E596ED6C9D36962CE1B73272B",
})

export const useMeilisearchHook = (props: { queries: MultiSearchQuery[] | undefined }) => {
  const { queries } = props
  const [results, setResults] = useState<MultiSearchResponse<Record<string, any>>>()

  useEffect(() => {
    const performMultiSearch = async () => {
      if (!queries?.length) return
      try {
        const searchResults = await client.multiSearch({ queries })
        setResults(searchResults)
      } catch (error) {
        console.error("Failed to perform multiSearch:", error)
      }
    }

    performMultiSearch()
  }, [queries])

  return { results }
}
