import React, { useEffect, useState, useContext } from "react"
import Posts from "../../club/clubprofile/Posts"
import { IPostSummary } from "../../../modals/Post"
import { usePostAPI } from "../../../services/usePostAPI"
import AppContext from "../../../context/app-context/AppContext"
import AddPost from "../../dialogs/AddPostDialog"
import { useParams } from "react-router-dom"

interface IAllPostsProps {
  isAdmin: boolean | undefined
}

function AllPosts(props: IAllPostsProps) {
  // Props
  const { isAdmin } = props

  // State
  const [posts, setPosts] = useState<IPostSummary[]>([])
  const [isShowAddPostDialog, setIsShowAddPostDialog] = useState<boolean>(false)

  // APIs
  const { getPostByClubId, deletePost } = usePostAPI()

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // Hooks
  const { clubId } = useParams()

  const cId = clubId ? Number(clubId) : loginAsProfileDetails?.club?.id

  const fetchPosts = async () => {
    if (!cId) return
    const res = await getPostByClubId(cId)
    setPosts(res?.data)
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  const removePost = async (postId: any | undefined) => {
    try {
      if (!postId) return
      const deletePostResponse = await deletePost(postId)
      if (deletePostResponse?.data) {
        setPosts(posts.filter((p) => p?.id != deletePostResponse?.data?.id))
      }
    } catch (error) {}
  }

  return (
    <div className="flex flex-col gap-3">
      {posts && posts?.length > 0 ? (
        posts?.map((post) => {
          return (
            <Posts
              onFetchPosts={() => fetchPosts()}
              key={post?.id}
              postData={post}
              removePost={() => removePost(post?.id)}
            />
          )
        })
      ) : (
        <div className="flex flex-col items-center gap-4 font-inter text-gray-500 justify-center px-4 py-2 bg-white rounded-2xl min-h-[332px]">
          <p>No posts yet!</p>
          {isAdmin && (
            <p
              className="underline cursor-pointer hover:text-gr-primaryR2"
              onClick={() => setIsShowAddPostDialog(true)}
            >
              Start sharing now!
            </p>
          )}
        </div>
      )}
      {/* Add post dialog */}
      <div>
        <AddPost
          onFetchPosts={() => fetchPosts()}
          isOpen={isShowAddPostDialog}
          onClose={() => setIsShowAddPostDialog(false)}
        />
      </div>
    </div>
  )
}

export default AllPosts
