import React, { useContext, useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import GRButton from "../ui/GRButton"
import { Field, FieldArray, Form, Formik } from "formik"
import {
  IUnder16MemberFormValidations,
  IAbove16MemberFormValidation,
} from "../../validations/Validations"
import GRTextfield from "../ui/GRTextfield"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CircularProgress,
  Popover,
  TextField,
  Typography,
} from "@mui/material"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import { CountryArray, genderOptions, seasonIWishToPlayOptions } from "../../utils/Constants"
import GRDatePicker from "../ui/GRDatePicker"
import { FamilyMemberDialogEnum } from "../../utils/Constants"
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"
import CrossIcon from "../../assets/svg/CrossIcon"
import Nationality from "../../utils/Nationality.json"
import AppContext from "../../context/app-context/AppContext"
import { convertIntoDropDownOptions, generateRandomString } from "../../utils/CommonUtils"
import {
  IInviteAbove16FormPayload,
  IUnder16AddMemberPayload,
  useLoginUserAPI,
} from "../../services/useLoginUserAPI"
import { IAddRugbyExperienceFormValues } from "../forms/AddRugbyExperience"
import { IGetClubByTextResponse } from "../../modals/Club"
import { useClubAPI } from "../../services/useClubAPI"
import dayjs from "dayjs"
import { cloneDeep, debounce } from "lodash"
import ExpandMoreIcon from "../../assets/svg/DownArrow"
import useLocationHook from "../../hooks/useLocationHook"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"
import { IRegisterChildPayload, useAuthAPI } from "../../services/useAuthAPI"
import { successToast } from "../../utils/ToastUtils"

interface ICreateFamilyMemberDialog {
  isOpen: boolean
  onSuccess: () => void
  onClose: () => void
}

const AddFamilyMemberDialog = (props: ICreateFamilyMemberDialog) => {
  // Props
  const { isOpen, onSuccess, onClose } = props

  // State
  const [activeTab] = useState<FamilyMemberDialogEnum>(FamilyMemberDialogEnum.BasicInfo)
  const [selectedAgeGroup, setSelectedAgGroup] = useState("under16")

  const handleOptionChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedAgGroup(event.target.value)
  }

  // const handleTabClick = (tab: FamilyMemberDialogEnum) => {
  //   setActiveTab(tab)
  // }

  const body = (
    <>
      <div className="lg:px-[31px] px-[15px] mb-4">
        <FormControl component="fieldset">
          <p className="text-[18px] font-semibold mb-1">Members age</p>
          <RadioGroup value={selectedAgeGroup} onChange={handleOptionChange} row>
            <Grid container spacing={2}>
              <Grid item>
                <FormControlLabel
                  value="under16"
                  control={<Radio color="error" />}
                  label={<span className="text-gr-neutralT4 text-[15px]">Under 16</span>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="above16"
                  control={<Radio color="error" />}
                  label={
                    <span
                      style={{
                        color: "#7B8593",
                        fontSize: "15px",
                      }}
                    >
                      16 or Above
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </div>
      {selectedAgeGroup === "under16" ? (
        <Under16Form onSuccess={onSuccess} onClose={onClose} activeTab={activeTab} />
      ) : (
        <Above16Form onSuccess={onSuccess} onClose={onClose} />
      )}
    </>
  )

  const dialogHeader = (
    <div className="">
      <div className="lg:mx-[32px] mx-[15px] flex justify-between items-center border-b border-b-gr-primaryN8 mb-5">
        <p className="font-inter sm:text-2xl text-xl font-semibold leading-6 text-black my-5">
          Add family member <br className="sm:hidden" /> (new GR user)
        </p>
        <div
          className="text-2xl leading-10 font-bold font-inter text-gr-primaryR3 cursor-pointer"
          onClick={() => onClose()}
        >
          <CrossIcon />
        </div>
      </div>
      {selectedAgeGroup === "under16" ? (
        <>
          <div className="mx-[15px] lg:mx-[31px]">
            <p className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-2 font-inter">
              Basic info
            </p>
            {/* <button
      className={`font-inter text-gr-primaryR2 sm:text-[18px] text-[13px] sm:py-3 font-semibold border-b-2 w-[49%] sm:mx-[22px] ${
        activeTab === FamilyMemberDialogEnum.BasicInfo ? "border-gr-primaryR2 bg-bgRed" : ""
      }`}
      onClick={() => handleTabClick(FamilyMemberDialogEnum.BasicInfo)}
    >
      Basic info
    </button> */}
            {/* <button
      className={`font-inter text-gr-primaryR2 sm:text-[18px] text-[13px] font-semibold border-b-2 w-[49%] sm:mr-[20px] ${
        activeTab === FamilyMemberDialogEnum.RugbyExperience
          ? "border-gr-primaryR2 bg-bgRed"
          : ""
      } `}
      onClick={() => handleTabClick(FamilyMemberDialogEnum.RugbyExperience)}
    >
      Rugby experience
    </button> */}
          </div>
          <div className="flex mx-[15px] md:mx-[31px] mb-4">
            <span className="text-red-600 text-lg -mt-2">*</span>
            <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
          </div>
        </>
      ) : null}
    </div>
  )

  return (
    <div>
      <GRDialog
        open={isOpen}
        onClose={() => onClose()}
        dialogtitle={dialogHeader}
        dialogbody={body}
        fullWidth
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
      />
    </div>
  )
}

export default AddFamilyMemberDialog

interface IUnder16BasicFormValues {
  relation: string
  firstName: string
  lastName: string
  middleName: string
  dob: string
  gender: string
  internationalEligibilities: string[]
  nationality: string[]
  level: string
  highestLevelPlayed: string | null
  strengths: string
  seasonIWishToPlay: string
  contactNumber: string
  address: string
  city: string
  state: string
  country: string
  postCode: string
  discipline: string
}
interface IUnder16FormValues extends IUnder16BasicFormValues, IAddRugbyExperienceFormValues {}

const under16FormInitialValues: IUnder16FormValues = {
  relation: "",
  firstName: "",
  lastName: "",
  middleName: "",
  dob: "",
  gender: "",
  internationalEligibilities: [],
  nationality: [],
  level: "",
  highestLevelPlayed: "",
  strengths: "",
  seasonIWishToPlay: "",
  contactNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  postCode: "",
  discipline: "",
  club: "",
  currentClub: false,
  startDate: "",
  endDate: "",
  experienceSeason: [
    {
      season: "",
      playingLevel: "",
      discipline: "",
      leagueCompetition: "",
      ageGroup: "",
      position1: "",
      position2: "",
      coachPosition: "",
      gamePlayed: "",
      pointScored: "",
    },
  ],
}

interface IUnder16FormProps {
  activeTab: FamilyMemberDialogEnum
  onSuccess: () => void
  onClose: () => void
}

export const Under16Form = (props: IUnder16FormProps) => {
  // Props
  const { activeTab, onSuccess, onClose } = props

  // State
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<IGetClubByTextResponse>()

  // Hooks
  const {
    countryOptions,
    stateOptions,
    cityOptions,
    fetchStateByCountry,
    fetchCityByStateAndCountry,
  } = useLocationHook()
  // Context
  const { dropDownOptions } = useContext(AppContext)

  // APIs
  const { addUnder16Member, isLoading: isSubmitting } = useLoginUserAPI()
  const { register } = useAuthAPI()

  const { getClubByText, isLoading } = useClubAPI()

  const fetchResults = async (searchStr: string) => {
    try {
      if (searchStr?.length < 3) {
        return
      }
      const r = await getClubByText(searchStr)
      setOptions(r)
    } catch {}
  }

  const debounceSearch = debounce(fetchResults, 1000)

  const onSubmit = async (values: IUnder16FormValues) => {
    try {
      const formData: IUnder16BasicFormValues & Partial<IAddRugbyExperienceFormValues> =
        cloneDeep(values)

      delete formData?.experienceSeason
      delete formData?.club
      delete formData?.currentClub
      delete formData?.startDate
      delete formData?.endDate

      if (formData.highestLevelPlayed === "") {
        formData.highestLevelPlayed = null
      }

      const t_millis = new Date().getTime()
      const randomString = generateRandomString()
      const payload: IRegisterChildPayload = {
        password: t_millis.toString(),
        confirmed: true,
        username: `${randomString}.${t_millis}@gr.com`,
        email: `${randomString}.${t_millis}@gr.com`,
        firstname: formData?.firstName, // becz of this lower case in firstname not able to do ...values
        lastname: formData?.lastName,
        dob: formData?.dob ? dayjs(values?.dob)?.format("YYYY-MM-DD") : undefined,
        discipline: formData?.discipline,
        city: formData?.city,
        country: formData?.country,
        state: formData?.state,
        profileType: "Player",
        middlename: formData?.middleName,
        gender: formData?.gender,
        internationalEligibilities: formData?.internationalEligibilities?.join(", "),
        nationality: formData?.nationality?.join(", "),
        level: formData?.level,
        postCode: formData?.postCode,
        address: formData?.address,
        highestLevelPlayed: formData?.highestLevelPlayed ? formData?.highestLevelPlayed : null,
        contact: formData?.contactNumber,
        strengths: formData?.strengths,
        isChild: true,
      }
      const registerResponse = await register(payload)
      if (registerResponse?.user?.id) {
        const payload: IUnder16AddMemberPayload = {
          data: {
            relation: formData?.relation,
            childId: registerResponse?.user?.id,
          },
        }
        await addUnder16Member(payload)
        onClose()
        onSuccess()
        successToast("Child profile created successfully!!")
      }
    } catch {}
  }

  const getClubValue = (clubId: string) => {
    const o = options?.data?.find((d) => d?.id === parseInt(clubId))
    if (!o) return
    return { label: o?.name, value: o?.id }
  }
  const [messagePopoverAnchorEl, setMessagePopoverAnchorEl] = useState<null | HTMLElement>(null)
  const [isNotState, setIsNotState] = useState<boolean>(false)

  // Handler function to toggle the message
  const toggleMessagePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagePopoverAnchorEl(messagePopoverAnchorEl ? null : event.currentTarget)
  }
  const { loginAsProfileDetails } = useContext(AppContext)

  const [formValues] = useState<IUnder16FormValues>({
    ...under16FormInitialValues,
    postCode: loginAsProfileDetails?.postCode || "",
    contactNumber: loginAsProfileDetails?.contactNumber || "",
    country: loginAsProfileDetails?.country || "",
    state: loginAsProfileDetails?.state || "",
    city: loginAsProfileDetails?.city || "",
    address: loginAsProfileDetails?.address || "",
  })

  return (
    <Formik
      initialValues={formValues}
      validationSchema={IUnder16MemberFormValidations}
      onSubmit={(values) => onSubmit(values)}
      enableReinitialize
    >
      {(formkProps) => (
        <Form className="font-inter">
          {activeTab === FamilyMemberDialogEnum.BasicInfo && (
            <div className="lg:px-[31px] px-[15px] mb-8 compeleteProfileForm">
              <div>
                {/* personal info */}
                <p className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-4">
                  Personal info
                </p>
                {/* relation and first name */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  <div className="w-full mb-4">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="relation">
                      Relation<span className="text-red-600">*</span>
                    </label>
                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="relation"
                        placeholder="Select relation"
                        defaultValue=""
                        name="relation"
                        onChange={formkProps?.handleChange}
                        value={formkProps?.values?.relation}
                        error={formkProps?.touched.relation && Boolean(formkProps?.errors.relation)}
                        options={convertIntoDropDownOptions(
                          dropDownOptions?.relation?.filter((relation) => {
                            return relation.title === "Daughter" || relation.title === "Son"
                          }),
                        )}
                        errormsg={
                          formkProps?.touched.relation && Boolean(formkProps?.errors.relation)
                            ? formkProps?.errors.relation
                            : ""
                        }
                      />
                    </Box>
                  </div>
                  <div className="w-full mb-4 ">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="firstName">
                      First name<span className="text-red-600">*</span>
                    </label>
                    <GRTextfield
                      id="firstName"
                      type="text"
                      height="40px"
                      placeholder="Enter first name"
                      width="100%"
                      name="firstName"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values?.firstName}
                      error={formkProps?.touched.firstName && Boolean(formkProps?.errors.firstName)}
                      helperText={
                        <span>
                          {" "}
                          {formkProps?.touched.firstName && formkProps?.errors.firstName}
                        </span>
                      }
                    />
                  </div>
                </div>
                {/* middle name and last name  */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  <div className="w-full mb-4">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="middleName">
                      Middle name
                    </label>
                    <GRTextfield
                      id="middleName"
                      type="text"
                      placeholder="Enter middle name"
                      width="100%"
                      height="40px"
                      name="middleName"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values?.middleName}
                      error={
                        formkProps?.touched.middleName && Boolean(formkProps?.errors.middleName)
                      }
                      helperText={
                        <span>
                          {" "}
                          {formkProps?.touched.middleName && formkProps?.errors.middleName}
                        </span>
                      }
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="firstName">
                      Last name<span className="text-red-600">*</span>
                    </label>
                    <GRTextfield
                      id="lastName"
                      type="text"
                      placeholder="Enter last name"
                      width="100%"
                      height="40px"
                      name="lastName"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values?.lastName}
                      error={formkProps?.touched.lastName && Boolean(formkProps?.errors.lastName)}
                      helperText={
                        <span> {formkProps?.touched.lastName && formkProps?.errors.lastName}</span>
                      }
                    />
                  </div>
                </div>
                {/* dob and gender  */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  <div className="w-full mb-4">
                    <label className="cursor-pointer block text-[15px] leading-6 font-[400]">
                      Date of birth<span className="text-red-600">*</span>
                    </label>
                    <GRDatePicker
                      className="w-full mb-4"
                      height="40px"
                      error={Boolean(formkProps?.touched.dob) && Boolean(formkProps?.errors.dob)}
                      slotProps={{
                        textField: {
                          error:
                            Boolean(formkProps?.touched.dob) && Boolean(formkProps?.errors.dob),
                          helperText:
                            formkProps?.touched.dob &&
                            (formkProps?.errors?.dob?.substring(0, 20) ===
                            "Start Date must be a `date`" ? (
                              <span className=" text-[13px] text-red-500 font-inter">
                                Invalid Date*
                              </span>
                            ) : (
                              <span className=" text-[13px] text-red-500 font-inter">
                                {formkProps?.errors.dob}
                              </span>
                            )),
                        },
                      }}
                      format="DD/MM/YYYY"
                      value={formkProps?.values?.dob}
                      onChange={(value) => formkProps?.setFieldValue("dob", value, true)}
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label className="block text-[15px] leading-6 font-[400]" htmlFor="gender">
                      Gender<span className="text-red-600">*</span>
                    </label>
                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="gender"
                        defaultValue=""
                        placeholder="Select gender"
                        name="gender"
                        style={{ height: "40px" }}
                        onChange={formkProps?.handleChange}
                        value={formkProps?.values?.gender}
                        error={formkProps?.touched.gender && Boolean(formkProps?.errors.gender)}
                        options={genderOptions}
                        errormsg={
                          formkProps?.touched.gender && Boolean(formkProps?.errors.gender)
                            ? formkProps?.errors.gender
                            : ""
                        }
                      />
                    </Box>
                  </div>
                </div>
                <p className="text-[18px] font-semibold mb-4 mt-3">Professional info</p>
                {/* International Eligibility and Nationality */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  {/* International Eligibility */}
                  <div className="w-full mb-4">
                    <div className="flex justify-between">
                      <label
                        className="flex text-[15px] leading-6 font-[400]"
                        htmlFor="internationalEligibilities"
                      >
                        International eligibility<span className="text-red-600">*</span>
                      </label>
                      <div className="mt-1" onClick={toggleMessagePopover}>
                        <ExclamtionIcon />
                      </div>
                      <Popover
                        open={Boolean(messagePopoverAnchorEl)}
                        anchorEl={messagePopoverAnchorEl}
                        onClose={() => setMessagePopoverAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Typography sx={{ p: 2 }}>
                          <div className="flex gap-2">
                            <span>
                              <a
                                className="font-bold text-red-600"
                                href="https://www.world.rugby/organisation/governance/regulations/reg-8"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                Link to{" "}
                              </a>
                              World Rugby International Eligibility Rules.
                            </span>
                            <div className="mt-1">
                              <CrossIcon onClick={() => setMessagePopoverAnchorEl(null)} />
                            </div>
                          </div>
                        </Typography>
                      </Popover>
                    </div>
                    <div className="">
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        renderTags={(value) => {
                          const numTags = value.length
                          return (
                            <span className="max-w-[60%] truncate text-sm mr-2">
                              {numTags > 1 && ` ${numTags - 1} more +`}
                              {value.slice(0, 1).join(", ")}
                            </span>
                          )
                        }}
                        id="internationalEligibilities"
                        // onChange={(_event, newValue) => {
                        //   formkProps.handleChange(_event)
                        //   formkProps.setFieldValue(
                        //     "internationalEligibilities",
                        //     Array.isArray(newValue) ? newValue.join(", ") : "",
                        //   )
                        // }}
                        onChange={(_event, newValue) => {
                          formkProps.handleChange(_event)
                          formkProps.setFieldValue("internationalEligibilities", newValue || "")
                        }}
                        renderOption={(formkProps, option, { selected }) => (
                          <li {...formkProps} style={{ fontSize: "14px" }}>
                            <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                            {option}
                          </li>
                        )}
                        onBlur={formkProps?.handleBlur}
                        value={formkProps?.values?.internationalEligibilities || []}
                        options={countryOptions?.map((c) => c?.name) || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="International eligibilities"
                            sx={AutocompleteInputCss}
                          />
                        )}
                      />
                      {formkProps?.touched?.internationalEligibilities &&
                        formkProps?.errors?.internationalEligibilities && (
                          <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                            {formkProps?.errors?.internationalEligibilities}
                          </div>
                        )}
                    </div>
                  </div>
                  {/*  Nationality*/}
                  <div className="w-full mb-4">
                    <label className="block text-[15px] leading-6 font-[400]" htmlFor="nationality">
                      Nationality<span className="text-red-600">*</span>
                    </label>
                    <div className="">
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        renderTags={(value) => {
                          const numTags = value.length
                          return (
                            <span className="max-w-[60%] truncate text-sm mr-2 text-sm">
                              {numTags > 1 && ` ${numTags - 1} more +`}
                              {value
                                .slice(0, 1)
                                .map((option) => option)
                                .join(", ")}
                            </span>
                          )
                        }}
                        id="nationality"
                        // onChange={(_event, newValue) => {
                        //   formkProps.handleChange(_event)
                        //   formkProps.setFieldValue(
                        //     "nationality",
                        //     Array.isArray(newValue) ? newValue.join(", ") : "",
                        //   )
                        // }}
                        onChange={(_event, newValue) => {
                          formkProps.handleChange(_event)
                          formkProps.setFieldValue("nationality", newValue || "")
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} style={{ fontSize: "14px" }}>
                            <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                            {option}
                          </li>
                        )}
                        onBlur={formkProps?.handleBlur}
                        value={formkProps.values.nationality || []}
                        options={
                          Nationality?.sort((a, b) =>
                            a.nationality > b.nationality ? 1 : -1,
                          )?.map((c) => c?.nationality) || []
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Nationality"
                            sx={AutocompleteInputCss}
                          />
                        )}
                      />
                      {formkProps?.touched?.nationality && formkProps?.errors?.nationality && (
                        <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                          {formkProps?.errors?.nationality}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  {/* Level */}
                  <div className="w-full mb-4">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="level">
                      Level<span className="text-red-600">*</span>
                    </label>
                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="level"
                        defaultValue=""
                        placeholder="Select level"
                        name="level"
                        onChange={formkProps?.handleChange}
                        value={formkProps?.values?.level}
                        error={formkProps?.touched.level && Boolean(formkProps?.errors.level)}
                        className={
                          formkProps?.touched.level && Boolean(formkProps?.errors.level)
                            ? "!border border-lightRed "
                            : ""
                        }
                        options={convertIntoDropDownOptions(dropDownOptions?.level)}
                        errormsg={
                          formkProps?.touched.level && formkProps?.errors.level
                            ? formkProps?.errors.level
                            : ""
                        }
                      />
                    </Box>
                  </div>
                  {/* Higest Level Played */}
                  <div className="w-full mb-4">
                    <label
                      className=" block text-[15px] leading-6 font-[400]"
                      htmlFor="highestLevelPlayed"
                    >
                      Highest level played
                    </label>
                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="highestLevelPlayed"
                        placeholder="Select highest level played"
                        defaultValue=""
                        name="highestLevelPlayed"
                        onChange={formkProps?.handleChange}
                        value={formkProps?.values?.highestLevelPlayed}
                        options={convertIntoDropDownOptions(dropDownOptions?.level)}
                      />
                    </Box>
                  </div>
                </div>
                {/* Specialties/Strengths and  Select the season for when you want the opportunities.  */}
                <div className="grid grid-cols-1  gap-x-5">
                  {/* Specialties/Strengths */}
                  <div className="w-full mb-4">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="strengths">
                      Specialities/Strengths
                    </label>
                    <GRTextfield
                      id="strengths"
                      type="text"
                      height="40px"
                      placeholder="Enter Specialities/Strengths"
                      width="100%"
                      name="strengths"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values?.strengths}
                    />
                  </div>
                  {/* Select the season for when you want the opportunities. */}
                  <div className="w-full mb-4">
                    <label
                      className=" block  text-[14px]  leading-6 font-[400]"
                      htmlFor="seasonIWishToPlay"
                    >
                      Select season for when you want the opportunities?
                    </label>
                    <Box sx={{ width: "100%" }}>
                      <GRSelect
                        id="seasonIWishToPlay"
                        defaultValue=""
                        name="seasonIWishToPlay"
                        placeholder="Select season"
                        onChange={formkProps?.handleChange}
                        value={formkProps?.values?.seasonIWishToPlay}
                        options={seasonIWishToPlayOptions}
                        // className="sm:w-[340px]"
                      />
                    </Box>
                  </div>
                </div>
                {/* Other info */}
                <p className="text-[18px] font-semibold mb-4 mt-3">Other info</p>
                <div className="grid grid-cols-1 gap-x-5">
                  {/* ContactNumber */}
                  <div className="w-full mb-4">
                    <label className="block font-inter text-sm mb-1" htmlFor="contactNumber">
                      Contact number
                    </label>
                    <GRTextfield
                      id="contactNumber"
                      type="text"
                      height="40px"
                      name="contactNumber"
                      placeholder="Enter contact number"
                      width="100%"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values?.contactNumber}
                      error={
                        formkProps?.touched.contactNumber &&
                        Boolean(formkProps?.errors.contactNumber)
                      }
                      helperText={
                        formkProps?.touched.contactNumber && formkProps?.errors.contactNumber
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  <div className="w-full mb-4">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="address">
                      Address
                    </label>
                    <GRTextfield
                      id="address"
                      type="text"
                      width="100%"
                      height="40px"
                      placeholder="Enter address"
                      name="address"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values?.address}
                    />
                  </div>

                  <div className="w-full mb-4">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="postCode">
                      Postcode<span className="text-red-600">*</span>
                    </label>
                    <GRTextfield
                      id="postCode"
                      type="text"
                      width="100%"
                      placeholder="Enter postcode"
                      height="40px"
                      name="postCode"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values?.postCode}
                      error={formkProps?.touched.postCode && Boolean(formkProps?.errors.postCode)}
                      helperText={formkProps?.touched.postCode && formkProps?.errors.postCode}
                    />
                  </div>
                </div>
                <div>
                  <div className="grid gap-x-5 grid-cols-1 sm:grid-cols-3 lg:gap-6">
                    <div className="w-full mb-4">
                      <div className="font-inter text-sm mb-1">
                        Country
                        <span className="text-red-600">*</span>
                      </div>
                      <div className="">
                        <Autocomplete
                          id="country"
                          onChange={(_event, newValue) => {
                            formkProps.handleChange(_event)
                            formkProps.setFieldValue("country", newValue || "")
                            formkProps.setFieldValue("state", "")
                            formkProps.setFieldValue("city", "")
                            if (newValue) {
                              if (CountryArray?.includes(newValue)) {
                                fetchCityByStateAndCountry(newValue, "")
                                setIsNotState(true)
                              } else {
                                fetchStateByCountry(newValue)
                                setIsNotState(false)
                              }
                            }
                          }}
                          renderOption={(formkProps, option) => (
                            <li {...formkProps} style={{ fontSize: "14px" }}>
                              {option}
                            </li>
                          )}
                          onBlur={formkProps?.handleBlur}
                          value={formkProps?.values?.country}
                          options={countryOptions?.map((c) => c?.name) || []}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Country"
                              sx={AutocompleteInputCss}
                            />
                          )}
                        />
                        {formkProps?.touched?.country && formkProps?.errors?.country && (
                          <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                            {formkProps?.errors?.country}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full mb-4">
                      <div className="font-inter text-sm mb-1">State</div>
                      <div className="">
                        <Autocomplete
                          id="state"
                          onChange={(_event, newValue) => {
                            formkProps.handleChange(_event)
                            formkProps.setFieldValue("state", newValue || "")
                            formkProps.setFieldValue("city", "")
                            if (newValue) {
                              fetchCityByStateAndCountry(formkProps.values.country, newValue)
                            }
                          }}
                          renderOption={(formkProps, option) => (
                            <li {...formkProps} style={{ fontSize: "14px" }}>
                              {option}
                            </li>
                          )}
                          onBlur={formkProps?.handleBlur}
                          value={formkProps?.values?.state}
                          readOnly={
                            CountryArray?.includes(formkProps.values.country) ? true : false
                          }
                          options={stateOptions?.map((s) => s?.name) || []}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="State" sx={AutocompleteInputCss} />
                          )}
                        />
                        {isNotState && (
                          <div className="text-[13px] text-blue-600 lowercase first-letter:uppercase !font-inter">
                            The country has no state so you can select a city
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full mb-4">
                      <div className="font-inter text-sm mb-1">City</div>
                      <div className="">
                        <Autocomplete
                          id="city"
                          onChange={(_event, newValue) => {
                            formkProps.handleChange(_event)
                            formkProps.setFieldValue("city", newValue || "")
                          }}
                          renderOption={(formkProps, option) => (
                            <li {...formkProps} style={{ fontSize: "14px" }}>
                              {option}
                            </li>
                          )}
                          onBlur={formkProps?.handleBlur}
                          value={formkProps?.values?.city}
                          options={cityOptions?.map((c: { name: any }) => c?.name) || []}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="City" sx={AutocompleteInputCss} />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Add Social Media Link */}
                {/* <div className="w-full mb-[23px] flex justify-between items-center">
                  <a className="underline text-gr-neutralT3 cursor-pointer">
                    + Add social media link
                  </a>
                </div> */}
                <div className="flex justify-between items-center my-5">
                  <GRButton onClick={() => onClose()} label="Cancel" type="button" width={100} />
                  <GRButton isSubmitting={isSubmitting} label="Save" width={100} />
                </div>
              </div>
            </div>
          )}
          {activeTab === FamilyMemberDialogEnum.RugbyExperience && (
            <>
              <div className="mx-[15px] lg:mx-[31px] mt-7 mb-8">
                <div className="mb-7">
                  <p className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-[22px]">
                    Basic info
                  </p>
                </div>

                <div className="w-full mb-4">
                  <div className="flex justify-between items-center">
                    <label className=" block text-[15px] leading-6 font-[400]" htmlFor="club">
                      Club<span className="text-gr-primaryR2">*</span>
                    </label>

                    <div className="flex justify-between gap-2">
                      <input
                        type="checkbox"
                        id="currentClub"
                        name="currentClub"
                        checked={formkProps?.values?.currentClub}
                        onChange={(e) =>
                          formkProps?.setFieldValue("currentClub", e?.target?.checked)
                        }
                        className=" accent-gr-primaryR2 !border-gr-primaryR2 !border-solid !text-gr-primaryR2 !bg-gr-primaryR2"
                      />
                      <label htmlFor="currentClub" className="text-gr-neutralT4 cursor-pointer">
                        Mark as current club
                      </label>
                    </div>
                  </div>
                  <div>
                    <Autocomplete
                      autoFocus
                      id="club-search"
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      onChange={(e, v) => {
                        formkProps?.handleChange(e)
                        formkProps?.setFieldValue("club", v?.value)
                      }}
                      onBlur={formkProps?.handleBlur}
                      value={getClubValue(formkProps?.values?.club)}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      getOptionLabel={(option) => option?.label}
                      options={options?.data?.map((o) => ({ label: o?.name, value: o?.id })) || []}
                      loading={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => debounceSearch(e?.target?.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          sx={AutocompleteInputCss}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                  <div className="w-full mb-4">
                    <label className="cursor-pointer block text-[15px] leading-6 font-[400]">
                      Start Date<span className="text-gr-primaryR2">*</span>
                    </label>

                    <GRDatePicker
                      className="w-full mb-4"
                      height="40px"
                      error={
                        Boolean(formkProps?.touched?.startDate) &&
                        Boolean(formkProps?.errors?.startDate)
                      }
                      slotProps={{
                        textField: {
                          error:
                            Boolean(formkProps?.touched?.startDate) &&
                            Boolean(formkProps?.errors?.startDate),
                          helperText:
                            formkProps?.touched?.startDate &&
                            (formkProps?.errors?.startDate?.substring(0, 20) ===
                            "Start Date must be a `date`" ? (
                              <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                Invalid Date*
                              </span>
                            ) : (
                              <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                {formkProps?.errors?.startDate}
                              </span>
                            )),
                        },
                      }}
                      format="DD/MM/YYYY"
                      value={dayjs(formkProps?.values?.startDate)}
                      onChange={(value) => formkProps?.setFieldValue("startDate", value, true)}
                    />
                  </div>

                  {!formkProps?.values?.currentClub && (
                    <div className="w-full mb-4">
                      <label className="cursor-pointer block text-[15px] leading-6 font-[400]">
                        End Date<span className="text-gr-primaryR2">*</span>
                      </label>

                      <GRDatePicker
                        className="w-full mb-4"
                        height="40px"
                        error={
                          Boolean(formkProps?.touched?.endDate) &&
                          Boolean(formkProps?.errors?.endDate)
                        }
                        slotProps={{
                          textField: {
                            error:
                              Boolean(formkProps?.touched?.endDate) &&
                              Boolean(formkProps?.errors?.endDate),
                            helperText:
                              formkProps?.touched?.endDate &&
                              (formkProps?.errors?.endDate?.substring(0, 20) ===
                              "Start Date must be a `date`" ? (
                                <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                  Invalid Date*
                                </span>
                              ) : (
                                <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                  {formkProps?.errors?.endDate}
                                </span>
                              )),
                          },
                        }}
                        format="DD/MM/YYYY"
                        value={dayjs(formkProps?.values?.endDate)}
                        onChange={(value) => formkProps?.setFieldValue("endDate", value, true)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <FieldArray
                  name="experienceSeason"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="flex flex-col gap-1">
                        {formkProps?.values?.experienceSeason?.map((es, index) => (
                          <div key={index}>
                            <div className=" sm:px-4 mb-[10px] mx-[10px] sm:mx-0">
                              <Accordion
                                className="h-full w-[290px] sm:w-full sm:px-4 "
                                defaultExpanded={true}
                                style={{
                                  borderRadius: "16px",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className=""
                                >
                                  <div className="font-inter  mt-2 ">
                                    <div className="md:text-[22px] text-lg font-semibold font-inter">
                                      Season
                                      {
                                        dropDownOptions?.seasons?.find(
                                          (s) => s?.id === parseInt(es?.season),
                                        )?.title
                                      }
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div>
                                    <div>
                                      <Field name={`experienceSeason[${index}].season`}>
                                        {({ field, meta }: any) => (
                                          <div className="w-full mb-4">
                                            <label
                                              className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                              htmlFor="season"
                                            >
                                              Season<span className="text-gr-primaryR2">*</span>
                                            </label>
                                            <div>
                                              <GRSelect
                                                key={"season"}
                                                id="season"
                                                style={{ height: "40px" }}
                                                options={convertIntoDropDownOptions(
                                                  dropDownOptions?.seasons,
                                                )}
                                                error={meta.touched && meta.error}
                                                errormsg={meta.touched && meta.error}
                                                {...field}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                      <div>
                                        <Field name={`experienceSeason[${index}].startDate`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label className="cursor-pointer block text-[15px] leading-6 font-[400]">
                                                Start Date
                                                <span className="text-gr-primaryR2">*</span>
                                              </label>
                                              <GRDatePicker
                                                className="w-full mb-4"
                                                height="40px"
                                                error={Boolean(meta.touched) && Boolean(meta.error)}
                                                slotProps={{
                                                  textField: {
                                                    error:
                                                      Boolean(meta.touched) && Boolean(meta.error),
                                                    helperText:
                                                      meta.touched &&
                                                      (meta.error?.substring(0, 20) ===
                                                      "Start Date must be a `date`" ? (
                                                        <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                                          Invalid Date*
                                                        </span>
                                                      ) : (
                                                        <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                                          {meta.error}
                                                        </span>
                                                      )),
                                                  },
                                                }}
                                                format="DD/MM/YYYY"
                                                value={dayjs(field?.value)}
                                                onChange={(value) =>
                                                  formkProps?.setFieldValue(
                                                    `experienceSeason[${index}].startDate`,
                                                    value,
                                                    true,
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div>
                                        <Field name={`experienceSeason[${index}].endDate`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label className="cursor-pointer block text-[15px] leading-6 font-[400]">
                                                End Date<span className="text-gr-primaryR2">*</span>
                                              </label>
                                              <GRDatePicker
                                                className="w-full mb-4"
                                                height="40px"
                                                error={Boolean(meta.touched) && Boolean(meta.error)}
                                                slotProps={{
                                                  textField: {
                                                    error:
                                                      Boolean(meta.touched) && Boolean(meta.error),
                                                    helperText:
                                                      meta.touched &&
                                                      (meta.error?.substring(0, 20) ===
                                                      "Start Date must be a `date`" ? (
                                                        <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                                          Invalid Date*
                                                        </span>
                                                      ) : (
                                                        <span className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                                          {meta.error}
                                                        </span>
                                                      )),
                                                  },
                                                }}
                                                format="DD/MM/YYYY"
                                                value={dayjs(field?.value)}
                                                onChange={(value) =>
                                                  formkProps?.setFieldValue(
                                                    `experienceSeason[${index}].endDate`,
                                                    value,
                                                    true,
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                      <div>
                                        <Field name={`experienceSeason[${index}].playingLevel`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <div className="flex justify-between items-center">
                                                <label
                                                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                  htmlFor="playingLevel"
                                                >
                                                  Playing Level
                                                  <span className="text-gr-primaryR2">*</span>
                                                </label>
                                              </div>
                                              <div>
                                                <GRSelect
                                                  id="playingLevel"
                                                  style={{ height: "40px" }}
                                                  options={convertIntoDropDownOptions(
                                                    dropDownOptions?.level,
                                                  )}
                                                  error={meta.touched && meta.error}
                                                  errormsg={meta.touched && meta.error}
                                                  {...field}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div>
                                        <Field name={`experienceSeason[${index}].discipline`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <div className="flex justify-between items-center">
                                                <label
                                                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                  htmlFor="discipline"
                                                >
                                                  Code
                                                  <span className="text-gr-primaryR2">*</span>
                                                </label>
                                              </div>
                                              <div>
                                                <GRSelect
                                                  id="discipline"
                                                  style={{ height: "40px" }}
                                                  options={convertIntoDropDownOptions(
                                                    dropDownOptions?.discipline,
                                                  )}
                                                  error={meta.touched && meta.error}
                                                  errormsg={meta.touched && meta.error}
                                                  {...field}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                      <div>
                                        <Field
                                          name={`experienceSeason[${index}].leagueCompetition`}
                                        >
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label
                                                className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                htmlFor="leagueCompetition"
                                              >
                                                League/Competition
                                                <span className="text-gr-primaryR2">*</span>
                                              </label>
                                              <GRTextfield
                                                id="leagueCompetition"
                                                type="text"
                                                width="100%"
                                                height="40px"
                                                error={meta.touched && meta.error}
                                                helperText={meta.touched && meta.error}
                                                {...field}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div>
                                        <Field name={`experienceSeason[${index}].ageGroup`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label
                                                className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                htmlFor="ageGroup"
                                              >
                                                Age Group
                                                <span className="text-gr-primaryR2">*</span>
                                              </label>
                                              <div>
                                                <GRSelect
                                                  id="ageGroup"
                                                  style={{ height: "40px" }}
                                                  options={convertIntoDropDownOptions(
                                                    dropDownOptions?.ageGroup,
                                                  )}
                                                  error={meta.touched && meta.error}
                                                  errormsg={meta.touched && meta.error}
                                                  {...field}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                      <div>
                                        <Field name={`experienceSeason[${index}].position1`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label
                                                className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                htmlFor="position1"
                                              >
                                                Position 1
                                                <span className="text-gr-primaryR2">*</span>
                                              </label>
                                              <GRSelect
                                                id="position1"
                                                style={{ height: "40px" }}
                                                options={convertIntoDropDownOptions(
                                                  dropDownOptions?.playerPosition,
                                                )}
                                                error={meta.touched && meta.error}
                                                errormsg={meta.touched && meta.error}
                                                {...field}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div>
                                        <Field name={`experienceSeason[${index}].position2`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label
                                                className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                htmlFor="position2"
                                              >
                                                Position 2
                                                <span className="text-gr-primaryR2">*</span>
                                              </label>
                                              <GRSelect
                                                id="position2"
                                                style={{ height: "40px" }}
                                                options={convertIntoDropDownOptions(
                                                  dropDownOptions?.playerPosition,
                                                )}
                                                error={meta.touched && meta.error}
                                                errormsg={meta.touched && meta.error}
                                                {...field}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                      <div>
                                        <Field name={`experienceSeason[${index}].gamePlayed`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label
                                                className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                htmlFor="gamePlayed"
                                              >
                                                Game Played
                                              </label>
                                              <GRTextfield
                                                id="gamePlayed"
                                                type="text"
                                                width="100%"
                                                height="40px"
                                                error={meta.touched && meta.error}
                                                helperText={meta.touched && meta.error}
                                                {...field}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                      <div>
                                        <Field name={`experienceSeason[${index}].pointScored`}>
                                          {({ field, meta }: any) => (
                                            <div className="w-full mb-4">
                                              <label
                                                className="cursor-pointer block text-[15px] leading-6 font-[400]"
                                                htmlFor="pointScored"
                                              >
                                                Points Scored
                                              </label>
                                              <GRTextfield
                                                id="pointScored"
                                                type="text"
                                                width="100%"
                                                height="40px"
                                                error={meta.touched && meta.error}
                                                helperText={meta.touched && meta.error}
                                                {...field}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                                      <div className="w-full">
                                        <div className="font-inter text-sm mb-1">
                                          Country
                                          <span className="text-red-600">*</span>
                                        </div>
                                        <div className="">
                                          <Autocomplete
                                            id="country"
                                            onChange={(_event, newValue) => {
                                              formkProps.handleChange(_event)
                                              formkProps.setFieldValue("country", newValue || "")
                                              formkProps.setFieldValue("state", "")
                                              formkProps.setFieldValue("city", "")
                                              if (newValue) fetchStateByCountry(newValue)
                                            }}
                                            renderOption={(formkProps, option) => (
                                              <li {...formkProps}>{option}</li>
                                            )}
                                            onBlur={formkProps?.handleBlur}
                                            value={formkProps?.values?.country}
                                            options={countryOptions?.map((c) => c?.name) || []}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Country"
                                                sx={AutocompleteInputCss}
                                              />
                                            )}
                                          />
                                          {formkProps?.touched?.country &&
                                            formkProps?.errors?.country && (
                                              <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                                                {formkProps?.errors?.country}
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      <div className="w-full">
                                        <div className="font-inter text-sm mb-1">City</div>
                                        <div className="">
                                          <Autocomplete
                                            id="city"
                                            onChange={(_event, newValue) => {
                                              formkProps.handleChange(_event)
                                              formkProps.setFieldValue("city", newValue || "")
                                            }}
                                            renderOption={(formkProps, option) => (
                                              <li {...formkProps}>{option}</li>
                                            )}
                                            onBlur={formkProps?.handleBlur}
                                            value={formkProps?.values?.city}
                                            options={
                                              cityOptions?.map((c: { name: any }) => c?.name) || []
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="City"
                                                sx={AutocompleteInputCss}
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="flex justify-between mx-[15px] lg:mx-[31px] mb-6 mt-4">
                        <div className="">
                          <button
                            onClick={() => {
                              arrayHelpers.push({
                                ...cloneDeep(
                                  formkProps?.values?.experienceSeason[
                                    formkProps?.values?.experienceSeason?.length - 1
                                  ],
                                ),
                                startDate: "",
                                endDate: "",
                              })
                            }}
                            type="button"
                            className="text-gr-primaryR2 sm:text-lg text-sm font-inter underline"
                          >
                            +Add another Season
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="flex justify-end items-center my-5 mr-5 -mt-5 mb-8">
                <GRButton
                  isSubmitting={isSubmitting}
                  label="Save"
                  className="!px-[75px]"
                  width={115}
                />
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}

interface IAbove16FormValue {
  memberEmail: string
  relation: string
  firstName: string
  lastName: string
}

const above16FormInitialValues = {
  memberEmail: "",
  relation: "",
  firstName: "",
  lastName: "",
}

interface IAbove16FormProps {
  onSuccess: () => void
  onClose: () => void
}

const Above16Form = (props: IAbove16FormProps) => {
  // Props
  const { onSuccess, onClose } = props

  // Context
  const { dropDownOptions } = useContext(AppContext)

  // APIs
  const {
    inviteAbove16Member,
    isLoading: isSubmitting,
    searchUserEmailByString,
  } = useLoginUserAPI()

  const onSubmit = async (values: IAbove16FormValue, { setFieldError }: any) => {
    try {
      const response = await searchUserEmailByString(values.memberEmail)
      if (response.data) {
        const existingEmails = response.data.map((item) => item.email)

        if (existingEmails.includes(values.memberEmail)) {
          setFieldError("memberEmail", "Email already exists")
          return // Stop form submission
        }
      }
      const payload: IInviteAbove16FormPayload = {
        data: {
          ...values,
        },
      }
      await inviteAbove16Member(payload)
      onSuccess()
      successToast("New Family Member Invited Successfully")
    } catch {}
  }

  return (
    <div className="px-[31px] mt-[14px] mb-8">
      <Formik
        initialValues={above16FormInitialValues}
        validationSchema={IAbove16MemberFormValidation}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formkProps) => (
          <Form className="font-inter">
            <>
              <p className="text-[18px] font-semibold mb-4 mt-6">Personal info</p>
              <div className="flex -mt-1 mb-2">
                <span className="text-red-600 text-lg -mt-2">*</span>
                <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
              </div>

              {/* relation and email  */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 ">
                <div className="w-full mb-4 ">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="relation"
                  >
                    Relation<span className="text-red-600">*</span>
                  </label>

                  <Box sx={{ width: "100%" }}>
                    <GRSelect
                      id="relation"
                      defaultValue=""
                      placeholder="Select relation"
                      name="relation"
                      onChange={formkProps?.handleChange}
                      value={formkProps?.values.relation}
                      error={formkProps?.touched.relation && Boolean(formkProps?.errors.relation)}
                      options={convertIntoDropDownOptions(dropDownOptions?.relation)}
                      errormsg={
                        formkProps?.touched.relation && Boolean(formkProps?.errors.relation)
                          ? formkProps?.errors.relation
                          : ""
                      }
                    />
                  </Box>
                </div>
                <div className="w-full mb-4">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="memberEmail"
                  >
                    Email id<span className="text-red-600">*</span>
                  </label>
                  <GRTextfield
                    id="memberEmail"
                    placeholder="Enter email"
                    type="text"
                    height="40px"
                    width="100%"
                    name="memberEmail"
                    onChange={formkProps?.handleChange}
                    value={formkProps?.values.memberEmail}
                    error={
                      formkProps?.touched.memberEmail && Boolean(formkProps?.errors.memberEmail)
                    }
                    helperText={
                      <span>
                        {formkProps?.touched.memberEmail && formkProps?.errors.memberEmail}
                      </span>
                    }
                  />
                </div>
              </div>
              {/* first name and last name  */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 ">
                <div className="w-full mb-4">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="firstName"
                  >
                    First name<span className="text-red-600">*</span>
                  </label>
                  <GRTextfield
                    id="firstName"
                    placeholder="Enter first name"
                    type="text"
                    height="40px"
                    width="100%"
                    name="firstName"
                    onChange={formkProps?.handleChange}
                    value={formkProps?.values.firstName}
                    error={formkProps?.touched.firstName && Boolean(formkProps?.errors.firstName)}
                    helperText={
                      <span> {formkProps?.touched.firstName && formkProps?.errors.firstName}</span>
                    }
                  />
                </div>
                <div className="w-full">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="firstName"
                  >
                    Last name<span className="text-red-600">*</span>
                  </label>
                  <GRTextfield
                    id="lastName"
                    type="text"
                    placeholder="Enter last name"
                    width="100%"
                    height="40px"
                    name="lastName"
                    onChange={formkProps?.handleChange}
                    value={formkProps?.values.lastName}
                    error={formkProps?.touched.lastName && Boolean(formkProps?.errors.lastName)}
                    helperText={
                      <span> {formkProps?.touched.lastName && formkProps?.errors.lastName}</span>
                    }
                  />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <GRButton
                  onClick={onClose}
                  type="button"
                  label="Cancel"
                  className="!px-[20px] !mt-10"
                />
                <GRButton
                  isSubmitting={isSubmitting}
                  label="Invite"
                  className="!px-[20px] !mt-10"
                />
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  )
}
