import React, { useReducer } from "react"
import AppContext, { IAppContextState } from "./AppContext"
import AppContextActions from "./AppContextActions"
import ATransactionsContextReducer from "./AppContextReducer"
import { ICurrentLoginProfile, ILoginUser } from "../../modals/User"
import { IDropDownOptionsResponse } from "../../modals/Config"
import { ILocation } from "@App/modals/Auth"
import { getAuthToken } from "../../utils/AuthUtils"
import { IConnectedUsersResponse, useLoginUserAPI } from "../../services/useLoginUserAPI"
import { useConfigAPI } from "../../services/useConfigAPI"
import { asyncLocalStorage } from "../../utils/CommonUtils"
import { initializeMeilisearchSettings } from "../../settings/meilisearchSettings"
import socket, { Socket } from "socket.io-client"

export const AppContextProvider = (props: any) => {
  const initialState: IAppContextState = {
    isAppLoading: false,
    primaryUserDetails: undefined,
    dropDownOptions: undefined,
    currentLocation: undefined,
    loginAsProfileDetails: undefined,
    currentLoginProfile: undefined,
    socket: undefined,
    unreadMessageCount: 0,
    connectedUser: undefined,
  }

  const [state, dispatch] = useReducer(ATransactionsContextReducer, initialState)

  // Load App
  const { getUserDetails, getCurrentLoginProfileDetails } = useLoginUserAPI()
  const { getDropDownOptions } = useConfigAPI()

  const loadApp = async () => {
    const authToken = getAuthToken()
    try {
      if (!authToken) {
        return
      }
      dispatch({
        type: AppContextActions.SET_LOAD_APP,
        payload: true,
      })

      const p1 = getUserDetails()
      const p2 = getDropDownOptions()

      const res = await Promise.allSettled([p1, p2])
      const ur = res[0].status === "fulfilled" ? res[0].value : undefined
      const cr = res[1].status === "fulfilled" ? res[1].value : undefined

      setPrimaryUserDetails(ur?.data?.user)
      setDropdownOptions(cr)

      const currentLogin = await asyncLocalStorage.getItem("currentProfile")

      const profileLoginAs = currentLogin
        ? JSON.parse(currentLogin)
        : {
            profileType: ur?.data?.user?.profileType,
            profileId: ur?.data?.user?.id,
          }

      if (!currentLogin) {
        await asyncLocalStorage.setItem("currentProfile", JSON.stringify(profileLoginAs))
      }

      const headers = {
        "x-user-id": profileLoginAs?.profileId,
        "x-profile-type": profileLoginAs?.profileType,
      }

      dispatch({
        type: AppContextActions.SET_CURRENT_LOGIN_PROFILE,
        payload: profileLoginAs,
      })

      if (profileLoginAs?.profileType === "Club") {
        dispatch({
          type: AppContextActions.SET_LOGIN_AS_PROFILE_DETAILS,
          payload: ur?.data?.user,
        })
      } else {
        const clpr = await getCurrentLoginProfileDetails({ headers })
        dispatch({
          type: AppContextActions.SET_LOGIN_AS_PROFILE_DETAILS,
          payload: clpr?.data?.user,
        })
      }

      await asyncLocalStorage.getItem("currentProfile")
      initializeMeilisearchSettings()

      // Socket Initialize
      const io = socket(process.env.REACT_APP_CHAT_MESSAGES_URL || "http://54.252.205.217:1337")
      io?.emit("new-user-add", profileLoginAs?.profileId)

      dispatch({
        type: AppContextActions.SET_SOCKET,
        payload: io,
      })

      dispatch({
        type: AppContextActions.SET_LOAD_APP,
        payload: false,
      })
    } catch {
      dispatch({
        type: AppContextActions.SET_LOAD_APP,
        payload: false,
      })
    }
  }

  const setPrimaryUserDetails = (user: ILoginUser | undefined) => {
    dispatch({
      type: AppContextActions.SET_USER_DETAILS,
      payload: user,
    })
  }

  const setDropdownOptions = (options: IDropDownOptionsResponse | undefined) => {
    dispatch({
      type: AppContextActions.SET_DROPDOWN_OPTIONS,
      payload: options,
    })
  }

  const setCurrentLocation = (currentLocation: ILocation | undefined) => {
    dispatch({
      type: AppContextActions.SET_CURRENT_LOCATION,
      payload: currentLocation,
    })
  }

  const setLoginAsProfileDetails = (loginAsProfileDetails: ILoginUser | undefined) => {
    dispatch({
      type: AppContextActions.SET_LOGIN_AS_PROFILE_DETAILS,
      payload: loginAsProfileDetails,
    })
  }

  const setCurrentLoginProfile = (loginProfile: ICurrentLoginProfile | undefined) => {
    dispatch({
      type: AppContextActions.SET_CURRENT_LOGIN_PROFILE,
      payload: loginProfile,
    })
  }

  const setSocket = (socket: Socket<any>) => {
    dispatch({
      type: AppContextActions.SET_SOCKET,
      payload: socket,
    })
  }

  const setUnreadMessageCount = (count: number) => {
    dispatch({
      type: AppContextActions.SET_UNREAD_COUNT,
      payload: count,
    })
  }

  const setConnectedUser = (connectedUser: IConnectedUsersResponse | undefined) => {
    dispatch({
      type: AppContextActions.SET_CONNECTED_USER,
      payload: connectedUser,
    })
  }
  return (
    <AppContext.Provider
      value={{
        isAppLoading: state.isAppLoading,
        primaryUserDetails: state.primaryUserDetails,
        dropDownOptions: state.dropDownOptions,
        currentLocation: state.currentLocation,
        loginAsProfileDetails: state.loginAsProfileDetails,
        currentLoginProfile: state.currentLoginProfile,
        socket: state.socket,
        unreadMessageCount: state.unreadMessageCount,
        connectedUser: state.connectedUser,
        setPrimaryUserDetails,
        setDropdownOptions,
        setCurrentLocation,
        loadApp,
        setLoginAsProfileDetails,
        setCurrentLoginProfile,
        setSocket,
        setUnreadMessageCount,
        setConnectedUser,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}
