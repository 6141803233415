import React, { SVGProps } from "react"

const AddBtn = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={58}
      height={21}
      viewBox="0 0 58 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.477 13.466V7.84h.955v5.625h-.955zm-2.335-2.335v-.955h5.625v.955h-5.625zM26.075 15h-1.108l3.205-8.727h1.09L32.467 15h-1.108l-2.608-7.347h-.068L26.075 15zm.409-3.41h4.466v.938h-4.466v-.937zm9.678 3.546a2.562 2.562 0 01-1.445-.413c-.418-.278-.744-.67-.98-1.176-.236-.509-.354-1.11-.354-1.803 0-.687.118-1.284.354-1.79.236-.505.564-.896.984-1.171.42-.276.907-.414 1.458-.414.426 0 .762.071 1.01.213.25.14.44.299.57.478.134.176.238.32.312.434h.085V6.273h1.006V15h-.972v-1.006h-.12c-.073.12-.178.27-.315.452-.136.179-.33.34-.584.482-.252.139-.589.208-1.01.208zm.136-.903c.403 0 .744-.105 1.023-.315.278-.213.49-.507.635-.882.144-.378.217-.814.217-1.309 0-.488-.071-.916-.213-1.282a1.867 1.867 0 00-.63-.861c-.28-.208-.623-.311-1.032-.311-.426 0-.781.11-1.065.328-.282.216-.493.51-.635.882-.14.37-.21.784-.21 1.244 0 .466.072.89.214 1.27.145.378.358.68.64.904.283.221.636.332 1.056.332zm7.317.903a2.562 2.562 0 01-1.445-.413c-.418-.278-.744-.67-.98-1.176-.236-.509-.354-1.11-.354-1.803 0-.687.118-1.284.354-1.79.236-.505.564-.896.984-1.171.42-.276.907-.414 1.458-.414.426 0 .763.071 1.01.213.25.14.44.299.57.478.134.176.238.32.312.434h.085V6.273h1.006V15h-.972v-1.006h-.12c-.073.12-.178.27-.315.452-.136.179-.33.34-.583.482-.253.139-.59.208-1.01.208zm.136-.903c.404 0 .744-.105 1.023-.315.278-.213.49-.507.635-.882.145-.378.217-.814.217-1.309 0-.488-.071-.916-.213-1.282a1.867 1.867 0 00-.63-.861c-.28-.208-.623-.311-1.032-.311-.426 0-.781.11-1.065.328-.282.216-.493.51-.635.882-.14.37-.209.784-.209 1.244 0 .466.071.89.213 1.27.145.378.358.68.64.904.283.221.636.332 1.056.332z"
        fill="#9F0002"
      />
      <rect x={0.5} y={0.5} width={57} height={20} rx={10} stroke="#9F0002" />
    </svg>
  )
}

export default AddBtn
