import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
} from "react-share"
import copyIcon from "../../assets/svg/copy-icon.svg"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import { usePostHook } from "../../hooks/usePostHook"

interface ShareButtonsProps {
  url: string
  title: string
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ url, title }) => {
  const { copyToClipBord } = usePostHook()

  const copyLinkButton = (
    <>
      <div
        className="flex justify-center items-center w-[44px] h-[44px] px-3 rounded-full bg-gr-primaryR1"
        onClick={() => copyToClipBord(url)}
      >
        <span className="text-white font-semibold">
          <img src={copyIcon} />
        </span>
      </div>
      <div className="ml-3 ">
        <span className="font-bold">Copy link</span>
      </div>
    </>
  )

  return (
    <div>
      <div className="flex items-center ml-4 mt-3">{copyLinkButton}</div>
      <div className="">
        <EmailShareButton className="m-4" url={url}>
          <EmailIcon size={52} round={true} />
        </EmailShareButton>
        <FacebookShareButton className="m-4" url={url} quote={title}>
          <FacebookIcon size={52} round={true} />
        </FacebookShareButton>
        <TwitterShareButton className="m-4" url={url} title={title}>
          <TwitterIcon size={52} round={true} />
        </TwitterShareButton>
        <LinkedinShareButton className="m-4" url={url} title={title}>
          <LinkedinIcon size={52} round={true} />
        </LinkedinShareButton>
        <WhatsappShareButton className="m-4" url={url} title={title}>
          <WhatsappIcon size={52} round={true} />
        </WhatsappShareButton>
        <TelegramShareButton className="m-4" url={url} title={title}>
          <TelegramIcon size={52} round={true} />
        </TelegramShareButton>
      </div>
    </div>
  )
}

export default ShareButtons
