import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { getAuthToken } from "../utils/AuthUtils"
import { AUTH_DASHBOARD } from "./RouteConstants"

const UnAuthenticatedRoutes = () => {
  return getAuthToken() ? <Navigate to={AUTH_DASHBOARD} /> : <Outlet />
}

export default UnAuthenticatedRoutes
