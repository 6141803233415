import React, { ReactNode, useState, useEffect } from "react"
import "react-multi-carousel/lib/styles.css"
import Carousel from "react-multi-carousel"

interface ICustomCarousellProps {
  children: ReactNode
  itemShow: number
}
const CustomCarousel = (props: ICustomCarousellProps) => {
  const { children, itemShow } = props
  const [device, setDevice] = useState("")

  useEffect(() => {
    updateDimensions()
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itemShow,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 546, min: 0 },
      items: 1,
      slidesToSlide: 1,
      // paritialVisibilityGutter: 50,
    },
  }
  const updateDimensions = () => {
    const width = window.innerWidth
    if (width < 465) {
      setDevice("mobile")
    } else if (width > 768 && width < 1024) {
      setDevice("tablet")
    } else {
      setDevice("desktop")
    }
  }

  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true}
      infinite={false}
      autoPlay={false}
      transitionDuration={300}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["mobile"]}
      deviceType={device}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      partialVisible={true}
    >
      {children}
    </Carousel>
  )
}

export default CustomCarousel
