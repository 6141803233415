import React, { Fragment, useContext, useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import GRTextfield from "../ui/GRTextfield"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import DialogHeader from "../dialogs/DialogHeader"
import { convertIntoDropDownOptions } from "../../utils/CommonUtils"
import AppContext from "../../context/app-context/AppContext"
import Nationality from "../../utils/Nationality.json"
import { useUserHook } from "../../hooks/useUserHook"
import Qualification from "./Qualification"
import * as yup from "yup"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import useLocationHook from "../../hooks/useLocationHook"
import CrossIcon from "../../assets/svg/CrossIcon"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"
import { Popover, Typography } from "@mui/material"
import { Box } from "@mui/material"

interface IProfessionalInfoFormValues {
  nationality: string[]
  internationalEligibilities: string[]
  highestLevelPlayed: string
  strengths: string
  disciplines: string[]
  highestQualification: string
  level: string
  qualification?: number[]
}

interface IProfessionalInfoPopupProps {
  open: boolean
  onClose: () => void
}

const ProfessionalInfoPopup: React.FC<IProfessionalInfoPopupProps> = ({ open, onClose }) => {
  // Context
  const { loginAsProfileDetails, dropDownOptions } = useContext(AppContext)

  const alreadyUploadedIds = loginAsProfileDetails?.qualification?.map((q) => q.id) ?? []
  //States
  const [uploadedFileIds, setUploadedFileIds] = useState<number[]>([...alreadyUploadedIds])

  // Hooks
  const { updateUserProfile, isUserProfileLoading, isCoach, isParent, isAgent, isPlayer } =
    useUserHook()
  // Validation schema
  const ProfessionalInfovalidationSchema = yup.object({
    nationality: yup
      .array()
      .min(1, "At least one nationality is required")
      .required("Nationality is required")
      .max(10, "Can select maximum 10 nationality"),
    internationalEligibilities: yup
      .array()
      .min(1, "At least one international eligibility is required")
      .required("International eligibility is required")
      .max(10, "Can select maximum 10 international eligibility"),
    highestLevelPlayed: yup.string().required("Highest level played is required"),
    disciplines: yup.array().min(1, "Code is required").required("Code is required"),
    highestQualification: yup
      .string()
      .test(
        "conditionalValidation",
        "Highest coaching qualification is required",
        function (value) {
          const user = loginAsProfileDetails
          if (user?.profileType === "Coach") {
            if (!value) {
              return false
            }
          }
          return true
        },
      ),
    level: yup.string().required("Level is required"),
  })

  const coachProfessionalInfovalidationSchema = yup.object({
    nationality: yup
      .array()
      .min(1, "At least one nationality is required")
      .required("Nationality is required")
      .max(10, "Can select maximum 10 nationality"),
    internationalEligibilities: yup.array().required("International eligibility is required"),
    highestLevelPlayed: yup.string().required("Highest level played is required"),
    disciplines: yup.array().min(1, "Code is required").required("Code is required"),
    highestQualification: yup
      .string()
      .test(
        "conditionalValidation",
        "Highest coaching qualification is required",
        function (value) {
          const user = loginAsProfileDetails
          if (user?.profileType === "Coach") {
            if (!value) {
              return false
            }
          }
          return true
        },
      ),
    level: yup.string().required("Level is required"),
  })

  const agentValidationSchema = yup.object({
    nationality: yup
      .array()
      .min(1, "At least one nationality is required")
      .required("Nationality is required")
      .max(10, "Can select maximum 10 nationality"),
    disciplines: yup.array().min(1, "Code is required").required("Code is required"),
  })

  const ParentvalidationSchema = yup.object({
    nationality: yup.array().notRequired().max(10, "Can select maximum 10 nationality"), // Making nationality non-required
    internationalEligibilities: yup
      .array()
      .notRequired()
      .max(10, "Can select maximum 10 international eligibility"), // Making internationalEligibilities non-required
    highestLevelPlayed: yup.string().notRequired(), // Making highestLevelPlayed non-required
    disciplines: yup.array().notRequired(), // Making discipline non-required
    highestQualification: yup.string().when("isPlayer", {
      is: false,
      then: yup.string().notRequired(), // Making highestQualification non-required
      otherwise: yup.string().nullable(),
    }),
    level: yup.string().notRequired().nullable(), // Making level non-required
  })
  const validationSchema = isParent
    ? ParentvalidationSchema // Empty schema means no validation, all fields optional
    : isAgent
    ? agentValidationSchema
    : isCoach
    ? coachProfessionalInfovalidationSchema
    : ProfessionalInfovalidationSchema
  const { countryOptions } = useLocationHook()

  const handleSave = async (values: IProfessionalInfoFormValues) => {
    try {
      const payload = {
        ...values,
        nationality: values?.nationality?.join(", "),
        highestLevelPlayed:
          !isAgent && values?.highestLevelPlayed ? Number(values?.highestLevelPlayed) : null,
        internationalEligibilities:
          !isAgent || !isCoach ? values?.internationalEligibilities?.join(", ") : [],
        level: isPlayer || isCoach ? Number(values?.level) : undefined,
        highestQualification: values?.highestQualification
          ? Number(values?.highestQualification)
          : null,
        disciplines: values?.disciplines,
        qualification: uploadedFileIds,
      }
      await updateUserProfile(payload)
      onClose()
    } catch {}
  }

  const formik = useFormik({
    initialValues: {
      nationality: loginAsProfileDetails?.nationality
        ? loginAsProfileDetails?.nationality?.split(", ")?.map((i) => i)
        : [],
      internationalEligibilities: loginAsProfileDetails?.internationalEligibilities
        ? loginAsProfileDetails?.internationalEligibilities?.split(", ")?.map((i) => i)
        : [],
      highestLevelPlayed: String(loginAsProfileDetails?.highestLevelPlayed?.id || ""),
      strengths: loginAsProfileDetails?.strengths || "",
      disciplines: loginAsProfileDetails?.disciplines
        ? loginAsProfileDetails?.disciplines?.map((i) => i.id.toString())
        : [],
      highestQualification: loginAsProfileDetails?.highestQualification?.id
        ? String(loginAsProfileDetails?.highestQualification?.id)
        : "",
      level: loginAsProfileDetails?.level?.id ? String(loginAsProfileDetails?.level?.id) : "",
      qualification: uploadedFileIds,
    },
    onSubmit: handleSave,
    // enableReinitialize: true,
    validationSchema,
  })
  const [messagePopoverAnchorEl, setMessagePopoverAnchorEl] = useState<null | HTMLElement>(null)

  // Handler function to toggle the message
  const toggleMessagePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagePopoverAnchorEl(messagePopoverAnchorEl ? null : event.currentTarget)
  }
  const body = (
    <div className="sm:mx-[32px] mx-[13px] mb-8 compeleteProfileForm">
      <div className="flex mb-4">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Qualification
          addUploadedFileId={(id: number) => {
            setUploadedFileIds([...uploadedFileIds, id])
          }}
        />

        <div className="flex flex-col items-center sm:flex-row  sm:items-start sm:justify-between sm:gap-5">
          {isAgent ? (
            <>
              <div className="w-max">
                <div className="font-inter mb-1 text-sm">
                  Nationality{!isParent && <span className="text-red-600">*</span>}
                </div>
                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    multiple
                    id="nationality"
                    placeholder="Select nationality"
                    defaultValue=""
                    name="nationality"
                    onChange={formik.handleChange}
                    value={formik.values.nationality || []}
                    error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                    className={
                      formik.touched.nationality && Boolean(formik.errors.nationality)
                        ? "!border border-lightRed w-[300px] sm:w-[315px]"
                        : "w-[300px] sm:w-[315px]"
                    }
                    options={Nationality?.sort((a, b) =>
                      a.nationality > b.nationality ? 1 : -1,
                    )?.map((ie) => ({
                      name: ie?.nationality,
                      value: ie?.nationality,
                    }))}
                    errormsg={
                      formik.touched.nationality && formik.errors.nationality
                        ? formik.errors.nationality?.toString()
                        : ""
                    }
                  />
                </Box>
              </div>
              <div className="w-max">
                <div className="text-sm  flex mb-1 block font-inter">
                  Code{!isParent && <span className="text-red-600">*</span>}
                </div>
                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="disciplines"
                    multiple
                    name="disciplines"
                    placeholder="Select code"
                    onChange={formik.handleChange}
                    value={formik.values.disciplines || []}
                    className="w-[300px] sm:w-[315px]"
                    options={convertIntoDropDownOptions(dropDownOptions?.discipline)}
                  />
                </Box>
              </div>
            </>
          ) : null}
          {!isAgent ? (
            <div className="w-full mb-4">
              <div className="font-inter mb-1 text-sm">
                Nationality{!isParent && <span className="text-red-600">*</span>}
              </div>
              <div className="">
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  renderTags={(value) => {
                    const numTags = value.length
                    return (
                      <span className="max-w-[60%] truncate text-sm  mr-2">
                        {numTags > 1 && ` ${numTags - 1} more +`}
                        {value
                          .slice(0, 1)
                          .map((option) => option)
                          .join(", ")}
                      </span>
                    )
                  }}
                  id="nationality"
                  onChange={(_event, newValue) => {
                    formik.handleChange(_event)
                    formik.setFieldValue("nationality", newValue || "")
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ fontSize: "14px" }}>
                      <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  )}
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.nationality}
                  options={
                    Nationality?.sort((a, b) => (a.nationality > b.nationality ? 1 : -1))?.map(
                      (c) => c?.nationality,
                    ) || []
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Nationality" sx={AutocompleteInputCss} />
                  )}
                />
                {formik?.touched?.nationality && formik?.errors?.nationality && (
                  <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                    {formik?.errors?.nationality}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {!isAgent ? (
            <div className="w-full mb-4">
              <div className="font-inter mb-1 text-sm flex">
                International eligibility
                {!isParent && !isCoach && <span className="text-red-600">*</span>}
                <div className="mt-1 ml-[5px] cursor-pointer" onClick={toggleMessagePopover}>
                  <ExclamtionIcon />
                </div>
                <Popover
                  open={Boolean(messagePopoverAnchorEl)}
                  anchorEl={messagePopoverAnchorEl}
                  onClose={() => setMessagePopoverAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 2 }}>
                    <div className="flex gap-2">
                      <span>
                        <a
                          className="font-bold text-red-600"
                          href="https://www.world.rugby/organisation/governance/regulations/reg-8"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Link to{" "}
                        </a>
                        World Rugby International Eligibility Rules.
                      </span>
                      <div className="mb-1 cursor-pointer">
                        <CrossIcon onClick={() => setMessagePopoverAnchorEl(null)} />
                      </div>
                    </div>
                  </Typography>
                </Popover>
              </div>
              <div className="">
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  renderTags={(value) => {
                    const numTags = value.length
                    return (
                      <span className="max-w-[60%] truncate text-sm mr-2 text-sm">
                        {numTags > 1 && ` ${numTags - 1} more +`}
                        {value
                          .slice(0, 1)
                          .map((option) => option)
                          .join(", ")}
                      </span>
                    )
                  }}
                  id="internationalEligibilities"
                  onChange={(_event, newValue) => {
                    formik.handleChange(_event)
                    formik.setFieldValue("internationalEligibilities", newValue || "")
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ fontSize: "14px" }}>
                      <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  )}
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.internationalEligibilities}
                  options={countryOptions?.map((c) => c?.name) || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="International eligibilities"
                      sx={AutocompleteInputCss}
                    />
                  )}
                />
                {formik?.touched?.internationalEligibilities &&
                  formik?.errors?.internationalEligibilities && (
                    <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                      {formik?.errors?.internationalEligibilities}
                    </div>
                  )}
              </div>
            </div>
          ) : null}
        </div>
        {!isAgent ? (
          <div className="flex flex-col sm:flex-row sm:gap-5">
            <div className="w-full mb-4">
              <div className="font-inter mb-1 text-sm">
                {isCoach ? "Coaching level" : "Level"}
                {!isParent && <span className="text-red-600">*</span>}
              </div>
              <div className="">
                <GRSelect
                  style={{ width: "full" }}
                  id="level"
                  placeholder="Select level"
                  name="level"
                  onChange={formik.handleChange}
                  value={formik.values.level}
                  options={convertIntoDropDownOptions(dropDownOptions?.level)}
                  error={formik.touched.level && Boolean(formik.errors.level)}
                  errormsg={
                    formik.touched.level && formik.errors.level
                      ? formik.errors.level?.toString()
                      : ""
                  }
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <div className="font-inter mb-1 text-sm">
                Highest level {isCoach ? "coached" : "played"}
                {!isParent && <span className="text-red-600">*</span>}
              </div>
              <div className="">
                <GRSelect
                  style={{ width: "100%" }}
                  id="highestLevelPlayed"
                  placeholder="Select highest level played"
                  name="highestLevelPlayed"
                  onChange={formik.handleChange}
                  value={formik.values.highestLevelPlayed}
                  options={convertIntoDropDownOptions(dropDownOptions?.level)}
                  error={
                    formik.touched.highestLevelPlayed && Boolean(formik.errors.highestLevelPlayed)
                  }
                  errormsg={
                    formik.touched.highestLevelPlayed && formik.errors.highestLevelPlayed
                      ? formik.errors.highestLevelPlayed?.toString()
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="">
          {isCoach && (
            <div className="w-full mb-4 ">
              <div className="font-inter mb-1 text-sm">
                Highest coaching qualification{" "}
                {!isParent && <span className="text-red-600">*</span>}
              </div>
              <div>
                <GRSelect
                  style={{ width: "100%" }}
                  id="highestQualification"
                  name="highestQualification"
                  placeholder="Select highest qualification"
                  onChange={formik.handleChange}
                  value={formik.values.highestQualification}
                  options={convertIntoDropDownOptions(dropDownOptions?.highestQualification)}
                  error={
                    formik.touched.highestQualification &&
                    Boolean(formik.errors.highestQualification)
                  }
                  errormsg={
                    formik.touched.highestQualification && formik.errors.highestQualification
                      ? formik.errors.highestQualification?.toString()
                      : ""
                  }
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col sm:flex-row sm:gap-5">
          {!isAgent ? (
            <div className="w-full mb-4">
              <div className="font-inter mb-1 text-sm">Specialities / Strength</div>
              <div>
                <GRTextfield
                  width="100%"
                  id="strengths"
                  placeholder="Enter specialities/strength"
                  name="strengths"
                  onChange={formik.handleChange}
                  value={formik.values.strengths}
                />
              </div>
            </div>
          ) : null}

          {!isAgent ? (
            <div className="w-full mb-4">
              {/* <div className="font-inter mb-1 text-sm">
                Code{!isParent && <span className="text-red-600">*</span>}
              </div>
              <div className="w-full sm:w-[320px]">
                <GRSelect
                  style={{ width: "100%" }}
                  id="disciplines"
                  multiple
                  placeholder="Select disciplines"
                  name="disciplines"
                  onChange={formik.handleChange}
                  value={formik.values.disciplines || []}
                  options={convertIntoDropDownOptions(dropDownOptions?.discipline)}
                  error={formik.touched.disciplines && Boolean(formik.errors.disciplines)}
                  errormsg={
                    formik.touched.disciplines && formik.errors.disciplines
                      ? formik.errors.disciplines?.toString()
                      : ""
                  }
                />
              </div> */}
              <div className="font-inter mb-1 text-sm">
                Code{!isParent && <span className="text-red-600">*</span>}
              </div>
              <div className="mb-3">
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  renderTags={(value) => {
                    const numTags = value.length
                    return (
                      <span className="max-w-[60%] truncate text-sm mr-2 text-sm">
                        {numTags > 1 && ` ${numTags - 1} more +`}
                        {value
                          .slice(0, 1)
                          .map((option) => option?.label)
                          .join(", ")}
                      </span>
                    )
                  }}
                  id="SelectDisciplines"
                  onChange={(_event, newValue) => {
                    formik.handleChange(_event)
                    const selectedOptions = newValue?.map((n: any) => n?.id)
                    formik.setFieldValue("disciplines", selectedOptions || "")
                  }}
                  renderOption={(props: object, option, { selected }) => (
                    <div {...props}>
                      <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                      {option?.label}
                    </div>
                  )}
                  isOptionEqualToValue={(options, selected) => options?.id === selected?.id}
                  getOptionLabel={(option) => option.label}
                  onBlur={formik?.handleBlur}
                  value={(() => {
                    const selectedOptions = dropDownOptions?.discipline?.filter((d) =>
                      formik.values.disciplines?.includes(String(d?.id)),
                    )
                    const options =
                      selectedOptions?.map((s) => ({ label: s?.title, id: String(s?.id) })) || []
                    return options
                  })()}
                  options={
                    dropDownOptions?.discipline?.map((d) => ({
                      label: d?.title,
                      id: String(d?.id),
                    })) || []
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select code" sx={AutocompleteInputCss} />
                  )}
                />
                {formik?.touched?.disciplines && formik?.errors?.disciplines && (
                  <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                    {formik?.errors?.disciplines}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex justify-between items-center mt-4">
          <GRButton
            onClick={onClose}
            label="Cancel"
            type="button"
            className="sm:!px-[75px] !px-[49px]"
            width={115}
          />
          <GRButton
            isSubmitting={isUserProfileLoading}
            onClick={formik.handleSubmit}
            label="Save"
            className="sm:!px-[75px] !px-[49px]"
            width={115}
          />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <GRDialog
        open={open}
        onClose={onClose}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Professional info" onClose={onClose} />}
      />
    </>
  )
}

export default ProfessionalInfoPopup
