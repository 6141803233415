import React from "react"

const TermsPolicy = () => {
  return (
    <>
      <div className="flex flex-col gap-2 h-[94vh] p-10">
        <h1 className="text-center mb-6 font-inter font-bold">Terms and Conditions</h1>
        <h2 className="text-center">
          <strong>GLOBAL SPORTS NETWORKING LTD</strong>
        </h2>
        <h2 className="text-center">
          <strong>GLOBAL RUGBY</strong>
        </h2>

        <h2>
          <strong>1. Interpretation</strong>
        </h2>
        <p className="font-inter">
          <p className="mt-5">
            <strong>1.1 Definitions:</strong>
          </p>
          <p className="mt-10">
            <strong>Company, “we”, “us”, “Global Rugby”</strong>: means{" "}
            <strong>GLOBAL SPORTS NETWORKING LTD</strong> of 27 Old Gloucester Street, London,
            England, WC1N 3AX, registered in England and Wales with company number 14853072.
          </p>
          <p className="mt-5">
            <strong>Contract</strong>: this agreement as amended from time to time in accordance
            with clause 4.1.
          </p>
          <p className="mt-5">
            <strong>Customer, “you”</strong>: the Member or Visitor who is a party to this Contract.
          </p>
          <p className="mt-5">
            <strong>Members</strong>: means persons who have registered and joined the Company’s
            Services.
          </p>
          <p className="mt-5">
            <strong>Minimum Age</strong>: means 16 years old. However, if law requires that you must
            be older in order for The Company to lawfully provide the Services to you without
            parental consent (including using your personal data) then the Minimum Age is such older
            age.
          </p>
          <p className="mt-5">
            <strong>Services</strong>: globalrugby.com and any associated sites, apps,
            communications and other services that state that they are offered under this Contract.
          </p>
          <p className="mt-5">
            <strong>Visitors</strong>: means persons who have not registered and joined the
            Company’s Services but access certain features.
          </p>
        </p>
        <h2 className="mt-10">
          <strong>2. User Agreement</strong>
        </h2>
        <p>
          <p className="mt-5">
            2.1 When you use our Services, you agree to all of these terms. Your use of our Services
            is also subject to our Cookie Policy and our Privacy Policy, which cover how we collect,
            use, share, and store your personal information.
          </p>
          <p className="mt-5">
            2.2 You agree that by clicking “Join Now,” “Join Global Rugby,” “Sign Up” or similar,
            registering, accessing, or using our Services, you are agreeing to enter into a legally
            binding contract with the Company (even if you are using our Services on behalf of a
            company).
          </p>
          <p className="mt-5">
            2.3 If you do not agree to this User Agreement, do not click “Join Now” (or similar) and
            do not access or otherwise use any of our Services. If you wish to terminate this
            contract, at any time you can do so by closing your account and no longer accessing or
            using our Services.
          </p>
        </p>

        <h2 className="mt-10">
          <strong>3. Obligations of the Members and Visitors</strong>
        </h2>
        <p>
          <p className="mt-5">
            <strong>3.1 General Obligations</strong>
          </p>
          <p className="mt-5">
            <strong>3.1.1</strong> You agree that you will:
          </p>
          <p className="mt-5">
            a) Comply with all applicable laws, including, without limitation, privacy laws,
            intellectual property laws, anti-spam laws, export control laws, tax laws, and
            regulatory requirements;
          </p>
          <p className="mt-5">b) Provide accurate information to us and keep it updated;</p>
          <p className="mt-5">c) Use your real name on your profile; and</p>
          <p className="mt-5">d) Use the Services in a professional manner.</p>
          <p className="mt-5">
            <strong>3.1.2</strong> You agree that you will not:
          </p>
          <p className="mt-5">
            a) Create a false identity on Global Rugby, misrepresent your identity, create a Member
            profile for anyone other than yourself (a real person), or use or attempt to use
            another’s account;
          </p>
          <p className="mt-5">
            b) Develop, support or use software, devices, scripts, robots or any other means or
            processes (including crawlers, browser plugins and add-ons or any other technology) to
            scrape the Services or otherwise copy profiles and other data from the Services;
          </p>
          <p className="mt-5">
            c) Override any security feature or bypass or circumvent any access controls or use
            limits of the Service (such as caps on keyword searches or profile views);
          </p>
          <p className="mt-5">
            d) Copy, use, disclose or distribute any information obtained from the Services, whether
            directly or through third parties (such as search engines), without the consent of the
            Company;
          </p>
          <p className="mt-5">
            e) Disclose information that you do not have the consent to disclose (such as
            confidential information of others (including your employer));
          </p>
          <p className="mt-5">
            f) Violate the intellectual property rights of others, including copyrights, patents,
            trademarks, trade secrets or other proprietary rights. For example, do not copy or
            distribute (except through the available sharing functionality) the posts or other
            content of others without their permission, which they may give by posting under a
            Creative Commons licence;
          </p>
          <p className="mt-5">
            g) Violate the intellectual property or other rights of the Company, including, without
            limitation, (i) copying or distributing our learning videos or other materials or (ii)
            copying or distributing our technology, unless it is released under open source
            licenses; (iii) using the word “Global Rugby” or our logos in any business name, email,
            or URL except as provided in the Brand Guidelines;
          </p>
          <p className="mt-5">
            h) Post anything that contains software viruses, worms, or any other harmful code;
          </p>
          <p className="mt-5">
            i) Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the
            source code for the Services or any related technology that is not open source;
          </p>
          <p className="mt-5">
            j) Imply or state that you are affiliated with or endorsed by the Company without our
            express consent (e.g., representing yourself as an accredited Global Rugby trainer);
          </p>
          <p className="mt-5">
            k) Rent, lease, loan, trade, sell/re-sell or otherwise monetise the Services or related
            data or access to the same, without the Company’s consent;
          </p>
          <p className="mt-5">
            l) Deep-link to our Services for any purpose other than to promote your profile or a
            Group on our Services, without the Company’s consent;
          </p>
          <p className="mt-5">
            m) Use bots or other automated methods to access the Services, add or download contacts,
            send or redirect messages;
          </p>
          <p className="mt-5">
            n) Monitor the Services’ availability, performance or functionality for any competitive
            purpose;
          </p>
          <p className="mt-5">
            o) Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function
            of the Services;
          </p>
          <p className="mt-5">
            p) Overlay or otherwise modify the Services or their appearance (such as by inserting
            elements into the Services or removing, covering, or obscuring an advertisement included
            on the Services); and/or
          </p>
          <p className="mt-5">
            q) Interfere with the operation of, or place an unreasonable load on, the Services
            (e.g., spam, denial of service attack, viruses, gaming algorithms).
          </p>
          <p className="mt-10">
            <strong>3.2 Use of Services </strong>
          </p>
          <p className="mt-5">3.2.1 To use the Services, you agree that:</p>
          <p className="mt-5">a) you are the Minimum Age or older;</p>
          <p className="mt-5">
            b) you will only have one Global Rugby account, which must be in your real name; and
          </p>
          <p className="mt-5">
            c) you are not already restricted by the Company from using the Services.
          </p>
          <p className="mt-5">
            Creating an account with false information is a violation of our terms, including
            accounts registered on behalf of others or persons under the Minimum Age.
          </p>
          <p className="mt-5">3.2.2 As a Member, you agree to:</p>
          <p className="mt-5">a) use a strong password and keep it confidential;</p>
          <p className="mt-5">b) not transfer any part of your account (e.g., connections); and</p>
          <p className="mt-5">
            c) follow the law and our list of General Obligations as set out in clause 3.1.
          </p>
          <p className="mt-5">
            You are responsible for anything that happens through your account unless you close it
            or report misuse.
          </p>
          <p className="mt-5">
            3.2.3 As between you and others (including your employer), your account belongs to you.
            However, if the Services were purchased by another party for you to use (e.g., bought by
            your employer), the party paying for such Service has the right to control access to and
            get reports on your use of such paid Service; however, they do not have rights to your
            personal account.
          </p>
          <p className="mt-5">
            <strong>3.3 Payment</strong>
          </p>
          <p className="mt-5">
            3.3.1 If you buy any of our paid Services, you agree to pay us the applicable fees and
            taxes and to additional terms specific to the paid Services. Failure to pay these fees
            will result in the termination of your paid Services.
          </p>
          <p className="mt-5">3.3.2 You agree that:</p>
          <p className="mt-5">
            a) your purchase may be subject to foreign exchange fees or differences in prices based
            on location (e.g., exchange rates);
          </p>
          <p className="mt-5">
            b) we may store and continue billing your payment method (e.g., credit card) even after
            it has expired, to avoid interruptions in your Services and to use to pay other Services
            you may buy;
          </p>
          <p className="mt-5">
            c) if you purchase a subscription, your payment method automatically will be charged at
            the start of each subscription period for the fees and taxes applicable to that period;
          </p>
          <p className="mt-5">
            d) you will honour your payment obligations and you are okay with us storing your
            payment information. You understand that there may be fees and taxes that are added to
            our prices;
          </p>
          <p className="mt-5">
            e) all of your purchases of Services are subject to The Company’s refund policy; and
          </p>
          <p className="mt-5">
            f) we may calculate taxes payable by you based on the billing information that you
            provide us at the time of purchase.
          </p>
          <p className="mt-5">
            <strong>3.4 Notices and Messages </strong>
          </p>
          <p className="mt-5">
            3.4.1 You agree that we will provide notices and messages to you in the following ways:
          </p>
          <p className="mt-5">a) within the Service; or</p>
          <p className="mt-5">
            b) sent to the contact information you provided us upon registration (e.g., email,
            mobile number, physical address).
          </p>
          <p className="mt-5">
            3.3.2 You agree to notify the Company of any change to your contact details, and such
            notification shall be effective on the date specified in the notice, or if no date is
            specified, two Business Days after the notice is deemed to be received.
          </p>
          <p className="mt-5">
            3.3.3 Notice will be given to the Company in accordance with the definition provided in
            this Contract.
          </p>
          <p className="mt-5">
            <strong>3.4 Sharing</strong>
          </p>
          <p className="mt-5">
            3.4.1 Our Services allow messaging and sharing of information in many ways, such as your
            profile, articles, group posts, links to news articles, job postings and messages.
            Information and content that you share, or post may be seen by other Members, Visitors
            or others (including off of the Services). Where we have made settings available, we
            will honour the choices you make about who can see content or information (e.g., message
            content to your addressees, sharing content only to Global Rugby connections,
            restricting your profile visibility from search engines, or opting not to notify others
            of your Global Rugby profile update).
          </p>
          <p className="mt-5">
            3.4.2 For job searching activities, we default to not notifying your connections network
            or the public. So, if you apply for a job through our Service or opt to signal that you
            are interested in a job, our default is to share it only with the job poster.
          </p>
          <p className="mt-5">
            3.4.3 The Company is not obligated to publish any information or content on its Services
            and can remove it with or without notice.
          </p>
          <p className="mt-10">
            <strong>4 RIGHTS AND LIMITATIONS</strong>
          </p>
          <p className="mt-5">
            <strong>4.1 Changes</strong>
          </p>
          <p className="mt-5">
            4.1.1 The Company reserves the right to modify this Contract, our Privacy Policy and our
            Cookies Policy from time to time. If we make material changes to it, we will provide you
            notice through our Services, or by other means, to provide you the opportunity to review
            the changes before they become effective.
          </p>
          <p className="mt-5">
            4.1.2 We agree that changes cannot be retroactive. If you object to any changes, you may
            close your account.
          </p>
          <p className="mt-5">
            4.1.3 Your continued use of our Services after we publish or send a notice about our
            changes to these terms means that you are consenting to the updated terms as of their
            effective date.
          </p>
          <p className="mt-5">
            <strong>4.2 Content and information</strong>
          </p>
          <p className="mt-5">
            4.2.1 As between you and the Company, you own the content and information that you
            submit or post to the Services, and you are only granting the Company and our affiliates
            the following non-exclusive licence:
          </p>
          <p className="mt-5">
            A worldwide, transferable and sublicensable right to use, copy, modify, distribute,
            publish and process, information and content that you provide through our Services and
            the services of others, without any further consent, notice and/or compensation to you
            or others. These rights are limited in the following ways:
          </p>
          <p className="mt-5">
            a) You can end this licence for specific content by deleting such content from the
            Services, or generally by closing your account, except (i) to the extent you shared it
            with others as part of the Service and they copied, re-shared it or stored it and (ii)
            for the reasonable time it takes to remove from backup and other systems;
          </p>
          <p className="mt-5">
            b) We will not include your content in advertisements for the products and services of
            third parties to others without your separate consent (including sponsored content).
            However, we have the right, without payment to you or others, to serve ads near your
            content and information, and your social actions may be visible and included with ads,
            as noted in the Privacy Policy. If you use a Service feature, we may mention that with
            your name or photo to promote that feature within our Services, subject to your
            settings;
          </p>
          <p className="mt-5">
            c) We will get your consent if we want to give others the right to publish your content
            beyond the Services. However, if you choose to share your post as "public, everyone or
            similar", we will enable a feature that allows other Members to embed that public post
            onto third-party services, and we enable search engines to make that public content
            findable though their Services;
          </p>
          <p className="mt-5">
            d) While we may edit and make format changes to your content (such as translating or
            transcribing it, modifying the size, layout or file type or removing metadata), we will
            not modify the meaning of your expression; and
          </p>
          <p className="mt-5">
            e) Because you own your content and information and we only have non-exclusive rights to
            it, you may choose to make it available to others, including under the terms of a
            Creative Commons licence.
          </p>
          <p className="mt-5">
            4.2.2 You and the Company agree that we may access, store, process and use any
            information and personal data that you provide in accordance with, the terms of the
            Privacy Policy and your choices (including settings).
          </p>
          <p className="mt-5">
            4.2.3 By submitting suggestions or other feedback regarding our Services to the Company,
            you agree that the Company can use and share (but does not have to) such feedback for
            any purpose without compensation to you.
          </p>
          <p className="mt-5">
            4.2.4 You agree to only provide content or information that does not violate the law nor
            anyone’s rights (including intellectual property rights). You also agree that your
            profile information will be truthful.
          </p>
          <p className="mt-5">
            <strong>4.3 Service availability</strong>
          </p>
          <p className="mt-5">
            4.3.1 We may change, suspend or discontinue any of our Services. We may also modify our
            prices effective prospectively upon reasonable notice to the extent allowed under the
            law.
          </p>
          <p className="mt-5">
            4.3.2 We don’t promise to store or keep showing any information and content that you’ve
            posted. Global Rugby is not a storage service. You agree that we have no obligation to
            store, maintain or provide you a copy of any content or information that you or others
            provide, except to the extent required by applicable law and as noted in our Privacy
            Policy.
          </p>
          <p className="mt-5">
            <strong>4.4 Other content, sites and apps</strong>
          </p>
          <p className="mt-5">
            4.4.1 By using the Services, you may encounter content or information that might be
            inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful.
            The Company generally does not review content provided by our Members or others. You
            agree that we are not responsible for others’ (including other Members’) content or
            information.
          </p>
          <p className="mt-5">
            4.4.2 We cannot always prevent this misuse of our Services, and you agree that we are
            not responsible for any such misuse. You also acknowledge the risk that you or your
            organisation may be mistakenly associated with content about others when we let
            connections and followers know you or your organisation were mentioned in the news.
          </p>
          <p className="mt-5">
            4.4.3 The Company may help connect Members offering their services (career coaching,
            accounting, etc.) with Members seeking services. The Company does not perform nor
            employs individuals to perform these services. You must be at least 18 years of age to
            offer, perform or procure these services.
          </p>
          <p className="mt-5">
            4.4.4 You acknowledge that the Company does not supervise, direct, control or monitor
            Members in the performance of these services and agree that:
          </p>
          <p className="mt-5">
            a) The Company is not responsible for the offering, performance or procurement of these
            services;
          </p>
          <p className="mt-5">
            b) The Company does not endorse any particular Member’s offered services, and
          </p>
          <p className="mt-5">
            c) nothing shall create an employment, agency, or joint venture relationship between the
            Company and any Member offering services.
          </p>
          <p className="mt-5">
            If you are a Member offering services, you represent and warrant that you have all the
            required licences and will provide services consistent with our Professional Community
            Policies.
          </p>
          <p className="mt-5">
            <strong>4.5 Limits</strong>
          </p>
          <p className="mt-5">
            4.5.1 The Company reserves the right to limit your use of the Services, including the
            number of your connections and your ability to contact other Members.
          </p>
          <p className="mt-5">
            4.5.2 The Company reserves the right to restrict, suspend, or terminate your account if
            you breach this Contract or the law or are misusing the Services (e.g., violating any of
            the terms of this Contract or Professional Community Policies.
          </p>
          <p className="mt-10">
            <strong>5 LIMITATION OF LIABILITY</strong>
          </p>
          <p className="mt-5">
            5.1 The Company makes no representation or warranty about the Services, including any
            representation that the Services will be uninterrupted or error-free, and provide the
            Services (including content and information) on an “as is” and “as available” basis.
          </p>
          <p className="mt-5">
            5.2 To the fullest extent permitted under applicable law, the Company disclaims any
            implied or statutory warranty, including any implied warranty of title, accuracy of
            data, non-infringement, merchantability, or fitness for a particular purpose.
          </p>
          <p className="mt-5">
            5.3 To the fullest extent permitted by law, the Company will not be liable in connection
            with this Contract for lost profits or lost business opportunities, reputation (e.g.,
            offensive or defamatory statements), loss of data (e.g., down time or loss, use of, or
            changes to, your information or content) or any indirect, incidental, consequential,
            special or punitive damages.
          </p>
          <p className="mt-5">
            5.4 The Company will not be liable to you in connection with this Contract for any
            amount that exceeds the total fees paid or payable by you to the Company for the
            Services during the term of this Contract, if any.
          </p>
          <p className="mt-5">
            5.5 The limitations of liability in this clause 5 are part of the basis of the agreement
            between you and the Company and shall apply to all claims of liability (e.g., warranty,
            tort, negligence, contract and law) even if the Company has been told of the possibility
            of any such damage, and even if these remedies fail their essential purpose.
          </p>
          <p className="mt-5">
            5.6 These limitations of liability do not apply to liability for death or personal
            injury or for fraud, gross negligence or intentional misconduct, or in cases of
            negligence where a material obligation has been breached, a material obligation being
            such which forms a prerequisite to our delivery of services and on which you may
            reasonably rely, but only to the extent that the damages were directly caused by the
            breach and were foreseeable upon conclusion of this Contract and to the extent that they
            are typical in the context of this Contract.
          </p>
          <p className="mt-5">
            <strong>6 TERMINATION</strong>
          </p>
          <p className="mt-5">
            6.1 Both you and the Company may terminate this Contract at any time with Notice to the
            other. On termination, you lose the right to access or use the Services.
          </p>
          <p className="mt-5">6.2 The following shall survive termination:</p>
          <p className="mt-5">a) Our rights to use and disclose your feedback;</p>
          <p className="mt-5">
            b) Members’ and/or Visitors’ rights to further re-share content and information you
            shared through the Services;
          </p>
          <p className="mt-5">c) Sections 4,5,7 and 8 of this Contract; and</p>
          <p className="mt-5">
            d) Any amounts owed by either party prior to termination remain owed after termination.
          </p>
          <p className="mt-5">
            <strong>7 GOVERNING LAW AND JURISDICTION</strong>
          </p>
          <p className="mt-5">
            7.1 The Contract and any dispute or claim (including non-contractual disputes or claims)
            arising out of or in connection with it or its subject matter or formation shall be
            governed by and construed in accordance with the law of England and Wales.
          </p>
          <p className="mt-5">
            7.2 Each party irrevocably agrees that the courts of England and Wales shall have
            exclusive jurisdiction to settle any dispute or claim (including non-contractual
            disputes or claims) arising out of or in connection with the Contract or its subject
            matter or formation.
          </p>
          <p className="mt-5">
            <strong>8 GENERAL TERMS</strong>
          </p>
          <p className="mt-5">
            8.1 If a court with authority over this Contract finds any part of it unenforceable, the
            parties agree that the court should modify the terms to make that part enforceable while
            still achieving its intent. If the court cannot do that, you and we agree to ask the
            court to remove that unenforceable part and still enforce the rest of this Contract.
          </p>
          <p className="mt-5">
            8.2 This Contract (including additional terms that may be provided by us when you engage
            with a feature of the Services) is the only agreement between us regarding the Services
            and supersedes all prior agreements for the Services.
          </p>
          <p className="mt-5">
            8.3 If we don't act to enforce a breach of this Contract, that does not mean that the
            Company has waived its right to enforce this Contract.
          </p>
          <p className="mt-5">
            8.4 You may not assign or transfer this Contract (or your membership or use of Services)
            to anyone without our consent. However, you agree that The Company may assign this
            Contract to its affiliates or a party that buys it without your consent.
          </p>
          <p className="mt-5">8.5 There are no third-party beneficiaries to this Contract.</p>
          <p className="mt-5">
            8.6 You agree that the only way to provide us Notice is at the addresses provided in
            this Contract.
          </p>
        </p>
        <h1 className="text-center mb-6 mt-10 font-inter font-bold">Privacy Policy</h1>
        <h2 className="text-center">
          <strong>GLOBAL SPORTS NETWORKING LTD</strong>
        </h2>
        <h2 className="text-center">
          <strong>GLOBAL RUGBY</strong>
        </h2>
        <p>
          <p className="font-inter">
            <strong>1. INTERPRETATION</strong>
          </p>
          <p className="mt-5">
            <strong>1.1 Definitions</strong>
          </p>
          <p className="mt-5">
            <strong>Company, “we”, “us”, “Global Rugby”</strong>: means{" "}
            <strong>GLOBAL SPORTS NETWORKING LTD</strong> of 27 Old Gloucester Street, London,
            England, WC1N 3AX, registered in England and Wales with company number 14853072.
          </p>
          <p className="mt-5">
            <strong>Data Controller: Global Rugby</strong>
          </p>
          <p className="mt-5">
            <strong>Members</strong>: means persons who have registered and joined the Company’s
            Services.
          </p>
          <p className="mt-5">
            <strong>Services</strong>: globalrugby.com and any associated sites, apps,
            communications and other services that state that they are offered under this Contract
          </p>
          <p className="mt-5">
            <strong>Visitors</strong>: means persons who have not registered and joined the
            Company’s Services but access certain features.
          </p>
          <p className="mt-5">
            <strong>1.2 Change</strong>
          </p>
          <p className="mt-5">
            We can modify this Privacy Policy, and if we make material changes to it, we will
            provide notice through our Services, or by other means, to provide you the opportunity
            to review the changes before they become effective. If you object to any changes, you
            may close your account.
          </p>
          <p className="mt-5">
            You acknowledge that your continued use of our Services after we publish or send a
            notice about our changes to this Privacy Policy means that the collection, use and
            sharing of your personal data is subject to the updated Privacy Policy, as of its
            effective date.
          </p>
          <p className="mt-10">
            <strong>2. DATA COLLECTION</strong>
          </p>
          <p className="mt-5">
            <strong>2.1 Registration</strong>
          </p>
          <p className="mt-5">
            To create an account, you need to provide data including your name, email address and/or
            mobile number, and a password. If you register for a premium Service, you will need to
            provide payment (e.g., credit card) and billing information.
          </p>
          <p className="mt-5">
            <strong>2.2 Profile</strong>
          </p>
          <p className="mt-5">
            You have choices about the information on your profile, such as your education, work
            experience, skills, photo, city or area and endorsements. You don’t have to provide
            additional information on your profile; however, profile information helps you to get
            more from our Services, including helping recruiters and business opportunities find
            you. It’s your choice whether to include sensitive information on your profile and to
            make that sensitive information public.
          </p>
          <p className="mt-5">
            <strong>2.3 Posting and Uploading</strong>
          </p>

          <p className="mt-5">
            We collect personal data from you when you provide, post or upload it to our Services,
            such as when you fill out a form, (e.g., with demographic data or salary), respond to a
            survey, or submit a resume or fill out a job application on our Services. If you opt to
            import your address book, we receive your contacts (including contact information your
            service provider(s) or app automatically added to your address book when you
            communicated with addresses or numbers not already in your list.
          </p>
          <p className="mt-5">
            If you sync your contacts or calendars with our Services, we will collect your address
            book and calendar meeting information to keep growing your network by suggesting
            connections for you and others, and by providing information about events, e.g., times,
            places, attendees and contacts.
          </p>
          <p className="mt-5">
            You don’t have to post or upload personal data; though if you don’t, it may limit your
            ability to grow and engage with your network over our Services.
          </p>
          <p className="mt-5">
            <strong>2.4 Data From Others</strong>
          </p>
          <p className="mt-5">
            2.4.1 You and others may post content that includes information about you (as part of
            articles, posts, comments, videos) on our Services. We also may collect public
            information about you, such as professional-related news and accomplishments, and make
            it available as part of our Services, including, as permitted by your settings, in
            notifications to others of mentions in the news.
          </p>
          <p className="mt-5">
            2.4.2 We receive personal data (including contact information) about you when others
            import or sync their contacts or calendar with our Services, associate their contacts
            with Member profiles, scan and upload business cards, or send messages using our
            Services (including invites or connection requests). If you or others opt-in to sync
            email accounts with our Services, we will also collect “email header” information that
            we can associate with Member profiles.
          </p>
          <p className="mt-5">
            2.4.3 We receive personal data (e.g., your job title and work email address) about you
            when you use the services of our customers and partners, such as employers or
            prospective employers and applicant tracking systems providing us job application data.
          </p>
          <p className="mt-5">
            2.4.4 We receive data about you when you use some of the other services provided by us
            or our affiliates, including Microsoft. For example, you may choose to send us
            information about your contacts in Microsoft apps and services, such as Outlook, for
            improved professional networking activities on our Services.
          </p>
          <p className="mt-5">
            2.4.5 We log usage data when you visit or otherwise use our Services, including our
            sites, app and platform technology, such as when you view or click on content (e.g.,
            learning video) or ads (on or off our sites and apps), perform a search, install or
            update one of our mobile apps, share articles or apply for jobs. We use logins, cookies,
            device information and internet protocol (“IP”) addresses to identify you and log your
            use.
          </p>
          <p className="mt-5">
            2.4.6 As further described in our Cookie Policy, we use cookies and similar technologies
            (e.g., pixels and ad tags) to collect data (e.g., device IDs) to recognise you and your
            device(s) on, off and across different services and devices where you have engaged with
            our Services. We also allow some others to use cookies as described in our Cookie
            Policy. You can opt out from our use of data from cookies and similar technologies that
            track your behaviour on the sites of others for ad targeting and other ad-related
            purposes.
          </p>
          <p className="mt-5">
            2.4.7 When you visit or leave our Services (including some plugins and our cookies or
            similar technology on the sites of others), we receive the URL of both the site you came
            from and the one you go to and the time of your visit. We also get information about
            your network and device (e.g., IP address, proxy server, operating system, web browser
            and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile
            carrier). If you use our Services from a mobile device, that device will send us data
            about your location based on your phone settings. We will ask you to opt-in before we
            use GPS or other tools to identify your precise location.
          </p>
          <p className="mt-5">
            2.4.8 We collect information about you when you send, receive, or engage with messages
            in connection with our Services. For example, if you get a Global Rugby connection
            request, we track whether you have acted on it and will send you reminders. We also use
            automatic scanning technology on messages to support and protect our site. For example,
            we use this technology to suggest possible responses to messages and to manage or block
            content that violates our User Agreement or Professional Community Policies from our
            Services.
          </p>
          <p className="mt-5">
            2.4.9 Others buying our Services for your use, such as your employer or your school,
            provide us with personal data about you and your eligibility to use the Services that
            they purchase for use by their workers, students or alumni. For example, we will get
            contact information for “Company Page” administrators.
          </p>
          <p className="mt-5">
            2.4.10 We receive information about your visits and interaction with services provided
            by others when you log-in with Global Rugby or visit others’ services that include some
            of our plugins (such as “Apply with Global Rugby”) or our ads, cookies or similar
            technologies.
          </p>
          <p className="mt-5">
            2.4.11 Our Services are dynamic, and we often introduce new features, which may require
            the collection of new information. If we collect materially different personal data or
            materially change how we collect, use or share your data, we will notify you and may
            also modify this Privacy Policy.
          </p>
          <p className="mt-10">
            <strong>3. HOW WE USE YOUR DATA</strong>
          </p>

          <p className="mt-5">
            3.1 We use your data to authorise access to our Services and honour your settings.
          </p>
          <p className="mt-5">
            3.2 We use data about you (such as your profile, profiles you have viewed or data
            provided through address book uploads or partner integrations) to help others find your
            profile, suggest connections for you and others (e.g., Members who share your contacts
            or job experiences) and enable you to invite others to become a Member and connect with
            you. You can also opt-in to allow us to use your precise location or proximity to others
            for certain tasks (e.g., to suggest other nearby Members for you to connect with,
            calculate the commute to a new job, or notify your connections that you are at a
            professional event).
          </p>
          <p className="mt-5">
            3.3 It is your choice whether to invite someone to our Services, send a connection
            request, or allow another Member to become your connection. When you invite someone to
            connect with you, your invitation will include your network and basic profile
            information (e.g., name, profile photo, job title, region). We will send invitation
            reminders to the person you invited.
          </p>
          <p className="mt-5">
            3.4 Our Services allow you to stay informed about news, events and ideas regarding
            professional topics you care about, and from professionals you respect. Our Services
            also allow you to improve your professional skills or learn new ones. We use the data we
            have about you (e.g., data you provide, data we collect from your engagement with our
            Services and inferences we make from the data we have about you), to personalise our
            Services for you, such as by recommending or ranking relevant content and conversations
            on our Services.
          </p>
          <p className="mt-5">
            3.5 We also use the data we have about you to suggest skills you could add to your
            profile and skills that you might need to pursue your next opportunity. So, if you let
            us know that you are interested in a new skill (e.g., by watching a learning video), we
            will use this information to personalise content in your feed, suggest that you follow
            certain members on our site, or suggest related learning content to help you towards
            that new skill.
          </p>
          <p className="mt-5">
            3.6 We use your content, activity and other data, including your name and photo, to
            provide notices to your network and others.
          </p>
          <p className="mt-5">
            3.7 Our Services allow you to explore careers, evaluate educational opportunities, and
            seek out, and be found for, career opportunities. Your profile can be found by those
            looking to hire (for a job or a specific task) or be hired by you. We will use your data
            to recommend jobs or mentees, show you and others relevant professional contacts (e.g.,
            who work at a company, in an industry, function or location or have certain skills and
            connections).
          </p>
          <p className="mt-5">
            3.8 You can signal that you are interested in changing jobs and share information with
            recruiters. We will use your data to recommend jobs to you and you to recruiters. We may
            use automated systems to provide content and recommendations to help make our Services
            more relevant to our Members, Visitors and customers.
          </p>
          <p className="mt-5">
            3.9 Our Services allow you to collaborate with colleagues, search for potential clients,
            customers, partners and others to do business with. Our Services allow you to
            communicate with other Members and schedule and prepare meetings with them. If your
            settings allow, we scan messages to provide “bots” or similar tools that facilitate
            tasks such as scheduling meetings, drafting responses, summarising messages or
            recommending next steps.
          </p>
          <p className="mt-5">
            3.10 We will contact you through email, mobile phone, notices posted on our websites or
            apps, messages to your Global Rugby inbox, and other ways through our Services,
            including text messages and push notifications. We will send you messages about the
            availability of our Services, security, or other service-related issues. We also send
            messages about how to use our Services, network updates, reminders, job suggestions and
            promotional messages from us and our partners.
          </p>
          <p className="mt-5">
            3.11 We target (and measure the performance of) ads to Members, Visitors and others both
            on and off our Services directly or through a variety of partners, using the following
            data, whether separately or combined:
          </p>
          <p className="mt-5">
            a) Data from advertising technologies on and off our Services, pixels, ad tags, cookies,
            and device identifiers;
          </p>
          <p className="mt-5">
            b) Member-provided information (e.g., profile, contact information, title and industry);
          </p>
          <p className="mt-5">
            c) Data from your use of our Services (e.g., search history, feed, content you read, who
            you follow or is following you, connections, groups participation, page visits, videos
            you watch, clicking on an ad, etc.), including as described in Section 1.3;
          </p>
          <p className="mt-5">
            d) Information from advertising partners, vendors and publishers; and
          </p>
          <p className="mt-5">
            e) Information inferred from data described above (e.g., using job titles from a profile
            to infer industry, seniority, and compensation bracket; using graduation dates to infer
            age or using first names or pronoun usage to infer gender; using your feed activity to
            infer your interests; or using device data to recognise you as a Member).
          </p>
          <p className="mt-5">
            3.12 We will show you ads called sponsored content which look similar to non-sponsored
            content, except that they are labelled as advertising (e.g., as “ad” or “sponsored”). If
            you take a social action (such as like, comment or share) on these ads, your action is
            associated with your name and viewable by others, including the advertiser.
          </p>
          <p className="mt-5">
            3.13 Subject to your settings, if you take a social action on the Global Rugby Services,
            that action may be mentioned with related ads.
          </p>
          <p className="mt-5">
            3.14 We adhere to self-regulatory principles for interest-based advertising and
            participate in industry opt-outs from such ads. This does not opt you out of receiving
            advertising; you will continue to get other ads by advertisers not listed with these
            self-regulatory tools. You can also opt-out specifically from our uses of certain
            categories of data to show you more relevant ads.
          </p>
          <p className="mt-5">
            3.15 We do not share your personal data with any third-party advertisers or ad networks
            except for:
          </p>
          <p className="mt-5">
            a) hashed IDs or device identifiers (to the extent they are personal data in some
            countries);
          </p>
          <p className="mt-5">
            b) with your separate permission (e.g., in a lead generation form) or
          </p>
          <p className="mt-5">
            c) data already visible to any users of the Services (e.g., profile).
          </p>
          <p className="mt-5">
            However, if you view or click on an ad on or off our Services, the ad provider will get
            a signal that someone visited the page that displayed the ad, and they may, through the
            use of mechanisms such as cookies, determine it is you. Advertising partners can
            associate personal data collected by the advertiser directly from you with hashed IDs or
            device identifiers received from us. In such instances, we seek to contractually require
            such advertising partners to obtain your explicit, opt-in consent before doing so.
          </p>
          <p className="mt-5">
            3.16 We use Members’ data and content for invitations and communications promoting
            membership and network growth, engagement and our Services, such as by showing your
            connections that you have used a feature on our Services.
          </p>
          <p className="mt-5">
            3.17 We use data, including public feedback, to conduct research and development for our
            Services in order to provide you and others with a better, more intuitive and
            personalised experience, drive membership growth and engagement on our Services, and
            help connect professionals to each other and to economic opportunity.
          </p>
          <p className="mt-5">
            3.18 We seek to create economic opportunity for Members of the global workforce and to
            help them be more productive and successful. We use the personal data available to us to
            research social, economic and workplace trends, such as jobs availability and skills
            needed for these jobs and policies that help bridge the gap in various industries and
            geographic areas. In some cases, we work with trusted third parties to perform this
            research, under controls that are designed to protect your privacy. We publish or allow
            others to publish economic insights, presented as aggregated data rather than personal
            data.
          </p>
          <p className="mt-5">
            3.19 Polls and surveys are conducted by us and others through our Services. You are not
            obligated to respond to polls or surveys, and you have choices about the information you
            provide. You may opt-out of survey invitations.
          </p>
          <p className="mt-5">
            3.20 We use data (which can include your communications) to investigate, respond to and
            resolve complaints and for Service issues (e.g., bugs).
          </p>
          <p className="mt-5">
            3.21 We use your data to produce and share insights that do not identify you. For
            example, we may use your data to generate statistics about our members, their profession
            or industry, to calculate ad impressions served or clicked on, or to publish visitor
            demographics for a Service or create demographic workforce insights.
          </p>
          <p className="mt-5">
            3.22 We use your data (including your communications) for security purposes or to
            prevent or investigate possible fraud or other violations of our User Agreement and/or
            attempts to harm our Members, Visitors or others.
          </p>

          <p className="mt-10">
            <strong>4. HOW WE SHARE INFORMATION</strong>
          </p>

          <p className="mt-5">
            <strong>4.1 Your Profile</strong>
          </p>

          <p className="mt-5">
            Your profile is fully visible to all Members and customers of our Services. Subject to
            your settings, it can also be visible to others on or off of our Services (e.g.,
            Visitors to our Services or users of third- party search engines). As detailed in our
            Help Center, your settings, degree of connection with the viewing Member, the
            subscriptions they may have, their usage of our Services, access channels and search
            types (e.g., by name or by keyword) impact the availability of your profile and whether
            they can view certain fields in your profile.
          </p>
          <p className="mt-5">
            <strong>4.2 Your Activity (posts, likes, follows, comments, messages)</strong>
          </p>

          <p className="mt-5">
            4.2.1 When you share an article or a post (e.g., an update, image, video or article)
            publicly it can be viewed by everyone and re-shared anywhere (subject to your settings).
            Members, Visitors and others will be able to find and see your publicly shared content,
            including your name (and photo if you have provided one).
          </p>
          <p className="mt-5">
            4.2.2 In a group, posts are visible to others in the group. Your membership in groups is
            public and part of your profile, but you can change visibility in your settings.
          </p>
          <p className="mt-5">
            4.2.3 Any information you share through companies’ or other organisations’ pages on our
            Services will be viewable by it and others who visit those pages.
          </p>
          <p className="mt-5">
            4.2.4 When you follow a person or organisation, you are visible to others and that “page
            owner” as a follower.
          </p>
          <p className="mt-5">
            4.2.5 We let senders know when you act on their message, subject to your settings where
            applicable.
          </p>
          <p className="mt-5">
            4.2.6 Subject to your settings, we let a Member know when you view their profile.
          </p>
          <p className="mt-5">
            4.2.7 When you like or re-share or comment on another’s content (including ads), others
            will be able to view these “social actions” and associate it with you (e.g., your name,
            profile and photo if you provided it).
          </p>
          <p className="mt-5">
            <strong>4.3 Company Accounts</strong>
          </p>

          <p className="mt-5">
            4.3.1 We understand that certain activities such as job hunting and personal messages
            are sensitive, and so we do not share those with your employer unless you choose to
            share it with them through our Services (for example, by applying for a new position in
            the same company or mentioning your job hunting in a message to a co-worker through our
            Services).
          </p>
          <p className="mt-5">
            4.3.2 Subject to your settings, when you use workplace tools and services (e.g.,
            interactive employee directory tools) certain of your data may also be made available to
            your employer or be connected with information we receive from your employer to enable
            these tools and services.
          </p>
          <p className="mt-5">
            <strong>4.4 Communication Archival</strong>
          </p>

          <p className="mt-5">
            Some Members (or their employers) need, for legal or professional compliance, to archive
            their communications and social media activity, and will use services of others to
            provide these archival services. We enable archiving of messages by and to those Members
            outside of our Services.
          </p>
          <p className="mt-5">
            <strong>4.5 Others’ Services</strong>
          </p>

          <p className="mt-5">
            Subject to your settings, other services may look up your profile. When you opt to link
            your account with other services, personal data will become available to them. The
            sharing and use of that personal data will be described in, or linked to, a consent
            screen when you opt to link the accounts. For example, you may link your Twitter or
            WeChat account to share content from our Services into these other services, or your
            email provider may give you the option to upload your Global Rugby contacts into its own
            service. Third-party services have their own privacy policies, and you may be giving
            them permission to use your data in ways we would not. You may revoke the link with such
            accounts.
          </p>
          <p className="mt-5">
            Subject to your settings, excerpts from your profile will appear on the services of
            others (e.g., search engine results, mail and calendar applications that show a user
            limited profile data of the person they are meeting or messaging, social media
            aggregators, talent and lead managers). “Old” profile information remains on these
            services until they update their data cache with changes you made to your profile
          </p>
          <p className="mt-5">
            <strong>4.6 Related Services</strong>
          </p>

          <p className="mt-5">
            We will share your personal data with our affiliates to provide and develop our
            Services. We may combine information internally across the different Services covered by
            this Privacy Policy to help our Services be more relevant and useful to you and others.
          </p>

          <p className="mt-5">
            <strong>4.7 Service Providers</strong>
          </p>

          <p className="mt-5">
            We use others to help us provide our Services (e.g., maintenance, analysis, audit,
            payments, fraud detection, marketing and development). They will have access to your
            information as reasonably necessary to perform these tasks on our behalf and are
            obligated not to disclose or use it for other purposes.
          </p>
          <p className="mt-5">
            <strong>4.8 Legal Disclosures</strong>
          </p>

          <p className="mt-5">
            It is possible that we will need to disclose information about you when required by law,
            subpoena, or other legal process or if we have a good faith belief that disclosure is
            reasonably necessary to:
          </p>
          <p className="mt-5">
            a) investigate, prevent or take action regarding suspected or actual illegal activities
            or to assist government enforcement agencies;
          </p>
          <p className="mt-5">b) enforce our agreements with you;</p>
          <p className="mt-5">
            c) investigate and defend ourselves against any third-party claims or allegations;
          </p>
          <p className="mt-5">
            d) protect the security or integrity of our Services (such as by sharing with companies
            facing similar threats); or
          </p>
          <p className="mt-5">
            e) exercise or protect the rights and safety of Global Rugby, our Members, personnel or
            others.
          </p>
          <p className="mt-5">
            We attempt to notify Members about legal demands for their personal data when
            appropriate in our judgment, unless prohibited by law or court order or when the request
            is an emergency. We may dispute such demands when we believe, in our discretion, that
            the requests are overbroad, vague or lack proper authority, but we do not promise to
            challenge every demand.
          </p>
          <p className="mt-5">
            <strong>4.9 Change in Control or Sale</strong>
          </p>

          <p className="mt-5">
            We can also share your personal data as part of a sale, merger or change in control, or
            in preparation for any of these events. Any other entity which buys us or part of our
            business will have the right to continue to use your data, but only in the manner set
            out in this Privacy Policy unless you agree otherwise.
          </p>
          <p className="mt-5">
            <strong>5. YOUR CHOICES AND OBLIGATIONS</strong>
          </p>

          <p className="mt-5">
            <strong>5.1 Data Retention</strong>
          </p>

          <p className="mt-5">
            We generally retain your personal data as long as you keep your account open or as
            needed to provide you Services. This includes data you or others provided to us and data
            generated or inferred from your use of our Services. Even if you only use our Services
            when looking for a new job every few years, we will retain your information and keep
            your profile open, unless you close your account. In some cases, we choose to retain
            certain information (e.g., insights about Services use) in a depersonalised or
            aggregated form.
          </p>
          <p className="mt-5">
            <strong>5.2 Rights to Access and Control of Your Personal Data</strong>
          </p>
          <p className="mt-5">
            5.2.1 We provide many choices about the collection, use and sharing of your data, from
            deleting or correcting data you include in your profile and controlling the visibility
            of your posts to advertising opt-outs and communication controls. We offer you settings
            to control and manage the personal data we have about you.
          </p>
          <p className="mt-5">5.2.2 For personal data that we have about you, you can:</p>
          <p className="mt-5">
            a) Delete Data: You can ask us to erase or delete all or some of your personal data
            (e.g., if it is no longer necessary to provide Services to you);
          </p>
          <p className="mt-5">
            b) Change or Correct Data: You can edit some of your personal data through your account.
            You can also ask us to change, update or fix your data in certain cases, particularly if
            it’s inaccurate;
          </p>
          <p className="mt-5">
            c) Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or
            some of your personal data (e.g., if we have no legal right to keep using it) or to
            limit our use of it (e.g., if your personal data is inaccurate or unlawfully held); and
          </p>
          <p className="mt-5">
            d) Right to Access and/or Take Your Data: You can ask us for a copy of your personal
            data and can ask for a copy of personal data you provided in machine-readable form.
          </p>
          <p className="mt-5">
            <strong>5.3 Account Closure</strong>
          </p>

          <p className="mt-5">
            5.3.1 If you choose to close your Global Rugby account, your personal data will
            generally stop being visible to others on our Services within 24 hours.
          </p>
          <p className="mt-5">
            5.3.2 We generally delete closed account information within 30 days of account closure,
            except as noted below.
          </p>
          <p className="mt-5">
            5.3.3 We retain your personal data even after you have closed your account if reasonably
            necessary to comply with our legal obligations (including law enforcement requests),
            meet regulatory requirements, resolve disputes, maintain security, prevent fraud and
            abuse (e.g., if we have restricted your account for breach of our Professional Community
            Policies), enforce our User Agreement, or fulfil your request to "unsubscribe" from
            further messages from us. We will retain de-personalised information after your account
            has been closed.
          </p>
          <p className="mt-5">
            5.3.4 Information you have shared with others (e.g., through updates or group posts)
            will remain visible after you close your account or delete the information from your own
            profile or mailbox, and we do not control data that other Members have copied out of our
            Services. Groups content and ratings or review content associated with closed accounts
            will show an unknown user as the source. Your profile may continue to be displayed in
            the services of others (e.g., search engine results) until they refresh their cache.
          </p>
          <p className="mt-10">
            <strong>6. FURTHER INFORMATION</strong>
          </p>

          <p className="mt-5">
            6.1 We implement security safeguards designed to protect your data, such as HTTPS. We
            regularly monitor our systems for possible vulnerabilities and attacks. However, we
            cannot warrant the security of any information that you send us. There is no guarantee
            that data may not be accessed, disclosed, altered, or destroyed by breach of any of our
            physical, technical, or managerial safeguards.
          </p>
          <p className="mt-5">
            6.2 We process data both inside and outside of the United Kingdom and rely on legally
            provided mechanisms to lawfully transfer data across borders. Countries where we process
            data may have laws which are different from, and potentially not as protective as, the
            laws of your own country.
          </p>
          <p className="mt-5">
            6.3 We will only collect and process personal data about you where we have lawful bases.
            Lawful bases include consent (where you have given consent), contract (where processing
            is necessary for the performance of a contract with you (e.g., to deliver the Global
            Rugby Services you have requested) and “legitimate interests.”
          </p>
          <p className="mt-5">
            6.4 Where we rely on your consent to process personal data, you have the right to
            withdraw or decline your consent at any time and where we rely on legitimate interests,
            you have the right to object.
          </p>
          <p className="mt-5">
            6.5 Where we process data based on consent, we will ask for your explicit consent. You
            may withdraw your consent at any time, but that will not affect the lawfulness of the
            processing of your personal data prior to such withdrawal. Where we rely on contract, we
            will ask that you agree to the processing of personal data that is necessary for
            entering into or performance of your contract with us. We will rely on legitimate
            interests as a basis for data processing where the processing of your data is not
            overridden by your interests or fundamental rights and freedoms.
          </p>
          <p className="mt-5">
            6.6 We currently do not share personal data with third parties for their direct
            marketing purposes without your permission.
          </p>
        </p>
      </div>
    </>
  )
}

export default TermsPolicy
