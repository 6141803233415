import React, { useContext, useEffect, useState } from "react"
import UploadFile from "../../assets/svg/UploadFile.svg"
import File from "../../assets/svg/File.svg"
import Bin from "../../assets/svg/BinIcon.svg"
import PdfIcon from "../../assets/svg/clubprofileicon/PdfIcon"
import WordIcon from "../../assets/svg/clubprofileicon/WordIcon"
import ImageIcon from "../../assets/svg/clubprofileicon/ImageIcon"
import { useConfigAPI } from "../../services/useConfigAPI"
import AppContext from "../../context/app-context/AppContext"
import { IUploadFilesResponse } from "../../modals/Config"
import { Link } from "react-router-dom"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"
import { Popover, Typography } from "@mui/material"
import { errorToast } from "../../utils/ToastUtils"

interface IQualificationProps {
  addUploadedFileId: (id: number) => void
}

const Qualification = (props: IQualificationProps) => {
  //props
  const { addUploadedFileId } = props

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  //states
  const [file, setFile] = useState<IUploadFilesResponse>()
  const [files, setFiles] = useState<IUploadFilesResponse[]>(
    loginAsProfileDetails?.qualification ?? [],
  )

  const [uploadedDocumentCount, setUploadedDocumentCount] = useState<number>(
    loginAsProfileDetails?.qualification?.length ?? 0,
  )

  useEffect(() => {
    // Update the document count when the component mounts
    setUploadedDocumentCount(loginAsProfileDetails?.qualification?.length ?? 0)
  }, [loginAsProfileDetails])

  useEffect(() => {
    if (file) {
      setFiles([...files, file])
    }
  }, [file])

  const handleDelete = async (id: number | null) => {
    if (!id) return
    const uploadResponse = await deleteFiles(id)
    if (uploadResponse) {
      const newFiles = files.filter((f) => f?.id !== uploadResponse?.id)
      setFiles(newFiles)
      const fileInput = document.getElementById("fileUpload") as HTMLInputElement
      if (fileInput) {
        fileInput.value = ""
      }
    }
  }

  const getFileIcon = (fileName: string) => {
    const extension = fileName?.split(".")?.pop()?.toLowerCase()
    if (extension === "pdf" || extension === "pptx") {
      return <PdfIcon />
    } else if (extension === "doc" || extension === "docx") {
      return <WordIcon />
    } else if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "svg"
    ) {
      return <ImageIcon />
    } else {
      return <img src={File} alt="" />
    }
  }

  const { uploadFiles, deleteFiles } = useConfigAPI()

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        if (uploadedDocumentCount + event.target.files.length <= 10) {
          const file = event.target.files[0]
          const formData = new FormData()
          formData.append("files", file, file?.name)
          const uploadResponse = await uploadFiles(formData)
          if (uploadResponse?.[0]) {
            addUploadedFileId(uploadResponse?.[0]?.id)
            setFile(uploadResponse?.[0])
            setUploadedDocumentCount(uploadedDocumentCount + 1)
          }
        } else {
          errorToast("You can only upload a maximum of 10 qualification documents.")
        }
      } else {
        setFile(undefined)
      }
    } catch {}
  }

  // Handler function to toggle the message
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  return (
    <div>
      <div className="flex justify-between">
        <span className="text-sm font-[400] flex mb-1">Qualification</span>
        <div
          className="mt-2 cursor-pointer"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <ExclamtionIcon />
        </div>
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>
          <div className="flex gap-2 text-sm">
            Add qualification documents, ex. WR Level 1 coaching qualification
          </div>
        </Typography>
      </Popover>

      <label
        htmlFor="fileUpload"
        className="cursor-pointer border-2 border-dashed border-red-darkRed h-[174px] w-full rounded-[24px] flex flex-col justify-center items-center"
      >
        <img src={UploadFile} alt="upload" />
        <div className="text-[20px] font-inter text-black">Browse files to upload</div>
      </label>
      <input
        type="file"
        id="fileUpload"
        onChange={handleFileUpload}
        className="hidden"
        accept=".doc,.docx,.jpeg,.jpg, .png, .raw, .indd, .pdf, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
      <div className="flex flex-col gap-2 mb-4">
        {files?.map((file, idx) => (
          <div key={idx} className="grid sm:grid-cols-1 gap-2 mt-4">
            <div className="">
              <FilesCard file={file} handleDelete={handleDelete} getFileIcon={getFileIcon} />
            </div>
            {/* <div className="flex items-center">
              <GRTextfield placeholder="Write title" width="100%" />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

interface IFilesCard {
  file: IUploadFilesResponse
  handleDelete: (id: number) => void
  getFileIcon: (fileName: string) => React.ReactNode
}

const FilesCard: React.FC<IFilesCard> = ({ file, handleDelete, getFileIcon }) => {
  return (
    <div className="flex justify-between items-center bg-bgRed rounded-[24px] px-4 py-3">
      <Link to={file?.url} target="_blank">
        <div className="flex gap-2 items-center">
          <div className="qualification-uploaded-img">{getFileIcon(file?.name)}</div>
          <div className="text-sm" title={file?.name}>
            {file?.name.substring(0, 15)}
          </div>
        </div>
      </Link>
      <div className="cursor-pointer" onClick={() => handleDelete(file?.id)}>
        <img src={Bin} alt="deleteIcon" />
      </div>
    </div>
  )
}

export default Qualification
