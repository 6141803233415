import * as React from "react"

function SvgComponent({ size = 12 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M7.754.17a.583.583 0 01.825 0l2.333 2.334a.583.583 0 010 .825L3.33 10.912a.583.583 0 01-.412.171H.583A.583.583 0 010 10.5V8.167c0-.155.061-.303.17-.413l5.834-5.833 1.75-1.75zM6.417 3.159l-5.25 5.25v1.509h1.508l5.25-5.25-1.508-1.509zm2.333.684l.925-.925-1.508-1.509-.925.925L8.75 3.842z"
        fill="#4C535D"
      />
    </svg>
  )
}

export default SvgComponent
