import React from "react"
import GRButton from "../ui/GRButton"
import { IBanner } from "../../services/useLoginUserAPI"
import { UNAUTH_LOGIN } from "../../routes/RouteConstants"
import { Link } from "react-router-dom"

interface IBannerProps {
  banner: IBanner | undefined
}
const Banner: React.FC<IBannerProps> = ({ banner }) => {
  const signIn = () => {
    console.log("Hello")
  }
  return (
    <div
      style={{ backgroundImage: `url(${banner?.bgImage?.url})` }}
      className={`w-full  min-h-[325px] md:min-h-[300px] bg-center bg-cover bg-no-repeat`}
    >
      <div
        className="p-4 md:p-16 text-white text-center min-h-[325px] md:min-h-[300px]"
        style={{ background: "rgb(0 0 0 / 64%)", width: "100%" }}
      >
        <p className="text-4xl font-bold">{banner?.heading}</p>
        <p className="px-4 md:px-8 lg:px-24 xl:px-40 mt-6 font-normal text-base font-inter">
          {banner?.description}
        </p>
        <div className="mt-6">
          <Link
            to={UNAUTH_LOGIN}
            className="font-normal text-base underline text-[12px] text-gr-primaryR2"
          >
            <GRButton
              onClick={signIn}
              className="!font-normal"
              label="Sign in"
              borderRadius={5}
              width="100px"
              height={32}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Banner
