import React, { useState, useContext, useEffect, useMemo } from "react"
import CrossIcon from "../../../assets/svg/CrossIcon"
import { Checkbox, List } from "@mui/material"
import AppContext from "../../../context/app-context/AppContext"
import FilterOptions from "./FilterOption"
import { useOpportunityAPI } from "../../../services/useOpportunityAPI"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import { getUniqueOptionsByProperty } from "../../../utils/CommonUtils"

interface IGRFilterProps {
  setOpen: (value: boolean) => void
  setOpportunity?: any
  getOpportunities?: any
}
const FilterOpportunityPopup: React.FC<IGRFilterProps> = ({
  setOpen,
  setOpportunity,
  getOpportunities,
}) => {
  // Context
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [showLocations, setShowLocations] = useState<boolean>(false)
  const [showClub, setShowClub] = useState<boolean>(false)
  const [showLevel, setShowLevel] = useState<boolean>(false)
  const [showDiscipline, setShowDiscipline] = useState<boolean>(false)
  const [showPosition, setShowPosition] = useState<boolean>(false)
  const [showAgeGroup, setShowAgeGroup] = useState<boolean>(false)
  const [filterParams, setFilterParams] = useState<string[]>([])
  const [index, setIndex] = useState(0)

  const handleToggleLocations = () => {
    setShowLocations(!showLocations)
  }

  const handleToggleClub = () => {
    setShowClub(!showClub)
  }

  const handleToggleLevel = () => {
    setShowLevel(!showLevel)
  }

  const handleToggleDiscipline = () => {
    setShowDiscipline(!showDiscipline)
  }

  const handleTogglePosition = () => {
    setShowPosition(!showPosition)
  }
  const handleToggleAgeGroup = () => {
    setShowAgeGroup(!showAgeGroup)
  }
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[] | undefined>([])
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)
  // APIs
  const { getOpportunityByProfileType } = useOpportunityAPI()
  useEffect(() => {
    getOpportunity()
  }, [])

  const getOpportunity = async () => {
    try {
      const opportunityList = await getOpportunityByProfileType(
        `profileType=${loginAsProfileDetails?.profileType}`,
      )
      setOpportunities(opportunityList?.opportunity)
    } catch (error) {}
  }
  const uniqueClubNameOptions = useMemo(
    () => getUniqueOptionsByProperty(opportunities, "club.name", "club", "club.id"),
    [opportunities],
  )
  const uniqueCountryOptions = useMemo(
    () => getUniqueOptionsByProperty(opportunities, "country", "country", ""),
    [opportunities],
  )
  const uniqueLevelOptions = useMemo(
    () => getUniqueOptionsByProperty(opportunities, "level.title", "level", "level.id"),
    [opportunities],
  )
  const uniqueDisciplineOptions = useMemo(
    () =>
      getUniqueOptionsByProperty(opportunities, "discipline.title", "discipline", "discipline.id"),
    [opportunities],
  )
  const uniquePositionOptions = useMemo(
    () =>
      getUniqueOptionsByProperty(opportunities, "playerPosition.title", "position", "position.id"),
    [opportunities],
  )
  const uniqueAgeGroupOptions = useMemo(
    () => getUniqueOptionsByProperty(opportunities, "ageGroup.title", "ageGroup", "ageGroup.id"),
    [opportunities],
  )
  const handleCheckboxChange = () => {
    setAllChecked(!allChecked)
    getOpportunities()
  }
  return (
    <div className="relative w-full h-[689px] rounded-[20px] bg-white">
      {/* crossIcon */}
      <div className="absolute cursor-pointer top-6 right-[24px] " onClick={() => setOpen(false)}>
        <CrossIcon />
      </div>

      {/* filters */}
      <div className="w-full h-[687px] rounded-lg p-5 pt-3">
        {/* heading */}
        <div className="flex items-center text-lg mb-3">
          <div className="-ml-3">
            <Checkbox
              checked={allChecked}
              onChange={() => handleCheckboxChange()}
              style={{
                color: "#9F0002",
              }}
            />
          </div>
          <div className="text-gr-neutralT2 text-base  font-inter font-medium">All filters</div>
        </div>
        {/* handle scroll */}
        <div className="overflow-y-scroll h-[600px] filterScroller">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              padding: "0px 16px 0 0",
            }}
          >
            <div className="mb-3 flex justify-between items-center pb-2 border-b border-gr-primaryN8">
              <div className="text-blue-dark2 font-medium text-base font-inter">Locations</div>
              <div
                className="text-gr-primaryN1 text-[10px] font-inter underline cursor-pointer text-xs hover:text-gr-primaryR2"
                onClick={handleToggleLocations}
              >
                {showLocations ? "View less" : "View all"}
              </div>
            </div>
            <FilterOptions
              setOpportunities={setOpportunity}
              getOpportunity={getOpportunities}
              options={uniqueCountryOptions}
              isShowAll={showLocations}
              allChecked={allChecked}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              index={index}
              setIndex={setIndex}
            />
            <div className="mt-4 mb-3 pb-2 flex justify-between items-center border-b border-gr-primaryN8">
              <div className="text-blue-dark2 font-medium text-base font-inter">Club</div>
              <div
                className="text-gr-primaryN1 text-[10px] font-inter underline cursor-pointer text-xs hover:text-gr-primaryR2"
                onClick={handleToggleClub}
              >
                {showClub ? "View less" : "View all"}
              </div>
            </div>
            <FilterOptions
              setOpportunities={setOpportunity}
              getOpportunity={getOpportunities}
              options={uniqueClubNameOptions}
              isShowAll={showLocations}
              allChecked={allChecked}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              index={index}
              setIndex={setIndex}
            />
            <div className="mt-4 mb-3 pb-2 flex justify-between items-center border-b border-gr-primaryN8">
              <div className="text-blue-dark2 font-medium text-base font-inter">Level</div>
              <div
                className="text-gr-primaryN1 text-[10px] font-inter underline cursor-pointer text-xs hover:text-gr-primaryR2"
                onClick={handleToggleLevel}
              >
                {showLevel ? "View less" : "View all"}
              </div>
            </div>
            <FilterOptions
              setOpportunities={setOpportunity}
              getOpportunity={getOpportunities}
              options={uniqueLevelOptions}
              allChecked={allChecked}
              isShowAll={showLevel}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              index={index}
              setIndex={setIndex}
            />
            <div className="mt-4 mb-3 pb-2 flex justify-between items-center border-b border-gr-primaryN8">
              <div className="text-blue-dark2 font-medium text-base font-inter">Code</div>
              <div
                className="text-gr-primaryN1 text-[10px] font-inter underline cursor-pointer text-xs hover:text-gr-primaryR2"
                onClick={handleToggleDiscipline}
              >
                {showDiscipline ? "View less" : "View all"}
              </div>
            </div>
            <FilterOptions
              setOpportunities={setOpportunity}
              getOpportunity={getOpportunities}
              options={uniqueDisciplineOptions}
              allChecked={allChecked}
              isShowAll={showDiscipline}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              index={index}
              setIndex={setIndex}
            />
            <div className="mt-4 mb-3 pb-2 flex justify-between items-center border-b border-gr-primaryN8">
              <div className="text-blue-dark2 font-medium text-base font-inter">Position</div>
              <div
                className="text-gr-primaryN1 text-[10px] font-inter underline cursor-pointer text-xs hover:text-gr-primaryR2"
                onClick={handleTogglePosition}
              >
                {showPosition ? "View less" : "View all"}
              </div>
            </div>
            <FilterOptions
              setOpportunities={setOpportunity}
              getOpportunity={getOpportunities}
              options={uniquePositionOptions}
              allChecked={allChecked}
              isShowAll={showPosition}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              index={index}
              setIndex={setIndex}
            />
            <div className="mt-4 mb-3 pb-2 flex justify-between items-center border-b border-gr-primaryN8">
              <div className="text-blue-dark2 font-medium text-base font-inter">Age Group</div>
              <div
                className="text-gr-primaryN1 text-[10px] font-inter underline cursor-pointer text-xs hover:text-gr-primaryR2"
                onClick={handleToggleAgeGroup}
              >
                {showAgeGroup ? "View less" : "View all"}
              </div>
            </div>
            <FilterOptions
              setOpportunities={setOpportunity}
              getOpportunity={getOpportunities}
              options={uniqueAgeGroupOptions}
              allChecked={allChecked}
              isShowAll={showAgeGroup}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              index={index}
              setIndex={setIndex}
            />
          </List>
        </div>
      </div>
    </div>
  )
}

export default FilterOpportunityPopup
