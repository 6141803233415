/* eslint-disable react/jsx-key */
import { Popover, Tooltip } from "@mui/material"
import { AUTH_CHANGE_PASSWORD, AUTH_DASHBOARD, AUTH_MY_PROFILE } from "../routes/RouteConstants"
import React, { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import AppContext from "../context/app-context/AppContext"
import { useUserHook } from "../hooks/useUserHook"
import { getNameInitials } from "../utils/CommonUtils"
import ClubLogo from "../assets/svg/ClubLogo.svg"

interface IUserAccountSettingComponentProps {
  handleClose: () => void
  anchorElUserSetting: (EventTarget & Element) | null
  handleLogout: () => void
}

const UserAccountSetting: React.FC<IUserAccountSettingComponentProps> = (props) => {
  const { handleClose, anchorElUserSetting, handleLogout } = props

  // Context
  const { primaryUserDetails } = useContext(AppContext)

  const { getCurrentLoginUserPorfilePicUrl } = useUserHook()

  const profilePicUrl = getCurrentLoginUserPorfilePicUrl()

  // Hooks
  const { handleSwitchAccount } = useUserHook()
  const navigate = useNavigate()

  const handleItemClick = () => {
    handleClose()
  }

  return (
    <Popover
      open={Boolean(anchorElUserSetting)}
      anchorEl={anchorElUserSetting}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      onClose={handleClose}
    >
      <div className="p-2 flex flex-col bg-white w-[157px] rounded-[8px] sm:flex  sm:w-[200px] min-h-[196px] py-4">
        <div className="mb-2.5">
          <span className="cursor-pointer font-inter text-[12px] text-gr-textUserProfile4 font-semibold hover:underline">
            <Link to={AUTH_MY_PROFILE} onClick={handleItemClick}>
              Profile
            </Link>
          </span>
        </div>
        <div className="border-b border-gr-primaryI1"></div>
        <div className="mb-1">
          <span className="cursor-pointer font-inter text-[13px] text-gr-textUserProfile4 font-semibold">
            Switch Account
          </span>
        </div>
        <div
          className="mb-2.5"
          onClick={() => {
            primaryUserDetails
              ? handleSwitchAccount({
                  profileId: primaryUserDetails?.id,
                  profileType: primaryUserDetails?.profileType,
                })
              : undefined
            navigate(AUTH_DASHBOARD)
            handleItemClick()
          }}
        >
          <div className="truncate cursor-pointer font-inter text-[12px] text-gr-neutralT3 font-medium flex justify-start items-center gap-2">
            {profilePicUrl ? (
              <div>
                <img className="w-[30px] h-[30px] rounded-full" src={profilePicUrl} alt="Pic" />
              </div>
            ) : (
              <div className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-gr-primaryR1">
                <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                  {getNameInitials(primaryUserDetails?.firstname, primaryUserDetails?.lastname)}
                </span>
              </div>
            )}

            <div className="hover:underline">
              {`${primaryUserDetails?.firstname} ${primaryUserDetails?.lastname}` || ""}
            </div>
          </div>
        </div>
        {primaryUserDetails?.member?.[0] && (
          <div className="">
            <div className="font-inter text-[13px] text-gr-textUserProfile4 font-semibold mb-1">
              Children
            </div>
            <div>
              {primaryUserDetails?.member
                .filter((member) => member?.childMember)
                ?.map((m) => (
                  <div
                    onClick={() => {
                      primaryUserDetails && m?.childMember?.id
                        ? handleSwitchAccount({
                            profileId: m?.childMember?.id,
                            profileType: "Child",
                          })
                        : undefined
                      navigate(AUTH_DASHBOARD)
                      handleItemClick()
                    }}
                    key={m?.id}
                    className="truncate cursor-pointer font-inter text-[12px] mb-1 text-gr-neutralT3 font-medium flex justify-start items-center gap-2"
                  >
                    {m?.childMember?.profilePicture?.url ? (
                      <div>
                        <img
                          className="w-[30px] h-[30px] rounded-full"
                          src={m?.childMember?.profilePicture?.url}
                          alt="Pic"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-gr-primaryR1">
                        <span className="text-[16px] sm:text-[16px] text-gr-primaryR2 font-semibold">
                          {getNameInitials(m?.childMember?.firstname, m?.childMember?.lastname)}
                        </span>
                      </div>
                    )}
                    <div className="hover:underline">{`${m?.childMember?.firstname} ${m?.childMember?.lastname}`}</div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {primaryUserDetails?.club?.name && (
          <div className="">
            <div className="font-inter text-[13px] text-gr-textUserProfile4 font-semibold my-1">
              Club (Admin)
            </div>
            <div
              className="mb-2.5"
              onClick={() => {
                primaryUserDetails
                  ? handleSwitchAccount({
                      profileId: primaryUserDetails?.club?.id,
                      profileType: "Club",
                    })
                  : undefined
                navigate(AUTH_DASHBOARD)
                handleItemClick()
              }}
            >
              <div className="cursor-pointer font-inter text-[12px] mb-1 text-gr-neutralT3 font-medium flex justify-start items-center gap-2">
                <div>
                  <img
                    className="w-[30px] h-[30px] rounded-full"
                    src={primaryUserDetails?.club?.clubLogo?.url ?? ClubLogo}
                    alt="Pic"
                  />
                </div>
                <Tooltip title={primaryUserDetails?.club?.name}>
                  <div className="hover:underline">
                    {primaryUserDetails?.club?.name.slice(0, 20) + "..." || ""}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
        <div className="border-b border-gr-primaryI1"></div>
        <div className="mb-2.5 hover:underline">
          <span className="cursor-pointer font-inter text-[12px] text-gr-textUserProfile4 font-semibold">
            <Link to={AUTH_CHANGE_PASSWORD} onClick={handleItemClick}>
              Change Password
            </Link>
          </span>
        </div>
        <div className="border-b border-gr-primaryI1"></div>
        <div className="hover:underline">
          <span
            onClick={() => {
              handleLogout()
              handleItemClick()
              navigate("/")
            }}
            className="cursor-pointer font-inter text-[12px] text-gr-textUserProfile4 font-semibold py-4 ml-1"
          >
            Logout
          </span>
        </div>
      </div>
    </Popover>
  )
}

export default UserAccountSetting
