import React from "react"
import GlobalRugbyLogo from "../../assets/svg/GlobalRugbyLogo"
import GRTextfield from "../ui/GRTextfield"
import GRButton from "../ui/GRButton"
import * as yup from "yup"
import { useFormik } from "formik"
import { UNAUTH_LOGIN } from "../../routes/RouteConstants"
import { useAuthAPI } from "../../services/useAuthAPI"
import { useNavigate } from "react-router"
import { successToast, errorToast } from "../../utils/ToastUtils"
import { Link } from "react-router-dom"
import { useUsersAPI } from "../../services/useUsersAPI"

const validationSchema = yup.object({
  email: yup.string().email("Invalid email*").required("Email is required*"),
})

function StepEmail() {
  // Hooks
  const navigate = useNavigate()

  // APIs
  const { forgotPassword, isLoading } = useAuthAPI()
  const { isValidUserEmail } = useUsersAPI()

  const handleForgotPassword = async (values: { email: string }) => {
    try {
      const user = await isValidUserEmail(values?.email?.toLowerCase())
      if (!user?.data) {
        errorToast("This email id is not registered with our platform")
        return
      }
      const fr = await forgotPassword({ ...values })
      if (fr) {
        successToast("Password reset link sent to your email")
        navigate(UNAUTH_LOGIN)
      }
    } catch {}
  }

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleForgotPassword,
  })

  return (
    <div className=" flex  justify-center font-inter gap-[10px] bg-white sm:bg-bgRed">
      <div className="w-full sm:w-[530px]  mt-[150px] mb-[200px] bg-white flex flex-col items-center rounded-lg sm:shadow-md sm:px-[60px] px-[30px] py-4  ">
        <GlobalRugbyLogo />
        <div className=" w-full flex flex-col text-center">
          <span className="text-3xl mb-2 font-extrabold ">Forgot Password</span>
          <span>Enter your email to receive a link and reset your password.</span>
        </div>
        <form onSubmit={handleSubmit} className="w-full compeleteProfileForm">
          <div className="mt-5 h-[6rem]">
            <label className="block text-[15px]" htmlFor="email">
              Email
            </label>
            <GRTextfield
              name="email"
              type="email"
              width="100%"
              placeholder="Enter Email id"
              value={values.email}
              onChange={handleChange}
              helperText={touched.email && errors.email}
              error={touched.email && Boolean(errors.email)}
            />
          </div>

          <div className=" flex justify-center mt-7">
            <GRButton
              isSubmitting={isLoading}
              onClick={handleSubmit}
              size="md"
              label="Send"
              width="100%"
              className="!bg-primaryR2"
            />
          </div>
        </form>
        <Link to={UNAUTH_LOGIN} className="mt-4 underline hover:text-gr-primaryR2">
          Back to login
        </Link>
      </div>
    </div>
  )
}

export default StepEmail
