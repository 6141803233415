import React from "react"
import { CircularProgress } from "@mui/material"
import { useContext, useEffect } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import AppRoutes from "./AppRoutes"
import AppContext from "./context/app-context/AppContext"
import { AppContextProvider } from "./context/app-context/AppContextProvider"
import { getAuthToken } from "./utils/AuthUtils"
import { CurrentLocationSettings } from "./settings/currentLocationSettings"
import useChatServices from "./services/useChatServices"
import { useChatAPI } from "./services/useChatAPI"
import { LoaderProvider, useLoader } from "./context/LoaderContext"
import ScreenWidthProvider from "./context/ScreenWidthContext"

function App() {
  return (
    <AppContextProvider>
      <LoaderProvider>
        <ScreenWidthProvider>
          <InitializeApp />
          <ToastContainer />
        </ScreenWidthProvider>
      </LoaderProvider>
    </AppContextProvider>
  )
}

export default App

const InitializeApp = () => {
  // Context
  const {
    setUnreadMessageCount,
    socket,
    primaryUserDetails,
    isAppLoading,
    loadApp,
    loginAsProfileDetails,
    currentLoginProfile,
  } = useContext(AppContext)

  // Constant
  const authToken = getAuthToken()
  const initializeLocation = CurrentLocationSettings()

  // Hooks
  const { unreadCountMessages } = useChatServices()

  // APIs
  const { getUnreadMessageCount } = useChatAPI()

  useEffect(() => {
    initializeLocation.updatePosition()
    loadApp()
  }, [])

  useEffect(() => {
    authToken && fetchUnreadMessageCount()
  }, [authToken, currentLoginProfile?.profileId])

  useEffect(() => {
    if (!socket) return
    unreadCountMessages(fetchUnreadMessageCount)
  }, [socket])

  const fetchUnreadMessageCount = async () => {
    try {
      const unreadCountResponse = await getUnreadMessageCount()
      setUnreadMessageCount(unreadCountResponse?.count || 0)
    } catch {}
  }

  const { isLoading } = useLoader()

  if (isAppLoading || (authToken && !loginAsProfileDetails && !primaryUserDetails)) {
    return (
      <div className="h-screen w-screen">
        <div className="flex justify-center items-center h-full">
          <span className="mr-4"> Loading... </span>
          <CircularProgress size={30} className="!text-gray-700" />
        </div>
      </div>
    )
  }

  return (
    <>
      <AppRoutes />

      {isLoading && (
        <div className="absolute top-0 left-0 h-screen w-screen bg-[#fff9] z-50">
          <div className="flex justify-center items-center h-full">
            <span className="mr-4"> Loading... </span>
            <CircularProgress size={30} className="!text-gray-700" />
          </div>
        </div>
      )}
    </>
  )
}
