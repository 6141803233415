import React, { useContext, useEffect, useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import GRTextfield from "../ui/GRTextfield"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import DialogHeader from "../dialogs/DialogHeader"
import AppContext from "../../context/app-context/AppContext"
import { UserBasicInfoValidation } from "../../validations/Validations"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import { CountryArray, profileTypeOptions } from "../../utils/Constants"
import { useUserHook } from "../../hooks/useUserHook"
import useLocationHook from "../../hooks/useLocationHook"
import { Autocomplete, TextField } from "@mui/material"

interface IBasicInfoFormValues {
  firstname: string
  lastname: string
  middlename: string
  gender: string
  address: string
  city: string
  country: string
  postCode: string
  contactNumber: string
  profileType: string
}

interface IBasicInfoPopupProps {
  isShowBasicInfoDialog: boolean
  onClose: () => void
}

const BasicInfoPopup: React.FC<IBasicInfoPopupProps> = (props) => {
  // Props
  const { isShowBasicInfoDialog, onClose } = props

  // Hooks
  const [isNotState, setIsNotState] = useState<boolean>(false)
  const { updateUserProfile, isUserProfileLoading } = useUserHook()
  const {
    countryOptions,
    stateOptions,
    cityOptions,
    fetchStateByCountry,
    fetchCityByStateAndCountry,
    fetchLocationData,
  } = useLocationHook()

  // Context
  const { loginAsProfileDetails } = useContext(AppContext)

  // UseEffect
  useEffect(() => {
    if (loginAsProfileDetails) {
      fetchLocationData(loginAsProfileDetails?.country, loginAsProfileDetails?.state)
    }
  }, [loginAsProfileDetails])

  const handleSave = async (values: IBasicInfoFormValues) => {
    try {
      const payload = {
        ...values,
      }
      await updateUserProfile(payload)
      onClose()
    } catch {}
  }

  const formik = useFormik({
    initialValues: {
      firstname: loginAsProfileDetails?.firstname || "",
      lastname: loginAsProfileDetails?.lastname || "",
      middlename: loginAsProfileDetails?.middlename || "",
      contactNumber: loginAsProfileDetails?.contactNumber || "",
      postCode: loginAsProfileDetails?.postCode || "",
      gender: loginAsProfileDetails?.gender || "",
      city: loginAsProfileDetails?.city || "",
      state: loginAsProfileDetails?.state || "",
      country: loginAsProfileDetails?.country || "",
      address: loginAsProfileDetails?.address || "",
      address2: loginAsProfileDetails?.address || "",
      profileType: loginAsProfileDetails?.profileType || "",
    },
    onSubmit: handleSave,
    validationSchema: UserBasicInfoValidation,
    enableReinitialize: true,
  })

  const body = (
    <div className="sm:mx-[32px] mx-[13px] mt-[14px] mb-8 ">
      <div className="flex -mt-2">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="font-inter font-medium text-gr-neutralT3 mt-5">Profile type</div>
        <div>
          <GRSelect
            disabled
            id="profileType"
            defaultValue=""
            error={formik.touched.profileType && Boolean(formik.errors.profileType)}
            name="profileType"
            onChange={formik.handleChange}
            value={formik.values.profileType}
            className={formik.touched.profileType && Boolean(formik.errors.profileType) ? "" : ""}
            options={profileTypeOptions}
            errormsg={
              formik.touched.profileType && formik.errors.profileType
                ? formik.errors.profileType
                : ""
            }
          />
        </div>

        <div className="mt-6 flex flex-col sm:flex-row  gap-6 sm:gap-5">
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">
              First name
              <span className="text-red-600">*</span>
            </div>
            <div>
              <GRTextfield
                id="firstname"
                type="text"
                placeholder="Enter first name"
                className="w-[300px] sm:w-[340px]"
                height="40px"
                name="firstname"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                helperText={<span> {formik.touched.firstname && formik.errors.firstname}</span>}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">Middle name</div>
            <div>
              <GRTextfield
                id="middlename"
                type="text"
                placeholder="Enter middle name"
                className="w-[300px] sm:w-[340px]"
                height="40px"
                name="middlename"
                onChange={formik.handleChange}
                value={formik.values.middlename}
                error={formik.touched.middlename && Boolean(formik.errors.middlename)}
                helperText={<span> {formik.touched.middlename && formik.errors.middlename}</span>}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex flex-col sm:flex-row  gap-6 sm:gap-5">
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">
              Last name
              <span className="text-red-600">*</span>
            </div>
            <div>
              <GRTextfield
                id="lastname"
                type="text"
                placeholder="Enter last name"
                className="w-[300px] sm:w-[340px]"
                height="40px"
                name="lastname"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                helperText={<span> {formik.touched.lastname && formik.errors.lastname}</span>}
              />
            </div>
          </div>
          <div className="w-full ">
            <div className="font-inter font-medium text-gr-neutralT3 text-sm">Gender</div>
            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="gender"
                  id="gender"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                >
                  <FormControlLabel
                    name="gender"
                    value="Female"
                    control={<Radio />} // Disable the radio button for Female
                    label="Female"
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio />} // Disable the radio button for Male
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="mt-6 flex flex-col sm:flex-row  gap-6 sm:gap-5">
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">Address line 1</div>
            <div>
              <GRTextfield
                className="w-[300px] sm:w-[340px]"
                name="address"
                id="address"
                placeholder="Enter Address line 1"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">Address line 2</div>
            <div>
              <GRTextfield
                className="w-[300px] sm:w-[340px]"
                name="address2"
                id="address2"
                placeholder="Enter Address line 2"
                value={formik.values.address2}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex flex-col sm:flex-row  gap-6 sm:gap-5">
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">
              Country
              <span className="text-red-600">*</span>
            </div>
            <div>
              <Autocomplete
                id="country"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("country", newValue || "")
                  formik.setFieldValue("state", "")
                  formik.setFieldValue("city", "")
                  if (newValue) {
                    if (CountryArray?.includes(newValue)) {
                      fetchCityByStateAndCountry(newValue, "")
                      setIsNotState(true)
                    } else {
                      fetchStateByCountry(newValue)
                      setIsNotState(false)
                    }
                  }
                }}
                renderOption={(params, option) => <li {...params}>{option}</li>}
                onBlur={formik?.handleBlur}
                value={formik?.values?.country}
                options={countryOptions?.map((c) => c?.name) || []}
                renderInput={(params) => (
                  <TextField
                    error={!!formik.touched.country && Boolean(formik.errors.country)}
                    {...params}
                    placeholder="Country"
                    sx={AutocompleteInputCss}
                  />
                )}
              />
              {formik?.touched?.country && formik?.errors?.country && (
                <div className=" text-[13px] text-red-600 lowercase first-letter:uppercase">
                  {formik?.errors?.country}
                </div>
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">State</div>
            <div>
              <Autocomplete
                id="state"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("state", newValue || "")
                  formik.setFieldValue("city", "")
                  if (newValue) {
                    fetchCityByStateAndCountry(formik.values.country, newValue)
                  }
                }}
                renderOption={(params, option) => <li {...params}>{option}</li>}
                onBlur={formik?.handleBlur}
                value={formik?.values?.state}
                readOnly={CountryArray?.includes(formik.values.country) ? true : false}
                options={stateOptions?.map((s) => s?.name) || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="State" sx={AutocompleteInputCss} />
                )}
              />
              {isNotState && (
                <div className="text-[13px] text-blue-600 lowercase first-letter:uppercase !font-inter">
                  The country has no state so you can select a city
                </div>
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">City</div>
            <div>
              <Autocomplete
                id="city"
                onChange={(_event, newValue) => {
                  formik.handleChange(_event)
                  formik.setFieldValue("city", newValue || "")
                }}
                renderOption={(params, option) => <li {...params}>{option}</li>}
                onBlur={formik?.handleBlur}
                value={formik?.values?.city}
                options={cityOptions?.map((c: { name: any }) => c?.name) || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="City" sx={AutocompleteInputCss} />
                )}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex flex-col sm:flex-row  gap-6 sm:gap-5">
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">
              Postcode<span className="text-red-600">*</span>
            </div>
            <div>
              <GRTextfield
                id="postCode"
                type="text"
                className="w-[300px] sm:w-[340px]"
                placeholder="Enter Postcode"
                height="40px"
                name="postCode"
                onChange={formik.handleChange}
                value={formik.values.postCode}
                error={formik.touched.postCode && Boolean(formik.errors.postCode)}
                helperText={<span> {formik.touched.postCode && formik.errors.postCode}</span>}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="font-inter font-medium text-gr-neutralT3">Phone number</div>
            <div>
              <GRTextfield
                id="contactNumber"
                type="text"
                className="w-[300px] sm:w-[340px]"
                placeholder="Enter Phone number"
                height="40px"
                name="contactNumber"
                onChange={formik.handleChange}
                value={formik.values.contactNumber}
                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                helperText={
                  <span> {formik.touched.contactNumber && formik.errors.contactNumber}</span>
                }
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-6">
          <GRButton
            onClick={onClose}
            label="Cancel"
            type="button"
            className="sm:!px-[75px] !px-[49px]"
            width={100}
          />
          <GRButton
            isSubmitting={isUserProfileLoading}
            label="Save"
            className="sm:!px-[75px] !px-[49px]"
            width={100}
          />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowBasicInfoDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader onClose={onClose} title="Basic info" />}
      />
    </>
  )
}

export default BasicInfoPopup
