import React, { useContext, useState } from "react"
import GRButton from "../../../../components/ui/GRButton"
import { GRDialog } from "../../../../components/ui/GRDialog"
import PhotoIcon from "../../../../assets/svg/PhotoIcon"
import VideoIcon from "../../../../assets/svg/VideoIcon"
import BinIcon from "../../../../assets/svg/BinIcon.svg"
import { RichTextEditor } from "../../../../components/richTextEditor/RichTextEditor"
import { Form, Formik } from "formik"
import { AddPostValidation } from "../../../../validations/Validations"
import CrossIcon from "../../../../assets/svg/CrossIcon"
import { IPostSummary, IUpdatePost } from "../../../../modals/Post"
import { useConfigAPI } from "../../../../services/useConfigAPI"
import { usePostAPI } from "../../../../services/usePostAPI"
import AppContext from "../../../../context/app-context/AppContext"
import { useUserHook } from "../../../../hooks/useUserHook"
import { successToast } from "../../../../utils/ToastUtils"
import { urlRegex, imageExtensions } from "../../../../utils/Constants"
import { debounce } from "lodash"
import GRProgressBar from "../../../ui/GRProgressBar"

interface IAddPostFormValues {
  postContent: string
  postType: string
}

interface IEditPostDialogProps {
  isOpen: boolean
  onClose: () => void
  postData?: IPostSummary | undefined
  onFetchPosts: () => void
  setUpdatePostContent: React.Dispatch<React.SetStateAction<IUpdatePost | undefined>>
}

const EditPost = (props: IEditPostDialogProps) => {
  const { isOpen, onClose, postData, setUpdatePostContent } = props
  // API
  const { uploadFiles, deleteFiles } = useConfigAPI()
  const { isLoading, updatePost, previewLink } = usePostAPI()
  // Context
  const { loginAsProfileDetails } = useContext(AppContext)
  // Hook
  const { isLoginAsClub } = useUserHook()
  const [url, setUrl] = useState<string>("")

  const [thumbnail, setThumbnail] = useState<string | null>(
    postData?.media?.url
      ? postData?.media?.url
      : postData?.preViewUrl
      ? postData?.preViewUrl
      : null,
  )
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false)
  const [isImage, setIsImage] = useState<boolean>(postData?.postType === "Image" ? true : false)
  const [key, setKey] = useState<number>(0) // Key to remount the input element
  const [uploadFileId, setUploadFileId] = useState<number | null>(postData?.media?.id || null)
  const [isSupportedImageType, setIsSupportedImageType] = useState(false)
  const [progress, setProgress] = useState(0)
  const [fileSizeError, setFileSizeError] = useState<string | null>(null)

  const handlePostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0]

        setIsSupportedImageType(false)
        const formData = new FormData()
        formData.append("files", file, file?.name)
        const fileSizeKB = file.size / 1024 // Convert to KB

        if (/image/.test(file.type)) {
          if (file && !imageExtensions?.includes(file?.type)) {
            setIsSupportedImageType(true)
            return
          }
          if (fileSizeKB < 10 || fileSizeKB > 5 * 1024) {
            setIsFileLoading(false)
            setFileSizeError("Photo size must be between 10 KB and 5 MB")
            return
          }
        } else {
          if (fileSizeKB < 75 || fileSizeKB > 200 * 1024) {
            setIsFileLoading(false)
            setFileSizeError("Video size must be between 75 KB and 200 MB")
            return
          }
        }
        setIsFileLoading(true)
        const uploadResponse = await uploadFiles(formData, setProgress)
        uploadResponse?.[0]?.mime?.split("/")[0] === "image" ? setIsImage(true) : setIsImage(false)
        setIsFileLoading(false)
        if (uploadResponse?.[0]) {
          setUploadFileId(uploadResponse?.[0]?.id)
          setThumbnail(uploadResponse?.[0]?.url)
          event.target.value = ""
        }
      } else {
        setThumbnail(null)
        event.target.value = ""
      }
      setKey((prevKey) => prevKey + 1)
    } catch {}
  }

  const deletePost = async (id: number | null | undefined) => {
    if (!id) return

    const uploadResponse = await deleteFiles(id)
    if (uploadResponse) {
      setThumbnail(null)
      setUploadFileId(null)
    }
  }
  const removePreViewLink = async () => {
    try {
      setThumbnail(null)
    } catch (error) {}
  }
  const convertUrlsToLinks = (content: string) => {
    if (urlRegex.test(content)) {
      const url = content.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")
      if (thumbnail) return
      getPreviewUrl(url)
    }
  }
  const getPreviewUrl = async (urlStr: string) => {
    try {
      if (!urlStr) return
      const values = urlStr?.split(" ")
      const value = values?.find(
        (v) => v?.includes("http://") || v?.includes("https://") || v?.includes("www."),
      )
      !url && value && setUrl(value)
      const validUrl =
        value?.includes("https://") || value?.includes("http://") ? value : `https://${value}`
      const preview = await previewLink(validUrl)
      if (preview?.data?.image) {
        setIsImage(true)
        setThumbnail(preview?.data?.image)
      }
    } catch (error) {}
  }
  const debouncePriView = debounce(convertUrlsToLinks, 1000)

  const editPost = async (values: IAddPostFormValues, formikBag: any) => {
    try {
      if (/^\s*<p>\s*<\/p>\s*$/.test(values?.postContent)) {
        formikBag.setFieldError("postContent", "Empty content not allowed")
        return
      }
      const postPayload: any = {
        data: {
          id: postData?.id,
          description: values?.postContent || "",
          media: uploadFileId ? uploadFileId : null,
          postType: values?.postType,
          club: isLoginAsClub ? loginAsProfileDetails?.club?.id : null,
          preViewUrl: !uploadFileId ? thumbnail : null,
        },
      }
      const addNewPost = await updatePost(postPayload)
      if (addNewPost?.data) {
        onClose()
        successToast("Post updated successfully")
        setThumbnail(
          addNewPost?.data?.media?.url
            ? addNewPost?.data?.media?.url
            : addNewPost?.data?.preViewUrl,
        )
        setUpdatePostContent(addNewPost?.data)
      }
    } catch (error) {}
  }

  const AddPostFormInitialValues = {
    postContent: postData?.description ? postData?.description : "",
    postType: postData?.postType ? postData?.postType : "",
  }

  return (
    <div>
      <GRDialog
        open={isOpen}
        onClose={() => onClose()}
        dialogtitle={
          <div className="ml-[32px] mr-[25px] flex justify-between items-center border-b border-b-gr-primaryN8">
            <p className="font-inter sm:text-2xl text-xl font-semibold  leading-6 text-black mt-5 pb-5">
              Edit Post
            </p>
            <div
              className="text-2xl leading-10 font-bold font-inter text-gr-primaryR3 cursor-pointer"
              onClick={() => onClose()}
            >
              <CrossIcon />
            </div>
          </div>
        }
        dialogbody={
          <Formik
            initialValues={AddPostFormInitialValues}
            validationSchema={AddPostValidation}
            onSubmit={editPost}
            enableReinitialize
          >
            {({ values, errors, touched, setFieldValue, setFieldTouched, handleSubmit }) => (
              <Form className="">
                <div className="py-3 bg-white mb-2 px-7 rounded-2xl">
                  <div>
                    <div>
                      <p className="text-lg font-semibold text-gr-primaryN1 leading-6 mb-6 font-inter">
                        Select Post Type
                      </p>
                    </div>
                    <div className="mb-4">
                      <div className="flex gap-[40px]">
                        <label className="">
                          <div
                            className={`mb-2  px-2 py-2 hover:bg-gr-primaryR1 ${
                              values.postType === "Image" ? "bg-gr-primaryR1" : ""
                            }`}
                          >
                            <PhotoIcon />
                          </div>
                          <div
                            className={`${
                              values.postType === "Image"
                                ? "font-inter text-sm text-gr-primaryN2 ml-1 font-extrabold"
                                : "font-inter font-bold text-sm text-gr-primaryN2 ml-1"
                            }`}
                          >
                            <p className="">Photo</p>
                          </div>
                          <input
                            key={key}
                            type="file"
                            id="photo"
                            name="photo"
                            className="hidden"
                            accept="image/*"
                            onChange={(event) => {
                              handlePostChange(event), setFieldValue("postType", "Image")
                            }}
                          />
                        </label>
                        <label>
                          <div
                            className={`mb-2  px-2 py-2 hover:bg-gr-primaryR1 ${
                              values.postType === "Video" ? "bg-gr-primaryR1" : ""
                            }`}
                          >
                            <VideoIcon />
                          </div>
                          <div
                            className={`${
                              values.postType === "Video"
                                ? "font-inter text-sm text-gr-primaryN2 ml-2 mt-1 font-extrabold"
                                : "font-inter mt-1 font-bold text-sm text-gr-primaryN2 ml-2"
                            }`}
                          >
                            Video
                          </div>
                          <input
                            key={key}
                            id="video"
                            name="video"
                            type="file"
                            className="hidden"
                            accept="video/*"
                            onChange={(event) => {
                              handlePostChange(event), setFieldValue("postType", "Video")
                            }}
                          />
                        </label>
                      </div>
                      <div className="text-xs text-gray-700 font-semibold">
                        Image size must be between 10 KB and 5 MB and video size must be between 75
                        KB and 200 MB
                      </div>
                      <div className="font-poppins text-errorRed text-xs">
                        {fileSizeError ? <span>{fileSizeError}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {isFileLoading ? (
                      <GRProgressBar progress={progress}></GRProgressBar>
                    ) : thumbnail ? (
                      <div>
                        {!isImage ? (
                          <video
                            controls
                            className="bg-cover md:h-[390px] sm:h-[300px] h-[150px] w-full"
                            src={thumbnail}
                          />
                        ) : (
                          <img src={thumbnail} alt="bannerImg" />
                        )}
                      </div>
                    ) : (
                      isSupportedImageType && (
                        <div>
                          <span className="text-[13px] text-red-500 first-letter:uppercase !font-inter">
                            Supported files include: images (jpg, jpeg, gif*, png, svg, webp)
                          </span>
                        </div>
                      )
                    )}
                    <div className="flex justify-end items-center gap-[56px]  mt-2">
                      {/* Conditionally render the delete button */}
                      {thumbnail && (
                        <div className="flex items-center gap-[12px] ">
                          <div>
                            <img
                              onClick={() =>
                                postData?.media?.id
                                  ? deletePost(postData?.media?.id)
                                  : removePreViewLink()
                              }
                              src={BinIcon}
                              alt="delete icon "
                            />{" "}
                          </div>
                          <div className="mt-[4px]  text-red-darkRed text-[12px] font-inter ">
                            Delete
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-[24px]">
                    <div>
                      <p className="font-microsoftTai text-black leading-6 mb-2.5">Edit Post</p>
                    </div>
                    <div>
                      <RichTextEditor
                        value={values?.postContent}
                        onChange={(v) => {
                          setFieldTouched("postContent", true)
                          if (v === "<p><br></p>") {
                            setFieldValue("postContent", "")
                            return
                          }
                          setFieldValue("postContent", v?.trim())
                          debouncePriView(v)
                        }}
                        placeholder="I am your text editor"
                      />
                    </div>

                    <div className="font-poppins text-errorRed text-xs h-4 mt-12">
                      {touched?.postContent && errors?.postContent ? errors?.postContent : ""}
                    </div>
                  </div>
                  <div className="flex justify-between items-center ">
                    <GRButton
                      onClick={onClose}
                      label="Cancel"
                      type="button"
                      className="!px-[45px]"
                    />
                    <GRButton
                      isSubmitting={isLoading}
                      onClick={handleSubmit}
                      label="Save"
                      className="!px-[45px]"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        }
        fullWidth
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
      />
    </div>
  )
}

export default EditPost
