import * as React from "react"

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={13} viewBox="0 0 14 13" fill="none">
      <path
        d="M6.756 12.09a.833.833 0 01-1.179 0l-5-5a.833.833 0 010-1.18l5-5a.833.833 0 111.179 1.18L3.178 5.666h9.655a.833.833 0 010 1.667H3.178l3.578 3.577a.833.833 0 010 1.178z"
        fill="#9F0002"
      />
    </svg>
  )
}

export default SvgComponent
