import React, { useContext, useEffect, useState } from "react"
import { GRDialog } from "../ui/GRDialog"
import { useFormik } from "formik"
import GRButton from "../ui/GRButton"
import { TextField } from "@mui/material"
import DialogHeader from "../dialogs/DialogHeader"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import GRTextfield from "../ui/GRTextfield"
import VideoIcon from "../../assets/svg/VideoIcon"
import { useConfigAPI } from "../../services/useConfigAPI"
import { IUpdateVideoHighlightPayload, useLoginUserAPI } from "../../services/useLoginUserAPI"
import * as yup from "yup"
import AppContext from "../../context/app-context/AppContext"
import Bin from "../../assets/svg/BinIcon.svg"
import EditIcon from "../../assets/svg/edit-Icons.svg"
import { successToast } from "../../utils/ToastUtils"
import { useUserHook } from "../../hooks/useUserHook"
import HighlightCrousalInPopup from "./HighlightCrousalInPopup"
import GRProgressBar from "../ui/GRProgressBar"

export enum VideoType {
  upload = "Upload video",
  url = "Video URL",
  embed = "Video embed code",
}

interface IUpdateVideoFormValues {
  id: string
  type: string //"Upload video" | "Video URL" | "Video embed code"
  description: string
  video: string
  videoURL: string
  videoEmbed: string
}

const initialValues = {
  id: "",
  description: "",
  type: "Upload video",
  video: "",
  videoURL: "",
  videoEmbed: "",
}

interface IHighlightProps {
  isShowDialog: boolean
  onClose: () => void
}

const EditHighlightPopup: React.FC<IHighlightProps> = (props) => {
  // props
  const { isShowDialog, onClose } = props

  //Context
  const { loginAsProfileDetails } = useContext(AppContext)

  //State
  const { uploadFiles } = useConfigAPI()
  const [thumbnail, setThumbnail] = useState<string | null>(
    loginAsProfileDetails?.videoHighlights[0]?.video?.url || null,
  )
  const [key, setKey] = useState<number>(0) // Key to remount the input element
  const [uploadFileId, setUploadFileId] = useState<number | null>(null)
  const [isUplaoding, setIsUploading] = useState<boolean>(false)
  const [uploadedFileIds, setUploadFileIds] = useState<number[]>(
    loginAsProfileDetails?.videoHighlights?.map((vh) => vh.id) ?? [],
  )
  const [removeVideoHighlightIds, setRemoveVideoHighlightIds] = useState<number[]>([])
  // Hook
  const { updateVideoHighlight, deleteVideoHighlightById } = useLoginUserAPI()
  const { fetchLoginAsUserProfile } = useUserHook()
  const [progress, setProgress] = useState(0)

  const handlePostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const file = event?.target?.files[0]
        const formData = new FormData()
        formData.append("files", file, file?.name)
        setIsUploading(true)
        const uploadResponse = await uploadFiles(formData, setProgress)
        setIsUploading(false)
        if (uploadResponse?.[0]) {
          setUploadFileId(uploadResponse?.[0]?.id)
          setThumbnail(uploadResponse?.[0]?.url)
          event.target.value = ""
        }
      } else {
        setThumbnail(null)
        event.target.value = ""
      }
      setKey((prevKey) => prevKey + 1)
    } catch {}
  }

  const handleSubmit = async (values: IUpdateVideoFormValues) => {
    try {
      if (values?.type === VideoType?.upload) {
        const videoPayload: IUpdateVideoHighlightPayload = {
          data: {
            id: values?.id,
            description: values?.description?.replaceAll(/<\/?[^>]+(>|$)/g, ""),
            video: uploadFileId || Number(values?.video),
            type: values?.type,
          },
        }
        const res = await updateVideoHighlight(videoPayload)
        deleteVideoHighlight()
        if (res?.data) {
          setUploadFileIds([...uploadedFileIds, res?.data?.id])
          setThumbnail(null)
          successToast("Updated !!")
          fetchLoginAsUserProfile()
          onClose()
        }
      } else if (values?.type === VideoType?.url) {
        const videoPayload: IUpdateVideoHighlightPayload = {
          data: {
            id: values?.id,
            description: values?.description?.replaceAll(/<\/?[^>]+(>|$)/g, ""),
            videoURL: values?.videoURL,
            type: values?.type,
          },
        }
        const res = await updateVideoHighlight(videoPayload)
        deleteVideoHighlight()
        if (res?.data) {
          setUploadFileIds([...uploadedFileIds, res?.data?.id])
          setThumbnail(null)
          successToast("Updated !!")
          fetchLoginAsUserProfile()
          onClose()
        }
      } else if (values?.type === VideoType?.embed) {
        const videoPayload: IUpdateVideoHighlightPayload = {
          data: {
            id: values?.id,
            description: values?.description?.replaceAll(/<\/?[^>]+(>|$)/g, ""),
            videoEmbed: values?.videoEmbed,
            type: values?.type,
          },
        }
        const addNewVideo = await updateVideoHighlight(videoPayload)
        deleteVideoHighlight()
        if (addNewVideo?.data) {
          setUploadFileIds([...uploadedFileIds, addNewVideo?.data?.id])
          setThumbnail(null)
          successToast("Updated !!")
          fetchLoginAsUserProfile()
          onClose()
        }
      }
    } catch (error) {}
  }

  const deleteVideoHighlight = async () => {
    try {
      if (removeVideoHighlightIds.length === 0) return

      const removeVH = await Promise.all(
        removeVideoHighlightIds?.map(async (rvh) => {
          const deleteVH = await deleteVideoHighlightById(rvh)
          return deleteVH
        }),
      )
      return removeVH
    } catch (error) {}
  }

  useEffect(() => {
    const fetchData = async () => {
      await deleteVideoHighlight()
      await fetchLoginAsUserProfile()
    }
    fetchData()
  }, [removeVideoHighlightIds])
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      id: String(loginAsProfileDetails?.videoHighlights[0]?.id || ""),
      video: String(loginAsProfileDetails?.videoHighlights[0]?.video?.id),
      videoURL: loginAsProfileDetails?.videoHighlights[0]?.videoURL ?? "",
      videoEmbed: loginAsProfileDetails?.videoHighlights[0]?.videoEmbed ?? "",
      description: loginAsProfileDetails?.videoHighlights[0]?.description ?? "",
      type: loginAsProfileDetails?.videoHighlights[0]?.type ?? "",
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object({
      description: yup.string()?.trim().required("Description is required"),
      type: yup.string(),
      video: yup.string().when("type", {
        is: "Upload video",
        then: yup.string().required("Please upload video"),
        otherwise: yup.string().optional(),
      }),
      videoURL: yup.string().when("type", {
        is: "Video URL",
        then: yup
          .string()
          .matches(
            /(?:https?:\/\/)?(?:www\.)?(youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|watch\?v%3D|v=|embed%2F|e%2F|vi%2F|e%2F|user%2F[^#]#user%2F[^#]#p%2F[^#]*){1}([a-zA-Z0-9_-]+)/,
            "Please provide a valid youtube link",
          )
          .required("Please provide video URL"),
        otherwise: yup.string().optional(),
      }),
      videoEmbed: yup.string().when("type", {
        is: "Video embed code",
        then: yup.string().required("Please provide video embed code"),
        otherwise: yup.string().optional(),
      }),
    }),
  })

  const body = (
    <div className="sm:mx-[32px] mx-[13px] mt-[25px] mb-8 compeleteProfileForm">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        {/* upload type */}
        <div className="font-semibold font-inter text-[18px]">Upload type</div>
        <RadioGroup
          id="type"
          name="type"
          row
          className="gap-3"
          value={formik.values.type}
          onChange={(event) => {
            formik.handleChange(event)
          }}
        >
          <FormControlLabel value="Upload video" control={<Radio />} label="Upload video" />
          <FormControlLabel value="Video URL" control={<Radio />} label="Youtube Video URL" />
        </RadioGroup>
        {formik.values.type === "Upload video" ? (
          <>
            <div title="Upload Video" className="">
              <label className="">
                <div className="mb-2 w-max cursor-pointer">
                  <VideoIcon className="cursor-pointer" />
                </div>
                <p className="font-microsoftTai text-sm text-gr-primaryN2 cursor-pointer">Video</p>
                <input
                  key={key}
                  id="video"
                  name="video"
                  type="file"
                  className="hidden"
                  accept="video/*"
                  onChange={async (event) => {
                    await handlePostChange(event)
                    formik.setFieldValue("video", setUploadFileId)
                    console.log("setUploadFileId", uploadFileId)
                  }}
                />
              </label>
              <div className="text-xs text-gray-700 font-semibold">
                Video size must be between 75 KB and 200 MB
              </div>
              <div className="text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                {(formik?.touched?.video && formik?.errors?.video) || ""}
              </div>
            </div>
            {isUplaoding ? (
              // <div className="h-[350px] flex justify-center items-center">Loading...</div>
              <GRProgressBar progress={progress}></GRProgressBar>
            ) : (
              thumbnail && (
                <div className=" flex justify-center items-center w-full  h-[390px] my-2 mb-4">
                  <video controls className="bg-cover h-[390px]" src={thumbnail} />
                </div>
              )
            )}
          </>
        ) : (
          //formik.values.type === "Video URL" ?
          <GRTextfield
            placeholder="Video URL"
            id="videoURL"
            name="videoURL"
            value={formik.values.videoURL}
            onChange={formik.handleChange}
            error={formik.touched.videoURL && Boolean(formik.errors.videoURL)}
            helperText={formik.touched.videoURL && formik.errors.videoURL}
          />
        )}
        <div className="min-h-[105px]">
          <TextField
            fullWidth
            multiline={true}
            minRows={2}
            id="description"
            name="description"
            value={formik.values.description}
            error={formik.touched.description && Boolean(formik.errors.description)}
            onChange={formik.handleChange}
            helperText={formik.touched.description && formik.errors.description}
            placeholder="Description"
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "10px",
              },
            }}
          />
        </div>
        <HighlightCrousalInPopup>
          {loginAsProfileDetails?.videoHighlights
            ?.filter((vh) => !removeVideoHighlightIds?.includes(vh?.id))
            ?.map((vh, idx) => (
              <div
                key={idx}
                onClick={() => {
                  formik.setFieldValue("description", vh?.description)
                  formik.setFieldValue("type", vh?.type)
                  formik.setFieldValue("id", vh?.id)
                  if (vh?.type === VideoType.url) {
                    formik.setFieldValue("videoURL", vh?.videoURL)
                  } else if (vh?.type === VideoType.embed) {
                    formik.setFieldValue("videoEmbed", vh?.videoEmbed)
                  } else {
                    formik.setFieldValue("video", vh?.video?.id)
                    setThumbnail(vh?.video?.url)
                  }
                }}
              >
                {vh.type === VideoType.upload ? (
                  <div className="mr-4 rounded-sm w-48 h-32 relative">
                    <video
                      autoPlay={false}
                      className=" rounded-sm cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300"
                      src={vh?.video?.url}
                    />
                    <img
                      className="mr-4 mb-[40%] w-6 h-6 absolute bottom-3 right-0 bg-bgRed rounded-full p-1 cursor-pointer"
                      src={EditIcon}
                      alt="deleteIcon"
                    />
                  </div>
                ) : (
                  <div className="w-48 h-32 relative  flex items-center justify-center transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300">
                    <iframe
                      className=" relative w-48 iframecss "
                      src={`https://www.youtube.com/embed/${new URL(vh.videoURL).searchParams.get(
                        "v",
                      )}`}
                    ></iframe>
                    <img
                      className="mr-4 mb-[40%] w-6 h-6 absolute bottom-3 right-0 bg-bgRed rounded-full p-1 cursor-pointer"
                      src={EditIcon}
                      alt="deleteIcon"
                    />
                    <div className=" w-48 h-32 absolute top-0 left-0 cursor-pointer z-20"></div>
                  </div>
                )}
                <img
                  className="mr-[60px] w-6 h-6 absolute bottom-3 right-0 bg-bgRed rounded-full p-1 cursor-pointer"
                  onClick={async (e) => {
                    e.stopPropagation()
                    try {
                      await setRemoveVideoHighlightIds([...removeVideoHighlightIds, vh?.id])
                    } catch {}
                  }}
                  src={Bin}
                  alt="deleteIcon"
                />
              </div>
            ))}
        </HighlightCrousalInPopup>
        <div className="flex justify-between items-center mt-5 ">
          <GRButton
            label="Cancel"
            type="button"
            onClick={onClose}
            className="sm:!px-[35px] font-inter"
          />
          <GRButton label="Update" className="sm:!px-[35px] font-inter" />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <GRDialog
        open={isShowDialog}
        onClose={onClose}
        classes={{ paper: "!max-w-[780px] !w-full !rounded-[10px]" }}
        dialogbody={body}
        dialogtitle={<DialogHeader title="Edit videos" onClose={onClose} />}
      />
    </>
  )
}

export default EditHighlightPopup
