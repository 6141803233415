import * as React from "react"
import { SVGProps } from "react"

const GotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.667 2.167a.833.833 0 0 1 0-1.667h5a.833.833 0 0 1 .833.833v5a.833.833 0 1 1-1.667 0V3.345L6.09 11.09a.833.833 0 0 1-1.178-1.178l7.744-7.744H9.667ZM.5 3.833c0-.92.746-1.666 1.667-1.666h4.166a.833.833 0 1 1 0 1.666H2.167v10h10V9.667a.833.833 0 1 1 1.666 0v4.166c0 .92-.746 1.667-1.666 1.667h-10C1.247 15.5.5 14.754.5 13.833v-10Z"
      fill="#7B8593"
    />
  </svg>
)

export default GotoIcon
