import React, { useEffect } from "react"
import { IClubDetails } from "../../../modals/Club"
import MessageIcon from "../../../assets/svg/MessageIcon"
import { Link } from "react-router-dom"
import { AUTH_MESSAGE, AUTH_USER_PROFILE } from "../../../routes/RouteConstants"
import { getNameInitials } from "../../../utils/CommonUtils"

export interface IMembers {
  clubData?: IClubDetails
  isAdmin: boolean | undefined
  setmemberNo: (member: IClubDetails | undefined) => void
}

function Members(props: IMembers) {
  // Props
  const { clubData, setmemberNo } = props
  useEffect(() => {
    // Update the member count in the parent component
    return setmemberNo(clubData)
  }, [clubData, setmemberNo])
  return (
    <div className="flex flex-col  bg-white rounded-2xl min-h-[332px]  px-6  py-4  ">
      <div className="flex justify-between items-center mt-2">
        <div className="font-inter sm:text-2xl text-xl font-semibold">Members</div>
      </div>
      <div className="border border-gray-200 mt-4 mb-5"></div>
      {clubData?.attributes?.members?.map((m, indx) => (
        <div
          key={indx}
          className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5`}
        >
          <div className="flex  gap-4 items-center">
            <Link to={`${AUTH_USER_PROFILE}/${m?.id}`}>
              {m?.profilePicture?.formats?.thumbnail?.url ? (
                <div>
                  <img
                    src={m?.profilePicture?.formats?.thumbnail?.url}
                    alt="profile-image"
                    className="rounded-full min-w-[44px] w-[44px] h-[44px]"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                  <span className="text-[16px] sm:text-[18px] text-gr-primaryR2 font-semibold">
                    {getNameInitials(m?.firstname, m?.lastname)}
                  </span>
                </div>
              )}
            </Link>
            <div>
              <p className="font-inter text-sm leading-[17px] text-gr-primaryN1 font-semibold mb-[3px] hover:underline">
                <Link to={`${AUTH_USER_PROFILE}/${m?.id}`}>
                  {m?.firstname} {m?.lastname}
                </Link>
              </p>
              <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                {m?.profileType}
              </p>
              <p className="font-inter text-xs leading-[15px] text-gr-neutralT3 font-normal">
                Position : {m?.position?.title}
              </p>
            </div>
          </div>
          <div className="cursor-pointer">
            <Link to={`${AUTH_MESSAGE}?chatwith=${m?.id}`}>
              <MessageIcon />
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}
export default Members
