import React, { useContext, useState } from "react"
import GRTextfield from "../ui/GRTextfield"
import { useFormik } from "formik"
import { Box, Popover, Typography } from "@mui/material"
import GRButton from "../ui/GRButton"
import RightArrow from "../../assets/svg/RightArrow"
import { hearAboutUsOptions, seasonIWishToPlayOptions } from "../../utils/Constants"
import RecordHistoryDialog from "../dialogs/RecordHistoryDialog"
import {
  professionalInfoOnboardingValidationSchema,
  professionalInfoOnboardingParentValidationSchema,
  CoachProfessionalInfoOnboardingValidationSchema,
} from "../../validations/Validations"
import AppContext from "../../context/app-context/AppContext"
import Nationality from "../../utils/Nationality.json"
import LeftArrow from "../../assets/svg/LeftArrowRed"
import { useUserHook } from "../../hooks/useUserHook"
import { convertIntoDropDownOptions } from "../../utils/CommonUtils"
import Qualification from "../playerProfile/Qualification"
import useLocationHook from "../../hooks/useLocationHook"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import GRSelect, { AutocompleteInputCss } from "../ui/GRSelect"
import CrossIcon from "../../assets/svg/CrossIcon"
import ExclamtionIcon from "../../assets/svg/ExclamationIcon"

export interface IProfessionalInfoFormValues {
  intlEligibility: string[]
  nationality: string[]
  level: string
  speciality: string
  highestLevelPlayed: string
  hearAboutUs: string
  season: string | null
  // referralUserId: string
  disciplines: string[]
  highestQualification: string
  region: string[]
  qualification: number[]
}

type IProfessionalInfoProps = {
  onSubmit: (values: IProfessionalInfoFormValues) => void
  isSubmitting: boolean
  goToPreviousStep: any
}

const ProfessionalInfo: React.FC<IProfessionalInfoProps> = ({
  onSubmit,
  isSubmitting,
  goToPreviousStep,
}) => {
  // Context
  const { loginAsProfileDetails, dropDownOptions } = useContext(AppContext)
  //Hook
  const { isCoach, isParent } = useUserHook()
  const { countryOptions } = useLocationHook()
  const validationSchema = isParent
    ? professionalInfoOnboardingParentValidationSchema // Empty schema means no validation, all fields optional
    : isCoach
    ? CoachProfessionalInfoOnboardingValidationSchema
    : professionalInfoOnboardingValidationSchema

  const handleGoBack = () => {
    goToPreviousStep() // Call the function passed from the parent component to go back to the previous step
  }
  const alreadyUploadedIds = loginAsProfileDetails?.qualification?.map((q) => q.id) ?? []
  // State
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const [uploadedFileIds, setUploadedFileIds] = useState<number[]>([...alreadyUploadedIds])

  const formik = useFormik({
    initialValues: {
      intlEligibility: loginAsProfileDetails?.internationalEligibilities
        ? loginAsProfileDetails?.internationalEligibilities?.split(", ")?.map((i) => i)
        : [],
      nationality: loginAsProfileDetails?.nationality
        ? loginAsProfileDetails?.nationality?.split(", ")?.map((n) => n)
        : [],
      level: loginAsProfileDetails?.level?.id ? String(loginAsProfileDetails?.level?.id) : "",
      speciality: loginAsProfileDetails?.strengths || "",
      highestLevelPlayed: loginAsProfileDetails?.highestLevelPlayed?.id
        ? String(loginAsProfileDetails?.highestLevelPlayed?.id)
        : "",
      hearAboutUs: loginAsProfileDetails?.hearingSource || "",
      season: loginAsProfileDetails?.seasonWishToPlay
        ? String(loginAsProfileDetails?.seasonWishToPlay)
        : "",
      // referralUserId: loginAsProfileDetails?.referralUserId || "",
      disciplines: loginAsProfileDetails?.disciplines
        ? loginAsProfileDetails?.disciplines?.map((i) => i.id.toString())
        : [],
      highestQualification: loginAsProfileDetails?.highestQualification?.id
        ? String(loginAsProfileDetails?.highestQualification?.id)
        : "",
      region: loginAsProfileDetails?.region?.split(",") || [],
      qualification: uploadedFileIds,
    },
    onSubmit: (values: IProfessionalInfoFormValues) => onSubmit(values),
    validationSchema,
    // enableReinitialize: true,
  })
  const [messagePopoverAnchorEl, setMessagePopoverAnchorEl] = useState<null | HTMLElement>(null)

  // Handler function to toggle the message
  const toggleMessagePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessagePopoverAnchorEl(messagePopoverAnchorEl ? null : event.currentTarget)
  }
  return (
    <div className="flex flex-col gap-4  border-t border-gr-primaryN8 bg-white  mt-[-20px ]   font-inter">
      {/* heading- Professional Info */}

      <div className="flex justify-between items-center font-bold">
        <div className="text-lg text-gr-primaryN1 mt-2 font-inter">Professional info</div>
        <div>
          2<span className="text-gray-500">/3</span>
        </div>
      </div>
      <div className="flex -mt-3">
        <span className="text-red-600 text-lg -mt-2">*</span>
        <span className="text-[12px] text-gray-500">Indicates a mandatory field</span>
      </div>
      {isParent ? (
        <div className="text-[12px] mt-[-10px] font-bold text-gray-400">
          Don't worry if you don't have playing experience, not all these fields are mandatory. Do
          fill the option "where did you hear about us" as it is mandatory.
        </div>
      ) : null}
      {/* form */}
      <div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col compeleteProfileForm">
          <div className="flex flex-col ">
            <Qualification
              addUploadedFileId={(id: number) => {
                setUploadedFileIds([...uploadedFileIds, id])
              }}
            />

            {/* International Eligibility and Nationality */}
            <div className="flex flex-col gap-x-8 items-center sm:flex-row sm:items-start sm:justify-between">
              {/* International Eligibility */}

              <div className="w-full mb-4">
                <div className="text-sm font-[400] flex mb-1">
                  International eligibility
                  {!isParent && !isCoach && <span className="text-red-600">*</span>}
                  <div
                    style={{ marginTop: "6px", marginLeft: "5px" }}
                    onClick={toggleMessagePopover}
                  >
                    <ExclamtionIcon />
                  </div>
                  <Popover
                    open={Boolean(messagePopoverAnchorEl)}
                    anchorEl={messagePopoverAnchorEl}
                    onClose={() => setMessagePopoverAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      <div className="flex gap-2">
                        <span>
                          <a
                            className="font-bold text-red-600"
                            href="https://www.world.rugby/organisation/governance/regulations/reg-8"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link to{" "}
                          </a>
                          World Rugby International Eligibility Rules.
                        </span>
                        <div className="mt-1">
                          <CrossIcon onClick={() => setMessagePopoverAnchorEl(null)} />
                        </div>
                      </div>
                    </Typography>
                  </Popover>
                </div>
                <div className="">
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    renderTags={(value) => {
                      const numTags = value.length
                      return (
                        <span className="max-w-[60%] truncate text-sm mr-2 text-sm">
                          {numTags > 1 && ` ${numTags - 1} more +`}
                          {value
                            .slice(0, 1)
                            .map((option) => option)
                            .join(", ")}
                        </span>
                      )
                    }}
                    id="intlEligibility"
                    onChange={(_event, newValue) => {
                      formik.handleChange(_event)
                      formik.setFieldValue("intlEligibility", newValue || "")
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} style={{ fontSize: "14px" }}>
                        <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                        {option}
                      </li>
                    )}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.intlEligibility}
                    options={countryOptions?.map((c) => c?.name) || []}
                    renderInput={(params) => (
                      <TextField
                        error={
                          !!(formik?.touched?.intlEligibility && formik?.errors?.intlEligibility)
                        }
                        {...params}
                        placeholder="International eligibilities"
                        sx={AutocompleteInputCss}
                      />
                    )}
                  />
                  {formik?.touched?.intlEligibility && formik?.errors?.intlEligibility && (
                    <div className="errorMessageCss !text-errorRed !ml-1 !mt-1">
                      {formik?.errors?.intlEligibility}
                    </div>
                  )}
                </div>
              </div>
              {/*  Nationality*/}
              <div className="w-full mb-4">
                <div className="text-sm font-[400] flex mb-1">
                  Nationality{!isParent && <span className="text-red-600">*</span>}
                </div>
                <div className="">
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    renderTags={(value) => {
                      const numTags = value.length
                      return (
                        <span className="max-w-[60%] truncate text-sm mr-2 text-sm">
                          {numTags > 1 && ` ${numTags - 1} more +`}
                          {value
                            .slice(0, 1)
                            .map((option) => option)
                            .join(", ")}
                        </span>
                      )
                    }}
                    id="nationality"
                    onChange={(_event, newValue) => {
                      formik.handleChange(_event)
                      formik.setFieldValue("nationality", newValue || "")
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} style={{ fontSize: "14px" }}>
                        <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                        {option}
                      </li>
                    )}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.nationality}
                    options={
                      Nationality?.sort((a, b) => (a.nationality > b.nationality ? 1 : -1))?.map(
                        (c) => c?.nationality,
                      ) || []
                    }
                    renderInput={(params) => (
                      <TextField
                        error={!!(formik?.touched?.nationality && formik?.errors?.nationality)}
                        {...params}
                        placeholder="Nationality"
                        sx={AutocompleteInputCss}
                      />
                    )}
                  />
                  {formik?.touched?.nationality && formik?.errors?.nationality && (
                    <div className="errorMessageCss !text-errorRed !ml-1 !mt-1">
                      {formik?.errors?.nationality}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Level  and  Higest Level Played */}
            <div className="flex flex-col gap-x-8 items-center sm:flex-row sm:justify-between sm:items-start ">
              {/* Level */}
              <div className="w-full mb-4">
                <label className="block text-[15px] font-[400]" htmlFor="level">
                  {isCoach ? "Coaching level" : "Level"}
                  {!isParent && <span className="text-red-600">*</span>}
                </label>

                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="level"
                    placeholder="Select level"
                    defaultValue=""
                    name="level"
                    onChange={formik.handleChange}
                    value={formik.values.level}
                    error={formik.touched.level && Boolean(formik.errors.level)}
                    className={"w-full"}
                    options={convertIntoDropDownOptions(dropDownOptions?.level)}
                    errormsg={
                      formik.touched.level && formik.errors.level ? formik.errors.level : ""
                    }
                  />
                </Box>
              </div>

              {/* Highest Level Played */}
              <div className="w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="highestLevelPlayed"
                >
                  {isCoach ? " Highest level coached" : "Highest level played"}
                </label>

                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="highestLevelPlayed"
                    defaultValue=""
                    name="highestLevelPlayed"
                    placeholder="Select highest level played"
                    onChange={formik.handleChange}
                    value={formik.values.highestLevelPlayed}
                    options={convertIntoDropDownOptions(dropDownOptions?.level)}
                    className="w-full"
                  />
                </Box>
              </div>
            </div>

            <div className="flex flex-col gap-x-8 items-center sm:flex-row sm:justify-between sm:items-start">
              {/* Specialties/Strengths */}
              <div className="w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="speciality"
                >
                  Specialities/Strengths
                </label>
                <GRTextfield
                  id="speciality"
                  placeholder="Speciality"
                  type="text"
                  height="48px"
                  name="speciality"
                  onChange={formik.handleChange}
                  value={formik.values.speciality}
                  error={formik.touched.speciality && Boolean(formik.errors.speciality)}
                  helperText={formik.touched.speciality && formik.errors.speciality}
                  className="w-full"
                />
              </div>
              {/* Where did you hear about us?  */}
              <div className="w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="hearAboutUs"
                >
                  Where did you hear about us?<span className="text-red-600">*</span>
                </label>

                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="hearAboutUs"
                    defaultValue=""
                    name="hearAboutUs"
                    placeholder="Select hear about us"
                    onChange={formik.handleChange}
                    value={formik.values.hearAboutUs}
                    options={hearAboutUsOptions}
                    className="w-full"
                    error={formik.touched.hearAboutUs && Boolean(formik.errors.hearAboutUs)}
                    errormsg={
                      formik.touched.hearAboutUs && Boolean(formik.errors.hearAboutUs)
                        ? formik.errors.hearAboutUs
                        : ""
                    }
                  />
                </Box>
              </div>
            </div>
            {/* Referral User ID */}
            {/* {formik.values.hearAboutUs === "Referral" && (
              <div className="w-max">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="referralUserId"
                >
                  Referral user ID <span className="text-red-600">*</span>
                </label>
                <GRTextfield
                  id="referralUserId"
                  type="text"
                  height="48px"
                  name="referralUserId"
                  onChange={formik.handleChange}
                  value={formik.values.referralUserId}
                  error={formik.touched.referralUserId && Boolean(formik.errors.referralUserId)}
                  helperText={formik.touched.referralUserId && formik.errors.referralUserId}
                  className="w-[300px] "
                />
              </div>
            )} */}
            <div
              className={`${
                isCoach
                  ? "flex flex-col items-center sm:flex-row  sm:items-start sm:justify-between gap-x-8"
                  : ""
              } `}
            >
              <div className={`${isCoach ? "w-full mb-4" : "w-full mb-4"}`}>
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="disciplines"
                >
                  Code
                </label>

                <Box sx={{ width: "100%" }}>
                  {isCoach ? (
                    <Autocomplete
                      id="disciplines"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      renderTags={(value) => {
                        const numTags = value.length
                        return (
                          <span className="max-w-[60%] truncate text-sm mr-2 text-sm">
                            {numTags > 1 && ` ${numTags - 1} more +`}
                            {value
                              .slice(0, 1)
                              .map((option) => option?.label)
                              .join(", ")}
                          </span>
                        )
                      }}
                      onChange={(_event, newValue) => {
                        formik.handleChange(_event)
                        const selectedOptions = newValue?.map((n: any) => n?.id)
                        formik.setFieldValue("disciplines", selectedOptions || "")
                      }}
                      renderOption={(props: object, option, { selected }) => (
                        <div {...props}>
                          <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                          {option?.label}
                        </div>
                      )}
                      isOptionEqualToValue={(options, selected) => options?.id === selected?.id}
                      getOptionLabel={(option) => option.label}
                      onBlur={formik?.handleBlur}
                      value={(() => {
                        const selectedOptions = dropDownOptions?.discipline?.filter((d) =>
                          formik.values.disciplines?.includes(String(d?.id)),
                        )
                        const options =
                          selectedOptions?.map((s) => ({ label: s?.title, id: String(s?.id) })) ||
                          []
                        return options
                      })()}
                      options={
                        dropDownOptions?.discipline?.map((d) => ({
                          label: d?.title,
                          id: String(d?.id),
                        })) || []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select code"
                          sx={AutocompleteInputCss}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      renderTags={(value) => {
                        const numTags = value.length
                        return (
                          <span className="max-w-[60%] truncate text-sm mr-2 text-sm">
                            {numTags > 1 && ` ${numTags - 1} more +`}
                            {value
                              .slice(0, 1)
                              .map((option) => option?.label)
                              .join(", ")}
                          </span>
                        )
                      }}
                      id="Disciplines"
                      onChange={(_event, newValue) => {
                        formik.handleChange(_event)
                        const selectedOptions = newValue?.map((n: any) => n?.id)
                        formik.setFieldValue("disciplines", selectedOptions || "")
                      }}
                      renderOption={(props: object, option, { selected }) => (
                        <div {...props}>
                          <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                          {option?.label}
                        </div>
                      )}
                      isOptionEqualToValue={(options, selected) => options?.id === selected?.id}
                      getOptionLabel={(option) => option.label}
                      onBlur={formik?.handleBlur}
                      value={(() => {
                        const selectedOptions = dropDownOptions?.discipline?.filter((d) =>
                          formik.values.disciplines?.includes(String(d?.id)),
                        )
                        const options =
                          selectedOptions?.map((s) => ({ label: s?.title, id: String(s?.id) })) ||
                          []
                        return options
                      })()}
                      options={
                        dropDownOptions?.discipline?.map((d) => ({
                          label: d?.title,
                          id: String(d?.id),
                        })) || []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select code"
                          sx={AutocompleteInputCss}
                        />
                      )}
                    />
                  )}
                </Box>
              </div>
              {isCoach && (
                <div className="w-full mb-4">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="highestQualification"
                  >
                    Highest coaching qualification
                  </label>

                  <Box sx={{ width: "100%" }}>
                    <GRSelect
                      id="highestQualification"
                      defaultValue=""
                      placeholder="Select highest qualification"
                      name="highestQualification"
                      onChange={formik.handleChange}
                      value={formik.values.highestQualification}
                      options={convertIntoDropDownOptions(dropDownOptions?.highestQualification)}
                      className=" "
                    />
                  </Box>
                </div>
              )}
            </div>
            {/* Select the season for when you want the opportunities. */}
            {!isParent ? (
              <div className="sm:w-full mb-4">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="season"
                >
                  Select the season for when you want the opportunities.
                </label>

                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="season"
                    defaultValue=""
                    placeholder="Select season"
                    style={{ width: "100%" }}
                    name="season"
                    onChange={formik.handleChange}
                    value={formik.values.season}
                    options={seasonIWishToPlayOptions}
                  />
                </Box>
              </div>
            ) : null}
            {/* Select the region you want to search for opportunities. */}
            {!isParent ? (
              <div className="mb-4 sm:w-full">
                <label
                  className="cursor-pointer block text-[15px] leading-6 font-[400]"
                  htmlFor="region"
                >
                  Select the region you want to search for opportunities.
                </label>

                <Box sx={{ width: "100%" }}>
                  <GRSelect
                    id="region"
                    multiple
                    defaultValue=""
                    placeholder="Select region"
                    style={{ width: "100%" }}
                    name="region"
                    onChange={formik.handleChange}
                    value={formik.values.region || []}
                    error={formik.touched.region && Boolean(formik.errors.region)}
                    errormsg={
                      formik.touched.region && formik.errors.region
                        ? formik.errors.region?.toString()
                        : ""
                    }
                    options={countryOptions}
                  />
                </Box>
              </div>
            ) : null}
          </div>
          {/* buttons -  next */}
          <div className="flex gap-4 justify-between mt-4 w-full">
            <div className="flex justify-start mb-2">
              <GRButton
                label="Previous"
                startIcon={<LeftArrow />}
                size="sm"
                className="!text-gr-primaryR2 !bg-white !px-[10px]"
                border="1px solid"
                width="110px"
                onClick={handleGoBack}
              />
            </div>
            <div className="flex mb-2">
              <GRButton
                isSubmitting={isSubmitting}
                label="Next"
                endIcon={<RightArrow />}
                width="110px"
                size="sm"
                className="!bg-primaryR2"
              />
            </div>
          </div>
        </form>
      </div>
      <RecordHistoryDialog
        onSuccess={() => setIsShowDialog(false)}
        isShowDialog={isShowDialog}
        onClose={() => setIsShowDialog(false)}
      />
    </div>
  )
}

export default ProfessionalInfo
