import React from "react"
import GlobalRugbyLogo from "../assets/svg/GlobalRugbyLogo"

import SSOLogin from "../components/loginRegistration/SSOLogin"
import LoginForm from "../components/forms/LoginForm"

function Login() {
  return (
    <div className="flex justify-center items-center py-10  h-screen  bg-white sm:bg-bgRed ">
      <div className="  h-[529px]  bg-white flex flex-col items-center rounded-2xl px-[30px] sm:w-[570px]  sm:shadow-light sm:px-[60px] py-4 ">
        <GlobalRugbyLogo className="w-16 sm:w-[76px]" />
        <div className="text-xl md:text-[32px] font-microsoftTai mb-5 font-bold mt-2">
          Sign into your account
        </div>
        <LoginForm />
        <SSOLogin isLogin={false} />
      </div>
    </div>
  )
}

export default Login
