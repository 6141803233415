import React, { SVGProps } from "react"

const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={25}
      height={26}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={40} height={40} rx={20} fill="#0A66C2" />
      <path
        d="M29.376 9H10.624A1.624 1.624 0 009 10.624v18.752A1.624 1.624 0 0010.624 31h18.752A1.624 1.624 0 0031 29.376V10.624A1.624 1.624 0 0029.376 9zM15.557 27.741H12.25V17.235h3.307V27.74zM13.901 15.78a1.9 1.9 0 111.91-1.898 1.87 1.87 0 01-1.91 1.898zM27.75 27.75h-3.306v-5.74c0-1.692-.72-2.215-1.649-2.215-.98 0-1.943.74-1.943 2.258v5.697h-3.308V17.242h3.181v1.456h.043c.32-.646 1.438-1.75 3.144-1.75 1.846 0 3.84 1.095 3.84 4.303l-.002 6.5z"
        fill="#fff"
      />
    </svg>
  )
}

export default LinkedInIcon
