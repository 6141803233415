import React from "react"
import { Link } from "react-router-dom"
import { AUTH_OPPORTUNITY } from "../../routes/RouteConstants"
import OpportunityCard from "../dashboard/Opportunities/OpportunityCard"
import ClubCard from "../dashboard/Opportunities/ClubCard"
import InfiniteScroll from "react-infinite-scroll-component"
import { CircularProgress } from "@mui/material"
interface ISearchedOpportunitiesProps {
  isShowSeeAllLink?: boolean
  handleSeeAll?: () => void
  opportunity: any
  handleMore?: () => void
  hasMore?: boolean
}

const SearchedOpportunities = (props: ISearchedOpportunitiesProps) => {
  // Props
  const { isShowSeeAllLink, opportunity, handleMore, hasMore } = props

  return (
    <div className="">
      {/* mobile view  */}
      <div className="sm:hidden">
        <div className="bg-white rounded-[16px] ">
          <div className="pt-[24px] sm:px-[15px] md:px-[44px]">
            <div className="text-[20px] font-microsoftTai font-bold mb-[39px] ml-[24px]">
              Opportunities
            </div>
            {new Array(4).fill("").map((ele, idx) => {
              return (
                <div key={idx}>
                  <ClubCard clubCardIdx={idx} />
                </div>
              )
            })}
          </div>
          <div className="flex justify-center items-center py-[12px] mb-4">
            <div className="font-inter text-gr-primaryR2 text-lg font-normal mb-3">
              <Link to={AUTH_OPPORTUNITY}>See all Opportunities</Link>
            </div>
          </div>
        </div>
      </div>
      {/* web view */}
      <div className="bg-white rounded-[20px] shadow-lg hidden sm:block ">
        {/* <div className="">
          <span className="w-[74px] h-[26px] ml-[40px] text-gr-primaryN1 font-bold font-microsoftTai text-[20px]">
            Opportunities
          </span>
        </div>
        {props?.opportunity?.map((el: any, idx: number) => {
          return (
            <OpportunityCard
              opportunity={el}
              opportunityCardindex={idx}
              key={idx}
            />
          )
        })} */}
        <div
          className="w-full pt-[12px] md:px-[24px] px-4 max-h-[600px] h-full overflow-y-auto"
          id="scrollableDiv"
        >
          <div className="pt-5 text-[20px] font-inter font-bold mb-[19px] ml-[24px] md:ml-[16px] ">
            Opportunities
          </div>
          <InfiniteScroll
            dataLength={opportunity?.length ?? 0}
            next={handleMore as any}
            hasMore={hasMore || false}
            loader={
              !isShowSeeAllLink && (
                <div className="flex justify-center items-center h-14">
                  <p className="flex justify-center items-center">
                    Loading
                    <span className="ml-2 flex justify-center items-center">
                      <CircularProgress size={15} style={{ color: "#000" }} />
                    </span>
                  </p>
                </div>
              )
            }
          >
            <div className="px-4 mb-4">
              {opportunity?.map((el: any) => (
                <OpportunityCard opportunity={el} key={el?.id} isSerchOpportunity={true} />
              ))}
            </div>
          </InfiniteScroll>
        </div>
        <div className="flex justify-center items-center py-[12px]">
          <button className="font-inter text-gr-primaryR2 text-lg font-normal mb-3 hover:underline">
            <Link to={AUTH_OPPORTUNITY}>See all Opportunities</Link>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SearchedOpportunities
