import CrossIcon from "../../assets/svg/CrossIcon"
import React from "react"

const DialogHeader = ({ onClose, title }: { onClose: () => void; title: string }) => {
  return (
    <div className="mx-[15px] lg:mx-[31px] flex justify-between items-center border-b border-b-gr-primaryN8 mb-4">
      <p className="font-inter sm:text-2xl text-xl font-semibold leading-6 text-black my-5">
        {title}
      </p>
      <div
        className="text-2xl leading-10 font-bold font-inter text-gr-primaryR3 cursor-pointer"
        onClick={() => onClose()}
      >
        <CrossIcon />
      </div>
    </div>
  )
}

export default DialogHeader
