import React from "react"
// import LinkedinIcon from "../../assets/svg/LinkedinIcon.svg"
// import GoogleIcon from "../../assets/svg/GoogleIcon"
import { UNAUTH_LOGIN, UNAUTH_REGISTER } from "../../routes/RouteConstants"
import { Link } from "react-router-dom"

interface SSOLoginProps {
  isLogin: boolean
}

const SSOLogin: React.FC<SSOLoginProps> = ({ isLogin }) => {
  return (
    <div className=" w-full font-microsoftTai mb-3 mt-[8px]  ">
      {/* <div className="flex items-center">
        <span className="flex-grow bg-gray-500 h-px "></span>
        <span className="px-4 text-gray-500 text-[14px]">Continue with</span>
        <span className="flex-grow bg-gray-500 h-px "></span>
      </div>
      <div className="flex justify-center space-x-4 mt-1">
        <GoogleIcon className="w-12 h-12" />
        <img className="w-12 h-12" src={LinkedinIcon} />
      </div> */}
      <div className="text-center font-inter mt-2">
        <span className="font-[400] text-basetext-[12px] text-black mr-1">
          {isLogin ? "Already have an account?" : "If you do not have an account?"}
        </span>
        <Link
          to={isLogin ? UNAUTH_LOGIN : UNAUTH_REGISTER}
          className="font-normal text-base hover:underline text-[12px] text-gr-primaryR2 "
        >
          {isLogin ? "Login here" : "Register here"}
        </Link>
      </div>
    </div>
  )
}

export default SSOLogin
