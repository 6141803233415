import React, { useState, useEffect, useContext } from "react"
import { Link, useParams } from "react-router-dom"
import FilterIcon from "../../../assets/svg/FilterIcon"
import FilterOpportunityPopup from "../../../components/dashboard/Opportunities/FilterOpportunityPopup"
import { AUTH_OPPORTUNITY } from "../../../routes/RouteConstants"
import ClubCard from "../../../components/dashboard/Opportunities/ClubCard"
import ClubOpportunityCard from "../../../components/dashboard/ClubOpportunities/ClubOpportunityCard"
import { useOpportunityAPI } from "../../../services/useOpportunityAPI"
import { IOpportunitySummary } from "../../../modals/Opportunity"
import AppContext from "../../../context/app-context/AppContext"
import GRButton from "../../../components/ui/GRButton"
import CreateOpportunityDialog from "../../../components/dialogs/CreateOpportunityDialog"
import { IDropDownOptionsResponse } from "../../../modals/Config"
import { useConfigAPI } from "../../../services/useConfigAPI"
interface IAllPostsProps {
  isAdmin: boolean | undefined
  setOpportunitiesNo: (opportunities: IOpportunitySummary[] | undefined) => void
}

function ClubOpportunities(props: IAllPostsProps) {
  // Props
  const { isAdmin, setOpportunitiesNo } = props
  const [open, setOpen] = useState<boolean>(false)
  const [opportunities, setOpportunities] = useState<IOpportunitySummary[]>()
  const [dropDownOptions, setDropDownOptions] = useState<IDropDownOptionsResponse>()
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  // Context
  const { getDropDownOptions } = useConfigAPI()
  const { loginAsProfileDetails } = useContext(AppContext)

  // Hooks
  const { clubId } = useParams()

  const cId = clubId ? Number(clubId) : loginAsProfileDetails?.club?.id

  // APIs
  const { getOpportunityByClubId, getOpportunityByClubIdAndFilter } = useOpportunityAPI()
  useEffect(() => {
    getOpportunity()
  }, [])

  const getOpportunity = async () => {
    try {
      if (!cId) return
      const opportunityList = clubId
        ? await getOpportunityByClubIdAndFilter(cId, "active=true")
        : await getOpportunityByClubId(cId)
      setOpportunities(opportunityList?.opportunity)
      setOpportunitiesNo(opportunityList?.opportunity)
    } catch (error) {}
  }
  const fetchConfig = async () => {
    try {
      const cr = await getDropDownOptions()
      setDropDownOptions(cr)
    } catch {}
  }
  useEffect(() => {
    getOpportunity()
    fetchConfig()
  }, [])
  return (
    <>
      <div className="hidden sm:flex flex-col bg-white rounded-2xl sm:px-6 sm:pt-5 p-4">
        {/* heading and btn */}
        <div className="justify-between mb-8">
          {/* Leftsection */}
          <div className="flex">
            <div className="font-inter font-semibold text-2xl">All opportunities</div>
            {isAdmin && (
              <div className="ml-auto">
                <GRButton
                  onClick={() => setIsShowDialog(!isShowDialog)}
                  label="Add New"
                  height={33}
                  fontSize="12px"
                  className="cursor-pointer"
                />
                <CreateOpportunityDialog
                  dropDownOptions={dropDownOptions}
                  isShowDialog={isShowDialog}
                  onClose={() => setIsShowDialog(false)}
                  onGetOpportunity={() => getOpportunity()}
                />
              </div>
            )}
          </div>
        </div>
        {/* opportunityCards */}
        <div className=" hidden sm:block font-inter">
          {opportunities?.length ? (
            opportunities?.map((opportunity, index) => (
              <ClubOpportunityCard opportunity={opportunity} key={index} />
            ))
          ) : (
            <p className="text-center">No opportunities yet</p>
          )}
        </div>
      </div>
      <div className="sm:hidden">
        <div className="flex items-center justify-end gap-[10px] mb-4 cursor-pointer">
          <div className="text-blue-400">
            <FilterIcon />
          </div>
          <div className="font-inter text-gr-neutralT2 underline" onClick={() => setOpen(true)}>
            All Filter
          </div>
        </div>
        <div className="flex flex-col sm:h-[671px] bg-white shadow-lg rounded-2xl pb-3">
          {open ? (
            <FilterOpportunityPopup setOpen={setOpen} />
          ) : (
            <div>
              <div className="flex items-center mt-4 ">
                {isAdmin && (
                  <div className="flex ml-4 mt-2  ">
                    <h2 className="text-[18px] font-semibold ">Opportunities</h2>
                    <div className="max-[320px]:ml-[35px] max-[360px]:ml-[70px] max-[380px]:ml-[80px] ml-[120px]">
                      <GRButton
                        onClick={() => setIsShowDialog(!isShowDialog)}
                        label="Add New"
                        height={33}
                        fontSize="12px"
                        className="cursor-pointer"
                      />
                      <CreateOpportunityDialog
                        dropDownOptions={dropDownOptions}
                        isShowDialog={isShowDialog}
                        onClose={() => setIsShowDialog(false)}
                        onGetOpportunity={() => getOpportunity()}
                      />
                    </div>
                  </div>
                )}
              </div>
              {opportunities &&
                opportunities?.map((opportunity, index) => (
                  <ClubCard opportunity={opportunity} key={index} />
                ))}
              <div className="flex justify-end pr-4 text-gr-neutralT3 underline text-[14px]  mt-2 font-normal font-inter">
                <Link to={AUTH_OPPORTUNITY}>View all</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default ClubOpportunities
