import * as React from "react"

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
      <path
        d="M9.492 9.834l-1.917 1.908a.832.832 0 000 1.183.833.833 0 001.183 0l3.334-3.333a.832.832 0 00.175-.275.833.833 0 000-.633.832.832 0 00-.175-.275L8.758 5.075A.837.837 0 007.575 6.26l1.917 1.908H1.5a.833.833 0 100 1.667h7.992zM9 .667a8.333 8.333 0 00-7.5 4.625.839.839 0 001.5.75 6.667 6.667 0 116 9.625 6.608 6.608 0 01-5.967-3.708.839.839 0 00-1.5.75A8.333 8.333 0 109 .667z"
        fill="#71839B"
      />
    </svg>
  )
}

export default SvgComponent
