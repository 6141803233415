import * as React from "react"
import { SVGProps } from "react"

const PhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M22.222 12.444a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Z" fill="#4390E3" />
    <path
      d="M0 3.556A3.556 3.556 0 0 1 3.556 0h24.888A3.556 3.556 0 0 1 32 3.556v24.888A3.556 3.556 0 0 1 28.444 32H3.556A3.556 3.556 0 0 1 0 28.444V3.556Zm28.444 0H3.556v14.078l6-4.8c.65-.52 1.572-.52 2.221 0l7.647 6.117 2.43-2.43a1.778 1.778 0 0 1 2.514 0l4.076 4.076V3.556ZM3.556 28.444h24.888v-2.819l-5.333-5.333-2.298 2.298c-.64.641-1.66.697-2.368.131L10.667 16.5l-7.111 5.689v6.256Z"
      fill="#4390E3"
    />
  </svg>
)

export default PhotoIcon
