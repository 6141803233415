import Cookies from "js-cookie"

export const setAuthToken = (value: string) => {
  Cookies.set("authToken", value)
}

export const getAuthToken = () => {
  return Cookies.get("authToken")
}

export const removeAuthToken = () => {
  return Cookies.remove("authToken")
}
