import * as React from "react"
import { SVGProps } from "react"

const BinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.875 3.75h11.25M5 3.75V2.5a1.25 1.25 0 0 1 1.25-1.25h2.5A1.25 1.25 0 0 1 10 2.5v1.25m1.875 0v8.75a1.25 1.25 0 0 1-1.25 1.25h-6.25a1.25 1.25 0 0 1-1.25-1.25V3.75h8.75ZM8.75 6.875v3.75M6.25 6.875v3.75"
      stroke="#9F0002"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BinIcon
