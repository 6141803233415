import GRSelect, { AutocompleteInputCss } from "../../../components/ui/GRSelect"
import React, { useContext } from "react"
import { AgentProfessionalInfoOnBoardingValidationSchema as validationSchema } from "../../../validations/Validations"
import { useFormik } from "formik"
import { Autocomplete, Box, TextField } from "@mui/material"
import AppContext from "../../../context/app-context/AppContext"
import Nationality from "../../../utils/Nationality.json"
import GRButton from "../../../components/ui/GRButton"
import LeftArrow from "../../../assets/svg/LeftArrowRed"
import RightArrow from "../../../assets/svg/RightArrow"
import { hearAboutUsOptions } from "../../../utils/Constants"
import { convertIntoDropDownOptions } from "../../../utils/CommonUtils"
import Qualification from "../../../components/playerProfile/Qualification"

export interface IAgentProfessionalInfoFormValues {
  nationality: string[]
  hearAboutUs: string
  // referralUserId: string
  disciplines: string[]
}
type IAgentProfessionalInfoProps = {
  onSubmit: (values: IAgentProfessionalInfoFormValues) => void
  isSubmitting: boolean
  goToPreviousStep: any
}

const AgentProfessionalInfoOnBoarding: React.FC<IAgentProfessionalInfoProps> = ({
  onSubmit,
  isSubmitting,
  goToPreviousStep,
}) => {
  // Context
  const { loginAsProfileDetails, dropDownOptions } = useContext(AppContext)
  const handleGoBack = () => {
    goToPreviousStep() // Call the function passed from the parent component to go back to the previous step
  }
  const formik = useFormik({
    initialValues: {
      nationality: loginAsProfileDetails?.nationality
        ? loginAsProfileDetails?.nationality?.split(", ")?.map((n) => n)
        : [],

      hearAboutUs: loginAsProfileDetails?.hearingSource || "",
      // referralUserId: loginAsProfileDetails?.referralUserId || "",
      disciplines: loginAsProfileDetails?.disciplines
        ? loginAsProfileDetails?.disciplines?.map((i) => i?.id?.toString())
        : [],
    },
    onSubmit: (values: IAgentProfessionalInfoFormValues) => onSubmit(values),
    validationSchema,
    enableReinitialize: true,
  })
  return (
    <>
      <div className="flex flex-col gap-4  border-t border-gr-primaryN8 bg-white mt-[-10px]   font-inter">
        {/* heading- Professional Info */}

        <div className="flex justify-between items-center font-bold">
          <div className="text-lg text-gr-primaryN1 mt-2 font-microsoftTai">Professional Info</div>
          <div>
            2 <span className="text-gray-500">/3</span>
          </div>
        </div>

        {/* form */}
        <div>
          <form onSubmit={formik.handleSubmit} className="flex flex-col ">
            <div className="mx-4 sm:mx-0 px-2 mb-8">
              <Qualification
                addUploadedFileId={(id: number) => {
                  console.log(id)
                }}
              />
            </div>
            <div className="flex flex-col ">
              <div className="flex flex-col items-center sm:flex-row sm:h-[90px] sm:items-start sm:justify-between">
                {/*  Nationality*/}
                <div className="w-[295px] sm:w-[285px] h-24">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="nationality"
                  >
                    Nationality<span className="text-red-600">*</span>
                  </label>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    renderTags={(value) => {
                      const numTags = value.length
                      return (
                        <span className="max-w-[60%] truncate text-sm">
                          {numTags > 1 && ` ${numTags - 1} more +`}
                          {value
                            .slice(0, 1)
                            .map((option) => option)
                            .join(", ")}
                        </span>
                      )
                    }}
                    id="nationality"
                    onChange={(_event, newValue) => {
                      formik.handleChange(_event)
                      formik.setFieldValue("nationality", newValue || "")
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} style={{ fontSize: "14px" }}>
                        <input type="checkbox" style={{ marginRight: 8 }} checked={selected} />
                        {option}
                      </li>
                    )}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.nationality}
                    options={
                      Nationality?.sort((a, b) => (a.nationality > b.nationality ? 1 : -1))?.map(
                        (c) => c?.nationality,
                      ) || []
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Nationality" sx={AutocompleteInputCss} />
                    )}
                  />
                  {formik?.touched?.nationality && formik?.errors?.nationality && (
                    <div className=" text-[13px] text-red-500 lowercase first-letter:uppercase font-inter">
                      {formik?.errors?.nationality}
                    </div>
                  )}
                </div>
                <div className="w-[320px]  h-24">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="discipline"
                  >
                    Code
                  </label>

                  <Box sx={{ width: "100%" }}>
                    <GRSelect
                      id="disciplines"
                      defaultValue=""
                      name="disciplines"
                      placeholder="Select code"
                      onChange={formik.handleChange}
                      value={formik.values.disciplines}
                      className="w-[300px] sm:w-[320px]"
                      options={convertIntoDropDownOptions(dropDownOptions?.discipline)}
                    />
                  </Box>
                </div>
              </div>
              <div className="flex flex-col items-center sm:flex-row sm:h-24 sm:items-start sm:justify-between">
                <div className="w-max h-24">
                  <label
                    className="cursor-pointer block text-[15px] leading-6 font-[400]"
                    htmlFor="hearAboutUs"
                  >
                    Where did you hear about us?<span className="text-red-600">*</span>
                  </label>

                  <Box sx={{ width: "100%" }}>
                    <GRSelect
                      id="hearAboutUs"
                      defaultValue=""
                      name="hearAboutUs"
                      placeholder="Select hear about us"
                      onChange={formik.handleChange}
                      value={formik.values.hearAboutUs}
                      options={hearAboutUsOptions}
                      className="w-[300px] sm:w-[285px]"
                      error={formik.touched.hearAboutUs && Boolean(formik.errors.hearAboutUs)}
                      errormsg={
                        formik.touched.hearAboutUs && Boolean(formik.errors.hearAboutUs)
                          ? formik.errors.hearAboutUs
                          : ""
                      }
                    />
                  </Box>
                </div>

                {/* Referral User ID
                {formik.values.hearAboutUs === "Referral" && (
                  <div className="w-max h-24">
                    <label
                      className="cursor-pointer block text-[15px] leading-6 font-[400]"
                      htmlFor="referralUserId"
                    >
                      Referral User ID <span className="text-red-600">*</span>
                    </label>

                    <GRTextfield
                      id="referralUserId"
                      type="text"
                      height="48px"
                      name="referralUserId"
                      onChange={formik.handleChange}
                      value={formik.values.referralUserId}
                      error={formik.touched.referralUserId && Boolean(formik.errors.referralUserId)}
                      helperText={formik.touched.referralUserId && formik.errors.referralUserId}
                      className="w-[300px] sm:w-[285px]"
                    />
                  </div>
                )} */}
              </div>
            </div>
            <div>
              {/* buttons -  next */}
              <div className="flex gap-4 justify-between mt-4 sm:mt-6 w-full">
                <div className="flex justify-start mb-2 -mt-2">
                  <GRButton
                    label="Previous"
                    startIcon={<LeftArrow />}
                    size="sm"
                    className="!text-gr-primaryR2 !bg-white !px-[10px]"
                    border="1px solid"
                    onClick={handleGoBack}
                  />
                </div>
                <div className="flex  mb-2 -mt-2">
                  <GRButton
                    isSubmitting={isSubmitting}
                    label="Next"
                    endIcon={<RightArrow />}
                    width="95px"
                    size="sm"
                    className="!bg-primaryR2"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AgentProfessionalInfoOnBoarding
