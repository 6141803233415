import React, { ReactNode, useState } from "react"
import EditBtn from "../../assets/svg/EditBtn"
import FacebookIcon from "../../assets/svg/FacebookIcon"
import LinkedInIcon from "../../assets/svg/LinkedInIcon"
import InstagramIcon from "../../assets/svg/InstagramIcon.svg"
import TwitterIcon from "../../assets/svg/clubprofileicon/Twitter.svg"
import TiktokIcon from "../../assets/svg/Tiktok.svg"
import SnapChatIcon from "../../assets/svg/SnapChat.svg"
import SocialMediaPopup from "./SocialMediaPopup"
import { IUser } from "../../modals/User"

interface ISocialNetworkProps {
  canEdit?: boolean
  profileDetails?: IUser
}

const SocialNetwork = (props: ISocialNetworkProps) => {
  // Props
  const { canEdit, profileDetails } = props
  // State
  const [isShowDialog, setIdShowDialog] = useState<boolean>(false)

  return (
    <>
      {isShowDialog && (
        <SocialMediaPopup open={isShowDialog} onClose={() => setIdShowDialog(false)} />
      )}
      <div className="px-4 py-[30px] rounded-[16px] bg-white">
        {/* heading and edit btn */}
        <div className="flex justify-between items-center">
          <div className="font-inter sm:text-2xl text-xl font-semibold">Social network</div>
          <div className="cursor-pointer" onClick={() => setIdShowDialog(true)}>
            {canEdit ? <EditBtn /> : ""}
          </div>
        </div>
        <div className="border border-gray-200 mt-4 mb-6"></div>
        <div className=" flex flex-col gap-[8px] ">
          <div className="flex flex-wrap sm:gap-[10px] sm:justify-normal justify-between px-2">
            {profileDetails?.facebookLink && (
              <SocialMediaCard
                icon={<FacebookIcon width={27} height={27} />}
                name="Facebook"
                link={
                  profileDetails?.facebookLink
                    ? profileDetails?.facebookLink.includes("https://") ||
                      profileDetails?.facebookLink.includes("http://")
                      ? profileDetails?.facebookLink
                      : `https://${profileDetails?.facebookLink}`
                    : null
                }
              />
            )}
            {profileDetails?.instagramLink && (
              <SocialMediaCard
                icon={<img src={InstagramIcon} alt="icon" width={27} height={27} />}
                name="Instagram"
                link={
                  profileDetails?.instagramLink
                    ? profileDetails?.instagramLink.includes("https://") ||
                      profileDetails?.instagramLink.includes("http://")
                      ? profileDetails?.instagramLink
                      : `https://${profileDetails?.instagramLink}`
                    : null
                }
              />
            )}
            {profileDetails?.linkedinLink && (
              <SocialMediaCard
                icon={<LinkedInIcon width={27} height={27} />}
                name="LinkedIn"
                link={
                  profileDetails?.linkedinLink
                    ? profileDetails?.linkedinLink.includes("https://") ||
                      profileDetails?.linkedinLink.includes("http://")
                      ? profileDetails?.linkedinLink
                      : `https://${profileDetails?.linkedinLink}`
                    : null // Set link to null when linkedinLink is null
                }
              />
            )}
            {profileDetails?.twitterLink && (
              <SocialMediaCard
                icon={<img src={TwitterIcon} width={27} height={27} />}
                name="Twitter"
                link={
                  profileDetails?.twitterLink
                    ? profileDetails?.twitterLink.includes("https://") ||
                      profileDetails?.twitterLink.includes("http://")
                      ? profileDetails?.twitterLink
                      : `https://${profileDetails?.twitterLink}`
                    : null
                }
              />
            )}
            {profileDetails?.tiktokLink && (
              <SocialMediaCard
                icon={<img src={TiktokIcon} width={27} height={27} />}
                name="TikTok"
                link={
                  profileDetails?.twitterLink
                    ? profileDetails?.tiktokLink.includes("https://") ||
                      profileDetails?.tiktokLink.includes("http://")
                      ? profileDetails?.tiktokLink
                      : `https://${profileDetails?.tiktokLink}`
                    : null
                }
              />
            )}
            {profileDetails?.snapchatLink && (
              <SocialMediaCard
                icon={<img src={SnapChatIcon} width={27} height={27} />}
                name="Snapchat"
                link={
                  profileDetails?.snapchatLink
                    ? profileDetails?.snapchatLink.includes("https://") ||
                      profileDetails?.snapchatLink.includes("http://")
                      ? profileDetails?.snapchatLink
                      : `https://${profileDetails?.snapchatLink}`
                    : null
                }
              />
            )}
          </div>
          <div className="error text-xs text-red-600 ml-2"></div> {/* Display error message */}
        </div>
      </div>
    </>
  )
}

interface IGRSocialMediaCardProps {
  icon?: ReactNode
  name?: string
  link?: any
}

const SocialMediaCard: React.FC<IGRSocialMediaCardProps> = (props: IGRSocialMediaCardProps) => {
  const handleClick = () => {
    if (!props.link) {
      // Display the error message in the error div
      const errorDiv = document.querySelector(".error")
      if (errorDiv) {
        errorDiv.textContent = "Link is not updated!"
      }
    }
  }

  return (
    <div
      className="flex justify-center items-center sm:w-auto sm:max-w-max max-w-[48%] w-full px-2 rounded-[4px] h-[50px] gap-[10px] border border-gr-primaryR4 sm:mb-0 mb-4 hover:bg-bgRed"
      onClick={handleClick} // Add onClick handler
    >
      <a href={props.link} target="_blank" rel="noopener noreferrer" className="  w-7 h-7 ">
        {props.icon}
      </a>
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-gr-neutralT3 font-inter font-medium text-[13px] w-[70px]"
      >
        {props.name}
      </a>
    </div>
  )
}

export default SocialNetwork
