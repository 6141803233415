import React, { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import {
  AUTH_CLUB_PROFILE,
  AUTH_USER_PROFILE,
  AUTH_MY_PROFILE,
  AUTH_MESSAGE,
} from "../../routes/RouteConstants"

import { IUser } from "../../modals/User"
import MessageIcon from "../../assets/svg/MessageIcon"
import LeftArrow from "../../assets/svg/LeftArrow"
import ClubLogo from "../../assets/svg/ClubLogo.svg"
import { getNameInitials } from "../../utils/CommonUtils"
import AppContext from "../../context/app-context/AppContext"

interface IFollowersProps {
  profileDetails?: IUser
}

const UserFollowings = (props: IFollowersProps) => {
  // Props
  const { profileDetails } = props
  const { loginAsProfileDetails } = useContext(AppContext)
  //Hook
  const { userId } = useParams()

  const clubFollowing = profileDetails?.clubFollowing?.map((c) => ({ ...c, type: "Club" }))
  const userFollowing = profileDetails?.following?.map((u) => ({ ...u, type: "User" }))

  const following: any = clubFollowing && userFollowing ? [...clubFollowing, ...userFollowing] : []
  profileDetails?.clubMember?.name && following.unshift(profileDetails?.clubMember)

  const Followings = location.pathname === "/Followings"

  return (
    <div className="flex flex-col  bg-white rounded-t-[16px] px-6  py-4">
      <div className="flex justify-between items-center mt-2">
        <div className="font-inter flex items-center gap-4  text-[24px] text-gr-primaryN1  font-semibold ">
          {!Followings ? (
            <Link to={userId ? `${AUTH_USER_PROFILE}/${userId}` : AUTH_MY_PROFILE}>
              <LeftArrow />
            </Link>
          ) : null}
          {`Following (${following?.length})`}
        </div>
      </div>

      <div className="border border-gray-200 mt-4 mb-5"></div>
      {following?.map((el: any, indx: number) => (
        <div
          key={indx}
          className={`flex justify-between items-center pl-3 pr-[14.5px] rounded-[10px] py-2 mb-1.5 `}
        >
          <div className="flex  gap-4 items-center">
            <div>
              <Link
                to={
                  el?.profileType === "Club"
                    ? `${AUTH_CLUB_PROFILE}/${el?.clubId}`
                    : `${AUTH_USER_PROFILE}/${el?.userId}`
                }
              >
                {el?.profileType === "Club" ? (
                  <img
                    src={el?.profilePicture?.formats?.thumbnail?.url || ClubLogo}
                    alt="profile-image"
                    className="rounded-full w-[48px] h-[48px]"
                  />
                ) : el?.profilePicture?.formats?.thumbnail?.url ? (
                  <img
                    src={el?.profilePicture?.formats?.thumbnail?.url}
                    alt="profile-image"
                    className="rounded-full w-[48px] h-[48px]"
                  />
                ) : (
                  <div className="flex items-center justify-center pl-3 pr-[14.5px] py-2 mb-1.5 w-[48px] h-[48px] rounded-full bg-gr-primaryR1">
                    <span className="text-[16px] sm:text-[18px] text-gr-primaryR2 font-semibold">
                      {getNameInitials(el?.firstname, el?.lastname)}
                    </span>
                  </div>
                )}
              </Link>
            </div>
            <div>
              <Link
                to={
                  el?.profileType === "Club"
                    ? `${AUTH_CLUB_PROFILE}/${el?.clubId}`
                    : `${AUTH_USER_PROFILE}/${el?.userId}`
                }
              >
                <p className="font-inter text-[14px] leading-[17px] text-gr-primaryN1 font-semibold mb-[3px] hover:underline">
                  {el?.profileType === "Club" ? `${el?.name}` : `${el?.firstname} ${el?.lastname}`}
                </p>
                <p className="font-inter text-[12px] leading-[15px] text-gr-neutralT3 font-normal">
                  {el?.profileType === "Club" ? `Club` : `${el?.profileType}`}
                </p>
              </Link>
            </div>
          </div>
          {loginAsProfileDetails?.id === userId || !userId ? (
            <div>
              <Link
                to={`${AUTH_MESSAGE}?chatwith=${
                  el?.profileType === "Club" ? el?.clubId : el?.userId
                }`}
              >
                <MessageIcon />
              </Link>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  )
}

export default UserFollowings
