import useBaseAPI from "./useBaseAPI"
import {
  IOpportunityResponse,
  IOpportunity,
  IOpportunityApplicantResponse,
} from "../modals/Opportunity"

export const useOpportunityAPI = () => {
  const { POST, GET, PUT, isLoading } = useBaseAPI()

  const createOpportunity = (payload: IOpportunityPayload): Promise<any> => {
    return POST(`/create-opportunity`, payload)
  }

  const getOpportunityByClubId = (clubId: number): Promise<IOpportunityResponse> => {
    return GET(`/opportunity-by-club/${clubId}`)
  }

  const getOpportunityByProfileType = (type: string): Promise<IOpportunityResponse> => {
    return GET(`/opportunity-by-profiletype?${type}`, null, undefined, true)
  }

  const getOpportunityById = (id: string): Promise<IOpportunity> => {
    return GET(`/opportunity-by-id/${id}`)
  }

  const updateApplicants = (payload: IApplicantPayload): Promise<IOpportunityApplicantResponse> => {
    return PUT(`/update-applicants`, payload)
  }

  const updateOpportunity = (payload: IUpdateOpportunityPayload): Promise<any> => {
    return PUT("/update-opportunity", payload)
  }

  const getApplyOpportunity = (): Promise<IOpportunityResponse> => {
    return GET(`/get-apply-opportunity`)
  }

  // const filterOpportunity=(): Promise
  const getfilterOpportunity = (params: string): Promise<any> => {
    return GET(`/filter-opportunity?${params}`)
  }

  const getOpportunityByClubIdAndFilter = (
    clubId: number,
    queryParams: string,
  ): Promise<IOpportunityResponse> => {
    return GET(`/opportunity-by-club/${clubId}?${queryParams}`)
  }

  const searchOpportunityByKey = (key: string): Promise<IOpportunityResponse> => {
    return GET(`/search-opportunity/${key}`)
  }

  return {
    isLoading,
    createOpportunity,
    getOpportunityByClubId,
    getOpportunityByProfileType,
    getOpportunityById,
    updateApplicants,
    updateOpportunity,
    getApplyOpportunity,
    getfilterOpportunity,
    getOpportunityByClubIdAndFilter,
    searchOpportunityByKey,
  }
}

interface IOpportunityRequestPayload {
  season: string
  ageGroup: string
  playerPosition: string | null
  coachPosition: string | null
  level: string
  discipline: string
  profileType: string
  country: string
  otherCriteria: string
  description: string | null
  opportunityThumbnail: number | null
  club: number | null
}
interface IUpdateOpportunityRequestPayload {
  id: number | null
  season: string
  ageGroup: string
  playerPosition: string | null
  coachPosition: string | null
  level: string
  discipline: string
  profileType: string
  country: string
  otherCriteria: string
  description: string | null
  opportunityThumbnail: number | null
  club: number | null
  status: boolean
}
export interface IUpdateOpportunityPayload {
  data: IUpdateOpportunityRequestPayload
}

export interface IApplicantRequestPayload {
  id: number | null
}
export interface IApplicantPayload {
  data: IApplicantRequestPayload
}
export interface IOpportunityPayload {
  data: IOpportunityRequestPayload
}
