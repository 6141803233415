import React, { useContext, useEffect, useState } from "react"
import ProfileBannerImg from "../../../assets/svg/ProfileBannerImg.svg"
import ProfileBannerImgPopup from "../../playerProfile/ProfileBannerImgPopup"
import ProfileImgPopup from "../../playerProfile/ProfileImgPopup"
import EditBtnBlack from "../../../assets/svg/EditBtnBlack"
import GRButton from "../../../components/ui/GRButton"
import { IClubDetails } from "../../../modals/Club"
import { useSearchParams } from "react-router-dom"
import { useUserHook } from "../../../hooks/useUserHook"
import AppContext from "../../../context/app-context/AppContext"
import { IUploadFilesResponse } from "@App/modals/Config"
import ClubLogo from "../../../assets/svg/ClubLogo.svg"
import { IUnfollowClubPayload, useClubAPI } from "../../../services/useClubAPI"
import ProfileImg from "../../../components/playerProfile/ProfileImg"

interface IClubHeroSectionProps {
  clubId: string
  clubData?: IClubDetails
  handleSendClaimRequest: () => Promise<void>
  isAdmin: boolean | undefined
  adminStatus: boolean | undefined
  onProfileUploadSuccess: (r: IUploadFilesResponse) => void
  onProfileDeleteSuccess: () => void
  onBannerUploadSuccess: (r: IUploadFilesResponse) => void
}

const HeroSection = (props: IClubHeroSectionProps) => {
  // Props
  const {
    clubId,
    clubData,
    handleSendClaimRequest,
    isAdmin,
    adminStatus,
    onProfileUploadSuccess,
    onProfileDeleteSuccess,
    onBannerUploadSuccess,
  } = props

  //API
  const { unfollowClub } = useClubAPI()

  // State
  const [isShowBannerImgDialog, setIsShowBannerImgDialog] = useState<boolean>(false)
  const [isShowProfileImgDialog, setIsShowProfileImgDialog] = useState<boolean>(false)
  const [isShowProfileImg, setIsShowProfileImg] = useState<boolean>(false)
  const [, setSearchParams] = useSearchParams({ tab: "" })

  // Hooks
  const { handleFollowClub, isAlreadyFollowingClub } = useUserHook()

  // Context
  const { loginAsProfileDetails, currentLoginProfile } = useContext(AppContext)

  const [isFollowingClub, setIsFollowingClub] = useState<boolean>(false)
  const [followersCount, setFollowersCount] = useState<number>()

  useEffect(() => {
    const isAlreadyFollowing = isAlreadyFollowingClub(Number(clubId))
    setIsFollowingClub(isAlreadyFollowing)
  }, [clubId])

  useEffect(() => {
    const member = clubData?.memberCount ? clubData?.memberCount : 0
    const followers = clubData?.followerCount ? clubData?.followerCount : 0
    setFollowersCount(member + followers)
  }, [clubData?.memberCount, clubData?.followerCount])

  const isCanSentClaimReq =
    loginAsProfileDetails?.userExperiences
      ?.find((u) => u?.club?.adminStatus === "Claim admin")
      ?.club?.id?.toString() === clubId

  const onClickFollowClub = async () => {
    try {
      const id = clubId ? parseInt(clubId) : undefined
      if (!id) return
      await handleFollowClub(id)
      setIsFollowingClub(!isFollowingClub)
      setFollowersCount(followersCount || 0 + 1)
    } catch {}
  }

  const handleUnfollow = async () => {
    try {
      const payload: IUnfollowClubPayload = {
        data: {
          clubId: clubId,
        },
      }
      await unfollowClub(payload)
      setIsFollowingClub(!isFollowingClub)
      followersCount && setFollowersCount(followersCount - 1)
    } catch {}
  }

  return (
    <div className="">
      <div className="rounded-2xl sm:px-0 px-4">
        <div className="relative sm:bg-transparent bg-white rounded-t-2xl">
          {clubData?.attributes?.clubCoverPhoto?.url ? (
            <img
              className="w-full lg:h-[277px] sm:h-[250px] h-[150px] object-cover rounded-t-2xl sm:rounded-none"
              src={clubData?.attributes?.clubCoverPhoto?.url ?? ProfileBannerImg}
              alt="bannerImg"
            />
          ) : (
            <div className="w-full lg:h-[277px] sm:h-[250px] h-[150px] rounded-t-2xl sm:rounded-none bg-gray-100"></div>
          )}

          {isAdmin && (
            <div
              onClick={() => {
                setIsShowBannerImgDialog(!isShowBannerImgDialog)
              }}
              className="cursor-pointer absolute top-[20px] sm:right-6 right-4"
            >
              <EditBtnBlack />
            </div>
          )}
          <ProfileBannerImgPopup
            isShowBannerImgDialog={isShowBannerImgDialog}
            onClose={() => setIsShowBannerImgDialog(false)}
            onProfileUploadSuccess={onBannerUploadSuccess}
            onProfileDeleteSuccess={onProfileDeleteSuccess}
            profilePicture={clubData?.attributes?.clubCoverPhoto}
          />
          <div className="absolute md:bottom-[-44%] bottom-[-33%] left-[36%] md:left-6 sm:left-4 mt-10">
            <div className="cursor-pointer rounded-full bg-white ">
              <div className="rounded-full sm:p-1 p-[2px] w-max relative">
                <div className="rounded-full bg-white sm:p-1 p-[2px] w-max relative">
                  <img
                    src={clubData?.attributes?.clubLogo?.url ?? ClubLogo}
                    className="w-[88px] h-[88px] sm:w-[150px] sm:h-[150px] md:w-[227px] md:h-[227px] rounded-full"
                    alt="profileImg"
                    onClick={() => setIsShowProfileImg(true)}
                  />
                  {isAdmin && (
                    <button
                      onClick={() => setIsShowProfileImgDialog(true)}
                      className="absolute sm:right-[10%] -right-[10px] bottom-[10%] flex justify-center items-center w-[35px] h-[35px] bg-white rounded-full shadow-lg"
                    >
                      <svg
                        enableBackground="new 0 0 32 32"
                        height="20px"
                        id="Layer_1"
                        version="1.1"
                        viewBox="0 0 32 32"
                        width="20px"
                      >
                        <g id="camera">
                          <path
                            clipRule="evenodd"
                            d="M16,10.001c-4.419,0-8,3.581-8,8c0,4.418,3.581,8,8,8   c4.418,0,8-3.582,8-8C24,13.583,20.418,10.001,16,10.001z M20.555,21.906c-2.156,2.516-5.943,2.807-8.459,0.65   c-2.517-2.156-2.807-5.944-0.65-8.459c2.155-2.517,5.943-2.807,8.459-0.65C22.42,15.602,22.711,19.391,20.555,21.906z"
                            fill="#333333"
                            fillRule="evenodd"
                          />
                          <path
                            clipRule="evenodd"
                            d="M16,14.001c-2.209,0-3.999,1.791-4,3.999v0.002   c0,0.275,0.224,0.5,0.5,0.5s0.5-0.225,0.5-0.5V18c0.001-1.656,1.343-2.999,3-2.999c0.276,0,0.5-0.224,0.5-0.5   S16.276,14.001,16,14.001z"
                            fill="#333333"
                            fillRule="evenodd"
                          />
                          <path
                            clipRule="evenodd"
                            d="M29.492,9.042l-4.334-0.723l-1.373-3.434   C23.326,3.74,22.232,3,21,3H11C9.768,3,8.674,3.74,8.214,4.886L6.842,8.319L2.509,9.042C1.055,9.283,0,10.527,0,12v15   c0,1.654,1.346,3,3,3h26c1.654,0,3-1.346,3-3V12C32,10.527,30.945,9.283,29.492,9.042z M30,27c0,0.553-0.447,1-1,1H3   c-0.553,0-1-0.447-1-1V12c0-0.489,0.354-0.906,0.836-0.986l5.444-0.907l1.791-4.478C10.224,5.25,10.591,5,11,5h10   c0.408,0,0.775,0.249,0.928,0.629l1.791,4.478l5.445,0.907C29.646,11.094,30,11.511,30,12V27z"
                            fill="#333333"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
            {isShowProfileImgDialog && (
              <ProfileImgPopup
                isShowProfileImgDialog={isShowProfileImgDialog}
                onClose={() => {
                  setIsShowProfileImgDialog(false)
                }}
                onProfileUploadSuccess={onProfileUploadSuccess}
                onProfileDeleteSuccess={onProfileDeleteSuccess}
                profilePicture={clubData?.attributes?.clubLogo}
              />
            )}
            <ProfileImg
              isShowProfileImg={isShowProfileImg}
              onClose={() => setIsShowProfileImg(false)}
              profilePicture={clubData?.attributes?.clubLogo}
            />
          </div>
        </div>

        {/* info */}
        <div
          className={`pt-10 px-4 sm:p-0 pb-4 sm:ml-[200px] md:ml-[272px] sm:mt-[22px] md:flex justify-center sm:justify-between sm:bg-transparent bg-white rounded-[0px_0px_16px_16px] sm:mb-0 mb-4`}
        >
          <div className="flex flex-col items-center sm:block sm:justify-normal ">
            <div className="text-[20px] mb-2 sm:mt-0 mt-2 md:text-[32px] md:leading-[40px] font-semibold font-inter sm:text-left text-center">
              {clubData?.attributes?.name}
            </div>
            <div className="flex gap-2 sm:gap-4 mb-2">
              <div className="text-[12px] font-inter text-gr-neutralT3">Club</div>
              <div className="border-r border-gr-primaryR4"></div>
              <div className="text-[12px] font-inter text-gr-neutralT3">
                {clubData?.attributes.country}
              </div>
            </div>
            <div className="sm:flex sm:flex-row gap-3 text-[12px] flex flex-col items-center">
              <div className="text-[12px] font-inter text-gr-neutralT3">
                Current Admin-{" "}
                {clubData?.attributes?.admins?.map((a, i) => (
                  <span key={a?.id}>
                    {a?.firstname} {a?.lastname}
                    {i !== clubData?.attributes?.admins?.length - 1 && ", "}
                  </span>
                ))}
              </div>
              {!adminStatus && isCanSentClaimReq && (
                <div className="border border-gr-primaryR2 py-1 px-3 rounded-2xl  w-[100px] font-[500] cursor-pointer font-inter text-gr-primaryR2 ">
                  <button type="button" onClick={() => handleSendClaimRequest()}>
                    Claim Admin
                  </button>
                </div>
              )}
              {adminStatus === false && isCanSentClaimReq && <p>Pending</p>}
            </div>
            <div className="sm:hidden flex mt-3">
              {!isAdmin && (
                <>
                  {!isAdmin && !isFollowingClub ? (
                    <div className="justify-start">
                      <GRButton
                        label="Follow"
                        width="115px"
                        size="sm"
                        height={30}
                        className="!rounded-3xl"
                        onClick={() => onClickFollowClub()}
                      />
                    </div>
                  ) : (
                    <>
                      <GRButton
                        onClick={handleUnfollow}
                        className="!rounded-3xl"
                        label="Unfollow"
                        size="sm"
                        borderRadius={100}
                        height={30}
                        width="100px"
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div className="sm:hidden">
              <button
                className="font-inter"
                onClick={() =>
                  setSearchParams((oldParams) => {
                    oldParams.set("tab", "followers")
                    return oldParams
                  })
                }
              >
                <span className="font-bold">{followersCount && followersCount}</span>{" "}
                {followersCount && followersCount > 1 ? "Followers" : "Follower"}
              </button>
            </div>
          </div>
          <div className="hidden sm:flex flex-col justify-start gap-3 md:items-end sm:mr-[15px] md:mr-[24px]">
            {!isAdmin &&
              loginAsProfileDetails?.clubMember?.receiverId !== Number(clubId) &&
              currentLoginProfile?.profileType !== "Club" && (
                <>
                  {!isAdmin && !isFollowingClub ? (
                    <div className="justify-start">
                      <GRButton
                        label="Follow"
                        width="115px"
                        size="sm"
                        height={30}
                        className="!rounded-3xl"
                        onClick={() => onClickFollowClub()}
                      />
                    </div>
                  ) : (
                    <>
                      <GRButton
                        onClick={handleUnfollow}
                        className="!rounded-3xl"
                        label="Unfollow"
                        size="sm"
                        borderRadius={100}
                        height={30}
                        width="100px"
                      />
                    </>
                  )}
                </>
              )}

            <div className="flex items-center gap-6 text-gr-neutralT3 mt-1 md:mb-0 mb-6">
              <button
                className="font-inter hover:text-gr-primaryR2"
                onClick={() =>
                  setSearchParams((oldParams) => {
                    oldParams.set("tab", "followers")
                    return oldParams
                  })
                }
              >
                <span className="font-bold">{followersCount && followersCount}</span>{" "}
                {followersCount && followersCount > 1 ? "Followers" : "Follower"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
