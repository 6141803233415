import React, { createContext, useContext, useState } from "react"

interface InitVal {
  isLoading: boolean
  setIsLoading: (newValue: boolean) => void
}

const initVal: InitVal = {
  isLoading: false,
  setIsLoading: () => undefined,
}

const LoaderContext = createContext<InitVal>(initVal)
export default LoaderContext

interface LoaderProviderProps {
  children: React.ReactNode
}

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <LoaderContext.Provider
      value={{
        isLoading: !!isLoading,
        setIsLoading,
      }}
    >
      {children}
    </LoaderContext.Provider>
  )
}

export const useLoader = () => useContext(LoaderContext)
