import React from "react"
import { GRDialog } from "../ui/GRDialog"
import DialogHeader from "./DialogHeader"
import GRTextfield from "../ui/GRTextfield"
import { useFormik } from "formik"
import { inviteByEmailFormValidation } from "../../validations/Validations"
import { useUsersAPI } from "../../services/useUsersAPI"
import GRButton from "../ui/GRButton"
import { successToast } from "../../utils/ToastUtils"

interface IRecordHistoryProps {
  isShowDialog: boolean
  onClose: () => void
}
const UpdateChildEmail = (props: IRecordHistoryProps) => {
  const { isShowDialog, onClose } = props

  // APIs
  const { isLoading, updateEmail } = useUsersAPI()

  const onSubmit = async (values: any) => {
    try {
      const payload = {
        data: {
          email: values?.email,
        },
      }
      const updateEmailResponse = await updateEmail(payload)
      if (updateEmailResponse?.user) {
        successToast("Updated child user email.")
        onClose()
      }
    } catch (error) {
      // Handle errors if needed
      console.error("Error updated child user email: ", error)
    }
  }
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: { email: "" },
    validationSchema: inviteByEmailFormValidation,
    onSubmit: onSubmit,
  })
  const body = (
    <div className="">
      <div className="px-8">
        <form className="compeleteProfileForm" onSubmit={handleSubmit}>
          <label className="leading-6 mb-1 block text-sm font-inter">Email</label>
          <div className="mb-6">
            <GRTextfield
              type="text"
              value={values?.email}
              onChange={handleChange}
              name="email"
              error={touched?.email && Boolean(errors?.email)}
              helperText={touched?.email && errors?.email ? errors?.email : ""}
              width="100%"
              placeholder="Enter email"
            />
          </div>
          <div className="flex justify-end mb-6">
            <GRButton
              isSubmitting={isLoading}
              type="submit"
              fontSize="14px"
              height={40}
              label="Send invite"
              className="w-[120px]"
            />
          </div>
        </form>
      </div>
    </div>
  )

  return (
    <div>
      <GRDialog
        open={isShowDialog}
        onClose={() => onClose()}
        dialogtitle={<DialogHeader onClose={onClose} title={`Set child user email`} />}
        dialogbody={body}
        fullWidth
        classes={{ paper: "" }}
      />
    </div>
  )
}

export default UpdateChildEmail
